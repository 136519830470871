import React, { useEffect, useState } from 'react';
import { Collapse, Form, Nav } from 'react-bootstrap';
import Loading from '../loading/Loading';

/*import {
  Activity,
  Clipboard
} from 'react-feather';*/


import { Link, useLocation, NavLink, useHistory, /*Redirect*/ } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_USER_REPORTS } from '../../store/report/types';
import { FETCH_USER_ACCOUNTING_LIST } from '../../store/accounting/types';
import { FETCH_USER_PROJECTS } from '../../store/project/types';
import { FETCH_USERS_GROUP } from '../../store/group/types';
import { FETCH_USER_SOCIETY } from '../../store/society/types';
import { GET_REPORTS_REF } from '../../store/report/types';

import folder from '../../images/folder.png'
import grupoSociedades from '../../images/grupoSociedades.png'
import sociedad from '../../images/sociedad.png'
import usuario from '../../images/usuario.png'
import modulos from '../../images/modulos.png'
import documents from '../../images/documents.png'
//import reportesAll from '../../images/reportesAll.png'
import administracion from '../../images/administracion.png'
import support from '../../images/support.png'
import home from '../../images/home.png'
import reports from '../../images/reports.png'
import contabilidad from '../../images/contabilidad.png'

const Sidebar = (
  {
    userProfile,
    showUserReports,
    showUserReportsRef,
    reportsUserList,
    showUserAccountingList,
    accountingUserList,
    showUserProject,
    projectsList,
    showUserGroup,
    groupsList,
    showUserSociety,
    societiesList,
    userLoaded,
    reportsRef
  }) => {

  setTimeout(() => {

    // Esconde reportes cuando hay un click en contabilidad y vice versa
    if (document.querySelector('#collapseIcon1') && document.querySelector('#collapseIcon2')) {

      const collapseIcon1 = document.querySelector('#collapseIcon1')
      const collapseIcon2 = document.querySelector('#collapseIcon2')

      document.querySelector('#reporteShow').addEventListener('click', () => {

        //collapseIcon2.classList.remove('show')
        collapseIcon2.classList.remove('show')

      })

      document.querySelector('#contabilidad').addEventListener('click', () => {
        //collapseIcon1.classList.remove('show')
        collapseIcon1.classList.remove('show')

      })


    }

  }, 4500)
  // Muestra mensaje para los iconos del navBar
  setTimeout(() => {


    const infoinner = document.querySelector('#infoinner')
    const adminInfo = document.querySelector('#adminInfo')
    const containerAdminIngo = document.querySelector('#containerAdminIngo')

    const adminInfoIcon = document.querySelector('#adminInfoIcon')
    const adminInfoIcon2 = document.querySelector('#adminInfoIcon2')
    const adminInfoIcon3 = document.querySelector('#adminInfoIcon3')


    if (adminInfo && adminInfoIcon && adminInfoIcon2 && adminInfoIcon3) {

      adminInfoIcon.addEventListener('mouseover', () => {
        adminInfo.style.opacity = '100'
        adminInfo.style.transition = 'all 1s'
        containerAdminIngo.style.justifyContent = 'end'
        infoinner.innerHTML = 'Panel de administración'
      })

      adminInfoIcon.addEventListener('mouseout', () => {

        adminInfo.style.opacity = '0'
      })

      adminInfoIcon2.addEventListener('mouseover', () => {
        adminInfo.style.opacity = '100'
        adminInfo.style.transition = 'all 1s'
        containerAdminIngo.style.justifyContent = 'center'
        infoinner.innerHTML = 'Soporte'
      })

      adminInfoIcon2.addEventListener('mouseout', () => {

        adminInfo.style.opacity = '0'
      })

      adminInfoIcon3.addEventListener('mouseover', () => {
        adminInfo.style.opacity = '100'
        adminInfo.style.transition = 'all 1s'
        containerAdminIngo.style.justifyContent = 'center'
        infoinner.innerHTML = 'Perfil de usuario'
      })

      adminInfoIcon3.addEventListener('mouseout', () => {

        adminInfo.style.opacity = '0'
      })

    }

    if (adminInfo && adminInfoIcon2 && adminInfoIcon3) {

      adminInfoIcon2.addEventListener('mouseover', () => {
        adminInfo.style.opacity = '100'
        adminInfo.style.transition = 'all 1s'
        containerAdminIngo.style.justifyContent = 'start'
        infoinner.innerHTML = 'Soporte'
      })

      adminInfoIcon2.addEventListener('mouseout', () => {

        adminInfo.style.opacity = '0'
      })

      adminInfoIcon3.addEventListener('mouseover', () => {
        adminInfo.style.opacity = '100'
        adminInfo.style.transition = 'all 1s'
        containerAdminIngo.style.justifyContent = 'center'
        infoinner.innerHTML = 'Perfil de usuario'
      })

      adminInfoIcon3.addEventListener('mouseout', () => {

        adminInfo.style.opacity = '0'
      })

    }

  }, 4500)


  useEffect(() => {
    showUserReportsRef()
  }, [showUserReportsRef])

  /*useEffect(() => {
    console.log(reportsRef)
    console.log(reportsUserList)
    console.log(userProfile)
  }, [reportsRef, reportsUserList])
*/
  const history = useHistory();

  const [loading, setLoading] = useState(false)

  const [isShow, setShow] = useState(false);

  const [openReport, setOpenReport] = useState(false);

  const [openAccounting, setOpenAccounting] = useState(false);

  const [uuidFolder, setUuidFolder] = useState('')

  const { pathname } = useLocation();

  const currentPath = window.location.pathname;



  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'PROJECT_ADMIN') {
        showUserProject();
      }
      if (userProfile.profile.user_role === 'GROUP_ADMIN' || userProfile.profile.user_role === 'USER_GROUP') {
        showUserGroup();
      }
      if (userProfile.profile.user_role === 'USER_SOCIETY') {
        showUserSociety();
      }
    }
  }, [showUserProject, showUserGroup, showUserSociety, userProfile]);

  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'PROJECT_ADMIN') {
        if (!localStorage.project || localStorage.project === "") {
          if (projectsList.length !== 0)
            localStorage.setItem('project', projectsList[0].uuid);
          else
            localStorage.setItem('project', "");
        }
      }
      if (userProfile.profile.user_role === 'GROUP_ADMIN' || userProfile.profile.user_role === 'USER_GROUP') {
        if (!localStorage.group || localStorage.group === "") {
          if (groupsList.length !== 0)
            localStorage.setItem('group', groupsList[0].uuid);
          else
            localStorage.setItem('group', "");
        }
      }
      if (userProfile.profile.user_role === 'USER_SOCIETY') {
        if (!localStorage.society || localStorage.society === "") {
          if (societiesList.length !== 0)
            localStorage.setItem('society', societiesList[0].uuid);
          else
            localStorage.setItem('society', "");
        }
      }
    }
  }, [userProfile, projectsList, groupsList, societiesList]);

  useEffect(() => {
    setShow(false);
  }, [pathname]);



  useEffect(() => {
    if (currentPath === '/documents_user/root') {
      setUuidFolder(localStorage.project)
      history.replace(`#1?project=${uuidFolder}`);
    }
  }, [userLoaded, projectsList])


  useEffect(() => {
    if (userLoaded) {
      if (userProfile.profile) {
        if (userProfile.profile.user_role === 'PROJECT_ADMIN') {
          if (localStorage.project) {
            showUserReports({ project: localStorage.project });
            showUserAccountingList({ project: localStorage.project });
          } else {
            if (projectsList.length !== 0) {
              showUserReports({ project: projectsList[0].uuid });
              showUserAccountingList({ project: projectsList[0].uuid });
            }
          }
        }
        if (userProfile.profile.user_role === 'GROUP_ADMIN' || userProfile.profile.user_role === 'USER_GROUP') {
          if (localStorage.group) {
            showUserReports({ group: localStorage.group });
            showUserAccountingList({ group: localStorage.group });
          } else {
            if (groupsList.length !== 0) {
              showUserReports({ project: groupsList[0].uuid });
              showUserAccountingList({ project: groupsList[0].uuid });
            }
          }
        }
        if (userProfile.profile.user_role === 'USER_SOCIETY') {
          if (localStorage.society) {
            showUserReports({ society: localStorage.society });
            showUserAccountingList({ society: localStorage.society });
          } else {
            if (societiesList.length !== 0) {
              showUserReports({ project: societiesList[0].uuid });
              showUserAccountingList({ project: societiesList[0].uuid });
            }
          }
        }
      }
    }
  }, [showUserReports, showUserAccountingList, userProfile, userLoaded, groupsList, projectsList, societiesList]);

  window.addEventListener('DOMContentLoaded', event => {
    // Se mostra el contenido despues de 6 segundos mientras se carga, muestra un loading
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 4000)
  })

  if (loading) {
    return (
      <Loading />
    )
  } else {

    // Botón del menu toggle
    const toggleSidebar = () => {
      setShow(!isShow);
    }

    const toggleProject = ({ target }) => {
      localStorage.setItem('project', target.value);
      switch (window.location.pathname) {
        case '/admin/group':
          history.replace(`#1?search=&project=${target.value}&ordering=`);
          break;
        case '/admin/society':
          history.replace(`#1?search=&project=${target.value}&group=`);
          break;
        case '/admin/users':
          history.replace(`#1%1?search=&project=${target.value}&group=&society=&ordering=&user_role=`);
          break;
        default:
          break;
      }
      if (window.location.pathname.includes('/reportView'))
        window.location.href = `/home`;
      else {
        if (window.location.pathname.includes('/accounting'))
          window.location.href = `/home`;
        else
          window.location.reload();
      }
      localStorage.removeItem('auxSociety');
    }

    const toggleGroup = ({ target }) => {
      localStorage.setItem('group', target.value);
      switch (window.location.pathname) {
        case '/admin/society':
          history.replace(`#1?search=&project=&group=${target.value}`);
          break;
        case '/admin/users':
          history.replace(`#1%1?search=&project=&group=${target.value}&society=&ordering=&user_role=`);
          break;
        default:
          break;
      }
      if (window.location.pathname.includes('/reportView'))
        window.location.href = `/home`;
      else {
        if (window.location.pathname.includes('/accounting'))
          window.location.href = `/home`;
        else
          window.location.reload();
      }
      localStorage.removeItem('auxSociety');
    }

    const toggleSociety = ({ target }) => {
      localStorage.setItem('society', target.value);
      window.location.reload();
      if (window.location.pathname.includes('/reportView'))
        window.location.href = `/home`;
      else {
        if (window.location.pathname.includes('/accounting'))
          window.location.href = `/home`;
        else
          window.location.reload();
      }
    }
    const selectDetail = () => {
      return (
        <>
          {
            userProfile.profile && (
              <>
                {
                  userProfile.profile.user_role === 'PROJECT_ADMIN' && (
                    <Form.Group controlId="project">
                      <Form.Control
                        size="md"
                        as="select"
                        type="project"
                        name="project"
                        onChange={toggleProject}
                      >
                        <option disabled>Lista de proyectos</option>
                        {
                          projectsList.map((project) => {
                            return (
                              localStorage.project === project.uuid ? (
                                <option value={project.uuid} key={project.uuid} selected>{project.project_name}</option>
                              ) : (
                                <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                              )
                            );
                          })
                        }
                      </Form.Control>
                    </Form.Group>
                  )
                }
                {
                  (userProfile.profile.user_role === 'GROUP_ADMIN' || userProfile.profile.user_role === 'USER_GROUP') && (
                    <Form.Group controlId="group">
                      <Form.Control
                        size="md"
                        as="select"
                        type="group"
                        name="group"
                        onChange={toggleGroup}
                      >
                        <option disabled>Lista de grupos</option>
                        {
                          groupsList.map((group) => {
                            return (
                              localStorage.group === group.uuid ? (
                                <option value={group.uuid} key={group.uuid} selected>{group.group_name}</option>
                              ) : (
                                <option value={group.uuid} key={group.uuid}>{group.group_name}</option>
                              )
                            );
                          })
                        }
                      </Form.Control>
                    </Form.Group>
                  )
                }
                {
                  (userProfile.profile.user_role === 'USER_SOCIETY') && (
                    <Form.Group controlId="society">
                      <Form.Control
                        size="md"
                        as="select"
                        type="society"
                        name="society"
                        onChange={toggleSociety}
                      >
                        <option disabled>Lista de sociedades</option>
                        {
                          societiesList.map((society) => {
                            return (
                              localStorage.society === society.uuid ? (
                                <option value={society.uuid} key={society.uuid} selected>{society.society_business_name}</option>
                              ) : (
                                <option value={society.uuid} key={society.uuid}>{society.society_business_name}</option>
                              )
                            );
                          })
                        }
                      </Form.Control>
                    </Form.Group>
                  )
                }
              </>
            )
          }
        </>
      );
    }

    const SidebarLinks = () => {
      if (pathname.includes('/admin/projects') ||
        pathname.includes('/admin/society') ||
        pathname.includes('/admin/group') ||
        pathname.includes('/admin/reports') ||
        pathname.includes('/admin/documents') ||
        pathname.includes('/admin/users') ||
        pathname.includes('/admin/accounting')
      ) {
        return (


          <div className="d-flex flex-column justify-content-between h-100" >
            <div className="menu-top" >
              {
                selectDetail()
              }


              <Nav variant="pills" id='navBarPrincipal' className="flex-column sidebar-nav">
                {// Verifica si el usuario es super admin, el link va a redirecciones a /admin/projects
                  userProfile.profile && (
                    userProfile.profile.user_role === 'SUPER_ADMIN' ? (

                      <Nav.Item id='navItemHover' >
                        <Nav.Link
                          id='pryectosTop'
                          style={{ display: 'flex' }}
                          as={NavLink}
                          active={pathname.includes('/admin/projects')}
                          eventKey="/admin/projects"
                          to="/admin/projects"
                          onClick={() => {
                            toggleSidebar();
                            if (window.location.pathname === "/admin/projects")
                              window.location.href = "/admin/projects";
                          }}
                        >


                          <div id='pryectos'><img src={folder} width={25} className="mr-3" alt='folder' /></div>Proyectos

                        </Nav.Link>
                      </Nav.Item>

                    ) : (
                      ''
                    )
                  )
                }
                {
                  userProfile.profile && (
                    userProfile.profile.user_role === 'SUPER_ADMIN' || userProfile.profile.user_role === 'PROJECT_ADMIN' ? (

                      <Nav.Item id='navItemHover'>
                        <Nav.Link
                          as={NavLink}
                          active={pathname.includes('/admin/group')}
                          eventKey="/admin/group"
                          to={!localStorage.project ? (`/admin/group#1?search=&project=&ordering=`) : (`/admin/group#1?search=&project=${localStorage.project}&ordering=`)}
                          onClick={() => {
                            toggleSidebar();
                            if (window.location.pathname === "/admin/group") {
                              if (!localStorage.project)
                                window.location.href = `/admin/group#1?search=&project=&ordering=`;
                              else
                                window.location.href = `/admin/group#1?search=&project=${localStorage.project}&ordering=`;
                              window.location.reload();
                            }
                          }}
                        >

                          <div id='pryectos2'><img src={grupoSociedades} width={25} className="mr-3" alt='sociedad' /></div>G.Sociedad</Nav.Link>

                      </Nav.Item>
                    ) : (
                      ''
                    )
                  )
                }
                <Nav.Item id='navItemHover'>
                  <Nav.Link
                    as={NavLink}
                    active={pathname.includes('/admin/society')}
                    eventKey="/admin/society"
                    to={!localStorage.project ? (
                      !localStorage.group ? (
                        `/admin/society#1?search=&project=&group=`
                      ) : (
                        `/admin/society#1?search=&project=&group=${localStorage.group}`
                      )) : (
                      `/admin/society#1?search=&project=${localStorage.project}&group=`
                    )}

                    onClick={() => {
                      toggleSidebar();
                      if (window.location.pathname === "/admin/society") {
                        if (!localStorage.project) {
                          if (!localStorage.group)
                            window.location.href = `/admin/society#1?search=&project=&group=`;
                          else
                            window.location.href = `/admin/society#1?search=&project=&group=${localStorage.group}`;
                        } else
                          window.location.href = `/admin/society#1?search=&project=${localStorage.project}&group=`;
                        window.location.reload();
                      }
                    }}
                  >

                    <div id='pryectos3'><img src={sociedad} width={25} className="mr-3" alt='...' /></div>Sociedad
                  </Nav.Link>

                </Nav.Item>
                <Nav.Item id='navItemHover'>
                  <Nav.Link
                    as={NavLink}
                    active={pathname.includes('/admin/users')}
                    eventKey="/admin/users"
                    to={!localStorage.project ? (
                      !localStorage.group ? (
                        `/admin/users#1%1?search=&project=&group=&society=&ordering=&user_role=`) : (`/admin/users#1%1?search=&project=&group=${localStorage.group}&society=&ordering=&user_role=`
                      )) : (
                      `/admin/users#1%1?search=&project=${localStorage.project}&group=&society=&ordering=&user_role=`
                    )}
                    onClick={() => {
                      toggleSidebar();
                      if (window.location.pathname === "/admin/users") {
                        if (!localStorage.project) {
                          if (!localStorage.group)
                            window.location.href = `/admin/users#1%1?search=&project=&group=&society=&ordering=&user_role=`;
                          else
                            window.location.href = `/admin/users#1%1?search=&project=&group=${localStorage.group}&society=&ordering=&user_role=`;
                        } else
                          window.location.href = `/admin/users#1%1?search=&project=${localStorage.project}&group=&society=&ordering=&user_role=`;
                        window.location.reload();
                      }
                    }}
                  >
                    <div id='pryectos4'><img src={usuario} width={25} className="mr-3" alt='...' /></div>Usuarios
                  </Nav.Link>
                </Nav.Item>
                {
                  userProfile.profile && (
                    userProfile.profile.user_role === 'SUPER_ADMIN' && (
                      <Nav.Item >
                        <div id='navItemHover'>
                          <Nav.Link

                            onClick={() => {
                              setOpenReport(!openReport);
                              toggleSidebar();
                            }}
                            aria-controls="example-collapse-text"
                            aria-expanded={openReport}
                            active={pathname.includes('/admin/reports') || pathname.includes('/admin/accounting')}
                          >
                            <div id='pryectos5'><img src={modulos} width={25} className="mr-3" alt='...' /></div>
                            Modulos
                          </Nav.Link>
                        </div>

                        <Collapse in={openReport}>
                          <div>
                            {/*<Nav.Item>*/}
                            {/*  <Nav.Link*/}
                            {/*    as={Link}*/}
                            {/*    to={"/"}*/}
                            {/*    className="text-capitalize ml-5"*/}
                            {/*    eventKey={`/`}*/}
                            {/*    onClick={toggleSidebar}*/}
                            {/*    style={{fontSize:"13px"}}*/}
                            {/*  >*/}
                            {/*    Admin modulos*/}
                            {/*  </Nav.Link>*/}
                            {/*</Nav.Item>*/}
                            <Nav.Item id="navItemHover">
                              <Nav.Link

                                as={Link}
                                to={"/admin/reports"}
                                className="text-capitalize ml-5"
                                eventKey={"/admin/reports"}
                                onClick={() => {
                                  toggleSidebar();
                                  if (window.location.pathname === "/admin/reports")
                                    window.location.href = "/admin/reports";
                                }}
                                active={pathname.includes('/admin/reports')}
                                style={{ fontSize: "13px" }}
                              >
                                <div id='pryectos6'><img src={folder} width={25} id='modules' alt='...' /> </div> Reportes
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item id="navItemHover">
                              <Nav.Link

                                as={Link}
                                to={"/admin/accounting"}
                                className="text-capitalize ml-5"
                                eventKey={"/admin/accounting"}
                                onClick={() => {
                                  toggleSidebar();
                                  if (window.location.pathname === "/admin/accounting")
                                    window.location.href = "/admin/accounting";
                                }}
                                active={pathname.includes('/admin/accounting')}
                                style={{ fontSize: "13px" }}
                              >
                                <div id='pryectos7'><img src={folder} width={25} id='modules' alt='...' /> </div>Contabilidad
                              </Nav.Link>
                            </Nav.Item>
                          </div>
                        </Collapse>
                      </Nav.Item>
                    )
                  )
                }
                {
                  userProfile.profile && (
                    userProfile.profile.user_role === 'SUPER_ADMIN' && (
                      <Nav.Item id='navItemHover'>
                        <Nav.Link as={NavLink} active={pathname.includes('/admin/documents')} eventKey="/admin/documents" to="/admin/documents/root" onClick={toggleSidebar}><div id='pryectos8'><img src={documents} width={25} className="mr-3" alt='...' /></div>Documentos</Nav.Link>
                      </Nav.Item>
                    )
                  )
                }
              </Nav>

            </div>


          </div>


        )
      }


      else {
        return (
          <div className="d-flex flex-column justify-content-between h-100">
            <div className="menu-top">
              {
                selectDetail()
              }
              <Nav variant="pills" className="flex-column sidebar-nav mt-1">
                <Nav.Item id='navItemHover'>
                  <Nav.Link as={NavLink} eventKey="/home" to="/home" onClick={toggleSidebar}><div id='pryecto9'><img src={home} width={25} alt='...' /></div>Inicio</Nav.Link>
                </Nav.Item>
                {
                  reportsUserList && (
                    reportsUserList.length !== 0 && (
                      <Nav.Item >
                        <Nav.Link
                          id='reporteShow'
                          onClick={() => setOpenReport(!openReport)}
                          aria-controls="example-collapse-text"
                          aria-expanded={openReport}
                        >
                          <div id='pryecto10'><img src={reports} width={25} alt='...' /></div>Mis reportes</Nav.Link>


                        <Collapse in={openReport} >
                          <div id='collapseIcon1'>
                          <div id='misReportes'>
                              {
                                reportsUserList.map((module) => {
                                  // Filtramos solo los reportes que están permitidos para el usuario actual
                                  const reportesFiltrados = module.report_list.filter(report =>
                                    reportsRef.some(permiso => permiso.reporte === report.uuid && permiso.cant_see === true && userProfile.user.uuid === permiso.usuario)
                                  );
                              
                                  return reportesFiltrados.map((report) => {
                                    return (
                                      <Nav.Item key={report.uuid}>
                                        <Nav.Link
                                          id='optNav'
                                          active={pathname.includes(`/reportView`) && pathname.includes(report.uuid)}
                                          href={`/reportView/${report.uuid}`}
                                          className='text-capitalize ml-5'
                                          eventKey={`/reportView/${report.uuid}`}
                                          onClick={toggleSidebar}
                                          style={{ fontSize: "13px", backgroundColor: '#009CDE' }}
                                        >
                                          {report.menu_name}
                                        </Nav.Link>
                                      </Nav.Item>
                                    )
                                  });
                                })
                              }
                            </div>
                          </div>
                        </Collapse>

                      </Nav.Item>
                    )
                  )
                }
                {
                  accountingUserList.length > 0 && (
                    <Nav.Item >
                      <div
                        style={{ cursor: 'pointer' }}
                        id='contabilidad'
                        onClick={() => setOpenAccounting(!openAccounting)}
                        aria-controls="example-collapse-text"
                        aria-expanded={openAccounting || window.location.href.includes('accounting')}
                        active={pathname.includes('/accounting')}
                      >
                        <div style={{ color: 'white', cursor: 'pointer' }} id='pryecto11'><img src={contabilidad} width={25} alt='...' /></div>Contabilidad</div>
                      {/*|| window.location.href.includes('accounting')}*/}
                      <Collapse in={openAccounting} >
                        <div id='collapseIcon2'>
                          <div id='miContabilidad'>
                            {
                              accountingUserList.map((accounting) => {
                                return (
                                  <Nav.Item key={accounting.uuid}>
                                    {
                                      // as={Link}
                                      // to={}
                                    }
                                    <Nav.Link
                                      id='optNav'

                                      href={
                                        accounting.accounting_type === 'BALANCE_8_COLUMNS' ? (`/accounting/1/${accounting.uuid}`) : (
                                          accounting.accounting_type === 'FINANCIAL_SITUATION' ? (`/accounting/2/${accounting.uuid}`) : (
                                            accounting.accounting_type === 'CASH_FLOWS' ? (`/accounting/4/${accounting.uuid}`) : (
                                              `/accounting/3/${accounting.uuid}`
                                            )
                                          )
                                        )
                                      }
                                      className="text-capitalize ml-5"
                                      eventKey={
                                        accounting.accounting_type === 'BALANCE_8_COLUMNS' ? (`/accounting/1/${accounting.uuid}`) : (
                                          accounting.accounting_type === 'CASH_FLOWS' ? (`/accounting/4/${accounting.uuid}`) : (
                                            `/accounting/3/${accounting.uuid}`
                                          )
                                        )
                                      }
                                      active={pathname.includes(`/accounting`) && pathname.includes(accounting.uuid)}
                                      onClick={toggleSidebar}
                                      style={{ fontSize: "13px", backgroundColor: '#009CDE' }}
                                    >
                                      {accounting.menu_name}
                                    </Nav.Link>
                                  </Nav.Item>
                                )
                              })
                            }
                          </div>
                        </div>
                      </Collapse>
                    </Nav.Item>
                  )
                }
                {
                  userProfile.profile && (
                    userProfile.profile.user_role !== 'SUPER_ADMIN' && userProfile.profile.user_documentation === true && (
                      <Nav.Item id='navItemHover'>
                        {/* as={NavLink}*/}
                        <Nav.Link href='/documents_user/root' eventKey="docs" to="/documents_user/root"><div id='pryecto12'><img src={folder} width={25} alt='...' /></div>Documentos</Nav.Link>
                      </Nav.Item>
                    )
                  )
                }
              </Nav>
            </div>

            <div className='testi'>


              <div id='containerAdminIngo' ><div id='adminInfo'><span id='infoinner'>Aqui podrás administrar todos tus datos</span></div></div>

              <div className="menu-bottom">
                <hr className='linea'></hr>
                <Nav  >

                  <Nav.Item id='adminInfoIcon2'>
                    <Nav.Link eventKey="support" href="/support" onClick={toggleSidebar} ><img src={support} width={35} id='iconHover' alt='...' /></Nav.Link>
                  </Nav.Item>
                  <Nav.Item id='adminInfoIcon3'>
                    <Nav.Link eventKey='info' href='/userinfo' onClick={toggleSidebar}><img src={usuario} width={35} id='iconHover' alt='...' /></Nav.Link>
                  </Nav.Item>
                  {
                    userProfile.profile && (
                      userProfile.profile.user_role === 'SUPER_ADMIN' ? (
                        <Nav.Item id='adminInfoIcon'>
                          {/* <Nav.Link  as={Link} to="/admin/projects" href='/admin/projects#1?search=' onClick={toggleSidebar}><Settings className="mr-3"/>Administración</Nav.Link>*/}
                          <Nav.Link href='/admin/projects#1?search=' onClick={toggleSidebar} ><img src={administracion} width={35} id='iconHover' alt='...' /></Nav.Link>
                        </Nav.Item>
                      ) : (
                        userProfile.profile.user_role === 'PROJECT_ADMIN' ? (
                          <Nav.Item id='adminInfoIcon'>
                            {/*as={Link} to={`/admin/group#1?search=&project=${localStorage.project}&ordering=`}*/}
                            <Nav.Link href={`/admin/group#1?search=&project=${localStorage.project}&ordering=`} onClick={toggleSidebar}><img src={administracion} width={35} id='iconHover' alt='...' /></Nav.Link>
                          </Nav.Item>
                        ) : (
                          userProfile.profile.user_role === 'GROUP_ADMIN' ? (
                            <Nav.Item id='adminInfoIcon'>
                              {/* as={Link} to={`/admin/society#1?search=&project=&group=${localStorage.group}`}*/}
                              <Nav.Link href={`/admin/society#1?search=&project=&group=${localStorage.group}`} onClick={toggleSidebar} ><img src={administracion} width={35} id='iconHover' alt='...' /></Nav.Link>
                            </Nav.Item>

                          ) : (
                            ''
                          )
                        )
                      )
                    )
                  }

                </Nav>
              </div>



            </div>


          </div>

        )
      }
    }

    return (
      <>
        <div className={`bg-white pt-5 p-3 ${isShow ? 'show' : ''}`} id="sidebar-wrapper">
          {SidebarLinks()}.
        </div>
      </>
    )
  }




}
Sidebar.propTypes = {
  showUserReports: PropTypes.func.isRequired,
  reportsUserList: PropTypes.array,
  accountingUserList: PropTypes.array,
  projectsList: PropTypes.array,
  showUserGroup: PropTypes.func.isRequired,
  showUserSociety: PropTypes.func.isRequired,
  societiesList: PropTypes.array,
};

const stateToProps = state => ({
  userProfile: state.profile.userProfile,
  reportsUserList: state.reports.reportsUserList,
  accountingUserList: state.accounting.accountingUserList,
  projectsList: state.projects.projectsList,
  userLoaded: state.profile.userLoaded,
  groupsList: state.groups.groupsList,
  societiesList: state.societies.societiesList,
  reportsRef: state.reports.reportsRef
});

const actionsToProps = dispatch => ({
  showUserReports: payload => dispatch({ type: FETCH_USER_REPORTS, payload }),
  showUserAccountingList: payload => dispatch({ type: FETCH_USER_ACCOUNTING_LIST, payload }),
  showUserProject: () => dispatch({ type: FETCH_USER_PROJECTS }),
  showUserGroup: () => dispatch({ type: FETCH_USERS_GROUP }),
  showUserSociety: payload => dispatch({ type: FETCH_USER_SOCIETY, payload }),
  showUserReportsRef: payload => dispatch({ type: GET_REPORTS_REF, payload })
});

export default connect(stateToProps, actionsToProps)(Sidebar);
