import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DELETE_LOG } from '../../store/logs/types';
import { Trash } from 'react-feather';
import { DELETE_INDUSTRY } from '../../store/society/types';

const IndustryDelete = ({ uuid, deleteIndustry, logDelete, setAuxIndustry, industryList }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    logDelete(false);
    setShow(false);
  };
  const handleShow = () => {
    logDelete(true);
    setShow(true);
  };

  const handleDelete = () => {
    handleClose();
    deleteIndustry(uuid);
    industryList.map((industry, n)=> {
      if (industry.uuid === uuid) {
        if (n === industryList.length - 1)
          setAuxIndustry(industryList[0].uuid);
        else{
          if(n === 0) {
            setAuxIndustry(industryList[1].uuid);
          }
          else
            setAuxIndustry(industryList[n+1].uuid);
        }
      }
      return null;
    })
  }

  return (
    <>
      <Button
        variant="outline-secondary"
        size="xs"
        title="Eliminar industria"
        data-toggle="tooltip"
        data-placement="top"
        onClick={handleShow}
      >
        <Trash/>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton/>
        <Modal.Body>
          <Card.Body className="py-5 text-center">
            <i className="far fa-trash-alt text-danger text-center fa-3x mb-4"/>
            <h3 className="py-2 font-weight-bold title-color text-center">
              Eliminar industria
            </h3>
            <p className="text-center">
              ¿Estás seguro que deseas eliminar esta industria?
            </p>
            <div className="justify-content-center d-flex">
              <Button
                className="btn-rounded col-6 base-btn-color mt-4 py-2"
                variant="primary"
                onClick={handleDelete}
              >
                Seguro
              </Button>
            </div>
          </Card.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

IndustryDelete.propTypes = {
  deleteIndustry: PropTypes.func.isRequired,
  logDelete: PropTypes.func.isRequired
};

const actionsToProps = dispatch => ({
  deleteIndustry: payload => dispatch({ type: DELETE_INDUSTRY, payload }),
  logDelete: payload => dispatch({ type: DELETE_LOG, payload })
});

export default connect(null, actionsToProps)(IndustryDelete);
