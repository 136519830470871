const schema = {
  first_name: {
    type: 'string',
    min: 3,
    max: 100,
    optional: false,
    pattern: '^[a-zA-ZÀ-ÿ\u00f1\u00d1]+( [a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
    messages: {
      required: 'Debes ingresar tu nombre.',
      string: 'Debes ingresar un nombre válido',
      stringMin: 'El texto es muy corto.',
      stringMax: 'Tu nombre es muy largo (máximo 100 caracteres).',
      stringPattern: 'Debes ingresar solo letras'
    }
  },
  last_name: {
    type: 'string',
    min: 3,
    max: 100,
    optional: false,
    pattern: '^[a-zA-ZÀ-ÿ\u00f1\u00d1]+( [a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
    messages: {
      required: 'Debes ingresar tu apellido.',
      string: 'Debes ingresar un apellido válido',
      stringMin: 'El texto es muy corto.',
      stringMax: 'Tu apellido es muy largo (máximo 100 caracteres).',
      stringPattern: 'Debes ingresar solo letras'
    }
  },
  email: {
    type: 'email',
    max: 100,
    optional: false,
    messages: {
      emailEmpty: 'El email es obligatorio.',
      email: 'El email debe tener el siguiente formato ejemplo@correo.com',
      string: 'Debes ingresar un email',
      stringMax: 'El email es muy largo (máximo 100 caracteres).'
    }
  },
  password: {
    type: 'string',
    min: 8,
    max: 100,
    optional: false,
    pattern: '^(?=.*\\d)(?=.*[\u0021-\u002f\u003a-\u0040\u005B-\u0060\u007B-\u007E])(?=.*[A-Z])(?=.*[a-z])',
    messages: {
      required: 'La contraseña es obligatoria.',
      string: 'Por favor ingresar contraseña valida.',
      stringMin: 'Su contraseña es muy corta (minimo 8 caracteres).',
      stringMax: 'Su contraseña es muy larga (maximo 100 caracteres).',
      stringPattern: 'Su contraseña debe tener como mínimo una mayúscula, una minúscula, un número y un símbolo (@$!%*?&).'
    }
  },
  password_confirmation: {
    type: 'equal',
    field: "password",
    min: 8,
    max: 100,
    optional: false,
    messages: {
      equalField: 'Las contraseñas deben ser iguales',
      required: 'La contraseña es obligatoria.',
      string: 'Por favor ingresar contraseña valida.',
      stringMin: 'Tu contraseña es muy corta (mínimo 8 caracteres).',
      stringMax: 'Tu contraseña es muy larga (máximo 100 caracteres).'
    }
  },
  phone_number: {
    type: 'string',
    min: 0,
    max: 15,
    optional: true,
    empty: true,
    pattern: '^(\\+?)?[0-9]*$',
    messages: {
      required: 'Debes ingresar tu número de telefono.',
      number: 'Debes ingresar un número válido',
      stringMin: 'El número es muy corto.',
      stringMax: 'Tu número es muy largo (máximo 15 caracteres).',
      stringPattern: 'Debes ingresar un número válido'
    }
  },
  rut_user: {
    type: 'string',
    min: 0,
    max: 10,
    optional: true,
    empty: true,
    pattern: '^[0-9]+-[0-9kK]{1}$',
    messages: {
      required: 'Debes ingresar tu rut.',
      string: 'Debes ingresar un rut válido',
      stringMin: 'El rut es muy corto.',
      stringMax: 'El rut es muy largo (máximo 10 caracteres).',
      stringPattern: 'El rut no es válido'
    }
  },
};

export default schema;
