import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Dropdown, FormControl, FormGroup, Modal, Row } from 'react-bootstrap';
import { Copy, Share2 } from 'react-feather';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { GET_DOCUMENT_TOKEN } from '../../store/document/types';

const DocumentShare = ({ obtainToken, documentToken, uuid, tokenLoaded }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFlagCopy(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [token, setToken] = useState('')
  const [flag, setFlag] = useState(false);
  const [flagCopy, setFlagCopy] = useState(false);

  useEffect(() => {
    setToken(documentToken);
    if(!tokenLoaded && documentToken && flag){
      handleShow();
      setFlag(false);
    }
  }, [documentToken, tokenLoaded, flag, flagCopy]);

  const handleShare = () => {
    obtainToken(uuid);
    setFlag(true);
  }

  return (
    <>
      <Dropdown.Item
        data-toggle="tooltip"
        data-placement="top"
        title="Compartir carpeta"
        className="text-mute"
        onClick={handleShare}
      >
        <Share2 size="15" className="mr-1"/>
        Compartir
      </Dropdown.Item>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton/>
        <Modal.Body>
          <Card.Body >
            <h4 className="font-weight-bold title-color text-left">
              Link
            </h4>
            <Row>
              <Col xs={10} className="p-0">
                <FormGroup className="position-relative" controlId="folder_name">
                  <FormControl
                    size="lg"
                    name="document_link"
                    type="document_link"
                    defaultValue={`${window.location.host}/share/${token}`}
                  />
                </FormGroup>
              </Col>
              <Col xs={2} className="p-0">
                <Button
                  className="btn-rounded base-btn-color"
                  variant="primary"
                  onClick={
                    () => {
                      navigator.clipboard.writeText(`${window.location.host}/share/${token}`);
                      setFlagCopy(true);
                    }
                  }
                >
                  <Copy size={35}/>
                </Button>
              </Col>
            </Row>
            {
              flagCopy && (
                <p>Link copiado al portapapeles</p>
              )
            }
          </Card.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

DocumentShare.propTypes = {
  obtainToken: PropTypes.func.isRequired,
};

const stateToProps = state => ({
  documentToken: state.documents.documentToken,
  tokenLoaded: state.documents.tokenLoaded
});

const actionsToProps = dispatch => ({
  obtainToken: payload => dispatch({ type: GET_DOCUMENT_TOKEN, payload })
});

export default connect(stateToProps, actionsToProps)(DocumentShare);
