import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { UserReport } from '../../components';

const ReportView = () => {

  return (
    <div className="container-admin bg-light">
      <Container className="py-5" id='layout-reports4' style={{ paddingLeft: '4rem' }} fluid>
        <div className="row">
          <div className="col-12">
            <Card bg='light' border='light'>
              <UserReport/>
            </Card>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ReportView;
