import React, { useEffect, useState } from 'react';
import { Breadcrumb, Col, Image, Row, Table } from 'react-bootstrap';
import { ConfigProvider } from 'react-avatar';
import SearchBar from '../search_bar';
import DocumentItem from '../document_item';
import { connect } from 'react-redux';
import { LIST_USER_FOLDERS, GET_DOCUMENT_PANEL_TAX_FORMS, PUT_BREAD_CRUMP, GET_DOCUMENTS_TAX_PANEL, NEW_VALUE_BREADCRUMP } from '../../store/document/types';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import searchFile from '../../images/file-search-regular.svg';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const DocumentsUser = ({ loadingTaxPanelState, getDocumentPanelTaxFormYear, bradCrumpList, breaDcrumbFucntion, folderTaxPanelList, showUserFolders, folderList, root_directory, document_list, loadingList, userProfile, getTaxPanelForms, newValueBreadCrump }) => {
  const { uuid } = useParams();
  const history = useHistory();
  const [valueSearch, setValueSearch] = useState(null);
  const [flag, setFlag] = useState(false);
  const [firstUuid, setFirstUuid] = useState(null);
  const [uuidProject, setUuidProject] = useState('')
  const [user, setUser] = useState('')
  const [folderData, setFolderData] = useState([]);
  const [isFolderList, setIsFolderList] = useState('')

  useEffect(() => {
    console.log(bradCrumpList)
  }, [bradCrumpList])

  console.log(isFolderList)

  useEffect(() => {
    const handlePopState = () => {
   
      if (folderData.length > 1) {
        // Eliminar el último objeto del array
        const newFolderData = folderData.slice(0, -1);
        setFolderData(newFolderData);
        // Obtener los datos de la carpeta anterior
        const previousFolderData = newFolderData[newFolderData.length - 1];
        console.log(previousFolderData.type)
        if (previousFolderData.type === "userFolder") {
          console.log('entra aqui 1', previousFolderData.type)
          showUserFolders(`/${previousFolderData.uuid}/`);
        } else {
          console.log('entra aqui 2', previousFolderData.type)
          getTaxPanelForms({ uuid: previousFolderData.uuid, type: previousFolderData.type });
        }

        if (previousFolderData.type !== 'folderList') {
          console.log('solo hay uno')
          console.log('entra aqui 3', previousFolderData.type)
          setIsFolderList('')
        }
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [folderData]);

  useEffect(() => {
    if (userProfile.profile && userProfile.profile.user_role) {
      setUser(userProfile.profile.user_role)
      if (userProfile.profile.user_role === 'PROJECT_ADMIN') {
        setUuidProject(localStorage.project);
      } if (userProfile.profile.user_role === 'GROUP_ADMIN') {
        setUuidProject(localStorage.group);
      } if (userProfile.profile.user_role === 'USER_SOCIETY') {
        setUuidProject(localStorage.society);
      } if (userProfile.profile.user_role === 'USER_GROUP') {
        setUuidProject(localStorage.group)
      }
    }

    if (uuid === 'root' && user) {
      if (user === 'PROJECT_ADMIN') {
        showUserFolders(`/?project=${uuidProject}`, `/?project=${uuidProject}`);
      } if (user === 'GROUP_ADMIN') {
        showUserFolders(`/?group=${uuidProject}`, `/?group=${uuidProject}`);
      } if (user === 'USER_SOCIETY') {
        showUserFolders(`/?society=${uuidProject}`, `/?society=${uuidProject}`);
      } if (user === 'USER_GROUP') {
        showUserFolders(`/?group=${uuidProject}`, `/?group=${uuidProject}`);
      }
    } else {
      if (!valueSearch)
        showUserFolders(`/${uuid}/`);
      else
        showUserFolders(`/${uuid}/?search=${valueSearch}`);
    }
  }, [uuid, showUserFolders, valueSearch, uuidProject, userProfile]);

  useEffect(() => {
    if (uuid != 'root') {
      history.push('/documents_user/root')
    }
  }, [showUserFolders, uuidProject, userProfile])

  useEffect(() => {
    if (root_directory !== 0)
      setFlag(true);// eslint-disable-next-line
  }, []);

  const handleFolder = (uuid, type, name) => {
    console.log(uuid, type)
    if (type === 'tax') {
      getTaxPanelForms({ uuid, type })
      breaDcrumbFucntion(({ uuid, type, name }));
      setFolderData([...folderData, { uuid, type }]);
    } else if (type === 'taxForm') {
      getTaxPanelForms({ uuid, type })
      breaDcrumbFucntion(({ uuid, type, name }));
      setFolderData([...folderData, { uuid, type }]);
    } else if (type === 'taxFormYear') {
      console.log('funciona')
      getDocumentPanelTaxFormYear({ uuid, type, name })
      breaDcrumbFucntion(({ uuid, type, name }));
      setFolderData([...folderData, { uuid, type }]);
    } else if (type === "userFolder") {
      setFirstUuid(uuid);
      showUserFolders(`/${uuid}`);
      breaDcrumbFucntion(({ uuid, type, name }));
      setFolderData([...folderData, { uuid, type }]);
    } else if (type === 'folderList') {
      breaDcrumbFucntion(({ uuid, type, name }));
      setIsFolderList(type)
      setFolderData([...folderData, { uuid, type }]);
    } else {
      return
    }

  }

  function handleBreadCrump(value) {
    const newData = value.map(item => {
      const { uuid, name, type } = item;
      console.log(uuid, type, name);
      return { uuid, name, type };
    });

    setFolderData(newData);
    newValueBreadCrump(newData)
  }

  const handleSearch = ({ target }) => {
    setValueSearch(target.value);
  }

  return (


    <ConfigProvider colors={['#F2BBAD', '#E3F2FD', '#2FB099', '#FEF4D5']}>
      <>
        <Row>
          <Col>
            <Breadcrumb className="small">
              <Breadcrumb.Item onClick={() => history.push({ pathname: `/` })}>Inicio</Breadcrumb.Item>
              {
                root_directory.length === 0 ? (
                  <Breadcrumb.Item active>Documentos</Breadcrumb.Item>
                ) : (
                  <Breadcrumb.Item onClick={() => history.push({ pathname: `/documents_user/root` })}>Documentos</Breadcrumb.Item>
                )
              }
              {
                bradCrumpList && (
                  bradCrumpList.map((folder, n) => {
                    return (
                      <Breadcrumb.Item
                        onClick={() => {
                          console.log(folder.uuid, folder.type)
                          history.push({ pathname: `/documents_user/${folder.uuid}` });
                          handleFolder(folder.uuid, folder.type)
                          handleBreadCrump(bradCrumpList.slice(0, n + 1))
                        }}
                      /* active={n === bradCrumpList.length - 1}*/
                      >
                        {folder.name}
                      </Breadcrumb.Item>
                    )
                  })
                )

                /*
                root_directory && (
                  root_directory.map((folder, n) => {
                    return (
                      <>
                        {
                          n === root_directory.length - 1 ? (
                            <Breadcrumb.Item active>{breadcrumb[breadcrumb.length - 1]?.name}</Breadcrumb.Item>
                          ) : (
                            <Breadcrumb.Item onClick={() => history.push({ pathname: `/documents_user/${folder.uuid}` })}>teste </Breadcrumb.Item>
                          )
                        }
                      </>
                    );
                  })
                )*/
              }
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          {
            uuid === 'root' && (
              <Col sm="4" lg="5" className="d-flex align-items-center">
                <h4 className="font-weight-bold mb-1">Documentos</h4>
              </Col>
            )
          }
          {
            root_directory && (
              uuid !== "root" && (
                <Col sm="4" lg="5" className="d-flex align-items-center">
                  <h4 className="font-weight-bold mb-1">
                    {bradCrumpList[bradCrumpList.length - 1]?.name}
                  </h4>
                </Col>
              )
            )
          }
          {
            root_directory.length !== 0 && (
              (document_list.length !== 0 || flag) && (
                <Col sm="8" lg="7" className="d-flex align-items-center justify-content-end">
                  <SearchBar handleSearch={handleSearch} />
                </Col>
              )
            )
          }
        </Row>
        {
          folderList.length === 0 && document_list.length === 0 ? (
            <div className="text-center my-5">
              <Image className="my-3" src={searchFile} />
              <h5 style={{ color: "gray" }}>Esta carpeta se encuentra vacia</h5>
            </div>
          ) : (
            <>
              <Table striped hover size="md" className='mt-4 mb-0'>
                <thead>
                  <tr className='small'>
                    <th scope="col" className='border-top-0 border-bottom-0'>Nombre</th>
                    <th scope="col" className='border-top-0 border-bottom-0 text-center'>Ult. Modificación</th>
                    <th scope="col" className='border-top-0 border-bottom-0'>Tamaño</th>
                    <th scope="col" className='border-top-0 border-bottom-0'></th>
                  </tr>
                </thead>

                <tbody>
                  <>
                    {
                      loadingTaxPanelState && (
                        <div className='ml-2 mb-4'>
                          <Button variant="primary" disabled>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Cargando...
                          </Button>
                        </div>
                      )

                    }

                    {


                      folderList && folderList.length !== 0 && !loadingList && Array.isArray(folderList) ? (
                        folderList.map((folder) => {
                          // Verificar si el nombre de la carpeta es un número entre 2017 y 2022
                          const isSpecialFolder = Number(folder.folder_name) >= 2017 && Number(folder.folder_name) <= 2023;

                          return (

                            isSpecialFolder ? (
                              <DocumentItem
                                key={folder.uuid}
                                name={folder.folder_name}
                                date={folder.modified ? folder.modified.date : undefined}
                                step={root_directory.length + 1}
                                type="taxFormYear"
                                handleFolder={handleFolder}
                                uuid={folder.uuid}
                                project_uuid={folder.project_uuid}
                              />
                            ) : folder.project ? (
                              <DocumentItem
                                key={folder.uuid}
                                name={folder.folder_name}
                                date={folder.modified ? folder.modified.date : undefined}
                                step={root_directory.length + 1}
                                type="userFolder"
                                handleFolder={handleFolder}
                                uuid={folder.uuid}
                                project_uuid={folder.project.uuid}
                              />
                            ) : folder.type === "document" ? (
                              <DocumentItem
                                key={folder.uuid}
                                file_name={folder.file_name}
                                Document={folder.Document}
                                date={folder.modified ? folder.modified.date : undefined}
                                type="taxFormYearDocument"
                                handleFolder={handleFolder}
                                uuid={folder.uuid}
                              />
                            ) : (
                              <DocumentItem
                                key={folder.uuid}
                                name={folder.folder_name}
                                date={folder.modified ? folder.modified.date : undefined}
                                step={root_directory.length + 1}
                                type="userFolder"
                                handleFolder={handleFolder}
                                uuid={folder.uuid}
                              />
                            )


                          );
                        })
                      ) : Array.isArray(folderList) ? (
                        folderList.map((folder) => (
                          <DocumentItem
                            key={folder.uuid}
                            name={folder.folder_name}
                            date={folder.modified ? folder.modified.date : undefined}
                            step={root_directory.length + 1}
                            type="userFolder"
                            handleFolder={handleFolder}
                            uuid={folder.uuid}
                          />
                        ))
                      ) : folderList && folderList.folder_list ? (
                        <>
                          {
                            folderList.folder_list.map((folder) => {
                              let type;
                              //const isSpecialFolder = Number(folder.folder_name) >= 2017 && Number(folder.folder_name) <= 2023;
                              //if (isSpecialFolder) {
                              //type = "taxFormYear";
                              /*} else */
                              if (folder.form_type === "F22" || folder.form_type === "F29" || folder.form_type === "F50") {
                                type = "taxForm";
                              } else {
                                type = "folderList";
                              }
                              return (
                                <DocumentItem
                                  key={folder.uuid}
                                  name={folder.folder_name}
                                  date={folder.modified ? folder.modified.date : undefined}
                                  step={root_directory.length + 1}
                                  type={type}
                                  handleFolder={handleFolder}
                                  uuid={folder.uuid}
                                  project_uuid={folder.project_uuid}
                                />
                              );
                            })
                          }
                        </>
                      ) : (<></>)
                    }

                    {
                      isFolderList !== 'folderList' && folderList && folderList.folder_tax_list && (
                        <>
                          {
                            folderList.folder_tax_list.map((folder) => {
                              return (
                                <DocumentItem
                                  key={folder.uuid}
                                  name={folder.folder_name}
                                  date={folder.modified ? folder.modified.date : undefined}
                                  step={root_directory.length + 1}
                                  type="tax"
                                  handleFolder={handleFolder}
                                  uuid={folder.uuid}
                                  project_uuid={folder.project_uuid}
                                />
                              );
                            })
                          }

                        </>
                      )
                    }
                    {
                      document_list && document_list.length !== 0 && (
                        document_list.map((document) => {
                          return (
                            <DocumentItem
                              key={document.uuid}
                              uuid={document.uuid}
                              name={document.document_name}
                              date={document.modified ? document.modified.date : undefined}
                              size={document.document_size}
                              step={root_directory.length + 1}
                              type="file_user"
                            />
                          )
                        })
                      )
                    }
                  </>
                </tbody>
              </Table>
            </>
          )
        }
      </>
    </ConfigProvider>
  )
}

DocumentsUser.propTypes = {
  showUserFolders: PropTypes.func.isRequired,
  folderList: PropTypes.array,
  root_directory: PropTypes.array,
  document_list: PropTypes.array,
  loadingList: PropTypes.bool
};

const stateToProps = state => ({
  userProfile: state.profile.userProfile,
  folderList: state.documents.folderList,
  root_directory: state.documents.root_directory,
  document_list: state.documents.document_list,
  loadingList: state.documents.loadingList,
  folderTaxPanelList: state.documents.document_list_tax_panel_forms,
  bradCrumpList: state.documents.breadCrump,
  loadingTaxPanelState: state.documents.loadingPanelTax
});

const actionsToProps = dispatch => ({

  showUserFolders: (payload, uuidProject) => {
    dispatch({ type: LIST_USER_FOLDERS, payload, uuidProject });
  },
  getTaxPanelForms: payload => dispatch({ type: GET_DOCUMENT_PANEL_TAX_FORMS, payload }),
  breaDcrumbFucntion: payload => dispatch({ type: PUT_BREAD_CRUMP, payload }),
  getDocumentPanelTaxFormYear: payload => dispatch({ type: GET_DOCUMENTS_TAX_PANEL, payload }),
  newValueBreadCrump: payload => dispatch({ type: NEW_VALUE_BREADCRUMP, payload })
});

export default connect(stateToProps, actionsToProps)(DocumentsUser);
