export const SET_USER_PROFILE = 'profile/SET_USER_PROFILE';
export const FETCH_USER_PROFILE = 'saga/FETCH_USER_PROFILE';
export const USER_LOADED = 'saga/USER_LOADED';
export const PATCH_PASSWORD = 'saga/PATCH_PASSWORD';
export const SET_PROFILE_ERRORS = 'profile/SET_PROFILE_ERRORS';
export const FLAG_PASSWORD_CHANGED = 'profile/FLAG_PASSWORD_CHANGED';
export const LOADING_TICKET_FORM = 'profile/LOADING_TICKET_FORM';
export const SET_TICKET_ERRORS = 'profile/SET_TICKET_ERRORS';
export const POST_TICKET = 'profile/POST_TICKET';
export const FETCH_NOTIFICATIONS = 'profile/FETCH_NOTIFICATIONS';
export const NOTIFICATIONS_DATA = 'profile/NOTIFICATIONS_DATA';
export const NOTIFICATIONS_LOADED = 'profile/NOTIFICATIONS_LOADED';
export const PATCH_IMAGE_PROFILE = 'saga/PATCH_IMAGE_PROFILE';
