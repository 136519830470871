import { takeLatest, call, put } from 'redux-saga/effects';
import cookieHandler from '../../utils/helpers/cookie.handler';
import {
  postBrowseData,
  postSessionLogout
} from '../../network';
import {
  DELETE_LOG,
  FLAG_DELETE_LOG,
  POST_LOG_OUT, POST_LOGS
} from './types';

function* postLogOutSession({ payload }) {
  try {
    yield call(postSessionLogout, payload);
    const { removeToken } = cookieHandler();
    removeToken();
    setInterval(() => {
      window.location.href = '/';
    }, 1000)
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* logDelete({ payload }) {
  if(payload)
    yield put({ type: FLAG_DELETE_LOG, payload: true });
  else{
    yield put({ type: FLAG_DELETE_LOG, payload: false });
  }
}

function* postLog({ payload }) {
  try {
    yield call(postBrowseData, payload);
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* watchPostLogOutSession() {
  yield takeLatest(POST_LOG_OUT, postLogOutSession);
}

function* watchLogDelete() {
  yield takeLatest(DELETE_LOG, logDelete);
}

function* watchPostLogs() {
  yield takeLatest(POST_LOGS, postLog);
}

const saga = [
  watchPostLogOutSession(),
  watchLogDelete(),
  watchPostLogs()
];

export default saga;
