import React from 'react'
import Dolar from './Dolar'


function IndcatorCarrousel() {
    return (
        <div id='contenedorSecondaryIndicador'>
            <Dolar />
        </div>
    )
}

export default IndcatorCarrousel