import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Check, UploadCloud, XCircle } from 'react-feather';
// import avatar from '../../images/avatar-icon.png';

const ProfilePicture = ({setLogo, logo, imgName, setAuxImage, /*profileImage,*/ uploadImage, setServiceError}) => {

  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);

  useEffect(() => {
    return () => {
      setLogo(picture);
    };
  }, [logo, picture, setLogo]);

  const onChangePicture = e => {
    if(imgName === 'image'){
      setAuxImage(true);
    }
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const deleteImg = () => {
    setImgData(null)
  }

  return (
    <Col lg={12}>
   
      {
        imgData === null ? (
          <label className=" mt-5 upload-profile-image position-relative bg-primary" id='cambiar_contraseña'>
            <input type="file" id="file" aria-label="File browser example" onChange={onChangePicture} style={{opacity: "0"}} hidden/>
            <div className="upload-file-custom-content text-center" id='cambiar_contraseña'>
              <span className="file-custom text-center font-weight-bold d-block text-white"><UploadCloud color="#FFF" className="mr-2"/>Subir imagen</span>
            </div>
          </label>
        ):(
          <Row className="ml-lg-3 d-flex justify-content-center">
            <Button
              size="lg"
              variant="success"
              className="btn"
              onClick={() =>{
                uploadImage(picture);
              }}
            >
              <Check className="mr-1"/>
              Confirmar
            </Button>
            <Button
              size="lg"
              variant="danger"
              className="btn mt-2"
              onClick={()=>{
                setImgData(null);
                setPicture(null);
                setServiceError(null);
              }}
            >
              <XCircle className="mr-1"/>
              Cancelar
            </Button>
          </Row>
        )
      }

    </Col>
  )
}

export default ProfilePicture;
