import {
  SET_DATA_LIST_GROUPS,
  IS_LOADED,
  SET_DATA_GROUP,
  IS_LOADED_GROUP,
  SET_LIST_GROUP_ADMINS,
  INDEX_PAGINATION_GROUPS,
  LOADING_GROUP_FORM,
  SET_GROUP_ERRORS,
  SET_LIST_USERS,
  FILTERED_GROUPS
} from './types';

const initialState = {
  groupsList: [],
  groupsFilteredList: [],
  groupData:null,
  loadingForm: false,
  isLoaded:false,
  isLoadedGroup: false,
  groupAdminList: [],
  groupUserList: [],
  indexPagination:0,
  errors: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case SET_DATA_LIST_GROUPS:
    return { ...state, groupsList: payload };
  case FILTERED_GROUPS:
    return { ...state, groupsFilteredList: payload };
  case SET_DATA_GROUP:
    return { ...state, groupData: payload };
  case SET_LIST_GROUP_ADMINS:
    return { ...state, groupAdminList: payload }
  case SET_LIST_USERS:
    return { ...state, groupUserList: payload }
  case LOADING_GROUP_FORM:
    return { ...state, loadingForm: payload };
  case SET_GROUP_ERRORS:
    return { ...state, errors: payload };
  case IS_LOADED:
    return { ...state, isLoaded: payload };
  case IS_LOADED_GROUP:
    return { ...state, isLoadedGroup: payload };
  case INDEX_PAGINATION_GROUPS:
    return { ...state, indexPagination: payload };
  default:
    return state;
  }
};

export default reducer;
