import React, { useEffect, useState } from 'react';
import { Breadcrumb, Col, Image, Row, Table } from 'react-bootstrap';
import { ConfigProvider } from 'react-avatar';
import SearchBar from '../search_bar';
import DocumentItem from '../document_item';
import DocumentUpload from '../document_upload';
import { connect } from 'react-redux';
import { DELETE_DOCUMENT, FETCH_LIST_DOCUMENTS, GET_DOCUMENT_PANEL_TAX_FORMS_ADMIN, PUT_BREAD_CRUMP, NEW_VALUE_BREADCRUMP } from '../../store/document/types';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import searchFile from '../../images/file-search-regular.svg';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const DocumentsAdmin = ({ showFolders, folderList, root_directory, document_list, loadingList, deleteDocumentFile, getTaxPanelForms, folderListTwo, breaDcrumbFucntion, bradCrumpList, newValueBreadCrump, loadingTaxPanelState }) => {
  const { uuid } = useParams();
  const history = useHistory();
  const [valueSearch, setValueSearch] = useState(null);
  const [flag, setFlag] = useState(false);
  const [inTaxFolder, setInTaxFolder] = useState(false)
  const [firstUuid, setFirstUuid] = useState(null);
  const [folderData, setFolderData] = useState([]);
  const [isFolderList, setIsFolderList] = useState('')

  console.log('foldetdata', folderData)
  console.log(bradCrumpList)

    useEffect(() => {
      if (!inTaxFolder) {
        if (uuid === 'root')
          showFolders('');
        else {
          if (!valueSearch)
            showFolders(`/${uuid}/`);
          else
            showFolders(`/${uuid}/?search=${valueSearch}`);
        }
      }
    }, [uuid, showFolders, valueSearch]);

  useEffect(() => {
    const handlePopState = () => {
      if (folderData.length > 1) {
        // Eliminar el último objeto del array
        const newFolderData = folderData.slice(0, -1);
        setFolderData(newFolderData);
        // Obtener los datos de la carpeta anterior
        const previousFolderData = newFolderData[newFolderData.length - 1];
        if (previousFolderData.type === "folder") {
          showFolders(`/${previousFolderData.uuid}/`);
        } else {
          getTaxPanelForms({ uuid: previousFolderData.uuid, type: previousFolderData.type });
        }

        if (previousFolderData.type !== 'folderListAdmin') {
          setIsFolderList('')
        }
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [folderData]);

  useEffect(() => {
    if (root_directory !== 0)
      setFlag(true);// eslint-disable-next-line
  }, []);

  const handleFolder = (uuid, type, name) => {
    if (type === 'taxAdmin') {
      console.log('es tax')
      breaDcrumbFucntion(({ uuid, name, type }));
      getTaxPanelForms({ uuid, type })
      setFolderData([...folderData, { uuid, type }]);

    } else if (type === 'taxFormAdmin') {
      breaDcrumbFucntion(({ uuid, name, type }));
      getTaxPanelForms({ uuid, type })
      setFolderData([...folderData, { uuid, type }]);

    } else if (type === 'taxFormYearAdmin') {
      breaDcrumbFucntion(({ uuid, name, type }));
      getTaxPanelForms({ uuid, type, name })
      setFolderData([...folderData, { uuid, type }]);
    } else if (type === 'folder') {
      console.log('es folder')
      setInTaxFolder(false)
      setFirstUuid(uuid);
      breaDcrumbFucntion(({ uuid, name, type }));
      setFolderData([...folderData, { uuid, type }]);
      showFolders(`/${uuid}/`);
    } else if (type === "folderListAdmin") {
      console.log('folder list')
      setIsFolderList(type)
      breaDcrumbFucntion(({ uuid, name, type }));
      getTaxPanelForms({ uuid, type, name })
      setFolderData([...folderData, { uuid, type }]);
    }

  }

  function handleBreadCrump(value) {
    const newData = value.map(item => {
      const { uuid, name, type } = item;
      console.log(uuid, type, name);
      return { uuid, name, type };
    });

    setFolderData(newData);
    newValueBreadCrump(newData)
  }
  const handleSearch = ({ target }) => {
    setValueSearch(target.value);
  }

  return (
    <ConfigProvider colors={['#F2BBAD', '#E3F2FD', '#2FB099', '#FEF4D5']}>
      <>
        <Row>
          <Col className='p-0'>
            <Breadcrumb className="small primario">
              <Breadcrumb.Item onClick={() => history.push({ pathname: `/` })}>Inicio</Breadcrumb.Item>
              {
                root_directory.length === 0 ? (
                  <Breadcrumb.Item active>Administración de documentos</Breadcrumb.Item>
                ) : (
                  <Breadcrumb.Item onClick={() => history.push({ pathname: `/admin/documents/root` })}>Administración de documentos</Breadcrumb.Item>
                )
              }
              {
                bradCrumpList && (
                  bradCrumpList.map((folder, n) => {
                    return (
                      <Breadcrumb.Item
                        onClick={() => {
                          history.push({ pathname: `/admin/documents/${folder.uuid}` });
                          // Aquí actualizamos bradCrumpList para que solo contenga los elementos hasta el índice actual
                          handleFolder(folder.uuid, folder.type)
                          handleBreadCrump(bradCrumpList.slice(0, n + 1))
                        }}
                      /* active={n === bradCrumpList.length - 1}*/
                      >
                        {folder.name}
                      </Breadcrumb.Item>
                    )
                  })
                )
                /* root_directory && (
                   root_directory.map((folder, n) => {
                     return (
                       <>
                         {
                           n === root_directory.length - 1 ? (
                             <Breadcrumb.Item active>{folder.folder_name}</Breadcrumb.Item>
                           ) : (
                             <Breadcrumb.Item onClick={() => history.push({ pathname: `/admin/documents/${folder.uuid}` })}>{folder.folder_name}</Breadcrumb.Item>
                           )
                         }
                       </>
                     );
                   })
                 )*/
              }
            </Breadcrumb>
          </Col>
        </Row>

        <Row>
          {
            uuid === 'root' && (
              <Col sm="12" className="d-flex align-items-center">
                <h4 className="font-weight-bold mb-1 mr-2">Documentos</h4>
                <p className='mb-0 small text-muted'>(Gestión de documentos)</p>
              </Col>
            )
          }
          {
            bradCrumpList && (
              uuid !== "root" && bradCrumpList.length !== 0 && (
                <Col sm="4" lg="5">
                  {
                    bradCrumpList.length === 1 && (
                      <Col className="p-0">
                        <p className="m-0">Proyecto:</p>
                      </Col>
                    )
                  }
                  {
                    bradCrumpList.length === 2 && (
                      <Col className="p-0">
                        <p className="m-0">Grupo:</p>
                      </Col>
                    )
                  }
                  {
                    bradCrumpList.length === 3 && (
                      <Col className="p-0">
                        <p className="m-0">Sociedad:</p>
                      </Col>
                    )
                  }
                  {
                    bradCrumpList.length === 4 && (
                      <Col className="p-0">
                        <p className="m-0">Carpeta:</p>
                      </Col>
                    )
                  }
                  <Col>
                    <h4 className="font-weight-bold mb-1 d-flex align-items-center">
                      {bradCrumpList[bradCrumpList.length - 1]?.name}
                      {/*root_directory[root_directory.length - 1].folder_name*/}
                    </h4>
                  </Col>

                </Col>
              )
            )

          }
          <Col sm="8" lg="7" className="d-flex align-items-center justify-content-end">
            {
              root_directory.length + 1 !== 1 && (
                root_directory.length + 1 >= 2 && root_directory.length + 1 <= 4 ? (
                  <Row>
                    {
                      (document_list.length !== 0 || flag) && (
                        <Col>
                          <SearchBar handleSearch={handleSearch} />
                        </Col>
                      )
                    }
                  </Row>
                ) : (
                  <Row>
                    {
                      (document_list.length !== 0 || flag) && (
                        <Col sm='6' lg='6'>
                          <SearchBar handleSearch={handleSearch} />
                        </Col>
                      )
                    }
                    <Col sm="6" lg="6">
                      <DocumentUpload uuid={uuid} />
                    </Col>
                  </Row>
                )
              )
            }
          </Col>
        </Row>
        {
          folderList.length === 0 && document_list.length === 0 ? (
            <div className="text-center my-5">
              <Image className="my-3" src={searchFile} />
              <h5 style={{ color: "gray" }}>Esta carpeta se encuentra vacia</h5>
            </div>
          ) : (
            <>
              <Table striped hover size="md" className='mt-4 mb-0'>
                <thead>
                  <tr className='small'>
                    <th scope="col" className='border-top-0 border-bottom-0'>Nombre</th>
                    <th scope="col" className='border-top-0 border-bottom-0 text-center'>Ult. Modificación</th>
                    <th scope="col" className='border-top-0 border-bottom-0'>Tamaño</th>
                    <th scope="col" className='border-top-0 border-bottom-0'></th>
                  </tr>
                </thead>

                <tbody>
                  <>
                  {
                      loadingTaxPanelState && (
                        <div className='ml-2 mb-4'>
                          <Button variant="primary" disabled>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Cargando...
                          </Button>
                        </div>
                      )

                    }

                    {
                      folderList && folderList.length !== 0 && !loadingList && Array.isArray(folderList) ? (
                        folderList.map((folder) => {
                          const isSpecialFolder = Number(folder.folder_name) >= 2017 && Number(folder.folder_name) <= 2023;

                          return (
                            folder.project ? (
                              <DocumentItem
                                key={folder.uuid}
                                name={folder.folder_name}
                                date={folder.modified.date}
                                size="42 MB"
                                step={root_directory.length + 1}
                                type={isSpecialFolder ? "taxFormYearAdmin" : "folder"}
                                handleFolder={handleFolder}
                                uuid={folder.uuid}
                                project_uuid={folder.project.uuid}
                              />
                            ) :
                              folder.type === "document" ? (
                                <DocumentItem
                                  key={folder.uuid}
                                  file_name={folder.file_name}
                                  Document={folder.Document}
                                  date={folder.modified ? folder.modified.date : undefined}
                                  type="taxFormYearAdminDocument"
                                  handleFolder={handleFolder}
                                  uuid={folder.uuid}
                                />

                              ) : (
                                <DocumentItem
                                  key={folder.uuid}
                                  name={folder.folder_name}
                                  date={folder.modified.date}
                                  size="42 MB"
                                  step={root_directory.length + 1}
                                  type={isSpecialFolder ? "taxFormYearAdmin" : "folder"}
                                  handleFolder={handleFolder}
                                  uuid={folder.uuid}
                                />
                              )
                          )
                        })
                      ) : folderList && folderList.folder_list ? (
                        <>
                          {
                            folderList.folder_list.map((folder) => {
                              let type;
                              if (folder.form_type === "F22" || folder.form_type === "F29" || folder.form_type === "F50") {
                                type = "taxFormAdmin";
                              } else {
                                type = "folderListAdmin";
                              }
                              return (
                                <DocumentItem
                                  key={folder.uuid}
                                  name={folder.folder_name}
                                  date={folder.modified ? folder.modified.date : undefined}
                                  step={root_directory.length + 1}
                                  type={type}
                                  handleFolder={handleFolder}
                                  uuid={folder.uuid}
                                  project_uuid={folder.project_uuid}
                                />
                              );
                            })
                          }
                        </>
                      ) : (<></>)
                    }

                    {
                      isFolderList !== 'folderListAdmin' && folderList && folderList.folder_tax_list && folderList.folder_tax_list.map((folder) => {
                        return (
                          <DocumentItem
                            key={folder.uuid}
                            name={folder.folder_name}
                            date={folder.modified.date}
                            size="42 MB"
                            step={root_directory.length + 1}
                            type="taxAdmin"
                            handleFolder={handleFolder}
                            uuid={folder.uuid}
                          />
                        )

                      })
                    }

                    {
                      document_list && document_list.length !== 0 && (
                        document_list.map((document) => {
                          return (
                            <DocumentItem
                              key={document.uuid}
                              uuid={document.uuid}
                              name={document.document_name}
                              date={document.modified.date}
                              size={document.document_size}
                              step={root_directory.length + 1}
                              type="file"
                              deleteDocumentFile={deleteDocumentFile}
                            />
                          )
                        })
                      )
                    }
                  </>
                </tbody>
              </Table>
            </>
          )
        }
      </>
    </ConfigProvider>
  )
}

DocumentsAdmin.propTypes = {
  showFolders: PropTypes.func.isRequired,
  folderList: PropTypes.array,
  root_directory: PropTypes.array,
  document_list: PropTypes.array,
  loadingList: PropTypes.bool,
  deleteDocumentFile: PropTypes.func.isRequired
};

const stateToProps = state => ({
  folderList: state.documents.folderList,
  root_directory: state.documents.root_directory,
  document_list: state.documents.document_list,
  loadingList: state.documents.loadingList,
  folderListTwo: state.documents.document_list_tax_panel_forms,
  bradCrumpList: state.documents.breadCrump,
  loadingTaxPanelState: state.documents.loadingPanelTax
});

const actionsToProps = dispatch => ({
  showFolders: payload => dispatch({ type: FETCH_LIST_DOCUMENTS, payload }),
  deleteDocumentFile: payload => dispatch({ type: DELETE_DOCUMENT, payload }),
  breaDcrumbFucntion: payload => dispatch({ type: PUT_BREAD_CRUMP, payload }),
  getTaxPanelForms: payload => dispatch({ type: GET_DOCUMENT_PANEL_TAX_FORMS_ADMIN, payload }),
  newValueBreadCrump: payload => dispatch({ type: NEW_VALUE_BREADCRUMP, payload })
});

export default connect(stateToProps, actionsToProps)(DocumentsAdmin);
