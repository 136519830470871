import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Form, FormControl, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import validator from '../../utils/validator';
import { registerSchema } from '../../utils/validator/schemas';
import { SET_AUTH_CHECKING, SET_AUTH_ERRORS, POST_REGISTER, GET_INVITATION_TOKEN } from '../../store/types';

const Register = ({
  postRegister,
  cleanAuthChecking,
  cleanAuthErrors,
  validateToken,
  tokenConfirmed,
  loadingForm,
  errors,
  loadingData
}) => {
  const { token } = useParams();
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [validationError, setValidationError] = useState({});
  const [registerForm, setRegisterForm] = useState({
    first_name: '',
    last_name: '',
    password: '',
    password_confirmation: '',
    token: token
  });
  const [flag, setFlag] = useState(false);
  const [serviceError, setServiceError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    validateToken(token)
  }, [validateToken, token]);

  useEffect(() => {
    if(flag){
      if (!loadingForm && errors !== null) {
        setServiceError(errors);
      }
      if (loadingForm === false && errors === null) {
        setFlag(false);
      }
    }
    if(!loadingForm && !tokenConfirmed && errors === "Error token"){
      history.replace('/expiredToken');
    }

    return () => {
      //cleanAuthErrors();
      cleanAuthChecking();
    };
  }, [cleanAuthErrors, cleanAuthChecking, history, errors, flag, loadingForm, tokenConfirmed]);

  useEffect(() => {
    if (
      Object.keys(validationError).length ||
      Object.keys(registerForm).some(key => registerForm[key] === '')
    ) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [validationError, registerForm]);

  const handleSubmit = event => {
    event.preventDefault();
    setServiceError(null);
    postRegister(registerForm);
    setServiceError(errors);
    setFlag(true);
  };

  const handleInputChange = ({ target }) => {
    const formData = { ...registerForm, [target.name]: target.value };
    if(target.name === "rut_user" && target.value === "") {
      delete formData.rut_user;
    }
    setRegisterForm(formData);
    delete validationError[target.name];
    setValidationError(validationError);
    const errors = validator({ [target.name]: registerSchema[target.name] }, formData);
    if (errors.length > 0) {
      const messages = errors
        .map(e => ({ [e.field]: e.message }))
        .reduce((prev, current) => ({ ...prev, ...current }), {});
      setValidationError({ ...validationError, ...messages });
      return false;
    }
  };

  useEffect(() => {
    setServiceError(errors);
  }, [errors]);

  const alert = () => {
    if (serviceError) {
      return (
        serviceError.map((error) => {
          return (<div key={error} className="text-danger">
            {error !== undefined ? (
              `-${error}`
            ) : (
              ''
            )}
          </div>);
        })
      );
    }
  };

  if (tokenConfirmed === true) {
    return (
      <>
        <h2 className="py-5 font-weight-bold">
          Regístrate en Magnus
        </h2>
        <Form noValidate onSubmit={handleSubmit} className="pr-lg-4 mb-5">
          <Form.Group controlId="first_name">
            <Form.Label className="font-weight-bold small">Nombre</Form.Label>
            <FormControl
              name="first_name"
              type="first_name"
              placeholder="Ingresa tu nombre"
              autoComplete="name"
              onChange={handleInputChange}
              isInvalid={validationError.first_name}
              size="lg"
            />
            <Form.Control.Feedback type="invalid">
              {validationError.first_name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="last_name">
            <Form.Label className="font-weight-bold small">Apellidos</Form.Label>
            <FormControl
              name="last_name"
              type="lastName"
              placeholder="Ingresa tus apellidos"
              autoComplete="lastName"
              onChange={handleInputChange}
              isInvalid={validationError.last_name}
              size="lg"
            />
            <Form.Control.Feedback type="invalid">
              {validationError.last_name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="rut_user">
            <Form.Label className="font-weight-bold small">RUT (Ej: 11111111-1)</Form.Label>
            <FormControl
              name="rut_user"
              type="rut"
              placeholder="Ingresa tu RUT"
              autoComplete="lastName"
              onChange={handleInputChange}
              isInvalid={validationError.rut_user}
              size="lg"
            />
            <Form.Control.Feedback type="invalid">
              {validationError.rut_user}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="phone_number">
            <Form.Label className="font-weight-bold small">Teléfono</Form.Label>
            <FormControl
              name="phone_number"
              type="phone"
              placeholder="EJ: +56988888888"
              autoComplete="lastName"
              onChange={handleInputChange}
              isInvalid={validationError.phone_number}
              size="lg"
            />
            <Form.Control.Feedback type="invalid">
              {validationError.phone_number}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="password" className="position-relative">
            <Form.Label className="font-weight-bold small">Contraseña</Form.Label>
            <FormControl
              name="password"
              type={'password'}
              placeholder="Ingresa tu contraseña"
              autoComplete="current-password"
              onChange={handleInputChange}
              isInvalid={validationError.password}
              size="lg"
              className="mb-2"
            />
            <Form.Control.Feedback type="invalid">
              {validationError.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="password_confirmation" className="position-relative">
            <Form.Label className="font-weight-bold small">Repita su contraseña</Form.Label>
            <FormControl
              name="password_confirmation"
              type={'password'}
              placeholder="Repite tu contraseña"
              autoComplete="current-password"
              onChange={handleInputChange}
              isInvalid={validationError.password_confirmation}
              size="lg"
              className="mb-2"
            />
            <Form.Control.Feedback type="invalid">
              {validationError.password_confirmation}
            </Form.Control.Feedback>
          </Form.Group>
          { serviceError &&(
            <div className="alert alert-danger my-3 mx-auto" role="alert">
              {alert()}
            </div>
            )
          }
          <Button
            className="btn-block font-weight-bold"
            variant="primary"
            type="submit"
            size="lg"
            disabled={disableSubmit || loadingData || loadingForm}
            onClick={handleSubmit}
          >
            {
              loadingData && (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              )
            }{' '}
            Finalizar registro
          </Button>
        </Form>
      </>
    );}else{
    return (
      <div className="spinner-container text-center py-5">
        <Spinner animation="border" className="spinner-custom" />
      </div>
    );
  }
};

Register.propTypes = {
  loadingForm: PropTypes.bool,
  errors: PropTypes.array,
  postRegister: PropTypes.func.isRequired,
  cleanAuthChecking: PropTypes.func.isRequired,
  cleanAuthErrors: PropTypes.func.isRequired,
  validateToken: PropTypes.func.isRequired
};

const stateToProps = state => ({
  loadingForm: state.auth.loadingForm,
  errors: state.auth.errors,
  tokenConfirmed: state.auth.tokenConfirmed,
  loadingData: state.auth.loadingData
});

const actionsToProps = dispatch => ({
  postRegister: payload => dispatch({ type: POST_REGISTER, payload }),
  cleanAuthChecking: () => dispatch({ type: SET_AUTH_CHECKING, payload: false }),
  cleanAuthErrors: () => dispatch({ type: SET_AUTH_ERRORS, payload: null }),
  validateToken: payload => dispatch({ type: GET_INVITATION_TOKEN, payload })
});

export default connect(stateToProps, actionsToProps)(Register);
