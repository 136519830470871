import {
  FLAG_PASSWORD_CHANGED,
  LOADING_TICKET_FORM, NOTIFICATIONS_DATA, NOTIFICATIONS_LOADED,
  SET_PROFILE_ERRORS,
  SET_TICKET_ERRORS,
  SET_USER_PROFILE,
  USER_LOADED
} from './types';

const initialState = {
  userProfile: {},
  userLoaded: false,
  errors: [],
  passChanged: false,
  loadingTicket: false,
  ticketErrors: [],
  notifications:[],
  notificationLoaded: false
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case SET_USER_PROFILE:
    return { ...state, userProfile: payload };
  case USER_LOADED:
    return { ...state, userLoaded: payload };
  case SET_PROFILE_ERRORS:
    return { ...state, errors: payload };
  case FLAG_PASSWORD_CHANGED:
    return { ...state, passChanged: payload };
  case SET_TICKET_ERRORS:
    return { ...state, ticketErrors: payload };
  case LOADING_TICKET_FORM:
    return { ...state, loadingTicket: payload };
  case NOTIFICATIONS_DATA:
    return { ...state, notifications: payload };
  case NOTIFICATIONS_LOADED:
    return { ...state, notificationLoaded: payload };
  default:
    return state;
  }
};

export default reducer;
