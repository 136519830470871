const services = () => ({
  loginURI: 'api/v1/token/',
  refreshURI: 'api/v1/token/refresh/',
  userProfileURI: '/api/v1/users/info/',
  userProfileDataURI: '/api/v1/users/user-profile/',
  projectsURI: '/api/v1/projects/project/',
  projectsPdfURI: '/api/v1/projects/detail/pdf/',
  projectSearchURI: '/api/v1/projects/search/',
  groupURI: '/api/v1/groups/group/',
  groupAdminURI: '/api/v1/groups/admin-available/',
  groupsPdfURI: '/api/v1/groups/detail/pdf/',
  groupSearchURI: '/api/v1/groups/search/',
  societiesURI: '/api/v1/societies/society/',
  societiesAdminURI: '/api/v1/societies/society/admin-available/',
  societiesPdfURI: '/api/v1/societies/detail/pdf/',
  societySearchURI: '/api/v1/societies/search/',
  userSocietiesURI: '/api/v1/societies/user/list/',
  usersURI: '/api/v1/users/user/',
  userSearchURI: '/api/v1/users/search/',
  invitationURI: '/api/v1/register/generate-invitation/',
  invitationTokenURI: '/api/v1/register/invitation/',
  importCsvURI: '/api/v1/register/load-register/csv/',
  reportsURI: '/api/v1/modules/reports/report/',
  reportListUserURI: '/api/v1/modules/module/get-module-report/',
  reportUserURI: '/api/v1/modules/reports/get-report/',
  modulesAdminURI: '/api/v1/modules/module/administration/',
  resetPasswordURI: '/api/v1/users/password/reset/',
  updatePasswordURI: '/api/v1/users/password/update/',
  reportSearchURI: '/api/v1/modules/reports/search/',
  invitationsURI: '/api/v1/register/invitations/',
  invitationsSearchURI: '/api/v1/register/invitations-search/',
  folderListURI: '/api/v1/folders/list',
  folderTemplateListURI: '/api/v1/folder-templates/list',
  folderTemplateURI: '/api/v1/folder-templates/folder/',
  documentURI: '/api/v1/documents/document/',
  userFolderListURI: '/api/v1/folders/user/list',
  shareTokenURI: '/api/v1/documents/share/token/',
  sharedTokenRouteURI: '/api/v1/documents/share/download/',
  personalizationURI: '/api/v1/personalization/login/',
  accountingURI: '/api/v1/modules/accounting/report/',
  accountingSearchURI: '/api/v1/modules/accounting/search/',
  accountingUserListURI: '/api/v1/modules/module/get-module-accounting/',
  balance8columnsURI: '/api/v1/modules/module/balance8columns/report/',
  balance8columnsDetailURI: '/api/v1/modules/module/balance8columns/detail/',
  balance8columnsDatesURI: '/api/v1/modules/module/balance8columns/available-dates/',
  cashFlowDatesURI:'/api/v1/modules/module/cashflow/available-dates/',
  cashFlowURI:'/api/v1/modules/module/cashflow/report/',
  financialSituationDatesURI:'/api/v1/modules/module/financial-situation/available-dates/',
  financialSituationDataURI:'/api/v1/modules/module/financial-situation/report/',
  financialSituationDetail: '/api/v1/modules/module/financial-situation/detail/',
  accountingDetailURI: '/api/v1/modules/module/financial-situation/account-detail/',
  logBrowserURI: '/api/v1/logs/browsing-log/',
  logoutURI: '/api/v1/logs/session-log/logout/',
  industryURI: '/api/v1/societies/industry-type/',
  statusResultsDatesURI: '/api/v1/modules/module/status-results/available-dates/',
  statusResultsURI: '/api/v1/modules/module/status-results/report/',
  statusResultsDetailURI: '/api/v1/modules/module/status-results/detail/',
  accountStatusDetailURI: '/api/v1/modules/module/status-results/account-detail/',
  zohoURI: '/api/v1/external/zoho/desk/ticket/',
  contactURI: '/api/v1/external/contact/email/',
  notificationURI: '/api/v1/documents/notification/',
  projectAdminsListURI: '/api/v1/projects/admin-available/',
  groupUsersList: '/api/v1/groups/user-available/',
  societyUsersList: '/api/v1/societies/user-available/',
  userProjectsURI: '/api/v1/projects/user/project/',
  userGroupsURI: '/api/v1/groups/user/group/',
  userSocietyURI: '/api/v1/societies/user/society/',
  documentTaxPanelForms: '/api/v1/folders-tax/user/list/',
  documentTaxPanelFormsAdmin: '/api/v1/folders-tax/list/',
  reportRef: '/api/v1/modules/reportUsuario/report-usuario/'
});

export default services;
