export const SET_DATA_GROUP = 'group/SET_DATA_GROUP';
export const FETCH_GROUP = 'group/FETCH_GROUP';
export const SET_DATA_LIST_GROUPS = 'group/SET_DATA_LIST_GROUPS';
export const FETCH_LIST_GROUPS = 'group/FETCH_LIST_GROUPS';
export const IS_LOADED = 'group/IS_LOADED';
export const IS_LOADED_GROUP = 'group/IS_LOADED_GROUP';
export const POST_GROUPS = 'group/POST_GROUPS';
export const PATCH_GROUP = 'group/PATCH_GROUP';
export const FETCH_GROUP_ADMINS = 'group/FETCH_GROUPS_ADMINS';
export const SET_LIST_GROUP_ADMINS = 'group/SET_LIST_GROUP_ADMINS';
export const DELETE_GROUP = 'group/DELETE_GROUP';
export const FETCH_PDF = 'group/FETCH_PDF';
export const INDEX_PAGINATION_GROUPS = 'group/INDEX_PAGINATION_GROUPS';
export const LOADING_GROUP_FORM = 'group/LOADING_GROUP_FORM';
export const SET_GROUP_ERRORS = 'group/SET_GROUP_ERRORS';
export const SET_LIST_USERS = 'group/SET_GROUP_ERRORS';
export const FETCH_GROUP_USERS = 'group/FETCH_GROUP_USERS';
export const FETCH_USERS_GROUP = 'group/FETCH_USERS_GROUP';
export const FILTERED_GROUPS = 'group/FILTERED_GROUPS';
