import React from 'react';
import ExpiredToken from '../../components/expiredToken';

const ExpiredTokenView= () => {
  return (
    <div className="bg-light vh-100">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-lg-6 mx-auto">
            <ExpiredToken/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpiredTokenView;
