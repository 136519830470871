import React, { useEffect, useState } from 'react';
import {
  Row,
  Breadcrumb,
  Col,
  Button,
  Form,
  FormGroup,
  Dropdown,
  Spinner,
  Accordion,
  Card,
  FormControl
} from 'react-bootstrap';
import { ChevronRight, CheckCircle } from 'react-feather'
import { HexColorPicker } from "react-colorful";
import { Prompt, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_PROJECT, FETCH_PROJECT_ADMINS, PATCH_PROJECT } from '../../store/project/types';
import validator from '../../utils/validator';
import { projectSchema } from '../../utils/validator/schemas';
import ImageUploadEdit from '../image_upload/imageEdit';

const EditProject = ({patchProject, showProject, projectData, isLoadedProject, showProjectAdmins, projectAdminList, loadingForm, errors}) => {
  const [step, setStep] = React.useState(0);
  const [color, setColor] = React.useState("#aabbcc");
  const [colorSecondary, setColorSecondary] = React.useState("#aabbcc");
  const [logo, setLogo] = useState([]);
  const [image, setImage] = useState([]);
  const [icon, setIcon] = useState([]);
  const { uuid } = useParams();
  const [validationError, setValidationError] = useState({});
  const [auxImage, setAuxImage] = useState(false);
  const [auxLogo, setAuxLogo] = useState(false);
  const [auxIcon, setAuxIcon] = useState(false);
  const [projectFormOne, setProjectFormOne] = useState({
    admin: []
  });
  const [projectFormTwo, setProjectFormTwo] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [triggerForm, setTriggerForm] = useState(false);
  const [projectForm, setProjectForm] = useState('');
  const [promptAux, setPromptAux] = useState(true);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [serviceError, setServiceError] = useState(null);
  const [valueSearch, setValueSearch] = useState("");
  const [auxProjectSelected, setAuxProjectSelected] = useState([]);

  useEffect(() => {
    window.onbeforeunload = (event) => {
      if(triggerForm === true){
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
      }
    };
  },);

  useEffect(() => {
    if(projectData){
      const array = [];
      if(projectData.admin.length !== 0) {
        for(let i=0; i<projectData.admin.length; i++){
          array.push(projectData.admin[i].uuid);
        }
        setAuxProjectSelected(array);
      }
    }
  }, [projectData, setAuxProjectSelected]);

  useEffect(() => {
    showProject(uuid);
  }, [showProject, uuid]);

  useEffect(() => {
    showProjectAdmins(valueSearch);
  }, [showProjectAdmins, valueSearch]);

  useEffect(() => {
    if(projectData){
      setColor(projectData.primary_color);
      setColorSecondary(projectData.secondary_color);
      if(projectData !== null){
        setProjectForm({
          project_name: projectData.project_name,
          client_name: projectData.client_name,
          associated_service_description: projectData.associated_service_description,
          service_description: projectData.service_description,
          admin: projectData.admin,
          meta_title: projectData.meta_title,
          meta_content: projectData.meta_content,
          url_own_site: projectData.url_own_site,
          custom_portal_url: projectData.custom_portal_url,
          header_title: projectData.header_title,
          footer_disclaimer: projectData.footer_disclaimer,
          contact_email: projectData.contact_email,
          contact_phone: projectData.contact_phone,
          primary_color:projectData.primary_color,
          secondary_color: projectData.secondary_color
        })
      }
    }
  }, [projectData, isLoadedProject]);

  useEffect(() => {
    if(step === 0){
      if (
        Object.keys(projectFormOne).some(key => projectFormOne[key] === '')
      ) {
        setDisableSubmit(true);
      } else {
        setDisableSubmit(false);
      }
    }
    if(step === 1){
      if (
        Object.keys(validationError).length ||
        Object.keys(projectFormTwo).some(key => projectFormTwo[key] === '')
      ) {
        setDisableSubmit(true);
      } else {
        setDisableSubmit(false);
      }
    }
  }, [validationError, projectFormOne, projectFormTwo, step]);

  const handleInputChange = ({ target }) => {
    if(target.name === "primary_color")
      setColor(target.value);
    if(target.name === "secondary_color")
      setColorSecondary(target.value);
    setProjectForm({ ...projectForm, [target.name]: target.value });
    if(!triggerForm){
      setTriggerForm(true)
    }
    if(step === 0){
      if(target.id === "adminsCheckbox"){
        const formData = auxProjectSelected;
        let newAdmins = projectFormOne.admin;
        if(formData.includes(target.value)){
          const pos = formData.indexOf(target.value);
          formData.splice(pos,1);
          const auxForm = auxProjectSelected;
          auxForm.splice(pos,1);
          if(newAdmins.includes(target.value)){
            newAdmins.splice(newAdmins.indexOf(target.value),1);
          }else{
            newAdmins.push(target.value);
            setProjectFormOne({ ...projectFormOne, admin: newAdmins });
          }
          setAuxProjectSelected(auxForm);
        }else{
          formData.push(target.value);
          if(newAdmins.includes(target.value)){
            newAdmins.splice(newAdmins.indexOf(target.value),1);
          }else{
            newAdmins.push(target.value);
            setProjectFormOne({ ...projectFormOne, admin: newAdmins });
          }
          const aux = {name:target.name, value:target.value}
          auxProjectSelected.push(aux);
        }
      }else{
        let formData = { ...projectFormOne, [target.name]: target.value };
        setProjectFormOne(formData);
      }
    }
    if(step === 1){
      let formData = { ...projectFormTwo, [target.name]: target.value };
      setProjectFormTwo(formData);
      if(target.name === "url_own_site" ||
        target.name === "custom_portal_url" ||
        target.name === "contact_email" ||
        target.name === "contact_phone"){
        setValidationError({ ...validationError, [target.name]: '' });
        const errors = validator({ [target.name]: projectSchema[target.name] }, formData);
        if (errors.length > 0) {
          const messages = errors
            .map(e => ({...validationError, [e.field]: e.message }))
            .reduce((prev, current) => ({ ...prev, ...current }), {});
          setValidationError(messages);
          return false;
        }
        delete validationError[target.name];
        setValidationError(validationError);
      }
    }
  };

  const onChange = nextStep => {
    setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
  };
  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);

  const sendData = () => {
    setPromptAux(false);
    setTriggerForm(false);
    setServiceError(null);
    setLoading(true);
    const projectForm = ({ ...projectFormOne, ...projectFormTwo })
    const formData = new FormData();
    const arrKeys = Object.keys(projectForm);
    const arrValues = Object.values(projectForm);
    let i = 0;

    arrKeys.forEach( o => {
      formData.append(o, arrValues[i] );
      i++;
    })
    if(auxImage){
      formData.append('image', image );
    }
    if(auxLogo){
      formData.append('logo', logo );
    }
    if(auxIcon){
      formData.append('icon', icon );
    }
    formData.append('primary_color', color );
    formData.append('secondary_color', colorSecondary );

    patchProject({ formData, uuid });
    setServiceError(errors);
    setFlag(true);
  }

  useEffect(() => {
    setServiceError(null);
  }, [errors]);

  useEffect(() => {
    if(flag){
      if (!loadingForm && errors !== null) {
        setServiceError(errors);
        setLoading(false);
      }
      if (loadingForm === false && errors === null) {
        setStep(step + 1);
        setFlag(false);
      }
    }
  }, [loadingForm, errors, flag, step]);

  const alert = () => {
    if (serviceError) {
      return (
        serviceError.map((error) => {
          return (<div key={error}>
            {error !== undefined ? (
              `-${error}`
            ) : (
              ''
            )}
          </div>);
        })
      );
    }
  };

  const handleSearch = ({ target }) => {
    setValueSearch(target.value);
  }

  return (
    isLoadedProject === true ?(
      <Form>
        <Row className="mb-4">
          <Prompt
            when={triggerForm && promptAux}
            message={`¿Estás seguro de desear abandonar este formulario? Los datos ingresados no serán guardados.`}
          />
          <Col>
            <Breadcrumb className="small">
              <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
              <Breadcrumb.Item href="/admin/projects">
                Administración proyectos
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Editar proyecto</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md="8" lg="6" className="mx-auto">
            <div className="steps d-flex justify-content-between align-items-center">
              <div className={`step-item ${step === 0 ? 'active' : ''}`}>
                <span className="step-item-indicator mr-3 small">1</span>
                <h6 className="step-item-title mb-0 small">Datos principales</h6>
              </div>
              <ChevronRight size="12" />
              <div className={`step-item ${step === 1 ? 'active' : ''}`}>
                <span className="step-item-indicator mr-3 small">2</span>
                <h6 className="step-item-title mb-0 small">Login</h6>
              </div>
              <ChevronRight size="12" />
              <div className={`step-item ${step === 2 ? 'active' : ''}`}>
                <span className="step-item-indicator mr-3 small">3</span>
                <h6 className="step-item-title mb-0 small">Diseño</h6>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md="8" lg="6" className="mx-auto">
            {step === 0 && (
              <>
                <h5 className="font-weight-bold mb-4">Datos principales</h5>
                <FormGroup controlId="project_name">
                  <Form.Label className="font-weight-bold small">
                    Nombre del proyecto
                    <span className="ml-1 text-danger small">
                      (*)
                    </span>
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    placeholder="Ingresa un nombre"
                    name="project_name"
                    type="project_name"
                    defaultValue={projectForm.project_name}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup controlId="client_name">
                  <Form.Label className="font-weight-bold small">
                    Nombre de cliente
                    <span className="ml-1 text-danger small">
                      (*)
                    </span>
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    placeholder="Ingresa un nombre"
                    name="client_name"
                    type="client_name"
                    defaultValue={projectForm.client_name}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup controlId="associated_service_description">
                  <Form.Label className="font-weight-bold small">
                    Descripción del servicio asociado
                    <span className="ml-1 text-danger small">
                      (*)
                    </span>
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    placeholder="Ingresa una descripción"
                    name="associated_service_description"
                    type="associated_service_description"
                    defaultValue={projectForm.associated_service_description}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup controlId="service_description">
                  <Form.Label className="font-weight-bold small">
                    Descripción del servicio
                    <span className="ml-1 text-danger small">
                      (*)
                    </span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    size="lg"
                    placeholder="Ingresa una descripción"
                    name="service_description"
                    type="service_description"
                    defaultValue={projectForm.service_description}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                {
                  (projectAdminList.length !== 0 || valueSearch !== "") && (
                    <>
                      <Form.Label className="font-weight-bold small">
                        Designar Administrador
                      </Form.Label>
                      <Accordion defaultActiveKey="1" >
                        <Card>
                          <Card.Header className="bg-transparent text-muted">
                            Seleccione
                          </Card.Header>
                          <Card.Body eventKey="1">
                            <Card.Body className="py-0">
                              <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                                <FormControl
                                  className='mb-2'
                                  size='md'
                                  placeholder='Buscar...'
                                  onChange={handleSearch}
                                />
                              </FormGroup>
                              <Form.Group controlId="adminsCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                                {
                                  projectAdminList.map( (admin) =>{
                                    return (
                                      <Form.Check type="checkbox" key={admin.uuid} label={`${admin.first_name} ${admin.last_name}`} name={`${admin.first_name} ${admin.last_name}`} value={admin.uuid} defaultChecked={auxProjectSelected.includes(admin.uuid)}/>
                                    );
                                  })
                                }
                              </Form.Group>
                            </Card.Body>
                          </Card.Body>
                        </Card>
                      </Accordion>
                    </>
                  )
                }
                <span className="text-danger">
                  (*)
                  <span className="text-black-50">
                    Campos obligatorios
                  </span>
                </span>
              </>
            )}
            {step === 1 && (
              <>
                <h5 className="font-weight-bold mb-4">Login</h5>
                <FormGroup controlId="meta_title">
                  <Form.Label className="font-weight-bold small">
                    Meta title
                    <span className="ml-1 text-danger small">
                      (*)
                    </span>
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    placeholder="Ingresa un título"
                    name="meta_title"
                    type="meta_title"
                    defaultValue={projectForm.meta_title}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup controlId="meta_content">
                  <Form.Label className="font-weight-bold small">
                    Meta content
                    <span className="ml-1 text-danger small">
                      (*)
                    </span>
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    placeholder="Ingresa un contenido"
                    name="meta_content"
                    type="meta_content"
                    defaultValue={projectForm.meta_content}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup controlId="url_own_site">
                  <Form.Label className="font-weight-bold small">Url sitio proyecto</Form.Label>
                  <Form.Control
                    size="lg"
                    placeholder="Ingresa una url"
                    name="url_own_site"
                    type="url_own_site"
                    defaultValue={projectForm.url_own_site}
                    onChange={handleInputChange}
                    isInvalid={validationError.url_own_site}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationError.url_own_site}
                  </Form.Control.Feedback>
                </FormGroup>
                <FormGroup controlId="custom_portal_url">
                  <Form.Label className="font-weight-bold small">Url sitio custom portal</Form.Label>
                  <Form.Control
                    size="lg"
                    placeholder="Ingresa una url"
                    name="custom_portal_url"
                    type="custom_portal_url"
                    defaultValue={projectForm.custom_portal_url}
                    onChange={handleInputChange}
                    isInvalid={validationError.custom_portal_url}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationError.custom_portal_url}
                  </Form.Control.Feedback>
                </FormGroup>
                <FormGroup controlId="header_title">
                  <Form.Label className="font-weight-bold small">Título header</Form.Label>
                  <Form.Control
                    size="lg"
                    placeholder="Ingresa un título"
                    name="header_title"
                    type="header_title"
                    defaultValue={projectForm.header_title}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup controlId="footer_disclaimer">
                  <Form.Label className="font-weight-bold small">Derecho de autor</Form.Label>
                  <Form.Control
                    size="lg"
                    placeholder="Ingresa derecho de autor"
                    name="footer_disclaimer"
                    type="footer_disclaimer"
                    defaultValue={projectForm.footer_disclaimer}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup controlId="contact_email">
                  <Form.Label className="font-weight-bold small">Mail contacto</Form.Label>
                  <Form.Control
                    type="contact_email"
                    size="lg"
                    placeholder="Ej: correo@email.com"
                    name="contact_email"
                    defaultValue={projectForm.contact_email}
                    onChange={handleInputChange}
                    isInvalid={validationError.contact_email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationError.contact_email}
                  </Form.Control.Feedback>
                </FormGroup>
                <FormGroup controlId="contact_phone">
                  <Form.Label className="font-weight-bold small">Número contacto</Form.Label>
                  <Form.Control
                    size="lg"
                    placeholder="EJ: +56988888888"
                    name="contact_phone"
                    type="contact_phone"
                    defaultValue={projectForm.contact_phone}
                    onChange={handleInputChange}
                    isInvalid={validationError.contact_phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationError.contact_phone}
                  </Form.Control.Feedback>
                </FormGroup>
                <Form.Group controlId="image">
                  <Form.Label className="font-weight-bold small">Imagen</Form.Label>
                  <ImageUploadEdit
                    setLogo={setImage}
                    logo={image}
                    img={projectData.image}
                    imgName={'image'}
                    auxImage={auxImage}
                    setAuxImage={setAuxImage}
                  />
                </Form.Group>
                <span className="text-danger">
                  (*)
                  <span className="text-black-50">
                    Campos obligatorios
                  </span>
                </span>
              </>
            )}
            {step === 2 && (
              <>
                <h5 className="font-weight-bold mb-4">Diseño</h5>
                <FormGroup controlId="logo">
                  <Form.Label className="font-weight-bold small">Logo</Form.Label>
                  <ImageUploadEdit
                    setLogo={setLogo}
                    logo={logo}
                    img={projectData.logo}
                    imgName={'logo'}
                    auxLogo={auxLogo}
                    setAuxLogo={setAuxLogo}
                  />
                </FormGroup>
                <FormGroup controlId="icon">
                  <Form.Label className="font-weight-bold small">Icono</Form.Label>
                  <ImageUploadEdit
                    setLogo={setIcon}
                    logo={icon}
                    img={projectData.icon}
                    imgName={'icon'}
                    auxIcon={auxIcon}
                    setAuxIcon={setAuxIcon}
                  />
                </FormGroup>
                <Row className="mb-3">
                  <Col lg="12">
                    <Form.Label className="font-weight-bold small">Código de color primario</Form.Label>
                  </Col>
                  <Col xs="9">
                    <FormGroup className="mb-0 position-relative" controlId="primary_color">
                      <Form.Control
                        size="lg"
                        placeholder="Selecciona un color"
                        value={color}
                        name="primary_color"
                        type="primary_color"
                        onChange={handleInputChange}
                      />
                      <div className="color-box-result" style={{ backgroundColor: color }}/>
                    </FormGroup>
                  </Col>
                  <Col xs="3">
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic" size="lg" block>
                        <i className="far fa-eye-dropper"/>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="border-0 p-0 my-1">
                        <HexColorPicker color={color} onChange={setColor} />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <Form.Label className="font-weight-bold small">Código de color secundario</Form.Label>
                  </Col>
                  <Col xs="9">
                    <FormGroup className="mb-0 position-relative" controlId="secondary_color">
                      <Form.Control
                        size="lg"
                        placeholder="Selecciona un color"
                        value={colorSecondary}
                        name="secondary_color"
                        type="secondary_color"
                        onChange={handleInputChange}
                      />
                      <div className="color-box-result" style={{ backgroundColor: colorSecondary }}/>
                    </FormGroup>
                  </Col>
                  <Col xs="3">
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic" size="lg" block>
                        <i className="far fa-eye-dropper"/>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="border-0 p-0 my-1">
                        <HexColorPicker color={colorSecondary} onChange={setColorSecondary}  />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </>
            )}
            {step === 3 && (
              <Row>
                <Col xs="12" className="text-center">
                  <CheckCircle size="48" className="text-success mb-4"/>
                  <h5 className="font-weight-bold">Proyecto actualizado con éxito</h5>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        {
          serviceError ? (
            <div className="alert alert-danger my-3 col-lg-6 col-md-8 mx-auto" role="alert">
              {alert()}
            </div>
          ) : null
        }
        <Row className="d-flex justify-content-center mb-5">
          {step > 0 && step < 3 && (
            <Button variant="light" className="mx-2" onClick={onPrevious}>Atrás</Button>
          )}
          {step === 3 ?(
            <Button className="mx-2" href="/admin/projects">Ir a proyectos</Button>
          ) :(
            step === 2 ?
              (
                <Button
                  className="mx-2"
                  onClick={sendData}
                  disabled={ loading || loadingForm}
                >
                  {
                    loading || loadingForm ? (
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    ) : (
                      ''
                    )
                  }{' '}
                  Siguiente
                </Button>
              ):(
                <Button className="mx-2" onClick={onNext} disabled={disableSubmit}>Siguiente</Button>
              )
          )
          }
        </Row>
      </Form>
    ):('')
  )
}

EditProject.propTypes = {
  patchProject: PropTypes.func.isRequired,
  showProject: PropTypes.func.isRequired,
  projectData: PropTypes.object,
  isLoadedProject: PropTypes.bool.isRequired,
  showProjectAdmins: PropTypes.func.isRequired,
  projectAdminList: PropTypes.array,
  loadingForm: PropTypes.bool,
  errors: PropTypes.array
};

const stateToProps = state => ({
  projectData: state.projects.projectData,
  isLoadedProject: state.projects.isLoadedProject,
  projectAdminList: state.projects.projectAdminList,
  loadingForm: state.projects.loadingForm,
  errors: state.projects.errors
});

const actionsToProps = dispatch => ({
  patchProject: payload => dispatch({ type: PATCH_PROJECT, payload }),
  showProject: payload => dispatch({ type: FETCH_PROJECT, payload }),
  showProjectAdmins: payload => dispatch({ type: FETCH_PROJECT_ADMINS, payload })
});

export default connect(stateToProps, actionsToProps)(EditProject);
