import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Badge, Dropdown } from 'react-bootstrap'
import Avatar from 'react-avatar'
import { MoreVertical, ArrowRight, Edit, List } from 'react-feather'
import { useHistory } from 'react-router-dom';
import SocietyDelete from './societyDelete';

const SocietyItem = props => {
  const { readOnly } = props;
  const history = useHistory();

  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    const windowLocation = window.location.pathname
    const rutaInicial = windowLocation.split('/').slice(0, 4).join('/')
    setCurrentPath(rutaInicial)
  }, [])

  return (
    <>
      
        <Row className='p-3' id={ currentPath === '/admin/group/detail' ? '' : 'projects_item'  }>
     

        {
          currentPath === '/admin/group/detail' ? (
            <>
            <Col  xs="12" sm="12" lg="4" xl="4" >
            <div className="media d-flex align-items-center">
              {/*
                <Avatar maxInitials="2" round="12px" fgColor="black" size="45" name={props.name} className="mr-3"/>
              */}
              <div className="media-body">
                <div className='d-block align-items-center mb-1'>
                  <h6 className="text-uppercase mb-0 mr-2">{props.name}</h6>
                 
                </div>
                <h6 className="text-muted small mb-0">{props.group}</h6>
              </div>
            </div>
          </Col>

          <Col  xs="12" sm="12" lg="4" xl="4" className='d-flex justify-content-center'  >
          <p className="text-pink m-0 small text-uppercase">{props.rut ? (props.rut) : ("-")}</p>
          </Col>    

          <Col xs="12" sm="12" lg="4" xl="4"  className='d-flex justify-content-end' >
            <div className="d-flex align-items-end">
              <Dropdown className='project-item'>
                {
                  !readOnly && (
                    <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" className="mx-1" size="sm">
                      <Edit size="12" />
                    </Dropdown.Toggle>
                  )
                }
                {!readOnly && (
                  <Dropdown.Menu className="shadow-sm border-0 p-2">
                    <Dropdown.Item onClick={() => history.push({ pathname: `/admin/society/edit/${props.uuid}` })}>Editar</Dropdown.Item>
                    <SocietyDelete setFlagDelete={props.setFlagDelete} uuid={props.uuid} />
                  </Dropdown.Menu>
                )}

                <Button variant="outline-dark" size="sm" onClick={() => history.push({ pathname: `/admin/society/detail/${props.uuid}` })}>
                  Ver Detalle
                  <List size="12" className='ml-2 mb-0' />
                </Button>
              </Dropdown>
            </div>
          </Col>

            </>
          ):(
            <>
            <Col  xs="12" sm="12" lg="3" xl="2" >
            <div className="media d-flex align-items-center">
              {/*
                <Avatar maxInitials="2" round="12px" fgColor="black" size="45" name={props.name} className="mr-3"/>
              */}
              <div className="media-body">
                <div className='d-block align-items-center mb-1'>
                  <h6 className="text-uppercase mb-0 mr-2">{props.name}</h6>
                 
                </div>
                <h6 className="text-muted small mb-0">{props.group}</h6>
              </div>
            </div>
          </Col>

          <Col  xs="12" sm="12" lg="3" xl="2"  >
          <p className="text-pink m-0 small text-uppercase">{props.rut ? (props.rut) : ("-")}</p>
          </Col>

          
    
            <Col  xs="12" sm="12" lg="3" xl="2"   >
              <Badge style={{ fontWeight:'light', fontSize:'15px', color:'black' }} className='text-wrap
              ' pill>{props.project}</Badge>
            </Col>
            
          

          <Col xs="12" sm="12" lg="3" xl="2"   >
            <div className="d-flex align-items-center">
              <Dropdown className='project-item'>
                {
                  !readOnly && (
                    <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" className="mx-1" size="sm">
                      <Edit size="12" />
                    </Dropdown.Toggle>
                  )
                }
                {!readOnly && (
                  <Dropdown.Menu className="shadow-sm border-0 p-2">
                    <Dropdown.Item onClick={() => history.push({ pathname: `/admin/society/edit/${props.uuid}` })}>Editar</Dropdown.Item>
                    <SocietyDelete setFlagDelete={props.setFlagDelete} uuid={props.uuid} />
                  </Dropdown.Menu>
                )}

                <Button variant="outline-dark" size="sm" onClick={() => history.push({ pathname: `/admin/society/detail/${props.uuid}` })}>
                  Ver Detalle
                  <List size="12" className='ml-2 mb-0' />
                </Button>
              </Dropdown>
            </div>
          </Col>
            </>
          )
        }
        </Row>

    </>
  )
}

export default SocietyItem;
