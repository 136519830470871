import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Collapse, Form, Row, Table } from 'react-bootstrap';
import GroupItem from '../group_item'
import { ConfigProvider } from 'react-avatar';
import SearchBar from '../search_bar';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_LIST_GROUPS, FETCH_LIST_PROJECTS } from '../../store/types';
import PaginationBar from '../pagination/paginationBar';
import { ChevronDown, Plus } from 'react-feather';

const GroupAdmin = ({ showGroups, groupsList, indexPagination, projectsList, showProjects, userProfile }) => {
  const [flagAction, setFlagAction] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const [paginationPosition, setPaginationPosition] = useState(window.location.hash.substring(1, window.location.hash.indexOf("?")));
  const [queryValue, setQueryValue] = useState(window.location.hash.substring(window.location.hash.indexOf("?")));
  const [filterValue, setFilterValue] = useState({
    project: "",
    ordering: ""
  });
  const [groupsInScreen, setGroupsInScreen] = useState(5);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'SUPER_ADMIN') {
        showProjects({ valueSearch: "", paginationPosition: null });
      }
    }
  }, [userProfile, showProjects]);

  useEffect(() => {
    showGroups({ valueSearch, paginationPosition, queryValue, filterValue, groupsInScreen });
  }, [showGroups, flagAction, valueSearch, paginationPosition, filterValue, groupsInScreen, queryValue]);

  const handleSearch = ({ target }) => {
    setPaginationPosition(1);
    setQueryValue(`?search=${target.value}&project=${filterValue.project}&ordering=${filterValue.ordering}`);
    setValueSearch(target.value);
  }

  useEffect(() => {
    if (!window.location.hash) {
      setPaginationPosition('1');
      setQueryValue("?search=&project=&ordering=");
    } else {
      setValueSearch(window.location.hash.substring(window.location.hash.indexOf("h=") + 2, window.location.hash.indexOf("&")));
      setFilterValue({
        project: window.location.hash.substring(window.location.hash.indexOf("t=") + 2, window.location.hash.indexOf("&ord", 2)),
        ordering: window.location.hash.substring(window.location.hash.indexOf("g=") + 2)
      })
    }
  }, [setPaginationPosition]);

  useEffect(() => {
    history.replace(`#${paginationPosition}?search=${valueSearch}&project=${filterValue.project}&ordering=${filterValue.ordering}`);
  }, [paginationPosition, valueSearch, filterValue, queryValue, history]);

  const handleSelectFilter = ({ target }) => {
    const formData = { ...filterValue, [target.name]: target.value };
    setPaginationPosition(1);
    setFilterValue(formData);
    setQueryValue(`?search=${valueSearch}&project=${formData.project}&ordering=${formData.ordering}`);
  };

  return (

    <>



      <Row className='d-flex align-items-center justify-content-beetwen' id='headerGroup'>

        <Col xs="10" sm="10" lg="3" xl="3">
          <Breadcrumb className="small">
            <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
            <Breadcrumb.Item style={{ padding: '0' }} active>Administración Grupo Sociedad</Breadcrumb.Item>
          </Breadcrumb>
          <h4 className="font-weight-bold mb-4 mb-lg-0 ml-3">Grupo Sociedad</h4>
        </Col>

        <Col xs="12" sm="12" lg="9" xl="9">

          <Row>

            <Col sm="12" className='d-block d-md-flex justify-content-end align-items-center mt-4'>



              {
                userProfile.profile && (
                  userProfile.profile.user_role === 'SUPER_ADMIN' && (



                    <Form.Group controlId="project" className='mb-0'>
                      <Form.Control
                        className="text-muted mb-4 mb-sm-0"
                        size="md"
                        as="select"
                        name="project"
                        type="project"
                        onChange={handleSelectFilter}
                        style={{ width: '13rem' }}
                      >
                        <option value="" className="secondary-text-color" selected> Todos los proyectos </option>
                        {
                          projectsList.map((project) => {
                            return (
                              filterValue.project === project.uuid ? (
                                <option value={project.uuid} key={project.uuid} selected>{project.project_name}</option>
                              ) : (
                                <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                              )
                            );
                          })
                        }
                      </Form.Control>
                    </Form.Group>

                  )
                )
              }

              <Form.Group controlId="ordering" className='mb-0 m-1'>
                <Form.Control
                  className="text-muted mb-4 mb-sm-0"
                  size="md"
                  as="select"
                  name="ordering"
                  type="ordering"
                  onChange={handleSelectFilter}
                  style={{ width: '10rem' }}
                >
                  <option className="secondary-text-color" disabled> Ordenar por: </option>
                  {
                    filterValue.ordering === "" ? (
                      <option value="" selected>Ultima edicion</option>
                    ) : (
                      <option value="" >Ultima edicion</option>
                    )
                  }
                  {
                    filterValue.ordering === "group_name" ? (
                      <option value="group_name" selected>Nombre</option>
                    ) : (
                      <option value="group_name">Nombre</option>
                    )
                  }
                  {
                    filterValue.ordering === "enabled" ? (
                      <option value="enabled" selected>Estado</option>
                    ) : (
                      <option value="enabled">Estado</option>
                    )
                  }
                </Form.Control>
              </Form.Group>






              <SearchBar handleSearch={handleSearch} defaultValue={decodeURI(valueSearch).length === 1 ? (`${decodeURI(valueSearch)} `) : (decodeURI(valueSearch))} />

              <Button as={Link} to="/admin/group/create" variant="primary" size="md" className='mt-3 mt-md-0'><Plus size={18} className='mr-2'></Plus>Crear</Button>


            </Col>


          </Row>

          {/*
          <Col sm="12" lg="12" className="d-flex align-items-center justify-content-end p-0">
            <Col>
              <Collapse in={open}>
                <div id="example-collapse-text">
                  <Row className="mt-3 d-flex align-items-center justify-content-end">
                    {
                      userProfile.profile && (
                        userProfile.profile.user_role === 'SUPER_ADMIN' && (
                          <Col sm="6" lg="5" xl="6">
                            <Form.Group controlId="project" className='mb-0'>
                              <Form.Control
                                className="text-muted mb-4 mb-sm-0"
                                size="md"
                                as="select"
                                name="project"
                                type="project"
                                onChange={handleSelectFilter}
                              >
                                <option value="" className="secondary-text-color" selected> Todos los proyectos </option>
                                {
                                  projectsList.map( (project) =>{
                                    return (
                                      filterValue.project === project.uuid ?(
                                        <option value={project.uuid} key={project.uuid} selected>{project.project_name}</option>
                                      ):(
                                        <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                                      )
                                    );
                                  })
                                }
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        )
                      )
                    }
                    <Col sm="6" lg="4" xl="6">
                      <Form.Group controlId="ordering" className='mb-0'>
                        <Form.Control
                          className="text-muted"
                          size="md"
                          as="select"
                          name="ordering"
                          type="ordering"
                          onChange={handleSelectFilter}
                        >
                          <option className="secondary-text-color" disabled> Ordenar por: </option>
                          {
                            filterValue.ordering === "" ? (
                              <option value="" selected>Ultima edicion</option>
                            ):(
                              <option value="" >Ultima edicion</option>
                            )
                          }
                          {
                            filterValue.ordering === "group_name" ? (
                              <option value="group_name" selected>Nombre</option>
                            ):(
                              <option value="group_name">Nombre</option>
                            )
                          }
                          {
                            filterValue.ordering === "enabled" ? (
                              <option value="enabled" selected>Estado</option>
                            ):(
                              <option value="enabled">Estado</option>
                            )
                          }
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </Col>
                        </Col>  */}

        </Col>


      </Row>












      <Table striped hover size="md" className='mt-4 mb-5'>

      <thead>
      <Row className='sub_header'>
        <Col xs="12" sm="12" lg="3" xl="2" ><span>Grupos:</span></Col>
        <Col xs="12" sm="12" lg="3" xl="2"  ><span>Proyecto:</span></Col>
        <Col xs="12" sm="12" lg="3" xl="2"  ><span>User - Sociedad</span></Col>
        <Col xs="12" sm="12" lg="3" xl="2" ><span>Acciones:</span></Col>
      </Row>
      </thead>

        <tbody>
          {groupsList.map((group) => (
            <GroupItem
              key={group.uuid}
              setFlagAction={setFlagAction}
              name={group.group_name}
              project={group.project}
              admin={group.admin_quantity}
              uuid={group.uuid}
              user={group.user_quantity}
              status={'Habilitado'}
              society={group.society_quantity}
              variant={group.primary_color}
              enabled={group.enable}
            />
          ))}
        </tbody>

      </Table>

      <PaginationBar total={indexPagination} position={paginationPosition} setPosition={setPaginationPosition} />
      {
        paginationPosition === 1 && groupsList.length >= 5 && (
          <div className="small d-flex justify-content-center align-content-center">
            <span className="mt-1">Mostrar:</span>
            <Col className="mb-2 d-flex">
              <Form.Group controlId="project" className="m-0">
                <Form.Control
                  className="text-muted"
                  as="select"
                  name="project"
                  type="project"
                  size="sm"
                  defaultValue={groupsInScreen}
                  onChange={({ target }) => {
                    setGroupsInScreen(target.value);
                  }}
                >
                  <option value={5} className="secondary-text-color" selected>5</option>
                  <option value={15} className="secondary-text-color">15</option>
                  <option value={30} className="secondary-text-color">30</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </div>
        )
      }
    </>

  )
}

GroupAdmin.propTypes = {
  showProjects: PropTypes.func.isRequired,
  showGroups: PropTypes.func.isRequired,
  projectsList: PropTypes.array,
  groupsList: PropTypes.array,
  isLoaded: PropTypes.bool.isRequired,
  indexPagination: PropTypes.number
};

const stateToProps = state => ({
  projectsList: state.projects.projectsList,
  groupsList: state.groups.groupsList,
  isLoaded: state.groups.isLoaded,
  indexPagination: state.groups.indexPagination,
  userProfile: state.profile.userProfile
});

const actionsToProps = dispatch => ({
  showGroups: payload => dispatch({ type: FETCH_LIST_GROUPS, payload }),
  showProjects: payload => dispatch({ type: FETCH_LIST_PROJECTS, payload })
});

export default connect(stateToProps, actionsToProps)(GroupAdmin);
