import { takeLatest, put, call } from 'redux-saga/effects';
import {
  balance8columnsDates,
  cashFlowDates,
  deleteAccounting,
  financialSituationDates,
  getAccountDetail,
  getAccountingData,
  getBalance8Columns,
  getBalance8ColumnsDetail,
  getCashFlow,
  getFinancialSituation,
  getFinancialSituationDetail,
  getStatusAccountDetail,
  getStatusResults,
  getStatusResultsDetail,
  getUserAccountingList,
  postAccounting,
  searchAccounting,
  statusResultsDates,
  updateAccounting
} from '../../network';
import {
  DATA_BALANCE_8_COLUMNS,
  DATA_CASH_FLOW, DATA_EXIST,
  DATA_FINANCIAL_SITUATION,
  DATA_STATUS_RESULTS,
  DATES_BALANCE_8_COLUMNS,
  DATES_CASH_FLOW,
  DATES_FINANCIAL_SITUATION,
  DATES_STATUS_RESULTS,
  DELETE_ACCOUNTING,
  DETAIL_ACCOUNT,
  DETAIL_BALANCE_8_COLUMNS,
  DETAIL_FINANCIAL_SITUATION,
  DETAIL_STATUS_ACCOUNT,
  DETAIL_STATUS_RESULTS,
  FETCH_ACCOUNT_DETAIL,
  FETCH_ACCOUNT_STATUS_DETAIL,
  FETCH_ACCOUNTING,
  FETCH_BALANCE_8_COLUMNS,
  FETCH_CASH_FLOW,
  FETCH_DATES_BALANCE_8_COLUMNS,
  FETCH_DATES_CASH_FLOW,
  FETCH_DATES_FINANCIAL_SITUATION,
  FETCH_DATES_STATUS_RESULTS,
  FETCH_DETAIL_BALANCE_8_COLUMNS, FETCH_EXCEL,
  FETCH_FINANCIAL_SITUATION,
  FETCH_FINANCIAL_SITUATION_DETAIL,
  FETCH_LIST_ACCOUNTING,
  FETCH_PDF_BALANCE_8_COLUMNS,
  FETCH_STATUS_RESULTS,
  FETCH_STATUS_RESULTS_DETAIL,
  FETCH_USER_ACCOUNTING_LIST,
  INDEX_PAGINATION_ACCOUNTING,
  INDEX_PAGINATION_ACCOUNTING_DISABLED,
  IS_LOADED,
  IS_LOADED_ACCOUNT_DETAIL,
  IS_LOADED_ACCOUNTING,
  IS_LOADED_ACCOUNTING_DETAIL,
  IS_LOADED_FINANCIAL_SITUATION_DETAIL,
  LOADING_ACCOUNTING_FORM,
  PATCH_ACCOUNTING,
  POST_ACCOUNTING,
  SET_ACCOUNTING_ERRORS,
  SET_DATA_ACCOUNTING,
  SET_DATA_LIST_ACCOUNTING,
  SET_DATA_LIST_ACCOUNTING_DISABLED,
  SET_USER_DATA_LIST_ACCOUNTING
} from './types';
import formsErrors from '../../utils/errors/formsErrors';
import messageValidationFailed from '../../utils/helpers/messageValidationFailed';
import registerErrors from '../../utils/errors/formsErrors';
import cookieHandler from '../../utils/helpers/cookie.handler';

function* showAccountingList({ payload }) {
  
  try {
    let response = "";
    let responseDisabled = "";

    const { queryValue, paginationPosition, paginationPositionDisabled, reportsInScreen } = payload;

    response = yield call(searchAccounting, `${queryValue}&enable=true&limit=${reportsInScreen}&offset=${(paginationPosition - 1) * reportsInScreen}`);



    responseDisabled = yield call(searchAccounting, `${queryValue}&enable=false&limit=${reportsInScreen}&offset=${(paginationPositionDisabled - 1) * reportsInScreen}`);
   

   
   
    yield put({ type: IS_LOADED, payload: false })
    yield put({ type: SET_DATA_LIST_ACCOUNTING, payload: response.data.results });

    console.log('Respuesta servidor 3', response.data.results)
    
    yield put({ type: SET_DATA_LIST_ACCOUNTING_DISABLED, payload: responseDisabled.data.results });
    yield put({ type: IS_LOADED, payload: true });
    yield put({ type: INDEX_PAGINATION_ACCOUNTING, payload: Math.ceil(response.data.count / reportsInScreen) }); 
    yield put({ type: INDEX_PAGINATION_ACCOUNTING_DISABLED, payload: Math.ceil(responseDisabled.data.count / reportsInScreen) });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* deleteAccountingData({ payload }) {
  try {
    yield call(deleteAccounting, payload);
    window.location.reload();
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showAccounting({ payload }) {
  try {
    const response = yield call(getAccountingData, payload);
    yield put({ type: IS_LOADED_ACCOUNTING, payload: false })
    yield put({ type: SET_DATA_ACCOUNTING, payload: response.data });
    yield put({ type: IS_LOADED_ACCOUNTING, payload: true })
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* createAccounting({ payload }) {
  let errors = [];
  try {
    yield put({ type: LOADING_ACCOUNTING_FORM, payload: true });
    yield call(postAccounting, payload);
    yield put({ type: LOADING_ACCOUNTING_FORM, payload: false });
    yield put({ type: SET_ACCOUNTING_ERRORS, payload: null });
  } catch (e) {
    yield put({ type: LOADING_ACCOUNTING_FORM, payload: true });
    const { response } = e;
    if (response.status === 400) {
      if (response.data.menu_name) {
        errors = [...errors, messageValidationFailed(response.data.menu_name, 'nombre de reporte.')];
      }
      if (response.data.description) {
        errors = [...errors, messageValidationFailed(response.data.description, 'descripción de reporte.')];
      }
      if (response.data.accounting_type) {
        errors = [...errors, messageValidationFailed(response.data.accounting_type, 'tipo de reporte.')];
      }
      if (response.data.module) {
        errors = [...errors, registerErrors.INVALID];
      }
      yield put({ type: SET_ACCOUNTING_ERRORS, payload: errors });
      yield put({ type: LOADING_ACCOUNTING_FORM, payload: false });
    }
    if (response.status === 404) {
      if (response.data.accounting_type) {
        errors = [...errors, registerErrors.DATA_CONFLICT];
      }
      yield put({ type: SET_ACCOUNTING_ERRORS, payload: errors });
      yield put({ type: LOADING_ACCOUNTING_FORM, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_ACCOUNTING_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: LOADING_ACCOUNTING_FORM, payload: false });
    }
    // TODO: handle error
    console.log(e);
  }
}

function* patchAccounting({ payload }) {
  let errors = [];
  try {
    yield put({ type: LOADING_ACCOUNTING_FORM, payload: true });
    yield call(updateAccounting, payload);
    yield put({ type: LOADING_ACCOUNTING_FORM, payload: false });
    yield put({ type: SET_ACCOUNTING_ERRORS, payload: null });
  } catch (e) {
    yield put({ type: LOADING_ACCOUNTING_FORM, payload: true });
    const { response } = e;
    if (response.status === 400) {
      if (response.data.menu_name) {
        errors = [...errors, messageValidationFailed(response.data.menu_name, 'nombre de reporte.')];
      }
      if (response.data.description) {
        errors = [...errors, messageValidationFailed(response.data.description, 'descripción de reporte.')];
      }
      if (response.data.accounting_type) {
        errors = [...errors, messageValidationFailed(response.data.accounting_type, 'tipo de reporte.')];
      }
      if (response.data.module) {
        errors = [...errors, registerErrors.INVALID];
      }
      yield put({ type: SET_ACCOUNTING_ERRORS, payload: errors });
      yield put({ type: LOADING_ACCOUNTING_FORM, payload: false });
    }
    if (response.status === 404) {
      if (response.data.accounting_type) {
        errors = [...errors, registerErrors.DATA_CONFLICT];
      }
      yield put({ type: SET_ACCOUNTING_ERRORS, payload: errors });
      yield put({ type: LOADING_ACCOUNTING_FORM, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_ACCOUNTING_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: LOADING_ACCOUNTING_FORM, payload: false });
    }
    // TODO: handle error
    console.log(e);
  }
}

function* showUserAccountingList({ payload }) {
  try {
    const { project, group, society } = payload;
    let response;
    if (project)
      response = yield call(getUserAccountingList, `project=${project}`);
    if (group)
      response = yield call(getUserAccountingList, `group=${group}`);
    if (society)
      response = yield call(getUserAccountingList, `society=${society}`);
    //yield put({ type: IS_LOADED_ACCOUNTING, payload: false });
    yield put({ type: SET_USER_DATA_LIST_ACCOUNTING, payload: response.data.results[0].report_list });
    //yield put({ type: IS_LOADED_ACCOUNTING, payload: true });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showBalance8Columns({ payload }) {
  try {
    yield put({ type: IS_LOADED_ACCOUNTING, payload: true });
    const response = yield call(getBalance8Columns, payload);
    yield put({ type: DATA_BALANCE_8_COLUMNS, payload: response.data });
    if (response.data.header === undefined)
      yield put({ type: DATA_EXIST, payload: false });
    else
      yield put({ type: DATA_EXIST, payload: true });
    yield put({ type: IS_LOADED_ACCOUNTING, payload: false });
  } catch (e) {
    // TODO: handle error
    yield put({ type: IS_LOADED_ACCOUNTING, payload: false });
    yield put({ type: DATA_EXIST, payload: false });
    console.log(e);
  }
}

function* showBalance8ColumnsDetail({ payload }) {
  try {
    yield put({ type: IS_LOADED_ACCOUNTING_DETAIL, payload: true });
    const response = yield call(getBalance8ColumnsDetail, payload);
    yield put({ type: DETAIL_BALANCE_8_COLUMNS, payload: response.data });
    yield put({ type: IS_LOADED_ACCOUNTING_DETAIL, payload: false });
  } catch (e) {
    // TODO: handle error
    console.log(e);
    yield put({ type: IS_LOADED_ACCOUNTING_DETAIL, payload: false });
  }
}

function* showBalance8ColumnsDates({ payload }) {
  try {
    yield put({ type: IS_LOADED_ACCOUNTING, payload: true });
    const response = yield call(balance8columnsDates, payload);
    yield put({ type: DATES_BALANCE_8_COLUMNS, payload: response.data });
    if (response.data === [])
      yield put({ type: DATA_EXIST, payload: true });
    else
      yield put({ type: DATA_EXIST, payload: false });
    yield put({ type: IS_LOADED_ACCOUNTING, payload: false });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showAccountingPDF({ payload }) {
  try {
    const { data, type } = payload
    const accessToken = cookieHandler().getAccessToken();
    const link = document.createElement('a');
    if (type === "1")
      link.href = `${process.env.REACT_APP_SERVICE_URL}/api/v1/modules/module/balance8columns/export/pdf/${data}&token=${accessToken}`;
    if (type === "2")
      // Quitar el / (${process.env.REACT_APP_SERVICE_URL}/) Si no funciona la descarga
      link.href = `${process.env.REACT_APP_SERVICE_URL}/api/v1/modules/module/financial-situation/export/pdf/${data}&token=${accessToken}`;
    if (type === "3")
      link.href = `${process.env.REACT_APP_SERVICE_URL}/api/v1/modules/module/status-results/export/pdf/${data}&token=${accessToken}`;
    if (type === "4")
      link.href = `${process.env.REACT_APP_SERVICE_URL}/api/v1/modules/module/cashflow/export/pdf/${data}&token=${accessToken}`;
    link.setAttribute('downloas', 'report.pdf');
    document.body.appendChild(link);
    link.click();
    yield;
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showAccountingExcel({ payload }) {

  try {
    const { data, type } = payload
    const accessToken = cookieHandler().getAccessToken();
    const link = document.createElement('a');
    if (type === "1")
      link.href = `${process.env.REACT_APP_SERVICE_URL}/api/v1/modules/module/balance8columns/export/excel/${data}&token=${accessToken}`;
    if (type === "2")
      link.href = `${process.env.REACT_APP_SERVICE_URL}/api/v1/modules/module/financial-situation/export/excel/${data}&token=${accessToken}`;
    if (type === "3")
      link.href = `${process.env.REACT_APP_SERVICE_URL}/api/v1/modules/module/status-results/export/excel/${data}&token=${accessToken}`;
    if (type === "4")
      link.href = `${process.env.REACT_APP_SERVICE_URL}/api/v1/modules/module/cashflow/export/excel/${data}&token=${accessToken}`;
    link.setAttribute('download', 'report.excel');
    document.body.appendChild(link);
    link.click();
    yield;
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showCashFlowDates({ payload }) {
  try {
    yield put({ type: IS_LOADED_ACCOUNTING, payload: true });
    const response = yield call(cashFlowDates, payload);
    console.log(response)
    yield put({ type: DATES_CASH_FLOW, payload: response.data });
    if (response.data === [])
      yield put({ type: DATA_EXIST, payload: true });
    else
      yield put({ type: DATA_EXIST, payload: false });
    yield put({ type: IS_LOADED_ACCOUNTING, payload: false });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showCashFlow({ payload }) {

  try {
    yield put({ type: IS_LOADED_ACCOUNTING, payload: true });
    const response = yield call(getCashFlow, payload);

    yield put({ type: DATA_CASH_FLOW, payload: response.data });
 
    if (response.data.header === undefined)
      yield put({ type: DATA_EXIST, payload: false });
    else
      yield put({ type: DATA_EXIST, payload: true });
      yield put({ type: IS_LOADED_ACCOUNTING, payload: false });
  } catch (e) {
    // TODO: handle error
    yield put({ type: IS_LOADED_ACCOUNTING, payload: false });
    yield put({ type: DATA_EXIST, payload: false });

    console.log(e);
  }

}



function* showFinancialSituationDates({ payload }) {
  try {
    yield put({ type: IS_LOADED_ACCOUNTING, payload: true });
    const response = yield call(financialSituationDates, payload);
    yield put({ type: DATES_FINANCIAL_SITUATION, payload: response.data });
    if (response.data.length === 0)
      yield put({ type: DATA_EXIST, payload: false });
    else
      yield put({ type: DATA_EXIST, payload: true });
    yield put({ type: IS_LOADED_ACCOUNTING, payload: false });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showFinancialSituation({ payload }) {
  try {
    yield put({ type: IS_LOADED_ACCOUNTING, payload: true });
    const response = yield call(getFinancialSituation, payload);
    yield put({ type: DATA_FINANCIAL_SITUATION, payload: response.data });
    if (response.data.header === undefined)
      yield put({ type: DATA_EXIST, payload: false });
    else
      yield put({ type: DATA_EXIST, payload: true });
    yield put({ type: IS_LOADED_ACCOUNTING, payload: false });
  } catch (e) {
    // TODO: handle error
    yield put({ type: IS_LOADED_ACCOUNTING, payload: false });
    yield put({ type: DATA_EXIST, payload: false });
    console.log(e);
  }
}

function* showFinancialSituationDetail({ payload }) {
  try {
    yield put({ type: IS_LOADED_FINANCIAL_SITUATION_DETAIL, payload: true });
    const response = yield call(getFinancialSituationDetail, payload);
    yield put({ type: DETAIL_FINANCIAL_SITUATION, payload: response.data });
    yield put({ type: IS_LOADED_FINANCIAL_SITUATION_DETAIL, payload: false });
  } catch (e) {
    // TODO: handle error
    console.log(e);
    yield put({ type: IS_LOADED_FINANCIAL_SITUATION_DETAIL, payload: false });
  }
}

function* showAccountDetail({ payload }) {
  try {
    yield put({ type: IS_LOADED_ACCOUNT_DETAIL, payload: true });
    const response = yield call(getAccountDetail, payload);
    yield put({ type: DETAIL_ACCOUNT, payload: response.data });
    yield put({ type: IS_LOADED_ACCOUNT_DETAIL, payload: false });
  } catch (e) {
    // TODO: handle error
    console.log(e);
    yield put({ type: IS_LOADED_ACCOUNT_DETAIL, payload: false });
  }
}

function* showStatusResultDates({ payload }) {
  try {
    yield put({ type: IS_LOADED_ACCOUNTING, payload: true });
    const response = yield call(statusResultsDates, payload);
    yield put({ type: DATES_STATUS_RESULTS, payload: response.data });
    if (response.data.length === 0)
      yield put({ type: DATA_EXIST, payload: false });
    else
      yield put({ type: DATA_EXIST, payload: true });
    yield put({ type: IS_LOADED_ACCOUNTING, payload: false });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showStatusResult({ payload }) {
  try {
    yield put({ type: IS_LOADED_ACCOUNTING, payload: true });
    const response = yield call(getStatusResults, payload);
    yield put({ type: DATA_STATUS_RESULTS, payload: response.data });
    if (response.data.header === undefined)
      yield put({ type: DATA_EXIST, payload: false });
    else
      yield put({ type: DATA_EXIST, payload: true });
    yield put({ type: IS_LOADED_ACCOUNTING, payload: false });
  } catch (e) {
    // TODO: handle error
    yield put({ type: IS_LOADED_ACCOUNTING, payload: false });
    yield put({ type: DATA_EXIST, payload: false });
    console.log(e);
  }
}

function* showStatusResultDetail({ payload }) {
  try {
    yield put({ type: IS_LOADED_FINANCIAL_SITUATION_DETAIL, payload: true });
    const response = yield call(getStatusResultsDetail, payload);
    yield put({ type: DETAIL_STATUS_RESULTS, payload: response.data });
    yield put({ type: IS_LOADED_FINANCIAL_SITUATION_DETAIL, payload: false });
  } catch (e) {
    // TODO: handle error
    console.log(e);
    yield put({ type: IS_LOADED_FINANCIAL_SITUATION_DETAIL, payload: false });
  }
}

function* showAccountStatusDetail({ payload }) {
  try {
    yield put({ type: IS_LOADED_ACCOUNT_DETAIL, payload: true });
    const response = yield call(getStatusAccountDetail, payload);
    yield put({ type: DETAIL_STATUS_ACCOUNT, payload: response.data });
    yield put({ type: IS_LOADED_ACCOUNT_DETAIL, payload: false });
  } catch (e) {
    // TODO: handle error
    console.log(e);
    yield put({ type: IS_LOADED_ACCOUNT_DETAIL, payload: false });
  }
}

function* watchShowAccountingList() {
  yield takeLatest(FETCH_LIST_ACCOUNTING, showAccountingList);
}

function* watchDeleteAccountingData() {
  yield takeLatest(DELETE_ACCOUNTING, deleteAccountingData);
}

function* watchShowAccounting() {
  yield takeLatest(FETCH_ACCOUNTING, showAccounting);
}

function* watchCreateAccounting() {
  yield takeLatest(POST_ACCOUNTING, createAccounting);
}

function* watchAccountingGroup() {
  yield takeLatest(PATCH_ACCOUNTING, patchAccounting);
}

function* watchShowUserAccountingList() {
  yield takeLatest(FETCH_USER_ACCOUNTING_LIST, showUserAccountingList);
}

function* watchShowBalance8Columns() {
  yield takeLatest(FETCH_BALANCE_8_COLUMNS, showBalance8Columns);
}

function* watchShowBalance8ColumnsDetail() {
  yield takeLatest(FETCH_DETAIL_BALANCE_8_COLUMNS, showBalance8ColumnsDetail);
}

function* watchShowBalance8ColumnsDates() {
  yield takeLatest(FETCH_DATES_BALANCE_8_COLUMNS, showBalance8ColumnsDates);
}

function* watchShowAccountingPDF() {
  yield takeLatest(FETCH_PDF_BALANCE_8_COLUMNS, showAccountingPDF);
}

function* watchShowAccountingExcel() {
  yield takeLatest(FETCH_EXCEL, showAccountingExcel);
}

function* watchShowCashFlowDates() {
  yield takeLatest(FETCH_DATES_CASH_FLOW, showCashFlowDates);
}

function* watchShowCashFlow() {
  yield takeLatest(FETCH_CASH_FLOW, showCashFlow);
}

function* watchShowFinancialSituationDates() {
  yield takeLatest(FETCH_DATES_FINANCIAL_SITUATION, showFinancialSituationDates);
}

function* watchShowFinancialSituation() {
  yield takeLatest(FETCH_FINANCIAL_SITUATION, showFinancialSituation);
}

function* watchShowFinancialSituationDetail() {
  yield takeLatest(FETCH_FINANCIAL_SITUATION_DETAIL, showFinancialSituationDetail);
}

function* watchShowAccountDetail() {
  yield takeLatest(FETCH_ACCOUNT_DETAIL, showAccountDetail);
}

function* watchShowStatusResultDates() {
  yield takeLatest(FETCH_DATES_STATUS_RESULTS, showStatusResultDates);
}

function* watchShowStatusResult() {
  yield takeLatest(FETCH_STATUS_RESULTS, showStatusResult);
}

function* watchShowStatusResultDetail() {
  yield takeLatest(FETCH_STATUS_RESULTS_DETAIL, showStatusResultDetail);
}

function* watchShowAccountStatusDetail() {
  yield takeLatest(FETCH_ACCOUNT_STATUS_DETAIL, showAccountStatusDetail);
}

const saga = [
  watchShowAccountingList(),
  watchDeleteAccountingData(),
  watchShowAccounting(),
  watchCreateAccounting(),
  watchAccountingGroup(),
  watchShowUserAccountingList(),
  watchShowBalance8Columns(),
  watchShowBalance8ColumnsDetail(),
  watchShowBalance8ColumnsDates(),
  watchShowAccountingPDF(),
  watchShowCashFlowDates(),
  watchShowCashFlow(),
  watchShowFinancialSituationDates(),
  watchShowFinancialSituation(),
  watchShowFinancialSituationDetail(),
  watchShowAccountDetail(),
  watchShowStatusResultDates(),
  watchShowStatusResult(),
  watchShowStatusResultDetail(),
  watchShowAccountStatusDetail(),
  watchShowAccountingExcel()
];

export default saga;
