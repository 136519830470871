import {
  SET_DATA_LIST_PROJECTS,
  IS_LOADED,
  SET_DATA_PROJECT,
  IS_LOADED_PROJECT,
  SET_LIST_PROJECT_ADMINS,
  INDEX_PAGINATION_PROJECTS,
  LOADING_PROJECT_FORM,
  SET_PROJECT_ERRORS

} from './types';

const initialState = {
  projectsList: [],
  projectData: null,
  projectAdminList: [],
  isLoaded: false,
  isLoadedProject: false,
  indexPagination:0,
  errors: null,
  loadingForm: false
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case SET_DATA_LIST_PROJECTS:
    return { ...state, projectsList: payload };
  case SET_DATA_PROJECT:
    return { ...state, projectData: payload };
  case SET_LIST_PROJECT_ADMINS:
    return { ...state, projectAdminList: payload };
  case IS_LOADED:
    return { ...state, isLoaded: payload };
  case LOADING_PROJECT_FORM:
    return { ...state, loadingForm: payload };
  case SET_PROJECT_ERRORS:
    return { ...state, errors: payload };
  case IS_LOADED_PROJECT:
    return { ...state, isLoadedProject: payload };
  case INDEX_PAGINATION_PROJECTS:
    return { ...state, indexPagination: payload };
  default:
    return state;
  }
};

export default reducer;
