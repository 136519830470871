import React, { useEffect, useState } from 'react';
import { Button, Card, Form, FormControl, FormGroup, Modal, Spinner } from 'react-bootstrap';
import { Edit3 } from 'react-feather';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_INDUSTRY_DATA, PATCH_INDUSTRY } from '../../store/society/types';

const EditIndustry = ({ patchIndustry, errors, name, uuid, industryLoading, showIndustryData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ industryForm, setIndustryForm ] = useState({});
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if(!industryLoading && errors.length === 0 && flag) {
      handleClose();
      showIndustryData(uuid);
      setFlag(false);
    }
  }, [industryLoading, errors, flag, showIndustryData, uuid]);

  const handleInputChange = ({ target }) => {
    const formData = { ...industryForm, [target.name]: target.value };
    setIndustryForm(formData);
  };

  return (
    <>
      <Button
        variant="outline-secondary"
        size="xs"
        title="Cambiar nombre industria"
        data-toggle="tooltip"
        data-placement="top"
        onClick={handleShow}
      >
        <Edit3/>
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton/>
        <Modal.Body>
          <Card.Body >
            <h3 className="font-weight-bold title-color text-left">
              Cambiar nombre
            </h3>
            <FormGroup className="position-relative" controlId="industry">
              <Form.Label className="font-weight-bold small">
                Nombre
              </Form.Label>
              <FormControl
                size="lg"
                name="industry"
                type="industry"
                placeholder="Ingrese nombre de industria"
                defaultValue={name}
                onChange={handleInputChange}
              />
            </FormGroup>
            {
              !industryLoading && errors.length !== 0 && (
                errors.map(
                  (error) => {
                    return(<p key={error} className='text-danger'>{error}</p>)
                  }
                )
              )
            }
            <div className="justify-content-center d-flex">
              <Button
                className="btn-rounded col-12 base-btn-color"
                variant="primary"
                onClick={ () => {
                  patchIndustry({ industryForm, uuid });
                  setFlag(true);
                }}
              >
                {
                  industryLoading ?(
                    <Spinner animation="border" className="spinner-custom" />
                  ):(
                    'Guardar'
                  )
                }
              </Button>
            </div>
          </Card.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

EditIndustry.propTypes = {
  patchIndustry: PropTypes.func.isRequired,
  showIndustryData: PropTypes.func.isRequired
};

const stateToProps = state => ({
  errors: state.societies.industryErrors,
  industryLoading: state.societies.industryLoading
});

const actionsToProps = dispatch => ({
  patchIndustry: payload => dispatch({ type: PATCH_INDUSTRY, payload }),
  showIndustryData: payload => dispatch({ type: FETCH_INDUSTRY_DATA, payload })
});

export default connect(stateToProps, actionsToProps)(EditIndustry);
