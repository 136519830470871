import { takeLatest, put, call } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';
import {
  deleteDocument,
  deleteFolder,
  getDocumentToken,
  getFolders,
  getTemplateFolders,
  getToken,
  getUserFolders,
  postFolder,
  updateDocument,
  updateFolder,
  getDocumentPanelTaxForms,
  documentTaxPanelFormsAdmins
} from '../../network';
import {
  DELETE_DOCUMENT, DELETE_ERRORS,
  DELETE_FOLDER,
  DOCUMENT_LIST,
  EXPIRED_TOKEN,
  FETCH_LIST_DOCUMENTS,
  FETCH_LIST_TEMPLATE,
  FOLDER_ERRORS,
  GET_DOCUMENT_TOKEN,
  LIST_FOLDERS,
  LIST_USER_FOLDERS,
  LOADING_DOCUMENT,
  LOADING_FOLDER,
  LOADING_NEW_FOLDER,
  LOADING_TOKEN,
  PATCH_DOCUMENT,
  PATCH_FOLDER,
  POST_DOCUMENT,
  POST_FOLDER,
  ROOT_DIRECTORY,
  SET_DOCUMENT_ERRORS,
  SHARE_TOKEN,
  TOKEN_CONFIRMATION,
  TOKEN_STATE,
  GET_DOCUMENT_PANEL_TAX_FORMS,
  GET_DOCUMENT_PANEL_TAX_FORMS_ADMIN,
  GET_DOCUMENT_PANEL_TAX_FORMS_SUCCESS,
  PUT_BREAD_CRUMP,
  GET_VALUE_BREAD_CRUMPS,
  REMOVE_LAST_BREADCRUMP,
  GET_DOCUMENTS_TAX_PANEL,
  LOADING_PANEL_TAX,
  NEW_VALUE_BREADCRUMP
} from './types';
import messageValidationFailed from '../../utils/helpers/messageValidationFailed';
import formsErrors from '../../utils/errors/formsErrors';
import axios from 'axios';
import cookieHandler from '../../utils/helpers/cookie.handler';

function* showFolders({ payload }) {
  try {
    const response = yield call(getFolders, payload);
    yield put({ type: LOADING_FOLDER, payload: true });
    if (payload === '') {
      yield put({ type: LIST_FOLDERS, payload: response.data });
      yield put({ type: ROOT_DIRECTORY, payload: [] });
      yield put({ type: DOCUMENT_LIST, payload: [] });
      yield put({ type: LOADING_FOLDER, payload: false });
    } else {
      yield put({ type: LIST_FOLDERS, payload: response.data.folder_list });
      yield put({ type: ROOT_DIRECTORY, payload: response.data.root_directory });
      yield put({ type: DOCUMENT_LIST, payload: response.data.document_list });
      yield put({ type: LOADING_FOLDER, payload: false });
    }
  } catch (e) {
    // TODO: handle error
    yield put({ type: LOADING_FOLDER, payload: false });
    console.log(e);
  }
}

function* showTemplateFolders({ payload }) {
  try {
    const { uuid, project } = payload;
    yield put({ type: LOADING_FOLDER, payload: true });
    if (uuid === '') {
      const response = yield call(getTemplateFolders, `?project=${project}`);
      yield put({ type: LIST_FOLDERS, payload: response.data });
      yield put({ type: ROOT_DIRECTORY, payload: [] });
      yield put({ type: DOCUMENT_LIST, payload: [] });
      yield put({ type: LOADING_FOLDER, payload: false });
    } else {
      const response = yield call(getTemplateFolders, `/${uuid}/?project=${project}`);
      yield put({ type: LIST_FOLDERS, payload: response.data.folder_template_list });
      yield put({ type: ROOT_DIRECTORY, payload: response.data.root_directory });
      yield put({ type: LOADING_FOLDER, payload: false });
    }
  } catch (e) {
    // TODO: handle error
    yield put({ type: LOADING_FOLDER, payload: false });
    console.log(e);
  }
}

function* createFolder({ payload }) {
  let errors = [];
  try {
    yield put({ type: LOADING_NEW_FOLDER, payload: true });
    yield call(postFolder, payload);
    yield put({ type: FOLDER_ERRORS, payload: [] });
    window.location.reload();
    yield put({ type: LOADING_NEW_FOLDER, payload: false });
  } catch (e) {
    const { response } = e;
    if (response.status === 400) {
      if (response.data.folder_name) {
        errors = [...errors, messageValidationFailed(response.data.folder_name, 'el nombre de su nueva carpeta.')];
      }
      yield put({ type: FOLDER_ERRORS, payload: errors });
      yield put({ type: LOADING_DOCUMENT, payload: false });
    }
    yield put({ type: LOADING_NEW_FOLDER, payload: false });
    // TODO: handle error
    console.log(e);
  }
}

const axiosPost = (payload) => {
  const { getAccessToken } = cookieHandler();
  const token = getAccessToken();
  const headers = {
    'Authorization': `Bearer ${token}`,
  }
  return axios.post(`${process.env.REACT_APP_SERVICE_URL}/api/v1/documents/document/`, payload, { headers })
}

function* uploadDocument({ payload }) {
  let errors = [];
  try {
    yield put({ type: SET_DOCUMENT_ERRORS, payload: null });
    yield put({ type: LOADING_DOCUMENT, payload: true });
    const response = yield call(axiosPost, payload);
    if (response)
      yield put({ type: LOADING_DOCUMENT, payload: false });
  } catch (e) {
    yield put({ type: LOADING_DOCUMENT, payload: true });
    const { response } = e;
    if (response.status === 400) {
      if (response.data.file_csv) {
        errors = [...errors, messageValidationFailed(response.data.file_csv, 'archivo csv.')];
      }
      yield put({ type: SET_DOCUMENT_ERRORS, payload: errors });
      yield put({ type: LOADING_DOCUMENT, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_DOCUMENT_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: LOADING_DOCUMENT, payload: false });
    }
    // TODO: handle error
    console.log(e);
  }
}

function* showUserFolders({ payload, uuidProject }) {
  try {
    //const payloadUuid = payload.substring(10)
    const response = yield call(getUserFolders, payload);
    yield put({ type: LOADING_FOLDER, payload: true });
    if (payload === uuidProject) {
      yield put({ type: LIST_FOLDERS, payload: response.data });
      yield put({ type: ROOT_DIRECTORY, payload: [] });
      yield put({ type: DOCUMENT_LIST, payload: [] });
      yield put({ type: LOADING_FOLDER, payload: false });
    } else {
      yield put({ type: LIST_FOLDERS, payload: response.data.folder_list });
      yield put({ type: ROOT_DIRECTORY, payload: response.data.root_directory });
      yield put({ type: DOCUMENT_LIST, payload: response.data.document_list });
      yield put({ type: LOADING_FOLDER, payload: false });
    }
  } catch (e) {
    // TODO: handle error
    yield put({ type: LOADING_FOLDER, payload: false });
    console.log(e);
  }
}

function* obtainToken({ payload }) {
  try {
    yield put({ type: LOADING_TOKEN, payload: true });
    const response = yield call(getDocumentToken, payload);
    yield put({ type: SHARE_TOKEN, payload: response.data.token });
    yield put({ type: LOADING_TOKEN, payload: false });
  } catch (e) {
    yield put({ type: LOADING_TOKEN, payload: false });
    // TODO: handle error
    console.log(e);
  }
}

function* deleteFolderTemplate({ payload }) {
  let errors = [];
  try {
    yield put({ type: LOADING_NEW_FOLDER, payload: true });
    yield put({ type: DELETE_ERRORS, payload: [] });
    yield call(deleteFolder, payload);
    yield put({ type: LOADING_NEW_FOLDER, payload: false });
    window.location.reload();
  } catch (e) {
    const { response } = e;
    if (response.status === 400) {
      if (response.data.folder_template) {
        errors = [...errors, 'La carpeta plantilla posee archivos dentro para alguna de sus variantes, para eliminar por favor borre esos archivos.'];
      }
      yield put({ type: DELETE_ERRORS, payload: errors });
      yield put({ type: LOADING_DOCUMENT, payload: false });
    }
    yield put({ type: LOADING_NEW_FOLDER, payload: false });
    // TODO: handle error
    console.log(e);
  }
}

function* deleteDocumentFile({ payload }) {
  try {
    yield call(deleteDocument, payload);
    window.location.reload();
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* patchFolder({ payload }) {
  let errors = [];
  try {
    yield put({ type: LOADING_NEW_FOLDER, payload: true });
    yield call(updateFolder, payload);
    yield put({ type: FOLDER_ERRORS, payload: [] });
    yield put({ type: LOADING_NEW_FOLDER, payload: false });
    window.location.reload();
  } catch (e) {
    const { response } = e;
    if (response.status === 400) {
      if (response.data.folder_name) {
        errors = [...errors, messageValidationFailed(response.data.folder_name, 'el nombre de su nueva carpeta.')];
      }
      yield put({ type: FOLDER_ERRORS, payload: errors });
      yield put({ type: LOADING_DOCUMENT, payload: false });
    }
    yield put({ type: LOADING_NEW_FOLDER, payload: false });
    // TODO: handle error
    console.log(e);
  }
}

function* patchDocument({ payload }) {
  let errors = [];
  try {
    yield put({ type: LOADING_NEW_FOLDER, payload: true });
    yield call(updateDocument, payload);
    yield put({ type: FOLDER_ERRORS, payload: [] });
    yield put({ type: LOADING_NEW_FOLDER, payload: false });
    window.location.reload();
  } catch (e) {
    const { response } = e;
    if (response.status === 400) {
      if (response.data.document_name) {
        errors = [...errors, messageValidationFailed(response.data.document_name, 'el nombre de su nueva carpeta.')];
      }
      yield put({ type: FOLDER_ERRORS, payload: errors });
      yield put({ type: LOADING_DOCUMENT, payload: false });
    }
    yield put({ type: LOADING_NEW_FOLDER, payload: false });
    // TODO: handle error
    console.log(e);
  }
}

function* tokenConfirmation({ payload }) {
  try {
    yield put({ type: TOKEN_STATE, payload: false });
    yield call(getToken, `?token=${payload}`);
    yield put({ type: TOKEN_STATE, payload: true });
  } catch (e) {
    yield put({ type: TOKEN_STATE, payload: false });
    yield put({ type: EXPIRED_TOKEN, payload: true });
    // TODO: handle error
    console.log(e);
  }
}

// Panel tax for Rol user
function* getDocumentPanelTaxForm({ payload }) {

  try {
    const { uuid, type } = payload;
    console.log(payload)
    if (type === "tax") {
      yield put({ type: LOADING_PANEL_TAX, payload: true });
      const response = yield call(getDocumentPanelTaxForms, uuid)
      yield put({ type: LOADING_FOLDER, payload: true });
      yield put({ type: LIST_FOLDERS, payload: response.data });
      yield put({ type: ROOT_DIRECTORY, payload: [] });
      yield put({ type: DOCUMENT_LIST, payload: [] });
      yield put({ type: LOADING_PANEL_TAX, payload: false });
    } else if (type === "taxForm") {
      yield put({ type: LOADING_PANEL_TAX, payload: true });
      const response = yield call(getDocumentPanelTaxForms, `${uuid}?form=true&debug=True`)
      const responseWithOrder = response.data.sort((a, b) => {
        const añoA = Number(a.folder_name)
        const añoB = Number(b.folder_name)
        return añoA - añoB
      })
      yield put({ type: LOADING_FOLDER, payload: true });
      yield put({ type: LIST_FOLDERS, payload: responseWithOrder });
      yield put({ type: ROOT_DIRECTORY, payload: [] });
      yield put({ type: DOCUMENT_LIST, payload: [] });
      yield put({ type: LOADING_PANEL_TAX, payload: false });
      if (response.length !== 0) {
        yield put({ type: LOADING_FOLDER, payload: false });
      }
    }

  } catch (error) {
    console.error(error)
  }
}

function* getDocumentPanelTaxFormYear({ payload }) {
  try {
    const type = payload.type
    if (type === 'taxFormYear') {
      yield put({ type: LOADING_PANEL_TAX, payload: true });
      const response = yield call(getDocumentPanelTaxForms, `${payload.uuid}?form=true&year=${payload.name}&debug=True`)
      const { root, form_type, uuid_holding, uuid_society, year, files } = response.data;
      const documents = files.map(file => ({
        Document: `${root}${form_type}/${uuid_holding}/${uuid_society}/${year}/${file}`,
        type: 'document', // Aquí agregas el campo 'type'
        file_name: `${file}`
      }));
      yield put({ type: LOADING_FOLDER, payload: true });
      yield put({ type: LIST_FOLDERS, payload: documents });
      yield put({ type: ROOT_DIRECTORY, payload: [] });
      yield put({ type: LOADING_FOLDER, payload: false });
      yield put({ type: LOADING_PANEL_TAX, payload: false });
    }
  } catch (error) {
    console.error(error)
  }
}
// End //



// Panel tax for rol Super Admin
function* getDocumentPanelTaxFormAdmin({ payload }) {
  try {
    const { uuid, type, name } = payload;
    if (type === "taxAdmin") {
      yield put({ type: LOADING_PANEL_TAX, payload: true });
      const response = yield call(documentTaxPanelFormsAdmins, uuid)
      yield put({ type: LOADING_FOLDER, payload: true });
      yield put({ type: GET_DOCUMENT_PANEL_TAX_FORMS_SUCCESS, payload: response.data })
      yield put({ type: LIST_FOLDERS, payload: response.data });
      yield put({ type: ROOT_DIRECTORY, payload: [] });
      yield put({ type: DOCUMENT_LIST, payload: [] });
      yield put({ type: LOADING_FOLDER, payload: false });
      yield put({ type: LOADING_PANEL_TAX, payload: false });
      yield put({ type: LOADING_PANEL_TAX, payload: false });
    } else if (type === "taxFormAdmin") {
      yield put({ type: LOADING_PANEL_TAX, payload: true });
      const response = yield call(documentTaxPanelFormsAdmins, `${uuid}?form=true&debug=True`)
      // toma los "años" de las carpeta y convierte en numerico y ordena
      const responseWithOrder = response.data.sort((a, b) => {
        const añoA = Number(a.folder_name)
        const añoB = Number(b.folder_name)
        return añoA - añoB
      })
      yield put({ type: LOADING_FOLDER, payload: true });
      yield put({ type: GET_DOCUMENT_PANEL_TAX_FORMS_SUCCESS, payload: response.data })
      yield put({ type: LIST_FOLDERS, payload: responseWithOrder });
      yield put({ type: ROOT_DIRECTORY, payload: [] });
      yield put({ type: DOCUMENT_LIST, payload: [] });
      yield put({ type: LOADING_FOLDER, payload: false });
      yield put({ type: LOADING_PANEL_TAX, payload: false });
    } else if (type === "taxFormYearAdmin") {
      yield put({ type: LOADING_PANEL_TAX, payload: true });
      yield put({ type: LOADING_PANEL_TAX, payload: true });
      const response = yield call(documentTaxPanelFormsAdmins, `${uuid}?form=true&year=${name}&debug=True`)
      const { root, form_type, uuid_holding, uuid_society, year, files } = response.data;
      const documents = files.map(file => ({
        Document: `${root}${form_type}/${uuid_holding}/${uuid_society}/${year}/${file}`,
        type: 'document', // Aquí agregas el campo 'type'
        file_name: `${file}`
      }));
      yield put({ type: LOADING_FOLDER, payload: true });
      yield put({ type: LIST_FOLDERS, payload: documents });
      yield put({ type: ROOT_DIRECTORY, payload: [] });
      yield put({ type: LOADING_FOLDER, payload: false });
      yield put({ type: LOADING_PANEL_TAX, payload: false });
      yield put({ type: LOADING_PANEL_TAX, payload: false });
    }



  } catch (error) {
    console.error(error)
  }
}
// End //




// Function yo breadCrump
function* breadCrump({ payload }) {
  const breadcrumb = yield select(state => state.documents.breadCrump);
  const newBreadcrumb = breadcrumb.concat(payload);
  yield put({ type: GET_VALUE_BREAD_CRUMPS, payload: newBreadcrumb })
}

function* removeLastBreadcrumb() {
  const breadcrumb = yield select(state => state.documents.breadCrump);
  const newBreadcrumb = breadcrumb.slice(0, breadcrumb.length - 1);
  yield put({ type: GET_VALUE_BREAD_CRUMPS, payload: newBreadcrumb });
}


function* newValueBreadCrump({ payload }) {
  yield put({ type: GET_VALUE_BREAD_CRUMPS, payload: payload })
}

function* watchShowFolders() {
  yield takeLatest(FETCH_LIST_DOCUMENTS, showFolders);
}


function* watchShowTemplateFolders() {
  yield takeLatest(FETCH_LIST_TEMPLATE, showTemplateFolders);
}

function* watchCreateFolder() {
  yield takeLatest(POST_FOLDER, createFolder);
}

function* watchUploadDocument() {
  yield takeLatest(POST_DOCUMENT, uploadDocument);
}

function* watchShowUserFolders() {
  yield takeLatest(LIST_USER_FOLDERS, showUserFolders);
}

function* watchObtainToken() {
  yield takeLatest(GET_DOCUMENT_TOKEN, obtainToken);
}

function* watchDeleteFolderTemplate() {
  yield takeLatest(DELETE_FOLDER, deleteFolderTemplate);
}

function* watchDeleteDocumentFile() {
  yield takeLatest(DELETE_DOCUMENT, deleteDocumentFile);
}

function* watchPatchFolder() {
  yield takeLatest(PATCH_FOLDER, patchFolder);
}

function* watchPatchDocument() {
  yield takeLatest(PATCH_DOCUMENT, patchDocument);
}

function* watchTokenConfirmation() {
  yield takeLatest(TOKEN_CONFIRMATION, tokenConfirmation);
}

function* watchgetDocumentPanelTaxForm() {
  yield takeLatest(GET_DOCUMENT_PANEL_TAX_FORMS, getDocumentPanelTaxForm)
}
function* watchgetDocumentPanelTaxFormAdmin() {
  yield takeLatest(GET_DOCUMENT_PANEL_TAX_FORMS_ADMIN, getDocumentPanelTaxFormAdmin)
}
function* watchBreadcrumb() {
  yield takeLatest(PUT_BREAD_CRUMP, breadCrump)
}

function* watchRemoveLastBreadcrumb() {
  yield takeLatest(REMOVE_LAST_BREADCRUMP, removeLastBreadcrumb)
}

function* watchGetDocumentPanelTaxFormYear() {
  yield takeLatest(GET_DOCUMENTS_TAX_PANEL, getDocumentPanelTaxFormYear)
}
function* watchNewValueBreadCrump() {
  yield takeLatest(NEW_VALUE_BREADCRUMP, newValueBreadCrump)
}
const saga = [
  watchShowFolders(),
  watchShowTemplateFolders(),
  watchCreateFolder(),
  watchUploadDocument(),
  watchShowUserFolders(),
  watchObtainToken(),
  watchDeleteFolderTemplate(),
  watchDeleteDocumentFile(),
  watchPatchFolder(),
  watchPatchFolder(),
  watchPatchDocument(),
  watchTokenConfirmation(),
  watchgetDocumentPanelTaxForm(),
  watchgetDocumentPanelTaxFormAdmin(),
  watchBreadcrumb(),
  watchRemoveLastBreadcrumb(),
  watchGetDocumentPanelTaxFormYear(),
  watchNewValueBreadCrump()
];

export default saga;
