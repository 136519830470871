import React, { useState } from 'react';
import { Button, Card, Dropdown, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trash } from 'react-feather'
import { DELETE_PROJECT } from '../../store/project/types';
import { DELETE_LOG } from '../../store/logs/types';

const ProjectDelete = ({ setFlagDelete, uuid, deleteProjectData, logDelete }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    logDelete(false);
    setShow(false);
  };
  const handleShow = () => {
    logDelete(true);
    setShow(true);
  };

  const handleDelete = () => {
    handleClose();
    setFlagDelete(true);
    deleteProjectData(uuid);
  }

  return (
    <>
      <Dropdown.Item
        data-toggle="tooltip"
        data-placement="top"
        title="Eliminar proyecto"
        className="text-danger font-weight-bold"
        onClick={handleShow}
      >
        Eliminar
      </Dropdown.Item>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton/>
        <Modal.Body>
          <Card.Body className=" text-center">
            <Trash className='text-danger' size="26"></Trash>
            <h3 className="py-2 font-weight-bold title-color text-center">
              Eliminar Proyecto
            </h3>
            <p className="text-center mb-0">
              ¿Estás seguro que deseas eliminar este proyecto? <br></br><i className='small text-muted'>(Al eliminar este proyecto se eliminaran todos los grupos de sociedad, sociedades y usuarios asociados a este)</i></p>

            <Button
              className="btn-rounded base-btn-color mt-4"
              variant="primary"
              size="md"
              onClick={handleDelete}
            >
              Eliminar
            </Button>
          </Card.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

ProjectDelete.propTypes = {
  deleteProjectData: PropTypes.func.isRequired,
  logDelete: PropTypes.func.isRequired
};

const actionsToProps = dispatch => ({
  deleteProjectData: payload => dispatch({ type: DELETE_PROJECT, payload }),
  logDelete: payload => dispatch({ type: DELETE_LOG, payload })
});

export default connect(null, actionsToProps)(ProjectDelete);
