import React from 'react';
import SharedFile from '../../components/sharedFile';

const ShareView = () => {

  return (
    <>
      <SharedFile/>
    </>
  );
};

export default ShareView;
