import React, { useEffect, useState, useRef } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import Alfred9 from '../../images/AlfredHeader.png'
import { Send } from 'react-feather';
import { useSpring, animated } from 'react-spring';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { connect } from 'react-redux';
import Alfred from '../../images/chatBotAvatar.png'
import { CSSTransition } from 'react-transition-group';

const ChatBot = ({
    userProfile,
}) => {

    const [email, setEmail] = useState('')

    const messagesRef = useRef(null)

    const [showDialog, setShowDialog] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState(false);

    // Respuesta de la api
    const [responseApi, setResponse] = useState('');

    // Inicializar el índice del último mensaje a -1
    const [load, setLoad] = useState(true)

    const [askList, setAskList] = useState([]);

    const [name, setName] = useState('')

    // Respuesta
    const [responseAsk, setResponseAsk] = useState([])

    // Pregunta
    const [ask, setAsk] = useState('Hola, soy Alfred tu asistente virtual de impuestos. Quiero recordarte que aunque tengo muchos conocimiento de la legislación vigente, te puedo dar una guía pero no reemplaza la asesoría de un experto en el área. Todos los días estoy incorporando nuevos conocimientos.');

    const [id, setId] = useState('')

    useEffect(() => {
        if (userProfile && userProfile.user && userProfile.user.first_name) {
            setEmail(userProfile.user.email)
        }
    }, [userProfile])

    // Muestra el ultimo mensaje 

    useEffect(() => {
        setTimeout(() => {
            if (messagesRef.current) {
                messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
            
                if (askList.length > responseAsk.length) {
                  // Ajustar la altura al 50% si se envió una pregunta
                  messagesRef.current.style.height = '90%';
                } else {
                  // Ajustar la altura al 100% si se recibió una respuesta
                  messagesRef.current.style.height = '100%';
                }
              }
        }, 0)
      }, [askList, responseAsk]);

    // Captando nombre del usuario
    useEffect(() => {
        if (userProfile && userProfile.user && userProfile.user.first_name) {
            const firstName = userProfile.user.first_name;
            setName(firstName.charAt(0).toLocaleUpperCase() + firstName.slice(1));
        }
    }, [userProfile, setName]);
    // Accediendo al endpoint de la api
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_ALFRED_URL}`, {
                    withCredentials: true,
                    // other custom settings for this specific request
                });
                debugger;
                setResponse(response.data.answer);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [setResponse, axios]);

    // Capta el id
    useEffect(() => {
        if (responseAsk.length > 0) {
            setId(responseAsk[0].session_id);
        }
    }, [responseAsk]);

    useEffect(() => {
        // Enviar la pregunta automáticamente al cargar la página
        handleSubmit({ preventDefault: () => { } });
    }, []); // Dependencia vacía para que se ejecute solo una vez al cargar la página

    // Metodo post
    const handleSubmit = event => {
        if (ask.length === 0) {
            event.preventDefault();
            alert('El campo necesita contener una pregunta!')
        } else {
            event.preventDefault();
            setAskList(prevList => [...prevList, ask]);

            const options = {
                headers: {
                    'Authorization': 'Basic ' + btoa('EduardoPortalMagnus:jKuc53-xekACH-zJyeud-ap1JLs-HEeCKy')
                },
                timeout: 120000 // Adding a timeout of 2 minutes
            };

            const fetchData2 = async () => {
                try {
                    const requestData = {
                        withCredentials: true,
                        question: ask
                    };

                    if (id) {
                        requestData.session_id = id;
                    }

                    const Askresponse = await axios.post(`${process.env.REACT_APP_ALFRED_URL}/alfred/ask`, requestData, options);
                    debugger;
                    setResponseAsk(prevList => [...prevList, Askresponse.data]);
                    setLoad(false);
                    console.log(Askresponse);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            setAsk('')
            fetchData2();
            setLoad(true)
        }

    };

    // Send the input value to the question state
    const handleAsk = (event) => {
        event.preventDefault();
        const askValue = event.target.value;
        setAsk(askValue);
    };

    // Función para cambiar el estado después de cierto tiempo  
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowDialog(true);
        }, 2000); // Aparecer después de 2 segundos
        return () => clearTimeout(timer);
    }, []);





    return (

        email === 'john.droguett@rsmchile.cl' || email === 'g.argomedo@rsmchile.cl' || email === 'superadmin@mail.com' ||
        email === 'claudia.sossa@rsmchile.cl' || email === 'constanza.rodriguez@rsmchile.cl' || email === 'david.sandoval@rsmchile.cl' ||
        email === 'felipe.yanez@rsmchile.cl' || email === 'ingrid.navarrete@rsmchile.cl' || email === 'jose.delafuente@rsmchile.cl' ||
        email === 'natalia.ramirezca@rsmchile.cl' || email === 'roberto.melendez@rsmchile.cl' || email === 'sergio.ayala@rsmchile.cl' ||
        email === 'sergio.bernales@rsmchile.cl' || email === 'yulexis.almeida@rsmchile.cl' || 'victor.padilla@erpmagnus.com'  ? (
            <>
            <Row>
                <Col xs="12" sm="12" lg="12" xl="12" className='containerChatMobile'>
                    <Row className='headerChatMobile'>
                        <Col xs="6" sm="6" lg="6" xl="6" className='headerChatMobileTwo'>
                            <div className='imgChatMobile'>
                                {
                                    
                                     <img src={Alfred} alt="Alfred" width={80} /> 
                                     
                                }
                            </div>

                        </Col>
                        <Col xs="6" sm="6" lg="6" xl="6">
                            <h6>Pregúntale a Alfred</h6>
                        </Col>
                    </Row>

                    <Row className='bodyChatMobile' ref={messagesRef}>

                        <Col xs="12" sm="12" lg="12" xl="12" >
                            {askList.map((ask, index) => (
                                <div className="displaytMsgMobiel" key={index}>

                                    {
                                        ask === 'Hola, soy Alfred tu asistente virtual de impuestos. Quiero recordarte que aunque tengo muchos conocimiento de la legislación vigente, te puedo dar una guía pero no reemplaza la asesoría de un experto en el área. Todos los días estoy incorporando nuevos conocimientos.' ? (
                                            <div className="containerMsgUser" >
                                            
                                                    <small id='name_user_ask'>Alfred:</small>
                                            
                                                <h6>{ask}</h6>
                                            </div>
                                        ) : (
                                            <div className="containerMsg" >
                                                {name && (
                                                    <small id='name_user_ask'>{name}:</small>
                                                )}
                                                <h6>{ask}</h6>
                                            </div>
                                        )
                                    }




                                    {load && index === askList.length - 1 ? (
                                        <div className='loading'>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    ) : (
                                        ask && responseAsk[index] && responseAsk[index].answer && (
                                            <div className="containerMsgUser" id={ask === 'Hola, soy Alfred tu asistente virtual de impuestos. Quiero recordarte que aunque tengo muchos conocimiento de la legislación vigente, te puedo dar una guía pero no reemplaza la asesoría de un experto en el área. Todos los días estoy incorporando nuevos conocimientos.' ? 'msgUserNone' : ''}>
                                                <small id='name_user_ask'>Alfred:</small>
                                                <br />
                                                <h6>
                                                    {responseAsk[index].answer}
                                                </h6>

                                            </div>
                                        )
                                    )}
                                </div>
                            ))}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="12" lg="12" xl="12" className='containerInputPrimary'>
                            <form className='containerInput'>
                                <input type='text' id='inputChatMobile' value={ask} onChange={handleAsk} placeholder='Cual es el valor del IVA' />
                                <button type='submit' id='btnChatMobile' onClick={handleSubmit} >{<Send style={{ color: 'white' }} />}</button>
                            </form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
        ):(
            <>
                <div id='warningChat'>
                    <h1><Spinner animation="grow" variant="primary" /></h1>
                </div>
            </>
        )
      

    )

}



const stateToProps = state => ({
    userProfile: state.profile.userProfile,
});


export default connect(stateToProps)(ChatBot);
