import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { CheckCircle } from 'react-feather';
import FileUpload from '../file_upload';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { POST_DOCUMENT } from '../../store/document/types';

const DocumentUpload = ({postDocument, documentErrors, loadingDocument, uuid}) => {
  const [show, setShow] = useState(false);
  const [step, setStep] = React.useState(0);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [flagFile, setFlagFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceError, setServiceError] = useState(null);
  const [file, setFile] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [flag, setFlag] = useState(false);

  const sendData = () => {
    setServiceError(null);
    setLoading(true);
    setServiceError(null);
    const fd = new FormData();
    fd.append('file', file );
    fd.append('folder', uuid );
    postDocument(fd);
    setServiceError(documentErrors);
    setFlag(true);
  }

  useEffect(() => {
    setServiceError(null);
  }, [documentErrors]);

  useEffect(() => {
    if(flag){
      if (!loadingDocument && documentErrors !== null) {
        setServiceError(documentErrors);
        setLoading(false);
      }
      if (loadingDocument === false && documentErrors === null) {
        setStep(step + 1);
        setFlag(false);
      }
    }
  }, [loadingDocument, documentErrors, flag, step]);

  const alert = () => {
    if (serviceError) {
      return (
        serviceError.map((documentErrors) => {
          return (<div key={documentErrors}>
            {documentErrors !== undefined ? (
              `-${documentErrors}`
            ) : (
              ''
            )}
          </div>);
        })
      );
    }
  };

  useEffect(() => {
    if (
      !flagFile
    ) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [flagFile]);

  return (
    <>
      <Button
        variant="primary"
        size="lg"
        title="Subir archivo"
        onClick={handleShow}
        block
      >
        Subir documento
      </Button>

      <Modal show={show} size='xl' onHide={handleClose}>
        <Modal.Header closeButton/>
        <Modal.Body>
          <Card.Body className="text-center">
            <>
              <Form>
                {step === 0 && (
                  <Row className="mb-4">
                    <Col xl="5" lg="6" className="mx-auto">
                      <h4 className="font-weight-bold mb-4">Subir documento</h4>
                      <Form.Group controlId="image">
                        <Form.Label className="font-weight-bold small">Subir archivo</Form.Label>
                        <FileUpload
                          setFile={setFile}
                          file={file}
                          setFlag={setFlagFile}
                        />
                      </Form.Group>
                      {
                        serviceError ? (
                          <div className="alert alert-danger my-3" role="alert">
                            {alert()}
                          </div>
                        ) : null
                      }
                      <Button
                        className="mt-2"
                        onClick={sendData}
                        disabled={disableSubmit || loading || loadingDocument }
                        size="lg"
                        block
                      >
                        {
                          loading || loadingDocument ? (
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                          ) : (
                            ''
                          )
                        }{' '}
                        Aceptar
                      </Button>
                    </Col>
                  </Row>
                )}
                {step === 1 && (
                  <Row>
                    <Col xs="12" className="text-center">
                      <CheckCircle size="48" className="text-success mb-4"/>
                      <h5 className="font-weight-bold mb-4">Documento subido con éxito</h5>
                      <Button className="mx-2" onClick={() => {window.location.reload()}}>Ir atras</Button>
                    </Col>
                  </Row>
                )}
              </Form>
            </>
          </Card.Body>
        </Modal.Body>
      </Modal>
    </>

  );
};

DocumentUpload.propTypes = {
  postDocument: PropTypes.func.isRequired,
};

const stateToProps = state => ({
  documentErrors: state.documents.documentErrors,
  loadingDocument: state.documents.loadingDocument
});

const actionsToProps = dispatch => ({
  postDocument: payload => dispatch({ type: POST_DOCUMENT, payload })
});

export default connect(stateToProps, actionsToProps)(DocumentUpload);
