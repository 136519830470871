export const SET_IS_LOGGED_IN = 'auth/SET_IS_LOGGED_IN';
export const SET_AUTH_CHECKING = 'auth/SET_AUTH_CHECKING';
export const SET_AUTH_ERRORS = 'auth/SET_AUTH_ERRORS';
export const POST_LOGIN_PASSWORD = 'auth/POST_LOGIN_PASSWORD';
export const POST_LOGOUT = 'auth/POST_LOGOUT';
export const POST_REGISTER = 'auth/POST_REGISTER';
export const CONFIRM_TOKEN = 'auth/CONFIRM_TOKEN';
export const GET_INVITATION_TOKEN = 'auth/GET_INVITATION_TOKEN';
export const SEND_EMAIL = 'auth/SEND_EMAIL';
export const POST_EMAIL_RESET_PASSWORD = 'auth/POST_EMAIL_RESET_PASSWORD';
export const POST_DATA_RESET = 'auth/POST_DATA_RESET';
export const DISPATCH_CONFIRM_ACCOUNT = 'auth/DISPATCH_CONFIRM_ACCOUNT';
export const FETCH_COLOR = 'auth/FETCH_COLOR';
export const SITE_DATA = 'auth/SITE_DATA';
export const LOADING_DATA = 'auth/LOADING_DATA';
export const DATA_LOADED = 'auth/DATA_LOADED';
export const LOADING_EMAIL_FORM = 'auth/LOADING_EMAIL_FORM';
export const SET_EMAIL_ERRORS = 'auth/SET_EMAIL_ERRORS';
export const POST_CONTACT_EMAIL = 'auth/POST_CONTACT_EMAIL';
