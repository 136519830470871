import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { CheckCircle } from 'react-feather';

const SuccessRegister = () => {
  const history = useHistory();

    return (
      <Card className="border-0">
        <Card.Body className="text-center p-5">
          <img  alt="" className="mb-4" />
          <h3 className="py-2 font-weight-bold title-color text-center">
            Cuenta registrada exitosamente
          </h3>
          <CheckCircle size={70} className="font-weight-bold text-success my-3"/>
          <p className="text-center">
            Haz creado una cuenta en la plataforma Magnus para entrar a tu portal haz click en el botón.
          </p>
          <Button
            className="btn-rounded mt-4"
            variant="primary"
            onClick={() => history.push('/')}
          >
            Ir a inicio de sesión
          </Button>
        </Card.Body>
      </Card>
    );
};

export default SuccessRegister;
