import React, { useState } from 'react';
import { Button, Card, Dropdown, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DELETE_INVITATION } from '../../store/user/types';

const InvitationDelete = ({ setFlagDelete, uuid, deleteInvitation }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = () => {
    setFlagDelete(true);
    deleteInvitation(uuid);
  }

  return (
    <>
      <Dropdown.Item
        data-toggle="tooltip"
        data-placement="top"
        title="Eliminar proyecto"
        className="text-danger"
        onClick={handleShow}
      >
        Eliminar
      </Dropdown.Item>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton/>
        <Modal.Body>
          <Card.Body className="py-5 text-center">
            <i className="far fa-trash-alt text-danger text-center fa-3x mb-4"/>
            <h3 className="py-2 font-weight-bold title-color text-center">
              Eliminar invitación
            </h3>
            <p className="text-center">
              ¿Estás seguro que deseas eliminar esta invitación?
            </p>
            <div className="justify-content-center d-flex">
              <Button
                className="btn-rounded col-6 base-btn-color mt-4 py-2"
                variant="primary"
                onClick={handleDelete}
              >
                Seguro
              </Button>
            </div>
          </Card.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

InvitationDelete.propTypes = {
  deleteInvitation: PropTypes.func.isRequired
};

const actionsToProps = dispatch => ({
  deleteInvitation: payload => dispatch({ type: DELETE_INVITATION, payload })
});

export default connect(null, actionsToProps)(InvitationDelete
);
