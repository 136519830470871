import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import validator from '../../utils/validator';
import { loginSchema } from '../../utils/validator/schemas';
import { POST_LOGIN_PASSWORD } from '../../store/types';

const LoginPassword = ({ postLogin, loginErrors }) => {
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });
  const [validationError, setValidationError] = useState({ email: '', password: '' });

  const handleSubmit = event => {
    event.preventDefault();
    postLogin(loginForm);
  };

  const handleInputChange = ({ target }) => {
    const formData = { ...loginForm, [target.name]: target.value };
    setLoginForm(formData);
    setValidationError({ ...validationError, [target.name]: '' });

    const errors = validator({ [target.name]: loginSchema[target.name] }, formData);
    if (errors.length > 0) {
      const messages = errors
        .map(e => ({...validationError, [e.field]: e.message }))
        .reduce((prev, current) => ({ ...prev, ...current }), {});
      setValidationError(messages);
      return false;
    }
  };

  return (
    <>
      <h2 className="py-5 font-weight-bold text-center">
        Bienvenido
      </h2>
      <Form noValidate onSubmit={handleSubmit} className="pr-lg-4">
        {loginErrors ? (
          <div className="alert alert-danger my-1 p-2" role="alert">
            {loginErrors}
          </div>
        ) : null
        }
        <Form.Group controlId="loginUsername">
          <Form.Label className="font-weight-bold small">Correo electrónico</Form.Label>
          <FormControl
            name="email"
            type="email"
            placeholder="Ingresa tu correo electrónico"
            autoComplete="email"
            onChange={handleInputChange}
            isInvalid={validationError.email}
            size="lg"
          />
          <Form.Control.Feedback type="invalid">
            {validationError.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="loginPassword" className="mb-5 position-relative">
          <Form.Label className="font-weight-bold small">Contraseña</Form.Label>
          <FormControl
            name="password"
            type={'password'}
            placeholder="Ingresa tu contraseña"
            autoComplete="current-password"
            onChange={handleInputChange}
            isInvalid={validationError.password}
            size="lg"
            className="mb-2"
          />
          <a href={'/forgottenPassword'} className="text-primary small font-weight-bold float-right link-primary">¿Has olvidado tu contraseña?</a>
          <Form.Control.Feedback type="invalid">
            {validationError.password}
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          className="btn-block font-weight-bold"
          variant="primary"
          type="submit"
          size="lg"
        >
          {'Ingresar'}
        </Button>
      </Form>
    </>
  );
};

LoginPassword.propTypes = {
  postLogin: PropTypes.func.isRequired,
  loginErrors: PropTypes.string
};

const stateToProps = state => ({
  loginErrors: state.auth.errors
});

const actionsToProps = dispatch => ({
  postLogin: payload => dispatch({ type: POST_LOGIN_PASSWORD, payload })
});

export default connect(stateToProps, actionsToProps)(LoginPassword);
