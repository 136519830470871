import {
  DATA_BALANCE_8_COLUMNS,
  DATA_CASH_FLOW, DATA_EXIST, DATA_FINANCIAL_SITUATION, DATA_STATUS_RESULTS,
  DATES_BALANCE_8_COLUMNS,
  DATES_CASH_FLOW,
  DATES_FINANCIAL_SITUATION, DATES_STATUS_RESULTS, DETAIL_ACCOUNT,
  DETAIL_BALANCE_8_COLUMNS, DETAIL_FINANCIAL_SITUATION, DETAIL_STATUS_ACCOUNT, DETAIL_STATUS_RESULTS,
  INDEX_PAGINATION_ACCOUNTING,
  INDEX_PAGINATION_ACCOUNTING_DISABLED,
  IS_LOADED, IS_LOADED_ACCOUNT_DETAIL,
  IS_LOADED_ACCOUNTING,
  IS_LOADED_ACCOUNTING_DETAIL, IS_LOADED_FINANCIAL_SITUATION_DETAIL,
  LOADING_ACCOUNTING_FORM,
  SET_ACCOUNTING_ERRORS,
  SET_DATA_ACCOUNTING,
  SET_DATA_LIST_ACCOUNTING,
  SET_DATA_LIST_ACCOUNTING_DISABLED,
  SET_USER_DATA_LIST_ACCOUNTING
} from './types';

const initialState = {
  accountingList: [],
  disabledAccountingList: [],
  accountingUserList: [],
  accountingData:null,
  loadingForm: false,
  isLoaded:false,
  isLoadedAccounting: false,
  indexPagination:0,
  indexPaginationDisabled:0,
  errors: null,
  balance8ColumnsData: {},
  balance8ColumnsDataDetail: [],
  isLoadedAccountingDetail: false,
  balance8ColumnsDates: [],
  cashFlowDates: [],
  cashFlowData: {},
  financialSituationDates: [],
  financialSituationData: {},
  financialSituationDetail: {},
  isLoadedFinancialDetail: false,
  accountDetail: {},
  isLoadedAccountDetail: false,
  statusResultsDates: [],
  statusResultsData: {},
  statusResultsDetail: {},
  accountStatusDetail: {},
  dataExist: true
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case SET_DATA_LIST_ACCOUNTING:
    return { ...state, accountingList: payload };
  case SET_DATA_LIST_ACCOUNTING_DISABLED:
    return { ...state, disabledAccountingList: payload };
  case SET_DATA_ACCOUNTING:
    return { ...state, accountingData: payload };
  case LOADING_ACCOUNTING_FORM:
    return { ...state, loadingForm: payload };
  case SET_ACCOUNTING_ERRORS:
    return { ...state, errors: payload };
  case SET_USER_DATA_LIST_ACCOUNTING:
    return { ...state, accountingUserList: payload };
  case IS_LOADED:
    return { ...state, isLoaded: payload };
  case IS_LOADED_ACCOUNTING:
    return { ...state, isLoadedAccounting: payload };
  case INDEX_PAGINATION_ACCOUNTING:
    return { ...state, indexPagination: payload };
  case INDEX_PAGINATION_ACCOUNTING_DISABLED:
    return { ...state, indexPaginationDisabled: payload };
  case DATA_BALANCE_8_COLUMNS:
    return { ...state, balance8ColumnsData: payload };
  case DETAIL_BALANCE_8_COLUMNS:
    return { ...state, balance8ColumnsDataDetail: payload };
  case IS_LOADED_ACCOUNTING_DETAIL:
    return { ...state, isLoadedAccountingDetail: payload };
  case DATES_BALANCE_8_COLUMNS:
    return { ...state, balance8ColumnsDates: payload };
  case DATES_CASH_FLOW:
    return { ...state, cashFlowDates: payload };
  case DATA_CASH_FLOW:
    return { ...state, cashFlowData: payload };
  case DATES_FINANCIAL_SITUATION:
    return { ...state, financialSituationDates: payload };
  case DATA_FINANCIAL_SITUATION:
    return { ...state, financialSituationData: payload };
  case IS_LOADED_FINANCIAL_SITUATION_DETAIL:
    return { ...state, isLoadedFinancialDetail: payload };
  case DETAIL_FINANCIAL_SITUATION:
    return { ...state, financialSituationDetail: payload };
  case IS_LOADED_ACCOUNT_DETAIL:
      return { ...state, isLoadedAccountDetail: payload };
  case DETAIL_ACCOUNT:
      return { ...state, accountDetail: payload };
  case DATES_STATUS_RESULTS:
    return { ...state, statusResultsDates: payload };
  case DATA_STATUS_RESULTS:
      return { ...state, statusResultsData: payload };
  case DETAIL_STATUS_RESULTS:
    return { ...state, statusResultsDetail: payload };
  case DETAIL_STATUS_ACCOUNT:
    return { ...state, accountStatusDetail: payload };
  case DATA_EXIST:
    return { ...state, dataExist: payload };
  default:
    return state;
  }
};

export default reducer;
