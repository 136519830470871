import React, { useEffect, useState } from 'react';
import { Collapse, Spinner, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FETCH_ACCOUNT_DETAIL } from '../../store/accounting/types';
import { connect } from 'react-redux';
import { ChevronDown, ChevronRight } from 'react-feather';

const AccountItem = (
  {
    n,
    detail,
    uuid,
    date,
    money,
    showAccountDetail,
    accountDetail,
    isLoadedAccountDetail,
    society,
    collapseOpen,
    setCollapseOpen
  }) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    
    if(open){
      setOpen(false);
      if(n === collapseOpen)
        setOpen(true);
    }
  }, [open, collapseOpen, n, money]);

  return (
    <>
      <tr
        onClick={() => {
          showAccountDetail(`${uuid}/?society=${society}${date}&rule=IFRS&parent1=${detail.parent1}&cod_cta=${detail.cod_cta}&money_type=${money}`);
          setCollapseOpen(n);
          setOpen(!open);
        }}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        style={{cursor:"pointer"}}
      >
        <td className="p-2">{detail.cod_cta}</td>
        <td className="p-2">{detail.nom_cta}</td>
        <td className="p-2">{detail.imp_mov_mn_sg}</td>
        {
          !open ? (
            <ChevronRight/>
          ):(
            <ChevronDown/>
          )
        }
      </tr>
      <Collapse in={open}>

        <td colSpan={3} className="bg-light">
          {
            isLoadedAccountDetail && open ? (
              <div className="d-flex align-content-center justify-content-center my-3">
                <Spinner as="span" animation="border" size="xl" role="status" aria-hidden="true" />
              </div>
            ) : (
              <>
                <h5 className="font-weight-bold">Detalle de cuenta - {detail.nom_cta} </h5>
                <Table responsive borderless>
                  <thead>
                  <tr>
                    <th className="p-0">
                      <div className="p-2">
                        Fecha
                      </div>
                      <hr className="m-0"/>
                    </th>
                    <th className="p-0">
                      <div className="p-2">
                        Detalle
                      </div>
                      <hr className="m-0"/>
                    </th>
                    <th className="p-0">
                      <div className="p-2 text-right">
                        Importe MN
                      </div>
                      <hr className="m-0"/>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    accountDetail.table && (
                      accountDetail.table.map ((account) =>{
                        return(
                          <tr>
                            <td className="p-2" style={{width:'17%'}}>{account.fec_doc.slice(0,10)}</td>
                            {
                              account.com_mov ? (
                                <td className="p-2">{account.com_mov}</td>
                              ):
                                account.nivel1 ? (
                                  <td className="p-2">{account.nivel1}</td>
                                ):(
                                  <td className="p-2">{account.nivel2}</td>
                                )
                            }

                            <td className="p-2 text-right">{account.imp_mov_mn_sg}</td>
                          </tr>
                        );
                      })
                    )
                  }
                  {
                    accountDetail.footer && (
                      accountDetail.footer.total ? (
                        <tr>
                          <td className="p-2 font-weight-bold" colSpan={2}>Subtotal</td>
                          <td className="p-2 text-right font-weight-bold">{accountDetail.footer.total}</td>
                        </tr>
                      ):(
                        <tr>
                          <td className="p-2 font-weight-bold" colSpan={2}>Subtotal</td>
                          <td className="p-2 text-right font-weight-bold"> - </td>
                        </tr>
                      )
                    )
                  }
                  </tbody>
                </Table>
              </>
            )
          }
        </td>
      </Collapse>
    </>
  );
};

AccountItem.propTypes = {
  showAccountDetail: PropTypes.func.isRequired,
  accountDetail: PropTypes.object,
  isLoadedAccountDetail: PropTypes.bool,
};

const stateToProps = state => ({
  accountDetail: state.accounting.accountDetail,
  isLoadedAccountDetail: state.accounting.isLoadedAccountDetail
});

const actionsToProps = dispatch => ({
  showAccountDetail: payload => dispatch({ type: FETCH_ACCOUNT_DETAIL, payload })
});

export default connect(stateToProps, actionsToProps)(AccountItem);
