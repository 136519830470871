import React, { useEffect } from 'react';
import { Breadcrumb, Col, Row, Button, Table } from 'react-bootstrap';
import { ConfigProvider } from 'react-avatar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ProjectItem from '../project_item';
import { Copy } from 'react-feather';
import { FETCH_USER } from '../../store/user/types';
import GroupItem from '../group_item';
import SocietyItem from '../society_item';

const UserDetail = ({ showUser, userData, userProfile }) => {
  const { uuid } = useParams();
  useEffect(() => {
    showUser(uuid);
  }, [showUser, uuid]);

  return (
    <>
      {
        userData !== null ? (


          <>
            <Row  className='d-flex align-items-center justify-content-beetwen' id='projectsAdmin'>

              <Col>
                <Breadcrumb className="small">
                  <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={
                      userProfile && (
                        userProfile.profile.user_role === "SUPER_ADMIN" ? (
                          `/admin/users`
                        ) : (
                          userProfile.profile.user_role === "PROJECT_ADMIN" ? (
                            `/admin/users#1%1?search=&project=${localStorage.project}&group=&society=&ordering=&user_role=`
                          ) : (
                            `/admin/users#1%1?search=&project=${localStorage.group}&group=&society=&ordering=&user_role=`)
                        )
                      )
                    }
                  >
                    Administración usuarios
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Detalle usuario</Breadcrumb.Item>
                </Breadcrumb>
                <h4 className="font-weight-bold mb-1 ml-3">{userData.user.first_name} {userData.user.last_name}</h4>
              </Col>

              <Col sm="4" lg="5" >
                <Row className="float-sm-right ml-1">
                  <div className="mt-3 mr-3">
                    <span className="font-weight-bold">ID:</span>
                    <span className="">{userData.user.uuid.substring(0, 10)}...</span>
                  </div>
                  <Button
                    className="m-0 mt-2 mr-3"
                    variant="outline-primary"
                    onClick={() => { navigator.clipboard.writeText(userData.user.uuid) }}
                  >
                    <Copy size={20} />
                  </Button>
                </Row>
              </Col>
            </Row>


            <hr />


            {
              userData.profile.user_description && (
                <Row className="mb-5">
                  <Col lg="6">
                    <h6 className="font-weight-bold">Descripción</h6>
                    <p className="small">{userData.profile.user_description}</p>
                  </Col>
                </Row>
              )
            }
            {
              userData.profile.rut_user && (
                <Row className="mb-5">
                  <Col lg="6">
                    <h6 className="font-weight-bold">RUT</h6>
                    <p className="small">{userData.profile.rut_user}</p>
                  </Col>
                </Row>
              )
            }
            <Row className="mb-5 'd-flex align-items-center justify-content-center">
              <Col lg="6" className='text-center'>
                <h6 className="font-weight-bold">Correo electrónico</h6>
                <p className="small">{userData.user.email}</p>
              </Col>
              <Col lg="6" className='text-center'>
                <h6 className="font-weight-bold">Estatus</h6>
                {
                  userData.user.enable ? (
                    <p className="small">Habilitado</p>
                  ) : (
                    <p className="small">Deshabilitado</p>
                  )
                }
              </Col>
            </Row>
            <hr />
            {
              userData.profile.user_magnus && (
                <Row className="mb-5">
                  <Col lg="6">
                    <h6 className="font-weight-bold">Usuario Magnus</h6>
                    <p className="small">{userData.profile.user_magnus}</p>
                  </Col>
                </Row>
              )
            }
            {
              userData.profile.position && (
                <Row className="mb-5">
                  <Col lg="6">
                    <h6 className="font-weight-bold">Cargo</h6>
                    <p className="small">{userData.profile.position}</p>
                  </Col>
                </Row>
              )
            }
            {
              userData.project && (
                userData.project.length !== 0 && (
                  <div className="my-5">
                    <Row>
                      <Col lg="6">
                        <h6 className="font-weight-bold">Proyecto</h6>
                      </Col>
                    </Row>
                    <Row>
                      {
                        userData.project.map((project) => {
                          return (
                            <Col xs="12">
                              <ProjectItem key={project.uuid} uuid={project.uuid} name={project.project_name} readOnly="true" />
                            </Col>
                          );
                        })
                      }
                    </Row>
                  </div>
                )
              )
            }
            {
              userData.group && (
                userData.group.length !== 0 && (
                  <div className="my-5">
                    <Row>
                      <Col lg="6">
                        <h6 className="font-weight-bold">Grupo</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        {
                          userData.group.map((group) => {
                            return (
                              <GroupItem key={group.uuid} uuid={group.uuid} name={group.group_name} readOnly="true" />
                            )
                          })
                        }
                      </Col>
                    </Row>
                  </div>
                )
              )
            }
            {
              userData.society && (
                userData.society.length !== 0 && (
                  <div className="my-5">
                    <Row>
                      <Col lg="6">
                        <h6 className="font-weight-bold">Sociedad</h6>
                      </Col>
                    </Row>
                    <Table striped={false} hovere>
                      <tbody>
                        {
                          userData.society.map((society) => {
                            return (
                              <SocietyItem key={society.uuid} uuid={society.uuid} name={society.society_business_name} readOnly="true" />
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </div>
                )
              )
            }


          </>
        ) : (
          ""
        )
      }
    </>
  )
}

UserDetail.propTypes = {
  showUser: PropTypes.func.isRequired,
  userData: PropTypes.object,
  isLoadedUser: PropTypes.bool.isRequired
};

const stateToProps = state => ({
  userData: state.users.userData,
  isLoadedUser: state.users.isLoadedUser,
  userProfile: state.profile.userProfile
});

const actionsToProps = dispatch => ({
  showUser: payload => dispatch({ type: FETCH_USER, payload })
});

export default connect(stateToProps, actionsToProps)(UserDetail);
