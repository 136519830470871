const formsErrors = {
  REQUIRED: '¡Oops! Este campo es obligatorio, por favor complete',
  RUT_TAKEN: '¡Vaya! El rut ingresado ya se registró.',
  FORMAT_ERROR: '¡Oops! Error en formato de',
  ERROR_LENGTH: '¡Oops! Error en largo de',
  FIELD_BLANK: '¡Oops! Por favor complete',
  PROJECT_TAKEN: '¡Vaya! El proyecto ingresado ya se registró.',
  PROJECT_NAME_TAKEN: '¡Vaya! El nombre de proyecto ingresado ya se registró.',
  TRY_AGAIN: '¡Oops! Parece que algo salío mal. Por favor, vuelve a intentarlo más tarde.',
  FORMAT_NOT_ALLOWED: '¡Oops! Error en formato de',
  NOT_FOUNT: 'Grafico no encontrado',
  DATA_CONFLICT : 'Conflicto en los datos del formulario , revise si los campos se encuentran ingresados correctamente.',
  DATA_CONFLICT_PARAMS : 'Conflicto en los datos de parametros, revise si se encuentran ingresados correctamente',
  URL_EXISTS: 'La URL ingresada ya fue registrada',
  USER_NOT_FOUND: '¡Oops! El correo ingresado no está registrado.',
  PASSWORD_EXISTS: '¡Oops! La contraseña ingresada debe ser diferente de la actual.',
  SAME_DATA : 'Ya existe este nombre, por favor cambie',
  INVALID: 'Elección invalida de modulo, por favor escoja otro.',
  ADMIN_ERROR: 'El administrador seleccionado ya se encuentra en otro proyecto',
  EMAIL_NOT_EXISTS: '¡Oops! El correo ingresado no está registrado.',
  ACTION_NOT_ALLOWED: 'Acción no permitida, cambio no realizado'
};

export default formsErrors;
