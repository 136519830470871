import axios from 'axios';
import loginNetwork from './auth';
import projectNetwork from './project';
import groupNetwork from './group';
import societyNetwork from './society';
import profileNetwork from './profile';
import userNetwork from './user';
import documentNetwork from './document';
import reportNetwork from './report';
import accountingNetwork from './accounting';
import logsNetwork from './logs';
import config from '../config';
import authInterceptor from './interceptors/authorization';

const appConfig = config();
const httpConfig = {
  baseURL: appConfig.serviceUrl,
  timeout: appConfig.timeout
};

const http = axios.create(httpConfig);
const httpAuth = axios.create(httpConfig);

const authorization = authInterceptor(httpAuth);
httpAuth.interceptors.request.use(authorization.authRequest, error => Promise.reject(error));
httpAuth.interceptors.response.use(response => response, authorization.authResponse);

const login = loginNetwork(http);
const profile = profileNetwork(httpAuth);
const project = projectNetwork(httpAuth);
const group = groupNetwork(httpAuth);
const society = societyNetwork(httpAuth);
const user = userNetwork(httpAuth);
const report = reportNetwork(httpAuth);
const document = documentNetwork(httpAuth);
const accounting = accountingNetwork(httpAuth);
const logs = logsNetwork(httpAuth);

export const getReportsRef = report.getReportsRef
export const documentTaxPanelFormsAdmins = document.documentTaxPanelFormsAdmins
export const getDocumentPanelTaxForms = document.getDocumentPanelTaxForms
export const loginPassword = login.loginPassword;
export const refreshToken = login.refreshToken;
export const getProject = project.getProject;
export const postProject = project.postProject;
export const updateProject = project.updateProject;
export const getProjectData = project.getProjectData;
export const getProjectAdmins = project.getProjectAdmins;
export const deleteProject = project.deleteProject;
export const getGroups = group.getGroups;
export const postGroup = group.postGroup;
export const updateGroup = group.updateGroup;
export const getGroupData = group.getGroupData;
export const getGroupAdmins = group.getGroupAdmins;
export const deleteGroup = group.deleteGroup;
export const getSociety = society.getSociety;
export const postSociety = society.postSociety;
export const updateSociety = society.updateSociety;
export const getSocietyData = society.getSocietyData;
export const getSocietyAdmins = society.getSocietyAdmins;
export const deleteSociety = society.deleteSociety;
export const getSocietiesUser = society.getSocietiesUser;
export const getProjectPDF = project.getProjectPDF;
export const getGroupPDF = group.getGroupPDF;
export const getSocietyPDF = society.getSocietyPDF;
export const getProfile = profile.getProfile;
export const searchProject = project.searchProject;
export const searchGroup = group.searchGroup;
export const searchSociety = society.searchSociety;
export const getUserList = user.getUserList;
export const searchUser = user.searchUser;
export const sendInvitation = user.sendInvitation;
export const getTokenInvitation = login.getTokenInvitation;
export const register = login.register;
export const registerCsv = user.registerCsv;
export const getReports = report.getReports;
export const deleteReport = report.deleteReport;
export const getReportData = report.getReportData;
export const postReport = report.postReport;
export const updateReport = report.updateReport;
export const getUserReports = report.getUserReports;
export const getUserReportData = report.getUserReportData;
export const getModulesList = report.getModulesList;
export const searchReports = report.searchReports;
export const sendEmail = login.sendEmail;
export const resetPassword = login.resetPassword;
export const confirmToken = login.confirmToken;
export const updatePassword = profile.updatePassword;
export const getUserData = user.getUserData;
export const deleteUser = user.deleteUser;
export const updateUser = user.updateUser;
export const getInvitationsList = user.getInvitationsList;
export const deleteInvitation = user.deleteInvitation;
export const resendInvitation = user.resendInvitation;
export const getInvitationsSearchList = user.getInvitationsSearchList;
export const getFolders = document.getFolders;
export const getTemplateFolders = document.getTemplateFolders;
export const postFolder = document.postFolder;
export const postDocument = document.postDocument;
export const getUserFolders = document.getUserFolders;
export const getDocumentToken = document.getDocumentToken;
export const deleteFolder = document.deleteFolder;
export const deleteDocument = document.deleteDocument;
export const updateFolder = document.updateFolder;
export const updateDocument = document.updateDocument;
export const obtainColor = login.obtainColor;
export const getToken = document.getToken;
export const getAccountingList = accounting.getAccountingList;
export const deleteAccounting = accounting.deleteAccounting;
export const getAccountingData = accounting.getAccountingData;
export const postAccounting = accounting.postAccounting;
export const updateAccounting = accounting.updateAccounting;
export const searchAccounting = accounting.searchAccounting;
export const getUserAccountingList = accounting.getUserAccountingList;
export const getBalance8Columns = accounting.getBalance8Columns;
export const getBalance8ColumnsDetail = accounting.getBalance8ColumnsDetail;
export const balance8columnsDates = accounting.balance8columnsDates;
export const cashFlowDates = accounting.cashFlowDates;
export const getCashFlow = accounting.getCashFlow;
export const getFinancialSituation = accounting.getFinancialSituation;
export const financialSituationDates = accounting.financialSituationDates;
export const getFinancialSituationDetail = accounting.getFinancialSituationDetail;
export const getAccountDetail = accounting.getAccountDetail;
export const postSessionLogout = logs.postSessionLogout;
export const postBrowseData = logs.postBrowseData;
export const getIndustries = society.getIndustries;
export const postIndustry = society.postIndustry;
export const updateIndustry = society.updateIndustry;
export const deleteIndustry = society.deleteIndustry;
export const getIndustryData = society.getIndustryData;
export const getStatusResults = accounting.getStatusResults;
export const statusResultsDates = accounting.statusResultsDates;
export const getStatusResultsDetail = accounting.getStatusResultsDetail;
export const getStatusAccountDetail = accounting.getStatusAccountDetail;
export const sendTicket = profile.sendTicket;
export const sendEmailContact = login.sendEmailContact;
export const getNotifications = profile.getNotifications;
export const getGroupUsers = group.getGroupUsers;
export const getUserGroups = group.getUserGroups;
export const getSocietyUsers = society.getSocietyUsers;
export const getUserProjects = project.getUserProjects;
export const getUserSocieties = society.getUserSocieties;
export const patchProfile = profile.patchProfile;
