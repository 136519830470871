import React, { useEffect, useState } from 'react';
import {
  Row,
  Breadcrumb,
  Col,
  Button,
  Form,
  Spinner,
  Accordion,
  Card,
  Dropdown,
  FormGroup,
  FormControl
} from 'react-bootstrap';
import { Link, Prompt, useParams } from 'react-router-dom';
import { CheckCircle, ChevronRight, Plus, X } from 'react-feather';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_LIST_GROUPS } from '../../store/group/types';
import { FETCH_MODULES, FETCH_REPORT, PATCH_REPORT } from '../../store/report/types';
import ImageUploadEdit from '../image_upload/imageEdit';
import { FETCH_LIST_PROJECTS } from '../../store/project/types';
import { FETCH_LIST_SOCIETIES } from '../../store/society/types';

const EditReport = (
  {
    isLoadedReport,
    patchReports,
    errors,
    loadingForm,
    showGroups,
    groupsList,
    showReport,
    reportData,
    showProjects,
    projectsList,
    showModulesList,
    listModules,
    showSocieties,
    societiesList,
    userProfile,
    groupsFilteredList
  }) => {
  const { uuid } = useParams();
  const [auxTool, setAuxTool] = useState();
  const [step, setStep] = useState(0);
  const [triggerForm, setTriggerForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promptAux, setPromptAux] = useState(true);
  const [flag, setFlag] = useState(false);
  const [serviceError, setServiceError] = useState(null);
  const [listParameters, setListParameters] = useState([]);
  const [auxProjectSelected, setAuxProjectSelected] = useState([]);
  const [indexParameters, setIndexParameters] = useState(0);
  const [arrayParams, setArrayParams] = useState([]);
  const [auxProject, setAuxProject] = useState(false);
  let flagProject = false;
  const [auxGroup, setAuxGroup] = useState(false);
  let flagGroup = false;
  const [auxUserRole, setAuxUserRole] = useState(false);
  let flagUserRole = false;
  const [auxUser, setAuxUser] = useState(false);
  let flagUser = false;
  const [auxOther, setAuxOther] = useState(false);
  const [reportOneForm, setReportOneForm] = useState({});
  const [reportTwoForm, setReportTwoForm] = useState({
    project: [],
    group: [],
    society: [],
    enable_project_admin: false,
    enable_group_admin: false,
    enable_user_group: false,
    enable_user_society: false
  });
  const [reportThreeForm, setReportThreeForm] = useState({parameter: []});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [auxImage, setAuxImage] = useState(false);
  const [image, setImage] = useState([]);
  const [auxCheckLists, setAuxCheckLists] = useState({});
  const [projectFilters, setProjectFilters] = useState({
    projectSearch: "",
  });
  const [groupFilters, setGroupFilters] = useState({
    groupSearch: "",
    project: ""
  });
  const [societyFilters, setSocietyFilters] = useState({
    societySearch: "",
    project: "",
    group: ""
  });
  const [auxAllProjects, setAuxAllProjects] = useState([]);

  useEffect(() => {
    showReport(uuid);
  },[showReport, uuid]);

  useEffect(() => {
    window.onbeforeunload = (event) => {
      if(triggerForm === true){
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
      }
    };
  },);

  useEffect(() => {
    if(reportData)
      setAuxTool(reportData.tool_type);
  }, [reportData]);

  useEffect(() => {
    if(reportData){
      const aux = [];
      const array = [];
      const auxGroup = [];
      const auxSociety = [];
      if(reportData.project_list) {
        for(let i=0; i<reportData.project_list.length; i++){
          aux.push({ value: reportData.project_list[i].uuid, name:reportData.project_list[i].project_name });
          array.push(reportData.project_list[i].uuid)
        }
      }
      if(reportData.group_list) {
        for(let j=0; j<reportData.group_list.length; j++){
          auxGroup.push( reportData.group_list[j].uuid );
        }
      }
      if(reportData.society_list) {
        for(let j=0; j<reportData.society_list.length; j++){
          auxSociety.push( reportData.society_list[j].uuid );
        }
      }
      setAuxProjectSelected(aux);
      setAuxCheckLists({
        ...reportTwoForm,
        project: array,
        group:auxGroup,
        society:auxSociety,
        enable_project_admin: reportData.enable_project_admin,
        enable_group_admin: reportData.enable_group_admin,
        enable_user_group: reportData.enable_user_group,
        enable_user_society: reportData.enable_user_society
      });
      setReportTwoForm({
        ...reportTwoForm,
        enable_project_admin: reportData.enable_project_admin,
        enable_group_admin: reportData.enable_group_admin,
        enable_user_group: reportData.enable_user_group,
        enable_user_society: reportData.enable_user_society
      });
    }// eslint-disable-next-line
  },[reportData]);

  useEffect(() => {
    if(reportData){
      setIndexParameters(0)
      const array = [];
      const params = [];
      if(reportData.parameter_list){
        for(let i=0; i<reportData.parameter_list.length; i++){
          array.push({type:1, id:'other', uuid:i, show:true});
          params.push({
            id:i.toString(),
            show: true,
            parameter:{
              name: reportData.parameter_list[i].parameter_name,
              content: reportData.parameter_list[i].parameter_content,
              project: reportData.parameter_list[i].project.uuid,
              uuid:reportData.parameter_list[i].uuid
            }});
          setIndexParameters(i+1);
        }
      }
      if(reportData.project_parameter_name){
        array.push({type:1, id:'project'});
      }
      if(reportData.group_parameter_name){
        array.push({type:1, id:'group'});
      }
      if(reportData.user_role_parameter_name){
        array.push({type:1, id:'user_role_id'});
      }
      if(reportData.user_parameter_name){
        array.push({type:1, id:'user'});
      }
      setListParameters(array);
      setArrayParams(params);
    }
  }, [reportData, showReport]);

  const handleInputChange = ({ target }) => {
    if(target.id === "projectsCheckbox"){
      const formData = {...reportTwoForm};
      if(formData.project.includes(target.value)){
        const pos = formData.project.indexOf(target.value);
        formData.project.splice(pos,1);
        const auxForm = auxProjectSelected;
        auxForm.splice(pos,1);
        setReportTwoForm(formData);
        setAuxProjectSelected(auxForm);
      }else{
        formData.project.push(target.value);
        const aux = {name:target.name, value:target.value}
        setReportTwoForm(formData);
        auxProjectSelected.push(aux);
      }
      const array = [...auxCheckLists.project];
      if(array.includes(target.value)){
        const pos = array.indexOf(target.value);
        array.splice(pos,1);
        setAuxCheckLists({...auxCheckLists,  project: array });
      }else{
        array.push(target.value);
        setAuxCheckLists({...auxCheckLists,  project: array });
      }
    }
    if(target.id === "groupsCheckbox"){
      const formData = {...reportTwoForm}
      if(formData.group.includes(target.value)){
        const pos = formData.group.indexOf(target.value);
        formData.group.splice(pos, 1);
        setReportTwoForm({...reportTwoForm, group: formData.group});
      }else{
        formData.group.push(target.value);
        setReportTwoForm({...reportTwoForm, group: formData.group});
      }
      const array = [...auxCheckLists.group];
      if(array.includes(target.value)){
        const pos = array.indexOf(target.value);
        array.splice(pos,1);
        setAuxCheckLists({...auxCheckLists,  group: array });
      }else{
        array.push(target.value);
        setAuxCheckLists({...auxCheckLists,  group: array });
      }
    }
    if(target.id === "societiesCheckbox"){
      const formData = {...reportTwoForm}
      if(formData.society.includes(target.value)){
        const pos = formData.society.indexOf(target.value);
        formData.society.splice(pos, 1);
        setReportTwoForm({...reportTwoForm, society: formData.society});
      }else{
        formData.society.push(target.value);
        setReportTwoForm({...reportTwoForm, society: formData.society});
      }
      const array = [...auxCheckLists.society];
      if(array.includes(target.value)){
        const pos = array.indexOf(target.value);
        array.splice(pos,1);
        setAuxCheckLists({...auxCheckLists,  society: array });
      }else{
        array.push(target.value);
        setAuxCheckLists({...auxCheckLists,  society: array });
      }
    }
    if(!triggerForm){
      setTriggerForm(true)
    }
    if(step === 0 || step === 3){
      if(target.name === 'tool_type'){
        setAuxTool(target.value);
        if(target.value === 'QUICKSIGHT'){
          delete reportOneForm.url;
        }
        if(target.value === 'POWER_BY'){
          delete reportOneForm.aws_id_dashboard;
          delete reportOneForm.aws_username;
        }
      }
      const formData = { ...reportOneForm, [target.name]: target.value };
      setReportOneForm(formData);
    }
    if(step === 1){
      if(!(target.id === "projectsCheckbox" || target.id === "groupsCheckbox") || target.id === "societiesCheckbox"){
        if(target.id === "user_type"){
          if(target.name === "enable_project_admin"){
            const formData = {
              ...reportTwoForm,
              enable_project_admin: !reportTwoForm.enable_project_admin
            };
            setAuxCheckLists({...auxCheckLists, enable_project_admin:!auxCheckLists.enable_project_admin});
            setReportTwoForm(formData);
          }
          if(target.name === "enable_group_admin"){
            const formData = {
              ...reportTwoForm,
              enable_group_admin: !reportTwoForm.enable_group_admin
            };
            setAuxCheckLists({...auxCheckLists, enable_group_admin:!auxCheckLists.enable_group_admin});
            setReportTwoForm(formData);
          }
          if(target.name === "enable_user_group"){
            const formData = {
              ...reportTwoForm,
              enable_user_group: !reportTwoForm.enable_user_group
            };
            setAuxCheckLists({...auxCheckLists, enable_user_group:!auxCheckLists.enable_user_group});
            setReportTwoForm(formData);
          }
          if(target.name === "enable_user_society"){
            const formData = {
              ...reportTwoForm,
              enable_user_society: !reportTwoForm.enable_user_society
            };
            setAuxCheckLists({...auxCheckLists, enable_user_society:!auxCheckLists.enable_user_society});
            setReportTwoForm(formData);
          }
        }
      }
    }
    if(step === 2){
      if(target.name === "other_name"){
        const exists = arrayParams.some(x => x.id === target.dataset.txt);
        if(exists){
          for(let i=0;i<arrayParams.length;i++){
            if(arrayParams[i].id === target.dataset.txt){
              let aux = arrayParams;
              aux[i] = {...aux[i], parameter:{...arrayParams[i].parameter,  name: target.value } };
              setArrayParams(aux);
            }
          }
        }else{
          arrayParams.push({ id:indexParameters , parameter: { name:target.value }  });
        }
      }
      if(target.name === "other_value"){
        const exists = arrayParams.some(x => x.id === target.dataset.txt)
        if(exists){
          for(let i=0;i<arrayParams.length;i++){
            if(arrayParams[i].id === target.dataset.txt){
              let aux = arrayParams;
              aux[i] = {...aux[i], parameter:{...arrayParams[i].parameter,  content: target.value } };
              setArrayParams(aux);
            }
          }
        }else{
          arrayParams.push({ id:indexParameters , parameter: { content:target.value }  });
        }
      }
      if(target.name === "project_parameter"){
        const exists = arrayParams.some(x => x.id === target.dataset.txt)
        if(exists){
          for(let i=0;i<arrayParams.length;i++){
            if(arrayParams[i].id === target.dataset.txt){
              let aux = arrayParams;
              aux[i] = {...aux[i], parameter:{...arrayParams[i].parameter,  project: target.value } };
              setArrayParams(aux);
            }
          }
        }else{
          arrayParams.push({ id:indexParameters , parameter: { project:target.value }  });
        }
      }
      if(!(target.name === "other_value" || target.name === "other_name" || target.name === "project_parameter")){
        const formData = { ...reportThreeForm, [target.name]: target.value };
        setReportThreeForm(formData);
      }else{
        let auxArrayParams = reportThreeForm;
        for(let i=0;i<arrayParams.length;i++) {
          if(arrayParams[i].show && arrayParams[i].id === target.dataset.txt ){
            auxArrayParams.parameter[i] = arrayParams[i].parameter
          }
        }
        setReportThreeForm({...reportThreeForm, parameter: auxArrayParams.parameter});
      }
    }
  };

  const alert = () => {
    if (serviceError) {
      return (
        serviceError.map((error) => {
          return (<div key={error}>
            {error !== undefined ? (
              `-${error}`
            ) : (
              ''
            )}
          </div>);
        })
      );
    }
  };

  const sendData = () => {
    const parameters = []
    for(let i=0; i<reportThreeForm.parameter.length; i++){
      if(reportThreeForm.parameter[i] !== undefined)
        parameters.push(reportThreeForm.parameter[i]);
    }
    const fd = new FormData();
    let reportForm = { ...reportOneForm, ...reportTwoForm, ...reportThreeForm };
    if(reportThreeForm.parameter)
      reportForm = {...reportForm, parameter: JSON.stringify(parameters)};
    if(reportForm.project.length === 0)
      delete reportForm.project;
    if(reportForm.group.length === 0)
      delete reportForm.group;
    if(reportForm.society.length === 0)
      delete reportForm.society;
    if(auxProjectSelected.length === 0 || reportThreeForm.parameter.length===0)
      delete reportForm.parameter;
    const arrKeys = Object.keys(reportForm);
    const arrValues = Object.values(reportForm);
    let i = 0;
    arrKeys.forEach( o => {
      fd.append(o, arrValues[i] );
      i++;
    })
    if(auxImage){
      fd.append('image', image );
    }
    setPromptAux(false);
    setTriggerForm(false);
    setServiceError(null);
    setLoading(true);
    patchReports({ fd, uuid });
    setServiceError(errors);
    setFlag(true);
  }

  useEffect(() => {
    if(flag){
      if (!loadingForm && errors !== null) {
        setServiceError(errors);
        setLoading(false);
      }
      if (loadingForm === false && errors === null) {
        setStep(4);
        setFlag(false);
      }
    }
  }, [loadingForm, errors, flag]);

  useEffect(() => {
    if(step === 0){
      if (
        Object.keys(reportOneForm).some(key => reportOneForm[key] === '')
      ) {
        setDisableSubmit(true);
      } else {
        setDisableSubmit(false);
      }
    }
    if(step === 1){
      if(
        !reportTwoForm.enable_project_admin &&
        !reportTwoForm.enable_group_admin &&
        !reportTwoForm.enable_user_society &&
        !reportTwoForm.enable_user_group){
        setDisableSubmit(true);
      } else {
        setDisableSubmit(false);
      }
    }
    if( step === 2 || step === 3){
      setDisableSubmit(false);
    }
  }, [reportOneForm, reportTwoForm, step]);

  useEffect(() => {
    setServiceError(null);
  }, [errors]);

  useEffect(() => {
    showModulesList('?module_type=REPORT');
  }, [ showModulesList]);

  useEffect(() => {
    showProjects({ ...projectFilters });
  }, [showProjects, projectFilters]);

  useEffect(() => {
    showGroups({ ...groupFilters });
  }, [showGroups, groupFilters]);

  useEffect(() => {
    showSocieties({ ...societyFilters });
  }, [showSocieties, societyFilters]);

  useEffect(() => {
    if(projectsList.length !== 0 && auxAllProjects.length === 0) {
      setAuxAllProjects(projectsList);
    }// eslint-disable-next-line
  }, [projectsList, auxAllProjects]);

  const onChange = nextStep => {
    setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
  };
  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);

  const formVariable = () => {
    return (
      <>
        {
          listParameters.map( (parameter, n) => {
            if(parameter.id === 'project' && !auxProject && !flagProject){
              flagProject = true;
              return (
                <div key={n}>
                  <Col className="p-0">
                    <Form.Group controlId="project_parameter_name">
                      <Form.Label className="font-weight-bold small">
                        Id de proyecto
                        <span className="ml-1 text-danger small">
                        (*)
                      </span>
                      </Form.Label>
                      <Row>
                        <Col fluid="lg">
                          <Form.Control
                            size="lg"
                            placeholder="Ingresa id"
                            name="project_parameter_name"
                            type="project_parameter_name"
                            onChange={handleInputChange}
                            defaultValue={reportData.project_parameter_name}
                          />
                        </Col>
                        <Col xl={1} lg={1} md={1} sm={1} xs={1} className="pl-0">
                          <Button
                            size="lg"
                            className="bg-secondary border-secondary float-right"
                            onClick=
                              {
                                ()=>{
                                  const list = listParameters;
                                  list.splice(n,1);
                                  setReportThreeForm({...reportThreeForm, project_parameter_name: ''});
                                  setListParameters(list);
                                  setAuxProject(true);
                                }
                              }
                          >
                            <X size={20}/>
                          </Button>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </div>
              )
            }
            if(parameter.id === 'group' && !auxGroup && !flagGroup){
              flagGroup =true;
              return (
                <div key={n}>
                  <Col className="p-0">
                    <Form.Group controlId="group_parameter_name">
                      <Form.Label className="font-weight-bold small">
                        Id de grupo
                        <span className="ml-1 text-danger small">
                        (*)
                      </span>
                      </Form.Label>
                      <Row>
                        <Col fluid="lg">
                          <Form.Control
                            size="lg"
                            placeholder="Ingresa id"
                            name="group_parameter_name"
                            type="group_parameter_name"
                            onChange={handleInputChange}
                            defaultValue={reportData.group_parameter_name}
                          />
                        </Col>
                        <Col xl={1} lg={1} md={1} sm={1} xs={1} className="pl-0">
                          <Button
                            size="lg"
                            className="bg-secondary border-secondary float-right"
                            onClick=
                              {
                                ()=>{
                                  const list = listParameters;
                                  list.splice(n,1);
                                  setReportThreeForm({...reportThreeForm, group_parameter_name: ''});
                                  setListParameters(list);
                                  setAuxGroup(true);
                                }
                              }
                          >
                            <X size={20}/>
                          </Button>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </div>
              )
            }
            if(parameter.id === 'user_role_id' && !auxUserRole && !flagUserRole){
              flagUserRole = true;
              return (
                <div key={n}>
                  <Col className="p-0">
                    <Form.Group controlId="user_role_parameter_name">
                      <Form.Label className="font-weight-bold small">
                        Id del rol de usuario
                        <span className="ml-1 text-danger small">
                        (*)
                      </span>
                      </Form.Label>
                      <Row>
                        <Col fluid="lg">
                          <Form.Control
                            size="lg"
                            placeholder="Ingresa id"
                            name="user_role_parameter_name"
                            type="user_role_parameter_name"
                            onChange={handleInputChange}
                            defaultValue={reportData.user_role_parameter_name}
                          />
                        </Col>
                        <Col xl={1} lg={1} md={1} sm={1} xs={1} className="pl-0">
                          <Button
                            size="lg"
                            className="bg-secondary border-secondary float-right"
                            onClick=
                              {
                                ()=>{
                                  const list = listParameters;
                                  list.splice(n,1);
                                  setReportThreeForm({...reportThreeForm, user_role_parameter_name: ''});
                                  setListParameters(list);
                                  setAuxUserRole(true);
                                }
                              }
                          >
                            <X size={20}/>
                          </Button>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </div>
              )
            }
            if(parameter.id === 'user' && !auxUser && !flagUser){
              flagUser = true;
              return (
                <div key={n}>
                  <Col className="p-0">
                    <Form.Group controlId="user_parameter_name">
                      <Form.Label className="font-weight-bold small">
                        Id de usuario
                        <span className="ml-1 text-danger small">
                        (*)
                      </span>
                      </Form.Label>

                      <Row>
                        <Col fluid="lg">
                          <Form.Control
                            size="lg"
                            placeholder="Ingresa id"
                            name="user_parameter_name"
                            type="user_parameter_name"
                            onChange={handleInputChange}
                            defaultValue={reportData.user_parameter_name}
                          />
                        </Col>
                        <Col xl={1} lg={1} md={1} sm={1} xs={1} className="pl-0">
                          <Button
                            size="lg"
                            className="bg-secondary border-secondary float-right"
                            onClick=
                              {
                                ()=>{
                                  const list = listParameters;
                                  list.splice(n,1);
                                  setReportThreeForm({...reportThreeForm, user_parameter_name: ''});
                                  setListParameters(list);
                                  setAuxUser(true);
                                }
                              }
                          >
                            <X size={20}/>
                          </Button>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </div>
              )
            }
            if(parameter.id === 'other'){
              return (
                listParameters[n].show && (
                  auxProjectSelected.length !== 0 && (
                    <Card key={n} className="p-4 my-4">
                      <Row>
                        <Col fluid="lg">
                          <h5>Otro parametro {listParameters[n].uuid+1}</h5>
                        </Col>
                        <Col>
                          <Button
                            className="bg-secondary border-secondary float-right"
                            onClick=
                              {
                                ()=>{
                                  for(let i=0; i<listParameters.length; i++){
                                    if(listParameters[i].uuid === parameter.uuid){
                                      let list = listParameters;
                                      list[i].show = false;
                                      for(let j=0; j<arrayParams.length; j++){
                                        if(arrayParams[j].id === parameter.uuid.toString()){
                                          const array = arrayParams;
                                          array[j].show = false;
                                          setArrayParams(array);
                                        }
                                      }
                                      const auxArrayParams = [];
                                      for(let k=0;k<arrayParams.length;k++) {
                                        if(arrayParams[k].show)
                                          auxArrayParams.push(arrayParams[k].parameter);
                                        else{
                                          auxArrayParams.push({ uuid:arrayParams[k].parameter.uuid, name:"", project:"", content:"" });
                                        }
                                      }
                                      setReportThreeForm({...reportThreeForm, parameter: auxArrayParams});
                                      setListParameters(list);
                                      setAuxOther(true);
                                    }
                                  }
                                  if(auxOther)
                                    setAuxOther(false);
                                }
                              }
                          >
                            <X size={20}/>
                          </Button>
                        </Col>
                      </Row>
                      <Col lg={12}>
                        <Form.Group controlId="other_name">
                          <Form.Label className="font-weight-bold small">
                            Nombre parámetro
                            <span className="ml-1 text-danger small">
                            (*)
                          </span>
                          </Form.Label>
                          {
                            arrayParams[listParameters[n].uuid].parameter ? (
                              <Form.Control
                                size="lg"
                                placeholder="Ingresa nombre"
                                name="other_name"
                                type="other_name"
                                data-txt={listParameters[n].uuid}
                                onChange={handleInputChange}
                                defaultValue={arrayParams[listParameters[n].uuid].parameter.name}
                              />
                            ):(
                              <Form.Control
                                size="lg"
                                placeholder="Ingresa nombre"
                                name="other_name"
                                type="other_name"
                                data-txt={listParameters[n].uuid}
                                onChange={handleInputChange}
                              />
                            )
                          }
                        </Form.Group>
                        <Form.Group controlId="other_value">
                          <Form.Label className="font-weight-bold small">
                            Valor parámetro
                            <span className="ml-1 text-danger small">
                              (*)
                            </span>
                          </Form.Label>
                          {
                            arrayParams[listParameters[n].uuid].parameter ? (
                              <Form.Control
                                size="lg"
                                placeholder="Ingresa valor de parámetro"
                                name="other_value"
                                type="other_value"
                                data-txt={listParameters[n].uuid}
                                onChange={handleInputChange}
                                defaultValue={arrayParams[listParameters[n].uuid].parameter.content}
                              />
                            ):(
                              <Form.Control
                                size="lg"
                                placeholder="Ingresa valor de parámetro"
                                name="other_value"
                                type="other_value"
                                data-txt={listParameters[n].uuid}
                                onChange={handleInputChange}
                              />
                            )
                          }

                        </Form.Group>
                        <Form.Group controlId="project_parameter">
                          <Form.Label className="font-weight-bold small">Designar proyecto
                          </Form.Label>
                          <Form.Control
                            size="lg"
                            as="select" custom
                            name="project_parameter"
                            type="project_parameter"
                            data-txt={listParameters[n].uuid}
                            onChange={handleInputChange}
                          >
                            <option value='' className="secondary-text-color" disabled selected> - </option>
                            {
                              auxProjectSelected.map( (project) =>{
                                return (
                                  arrayParams[listParameters[n].uuid].parameter && (
                                    arrayParams[listParameters[n].uuid].parameter.project === project.value ? (
                                      <option value={project.value} key={project.value} selected>{project.name}</option>
                                    ):(
                                      <option value={project.value} key={project.value}>{project.name}</option>
                                    )
                                  )
                                );
                              })
                            }
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Card>
                  )
                )
              )
            }
            return null;
          })
        }
      </>
    );
  }

  return (
    reportData && isLoadedReport ? (
      <Form>
        <Row className="mb-4">
          <Prompt
            when={triggerForm && promptAux}
            message={`¿Estás seguro de desear abandonar este formulario? Los datos ingresados no serán guardados.`}
          />
          <Col>
            <Breadcrumb className="small">
              <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
              <Breadcrumb.Item href="/admin/reports">
                Administración de reportes
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Creación reporte</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md="8" lg="6" className="mx-auto">
            <div className="steps d-lg-flex justify-content-between align-items-center">
              <div className={`step-item ${step === 0 ? 'active' : ''}`}>
                <span className="step-item-indicator mr-3 small">1</span>
                <h6 className="step-item-title mb-0 small">Datos principales</h6>
              </div>
              <ChevronRight size="12" />
              <div className={`step-item ${step === 1 ? 'active' : ''}`}>
                <span className="step-item-indicator mr-3 small">2</span>
                <h6 className="step-item-title mb-0 small">Usuarios</h6>
              </div>
              <ChevronRight size="12" />
              <div className={`step-item ${step === 2 ? 'active' : ''}`}>
                <span className="step-item-indicator mr-3 small">3</span>
                <h6 className="step-item-title mb-0 small">Parámetros</h6>
              </div>
              <ChevronRight size="12" />
              <div className={`step-item ${step === 3 ? 'active' : ''}`}>
                <span className="step-item-indicator mr-3 small">4</span>
                <h6 className="step-item-title mb-0 small">Ubicación</h6>
              </div>
            </div>
          </Col>
        </Row>
        {step === 0 && (
          <Row className="mb-4">
            <Col xl="5" lg="6" className="mx-auto">
              <h4 className="font-weight-bold mb-4">Datos principales</h4>
              <Form.Group controlId="report_name">
                <Form.Label className="font-weight-bold small">
                  Título
                  <span className="ml-1 text-danger small">
                  (*)
                </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa un nombre"
                  name="report_name"
                  type="report_name"
                  defaultValue={reportData.report_name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="menu_name">
                <Form.Label className="font-weight-bold small">
                  Nombre de sub menú
                  <span className="ml-1 text-danger small">
                  (*)
                </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa un nombre"
                  name="menu_name"
                  type="menu_name"
                  defaultValue={reportData.menu_name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label className="font-weight-bold small">
                  Descripción
                  <span className="ml-1 text-danger small">
                  (*)
                </span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Escribe aquí..."
                  name="description"
                  type="description"
                  defaultValue={reportData.description}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="tool_type">
                <Form.Label className="font-weight-bold small">Tipo de herramienta</Form.Label>
                <Form.Control
                  as="select"
                  custom placeholder="Selecciona"
                  onChange={handleInputChange}
                  name="tool_type"
                  type="tool_type"
                  defaultValue={reportData.tool_type}
                >
                  <option value="POWER_BI">Power BI</option>
                  <option value="QUICKSIGHT">Quicksight</option>
                </Form.Control>
              </Form.Group>
              {
                auxTool === 'POWER_BI' && (
                  <Form.Group controlId="url">
                    <Form.Label className="font-weight-bold small">
                      URL
                      <span className="ml-1 text-danger small">
                      (*)
                    </span>
                    </Form.Label>
                    <Form.Control
                      size="lg"
                      placeholder="Ingresa un nombre"
                      name="url"
                      type="url"
                      defaultValue={reportData.url}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                )
              }
              {
                auxTool === 'QUICKSIGHT' && (
                  <>
                    <Form.Group controlId="aws_id_dashboard">
                      <Form.Label className="font-weight-bold small">
                        ID AWS dashboard
                        <span className="ml-1 text-danger small">
                      (*)
                    </span>
                      </Form.Label>
                      <Form.Control
                        size="lg"
                        placeholder="Ingresa un nombre"
                        name="aws_id_dashboard"
                        type="aws_id_dashboard"
                        defaultValue={reportData.aws_id_dashboard}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="aws_username">
                      <Form.Label className="font-weight-bold small">
                        Nombre usuario AWS
                        <span className="ml-1 text-danger small">
                      (*)
                    </span>
                      </Form.Label>
                      <Form.Control
                        size="lg"
                        placeholder="Ingresa un nombre"
                        name="aws_username"
                        type="aws_username"
                        defaultValue={reportData.aws_username}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </>
                )
              }
              <Form.Group controlId="image">
                <Form.Label className="font-weight-bold small">Imagen</Form.Label>
                <ImageUploadEdit
                  setLogo={setImage}
                  logo={image}
                  img={reportData.image}
                  imgName={'image'}
                  auxImage={auxImage}
                  setAuxImage={setAuxImage}
                />
              </Form.Group>
              <span className="text-danger">
              (*)
              <span className="text-black-50">
                Campos obligatorios
              </span>
            </span>
              {
                serviceError ? (
                  <div className="alert alert-danger my-3" role="alert">
                    {alert()}
                  </div>
                ) : null
              }
            </Col>
          </Row>
        )}
        {step === 1 && (
          <Row className="mb-4">
            <Col xl="5" lg="6" className="mx-auto">
              <h4 className="font-weight-bold mb-4">Parámetros</h4>
              <Form.Group controlId="project">
                <Form.Label className="font-weight-bold small">
                  Designar proyecto
                </Form.Label>
                <Accordion defaultActiveKey="1" className="mb-4">
                  <Card>
                    <Card.Header className="bg-transparent text-muted">
                      Seleccione
                    </Card.Header>
                    <Card.Body eventKey="1">
                      <Card.Body className="py-0">
                        <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                          <FormControl
                            className='mb-2'
                            size='md'
                            placeholder='Buscar...'
                            onChange={({target})=>setProjectFilters({ ...projectFilters, projectSearch: target.value })}
                          />
                        </FormGroup>
                        <Form.Group controlId="projectsCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                          {
                            projectsList.map( (project) =>{
                              return (
                                <Form.Check type="checkbox" key={project.uuid} label={project.project_name} name={project.project_name} value={project.uuid}  defaultChecked={auxCheckLists.project.includes(project.uuid)}/>
                              );
                            })
                          }
                        </Form.Group>
                      </Card.Body>
                    </Card.Body>
                  </Card>
                </Accordion>
              </Form.Group>
              <Form.Group controlId="group">
                <Form.Label className="font-weight-bold small">
                  Designar grupo de sociedad
                </Form.Label>
                <Accordion defaultActiveKey="1" className="mb-4">
                  <Card>
                    <Card.Header className="bg-transparent text-muted">
                      Seleccione
                    </Card.Header>
                    <Card.Header className="bg-transparent">
                      <Form.Group controlId="project" className="m-0">
                        <Form.Control
                          size="md"
                          as="select"
                          placeholder="Ingresa un correo"
                          name="project"
                          type="project"
                          onChange={({target})=>setGroupFilters({ ...groupFilters, project: target.value })}
                        >
                          <option value="" className="secondary-text-color" selected> Todos los proyectos </option>
                          {
                            auxAllProjects.map( (project) =>{
                              return (
                                <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                              );
                            })
                          }
                        </Form.Control>
                      </Form.Group>
                    </Card.Header>
                    <Card.Body eventKey="1">
                      <Card.Body className="py-0">
                        <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                          <FormControl
                            className='mb-2'
                            size='md'
                            placeholder='Buscar...'
                            onChange={({target})=>setGroupFilters({ ...groupFilters, groupSearch: target.value })}
                          />
                        </FormGroup>
                        <Form.Group controlId="groupsCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                          {
                            groupsList.map( (group) =>{
                              return (
                                <Form.Check type="checkbox" key={group.uuid} label={group.group_name} name={group.group_name} value={group.uuid} defaultChecked={auxCheckLists.group.includes(group.uuid)}/>
                              );
                            })
                          }
                        </Form.Group>
                      </Card.Body>
                    </Card.Body>
                  </Card>
                </Accordion>
              </Form.Group>
              <Form.Group controlId="society">
                <Form.Label className="font-weight-bold small">
                  Designar sociedad
                </Form.Label>
                <Accordion defaultActiveKey="1" className="mb-4">
                  <Card>
                    <Card.Header className="bg-transparent text-muted">
                      Seleccione
                    </Card.Header>
                    <Card.Header className="bg-transparent">
                      {
                        userProfile.profile && (
                          userProfile.profile.user_role !== "GROUP_ADMIN" && (
                            <Form.Group controlId="project">
                              <Form.Control
                                size="md"
                                as="select"
                                placeholder="Ingresa un correo"
                                name="project"
                                type="project"
                                onChange={({target})=> {
                                  showGroups({groupSearch: "" ,project: target.value, filtered: true});
                                  setSocietyFilters({ ...societyFilters, project: target.value }
                                  )
                                  if(target.value === "")
                                    setSocietyFilters({...societyFilters, project: "", group: ""})
                                }}
                              >
                                <option value="" className="secondary-text-color" selected> Todos los proyectos </option>
                                {
                                  auxAllProjects.map( (project) =>{
                                    return (
                                      <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                                    );
                                  })
                                }
                              </Form.Control>
                            </Form.Group>
                          )
                        )
                      }
                      <Form.Group controlId="group" className="m-0">
                        <Form.Control
                          size="md"
                          as="select"
                          placeholder="Ingresa un correo"
                          name="group"
                          type="group"
                          disabled={(societyFilters.project === "")}
                          onChange={({target})=>setSocietyFilters({ ...societyFilters, group: target.value })}
                        >
                          <option value="" className="secondary-text-color" selected> Todos los grupos </option>
                          {
                            groupsFilteredList.map( (group) =>{
                              return (
                                <option value={group.uuid} key={group.uuid}>{group.group_name}</option>
                              );
                            })
                          }
                        </Form.Control>
                      </Form.Group>
                    </Card.Header>
                    <Card.Body eventKey="1">
                      <Card.Body className="py-0">
                        <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                          <FormControl
                            className='mb-2'
                            size='md'
                            placeholder='Buscar...'
                            onChange={({target})=>setSocietyFilters({ ...societyFilters, societySearch: target.value })}
                          />
                        </FormGroup>
                        <Form.Group controlId="societiesCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                          {
                            societiesList.map( (society) =>{
                              return (
                                <Form.Check type="checkbox" key={society.uuid} label={society.society_business_name} name={society.society_business_name} value={society.uuid} defaultChecked={auxCheckLists.society.includes(society.uuid)}/>
                              );
                            })
                          }
                        </Form.Group>
                      </Card.Body>
                    </Card.Body>
                  </Card>
                </Accordion>
              </Form.Group>
              <Form.Group controlId="user_type">
                <Form.Label className="font-weight-bold small">
                  Designar tipo de usuario
                  <span className="ml-1 text-danger small">
                  (*)
                </span>
                </Form.Label>
                <Accordion defaultActiveKey="1" className="mb-4">
                  <Card>
                    <Card.Header className="bg-transparent text-muted">
                      Seleccione
                    </Card.Header>
                    <Card.Header className="bg-transparent">
                      <Card.Body>
                        <Form.Group controlId="user_type" onChange={handleInputChange}>
                          <Form.Check type="checkbox" label="Admin de proyecto" name="enable_project_admin" value={reportTwoForm.enable_project_admin} defaultChecked={auxCheckLists.enable_project_admin}/>
                          <Form.Check type="checkbox" label="Admin de grupo" name="enable_group_admin" value={reportTwoForm.enable_group_admin} defaultChecked={auxCheckLists.enable_group_admin}/>
                          <Form.Check type="checkbox" label="Usuario de grupo" name="enable_user_group" value={reportTwoForm.enable_user_group} defaultChecked={auxCheckLists.enable_user_group}/>
                          <Form.Check type="checkbox" label="Usuario de sociedad" name="enable_user_society" value={reportTwoForm.enable_user_society} defaultChecked={auxCheckLists.enable_user_society}/>
                        </Form.Group>
                      </Card.Body>
                    </Card.Header>
                  </Card>
                </Accordion>
              </Form.Group>
              <span className="text-danger">
              (*)
              <span className="text-black-50">
                Campos obligatorios
              </span>
            </span>
            </Col>
          </Row>
        )}
        {step === 2 && (
          <Row className="mb-4">
            <Col xl="5" lg="6" className="mx-auto">
              <h4 className="font-weight-bold mb-4">Parámetros</h4>
              {
                formVariable()
              }
              <Card >
                <div className="p-4 font-weight-bold bg-light">
                  <Row>
                    <Col className="mt-2">
                      Añadir parametro
                    </Col>
                    <Col>
                      <Dropdown>
                        <Dropdown.Toggle
                          className="rounded-circle float-right px-2 btn btn-primary dropdown-toggle-plus"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <Plus size="20"/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={ () => {
                              if(!flagProject)
                                setListParameters( [...listParameters,{ type: 1, id: 'project' }] )
                              setAuxProject(false);
                            }}
                          >
                            Id del proyecto
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={ () => {
                              if(!flagGroup)
                                setListParameters( [...listParameters,{ type: 1, id: 'group' }] )
                              setAuxGroup(false);
                            }}
                          >
                            Id del grupo
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={ () => {
                              if(!flagUserRole)
                                setListParameters( [...listParameters,{ type: 1, id: 'user_role_id' }] );
                              setAuxUserRole(false);
                            }}
                          >
                            Id del rol de usuario
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={ () => {
                              if(!flagUser)
                                setListParameters( [...listParameters,{ type: 1, id: 'user' }] )
                              setAuxUser(false);
                            }}
                          >
                            Id del usuario
                          </Dropdown.Item>
                          {
                            auxProjectSelected.length !== 0 && (
                              <Dropdown.Item
                                onClick={ () => {
                                  setListParameters( [...listParameters,{ type: 1, id: 'other', uuid:indexParameters, show: true }] );
                                  arrayParams.push({ id: indexParameters.toString(), show: true, parameter:{uuid:""} });
                                  setIndexParameters(indexParameters+1);
                                }}
                              >
                                Otro
                              </Dropdown.Item>
                            )
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                </div>
              </Card>
              <span className="text-danger">
              (*)
              <span className="text-black-50">
                Campos obligatorios
              </span>
            </span>
            </Col>
          </Row>
        )}
        {step === 3 && (
          <Row className="mb-4">
            <Col xl="5" lg="6" className="mx-auto">
              <h4 className="font-weight-bold mb-4">Ubicación</h4>
              <Form.Group controlId="module">
                <Form.Label className="font-weight-bold small">
                  Modulo
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  as="select"
                  placeholder="Ingresa un correo"
                  type="module"
                  name="module"
                  onChange={handleInputChange}
                >
                  <option value='' disabled selected>Seleccione</option>
                  {
                    listModules.map( (module) => {
                      return(
                        reportData.module &&(
                          reportData.module.uuid === module.uuid ? (
                            <option key={module.uuid} value={module.uuid} selected>{module.module_name}</option>
                          ):(
                            <option key={module.uuid} value={module.uuid}>{module.module_name}</option>
                          )
                        )
                      );
                    })
                  }
                </Form.Control>
              </Form.Group>
              <span className="text-danger">
              (*)
              <span className="text-black-50">
                Campos obligatorios
              </span>
            </span>
            </Col>
          </Row>
        )}
        {step === 4 && (
          <Row>
            <Col xs="12" className="text-center">
              <CheckCircle size="48" className="text-success mb-4"/>
              <h5 className="font-weight-bold mb-4">Reporte creado con éxito</h5>
            </Col>
          </Row>
        )}
        {
          serviceError ? (
            <div className="alert alert-danger my-3 col-lg-6 col-md-8 mx-auto" role="alert">
              {alert()}
            </div>
          ) : null
        }
        <Row className="d-flex justify-content-center mb-5">
          {step > 0 && step < 4 && (
            <Button variant="light" className="mx-2" onClick={onPrevious}>Atrás</Button>
          )}
          {step === 4 ?(
            <Button className="mx-2" as={Link} to="/admin/reports">Ir a reportes</Button>
          ) :(
            step === 3 ?
              (
                <Button
                  className="mx-2"
                  onClick={sendData}
                  disabled={ loading || loadingForm || disableSubmit}
                >
                  {
                    loading || loadingForm ? (
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    ) : (
                      ''
                    )
                  }{' '}
                  Editar reporte
                </Button>
              ):(
                <Button className="mx-2" onClick={onNext} disabled={disableSubmit}>Siguiente</Button>
              )
          )}
        </Row>
      </Form>
    ):(
      <></>
    )
  )
}

EditReport.propTypes = {
  patchReports: PropTypes.func.isRequired,
  showGroups: PropTypes.func.isRequired,
  groupsList: PropTypes.array,
  showReport: PropTypes.func.isRequired,
  reportData: PropTypes.object,
  showProjects: PropTypes.func.isRequired,
  projectsList: PropTypes.array,
  showModulesList: PropTypes.func.isRequired,
  showSocieties: PropTypes.func.isRequired,
  societiesList: PropTypes.array
};

const stateToProps = state => ({
  patchReports: state.reports.postReports,
  errors: state.reports.errors,
  reportData: state.reports.reportData,
  userProfile: state.profile.userProfile,
  loadingForm: state.reports.loadingForm,
  groupsList: state.groups.groupsList,
  isLoadedReport: state.reports.isLoadedReport,
  projectsList: state.projects.projectsList,
  listModules: state.reports.listModules,
  societiesList: state.societies.societiesList,
  groupsFilteredList: state.groups.groupsFilteredList
});

const actionsToProps = dispatch => ({
  showGroups: payload => dispatch({ type: FETCH_LIST_GROUPS, payload }),
  patchReports: payload => dispatch({ type: PATCH_REPORT, payload }),
  showReport: payload => dispatch({ type: FETCH_REPORT, payload }),
  showProjects: payload => dispatch({ type: FETCH_LIST_PROJECTS, payload }),
  showModulesList: payload => dispatch({ type: FETCH_MODULES, payload }),
  showSocieties: payload => dispatch({ type: FETCH_LIST_SOCIETIES, payload })
});

export default connect(stateToProps, actionsToProps)(EditReport);
