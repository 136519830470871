import config from '../config';

const { loginURI, refreshURI, contactURI, invitationTokenURI, resetPasswordURI, personalizationURI } = config().services;
const network = client => ({
  loginPassword: payload => client.post(loginURI, { ...payload }),
  refreshToken: payload => client.post(refreshURI, { ...payload }),
  getTokenInvitation: (payload) => client.get(`${invitationTokenURI}${payload}`),
  register: (payload) => client.post(invitationTokenURI,{ ...payload }),
  sendEmail: ({ email }) => client.post(resetPasswordURI, { email }),
  resetPassword: ({ token, password, password_confirmation }) =>
    client.patch(resetPasswordURI, { token, password, password_confirmation }),
  confirmToken: (payload) => client.get(`${resetPasswordURI}?token=${payload}`),
  obtainColor: payload => client.post(personalizationURI, { ...payload } ),
  sendEmailContact: payload => client.post(contactURI, payload )
});

export default network;
