import { takeLatest, put, call } from 'redux-saga/effects';
import cookieHandler from '../../utils/helpers/cookie.handler';
import {
  confirmToken,
  getTokenInvitation,
  loginPassword,
  obtainColor,
  postSessionLogout,
  register,
  resetPassword,
  sendEmail,
  sendEmailContact
} from '../../network';
import {
  POST_LOGIN_PASSWORD,
  SET_IS_LOGGED_IN,
  SET_AUTH_CHECKING,
  SET_AUTH_ERRORS,
  POST_LOGOUT,
  GET_INVITATION_TOKEN,
  CONFIRM_TOKEN,
  POST_REGISTER,
  SEND_EMAIL,
  POST_EMAIL_RESET_PASSWORD,
  POST_DATA_RESET,
  DISPATCH_CONFIRM_ACCOUNT,
  FETCH_COLOR, SITE_DATA, LOADING_DATA, DATA_LOADED, LOADING_EMAIL_FORM, SET_EMAIL_ERRORS, POST_CONTACT_EMAIL
} from './types';
import { SET_USER_PROFILE } from '../profile/types';
import messageValidationFailed from '../../utils/helpers/messageValidationFailed';
import formsErrors from '../../utils/errors/formsErrors';
import registerErrors from '../../utils/errors/formsErrors';

function* postLoginPassword({ payload }) {
  try {
    yield put({ type: SET_AUTH_ERRORS, payload: null });
    yield put({ type: SET_AUTH_CHECKING, payload: true });
    const response = yield call(loginPassword, payload);
    yield put({ type: SET_IS_LOGGED_IN, payload: true });
    const { setTokens } = cookieHandler();
    setTokens(response.data);
    yield put({ type: SET_AUTH_CHECKING, payload: false });
    window.location.href = "/home"
  } catch (e) {
    const { response } = e;
    yield put({ type: SET_IS_LOGGED_IN, payload: false });
    if (response.status === 400 || response.status === 401) {
      yield put({ type: SET_AUTH_ERRORS, payload:'¡Vaya! El correo y/o contraseña no son válidos. ' });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_AUTH_ERRORS, payload: '¡Oops! Parece que algo salío mal. Por favor, vuelve a intentarlo más tarde.' });
    }
    yield put({ type: SET_AUTH_CHECKING, payload: false });
    // TODO: handle error
    console.log(e);
  }
}

function* postLogout() {
  try {
    yield call(postSessionLogout);
    yield put({ type: SET_IS_LOGGED_IN, payload: false });
    yield put({ type: SET_USER_PROFILE, payload: {} });
    const { removeToken } = cookieHandler();
    removeToken();

  } catch (e) {
    yield put({ type: SET_IS_LOGGED_IN, payload: false });
    yield put({ type: SET_USER_PROFILE, payload: {} });
    const { removeToken } = cookieHandler();
    window.location.href = '/';
    removeToken();
    // TODO: handle error
    console.log(e);
  }

}

function* getInvitationToken({ payload }) {
  try {
    yield put({ type: SET_AUTH_CHECKING, payload: false });
    yield call(getTokenInvitation,`?token=${payload}`);
    yield put({ type: CONFIRM_TOKEN, payload: true });
    yield put({ type: SET_AUTH_CHECKING, payload: true });
  } catch (e) {
    const { response } = e;
    if (response.status === 400) {
      if (response.data.token) {
        yield put({ type: SET_AUTH_ERRORS, payload: "Error token" });
        yield put({ type: SET_AUTH_CHECKING, payload: false });
      }
    }
    // TODO: handle error
    console.log(e);
  }
}

function* postRegister({ payload }) {
  let errors = [];
  try {
    yield put({ type: LOADING_DATA, payload: true });
    yield put({ type: SET_AUTH_CHECKING, payload: true });
    yield call(register, payload);
    yield put({ type: SET_AUTH_ERRORS, payload: null });
    yield put({ type: SET_AUTH_CHECKING, payload: false });
    yield put({ type: LOADING_DATA, payload: false });
    window.location.href = '/successRegister';
  } catch (e) {
    const { response } = e;
    if (response.status === 400) {
      if (response.data.first_name) {
        errors = [...errors, messageValidationFailed(response.data.first_name, 'nombre.')];
      }
      if (response.data.phone_number) {
        errors = [...errors, messageValidationFailed(response.data.phone_number, 'numero de telefono.')];
      }
      if (response.data.last_name) {
        errors = [...errors, messageValidationFailed(response.data.last_name, 'apellidos.')];
      }
      if (response.data.rut_user) {
        errors = [...errors,registerErrors.RUT_TAKEN];
      }
      yield put({ type: SET_AUTH_ERRORS, payload: errors });
      yield put({ type: SET_AUTH_CHECKING, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_AUTH_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: SET_AUTH_CHECKING, payload: false });
    }
    yield put({ type: LOADING_DATA, payload: false });
    // TODO: handle error
    console.log(e);
  }
}

function* postSendEmailReset({ payload }) {
  const { resetForm, history } = payload;
  try {
    yield put({ type: SEND_EMAIL, payload: true });
    const response = yield call(sendEmail, resetForm);
    if (response.data && response.data.message === 'EMAIL_SENT') {
      yield put({ type: SEND_EMAIL, payload: false });
      history.push('/successEmail');
    }
  } catch (e) {
    yield put({ type: SEND_EMAIL, payload: false });
    const { response } = e
    if (response.status === 400) {
      if (response.data.email && response.data.email.includes('EMAIL_NOT_EXISTS')) {
        yield put({ type: SET_AUTH_ERRORS, payload: registerErrors.EMAIL_NOT_EXISTS });
      }
    }
    // TODO: handle error
    console.log(e);
  }
}

function* patchResetPassword({ payload }) {
  const { history } = payload;
  try {
    yield call(resetPassword, payload);
    history.push('/SuccessResetPass');
  } catch (e) {
    const { response } = e;
    if (response.status === 400) {
      if (response.data.password && response.data.password.includes('PASSWORD_EXISTS')) {
        yield put({ type: SET_AUTH_ERRORS, payload: registerErrors.PASSWORD_EXISTS });
      }
    }
    // TODO: handle error
    console.log(e);
  }
}

function* getConfirmToken({ payload }) {
  const { history, token } = payload;
  try {
    yield call(confirmToken, token);
    yield put({ type: CONFIRM_TOKEN, payload: true });
  } catch (e) {
    history.push('/expiredToken');
    // TODO: handle error
    console.log(e);
  }
}

function* postLoadLogin ({payload}) {
  const { setColor, removeColor } = cookieHandler();
  try {
    yield put({ type: LOADING_DATA, payload: true });
    const response = yield call(obtainColor, payload);
    setColor(response.data.primary_color);
    yield put({ type: SITE_DATA, payload: response.data });
    localStorage.removeItem('project_name' );
    localStorage.removeItem( 'meta_title' );
    localStorage.removeItem( 'meta_content' );
    localStorage.removeItem( 'header_title' );
    localStorage.removeItem( 'subtitle' );
    localStorage.removeItem( 'footer_disclaimer' );
    localStorage.removeItem( 'url_own_site' );
    localStorage.removeItem( 'logo' );
    localStorage.removeItem( 'icon' );
    localStorage.removeItem( 'contact_email' );
    localStorage.removeItem( 'contact_phone' );
    localStorage.setItem( 'project_name', response.data.project_name );
    localStorage.setItem( 'meta_title', response.data.meta_title);
    localStorage.setItem( 'meta_content', response.data.meta_content);
    localStorage.setItem( 'header_title', response.data.header_title);
    localStorage.setItem( 'subtitle', response.data.subtitle);
    localStorage.setItem( 'footer_disclaimer', response.data.footer_disclaimer);
    localStorage.setItem( 'url_own_site', response.data.url_own_site);
    localStorage.setItem( 'logo', response.data.logo);
    localStorage.setItem( 'icon', response.data.icon);
    localStorage.setItem( 'contact_email', response.data.contact_email);
    localStorage.setItem( 'contact_phone', response.data.contact_phone);
    localStorage.removeItem('project' );
    localStorage.removeItem('group' );
    localStorage.removeItem('society' );
    yield put({ type: LOADING_DATA, payload: false });
    yield put({ type: DATA_LOADED, payload: true });
  } catch (e) {
    removeColor();
    localStorage.removeItem('project_name' );
    localStorage.removeItem( 'meta_title' );
    localStorage.removeItem( 'meta_content' );
    localStorage.removeItem( 'header_title' );
    localStorage.removeItem( 'subtitle' );
    localStorage.removeItem( 'footer_disclaimer' );
    localStorage.removeItem( 'url_own_site' );
    localStorage.removeItem( 'logo' );
    localStorage.removeItem( 'icon' );
    localStorage.removeItem( 'contact_email' );
    localStorage.removeItem( 'contact_phone' );
    localStorage.removeItem('project' );
    localStorage.removeItem('group' );
    localStorage.removeItem('society' );
    localStorage.removeItem('auxSociety' );
    yield put({ type: LOADING_DATA, payload: false });
    yield put({ type: DATA_LOADED, payload: true });
    // TODO: handle error
    console.log(e);
  }
}

function* postContactEmail({ payload }) {
  let errors = [];
  try {
    yield put({ type: LOADING_EMAIL_FORM, payload: true });
    yield call(sendEmailContact, payload);
    yield put({ type: LOADING_EMAIL_FORM, payload: false });
    yield put({ type: SET_EMAIL_ERRORS, payload: [] });
  } catch (e) {
    yield put({ type: LOADING_EMAIL_FORM, payload: true });
    const { response } = e;
    if (response.status === 400) {
      if (response.data.email) {
        errors = [...errors, messageValidationFailed(response.data.email, 'email.')];
      }
      if (response.data.subject) {
        errors = [...errors, messageValidationFailed(response.data.subject, 'asunto.')];
      }
      if (response.data.description) {
        errors = [...errors, messageValidationFailed(response.data.description, 'descripción.')];
      }
      yield put({ type: SET_EMAIL_ERRORS, payload: errors });
    } else {
      errors = [...errors, formsErrors.TRY_AGAIN];
      yield put({ type: SET_EMAIL_ERRORS, payload: errors });
    }
    yield put({ type: LOADING_EMAIL_FORM, payload: false });
    // TODO: handle error
    console.log(e);
  }
}

function* watchPostLoginPassword() {
  yield takeLatest(POST_LOGIN_PASSWORD, postLoginPassword);
}

function* watchPostLogout() {
  yield takeLatest(POST_LOGOUT, postLogout);
}

function* watchGetInvitationToken() {
  yield takeLatest(GET_INVITATION_TOKEN, getInvitationToken);
}

function* watchPostRegister() {
  yield takeLatest(POST_REGISTER, postRegister);
}

function* watchSendEmailReset() {
  yield takeLatest(POST_EMAIL_RESET_PASSWORD, postSendEmailReset);
}

function* watchPatchResetPassword() {
  yield takeLatest(POST_DATA_RESET, patchResetPassword);
}

function* watchGetConfirmToken() {
  yield takeLatest(DISPATCH_CONFIRM_ACCOUNT, getConfirmToken);
}

function* watchPostLoadLogin() {
  yield takeLatest(FETCH_COLOR, postLoadLogin);
}

function* watchPostContactEmail() {
  yield takeLatest(POST_CONTACT_EMAIL, postContactEmail);
}

const saga = [
  watchPostLoginPassword(),
  watchPostLogout(),
  watchGetInvitationToken(),
  watchPostRegister(),
  watchSendEmailReset(),
  watchPatchResetPassword(),
  watchGetConfirmToken(),
  watchPostLoadLogin(),
  watchPostContactEmail()
];

export default saga;
