import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PasswordForm from '../../components/changePassword';

const ChangePassword = () => {
  return (
    <div className="d-flex flex-column bg-light">
      <Container className="pt-2 my-5">
        <Row className="justify-content-md-center mt-5 ml-md-5">
          <Col lg="7" md="5">
            <PasswordForm/>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ChangePassword;
