import React, { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap-4-pagination';

const PaginationBar = ({ total, position, setPosition }) => {
  const [show, setShow] = useState(3)

  useEffect(() => {
    if(parseInt(position)===total){
      setShow(2)
    }
    else{
      setShow(3)
    }
  }, [total, position]);

  let paginationConfig = {
    totalPages: total,
    currentPage: parseInt(position),
    showMax: show,
    size: "sm",
    className: "mt-5",
    threeDots: true,
    onClick: function (page) {
      setPosition(page);
    }
  };

  return (
    <>
      {
        total > 1 ?(
          <div className="App">
            <Pagination {...paginationConfig} />
          </div>
        ):(
          <></>
        )
      }
    </>
  );
};

export default PaginationBar;
