import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { RegisterForm } from '../../components';

const RegisterView = () => {
  return (
    <Container fluid>
      <h5 className="mt-2 mr-2">MAGNUS</h5>
      <Row className="justify-content-md-center mt-4">
        <Col xs sm="6" md="6" lg="4">
          <RegisterForm />
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterView;
