import {
  LIST_FOLDERS,
  FOLDER_ERRORS,
  LOADING_FOLDER,
  ROOT_DIRECTORY,
  DOCUMENT_LIST,
  LOADING_DOCUMENT,
  SET_DOCUMENT_ERRORS,
  SHARE_TOKEN,
  LOADING_TOKEN,
  LOADING_NEW_FOLDER,
  TOKEN_STATE,
  EXPIRED_TOKEN,
  DELETE_ERRORS,
  GET_DOCUMENT_PANEL_TAX_FORMS_SUCCESS,
  GET_VALUE_BREAD_CRUMPS, 
  LOADING_PANEL_TAX
} from './types';

const initialState = {
  folderList: [],
  root_directory: [],
  document_list: [],
  loadingList: false,
  errors: [],
  documentErrors: [],
  folderErrors: [],
  breadCrump: [],
  loadingDocument: false,
  documentToken: null,
  tokenLoaded: false,
  newFolderLoaded: false,
  stateToken: false,
  expiredToken: false,
  loadingPanelTax: false,
  document_list_tax_panel_forms: []
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case DOCUMENT_LIST:
    return { ...state, document_list: payload };
  case LIST_FOLDERS:
    console.log(payload)
    return { ...state, folderList: payload };
  case ROOT_DIRECTORY:
    return { ...state, root_directory: payload };
  case FOLDER_ERRORS:
    return { ...state, errors: payload };
  case LOADING_FOLDER:
    return { ...state, loadingList: payload };
  case LOADING_DOCUMENT:
    return { ...state, loadingDocument: payload };
  case SET_DOCUMENT_ERRORS:
    return { ...state, documentErrors: payload };
  case SHARE_TOKEN:
    return { ...state, documentToken: payload };
  case LOADING_TOKEN:
    return { ...state, tokenLoaded: payload };
  case LOADING_NEW_FOLDER:
    return { ...state, newFolderLoaded: payload };
  case TOKEN_STATE:
    return { ...state, stateToken: payload };
  case EXPIRED_TOKEN:
    return { ...state, expiredToken: payload };
  case DELETE_ERRORS:
    return { ...state, folderErrors: payload };
  case GET_DOCUMENT_PANEL_TAX_FORMS_SUCCESS:
    console.log(payload)
    return { ...state, document_list_tax_panel_forms: payload}
  case GET_VALUE_BREAD_CRUMPS:
      return { ...state, breadCrump: payload }
  case LOADING_PANEL_TAX:
      console.log(payload)
      return { ...state, loadingPanelTax: payload}
      
  default:
    return state;
  }
};

export default reducer;
