import React from 'react';
import { BrowserRouter, Switch, Route as Default } from 'react-router-dom';
import Route from './layout';
import {
  HomeView,
  LoginView,
  RegisterView,
  ProjectsView,
  GroupView,
  UsersView,
  SocietyView,
  CreateProjectView,
  DetailProjectView,
  CreateGroupView,
  DetailGroupView,
  CreateSocietyView,
  DetailSocietyView,
  EditProjectView,
  EditGroupView,
  EditSocietyView,
  LoginUserView,
  InviteUserView,
  SuccessRegView,
  ImportUsersView,
  ReportsView,
  CreateReportView,
  EditReportView,
  DetailReportView,
  ReportView,
  ExpiredTokenView,
  sendEmailResetPwdView,
  SuccessEmail,
  ResetPassword,
  SuccessResetPass,
  UpdatePassword,
  UserDetail,
  UserEdit,
  DocumentsAdminView,
  DocumentsUserView,
  DocumentsEditView,
  ShareView,
  AccountingView,
  CreateAccountingView,
  AccountingUserView,
  NoAvailableView,
  LandingView, 
  SupportView
} from './views';
import EditAccountingView from './views/management/editAccountingView';
import DetailAccountingView from './views/management/detailAccountingView';
import ChatBot from './components/ask/ChatBot';
import homeView from './views/homeView';
const Router = () => {
  const currentPath = window.location.pathname
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/home" Component={HomeView} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact />
        {
          window.innerWidth < 1024 && currentPath === '/chatbot' && (
            <Route path="/chatbot" Component={ChatBot} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact />
          )
        }
        {
          window.innerWidth > 1024 && currentPath === '/chatbot' && (
            <Route path="/chatbot" Component={homeView} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact />
          )
        }
        <Route path="/support" Component={SupportView} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact />
        <Route path="/userinfo" Component={NoAvailableView} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact />
        <Route path="/changePassword" Component={UpdatePassword} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact />
        <Route path="/admin/projects" Component={ProjectsView} showNav={true} adminRoute={true} exact />
        <Route path="/admin/projects/create" Component={CreateProjectView} adminRoute={true} showNav={true} exact />
        <Route path="/admin/projects/detail/:uuid" Component={DetailProjectView} adminRoute={true} showNav={true} exact />
        <Route path="/admin/projects/edit/:uuid" Component={EditProjectView} adminRoute={true} showNav={true} exact />
        <Route path="/admin/group" Component={GroupView} showNav={true} projectAdminRoute={true} adminRoute={true} exact />
        <Route path="/admin/group/create" Component={CreateGroupView} showNav={true} projectAdminRoute={true} adminRoute={true} exact />
        <Route path="/admin/group/detail/:uuid" Component={DetailGroupView} showNav={true} projectAdminRoute={true} adminRoute={true} exact />
        <Route path="/admin/group/edit/:uuid" Component={EditGroupView} showNav={true} projectAdminRoute={true} adminRoute={true} exact />
        <Route path="/admin/society" Component={SocietyView} showNav={true} projectAdminRoute={true} groupAdminRoute={true} adminRoute={true} exact />
        <Route path="/admin/society/create" Component={CreateSocietyView} projectAdminRoute={true} showNav={true} groupAdminRoute={true} adminRoute={true} exact />
        <Route path="/admin/society/detail/:uuid" Component={DetailSocietyView} projectAdminRoute={true} showNav={true} groupAdminRoute={true} adminRoute={true} exact />
        <Route path="/admin/society/edit/:uuid" Component={EditSocietyView} projectAdminRoute={true} showNav={true} groupAdminRoute={true} adminRoute={true} exact />
        <Route path="/admin/users" Component={UsersView} showNav={true} projectAdminRoute={true} groupAdminRoute={true} adminRoute={true} exact />
        <Route path="/admin/users/invite" Component={InviteUserView} showNav={true} projectAdminRoute={true} adminRoute={true} groupAdminRoute={true} exact />
        <Route path="/admin/users/detail/:uuid" Component={UserDetail} projectAdminRoute={true} groupAdminRoute={true} adminRoute={true} showNav={true} exact />
        <Route path="/admin/users/import" Component={ImportUsersView} showNav={true} projectAdminRoute={true} adminRoute={true} groupAdminRoute={true} exact />
        <Route path="/admin/users/edit/:uuid" Component={UserEdit} projectAdminRoute={true} groupAdminRoute={true} adminRoute={true} showNav={true} exact />
        <Route path="/admin" Component={LoginView} showSide={false} guestRoute={true} showNav={false} exact />
        <Route path="/reportView/:uuid" Component={ReportView} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact/>
        <Route path="/login" Component={LoginUserView} showSide={false} showNav={false} guestRoute exact />
        <Route path="/" Component={LandingView} showSide={false} showNav={false} guestRoute exact />
        <Route path="/share/:token" Component={ShareView} showSide={false} showNav={false} exact />
        <Route path="/forgottenPassword" Component={sendEmailResetPwdView} showSide={false} showNav={false} guestRoute exact/>
        <Route path="/password_reset/:token" Component={ResetPassword} showSide={false} showNav={false} guestRoute exact/>
        <Route path="/SuccessEmail" Component={SuccessEmail} showSide={false} showNav={false} guestRoute exact />
        <Route path="/SuccessResetPass" Component={SuccessResetPass} showSide={false} showNav={false} guestRoute exact />
        <Route path="/successRegister" Component={SuccessRegView} showSide={false} showNav={false} guestRoute exact />
        <Route path="/expiredToken" Component={ExpiredTokenView} showSide={false} showNav={false} guestRoute exact />
        <Route path="/admin/reports" Component={ReportsView} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact />
        <Route path="/admin/documents/:uuid" Component={DocumentsAdminView} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact />
        <Route path="/admin/documents/:uuid/:project" Component={DocumentsEditView} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact />
        <Route path="/documents_user/:uuid" Component={DocumentsUserView} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact />
        <Route path="/admin/reports/create" Component={CreateReportView} showNav={true} projectAdminRoute={true} adminRoute={true} exact />
        <Route path="/admin/reports/edit/:uuid" Component={EditReportView} showNav={true} projectAdminRoute={true} adminRoute={true} exact />
        <Route path="/admin/reports/detail/:uuid" Component={DetailReportView} showNav={true} projectAdminRoute={true} adminRoute={true} exact />
        <Route path="/admin/accounting" Component={AccountingView} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact />
        <Route path="/admin/accounting/create" Component={CreateAccountingView} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact />
        <Route path="/admin/accounting/edit/:uuid" Component={EditAccountingView} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact />
        <Route path="/admin/accounting/detail/:uuid" Component={DetailAccountingView} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact />
        <Route path="/accounting/:type/:uuid" Component={AccountingUserView} showNav={true} adminRoute={true} projectAdminRoute={true} groupAdminRoute={true} exact />
        <Route path="/register/:token" Component={RegisterView} showSide={false} showNav={false} guestRoute exact />
        <Default path="/health-check">
          <h3>The App is Healthy</h3>
        </Default>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
