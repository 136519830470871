import { takeLatest, put, call } from 'redux-saga/effects';
import {
  getSociety,
  postSociety,
  getSocietyData,
  updateSociety,
  getSocietyAdmins,
  deleteSociety,
  getSocietyPDF,
  searchSociety,
  getSocietiesUser,
  getIndustries,
  postIndustry,
  updateIndustry,
  deleteIndustry,
  getIndustryData,
  getSocietyUsers,
  getUserSocieties
} from '../../network';
import {
  FETCH_LIST_SOCIETIES,
  SET_DATA_LIST_SOCIETIES,
  IS_LOADED,
  POST_SOCIETIES,
  SET_DATA_SOCIETY,
  FETCH_SOCIETY,
  PATCH_SOCIETY,
  IS_LOADED_SOCIETY,
  FETCH_SOCIETY_ADMINS,
  SET_LIST_SOCIETY_ADMINS,
  DELETE_SOCIETY,
  FETCH_PDF,
  INDEX_PAGINATION_SOCIETIES,
  LOADING_SOCIETY_FORM,
  SET_SOCIETY_ERRORS,
  FETCH_SOCIETY_USER_LIST,
  SOCIETY_USER_LIST,
  INDUSTRIES_LIST,
  FETCH_INDUSTRIES,
  POST_INDUSTRY,
  PATCH_INDUSTRY,
  DELETE_INDUSTRY,
  INDUSTRY_DATA,
  FETCH_INDUSTRY_DATA,
  INDUSTRY_LOADED,
  INDUSTRY_ERRORS,
  INDUSTRY_LOADING,
  SET_LIST_SOCIETY_USERS, FETCH_SOCIETY_USERS, FETCH_USER_SOCIETY
} from './types';
import messageValidationFailed from '../../utils/helpers/messageValidationFailed';
import formsErrors from '../../utils/errors/formsErrors';
import registerErrors from '../../utils/errors/formsErrors';
import cookieHandler from '../../utils/helpers/cookie.handler';

function* showSocieties({ payload }) {
  try {
    let response = "";
    const { paginationPosition, queryValue, societiesInScreen, societySearch, project, group } = payload;
    if(!paginationPosition){
      if(societySearch || project || group)
        response = yield call(searchSociety,`?limit=10000&offset=${(paginationPosition-1)*societiesInScreen}&search=${societySearch}&project=${project}&group=${group}`);
      else
      response = yield call(getSociety,`?limit=10000&offset=${(paginationPosition-1)*societiesInScreen}`);
    }else{
      response = yield call(searchSociety,`${queryValue}&limit=${societiesInScreen}&offset=${(paginationPosition-1)*societiesInScreen}`);
    }
    yield put({ type: IS_LOADED, payload: false })
    yield put({ type: SET_DATA_LIST_SOCIETIES, payload: response.data.results });
    yield put({ type: IS_LOADED, payload: true });
    yield put({ type: INDEX_PAGINATION_SOCIETIES, payload: Math.ceil(response.data.count/societiesInScreen) });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showSociety({ payload }) {
  try {
    const response = yield call(getSocietyData, payload);
    yield put({ type: IS_LOADED_SOCIETY, payload: false })
    yield put({ type: SET_DATA_SOCIETY, payload: response.data });
    yield put({ type: IS_LOADED_SOCIETY, payload: true })
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showSocietyUsers({ payload }) {
  try {
    const response = yield call(getSocietyUsers, payload);
    yield put({ type: SET_LIST_SOCIETY_USERS, payload: response.data.results });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* deleteSocietiesData({ payload }) {
  try {
    yield call(deleteSociety, payload);
    window.location.reload();
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* postSocieties({ payload }) {
  let errors = [];
  try {
    console.log(payload)
   yield put({ type: LOADING_SOCIETY_FORM, payload: true });
    yield call(postSociety, payload);
    yield put({ type: LOADING_SOCIETY_FORM, payload: false });
    yield put({ type: SET_SOCIETY_ERRORS, payload: null });
  } catch (e) {
    yield put({ type: LOADING_SOCIETY_FORM, payload: true });
    const { response } = e;
    if (response.status === 400) {
      if (response.data.society_business_name) {
        errors = [...errors, messageValidationFailed(response.data.society_business_name, 'razón social.')];
      }
      if (response.data.business_line) {
        errors = [...errors, messageValidationFailed(response.data.business_line, 'giro de empresa.')];
      }
      if (response.data.company_code) {
        errors = [...errors, messageValidationFailed(response.data.company_code, 'código de empresa.')];
      }
      if (response.data.society_email) {
        errors = [...errors, messageValidationFailed(response.data.society_email, 'email de sociedad.')];
      }
      if (response.data.society_contact_name) {
        errors = [...errors, messageValidationFailed(response.data.society_contact_name, 'nombre de contacto.')];
      }
      if (response.data.society_phone_number) {
        errors = [...errors, messageValidationFailed(response.data.society_phone_number, 'numero de contacto.')];
      }
      if (response.data.society_address) {
        errors = [...errors, messageValidationFailed(response.data.society_address, 'dirección.')];
      }
      if (response.data.society_contact_email) {
        errors = [...errors, messageValidationFailed(response.data.society_contact_email, 'email de contacto.')];
      }
      if (response.data.society_rut) {
        errors = [...errors,registerErrors.RUT_TAKEN];
      }
      yield put({ type: SET_SOCIETY_ERRORS, payload: errors });
      yield put({ type: LOADING_SOCIETY_FORM, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_SOCIETY_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: LOADING_SOCIETY_FORM, payload: false });
    }
    // TODO: handle error
    console.log(e);
  }
}

function* patchSociety({ payload }) {
  let errors = [];
  console.log(payload.societyForm.tax_module)
  try {
    yield put({ type: LOADING_SOCIETY_FORM, payload: true });
    yield call(updateSociety, payload);
    yield put({ type: LOADING_SOCIETY_FORM, payload: false });
    yield put({ type: SET_SOCIETY_ERRORS, payload: null });
  } catch (e) {
    yield put({ type: LOADING_SOCIETY_FORM, payload: true });
    const { response } = e;
    if (response.status === 400) {
      if (response.data.society_business_name) {
        errors = [...errors, messageValidationFailed(response.data.society_business_name, 'razón social.')];
      }
      if (response.data.business_line) {
        errors = [...errors, messageValidationFailed(response.data.business_line, 'giro de empresa.')];
      }
      if (response.data.company_code) {
        errors = [...errors, messageValidationFailed(response.data.company_code, 'código de empresa.')];
      }
      if (response.data.society_contact_name) {
        errors = [...errors, messageValidationFailed(response.data.society_contact_name, 'nombre de contacto.')];
      }
      if (response.data.society_phone_number) {
        errors = [...errors, messageValidationFailed(response.data.society_phone_number, 'numero de contacto.')];
      }
      if (response.data.society_address) {
        errors = [...errors, messageValidationFailed(response.data.society_address, 'dirección.')];
      }
      if (response.data.society_contact_email) {
        errors = [...errors, messageValidationFailed(response.data.society_contact_email, 'email de contacto.')];
      }
      if (response.data.society_rut) {
        errors = [...errors,registerErrors.RUT_TAKEN];
      }
      if (response.data.group) {
        errors = [...errors,"Existe un conflicto en el cambio de grupo, causado por los archivos asociados en el gestor de documentos"];
      }
      yield put({ type: SET_SOCIETY_ERRORS, payload: errors });
      yield put({ type: LOADING_SOCIETY_FORM, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_SOCIETY_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: LOADING_SOCIETY_FORM, payload: false });
    }
    // TODO: handle error
    console.log(e);
  }
}

function* showSocietyAdmins({ payload }) {
  try {
    const response = yield call(getSocietyAdmins, payload);
    yield put({ type: SET_LIST_SOCIETY_ADMINS, payload: response.data.results });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showSocietyPDF({ payload }) {
  try {
    const accessToken = cookieHandler().getAccessToken();
    yield call(getSocietyPDF, `${payload}/?token=${accessToken}`);
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_SERVICE_URL}api/v1/societies/detail/pdf/${payload}/?token=${accessToken}`;
    link.setAttribute('download', 'report.pdf');
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showSocietyForUser({ payload }) {
  try {
    const {project, group, society} = payload;
    let response;
    if(project) {
      response = yield call(getSocietiesUser, `project=${project}`);
      yield put({ type: SOCIETY_USER_LIST, payload: response.data.results });
    }
    if(group) {
      response = yield call(getSocietiesUser, `group=${group}`);
      yield put({ type: SOCIETY_USER_LIST, payload: response.data.results });
    }
    if(society) {
      response = yield call(getSocietiesUser, `society=${society}`);
      yield put({ type: SOCIETY_USER_LIST, payload: response.data.results });
    }
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showIndustriesList() {
  try {
    const response = yield call(getIndustries,`?limit=10000&offset=0`);
    yield put({ type: INDUSTRIES_LIST, payload: response.data.results });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* postIndustryObject({ payload }) {
  let errors = [];
  try {
    yield put({ type: INDUSTRY_LOADING, payload: true });
    yield call(postIndustry, payload);
    yield put({ type: INDUSTRY_LOADED, payload: true });
    yield put({ type: INDUSTRY_LOADED, payload: false });
    yield put({ type: INDUSTRY_ERRORS, payload: errors });
    yield put({ type: INDUSTRY_LOADING, payload: false });
  } catch (e) {
    yield put({ type: INDUSTRY_LOADING, payload: true });
    const { response } = e;
    if (response.status === 400) {
      if (response.data.industry) {
        errors = [...errors, messageValidationFailed(response.data.industry, ' el nombre de industria.')];
      }
      yield put({ type: INDUSTRY_ERRORS, payload: errors });
      yield put({ type: INDUSTRY_LOADING, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_SOCIETY_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: INDUSTRY_LOADING, payload: false });
    }
    yield put({ type: INDUSTRY_LOADING, payload: false });
    yield put({ type: INDUSTRY_LOADED, payload: true });
    yield put({ type: INDUSTRY_LOADED, payload: false });
    // TODO: handle error
    console.log(e);
  }
}

function* patchIndustry({ payload }) {
  let errors = [];
  try {
    yield put({ type: INDUSTRY_LOADING, payload: true });
    yield call(updateIndustry, payload);
    yield put({ type: INDUSTRY_LOADED, payload: true });
    yield put({ type: INDUSTRY_LOADED, payload: false });
    yield put({ type: INDUSTRY_ERRORS, payload: errors });
    yield put({ type: INDUSTRY_LOADING, payload: false });
  } catch (e) {
    yield put({ type: INDUSTRY_LOADING, payload: true });
    const { response } = e;
    if (response.status === 400) {
      if (response.data.industry) {
        errors = [...errors, messageValidationFailed(response.data.industry, ' el nombre de industria.')];
      }
      yield put({ type: INDUSTRY_ERRORS, payload: errors });
      yield put({ type: INDUSTRY_LOADING, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_SOCIETY_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: INDUSTRY_LOADING, payload: false });
    }
    yield put({ type: INDUSTRY_LOADING, payload: false });
    yield put({ type: INDUSTRY_LOADED, payload: true });
    yield put({ type: INDUSTRY_LOADED, payload: false });
    // TODO: handle error
    console.log(e);
  }
}

function* deleteIndustryObject({ payload }) {
  try {
    yield call(deleteIndustry, payload);
    yield put({ type: INDUSTRY_LOADED, payload: true });
    yield put({ type: INDUSTRY_LOADED, payload: false });
  } catch (e) {
    // TODO: handle error
    console.log(e);
    yield put({ type: INDUSTRY_LOADED, payload: true });
    yield put({ type: INDUSTRY_LOADED, payload: false });
  }
}

function* showIndustryData({ payload }) {
  try {
    const response = yield call(getIndustryData,payload);
    yield put({ type: INDUSTRY_DATA, payload: response.data });
    yield put({ type: INDUSTRY_LOADED, payload: true });
    yield put({ type: INDUSTRY_LOADED, payload: false });
  } catch (e) {
    // TODO: handle error
    console.log(e);
    yield put({ type: INDUSTRY_LOADED, payload: true });
    yield put({ type: INDUSTRY_LOADED, payload: false });
  }
}

function* showUserSociety() {
  try {
    const response = yield call(getUserSocieties);
    yield put({ type: IS_LOADED, payload: false })
    yield put({ type: SET_DATA_LIST_SOCIETIES, payload: response.data.results });
    yield put({ type: IS_LOADED, payload: true });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* watchShowSocieties() {
  yield takeLatest(FETCH_LIST_SOCIETIES, showSocieties);
}

function* watchDeleteSocietyData() {
  yield takeLatest(DELETE_SOCIETY, deleteSocietiesData);
}

function* watchShowSociety() {
  yield takeLatest(FETCH_SOCIETY, showSociety);
}

function* watchPostSocieties() {
  yield takeLatest(POST_SOCIETIES, postSocieties);
}

function* watchPatchSociety() {
  yield takeLatest(PATCH_SOCIETY, patchSociety);
}

function* watchShowSocietyAdmins() {
  yield takeLatest(FETCH_SOCIETY_ADMINS, showSocietyAdmins);
}

function* watchShowSocietyPDF() {
  yield takeLatest(FETCH_PDF, showSocietyPDF);
}

function* watchShowSocietyForUser() {
  yield takeLatest(FETCH_SOCIETY_USER_LIST, showSocietyForUser);
}

function* watchShowIndustriesList() {
  yield takeLatest(FETCH_INDUSTRIES, showIndustriesList);
}

function* watchPostIndustryObject() {
  yield takeLatest(POST_INDUSTRY, postIndustryObject);
}

function* watchPatchIndustry() {
  yield takeLatest(PATCH_INDUSTRY, patchIndustry);
}

function* watchDeleteIndustryObject() {
  yield takeLatest(DELETE_INDUSTRY, deleteIndustryObject);
}

function* watchShowIndustryData() {
  yield takeLatest(FETCH_INDUSTRY_DATA, showIndustryData);
}

function* watchShowSocietyUsers() {
  yield takeLatest(FETCH_SOCIETY_USERS, showSocietyUsers);
}

function* watchShowUserSociety() {
  yield takeLatest(FETCH_USER_SOCIETY, showUserSociety);
}

const saga = [
  watchShowSocieties(),
  watchDeleteSocietyData(),
  watchShowSociety(),
  watchPostSocieties(),
  watchPatchSociety(),
  watchShowSocietyAdmins(),
  watchShowSocietyPDF(),
  watchShowSocietyForUser(),
  watchShowIndustriesList(),
  watchPostIndustryObject(),
  watchPatchIndustry(),
  watchDeleteIndustryObject(),
  watchShowIndustryData(),
  watchShowSocietyUsers(),
  watchShowUserSociety()
];

export default saga;
