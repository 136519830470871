import React from 'react';
import { LoginUserForm } from '../../components';

const LoginUserView = () => {
  return (
    <LoginUserForm />
  );
};

export default LoginUserView;
