import React from 'react';
import Landing from '../components/landing';

const LandingView = () => {
  return (
    <Landing></Landing>
  );
};

export default LandingView;
