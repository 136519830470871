const schema = {
  society_rut: {
    type: 'string',
    min: 0,
    max: 10,
    optional: true,
    empty: true,
    pattern: '^[0-9]+-[0-9kK]{1}$',
    messages: {
      required: 'Debes ingresar el rut de la empresa.',
      string: 'Debes ingresar un rut válido',
      stringMin: 'El rut es muy corto.',
      stringMax: 'El rut es muy largo (máximo 10 caracteres).',
      stringPattern: 'El rut no es válido'
    }
  },
  society_email: {
    type: 'email',
    max: 100,
    optional: true,
    messages: {
      emailEmpty: 'El email es obligatorio.',
      email: 'El email debe tener el siguiente formato ejemplo@correo.com',
      string: 'Debes ingresar un email',
      stringMax: 'El email es muy largo (maximo 100 caracteres).'
    }
  },
  society_contact_email: {
    type: 'email',
    max: 100,
    optional: true,
    messages: {
      email: 'El email debe tener el siguiente formato ejemplo@correo.com',
      string: 'Debes ingresar un email',
      stringMax: 'El email es muy largo (maximo 100 caracteres).'
    }
  },
  society_phone_number: {
    type: 'string',
    min: 0,
    max: 15,
    optional: true,
    empty: true,
    pattern: '^(\\+?)?[0-9]*$',
    messages: {
      required: 'Debes ingresar tu número de telefono.',
      number: 'Debes ingresar un número válido',
      stringMin: 'El número es muy corto.',
      stringMax: 'Tu número es muy largo (máximo 15 caracteres).',
      stringPattern: 'Debes ingresar un número válido'
    }
  }
};

export default schema;
