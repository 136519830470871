import React from 'react';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import { CheckCircle } from 'react-feather';

const SuccessPass = () => {
  return (
    <div className="d-flex flex-column bg-light vh-100">
      <Container className="pt-2 mb-5">
        <Row className="justify-content-md-center my-5">
          <Col lg="8">
            <Card className="border-0 text-center bg-transparent">
              <Card.Body className="py-5">
                <CheckCircle size={70} className="font-weight-bold text-success"/>
                <h3 className="py-2 font-weight-bold title-color text-center my-5">
                  Contraseña modificada con exito
                </h3>
                <div className="justify-content-center d-flex">
                  <Button
                    className="btn-rounded col-6 base-btn-color mt-4 py-2"
                    variant="primary"
                    href={'/'}
                  >
                    Entendido
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SuccessPass;
