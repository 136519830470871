import React from 'react';
import { Container, Card} from 'react-bootstrap';
import { AccountingUserAdmin } from '../../components';

const AccountingView = () => {

  return (

    <div className="container-admin bg-light">
      <Container className="py-5" id='container_fluid' style={{ paddingLeft: '4rem' }} fluid>
        <div className="row">
          <div className="col-12" style={{WebkitFlex: '0 0 100%'}}>   
                <AccountingUserAdmin />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AccountingView;
