export const FETCH_LIST_DOCUMENTS = 'document/FETCH_LIST_DOCUMENTS';
export const FETCH_LIST_TEMPLATE = 'document/FETCH_LIST_TEMPLATE';
export const LIST_FOLDERS = 'document/FETCH_LIST_FOLDERS';
export const ROOT_DIRECTORY = 'document/ROOT_DIRECTORY';
export const FOLDER_ERRORS = 'document/FOLDER_ERRORS';
export const LOADING_FOLDER = 'document/LOADING_FOLDER';
export const DOCUMENT_LIST = 'document/DOCUMENT_LIST';
export const POST_FOLDER = 'document/POST_FOLDER';
export const POST_DOCUMENT = 'document/POST_DOCUMENT';
export const LOADING_DOCUMENT = 'document/LOADING_DOCUMENT';
export const SET_DOCUMENT_ERRORS = 'document/SET_DOCUMENT_ERRORS';
export const LIST_USER_FOLDERS = 'document/LIST_USER_FOLDERS';
export const SHARE_TOKEN = 'document/SHARE_TOKEN';
export const GET_DOCUMENT_TOKEN = 'document/GET_DOCUMENT_TOKEN';
export const LOADING_TOKEN = 'document/LOADING_TOKEN';
export const LOADING_NEW_FOLDER = 'document/LOADING_NEW_FOLDER';
export const DELETE_FOLDER = 'document/DELETE_FOLDER';
export const DELETE_DOCUMENT = 'document/DELETE_DOCUMENT';
export const PATCH_FOLDER = 'document/PATCH_FOLDER';
export const PATCH_DOCUMENT = 'document/PATCH_DOCUMENT';
export const TOKEN_CONFIRMATION = 'document/TOKEN_CONFIRMATION';
export const TOKEN_STATE = 'document/TOKEN_STATE';
export const EXPIRED_TOKEN = 'document/EXPIRED_TOKEN';
export const DELETE_ERRORS = 'document/DELETE_ERRORS';
export const GET_DOCUMENT_PANEL_TAX_FORMS = 'document/GET_DOCUMENT_PANEL_TAX_FORMS'
export const GET_DOCUMENT_PANEL_TAX_FORMS_ADMIN = 'document/GET_DOCUMENT_PANEL_TAX_FORMS_ADMIN'
export const GET_DOCUMENT_PANEL_TAX_FORMS_SUCCESS = 'document/GET_DOCUMENT_PANEL_TAX_FORMS_SUCCESS'
export const PUT_BREAD_CRUMP = 'document/PUT_BREAD_CRUMP'
export const GET_VALUE_BREAD_CRUMPS = 'document/GET_VALUE_BREAD_CRUMPS'
export const REMOVE_LAST_BREADCRUMP = 'document/REMOVE_LAST_BREADCRUMP'
export const GET_DOCUMENTS_TAX_PANEL = 'document/GET_DOCUMENTS_TAX_PANEL'
export const LOADING_PANEL_TAX = 'document/LOADING_PANEL_TAX'
export const NEW_VALUE_BREADCRUMP = 'document/NEW_VALUE_BREADCRUMP'