import React, { useEffect, useState } from 'react';
import {
  Row,
  Breadcrumb,
  Col,
  Button,
  Form,
  Spinner,
  Card,
  Accordion, FormGroup, FormControl
} from 'react-bootstrap';
import { Link, Prompt, useParams } from 'react-router-dom';
import { CheckCircle, ChevronRight } from 'react-feather';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_LIST_GROUPS } from '../../store/group/types';
import { FETCH_MODULES } from '../../store/report/types';
import { FETCH_LIST_PROJECTS } from '../../store/project/types';
import { FETCH_LIST_SOCIETIES } from '../../store/society/types';
import { FETCH_ACCOUNTING, PATCH_ACCOUNTING, POST_ACCOUNTING } from '../../store/accounting/types';

const EditAccounting = (
  {
    patchAccounting,
    userProfile,
    errors,
    loadingForm,
    showGroups,
    groupsList,
    showProjects,
    projectsList,
    showModulesList,
    listModules,
    showSocieties,
    societiesList,
    showAccounting,
    accountingData,
    isLoadedAccounting,
    groupsFilteredList
  }) => {
  const { uuid } = useParams();
  const [step, setStep] = useState(0);
  const [triggerForm, setTriggerForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promptAux, setPromptAux] = useState(true);
  const [flag, setFlag] = useState(false);
  const [serviceError, setServiceError] = useState(null);
  const [auxProjectSelected, setAuxProjectSelected] = useState([]);
  const [reportOneForm, setReportOneForm] = useState({});
  const [auxCheckLists, setAuxCheckLists] = useState({});
  const [reportTwoForm, setReportTwoForm] = useState({
    project:[],
    group: [],
    society: [],
    enable_project_admin: false,
    enable_group_admin: false,
    enable_user_group: false,
    enable_user_society: false
  });
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [projectFilters, setProjectFilters] = useState({
    projectSearch: "",
  });
  const [groupFilters, setGroupFilters] = useState({
    groupSearch: "",
    project: ""
  });
  const [societyFilters, setSocietyFilters] = useState({
    societySearch: "",
    project: "",
    group: ""
  });
  const [auxAllProjects, setAuxAllProjects] = useState([]);

  useEffect(() => {
    showAccounting(uuid);
  }, [showAccounting, uuid]);

  useEffect(() => {
    if(accountingData){
      const aux = [];
      const array = [];
      const auxGroup = [];
      const auxSociety = [];
      if(accountingData.project_list) {
        for(let i=0; i<accountingData.project_list.length; i++){
          aux.push({ value: accountingData.project_list[i].uuid, name:accountingData.project_list[i].project_name });
          array.push(accountingData.project_list[i].uuid)
        }
      }
      if(accountingData.group_list) {
        for(let j=0; j<accountingData.group_list.length; j++){
          auxGroup.push( accountingData.group_list[j].uuid );
        }
      }
      if(accountingData.society_list) {
        for(let j=0; j<accountingData.society_list.length; j++){
          auxSociety.push( accountingData.society_list[j].uuid );
        }
      }
      setAuxProjectSelected(aux);
      setAuxCheckLists({
        ...reportTwoForm,
        project: array,
        group:auxGroup,
        society:auxSociety,
        enable_project_admin: accountingData.enable_project_admin,
        enable_group_admin: accountingData.enable_group_admin,
        enable_user_group: accountingData.enable_user_group,
        enable_user_society: accountingData.enable_user_society
      });
      setReportTwoForm({
        ...reportTwoForm,
        enable_project_admin: accountingData.enable_project_admin,
        enable_group_admin: accountingData.enable_group_admin,
        enable_user_group: accountingData.enable_user_group,
        enable_user_society: accountingData.enable_user_society
      });
    }// eslint-disable-next-line
  },[accountingData]);

  useEffect(() => {
    window.onbeforeunload = (event) => {
      if(triggerForm === true){
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
      }
    };
  },);

  const handleInputChange = ({ target }) => {
    if(target.id === "projectsCheckbox"){
      const formData = {...reportTwoForm};
      if(formData.project.includes(target.value)){
        const pos = formData.project.indexOf(target.value);
        formData.project.splice(pos,1);
        const auxForm = auxProjectSelected;
        auxForm.splice(pos,1);
        setReportTwoForm(formData);
        setAuxProjectSelected(auxForm);
      }else{
        formData.project.push(target.value);
        const aux = {name:target.name, value:target.value}
        setReportTwoForm(formData);
        auxProjectSelected.push(aux);
      }
      const array = [...auxCheckLists.project];
      if(array.includes(target.value)){
        const pos = array.indexOf(target.value);
        array.splice(pos,1);
        setAuxCheckLists({...auxCheckLists,  project: array });
      }else{
        array.push(target.value);
        setAuxCheckLists({...auxCheckLists,  project: array });
      }
    }
    if(target.id === "groupsCheckbox"){
      const formData = {...reportTwoForm};
      if(formData.group.includes(target.value)){
        const pos = formData.group.indexOf(target.value);
        formData.group.splice(pos, 1);
        setReportTwoForm({...reportTwoForm, group: formData.group});
      }else{
        formData.group.push(target.value);
        setReportTwoForm({...reportTwoForm, group: formData.group});
      }
      const array = [...auxCheckLists.group];
      if(array.includes(target.value)){
        const pos = array.indexOf(target.value);
        array.splice(pos,1);
        setAuxCheckLists({...auxCheckLists,  group: array });
      }else{
        array.push(target.value);
        setAuxCheckLists({...auxCheckLists,  group: array });
      }
    }
    if(target.id === "societiesCheckbox"){
      const formData = {...reportTwoForm};
      if(formData.society.includes(target.value)){
        const pos = formData.society.indexOf(target.value);
        formData.society.splice(pos, 1);
        setReportTwoForm({...reportTwoForm, society: formData.society});
      }else{
        formData.society.push(target.value);
        setReportTwoForm({...reportTwoForm, society: formData.society});
      }
      const array = [...auxCheckLists.society];
      if(array.includes(target.value)){
        const pos = array.indexOf(target.value);
        array.splice(pos,1);
        setAuxCheckLists({...auxCheckLists,  society: array });
      }else{
        array.push(target.value);
        setAuxCheckLists({...auxCheckLists,  society: array });
      }
    }
    if(!triggerForm){
      setTriggerForm(true)
    }
    if(step === 0 || step === 2){
      const formData = { ...reportOneForm, [target.name]: target.value };
      setReportOneForm(formData);
    }
    if(step === 1){
      if(!(target.id === "projectsCheckbox" || target.id === "groupsCheckbox" || target.id === "societiesCheckbox")){
        if(target.id === "user_type"){
          if(target.name === "enable_project_admin"){
            const formData = {
              ...reportTwoForm,
              enable_project_admin: !reportTwoForm.enable_project_admin
            };
            setAuxCheckLists({...auxCheckLists, enable_project_admin:!auxCheckLists.enable_project_admin});
            setReportTwoForm(formData);
          }
          if(target.name === "enable_group_admin"){
            const formData = {
              ...reportTwoForm,
              enable_group_admin: !reportTwoForm.enable_group_admin
            };
            setAuxCheckLists({...auxCheckLists, enable_group_admin:!auxCheckLists.enable_group_admin});
            setReportTwoForm(formData);
          }
          if(target.name === "enable_user_group"){
            const formData = {
              ...reportTwoForm,
              enable_user_group: !reportTwoForm.enable_user_group
            };
            setAuxCheckLists({...auxCheckLists, enable_user_group:!auxCheckLists.enable_user_group});
            setReportTwoForm(formData);
          }
          if(target.name === "enable_user_society"){
            const formData = {
              ...reportTwoForm,
              enable_user_society: !reportTwoForm.enable_user_society
            };
            setAuxCheckLists({...auxCheckLists, enable_user_society:!auxCheckLists.enable_user_society});
            setReportTwoForm(formData);
          }
        }else{
          const formData = { ...reportTwoForm, [target.name]: target.value };
          setReportTwoForm(formData);
        }
      }
    }
  };

  const alert = () => {
    if (serviceError) {
      return (
        serviceError.map((error) => {
          return (<div key={error}>
            {error !== undefined ? (
              `-${error}`
            ) : (
              ''
            )}
          </div>);
        })
      );
    }
  };

  const sendData = () => {
    const fd = new FormData();
    let reportForm = { ...reportOneForm, ...reportTwoForm };
    if(reportForm.project.length === 0)
      delete reportForm.project;
    if(reportForm.group.length === 0)
      delete reportForm.group;
    if(reportForm.society.length === 0)
      delete reportForm.society;
    const arrKeys = Object.keys(reportForm);
    const arrValues = Object.values(reportForm);
    let i = 0;
    arrKeys.forEach( o => {
      fd.append(o, arrValues[i] );
      i++;
    })
    setPromptAux(false);
    setTriggerForm(false);
    setServiceError(null);
    setLoading(true);
    patchAccounting({ fd, uuid });
    setServiceError(errors);
    setFlag(true);
  }

  useEffect(() => {
    if(flag){
      if (!loadingForm && errors !== null) {
        setServiceError(errors);
        setLoading(false);
      }
      if (loadingForm === false && errors === null) {
        setStep(3);
        setFlag(false);
      }
    }
  }, [loadingForm, errors, flag]);

  useEffect(() => {
    if(step === 0){
      if (
        Object.keys(reportOneForm).some(key => reportOneForm[key] === '')
      ) {
        setDisableSubmit(true);
      } else {
        setDisableSubmit(false);
      }
    }
    if(step === 1){
      if(
        !reportTwoForm.enable_project_admin &&
        !reportTwoForm.enable_group_admin &&
        !reportTwoForm.enable_user_society &&
        !reportTwoForm.enable_user_group){
        setDisableSubmit(true);
      } else {
        setDisableSubmit(false);
      }
    }
    if( step === 2 ){
      setDisableSubmit(false);
    }
  }, [reportOneForm, reportTwoForm, step]);

  useEffect(() => {
    setServiceError(null);
  }, [errors]);

  useEffect(() => {
    showModulesList('?module_type=ACCOUNTING');
  }, [userProfile.profile, showModulesList]);

  useEffect(() => {
    showProjects({ ...projectFilters });
  }, [showProjects, projectFilters]);

  useEffect(() => {
    showGroups({ ...groupFilters });
  }, [showGroups, groupFilters]);

  useEffect(() => {
    showSocieties({ ...societyFilters });
  }, [showSocieties, societyFilters]);

  useEffect(() => {
    if(projectsList.length !== 0 && auxAllProjects.length === 0) {
      setAuxAllProjects(projectsList);
    }// eslint-disable-next-line
  }, [projectsList, auxAllProjects]);

  const onChange = nextStep => {
    setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
  };
  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);

  return (
    <Form>
      {
        accountingData && isLoadedAccounting && (
          <>
            <Row className="mb-4">
              <Prompt
                when={triggerForm && promptAux}
                message={`¿Estás seguro de desear abandonar este formulario? Los datos ingresados no serán guardados.`}
              />
              <Col>
                <Breadcrumb className="small">
                  <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
                  <Breadcrumb.Item href="/admin/reports">
                    Administración de reportes
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Creación reporte</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            {
              step >= 0 && step < 3 && (
                <Row className="mb-5">
                  <Col md="8" lg="6" className="mx-auto">
                    <div className="steps d-lg-flex justify-content-between align-items-center">
                      <div className={`step-item ${step === 0 ? 'active' : ''}`}>
                        <span className="step-item-indicator mr-3 small">1</span>
                        <h6 className="step-item-title mb-0 small">Datos principales</h6>
                      </div>
                      <ChevronRight size="12" />
                      <div className={`step-item ${step === 1 ? 'active' : ''}`}>
                        <span className="step-item-indicator mr-3 small">2</span>
                        <h6 className="step-item-title mb-0 small">Usuarios</h6>
                      </div>
                      <ChevronRight size="12" />
                      <div className={`step-item ${step === 2 ? 'active' : ''}`}>
                        <span className="step-item-indicator mr-3 small">3</span>
                        <h6 className="step-item-title mb-0 small">Ubicación</h6>
                      </div>
                    </div>
                  </Col>
                </Row>
              )
            }
            {step === 0 && (
              <Row className="mb-4">
                <Col xl="5" lg="6" className="mx-auto">
                  <h4 className="font-weight-bold mb-4">Datos principales</h4>
                  <Form.Group controlId="accounting_type">
                    <Form.Label className="font-weight-bold small">
                      Seleccionar reporte
                      <span className="ml-1 text-danger small">
                    (*)
                  </span>
                    </Form.Label>
                    <Form.Control
                      size="lg"
                      as="select"
                      placeholder="Ingresa tipo de reporte"
                      type="accounting_type"
                      name="accounting_type"
                      onChange={handleInputChange}
                      defaultValue={accountingData.accounting_type}
                    >
                      <option value='' disabled selected>Seleccione</option>
                      <option value='BALANCE_8_COLUMNS'>Reporte 8 columnas</option>
                      <option value='FINANCIAL_SITUATION'>Estado de situación financiera</option>
                      <option value='STATUS_RESULTS'>Estado de resultado</option>
                      <option value='CASH_FLOWS'>Estado de flujos efectivos</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="menu_name">
                    <Form.Label className="font-weight-bold small">
                      Título
                      <span className="ml-1 text-danger small">
                  (*)
                </span>
                    </Form.Label>
                    <Form.Control
                      size="lg"
                      placeholder="Ingresa un nombre"
                      name="menu_name"
                      type="menu_name"
                      onChange={handleInputChange}
                      defaultValue={accountingData.menu_name}
                    />
                  </Form.Group>
                  <Form.Group controlId="description">
                    <Form.Label className="font-weight-bold small">
                      Descripción
                      <span className="ml-1 text-danger small">
                  (*)
                </span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Escribe aquí..."
                      name="description"
                      type="description"
                      onChange={handleInputChange}
                      defaultValue={accountingData.description}
                    />
                  </Form.Group>
                  <span className="text-danger">
              (*)
              <span className="text-black-50">
                Campos obligatorios
              </span>
            </span>
                </Col>
              </Row>
            )}
            {step === 1 && (
              <Row className="mb-4">
                <Col xl="5" lg="6" className="mx-auto">
                  <h4 className="font-weight-bold mb-4">Parámetros</h4>
                  <Form.Group controlId="project">
                    <Form.Label className="font-weight-bold small">
                      Designar proyecto
                    </Form.Label>
                    <Accordion defaultActiveKey="1" className="mb-4">
                      <Card>
                        <Card.Header className="bg-transparent text-muted">
                          Seleccione
                        </Card.Header>
                        <Card.Body eventKey="1">
                          <Card.Body className="py-0">
                            <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                              <FormControl
                                className='mb-2'
                                size='md'
                                placeholder='Buscar...'
                                onChange={({target})=>setProjectFilters({ ...projectFilters, projectSearch: target.value })}
                              />
                            </FormGroup>
                            <Form.Group controlId="projectsCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                              {
                                projectsList.map( (project) =>{
                                  return (
                                    <Form.Check type="checkbox" key={project.uuid} label={project.project_name} name={project.project_name} value={project.uuid}  defaultChecked={auxCheckLists.project.includes(project.uuid)}/>
                                  );
                                })
                              }
                            </Form.Group>
                          </Card.Body>
                        </Card.Body>
                      </Card>
                    </Accordion>
                  </Form.Group>
                  <Form.Group controlId="group">
                    <Form.Label className="font-weight-bold small">
                      Designar grupo de sociedad
                    </Form.Label>
                    <Accordion defaultActiveKey="1" className="mb-4">
                      <Card>
                        <Card.Header className="bg-transparent text-muted">
                          Seleccione
                        </Card.Header>
                        <Card.Header className="bg-transparent">
                          <Form.Group controlId="project" className="m-0">
                            <Form.Control
                              size="md"
                              as="select"
                              placeholder="Ingresa un correo"
                              name="project"
                              type="project"
                              onChange={({target})=>setGroupFilters({ ...groupFilters, project: target.value })}
                            >
                              <option value="" className="secondary-text-color" selected> Todos los proyectos </option>
                              {
                                auxAllProjects.map( (project) =>{
                                  return (
                                    <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                                  );
                                })
                              }
                            </Form.Control>
                          </Form.Group>
                        </Card.Header>
                        <Card.Body eventKey="1">
                          <Card.Body className="py-0">
                            <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                              <FormControl
                                className='mb-2'
                                size='md'
                                placeholder='Buscar...'
                                onChange={({target})=>setGroupFilters({ ...groupFilters, groupSearch: target.value })}
                              />
                            </FormGroup>
                            <Form.Group controlId="groupsCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                              {
                                groupsList.map( (group) =>{
                                  return (
                                    <Form.Check type="checkbox" key={group.uuid} label={group.group_name} name={group.group_name} value={group.uuid} defaultChecked={auxCheckLists.group.includes(group.uuid)}/>
                                  );
                                })
                              }
                            </Form.Group>
                          </Card.Body>
                        </Card.Body>
                      </Card>
                    </Accordion>
                  </Form.Group>
                  <Form.Group controlId="society">
                    <Form.Label className="font-weight-bold small">
                      Designar sociedad
                    </Form.Label>
                    <Accordion defaultActiveKey="1" className="mb-4">
                      <Card>
                        <Card.Header className="bg-transparent text-muted">
                          Seleccione
                        </Card.Header>
                        <Card.Header className="bg-transparent">
                          {
                            userProfile.profile && (
                              userProfile.profile.user_role !== "GROUP_ADMIN" && (
                                <Form.Group controlId="project">
                                  <Form.Control
                                    size="md"
                                    as="select"
                                    placeholder="Ingresa un correo"
                                    name="project"
                                    type="project"
                                    onChange={({target})=> {
                                      showGroups({groupSearch: "" ,project: target.value, filtered: true});
                                      setSocietyFilters({ ...societyFilters, project: target.value }
                                      )
                                      if(target.value === "")
                                        setSocietyFilters({...societyFilters, project: "", group: ""})
                                    }}
                                  >
                                    <option value="" className="secondary-text-color" selected> Todos los proyectos </option>
                                    {
                                      auxAllProjects.map( (project) =>{
                                        return (
                                          <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                                        );
                                      })
                                    }
                                  </Form.Control>
                                </Form.Group>
                              )
                            )
                          }
                          <Form.Group controlId="group" className="m-0">
                            <Form.Control
                              size="md"
                              as="select"
                              placeholder="Ingresa un correo"
                              name="group"
                              type="group"
                              disabled={(societyFilters.project === "")}
                              onChange={({target})=>setSocietyFilters({ ...societyFilters, group: target.value })}
                            >
                              <option value="" className="secondary-text-color" selected> Todos los grupos </option>
                              {
                                groupsFilteredList.map( (group) =>{
                                  return (
                                    <option value={group.uuid} key={group.uuid}>{group.group_name}</option>
                                  );
                                })
                              }
                            </Form.Control>
                          </Form.Group>
                        </Card.Header>
                        <Card.Body eventKey="1">
                          <Card.Body className="py-0">
                            <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                              <FormControl
                                className='mb-2'
                                size='md'
                                placeholder='Buscar...'
                                onChange={({target})=>setSocietyFilters({ ...societyFilters, societySearch: target.value })}
                              />
                            </FormGroup>
                            <Form.Group controlId="societiesCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                              {
                                societiesList.map( (society) =>{
                                  return (
                                    <Form.Check type="checkbox" key={society.uuid} label={society.society_business_name} name={society.society_business_name} value={society.uuid} defaultChecked={auxCheckLists.society.includes(society.uuid)}/>
                                  );
                                })
                              }
                            </Form.Group>
                          </Card.Body>
                        </Card.Body>
                      </Card>
                    </Accordion>
                  </Form.Group>
                  <Form.Group controlId="user_type">
                    <Form.Label className="font-weight-bold small">
                      Designar tipo de usuario
                      <span className="ml-1 text-danger small">
                  (*)
                </span>
                    </Form.Label>
                    <Accordion defaultActiveKey="1" className="mb-4">
                      <Card>
                        <Card.Header className="bg-transparent text-muted">
                          Seleccione
                        </Card.Header>
                        <Card.Header className="bg-transparent">
                          <Card.Body>
                            <Form.Group controlId="user_type" onChange={handleInputChange}>
                              <Form.Check type="checkbox" label="Admin de proyecto" name="enable_project_admin" value={reportTwoForm.enable_project_admin} defaultChecked={auxCheckLists.enable_project_admin}/>
                              <Form.Check type="checkbox" label="Admin de grupo" name="enable_group_admin" value={reportTwoForm.enable_group_admin} defaultChecked={auxCheckLists.enable_group_admin}/>
                              <Form.Check type="checkbox" label="Usuario de grupo" name="enable_user_group" value={reportTwoForm.enable_user_group} defaultChecked={auxCheckLists.enable_user_group}/>
                              <Form.Check type="checkbox" label="Usuario de sociedad" name="enable_user_society" value={reportTwoForm.enable_user_society} defaultChecked={auxCheckLists.enable_user_society}/>
                            </Form.Group>
                          </Card.Body>
                        </Card.Header>
                      </Card>
                    </Accordion>
                  </Form.Group>
                  <span className="text-danger">
                    (*)
                    <span className="text-black-50">
                      Campos obligatorios
                    </span>
                  </span>
                </Col>
              </Row>
            )}
            {step === 2 && (
              <Row className="mb-4">
                <Col xl="5" lg="6" className="mx-auto">
                  <h4 className="font-weight-bold mb-4">Ubicación</h4>
                  <Form.Group controlId="module">
                    <Form.Label className="font-weight-bold small">
                      Modulo
                      <span className="ml-1 text-danger small">
                    (*)
                  </span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      placeholder="Ingresa un correo"
                      type="module"
                      name="module"
                      onChange={handleInputChange}
                    >
                      <option value='' disabled selected>Seleccione</option>
                      {
                        listModules.map( (module) => {
                          return(
                            accountingData.module &&(
                              accountingData.module.uuid === module.uuid ? (
                                <option key={module.uuid} value={module.uuid} selected>{module.module_name}</option>
                              ):(
                                <option key={module.uuid} value={module.uuid}>{module.module_name}</option>
                              )
                            )
                          );
                        })
                      }
                    </Form.Control>
                  </Form.Group>
                  <span className="text-danger">
              (*)
              <span className="text-black-50">
                Campos obligatorios
              </span>
            </span>
                </Col>
              </Row>
            )}
            {step === 3 && (
              <Row>
                <Col xs="12" className="text-center">
                  <CheckCircle size="48" className="text-success mb-4"/>
                  <h5 className="font-weight-bold mb-4">Reporte creado con éxito</h5>
                </Col>
              </Row>
            )}
            {
              serviceError ? (
                <div className="alert alert-danger my-3 col-lg-6 col-md-8 mx-auto" role="alert">
                  {alert()}
                </div>
              ) : null
            }
            <Row className="d-flex justify-content-center mb-5">
              {step > 0 && step < 3 && (
                <Button variant="light" className="mx-2" onClick={onPrevious}>Atrás</Button>
              )}
              {step === 3 ?(
                <Button className="mx-2" as={Link} to="/admin/accounting">Ir a contabilidad</Button>
              ) :(
                step === 2 ?
                  (
                    <Button
                      className="mx-2"
                      onClick={sendData}
                      disabled={ loading || loadingForm || disableSubmit}
                    >
                      {
                        loading || loadingForm ? (
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        ) : (
                          ''
                        )
                      }{' '}
                      Crear reporte
                    </Button>
                  ):(
                    <Button className="mx-2" onClick={onNext} disabled={disableSubmit}>Siguiente</Button>
                  )
              )}
            </Row>
          </>
        )
      }
    </Form>
  )
}

EditAccounting.propTypes = {
  createAccounting: PropTypes.func.isRequired,
  showGroups: PropTypes.func.isRequired,
  groupsList: PropTypes.array,
  showProjects: PropTypes.func.isRequired,
  projectsList: PropTypes.array,
  showModulesList: PropTypes.func.isRequired,
  showSocieties: PropTypes.func.isRequired,
  societiesList: PropTypes.array,
  accountingData: PropTypes.object,
  showAccounting: PropTypes.func.isRequired,
  patchAccounting: PropTypes.func.isRequired
};

const stateToProps = state => ({
  errors: state.accounting.errors,
  userProfile: state.profile.userProfile,
  loadingForm: state.accounting.loadingForm,
  groupsList: state.groups.groupsList,
  isLoaded: state.accounting.isLoaded,
  projectsList: state.projects.projectsList,
  listModules: state.reports.listModules,
  societiesList: state.societies.societiesList,
  accountingData: state.accounting.accountingData,
  isLoadedAccounting: state.accounting.isLoadedAccounting,
  groupsFilteredList: state.groups.groupsFilteredList
});

const actionsToProps = dispatch => ({
  showGroups: payload => dispatch({ type: FETCH_LIST_GROUPS, payload }),
  createAccounting: payload => dispatch({ type: POST_ACCOUNTING, payload }),
  showProjects: payload => dispatch({ type: FETCH_LIST_PROJECTS, payload }),
  showModulesList: payload => dispatch({ type: FETCH_MODULES, payload }),
  showSocieties: payload => dispatch({ type: FETCH_LIST_SOCIETIES, payload }),
  showAccounting: payload => dispatch({ type: FETCH_ACCOUNTING, payload }),
  patchAccounting: payload => dispatch({ type: PATCH_ACCOUNTING, payload })
});

export default connect(stateToProps, actionsToProps)(EditAccounting);
