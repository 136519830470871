import config from '../config';

const { logBrowserURI, logoutURI } = config().services;

const network = client => ({
  postBrowseData: payload => client.post(logBrowserURI, { ...payload }),
  postSessionLogout: () => client.post(logoutURI)
});


export default network;
