import {
  SET_DATA_LIST_USERS,
  IS_LOADED,
  INDEX_PAGINATION_USERS,
  IS_LOADED_USER,
  SET_DATA_USER,
  LOADING_USER_FORM,
  SET_USER_ERRORS,
  DELETE_USER_ERRORS,
  SET_DATA_LIST_INVITATIONS,
  INDEX_PAGINATION_INVITATIONS,
  INVITATION_LOADING,
  INVITATION_ERRORS,
  RESEND_LOADING,
  INVITATION_SENT, LOADING_CSV
} from './types';

const initialState = {
  usersList: [],
  invitationsList: [],
  userData:null,
  isLoadedUser:false,
  indexPagination:0,
  indexPaginationInvitations:0,
  errors: null,
  loadingUserForm: false,
  deleteErrors: false,
  loadingInvitation: false,
  invitationErrors: null,
  loadingResend: false,
  invitationSent: false,
  loadingCsv: false
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case SET_DATA_LIST_USERS:
    return { ...state, usersList: payload };
  case SET_DATA_LIST_INVITATIONS:
    return { ...state, invitationsList: payload };
  case IS_LOADED:
    return { ...state, isLoaded: payload };
  case INDEX_PAGINATION_USERS:
    return { ...state, indexPagination: payload };
  case INDEX_PAGINATION_INVITATIONS:
    return { ...state, indexPaginationInvitations: payload };
  case LOADING_USER_FORM:
    return { ...state, loadingUserForm: payload };
  case SET_USER_ERRORS:
    return { ...state, errors: payload };
  case IS_LOADED_USER:
    return { ...state, isLoadedUser: payload };
  case SET_DATA_USER:
    return { ...state, userData: payload };
  case DELETE_USER_ERRORS:
    return { ...state, deleteErrors: payload };
  case INVITATION_LOADING:
    return { ...state, loadingInvitation: payload };
  case INVITATION_ERRORS:
    return { ...state, invitationErrors: payload };
  case RESEND_LOADING:
    return { ...state, loadingResend: payload };
  case INVITATION_SENT:
    return { ...state, invitationSent: payload };
  case LOADING_CSV:
    return { ...state, loadingCsv: payload };
  default:
    return state;
  }
};

export default reducer;
