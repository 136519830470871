import {
  SET_DATA_LIST_REPORTS,
  IS_LOADED,
  SET_DATA_REPORT,
  IS_LOADED_REPORT,
  INDEX_PAGINATION_REPORTS,
  LOADING_REPORT_FORM,
  SET_REPORT_ERRORS,
  SET_USER_DATA_LIST_REPORTS,
  SET_LIST_MODULES, SET_DATA_LIST_REPORTS_DISABLED, INDEX_PAGINATION_REPORTS_DISABLED,
  GET_REPORTS_REF_REDUCER
} from './types';

const initialState = {
  reportsList: [],
  disabledReportsList: [],
  reportsUserList: [],
  reportData:null,
  loadingForm: false,
  isLoaded:false,
  isLoadedReport: false,
  indexPagination:0,
  indexPaginationDisabled:0,
  errors: null,
  listModules: [],
  reportsRef: []
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case SET_DATA_LIST_REPORTS:
    return { ...state, reportsList: payload };
  case SET_DATA_LIST_REPORTS_DISABLED:
    return { ...state, disabledReportsList: payload };
  case SET_USER_DATA_LIST_REPORTS:
    return { ...state, reportsUserList: payload };
  case SET_DATA_REPORT:
    return { ...state, reportData: payload };
  case LOADING_REPORT_FORM:
    return { ...state, loadingForm: payload };
  case SET_REPORT_ERRORS:
    return { ...state, errors: payload };
  case IS_LOADED:
    return { ...state, isLoaded: payload };
  case IS_LOADED_REPORT:
    return { ...state, isLoadedReport: payload };
  case INDEX_PAGINATION_REPORTS:
    return { ...state, indexPagination: payload };
  case INDEX_PAGINATION_REPORTS_DISABLED:
    return { ...state, indexPaginationDisabled: payload };
  case SET_LIST_MODULES:
    return { ...state, listModules: payload };
  case GET_REPORTS_REF_REDUCER:
    return { ...state, reportsRef: payload };
  default:
    return state;
  }
};

export default reducer;
