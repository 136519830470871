import React from 'react'
import { Container, Card } from 'react-bootstrap'
import { AccountingDetail } from '../../components';

const DetailAccountingView = () => {
  return (
    <div className="container-admin bg-light">
      <Container className="py-5" id='layout-reports2' style={{ paddingLeft: '4rem' }} fluid>
        <div className="row">
          <div className="col-12">
            <Card className="border-0 shadow-sm mb-5">
              <Card.Body>
                <AccountingDetail/>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default DetailAccountingView;
