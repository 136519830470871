  import React, { useEffect, useState } from 'react';
import { Breadcrumb, Col, Row, Button, Table } from 'react-bootstrap'
import { ConfigProvider } from 'react-avatar';
import SocietyItem from '../society_item';
import UsersItem from '../users_item';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_GROUP, FETCH_PDF } from '../../store/group/types';
import { useParams } from 'react-router-dom';
import ProjectItem from '../project_item';
import { Copy } from 'react-feather';

const GroupDetail = ({showGroup, groupData, showGroupPDF, userProfile}) => {
  const { uuid } = useParams();
  useEffect(() => {
    showGroup(uuid);
    
    if (groupData && groupData.project && groupData.project.enable) {
      setProjectEnabled(groupData.project.enable);
     
    }
  }, [showGroup, uuid, groupData]);

  const [projectEnabled, setProjectEnabled] = useState(false);

 


  const handlePDF = () => {
    showGroupPDF(uuid);
  }

  return (
    <>
      {
        groupData ? (
         
            <>

              <Row  className='d-flex align-items-center justify-content-beetwen' id='projectsAdmin'>
                <Col className='p-0'>
                  <Breadcrumb className="small">
                    <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
                    <Breadcrumb.Item
                      href={
                        userProfile && (
                          userProfile.profile.user_role === "SUPER_ADMIN" ? (
                            `/admin/group`
                          ):(
                            `/admin/group#1?search=&project=${localStorage.project}&ordering=`
                          )
                        )
                      }
                    >
                      Administración grupo sociedad
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Detalle grupo</Breadcrumb.Item>
                  </Breadcrumb>
                  <h4 className="font-weight-bold mb-1 ml-3">{groupData.group_name}</h4>
                </Col>

                <Col>
                  <Row className="float-sm-right ml-1">
                    <div className="mt-3 mr-3">
                      <span className="font-weight-bold">ID:</span>
                      <span className="">{groupData.uuid.substring(0, 10)}...</span>
                    </div>
                    <Button
                      className="m-0 mt-2 mr-3"
                      variant="outline-primary"
                      onClick={() => {navigator.clipboard.writeText(groupData.uuid)}}
                    >
                      <Copy size={20}/>
                    </Button>
                  </Row>
                </Col>
              </Row>

              <hr />
              <Row  id='projects_item'>
                <Col>
                  <h6 className="font-weight-bold">Descripción del grupo</h6>
                  <p className="small">{groupData.group_description}</p>
                </Col>
              </Row>


              {
                groupData.project && (
                  <div className='mt-3'>
                    <Row>
                      <Col>
                        <h6 className="font-weight-bold">Proyecto</h6>
                      </Col>
                    </Row>
                    <Row >
                      <Col>
                        <ProjectItem key={groupData.project.uuid} uuid={groupData.project.uuid} enabled={projectEnabled} name={groupData.project.project_name} readOnly="true"/>
                      </Col>
                    </Row>
                  </div>
                )
              }
              {
                groupData.societies && (
                  groupData.societies.length !== 0 && (

                    <div className='mt-3'>
                    <Row>
                      <Col>
                      <h6 className="font-weight-bold">Sociedad</h6>
                      </Col>
                    </Row>
                    <Row id='projects_item' className='mb-3'>
                      <Col>
                      {
                            groupData.societies.map((society) => {
                              return (
                                <SocietyItem readOnly="true" key={society.uuid} name={society.society_business_name} rut={society.society_rut} project={society.project} variant="warning" group={society.group} uuid={society.uuid}/>
                              )
                            })
                          }
                      </Col>
                    </Row>
                  </div>
                  )
                )
              }
              {
                groupData.admin && (
                  groupData.admin.length !== 0 && (
                    <>
                      <Row>
                        <Col lg="6">
                          <h6 className="font-weight-bold">Admin</h6>
                        </Col>
                      </Row>
                      {
                        groupData.admin.map( (admin) => {
                          return (
                            <Table striped hover size="md">
                              <tbody>
                                <UsersItem key={admin.uuid} uuid={admin.uuid} name={`${admin.first_name} ${admin.last_name}`} readOnly="true" />
                              </tbody>
                            </Table>
                          );
                        })
                      }
                    </>
                  )
                )
              }
              {
                groupData.user_group && (
                  groupData.user_group.length !== 0  && (
                    <>
                      <Row>
                        <Col lg="6">
                          <h6 className="font-weight-bold mt-5">Usuarios grupo</h6>
                        </Col>
                      </Row>
                      {
                        groupData.user_group.map( (user) => {
                          return (
                            <Row>
                              <Col xs="12">
                                <UsersItem key={user.uuid} uuid={user.uuid} name={`${user.first_name} ${user.last_name}`} readOnly="true"/>
                              </Col>
                            </Row>
                          );
                        })
                      }
                    </>
                  )
                )
              }
              {
                groupData.user_society && (
                  groupData.user_society.length !== 0  && (
                    <>
                      <Row>
                        <Col lg="6">
                          <h6 className="font-weight-bold mt-3">Usuarios sociedad</h6>
                        </Col>
                      </Row>
                      {
                        groupData.user_society.map( (user) => {
                          return (
                            <Row>
                              <Col xs="12">
                                <UsersItem key={user.uuid} uuid={user.uuid} name={`${user.first_name} ${user.last_name}`} society={user.society} readOnly="true"/>
                              </Col>
                            </Row>
                          );
                        })
                      }
                    </>
                  )
                )
              }
              <Col className="d-flex justify-content-end mt-5">
                <Button variant="primary" onClick={handlePDF} size="lg">Imprimir info</Button>
              </Col>
            </>):(
          ""
        )
      }
    </>
  )
}


GroupDetail.propTypes = {
  showGroup: PropTypes.func.isRequired,
  groupData: PropTypes.object,
  isLoaded: PropTypes.bool.isRequired,
  showGroupPDF: PropTypes.func.isRequired
};

const stateToProps = state => ({
  groupData: state.groups.groupData,
  isLoaded: state.groups.isLoaded,
  userProfile: state.profile.userProfile,
});

const actionsToProps = dispatch => ({
  showGroup: payload => dispatch({ type: FETCH_GROUP, payload }),
  showGroupPDF: payload => dispatch({ type: FETCH_PDF, payload })
});

export default connect(stateToProps, actionsToProps)(GroupDetail);
