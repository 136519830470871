import React, { useState } from 'react';
import { Button, Card, Dropdown, Modal } from 'react-bootstrap';
import { Trash2 } from 'react-feather';

const DocumentDelete = ({ uuid, deleteDocument }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleDelete = () => {
    handleClose();
    deleteDocument(uuid);
  }

  return (
    <>
      <Dropdown.Item
        data-toggle="tooltip"
        data-placement="top"
        title="Eliminar documento"
        className="text-danger"
        onClick={handleShow}
      >
        <Trash2 size="15" className="mr-1"/>
        Eliminar
      </Dropdown.Item>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton/>
        <Modal.Body>
          <Card.Body className="py-5 text-center">
            <i className="far fa-trash-alt text-danger text-center fa-3x mb-4"/>
            <h3 className="py-2 font-weight-bold title-color text-center">
              Eliminar documento
            </h3>
            <p className="text-center">
              ¿Estás seguro que deseas eliminar este documento?
            </p>
            <div className="justify-content-center d-flex">
              <Button
                className="btn-rounded col-6 base-btn-color mt-4 py-2"
                variant="primary"
                onClick={handleDelete}
              >
                Seguro
              </Button>
            </div>
          </Card.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DocumentDelete;
