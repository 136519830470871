import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SendEmail from '../../components/sendEmailResetPassword/sendEmail';

const SendEmailReset = () => {
  return (
    <div className="d-flex flex-column bg-light vh-100">
      <Container className="pt-2 mb-5">
        <Row className="justify-content-md-center mt-4">
          <Col lg="12">
            <SendEmail/>
          </Col>
        </Row>
      </Container>
    </div>

  );
};

export default SendEmailReset;
