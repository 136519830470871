import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Modal, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Inbox, Mail } from 'react-feather';
import { POST_TICKET } from '../../store/profile/types';
import validator from '../../utils/validator';
import { ticketSchema } from '../../utils/validator/schemas';

const ZohoModal = (
  {
    type,
    postZohoTicket,
    ticketErrors,
    loadingTicket
  }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [ticketForm, setTicketForm] = useState({ subject:'', description:'' });
  const [validationError, setValidationError] = useState({ description:'' });
  const [flag, setFlag] = useState(false);

  const handleTicket = () => {
    postZohoTicket(ticketForm);
    setFlag(true);
  }

  useEffect(() => {
    if(!loadingTicket && ticketErrors.length === 0 && flag) {
      handleClose();
      setFlag(false);
    }
  }, [loadingTicket, ticketErrors, flag]);

  useEffect(() => {
    if (
      Object.keys(ticketForm).some(key => ticketForm[key] === '')
    ) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [ticketForm]);

  const handleInputChange = ({ target }) => {
    const formData = { ...ticketForm, [target.name]: target.value };
    setTicketForm(formData);

    if(target.name === 'description'){
      setValidationError({ ...validationError, [target.name]: '' });
      const errors = validator({ [target.name]: ticketSchema[target.name] }, formData);
      if (errors.length > 0) {
        const messages = errors
          .map(e => ({...validationError, [e.field]: e.message }))
          .reduce((prev, current) => ({ ...prev, ...current }), {});
        setValidationError(messages);
        return false;
      }
    }
  };

  return (
    <>
      {
        type === 1 && (
          <Dropdown.Item className='d-flex align-items-center' onClick={handleShow}>
            <Inbox className='mr-2' size="20"></Inbox>
            <p className='mb-0 small' >Enviar ticket de soporte</p>
          </Dropdown.Item>
        )
      }

      {
        type === 2 && (
          <Button variant="outline-primary" size="sm" onClick={handleShow}>
            <Mail size="16" className='mr-2'></Mail>
            Enviar ticket de soporte
          </Button>
        )
      }
       {
        type === 3 && (
          <Button className='btn_contact' variant="outline-primary" size="sm" onClick={handleShow}>
            <p style={{color:'#FFF', margin: '2px', fontSize: '17px'}} ><Mail size="20" className='icon_smartphone mr-2'  />Enviar ticket de soporte</p> 
          </Button>
        )
      }

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton/>
        <Modal.Body className='p-5 border-0' >
          <h3 className='font-weight-bold'>¿Necesitas ayuda?</h3>
          <p className='text-muted'>Le responderemos pronto con un mensaje de correo electrónico</p>

          <Form.Group controlId="subject">
            <Form.Label className="small">
              Asunto
            </Form.Label>
            <Form.Control
              size="lg"
              placeholder="Ingresa asunto"
              name="subject"
              type="subject"
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="description">
            <Form.Label className="small">
              Mensaje
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Díganos cómo podemos ayudarte..."
              name="description"
              type="description"
              onChange={handleInputChange}
              isInvalid={validationError.description}
            />
            <Form.Text id="passwordHelpBlock" muted>
              Máximo 1024 carácteres
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {validationError.description}
            </Form.Control.Feedback>
          </Form.Group>

          {
            !loadingTicket && ticketErrors.length !== 0 && (
              ticketErrors.map(
                (error) => {
                  return(<p key={error} className='text-danger'>{error}</p>)
                }
              )
            )
          }

          <Button disabled={disableSubmit || validationError.description !== ''} variant="primary" className='float-right' onClick={handleTicket} size="md">
            {
              loadingTicket ?(
                <Spinner animation="border" className="spinner-custom" />
              ):(
                'Enviar'
              )
            }
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

ZohoModal.propTypes = {
  postZohoTicket: PropTypes.func.isRequired,
  ticketErrors: PropTypes.array,
  loadingTicket: PropTypes.bool,
};

const stateToProps = state => ({
  ticketErrors: state.profile.ticketErrors,
  loadingTicket: state.profile.loadingTicket
});

const actionsToProps = dispatch => ({
  postZohoTicket: payload => dispatch({ type: POST_TICKET, payload })
});

export default connect(stateToProps, actionsToProps)(ZohoModal);
