import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { UploadCloud, XCircle, File } from 'react-feather';

const FileUpload = ({setFile, file, setFlag}) => {

  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);

  useEffect(() => {
      setFile(picture);
  }, [file, picture, setFile]);

  const onChangePicture = e => {
    if (e.target.files[0]) {
      if(imgData){
        setImgData(null);
        setFlag(false);
      }
      setFlag(true);
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const deleteImg = () => {
    setImgData(null);
    setFlag(false);
  }

  return (
    <>
      <label className="upload-file-custom position-relative">
        <input type="file" onChange={onChangePicture} />
        <div className="upload-file-custom-content text-center mb-4">
          { !imgData &&
            <>
              <UploadCloud color="#999999"/>
              <span className="file-custom text-center font-weight-bold d-block">Subir archivo</span>
            </>
          }
          { imgData &&
          <>
            <File size="30" className="font-weight-bold"/>
            <p>Archivo adjunto</p>
            <Button variant="light" className="btn-delete" onClick={deleteImg}>
              <XCircle/>
            </Button>
          </>
          }
        </div>
      </label>
    </>
  )
}

export default FileUpload;
