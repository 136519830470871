export const SET_DATA_PROJECT = 'project/SET_DATA_PROJECT';
export const FETCH_PROJECT = 'project/FETCH_PROJECT';
export const SET_DATA_LIST_PROJECTS = 'project/SET_DATA_LIST_PROJECTS';
export const FETCH_LIST_PROJECTS = 'project/FETCH_LIST_PROJECTS';
export const IS_LOADED = 'project/IS_LOADED';
export const IS_LOADED_PROJECT = 'project/IS_LOADED_PROJECT';
export const POST_PROJECTS = 'project/POST_PROJECTS';
export const PATCH_PROJECT = 'project/PATCH_PROJECT';
export const FETCH_PROJECT_ADMINS = 'project/FETCH_PROJECT_ADMINS';
export const SET_LIST_PROJECT_ADMINS = 'project/SET_LIST_PROJECT_ADMINS';
export const DELETE_PROJECT = 'project/DELETE_PROJECT';
export const FETCH_PDF = 'project/FETCH_PDF';
export const INDEX_PAGINATION_PROJECTS = 'project/INDEX_PAGINATION_PROJECTS';
export const SET_PROJECT_ERRORS = 'project/SET_PROJECT_ERRORS';
export const LOADING_PROJECT_FORM = 'project/LOADING_PROJECT_FORM';
export const FETCH_USER_PROJECTS = 'project/FETCH_USER_PROJECTS';
