export const SET_DATA_ACCOUNTING = 'accounting/SET_DATA_ACCOUNTING';
export const FETCH_ACCOUNTING = 'accounting/FETCH_ACCOUNTING';
export const SET_DATA_LIST_ACCOUNTING = 'accounting/SET_DATA_LIST_ACCOUNTING';
export const SET_DATA_LIST_ACCOUNTING_DISABLED = 'accounting/SET_DATA_LIST_ACCOUNTING_DISABLED';
export const FETCH_LIST_ACCOUNTING = 'accounting/FETCH_LIST_ACCOUNTING';
export const IS_LOADED = 'accounting/IS_LOADED';
export const IS_LOADED_ACCOUNTING = 'accounting/IS_LOADED_ACCOUNTING';
export const POST_ACCOUNTING = 'accounting/POST_ACCOUNTING';
export const PATCH_ACCOUNTING = 'accounting/PATCH_ACCOUNTING';
export const DELETE_ACCOUNTING = 'accounting/DELETE_ACCOUNTING';
export const INDEX_PAGINATION_ACCOUNTING = 'accounting/INDEX_PAGINATION_ACCOUNTING';
export const INDEX_PAGINATION_ACCOUNTING_DISABLED = 'accounting/INDEX_PAGINATION_ACCOUNTING_DISABLED';
export const LOADING_ACCOUNTING_FORM = 'accounting/LOADING_ACCOUNTING_FORM';
export const SET_ACCOUNTING_ERRORS = 'accounting/SET_ACCOUNTING_ERRORS';
export const FETCH_MODULES = 'accounting/FETCH_MODULES';
export const SET_LIST_MODULES = 'accounting/LIST_MODULES';
export const FETCH_USER_ACCOUNTING_LIST = 'accounting/FETCH_USER_ACCOUNTING_LIST';
export const SET_USER_DATA_LIST_ACCOUNTING = 'accounting/SET_USER_DATA_LIST_ACCOUNTING';
export const FETCH_BALANCE_8_COLUMNS = 'accounting/FETCH_BALANCE_8_COLUMNS';
export const DATA_BALANCE_8_COLUMNS = 'accounting/DATA_BALANCE_8_COLUMNS';
export const DETAIL_BALANCE_8_COLUMNS = 'accounting/DETAIL_BALANCE_8_COLUMNS';
export const FETCH_DETAIL_BALANCE_8_COLUMNS = 'accounting/FETCH_DETAIL_BALANCE_8_COLUMNS';
export const IS_LOADED_ACCOUNTING_DETAIL = 'accounting/IS_LOADED_ACCOUNTING_DETAIL';
export const FETCH_DATES_BALANCE_8_COLUMNS = 'accounting/FETCH_DATES_BALANCE_8_COLUMNS';
export const DATES_BALANCE_8_COLUMNS = 'accounting/DATES_BALANCE_8_COLUMNS';
export const FETCH_PDF_BALANCE_8_COLUMNS = 'accounting/FETCH_PDF_BALANCE_8_COLUMNS';
export const DATES_CASH_FLOW = 'accounting/DATES_CASH_FLOW';
export const FETCH_DATES_CASH_FLOW = 'accounting/FETCH_DATES_CASH_FLOW';
export const FETCH_CASH_FLOW = 'accounting/FETCH_CASH_FLOW';
export const DATA_CASH_FLOW = 'accounting/DATA_CASH_FLOW';
export const DATES_FINANCIAL_SITUATION = 'accounting/DATES_FINANCIAL_SITUATION';
export const FETCH_FINANCIAL_SITUATION = 'accounting/FETCH_FINANCIAL_SITUATION';
export const FETCH_DATES_FINANCIAL_SITUATION = 'accounting/FETCH_DATES_FINANCIAL_SITUATION';
export const DATA_FINANCIAL_SITUATION = 'accounting/DATA_FINANCIAL_SITUATION';
export const FETCH_FINANCIAL_SITUATION_DETAIL = 'accounting/FETCH_FINANCIAL_SITUATION_DETAIL';
export const IS_LOADED_FINANCIAL_SITUATION_DETAIL = 'accounting/FETCH_FINANCIAL_SITUATION_COLUMNS';
export const DETAIL_FINANCIAL_SITUATION = 'accounting/DETAIL_FINANCIAL_SITUATION';
export const FETCH_ACCOUNT_DETAIL = 'accounting/FETCH_ACCOUNT_DETAIL';
export const IS_LOADED_ACCOUNT_DETAIL = 'accounting/IS_LOADED_ACCOUNT_DETAIL';
export const DETAIL_ACCOUNT = 'accounting/DETAIL_ACCOUNT';
export const DATES_STATUS_RESULTS = 'accounting/DATES_STATUS_RESULTS';
export const FETCH_STATUS_RESULTS = 'accounting/FETCH_STATUS_RESULTS';
export const FETCH_DATES_STATUS_RESULTS = 'accounting/FETCH_DATES_STATUS_RESULTS';
export const DATA_STATUS_RESULTS = 'accounting/DATA_STATUS_RESULTS';
export const FETCH_STATUS_RESULTS_DETAIL = 'accounting/FETCH_STATUS_RESULTS_DETAIL';
export const DETAIL_STATUS_RESULTS = 'accounting/DETAIL_STATUS_RESULTS';
export const DETAIL_STATUS_ACCOUNT = 'accounting/DETAIL_STATUS_ACCOUNT';
export const FETCH_ACCOUNT_STATUS_DETAIL = 'accounting/FETCH_ACCOUNT_STATUS_DETAIL';
export const DATA_EXIST = 'accounting/DATA_EXIST';
export const FETCH_EXCEL = 'accounting/FETCH_EXCEL';
