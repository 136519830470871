import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Badge, Dropdown } from 'react-bootstrap'
import Avatar from 'react-avatar'
import { User, Users, Edit, List, MoreVertical, ArrowRight } from 'react-feather';
import { useHistory } from 'react-router-dom';
import GroupDelete from './groupDelete';

import sociedad from '../../images/sociedades.png'
import user from '../../images/usuarios.png'

const GroupItem = props => {
  const { readOnly } = props;
  const history = useHistory();

  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    const windowLocation = window.location.pathname
    const rutaInicial = windowLocation.split('/').slice(0, 4).join('/')
    setCurrentPath(rutaInicial)
    console.log(currentPath)
  }, [currentPath])

  return (
    currentPath === '/admin/society/detail' ? (
      <>
      <Row className='p-3' id={ currentPath === '/admin/society/detail' ? '' : 'projects_item'  }>
        <>
          <Col  xs="12" sm="12" lg="6" xl="6" className='d-flex align-items-center justify-content-md-start justify-content-center' >

            <div className="media">

              {/*
                <Avatar maxInitials="2" round="12px" fgColor="black" size="45" name={props.name} className="mr-3"/>
              */}

              <div className="media-body mb-0">
                <h6>{props.name}</h6>
                {!readOnly &&
                  <h6 className="text-muted small mb-0">{props.admin} Administradores</h6>
                }
              </div>
            </div>
          </Col>

          <Col xs="12" sm="12" lg="6" xl="6" className="">
            <div className="d-flex align-items-end" style={{display:'flex', justifyContent:'end'}}>

              <Dropdown className='project-item'>
                {!readOnly && (
                  <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" className='m-2'  size="sm">
                    <Edit size="12" />
                  </Dropdown.Toggle>
                )}
                {!readOnly && (
                  <Dropdown.Menu className="shadow-sm border-0">
                    <Dropdown.Item onClick={() => history.push({ pathname: `/admin/group/edit/${props.uuid}` })}>Editar</Dropdown.Item>
                    <GroupDelete uuid={props.uuid} setFlagDelete={props.setFlagAction} />
                  </Dropdown.Menu>
                )}

                <Button variant="outline-dark" size="sm" className='m-1' onClick={() => history.push({ pathname: `/admin/group/detail/${props.uuid}` })}>
                  Ver Detalle
                  <List size="12" className='ml-2 mb-0' />
                </Button>
              </Dropdown>
            </div>
          </Col>
          
        </>
      </Row>
    </>
    ):(
      <Row className='p-3' id={ currentPath === '/admin/society/detail' ? '' : 'projects_item'  }>
      <>
        <Col  xs="12" sm="12" lg="3" xl="2" className='d-flex align-items-center justify-content-md-start justify-content-center' >

          <div className="media">

            {/*
              <Avatar maxInitials="2" round="12px" fgColor="black" size="45" name={props.name} className="mr-3"/>
            */}

            <div className="media-body mb-0">
              <h6>{props.name}</h6>
              {!readOnly &&
                <h6 className="text-muted small mb-0">{props.admin} Administradores</h6>
              }
            </div>
          </div>
        </Col>

        <Col  xs="12" sm="12" lg="3" xl="2" className=''>
          {!readOnly && (
            <Badge className='text-wrap' style={{ fontSize:'15px' }} pill>{props.project}</Badge>
          )}
        </Col>


        <Col  xs="12" sm="12" lg="3" xl="2" className="d-flex ">
          {!readOnly && (
            <>
              <div className="d-flex align-items-center justify-content-beetwen border-0 rounded mr-2 bg-light">
                <h6 className="mb-0 small p-2"><img width='20' src={sociedad} /></h6>
                <h6 className="mb-0 small p-2">{props.user}</h6>
              </div>
              <div className="d-flex align-items-center justify-content-beetwen border-0 rounded bg-light">
                <h6 className="mb-0 small p-2"><img width='30' src={user} /></h6>
                <h6 className="mb-0 small p-2">{props.society}</h6>
              </div>
            </>
          )}
             
        </Col>

        
   
        <Col xs="12" sm="12" lg="3" xl="2" className="">
          <div className="d-flex align-items-center">

            <Dropdown className='project-item'>
              {!readOnly && (
                <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" className='m-2'  size="sm">
                  <Edit size="12" />
                </Dropdown.Toggle>
              )}
              {!readOnly && (
                <Dropdown.Menu className="shadow-sm border-0">
                  <Dropdown.Item onClick={() => history.push({ pathname: `/admin/group/edit/${props.uuid}` })}>Editar</Dropdown.Item>
                  <GroupDelete uuid={props.uuid} setFlagDelete={props.setFlagAction} />
                </Dropdown.Menu>
              )}

              <Button variant="outline-dark" size="sm" className='m-1' onClick={() => history.push({ pathname: `/admin/group/detail/${props.uuid}` })}>
                Ver Detalle
                <List size="12" className='ml-2 mb-0' />
              </Button>
            </Dropdown>
          </div>
        </Col>


      </>
    </Row>
    )
   
  )
}

export default GroupItem;
