import React, { useEffect, useState } from 'react';
import { Row, Breadcrumb, Col, Button, Form, Spinner } from 'react-bootstrap';
import { Link, Prompt } from 'react-router-dom';
import { CheckCircle } from 'react-feather'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FileUpload from '../file_upload';
import { POST_CSV } from '../../store/user/types';

const ImportUsers = ({ postCsv, loadingCsv, errors }) => {
  const [step, setStep] = React.useState(0);
  const [triggerForm, setTriggerForm] = useState(false);
  const [promptAux, setPromptAux] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [flagFile, setFlagFile] = useState(false);
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [serviceError, setServiceError] = useState(null);

  useEffect(() => {
    window.onbeforeunload = (event) => {
      if(triggerForm === true){
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
      }
    };
  },);

  const sendData = () => {
    setPromptAux(false);
    setTriggerForm(false);
    setServiceError(null);
    setLoading(true);
    const fd = new FormData();
    fd.append('file_csv', file );
    postCsv(fd);
    setServiceError(errors);
    setFlag(true);
  }

  useEffect(() => {
    setServiceError(null);
  }, [errors]);

  useEffect(() => {
    if(flag){
      if (!loadingCsv && errors !== null) {
        setServiceError(errors);
        setLoading(false);
      }
      if (loadingCsv === false && errors === null) {
        setStep(step + 1);
        setFlag(false);
      }
    }
  }, [loadingCsv, errors, flag, step]);

  const alert = () => {
    if (serviceError) {
      return (
        serviceError.map((error) => {
          return (<div key={error}>
            {error !== undefined ? (
              `-${error}`
            ) : (
              ''
            )}
          </div>);
        })
      );
    }
  };

  useEffect(() => {
    if (
      !flagFile
    ) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [flagFile]);

  return (
    <Form>
      <Row className="mb-4">
        <Prompt
          when={triggerForm && promptAux}
          message={`¿Estás seguro de desear abandonar este formulario? Los datos ingresados no serán guardados.`}
        />
        <Col>
          <Breadcrumb className="small">
            <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin/group">
              Administración usuarios
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Importar usuarios</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {step === 0 && (
        <Row className="mb-4">
          <Col xl="5" lg="6" className="mx-auto">
            <h4 className="font-weight-bold mb-4">Importación de usuarios</h4>
            <Form.Group controlId="image">
              <Form.Label className="font-weight-bold small">Subir archivo CSV</Form.Label>
              <FileUpload
                setFile={setFile}
                file={file}
                setFlag={setFlagFile}
              />
            </Form.Group>
            {
              serviceError ? (
                <div className="alert alert-danger my-3" role="alert">
                  {alert()}
                </div>
              ) : null
            }
            <Button
              className="mt-2"
              onClick={sendData}
              disabled={disableSubmit || loading || loadingCsv}
              size="lg"
              block
            >
              {
                loading || loadingCsv ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  ''
                )
              }{' '}
              Crear usuarios desde archivo
            </Button>
          </Col>
        </Row>
      )}
      {step === 1 && (
        <Row>
          <Col xs="12" className="text-center">
            <CheckCircle size="48" className="text-success mb-4"/>
            <h5 className="font-weight-bold mb-4">Usuarios creados con éxito</h5>
            <Button className="mx-2" as={Link} to="/admin/users">Ir a usuarios</Button>
          </Col>
        </Row>
      )}
    </Form>
  )
}

ImportUsers.propTypes = {
  postCsv: PropTypes.func.isRequired,
};

const stateToProps = state => ({
  errors: state.users.errors,
  loadingCsv: state.users.loadingCsv
});

const actionsToProps = dispatch => ({
  postCsv: payload => dispatch({ type: POST_CSV, payload })
});

export default connect(stateToProps, actionsToProps)(ImportUsers);
