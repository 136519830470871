import { Cookies } from 'react-cookie';
import moment from 'moment';

const cookies = new Cookies();

const cookieHandler = () => ({
  setTokens: payload => {
    const expires = moment().add(1, 'y').toDate();
    cookies.set('accessToken', payload.access, { path: '/', sameSite: 'lax', expires, domain: `.${process.env.REACT_APP_DOMAIN}` });
    cookies.set('refreshToken', payload.refresh, { path: '/', sameSite: 'lax', expires, domain: `.${process.env.REACT_APP_DOMAIN}` });
  },
  setColor: payload => {
    cookies.set('primaryColor', payload.substring(1), { path: '/', sameSite: true });
  },
  getAccessToken: () => cookies.get('accessToken'),
  getRefreshToken: () => cookies.get('refreshToken'),
  getPrimaryColor: () => cookies.get('primaryColor'),
  removeToken: () => {
    cookies.remove('accessToken', { path: '/', domain: `.${process.env.REACT_APP_DOMAIN}` });
    cookies.remove('refreshToken', { path: '/', domain: `.${process.env.REACT_APP_DOMAIN}` });
  },
  removeColor: () => {
    cookies.remove('primaryColor', { path: '/' });
  }
});

export default cookieHandler;
