import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Badge, Dropdown } from 'react-bootstrap';
import Avatar from 'react-avatar'
import { MoreVertical, ArrowRight } from 'react-feather';
import { useHistory } from 'react-router-dom';
import UserDelete from './userDelete';
import { Edit, List } from 'react-feather'
//import PropTypes from 'prop-types';
//import { PATCH_USER } from '../../store/user/types';
//import { connect } from 'react-redux';

const UsersItem = props => {
  const [variant, setVariant] = useState('');
  const [nameUser, setNameUser] = useState('');
  const { readOnly } = props;
  const uuid = props.uuid;
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState('');
  const userForm = { enable: !props.enable };

  useEffect(() => {
    const windowLocation = window.location.pathname
    const rutaInicial = windowLocation.split('/').slice(0, 4).join('/')
    setCurrentPath(rutaInicial)
  }, [])


  const handleDisable = () => {
    props.patchUser({ userForm, uuid });
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  useEffect(() => {
    const variants = [
      'warning',
      'info',
      'danger',
      'success',
      'dark'
    ]
    switch (props.role) {
      case 'USER_GROUP':
        setNameUser('Usuario grupo');
        setVariant(variants[0]);
        break;
      case 'PROJECT_ADMIN':
        setNameUser('Admin de proyecto');
        setVariant(variants[1]);
        break;
      case 'GROUP_ADMIN':
        setNameUser('Admin de grupo');
        setVariant(variants[2]);
        break;
      case 'SUPER_ADMIN':
        setNameUser('Superadmin');
        setVariant(variants[3]);
        break;
      case 'USER_SOCIETY':
        setNameUser('Usuario sociedad');
        setVariant(variants[4]);
        break;
      default:
        break;
    }
  }, [props.role]);

  return (
    <>
      <Row className='p-3 project-item' id='projects_item' >
        {!readOnly ? (
          <>

            <Col xs="12" sm="12" lg="3" xl="2">
              <div className="d-flex">

                <div className="media-body">
                  <div className='d-flex align-items-center justify-content-between justify-content-sm-start mb-2'>
                    <h6 className="font-weight-bold mb-0 mr-2">{props.name}</h6>
                    {/*
                      props.enable && readOnly && (
                        <Badge variant="warning">Desabilitado</Badge>
                      )
                      */}
                  </div>
                  <h6 className="text-pink small mb-0">{props.email}</h6>
                </div>
              </div>
            </Col>

            <Col xs="12" sm="12" lg="3" xl="2" style={{ fontSize: '100%', fontWeight: 'bold' }}>
              <span>{nameUser}</span>
            </Col>

            <Col xs="12" sm="12" lg="3" xl="2">
              {
                !props.enable ? (
                  <h6><span className='desabilitadoStatus'></span>Deshabilitado</h6>
                ) : (
                  <h6><span className='habilitadoStatus'></span>Habilitado</h6>
                )
              }
            </Col>

            <Col xs="12" sm="12" lg="3" xl="2">
              <div className="d-flex align-items-center">
                <Dropdown>
                  <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" size="sm">
                    <Edit size="12" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="shadow-sm border-0 p-2">
                    {
                      props.enable ? (
                        <Dropdown.Item
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Deshabilitar usuario"
                          onClick={handleDisable}
                        >
                          Deshabilitar
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Habilitar usuario"
                          onClick={handleDisable}
                        >
                          Habilitar
                        </Dropdown.Item>
                      )
                    }
                    <Dropdown.Item
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Editar usuario"
                      onClick={() => history.push({ pathname: `/admin/users/edit/${props.uuid}` })}
                    >
                      Editar
                    </Dropdown.Item>
                    <UserDelete uuid={props.uuid} setFlagDelete={props.setFlagAction} />
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                  variant="outline-dark"
                  size="sm"
                  className='ml-0 ml-sm-2'
                  onClick={() => history.push({ pathname: `/admin/users/detail/${props.uuid}` })}
                >
                  Ver Detalle
                  <List size="12" className='ml-2 mb-0' />

                </Button>
              </div>
            </Col>

          </>
        ) : (
          <>
            <Col>
              <div className="media">
                {
                  /*
                      <Avatar maxInitials="2" round="8px" fgColor="black" size="48" name={props.name} className="mr-3" />
                  */
                }
                <div className="media-body">
                  <h6 className="font-weight-bold">{props.name}</h6>
                  <h6 className="text-pink small mb-0">{props.rut}</h6>
                  {
                    props.society && (
                      <h6 className="text-muted">{props.society.society_business_name}</h6>
                    )
                  }
                </div>
              </div>
            </Col>


            {
              currentPath === '/admin/group/detail' || currentPath === '/admin/society/detail' ? (
                <Col xs="12" sm="12" lg="3" xl="2" className='d-flex justify-content-center'>

                  {
                    <h6></h6>
                  }

                </Col>
              ) : (
                <Col xs="12" sm="12" lg="3" xl="2">
                  {
                    !props.enable ? (
                      <h6><span className='desabilitadoStatus'></span>Deshabilitado</h6>
                    ) : (
                      <h6><span className='habilitadoStatus desabilitadoStatus'></span>Habilitado</h6>
                    )
                  }
                </Col>
              )
            }







            <Col className="d-flex align-items-center justify-content-end ml-auto">
              <div className="d-flex align-items-center">
                <Button
                  variant="outline-dark"
                  size="sm"
                  onClick={() => history.push({ pathname: `/admin/users/detail/${props.uuid}` })}
                >
                  Ver Detalle
                  <List size="12" className='ml-2 mb-0' />
                </Button>
              </div>
            </Col>
          </>
        )
        }
      </Row>

    </>
  )
}


/*UsersItem.propTypes = {
  patchUser: PropTypes.func.isRequired
};

const actionsToProps = dispatch => ({
  patchUser: payload => dispatch({ type: PATCH_USER, payload })
});*/

export default /*connect(null, actionsToProps)*/UsersItem;
