import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Button, Col, Form, FormControl, Image, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import validator from '../../utils/validator';
import { loginSchema } from '../../utils/validator/schemas';
import { FETCH_COLOR, POST_LOGIN_PASSWORD } from '../../store/types';
import BgLogin from '../../images/login-bg.png';
import logo from '../../images/logo-color.svg';

const LoginUserPassword = ({ postLogin, loginErrors, postLoadLogin, siteData, loadingData, isLoaded }) => {
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });
  const [validationError, setValidationError] = useState({ email: '', password: '' });

  useEffect(() => {
    postLoadLogin({ custom_portal_url: window.location.href })
  }, [postLoadLogin]);

  const handleSubmit = event => {
    event.preventDefault();
    postLogin(loginForm);
  };

  const handleInputChange = ({ target }) => {
    const formData = { ...loginForm, [target.name]: target.value };
    setLoginForm(formData);
    setValidationError({ ...validationError, [target.name]: '' });

    const errors = validator({ [target.name]: loginSchema[target.name] }, formData);
    if (errors.length > 0) {
      const messages = errors
        .map(e => ({...validationError, [e.field]: e.message }))
        .reduce((prev, current) => ({ ...prev, ...current }), {});
      setValidationError(messages);
      return false;
    }
  };

  return (
    <div className="d-flex">
      {
        loadingData && !isLoaded ? (
          <Col xs={12}>
            <div className="spinner-container text-center my-5">
              <Spinner animation="border" className="spinner-custom"/>
            </div>
          </Col>
        ):(
          <>
            <Container fluid className="login">
              <Row>
                <Col xs="12" lg="6" className="p-0">
                  <div className='login__content'>
                    <Form noValidate onSubmit={handleSubmit} className="mb-4">
                          <Col xs="12" md="8" className='mx-auto'>
                          <h2 className="mb-5">
                            {
                              siteData ? (
                                <Col xs={4} className="p-0">
                                  <Image src={`${process.env.REACT_APP_SERVICE_URL}${siteData.logo}`} fluid alt="logo"/>
                                </Col>
                              ):(
                                <Col xs={4} className="p-0">
                                  <Image src={logo} fluid alt="logo"/>
                                </Col>
                              )
                            }
                          </h2>
                          {
                            siteData && (
                              <h4 className="font-weight-bold mb-4">{siteData.meta_title}</h4>
                            )
                          }
                          {loginErrors ? (
                            <div className="alert alert-danger my-1 p-2" role="alert">
                              {loginErrors}
                            </div>
                          ) : null
                          }
                          <h6 className="my-3 font-weight-bold">
                            Ingresa a tu cuenta
                          </h6>
                            <Form.Group controlId="loginUsername">
                              <Form.Label className="font-weight-bold small">Correo electrónico</Form.Label>
                              <FormControl
                                name="email"
                                type="email"
                                placeholder="Ingresa tu correo electrónico"
                                autoComplete="email"
                                onChange={handleInputChange}
                                isInvalid={validationError.email}
                                size="lg"
                              />
                              <Form.Control.Feedback type="invalid">
                                {validationError.email}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="loginPassword" className="mb-5 position-relative">
                              <Form.Label className="font-weight-bold small">Contraseña</Form.Label>
                              <FormControl
                                name="password"
                                type={'password'}
                                placeholder="Ingresa tu contraseña"
                                autoComplete="current-password"
                                onChange={handleInputChange}
                                isInvalid={validationError.password}
                                size="lg"
                                className="mb-2"
                              />
                              <a href={'/forgottenPassword'} className="text-primary small font-weight-bold float-right">¿Has olvidado tu contraseña?</a>
                              <Form.Control.Feedback type="invalid">
                                {validationError.password}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Button
                              className="btn-block font-weight-bold"
                              variant="primary"
                              type="submit"
                              size="lg"
                            >
                              {'Iniciar sesión'}
                            </Button>
                          </Col>


                    </Form>
                    <Row>
                      <Col xs="12" className="d-flex justify-content-center mb-4">
                        <h6 className="text-muted small mb-0 mr-5">contacto@magnus.cl</h6>
                        <div className="separator-line"/>
                        <h6 className="text-muted small mb-0">+569 3948 4847</h6>
                      </Col>
                      <Col xs="12" className="text-center">
                        <h6 className="text-muted small">© 2021 Magnus</h6>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs="12" lg="6" className="p-0 d-none d-lg-block">
                  <div className="login__bg">
                  {
                    siteData ? (
                      <img src={`${process.env.REACT_APP_SERVICE_URL}${siteData.image}`} alt="" className="img-login"/>
                    ):(
                      <img src={BgLogin} alt="" className="img-login"/>
                    )
                  }

                    <Image src={logo} fluid alt="logo" className='login__bg--logo'/>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        )
      }
    </div>
  );
};

LoginUserPassword.propTypes = {
  postLogin: PropTypes.func.isRequired,
  loginErrors: PropTypes.string,
  postLoadLogin: PropTypes.func.isRequired
};

const stateToProps = state => ({
  loginErrors: state.auth.errors,
  siteData: state.auth.siteData,
  loadingData: state.auth.loadingData,
  isLoaded: state.auth.isLoaded
});

const actionsToProps = dispatch => ({
  postLogin: payload => dispatch({ type: POST_LOGIN_PASSWORD, payload }),
  postLoadLogin: payload => dispatch({ type: FETCH_COLOR, payload })
});

export default connect(stateToProps, actionsToProps)(LoginUserPassword);
