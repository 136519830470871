import React, { useEffect, useState } from 'react';
import { Breadcrumb, Card, Col, Dropdown, DropdownButton, Form, Row, Spinner, Table } from 'react-bootstrap';
import { ConfigProvider } from 'react-avatar';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import AccountingModalOne from './accountingModalOne';
import AccountingModalTwo from './accountingModalTwo';

import { Maximize2, Minimize2 } from 'react-feather';

import {
  FETCH_BALANCE_8_COLUMNS,
  FETCH_CASH_FLOW,
  FETCH_DATES_BALANCE_8_COLUMNS,
  FETCH_DATES_CASH_FLOW,
  FETCH_DATES_FINANCIAL_SITUATION,
  FETCH_DATES_STATUS_RESULTS, FETCH_EXCEL,
  FETCH_FINANCIAL_SITUATION,
  FETCH_PDF_BALANCE_8_COLUMNS, FETCH_STATUS_RESULTS
} from '../../store/accounting/types';
import PropTypes from 'prop-types';
import { FETCH_SOCIETY_USER_LIST } from '../../store/society/types';
import { XCircle } from 'react-feather';
import AccountingModalFour from './accountingModalFour';
//import { locale } from 'moment';

const AccountingUser = (
  {
    showBalance8Columns,
    balance8ColumnsData,
    showSocietyForUser,
    societyUserList,
    showBalance8ColumnsDates,
    balance8ColumnsDates,
    isLoadedAccounting,
    showAccountingPDF,
    showCashFlowDates,
    cashFlowDates,
    showCashFlow,
    cashFlowData,
    showFinancialSituation,
    showFinancialSituationDates,
    financialSituationDates,
    financialSituationData,
    showStatusResultDates,
    showStatusResult,
    statusResultsDates,
    statusResultsData,
    dataExist,
    showAccountingExcel,
    userProfile,
  }) => {
  const [showHeaderReport, setShowHeaderReport] = useState(true);
  const { type, uuid } = useParams();
  const [society, setSociety] = useState(null);
  const [date, setDate] = useState(null);
  const [dateComparison, setDateComparison] = useState({
    start: '',
    end: ''
  });

  const currentPath = window.location.pathname;
  console.log(currentPath)


  const [money, setMoney] = useState('LOCAL')
  function changeMoney(e) {
    setMoney(e.target.value)
  }

  const [typeFinancialSituation, setTypeFinancialSituation] = useState('SINGLE');
  const [rule, setRule] = useState("IFRS");
  const [flagDate, setFlagDate] = useState(false);

  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === "PROJECT_ADMIN")
        showSocietyForUser({ project: localStorage.project });
      if (userProfile.profile.user_role === "GROUP_ADMIN" || userProfile.profile.user_role === "USER_GROUP")
        showSocietyForUser({ group: localStorage.group });
      if (userProfile.profile.user_role === "USER_SOCIETY")
        showSocietyForUser({ society: localStorage.society });
    }

  }, [showSocietyForUser, uuid, userProfile]);

  useEffect(() => {
    if (societyUserList.length > 0) {
      if (!society) {
        if (localStorage.society) {
          setSociety(localStorage.society);
          localStorage.setItem('auxSociety', localStorage.society);
        } else {
          if (localStorage.auxSociety) {
            setSociety(localStorage.auxSociety);
          } else {
            setSociety(societyUserList[0].uuid);
            localStorage.setItem('auxSociety', societyUserList[0].uuid);
          }
        }
      } else {
        if (type === "1")
          showBalance8ColumnsDates(`${uuid}/?society=${society}&rule=${rule}&money_type=${money}`);
        if (type === "2")
          showFinancialSituationDates(`${uuid}/?money_type=${money}&society=${society}`);
        if (type === "3")
          showStatusResultDates(`${uuid}/?society=${society}&money_type=${money}`);
        if (type === "4")
          showCashFlowDates(`${uuid}/?society=${society}&money_type=${money}`);
      }
    }// eslint-disable-next-line
  }, [showBalance8ColumnsDates, rule, showFinancialSituationDates, showCashFlowDates, uuid, societyUserList, society, type]);

  useEffect(() => {
    if (societyUserList.length > 0) {
      if (date && flagDate) {

        if (type === "1" && balance8ColumnsDates.length > 0) {
          showBalance8Columns(`${uuid}/?society=${society}${date}&rule=${rule}&money_type=${money}`);
        }
        if (type === "2" && financialSituationDates.length > 0) {
          showFinancialSituation(`${uuid}/?society=${society}${date}&rule=${rule}&report_type=${typeFinancialSituation}&money_type=${money}`);
        }
        if (type === "3" && statusResultsDates.length > 0) {
          showStatusResult(`${uuid}/?society=${society}${date}&rule=${rule}&report_type=${typeFinancialSituation}&money_type=${money}`);
        }
        if (type === "4" && cashFlowDates.length > 0) {
          showCashFlow(`${uuid}/?society=${society}${date}&money_type=${money}`);
        }

      }
    }// eslint-disable-next-line
  }, [showBalance8Columns, money, flagDate, rule, typeFinancialSituation, showFinancialSituation, financialSituationDates, cashFlowDates, showCashFlow, societyUserList, uuid, balance8ColumnsDates, society, date, type]);

  useEffect(() => {
    if (societyUserList.length > 0) {
      if (type === "1" && balance8ColumnsDates.length > 0)
        setDate(`&year=${balance8ColumnsDates[balance8ColumnsDates.length - 1].year}&month=${balance8ColumnsDates[balance8ColumnsDates.length - 1].month}`);
      if (type === "2" && financialSituationDates.length > 0 && !date) {
        setDate(`&start_year=${financialSituationDates[financialSituationDates.length - 1].year}&end_month=${financialSituationDates[financialSituationDates.length - 1].month}&end_year=${financialSituationDates[financialSituationDates.length - 1].year - 1}&start_month=${financialSituationDates[financialSituationDates.length - 1].month}`);
        setDateComparison({
          ...dateComparison,
          start: `&start_year=${financialSituationDates[financialSituationDates.length - 1].year}&start_month=${financialSituationDates[financialSituationDates.length - 1].month}`,
          end: `&end_year=${financialSituationDates[financialSituationDates.length - 1].year - 1}&end_month=${financialSituationDates[financialSituationDates.length - 1].month}`
        });
        setTypeFinancialSituation('COMPARE');
      }
      if (type === "3" && statusResultsDates.length > 0 && !date) {
        setDate(`&start_year=${statusResultsDates[statusResultsDates.length - 1].year}&end_month=${statusResultsDates[statusResultsDates.length - 1].month}&end_year=${statusResultsDates[statusResultsDates.length - 1].year - 1}&start_month=${statusResultsDates[statusResultsDates.length - 1].month}`);
        setDateComparison({
          ...dateComparison,
          start: `&start_year=${statusResultsDates[statusResultsDates.length - 1].year}&start_month=${statusResultsDates[statusResultsDates.length - 1].month}`,
          end: `&end_year=${statusResultsDates[statusResultsDates.length - 1].year - 1}&end_month=${statusResultsDates[statusResultsDates.length - 1].month}`
        });
        setTypeFinancialSituation('COMPARE');
      }
      if (type === "4" && cashFlowDates.length > 0)
        setDate(`&year=${cashFlowDates[cashFlowDates.length - 1].year}`);
      setFlagDate(true);
    } else {
      setDate(null);
      setDateComparison({ start: '', end: '' });
    }// eslint-disable-next-line
  }, [societyUserList, financialSituationDates, cashFlowDates, setFlagDate, type, balance8ColumnsDates, statusResultsDates]);

  useEffect(() => {
    setFlagDate(false);
  }, [type, uuid]);


  function handleClosedReportToggle() {
    setShowHeaderReport(!showHeaderReport);
    console.log('funciona')
  }


  const handlePDF = () => {
    if (type === '1' || type === '4')
      showAccountingPDF({ data: `${uuid}/?society=${society}${date}&rule=${rule}&money_type=${money}&money_type=${money}`, type });
    if (type === '2' || type === '3')
      showAccountingPDF({ data: `${uuid}/?society=${society}${date}&rule=${rule}&report_type=${typeFinancialSituation}&money_type=${money}`, type });
  }

  const handleExcel = () => {
    if (type === '1' || type === '4')
      showAccountingExcel({ data: `${uuid}/?society=${society}${date}&rule=${rule}&money_type=${money}`, type });
    if (type === '2' || type === '3')
      showAccountingExcel({ data: `${uuid}/?society=${society}${date}&rule=${rule}&report_type=${typeFinancialSituation}&money_type=${money}`, type });
  }

  const putTittle = (variable) => {
    let flag = true;
    for (let i = 0; i < financialSituationData.table.passive_and_heritage.length; i++) {
      if (flag && financialSituationData.table.passive_and_heritage[i].nivel2 === variable) {
        flag = false;
        return (
          <>
       
            <tr>
              {
                variable === "PATRIMONIO" && (
                  <td colSpan={5} id='title-pdf-report' className="font-weight-bold text-capitalize">Patrimonio</td>
                )
              }
              {
                variable === "PASIVOS NO CORRIENTES" && (
                  <td colSpan={5} id='title-pdf-report' className="font-weight-bold text-capitalize">Pasivos no contables</td>
                )
              }
            </tr>
          </>
        )
      }
    }
  }

  const handleSelections = ({ target }) => {
    if (target.name === 'date') {
      setDate(target.value);
    }
    if (target.name === 'society') {
      setSociety(target.value);
      localStorage.setItem('auxSociety', target.value);
    }
    if (target.name === 'start' || target.name === 'end') {
      if (target.name === 'start' && dateComparison.end === '') {
        setDate(target.value);
        setDateComparison({ ...dateComparison, start: target.value });
      }
      if (target.name === 'start' && dateComparison.end) {
        setDate(`${target.value}${dateComparison.end}`);
        setDateComparison({ ...dateComparison, start: target.value });
      }
      if (target.name === 'end' && target.value !== '') {
        setDate(`${dateComparison.start}${target.value}`);
        setTypeFinancialSituation('COMPARE');
        setDateComparison({ ...dateComparison, end: target.value });
      }
      if (target.name === 'end' && target.value === '') {
        setDate(`${dateComparison.start}`);
        setTypeFinancialSituation('SINGLE');
        setDateComparison({ ...dateComparison, end: target.value });
      }
    }
  };

  let previousTitle = '';

  return (
    <ConfigProvider colors={['#F2BBAD', '#E3F2FD', '#2FB099', '#FEF4D5']}>
      <>
        <div>
          <span id='closed_header_reports'>{showHeaderReport ? <Maximize2 onClick={handleClosedReportToggle} /> : <Minimize2 onClick={handleClosedReportToggle} />}</span>
        </div>

        {showHeaderReport && (
          <Card className="border-0 shadow-sm" id='header_report' style={{ backgroundColor: '#009ada14', marginTop: '1.5%' }}>
            <Card.Body id='card_reports'>
              {
                type === '1' && (
                  <>
                    <Row>
                      <Col>
                        <Breadcrumb className="small">
                          <Breadcrumb.Item href="#">Inicio</Breadcrumb.Item>
                          <Breadcrumb.Item active>Balance 8 columnas</Breadcrumb.Item>
                        </Breadcrumb>
                      </Col>
                    </Row>
                    <Row className='d-flex justify-content-center'>
                      <Col sm="5" lg='3'>
                        <Row className="d-flex align-items-center justify-content-center">
                          <Col className="mt-3 col-lg-12 col-12">
                            <Form.Group controlId="date text-align-center">
                              <h6 className="mr-3 small font-weight-bold">Fecha de balance:</h6>
                              <Form.Control
                                className="text-muted"
                                size="md"
                                as="select"
                                name="date"
                                type="date"
                                onChange={handleSelections}
                              >
                                mt-3 col-lg-8 col-12
                                {
                                  balance8ColumnsDates.slice(0).reverse().map((date, n) => {
                                    return (
                                      <option
                                        key={n}
                                        className="secondary-text-color"
                                        value={`&year=${date.year}&month=${date.month}`}
                                      >
                                        {date.month}/{date.year}
                                      </option>
                                    );
                                  })
                                }
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>

                      <Col sm="5" lg={userProfile.profile && (userProfile.profile.user_role !== "USER_SOCIETY" ? (3) : (3))}>
                        <Row className="d-flex align-items-center justify-content-center">
                          <Col className="mt-3 col-lg-12 col-12">
                            <Form.Group controlId="rule">
                              <h6 className="mr-3 small font-weight-bold">Norma:</h6>
                              <Form.Control
                                className="text-muted bg-light"
                                size="md"
                                as="select"
                                name="rule"
                                type="rule"
                                onChange={({ target }) => {
                                  setRule(target.value);
                                }}
                              >
                                <option value={"IFRS"} className="secondary-text-color" selected>IFRS</option>
                                <option value={"TRBT"} className="secondary-text-color">Tributaria</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>

                      <Col sm="5" lg={userProfile.profile && (userProfile.profile.user_role !== "USER_SOCIETY" ? (3) : (3))}>
                        <Row className="d-flex align-items-center justify-content-center">
                          <Col className="mt-3 col-lg-12 col-12">
                            <Form.Group controlId="rule">
                              <h6 className="mr-3 small font-weight-bold">Moneda:</h6>
                              <Form.Control
                                className="text-muted bg-light"
                                size="md"
                                as="select"
                                name="moneyType"
                                type="monery"
                                onChange={changeMoney}
                              >
                                <option value="LOCAL" className="secondary-text-color" selected>LOCAL</option>
                                <option value="USD" className="secondary-text-color">USD</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      {
                        userProfile.profile && (
                          userProfile.profile.user_role !== "USER_SOCIETY" && (
                            <Col sm="5" lg="3">
                              <Row className="d-flex align-items-center justify-content-center">
                                <Col className="mt-3 col-lg-12 col-12">
                                  <Form.Group controlId="society">
                                    <h6 className="mr-3 small font-weight-bold">Sociedad:</h6>
                                    <Form.Control
                                      className="text-muted bg-light"
                                      size="md"
                                      as="select"
                                      name="society"
                                      type="society"
                                      onChange={handleSelections}
                                    >
                                      {
                                        societyUserList.map((societyObj) => {
                                          return (
                                            societyObj.uuid === society ? (
                                              <option value={societyObj.uuid} className="secondary-text-color" selected>{societyObj.society_business_name}</option>
                                            ) : (
                                              <option value={societyObj.uuid} className="secondary-text-color">{societyObj.society_business_name}</option>)
                                          );
                                        })
                                      }
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                          )
                        )
                      }
                    </Row>
                    {/*<Col sm={12} className="pt-3 custom-filter">
                    <Col sm="12" lg="8" className="p-0">
                      <Row>
                        <Col xs="12" md="auto" className='d-flex align-items-center'>
                          <h6 className="mr-3 small font-weight-bold">Fecha de balance:</h6>
                          <Form.Group controlId="date">
                            <Form.Control
                              className="text-muted"
                              size="md"
                              as="select"
                              name="date"
                              type="date"
                              onChange={handleSelections}
                            >
                              {
                                balance8ColumnsDates.slice(0).reverse().map((date, n)=>{
                                  return(
                                    <option
                                      key={n}
                                      className="secondary-text-color"
                                      value={`&year=${date.year}&month=${date.month}`}
                                    >
                                      {date.month}/{date.year}
                                    </option>
                                  );
                                })
                              }
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                            </Col>*/}
                  </>
                )
              }
              {
                type === '2' && (
                  <>
                    <Row>
                      <Col className='p-0'>
                        <Breadcrumb className="small">
                          <Breadcrumb.Item href="#">Inicio</Breadcrumb.Item>
                          <Breadcrumb.Item active>Estado de situación financiera</Breadcrumb.Item>
                        </Breadcrumb>
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-center'>
                      <Col sm="12" lg="0" className="d-flex align-items-center">
                        {/*<h4 className="font-weight-bold mb-1">Estado de situación financiera</h4>*/}
                        {
                          financialSituationDates.length !== 0 && (
                            dateComparison.start === `&start_year=${financialSituationDates[financialSituationDates.length - 1].year}&start_month=${financialSituationDates[financialSituationDates.length - 1].month}` && (
                              <p className="text-primary ml-2 mb-0"></p>
                            )
                          )
                        }
                      </Col>

                      <Col sm="5" lg='2'>
                        <Row className="d-flex align-items-center justify-content-center">
                          <Col className="mt-3 col-lg-12 col-12">
                            <Form.Group controlId="start">
                              <h6 className="mr-3 small font-weight-bold">Fecha de balance:</h6>
                              <Form.Control
                                className="text-muted"
                                size="md"
                                as="select"
                                name="start"
                                type="start"
                                onChange={handleSelections}
                              >
                                {
                                  financialSituationDates.slice(0).reverse().map((date, n) => {
                                    return (
                                      dateComparison.end === `&end_year=${date.year}&end_month=${date.month}` ? (
                                        <option
                                          key={n}
                                          className="secondary-text-color"
                                          style={{ backgroundColor: "#e3e3e3" }}
                                          disabled
                                        >
                                          {date.month}/{date.year}
                                        </option>
                                      ) : (
                                        <option
                                          key={n}
                                          className="secondary-text-color"
                                          value={`&start_year=${date.year}&start_month=${date.month}`}
                                        >
                                          {date.month}/{date.year}
                                        </option>
                                      )
                                    );
                                  })
                                }
                              </Form.Control>
                            </Form.Group>

                          </Col>
                        </Row>
                      </Col>

                      <Col sm="5" lg='2'>
                        <Row className="d-flex align-items-center justify-content-center">
                          <Col className="mt-3 col-lg-12 col-12">

                            <Form.Group controlId="end">
                              <h6 className="small font-weight-bold mr-2">Comparar con:</h6>
                              <Form.Control
                                className="text-muted"
                                size="md"
                                as="select"
                                name="end"
                                type="end"
                                onChange={handleSelections}
                              >
                                <option value="" className="secondary-text-color"> - </option>
                                {
                                  financialSituationDates.slice(0).reverse().map((date, n) => {
                                    return (
                                      dateComparison.start === `&start_year=${date.year}&start_month=${date.month}` ? (
                                        <option
                                          key={n}
                                          className="secondary-text-color"
                                          style={{ backgroundColor: "#e3e3e3" }}
                                          disabled
                                        >
                                          {date.month}/{date.year}
                                        </option>
                                      ) : (
                                        dateComparison.end === `&end_year=${date.year}&end_month=${date.month}` ? (
                                          <option
                                            key={n}
                                            className="secondary-text-color"
                                            value={`&end_year=${date.year}&end_month=${date.month}`}
                                            selected
                                          >
                                            {date.month}/{date.year}
                                          </option>
                                        ) : (
                                          <option
                                            key={n}
                                            className="secondary-text-color"
                                            value={`&end_year=${date.year}&end_month=${date.month}`}
                                          >
                                            {date.month}/{date.year}
                                          </option>
                                        )
                                      )
                                    )
                                  })
                                }
                              </Form.Control>
                            </Form.Group>




                          </Col>
                        </Row>
                      </Col>

                      <Col sm="5" lg={userProfile.profile && (userProfile.profile.user_role !== "USER_SOCIETY" ? (2) : (2))}>
                        <Row className="d-flex align-items-center justify-content-center">
                          <Col className="mt-3 col-lg-12 col-12">
                            <Form.Group controlId="rule">
                              <h6 className="mr-3 small font-weight-bold">Norma:</h6>
                              <Form.Control
                                className="text-muted bg-light"
                                size="md"
                                as="select"
                                name="rule"
                                type="rule"
                                onChange={({ target }) => {
                                  setRule(target.value);
                                }}
                              >
                                <option value={"IFRS"} className="secondary-text-color" selected>IFRS</option>
                                <option value={"TRBT"} className="secondary-text-color">Tributaria</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="5" lg={userProfile.profile && (userProfile.profile.user_role !== "USER_SOCIETY" ? (2) : (2))}>
                        <Row className="d-flex align-items-center justify-content-center">
                          <Col className="mt-3 col-lg-12 col-12">
                            <Form.Group controlId="rule">
                              <h6 className="mr-3 small font-weight-bold">Moneda:</h6>
                              <Form.Control
                                className="text-muted bg-light"
                                size="md"
                                as="select"
                                name="moneyType"
                                type="monery"
                                onChange={changeMoney}
                              >
                                <option value="LOCAL" className="secondary-text-color" selected>LOCAL</option>
                                <option value="USD" className="secondary-text-color">USD</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>

                      {
                        userProfile.profile && (
                          userProfile.profile.user_role !== "USER_SOCIETY" && (
                            <Col sm="5" lg="2">
                              <Row className="d-flex align-items-center justify-content-end">
                                <Col className="mt-3 col-lg-12 col-12">
                                  <Form.Group controlId="society">
                                    <h6 className="mr-3 small font-weight-bold">Sociedad:</h6>
                                    <Form.Control
                                      className="text-muted bg-light"
                                      size="md"
                                      as="select"
                                      name="society"
                                      type="society"
                                      onChange={handleSelections}
                                    >
                                      {
                                        societyUserList.map((societyObj) => {
                                          return (
                                            societyObj.uuid === society ? (
                                              <option value={societyObj.uuid} className="secondary-text-color" selected>{societyObj.society_business_name}</option>
                                            ) : (
                                              <option value={societyObj.uuid} className="secondary-text-color">{societyObj.society_business_name}</option>)
                                          );
                                        })
                                      }
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                          )
                        )
                      }
                    </Row>
                  </>
                )
              }
              {
                type === '3' && (
                  <>
                    <Row>
                      <Col className="p-0">
                        <Breadcrumb className="small">
                          <Breadcrumb.Item href="#">Inicio</Breadcrumb.Item>
                          <Breadcrumb.Item active>Estado de resultado</Breadcrumb.Item>
                        </Breadcrumb>
                      </Col>
                    </Row>
                    <Row className='d-flex justify-content-center '>
                      <Col sm="12" lg="0" className="d-flex align-items-center">
                        {/*<h4 className="font-weight-bold mb-1">Estado de resultados</h4>*/}
                        {
                          statusResultsDates.length !== 0 && (
                            dateComparison.start === `&start_year=${statusResultsDates[statusResultsDates.length - 1].year}&start_month=${statusResultsDates[statusResultsDates.length - 1].month}` && (
                              <p className="text-primary ml-2 mb-0"></p>
                            )
                          )
                        }
                      </Col>

                      <Col sm="5" lg='2'>
                        <Row className="d-flex align-items-center justify-content-center">
                          <Col className="mt-3 col-lg-12 col-sm-12">
                            <Form.Group controlId="start">
                              <h6 className="small font-weight-bold mr-2">Fecha de estado:</h6>
                              <Form.Control
                                className="text-muted"
                                size="md"
                                as="select"
                                name="start"
                                type="start"
                                onChange={handleSelections}
                              >
                                {
                                  statusResultsDates.slice(0).reverse().map((date, n) => {
                                    return (
                                      dateComparison.end === `&end_year=${date.year}&end_month=${date.month}` ? (
                                        <option
                                          key={n}
                                          className="secondary-text-color"
                                          style={{ backgroundColor: "#e3e3e3" }}
                                          disabled
                                        >
                                          {date.month}/{date.year}
                                        </option>
                                      ) : (
                                        <option
                                          key={n}
                                          className="secondary-text-color"
                                          value={`&start_year=${date.year}&start_month=${date.month}`}
                                        >
                                          {date.month}/{date.year}
                                        </option>
                                      )
                                    );
                                  })
                                }
                              </Form.Control>
                            </Form.Group>

                          </Col>
                        </Row>
                      </Col>

                      <Col sm="5" lg='2'>
                        <Row className="d-flex align-items-center justify-content-center">
                          <Col className="mt-3 col-lg-12 col-sm-12">

                            <Form.Group controlId="end">
                              <h6 className="small font-weight-bold mr-2">Comparar con:</h6>
                              <Form.Control
                                className="text-muted"
                                size="md"
                                as="select"
                                name="end"
                                type="end"
                                onChange={handleSelections}
                              >
                                <option value="" className="secondary-text-color"> - </option>
                                {
                                  statusResultsDates.slice(0).reverse().map((date, n) => {
                                    return (
                                      dateComparison.start === `&start_year=${date.year}&start_month=${date.month}` ? (
                                        <option
                                          key={n}
                                          className="secondary-text-color"
                                          style={{ backgroundColor: "#e3e3e3" }}
                                          disabled
                                        >
                                          {date.month}/{date.year}
                                        </option>
                                      ) : (
                                        dateComparison.end === `&end_year=${date.year}&end_month=${date.month}` ? (
                                          <option
                                            key={n}
                                            className="secondary-text-color"
                                            value={`&end_year=${date.year}&end_month=${date.month}`}
                                            selected
                                          >
                                            {date.month}/{date.year}
                                          </option>
                                        ) : (
                                          <option
                                            key={n}
                                            className="secondary-text-color"
                                            value={`&end_year=${date.year}&end_month=${date.month}`}
                                          >
                                            {date.month}/{date.year}
                                          </option>
                                        )
                                      )
                                    );
                                  })
                                }
                              </Form.Control>
                            </Form.Group>


                          </Col>
                        </Row>
                      </Col>

                      <Col sm="5" lg={userProfile.profile && (userProfile.profile.user_role !== "USER_SOCIETY" ? (2) : (2))}>
                        <Row className="d-flex align-items-center justify-content-center">
                          <Col className="mt-3 col-lg-12 col-sm-12">
                            <Form.Group controlId="rule">
                              <h6 className="small font-weight-bold mr-2">Norma:</h6>
                              <Form.Control
                                className="text-muted bg-light"
                                size="md"
                                as="select"
                                name="rule"
                                type="rule"
                                onChange={({ target }) => {
                                  setRule(target.value);
                                }}
                              >
                                <option value={"IFRS"} className="secondary-text-color" selected>IFRS</option>
                                <option value={"TRBT"} className="secondary-text-color">Tributaria</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>

                      <Col sm="5" lg={userProfile.profile && (userProfile.profile.user_role !== "USER_SOCIETY" ? (2) : (2))}>
                        <Row className="d-flex align-items-center justify-content-center">
                          <Col className="mt-3 col-lg-12 col-sm-12">
                            <Form.Group controlId="rule">
                              <h6 className="small font-weight-bold mr-2">Moneda: </h6>
                              <Form.Control
                                className="text-muted bg-light"
                                size="md"
                                as="select"
                                name="moneyType"
                                type="monery"
                                onChange={changeMoney}
                              >
                                <option value="LOCAL" className="secondary-text-color" selected>LOCAL</option>
                                <option value="USD" className="secondary-text-color">USD</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      {
                        userProfile.profile && (
                          userProfile.profile.user_role !== "USER_SOCIETY" && (
                            <Col sm="5" lg="2">
                              <Row className="d-flex align-items-center justify-content-center">
                                <Col className="mt-3 col-lg-12 col-sm-12">
                                  <Form.Group controlId="society">
                                    <h6 className="small font-weight-bold mr-2">Sociedad:</h6>
                                    <Form.Control
                                      className="text-muted bg-light"
                                      size="md"
                                      as="select"
                                      name="society"
                                      type="society"
                                      onChange={handleSelections}
                                    >
                                      {
                                        societyUserList.map((societyObj) => {
                                          return (
                                            societyObj.uuid === society ? (
                                              <option value={societyObj.uuid} className="secondary-text-color" selected>{societyObj.society_business_name}</option>
                                            ) : (
                                              <option value={societyObj.uuid} className="secondary-text-color">{societyObj.society_business_name}</option>)
                                          );
                                        })
                                      }
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                          )
                        )
                      }
                    </Row>
                  </>
                )
              }
              {
                type === '4' && (
                  <>
                    <Row>
                      <Col className="p-0">
                        <Breadcrumb className="small">
                          <Breadcrumb.Item href="#">Inicio</Breadcrumb.Item>
                          <Breadcrumb.Item active>Estado de flujos efectivos</Breadcrumb.Item>
                        </Breadcrumb>
                      </Col>
                    </Row>
                    <Row className='d-flex justify-content-center'>
                      <Col sm="12" className="d-flex align-items-center">
                        {/*<h4 className="font-weight-bold mb-1">Estado de flujos efectivos</h4>*/}
                      </Col>
                      <Col sm="4" lg="4">
                        <Row className="d-flex align-items-center justify-content-center">
                          <Col className="mt-3">
                            <Form.Group controlId="date">
                              <h6 className="small font-weight-bold mr-2">Año del estado:</h6>
                              <Form.Control
                                className="text-muted"
                                size="md"
                                as="select"
                                name="date"
                                type="date"
                                onChange={handleSelections}
                              >
                                {

                                  cashFlowDates.slice(0).reverse().map((date, n) => {
                                    return (
                                      <option
                                        key={n}
                                        className="secondary-text-color"
                                        value={`&year=${date.year}`}
                                      >
                                        {date.year}

                                      </option>
                                    );
                                  })
                                }
                              </Form.Control>
                            </Form.Group>

                          </Col>
                        </Row>
                      </Col>


                      <Col sm="4" lg={userProfile.profile && (userProfile.profile.user_role !== "USER_SOCIETY" ? (4) : (4))}>
                        <Row className="d-flex align-items-center justify-content-center">
                          <Col className="mt-3">
                            <Form.Group controlId="rule">
                              <h6 className="small font-weight-bold mr-2">Moneda:</h6>
                              <Form.Control
                                className="text-muted bg-light"
                                size="md"
                                as="select"
                                name="moneyType"
                                type="monery"
                                onChange={changeMoney}
                              >
                                <option value="LOCAL" className="secondary-text-color" selected>LOCAL</option>
                                <option value="USD" className="secondary-text-color">USD</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>

                      {
                        userProfile.profile && (
                          userProfile.profile.user_role !== "USER_SOCIETY" && (
                            <Col sm="4" lg="4">
                              <Row className="d-flex align-items-center justify-content-center">
                                <Col className="mt-3">
                                  <Form.Group controlId="society">
                                    <h6 className="small font-weight-bold mr-2">Sociedad:</h6>
                                    <Form.Control
                                      className="text-muted bg-light"
                                      size="md"
                                      as="select"
                                      name="society"
                                      type="society"
                                      onChange={handleSelections}
                                    >
                                      {
                                        societyUserList.map((societyObj) => {
                                          return (
                                            societyObj.uuid === society ? (
                                              <option value={societyObj.uuid} className="secondary-text-color" selected>{societyObj.society_business_name}</option>
                                            ) : (
                                              <option value={societyObj.uuid} className="secondary-text-color">{societyObj.society_business_name}</option>)
                                          );
                                        })
                                      }
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                          )
                        )
                      }

                    </Row>
                  </>
                )
              }
            </Card.Body>
          </Card>
        )}

        <Card className="border-0 shadow-sm mt-4">
          {
            type === '1' && balance8ColumnsData && (
              <>
                {
                  !dataExist && !isLoadedAccounting ? (
                    <Col className="text-center">
                      <h5 className="font-weight-bold m-3">Datos no encontrados</h5>
                      <XCircle className="text-danger" size={50} />
                      <h6 className="m-3">No es posible mostrar los datos en este momento, por favor intente nuevamente.</h6>
                    </Col>
                  ) : (
                    isLoadedAccounting ? (
                      <div className="d-flex align-content-center justify-content-center my-5">
                        <Spinner as="span" animation="border" size="xl" role="status" aria-hidden="true" />
                      </div>
                    ) : (
                      balance8ColumnsData.header && (
                        <>


                          <Col sm="12" lg="12" id='title-boton' className="text-center d-flex align-items-center justify-content-between p-2">
                            <div />
                            <div>
                              <h4 className="font-weight-bold">{(balance8ColumnsData.header.society_business_name).charAt(0).toUpperCase() + (balance8ColumnsData.header.society_business_name).slice(1)}</h4>

                              <h6 className="font-weight-bold">Balance 8 columnas</h6>

                              <p className="m-0" style={{ fontSize: "12px" }}>{balance8ColumnsData.header.data}</p>
                              <p className="m-0" style={{ fontSize: "12px" }}>Cifras esperadas en moneda: {balance8ColumnsData.header.money_symbol}</p>
                              <p className="m-0" style={{ fontSize: "12px" }}>Norma contable: {rule === 'TRBT' ? ('Tributaria') : ('IFRS')}</p>
                            </div>

                            <DropdownButton id="dropdown-basic-button" variant="secondary" title="Descargar">
                              <Dropdown.Item onClick={handlePDF}>Descargar PDF</Dropdown.Item>
                              <Dropdown.Item onClick={handleExcel}>Descargar Excel</Dropdown.Item>
                            </DropdownButton>

                          </Col>

                          <Card.Body>
                            <Table responsive borderless striped className="mb-0 small">
                              <thead style={{ backgroundColor: '#f3f3f3' }}>
                                <tr>
                                  <th colSpan={2} className="p-0 text-center border-right">
                                    <div className="p-2">
                                      Cuenta
                                    </div>
                                  </th>
                                  <th colSpan={2} className="p-0 text-center border-left">
                                    <div className="p-2">
                                      Monto
                                    </div>
                                  </th>
                                  <th colSpan={2} className="p-0 text-center border-left">
                                    <div className="p-2">
                                      Saldos
                                    </div>
                                  </th>
                                  <th colSpan={2} className="p-0 text-center border-left">
                                    <div className="p-2">
                                      Inventarios
                                    </div>
                                  </th>
                                  <th colSpan={2} className="p-0 text-center border-left">
                                    <div className="p-2">
                                      Resultados
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <thead>
                                <tr>
                                  <th className="p-0 text-center">
                                    <div className="p-2">
                                      Código
                                    </div>
                                    <hr className="m-0" />
                                  </th>
                                  <th className="p-0 text-center">
                                    <div className="p-2">
                                      Nombre de cuenta
                                    </div>
                                    <hr className="m-0" />
                                  </th>
                                  <th className="p-0 text-center border-left">
                                    <div className="p-2">
                                      Debitos
                                    </div>
                                    <hr className="m-0" />
                                  </th>
                                  <th className="p-0 text-center border-left">
                                    <div className="p-2">
                                      Creditos
                                    </div>
                                    <hr className="m-0" />
                                  </th>
                                  <th className="p-0 text-center border-left">
                                    <div className="p-2">
                                      Deudor
                                    </div>
                                    <hr className="m-0" />
                                  </th>
                                  <th className="p-0 text-center border-left">
                                    <div className="p-2">
                                      Acreedor
                                    </div>
                                    <hr className="m-0" />
                                  </th>
                                  <th className="p-0 text-center border-left">
                                    <div className="p-2">
                                      Activo
                                    </div>
                                    <hr className="m-0" />
                                  </th>
                                  <th className="p-0 text-center border-left">
                                    <div className="p-2">
                                      Pasivo
                                    </div>
                                    <hr className="m-0" />
                                  </th>
                                  <th className="p-0 text-center border-left">
                                    <div className="p-2">
                                      Perdidas
                                    </div>
                                    <hr className="m-0" />
                                  </th>
                                  <th className="p-0 text-center border-left">
                                    <div className="p-2">
                                      Ganancias
                                    </div>
                                    <hr className="m-0" />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  balance8ColumnsData.table.map((accounting, n) => {
                                    return (
                                      <tr key={n}>
                                        <td><AccountingModalOne rule={rule} cod={accounting.cod_cta} uuid={uuid} society={society} date={date} money={money} /></td>
                                        <td>{accounting.nom_cta}</td>
                                        <td className="text-right border-left">{accounting.debitos}</td>
                                        <td className="text-right border-left">{accounting.creditos}</td>
                                        <td className="text-right border-left">{accounting.deudor}</td>
                                        <td className="text-right border-left">{accounting.acreedor}</td>
                                        <td className="text-right border-left">{accounting.activo}</td>
                                        <td className="text-right border-left">{accounting.pasivo}</td>
                                        <td className="text-right border-left">{accounting.perdida}</td>
                                        <td className="text-right border-left">{accounting.ganancia}</td>
                                      </tr>
                                    );
                                  })
                                }

                                <tr className="custom-filter">
                                  <td colSpan={2} className="font-weight-bold border-left">Total acumulado</td>
                                  <td className="font-weight-bold text-right border-left">{balance8ColumnsData.footer.cumulative_total.debitos}</td>
                                  <td className="font-weight-bold text-right border-left">{balance8ColumnsData.footer.cumulative_total.creditos}</td>
                                  <td className="font-weight-bold text-right border-left">{balance8ColumnsData.footer.cumulative_total.deudor}</td>
                                  <td className="font-weight-bold text-rightborder-left">{balance8ColumnsData.footer.cumulative_total.acreedor}</td>
                                  <td className="font-weight-bold text-right border-left">{balance8ColumnsData.footer.cumulative_total.activo}</td>
                                  <td className="font-weight-bold text-right border-left">{balance8ColumnsData.footer.cumulative_total.pasivo}</td>
                                  <td className="font-weight-bold text-right border-left">{balance8ColumnsData.footer.cumulative_total.perdida}</td>
                                  <td className="font-weight-bold text-right border-left">{balance8ColumnsData.footer.cumulative_total.ganancia}</td>
                                </tr>
                                <tr className="custom-filter">
                                  <td colSpan={2}>Resultado de ejercicio</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.result_excersice.debitos}</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.result_excersice.creditos}</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.result_excersice.deudor}</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.result_excersice.acreedor}</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.result_excersice.activo}</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.result_excersice.pasivo}</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.result_excersice.perdida}</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.result_excersice.ganancia}</td>
                                </tr>
                                <tr className="custom-filter">
                                  <td colSpan={2}>Sumas iguales</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.equal_sums.debitos}</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.equal_sums.creditos}</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.equal_sums.deudor}</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.equal_sums.acreedor}</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.equal_sums.activo}</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.equal_sums.pasivo}</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.equal_sums.perdida}</td>
                                  <td className="text-right border-left">{balance8ColumnsData.footer.equal_sums.ganancia}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card.Body>
                        </>
                      )
                    )
                  )
                }
              </>
            )
          }

          {
            type === '2' && (



              <>
                {
                  !dataExist && !isLoadedAccounting ? (
                    <Col className='text-center'>
                      <h5 className='font-weight-bold m-3'>Datos no encontrados</h5>
                      <XCircle className='text-danger' size={50} />
                      <h6 className='m-3'>No es posible mostrar los datos en este momento, por favor intente nuevamente.</h6>
                    </Col>
                  ) : (
                    <>
                      {
                        isLoadedAccounting && (
                          <div className="d-flex align-content-center justify-content-center my-5">
                            <Spinner as="span" animation="border" size="xl" role="status" aria-hidden="true" />
                          </div>
                        )
                      }
                      {
                        financialSituationData.header && !isLoadedAccounting && (
                          (
                            <>
                              {
                                financialSituationData.header && financialSituationData.table.assets.length > 0 && financialSituationData.table.passive_and_heritage.length > 0 ? (
                                  <Col sm="12" lg="12" className="d-flex align-items-center justify-content-between p-4">
                                    <div />
                                    <div className="text-center">
                                      <h4
                                        className="font-weight-bold">{(financialSituationData.header.society_business_name).charAt(0).toUpperCase() + (financialSituationData.header.society_business_name).slice(1)}
                                      </h4>
                                      <h6 className="font-weight-bold">Estado de situación financiera</h6>
                                      <p className="m-0" style={{ fontSize: "13px", color: '#04a8e9', fontWeight: 'bold' }}>{financialSituationData.header.start_date}{financialSituationData.header.end_date && (` y ${financialSituationData.header.end_date}`)}</p>
                                      <p className="m-0" style={{ fontSize: "12px" }}>Cifras esperadas en moneda: {financialSituationData.header.money_symbol}</p>
                                      <p className="m-0" style={{ fontSize: "12px" }}>Norma contable: {rule === 'TRBT' ? ('Tributaria') : ('IFRS')}</p>
                                    </div>

                                    <DropdownButton id="dropdown-basic-button" variant="secondary" title="Descargar">
                                      <Dropdown.Item onClick={handlePDF}>Descargar PDF</Dropdown.Item>
                                      <Dropdown.Item onClick={handleExcel}>Descargar Excel</Dropdown.Item>
                                    </DropdownButton>
                                  </Col>
                                ) : (
                                  <Col sm="12" lg="12" className="d-flex align-items-center justify-content-between p-4">

                                  </Col>
                                )
                              }
                              {
                                financialSituationData.table.assets.length === 0 && financialSituationData.table.passive_and_heritage.length === 0 ? (
                                  <Card.Body>
                                    <Col className='text-center'>
                                      <h5 className='font-weight-bold m-3'>Datos no encontrados</h5>
                                      <XCircle className='text-danger' size={50} />
                                      <h6 className='m-3'>No es posible mostrar los datos en este momento, por favor intente nuevamente.</h6>
                                    </Col>
                                  </Card.Body>
                                ) : (
                                  <Card.Body>
                                    <Table responsive borderless striped className="mb-0 small">
                                      <thead>
                                        <tr>
                                          <th className="col-10 p-0">
                                            <div className="p-2" id='primary-title-pdf'>
                                              ACTIVO
                                            </div>
                                            <hr className="m-0" />
                                          </th>
                                          {
                                            financialSituationData.header && (
                                              <>
                                                {
                                                  financialSituationData.header.start_date && (
                                                    <th className="p-0 text-right">
                                                      <div >
                                                        {financialSituationData.header.start_date}
                                                      </div>
                                                      <hr className="m-0" />
                                                    </th>
                                                  )
                                                }
                                                {financialSituationData.header.start_date && (<th className="p-0" />)}
                                                {
                                                  financialSituationData.header.end_date && (
                                                    <th className="p-0">
                                                      <div className="text-right">
                                                        {financialSituationData.header.end_date}
                                                      </div>
                                                      <hr className="m-0" />
                                                    </th>
                                                  )
                                                }
                                                {financialSituationData.header.end_date && (<th className="p-0" />)}
                                              </>
                                            )
                                          }
                                        </tr>
                                      </thead>

                                      <tbody>
                                       
                                        {
                                          financialSituationData.table && (
                                            financialSituationData.table.assets.length !== 0 && (
                                              <>
                                                {
                                                  financialSituationData.table.assets[0].nivel2 === "ACTIVOS CORRIENTES" && (
                                                    <tr>
                                                      <td colSpan={5} id='title-pdf-report' className="font-weight-bold">
                                                        Activo corriente
                                                      </td>
                                                    </tr>
                                                  )
                                                }
                                                {
                                                  financialSituationData.table.assets.map((asset) => {
                                                    return (
                                                      <>
                                                        {
                                                          asset.nivel2 === "ACTIVOS CORRIENTES" && (
                                                            asset.index === "total" ? (
                                                              <tr className="custom-filter">
                                                                <td className="font-weight-bold p-2">Activos corrientes totales</td>
                                                                <td className="font-weight-bold p-2 text-right"
                                                                  colSpan={2}>{asset.start_date}</td>
                                                                {asset.end_date && (
                                                                  <td className="font-weight-bold p-2 text-right"
                                                                    colSpan={2}>{asset.end_date}</td>
                                                                )}
                                                              </tr>
                                                            ) : (
                                                              <tr key={asset.uuid}>
                                                                <td className="p-2">{asset.index}</td>
                                                                <td className="p-2 text-right" colSpan={2}><AccountingModalTwo
                                                                  rule={rule}
                                                                  name={asset.index} uuid={uuid} society={society}
                                                                  parent1={asset.parent1}
                                                                  date={`&year=${dateComparison.start.substring(12, 16)}&month=${dateComparison.start.substring(29, 31)}`}
                                                                  value={asset.start_date}
                                                                  money={money}
                                                                /></td>
                                                                {asset.end_date && (
                                                                  <td className="p-2 text-right" colSpan={2}><AccountingModalTwo
                                                                    rule={rule}
                                                                    name={asset.index} uuid={uuid} society={society}
                                                                    parent1={asset.parent1}
                                                                    date={`&year=${dateComparison.end.substring(10, 14)}&month=${dateComparison.end.substring(25, 27)}`}
                                                                    value={asset.end_date}
                                                                    money={money}
                                                                  /></td>
                                                                )}
                                                              </tr>
                                                            )
                                                          )
                                                        }
                                                      </>
                                                    );
                                                  })
                                                }
                                                {
                                                  financialSituationData.table.assets[financialSituationData.table.assets.length - 2].nivel2 === "ACTIVOS NO CORRIENTES" && (
                                                    <tr>
                                                      <td colSpan={5} id='title-pdf-report' className="font-weight-bold">
                                                        Activo no corriente
                                                      </td>
                                                    </tr>
                                                  )
                                                }
                                                {
                                                  financialSituationData.table.assets.map((asset) => {
                                                    return (
                                                      <>
                                                        {
                                                          asset.nivel2 === "ACTIVOS NO CORRIENTES" && (
                                                            asset.index === "total" ? (
                                                              <tr className="custom-filter">
                                                                <td className="font-weight-bold p-2">Activos no corrientes totales</td>
                                                                <td className="font-weight-bold p-2 text-right"
                                                                  colSpan={2}>{asset.start_date}</td>
                                                                {asset.end_date && (
                                                                  <td className="font-weight-bold p-2 text-right"
                                                                    colSpan={2}>{asset.end_date}</td>
                                                                )}
                                                              </tr>
                                                            ) : (
                                                              <tr key={asset.uuid}>
                                                                <td className="p-2">{asset.index}</td>
                                                                <td className="p-2 text-right" colSpan={2}><AccountingModalTwo
                                                                  rule={rule}
                                                                  name={asset.index} uuid={uuid} society={society}
                                                                  parent1={asset.parent1}
                                                                  date={`&year=${dateComparison.start.substring(12, 16)}&month=${dateComparison.start.substring(29, 31)}`}
                                                                  value={asset.start_date}
                                                                  money={money}
                                                                /></td>

                                                                {asset.end_date && (
                                                                  <td className="p-2 text-right" colSpan={2}><AccountingModalTwo
                                                                    rule={rule}
                                                                    name={asset.index} uuid={uuid} society={society}
                                                                    parent1={asset.parent1}
                                                                    date={`&year=${dateComparison.end.substring(10, 14)}&month=${dateComparison.end.substring(25, 27)}`}
                                                                    value={asset.end_date}
                                                                    money={money}
                                                                  /></td>
                                                                )}
                                                              </tr>
                                                            )
                                                          )
                                                        }
                                                      </>
                                                    )
                                                  })
                                                }
                                                {
                                                  financialSituationData.table.assets[financialSituationData.table.assets.length - 1] && (
                                                    <tr className="custom-filter">
                                                      <td
                                                        className="font-weight-bold">Activos totales</td>
                                                      <td
                                                        className="font-weight-bold text-right">{financialSituationData.table.assets[financialSituationData.table.assets.length - 1].start_date}</td>
                                                      {financialSituationData.table.assets[financialSituationData.table.assets.length - 1].end_date && (
                                                        <td className="font-weight-bold p-2 text-right"
                                                          colSpan={2}>{financialSituationData.table.assets[financialSituationData.table.assets.length - 1].end_date}</td>
                                                      )}
                                                    </tr>
                                                  )
                                                }
                                              </>
                                            )
                                          )
                                        }
                                 
                                      </tbody>
                                    </Table>
                                    <Table responsive borderless striped className="mb-0 small">
                                      <thead>
                                        <tr>
                                          <th className="col-10 p-0">
                                            <div className="p-2" id='primary-title-pdf'>
                                              PASIVOS Y PATRIMONIO
                                            </div>
                                            <hr className="m-0" />
                                          </th>
                                          {
                                            financialSituationData.header && (
                                              <>
                                                {
                                                  financialSituationData.header.start_date && (
                                                    <th colSpan={2} className="p-0">
                                                      <div className="p-2 text-right">
                                                        {financialSituationData.header.start_date}
                                                      </div>
                                                      <hr className="m-0" />
                                                    </th>
                                                  )
                                                }
                                                {financialSituationData.header.start_date && (<th className="p-0" />)}
                                                {
                                                  financialSituationData.header.end_date && (
                                                    <th className="p-0">
                                                      <div className="p-2 text-right">
                                                        {financialSituationData.header.end_date}
                                                      </div>
                                                      <hr className="m-0" />
                                                    </th>
                                                  )
                                                }
                                                {financialSituationData.header.end_date && (<th className="p-0" />)}
                                              </>
                                            )
                                          }
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          financialSituationData.table && (
                                            financialSituationData.table.passive_and_heritage.length !== 0 && (
                                              <>
                                     
                                                <tr>
                                                  <td colSpan={5} id='title-pdf-report' className="font-weight-bold">
                                                    Pasivo corriente
                                                  </td>
                                                </tr>
                                                {
                                                  financialSituationData.table.passive_and_heritage.map((active) => {
                                                    return (
                                                      <>
                                                        {
                                                          active.nivel2 === "PASIVOS CORRIENTES" && (
                                                            active.index === "total" ? (
                                                              <tr className="custom-filter">
                                                                <td className="font-weight-bold p-2">Pasivos corrientes totales</td>
                                                                <td className="font-weight-bold p-2 text-right"
                                                                  colSpan={2}>{active.start_date}</td>
                                                                {active.end_date && (
                                                                  <td className="font-weight-bold p-2 text-right"
                                                                    colSpan={2}>{active.end_date}</td>
                                                                )}
                                                              </tr>
                                                            ) : (
                                                              <tr key={active.uuid}>
                                                                <td className="p-2">{active.index}</td>
                                                                <td className="p-2 text-right" colSpan={2}><AccountingModalTwo
                                                                  rule={rule}
                                                                  name={active.index} uuid={uuid} society={society}
                                                                  parent1={active.parent1}
                                                                  date={`&year=${dateComparison.start.substring(12, 16)}&month=${dateComparison.start.substring(29, 31)}`}
                                                                  value={active.start_date}
                                                                  money={money}
                                                                /></td>
                                                                {active.end_date && (
                                                                  <td className="p-2 text-right" colSpan={2}><AccountingModalTwo
                                                                    rule={rule}
                                                                    name={active.index} uuid={uuid} society={society}
                                                                    parent1={active.parent1}
                                                                    date={`&year=${dateComparison.end.substring(10, 14)}&month=${dateComparison.end.substring(25, 27)}`}
                                                                    value={active.end_date}
                                                                    money={money}
                                                                  /></td>
                                                                )}
                                                              </tr>
                                                            )
                                                          )
                                                        }
                                                      </>
                                                    );
                                                  })
                                                }
                                                {
                                                  putTittle("PASIVOS NO CORRIENTES")
                                                }
                                                {
                                                  financialSituationData.table.passive_and_heritage.map((active) => {
                                                    return (
                                                      <>
                                                        {
                                                          active.nivel2 === "PASIVOS NO CORRIENTES" && (
                                                            active.index === "total" ? (
                                                              <tr className="custom-filter">
                                                                <td className="font-weight-bold p-2">Pasivos no corrientes totales</td>
                                                                <td className="font-weight-bold p-2 text-right"
                                                                  colSpan={2}>{active.start_date}</td>
                                                                {active.end_date && (
                                                                  <td className="font-weight-bold p-2 text-right"
                                                                    colSpan={2}>{active.end_date}</td>
                                                                )}
                                                              </tr>
                                                            ) : (
                                                              <tr key={active.uuid}>
                                                                <td className="p-2">{active.index}</td>
                                                                <td className="p-2 text-right" colSpan={2}><AccountingModalTwo
                                                                  rule={rule}
                                                                  name={active.index} uuid={uuid} society={society}
                                                                  parent1={active.parent1}
                                                                  date={`&year=${dateComparison.start.substring(12, 16)}&month=${dateComparison.start.substring(29, 31)}`}
                                                                  value={active.start_date}
                                                                  money={money}
                                                                /></td>
                                                                {active.end_date && (
                                                                  <td className="p-2 text-right" colSpan={2}><AccountingModalTwo
                                                                    rule={rule}
                                                                    name={active.index} uuid={uuid} society={society}
                                                                    parent1={active.parent1}
                                                                    date={`&year=${dateComparison.end.substring(10, 14)}&month=${dateComparison.end.substring(25, 27)}`}
                                                                    value={active.end_date}
                                                                    money={money}
                                                                  /></td>
                                                                )}
                                                              </tr>
                                                            )
                                                          )
                                                        }
                                                      </>
                                                    );
                                                  })
                                                }
                                                {
                                                  putTittle("PATRIMONIO")
                                                }
                                                {
                                                  financialSituationData.table.passive_and_heritage.map((active) => {
                                                    return (
                                                      <>
                                                        {
                                                          active.nivel2 === "PATRIMONIO" && (
                                                            active.index === "total" ? (
                                                              <tr className="custom-filter">
                                                                <td className="font-weight-bold p-2">Patrimonio total</td>
                                                                <td className="font-weight-bold p-2 text-right"
                                                                  colSpan={2}>{active.start_date}</td>
                                                                {active.end_date && (
                                                                  <td className="font-weight-bold p-2 text-right"
                                                                    colSpan={2}>{active.end_date}</td>
                                                                )}
                                                              </tr>
                                                            ) : (
                                                              <tr key={active.uuid}>
                                                                <td className="p-2">{active.index}</td>
                                                                <td className="p-2 text-right" colSpan={2}><AccountingModalTwo
                                                                  rule={rule}
                                                                  name={active.index} uuid={uuid} society={society}
                                                                  parent1={active.parent1}
                                                                  date={`&year=${dateComparison.start.substring(12, 16)}&month=${dateComparison.start.substring(29, 31)}`}
                                                                  value={active.start_date}
                                                                  money={money}
                                                                /></td>
                                                                {active.end_date && (
                                                                  <td className="p-2 text-right" colSpan={2}><AccountingModalTwo
                                                                    rule={rule}
                                                                    name={active.index} uuid={uuid} society={society}
                                                                    parent1={active.parent1}
                                                                    date={`&year=${dateComparison.end.substring(10, 14)}&month=${dateComparison.end.substring(25, 27)}`}
                                                                    value={active.end_date}
                                                                    money={money}
                                                                  /></td>
                                                                )}
                                                              </tr>
                                                            )
                                                          )
                                                        }
                                                      </>
                                                    );
                                                  })
                                                }
                                              </>
                                            )
                                          )
                                        }

                                        {
                                          financialSituationData.table.passive_and_heritage.length > 0 && (
                                            <tr className="custom-filter font-weight-bold">
                                              <td>{financialSituationData.table.passive_and_heritage[financialSituationData.table.passive_and_heritage.length - 2].index}</td>
                                              <td colSpan={2}
                                                className="text-right">{financialSituationData.table.passive_and_heritage[financialSituationData.table.passive_and_heritage.length - 2].start_date}</td>
                                              {financialSituationData.table.passive_and_heritage[financialSituationData.table.passive_and_heritage.length - 2].end_date && (
                                                <td className="text-right"
                                                  colSpan={2}>{financialSituationData.table.passive_and_heritage[financialSituationData.table.passive_and_heritage.length - 2].end_date}</td>
                                              )}
                                            </tr>
                                          )
                                        }
                                        {
                                          financialSituationData.table.passive_and_heritage[financialSituationData.table.passive_and_heritage.length - 1] && (
                                            <>
                                              <tr className="custom-filter">
                                                <td
                                                  className="font-weight-bold">Pasivos y patrimonio totales</td>
                                                <td colSpan={2}
                                                  className="font-weight-bold text-right">{financialSituationData.table.passive_and_heritage[financialSituationData.table.passive_and_heritage.length - 1].start_date}</td>
                                                {financialSituationData.table.passive_and_heritage[financialSituationData.table.passive_and_heritage.length - 1].end_date && (
                                                  <td className="font-weight-bold text-right"
                                                    colSpan={2}>{financialSituationData.table.passive_and_heritage[financialSituationData.table.passive_and_heritage.length - 1].end_date}</td>
                                                )}
                                              </tr>
                                           
                                            </>
                                          )
                                        }
                                      </tbody>
                                    </Table>
                                  </Card.Body>
                                )
                              }
                            </>
                          )
                        )
                      }
                    </>
                  )
                }
              </>
            )

          }

          {
            type === '3' && (
              <>
                {
                  !dataExist && !isLoadedAccounting ? (
                    <Col className='text-center'>
                      <h5 className='font-weight-bold m-3'>Datos no encontrados</h5>
                      <XCircle className='text-danger' size={50} />
                      <h6 className='m-3'>No es posible mostrar los datos en este momento, por favor intente nuevamente.</h6>
                    </Col>
                  ) : (
                    <>
                      {
                        isLoadedAccounting && (
                          <div className="d-flex align-content-center justify-content-center my-5">
                            <Spinner as="span" animation="border" size="xl" role="status" aria-hidden="true" />
                          </div>
                        )
                      }
                      {
                        statusResultsData.header && !isLoadedAccounting && (
                          <>
                            {
                              statusResultsData.header && (
                                <Col sm="12" lg="12" className="text-center d-flex align-items-center justify-content-between p-4">
                                  <div />
                                  <div>
                                    <h4 className="font-weight-bold">{(statusResultsData.header.society_business_name).charAt(0).toUpperCase() + (statusResultsData.header.society_business_name).slice(1)}</h4>

                                    <h6 className="font-weight-bold">Estado de resultados</h6>

                                    <p className="m-0" style={{ fontSize: "13px", color: '#04a8e9', fontWeight: 'bold' }}>{statusResultsData.header.start_date}{statusResultsData.header.end_date && (` y ${statusResultsData.header.end_date}`)}</p>
                                    <p className="m-0" style={{ fontSize: "12px" }}>Cifras esperadas en moneda: {statusResultsData.header.money_symbol}</p>
                                    {
                                      console.log(statusResultsData.header)
                                    }
                                    <p className="m-0" style={{ fontSize: "12px" }}>Norma contable: {rule === 'TRBT' ? ('Tributaria') : ('IFRS')}</p>

                                  </div>

                                  <DropdownButton id="dropdown-basic-button" variant="secondary" title="Descargar">
                                    <Dropdown.Item onClick={handlePDF}>Descargar PDF</Dropdown.Item>
                                    <Dropdown.Item onClick={handleExcel}>Descargar Excel</Dropdown.Item>
                                  </DropdownButton>
                                </Col>
                              )
                            }
                            <Card.Body>
                              <Table responsive borderless striped className="mb-0 small">
                                <thead>
                                  <tr>
                                    <th className="col-10 p-0">
                                      <div className="p-2" id='title-pdf-report'>
                                        Estado de resultado
                                      </div>
                                      <hr className="m-0" />
                                    </th>
                                    {
                                      statusResultsData.colum_date && (
                                        <>
                                          {
                                            statusResultsData.colum_date.start_date && (
                                              <th className="p-0">
                                                <div className="p-0 text-right">
                                                  {statusResultsData.colum_date.start_date}
                                                </div>
                                                <hr className="m-0" />
                                              </th>
                                            )
                                          }
                                          {statusResultsData.colum_date.start_date && (<th className="p-0" />)}
                                          {
                                            statusResultsData.colum_date.end_date && (
                                              <th className="p-0">
                                                <div className="p-0 text-right">
                                                  {statusResultsData.colum_date.end_date}
                                                </div>
                                                <hr className="m-0" />
                                              </th>
                                            )
                                          }
                                          {statusResultsData.colum_date.end_date && (<th className="p-0" />)}
                                        </>
                                      )
                                    }
                                  </tr>
                                </thead>
                                <tbody>
                             
                                  {
                                    statusResultsData && (
                                      statusResultsData.table && (
                                        statusResultsData.table.map((status, n) => {
                                          return (
                                            <>
                                              {
                                                n === statusResultsData.table.length - 1 || status.id_grupo !== statusResultsData.table[n + 1].id_grupo ? (
                                                  <>
                                                    <tr className="custom-filter">
                                                      <td className="font-weight-bold p-2">{status.index}</td>
                                                      <td className="font-weight-bold p-2 text-right" colSpan={2}>{status.start_date}</td>
                                                      {status.end_date && (
                                                        <td className="font-weight-bold p-2 text-right" colSpan={2}>{status.end_date}</td>
                                                      )}
                                                    </tr>
                                                
                                                  </>
                                                ) : (
                                                  <tr>
                                                    <td className="p-2">{status.index}</td>
                                                    <td className="p-2 text-right" colSpan={2}><AccountingModalFour name={status.index} uuid={uuid} rule={rule} society={society} parent1={status.parent1} date={`&year=${dateComparison.start.substring(12, 16)}&month=${dateComparison.start.substring(29, 31)}`} money={money} value={status.start_date} /></td>
                                                    {status.end_date && (
                                                      <td className="p-2 text-right" colSpan={2}><AccountingModalFour name={status.index} uuid={uuid} rule={rule} society={society} parent1={status.parent1} date={`&year=${dateComparison.end.substring(10, 14)}&month=${dateComparison.end.substring(25, 27)}`} money={money} value={status.end_date} /></td>
                                                    )}
                                                  </tr>
                                                )
                                              }
                                            </>
                                          );
                                        })
                                      )
                                    )
                                  }
                                </tbody>
                              </Table>
                            </Card.Body>
                          </>
                        )
                      }
                    </>
                  )
                }
              </>
            )
          }
          {
            type === '4' && (
              <>
                {
                  !dataExist && !isLoadedAccounting ? (
                    console.log(isLoadedAccounting),
                    <Col className="text-center">
                      <h5 className="font-weight-bold m-3">Datos no encontrados</h5>
                      <XCircle className="text-danger" size={50} />
                      <h6 className="m-3">No es posible mostrar los datos en este momento, por favor intente nuevamente.</h6>
                    </Col>
                  ) : (
                    <>

                      {
                        isLoadedAccounting && (
                          console.log('Valicación 2', isLoadedAccounting),
                          <div className="d-flex align-content-center justify-content-center my-5">
                            <Spinner as="span" animation="border" size="xl" role="status" aria-hidden="true" />
                          </div>
                        )
                      }

                      {
                        cashFlowData.header && !isLoadedAccounting && (
                          <>
                            {
                              // Header
                              cashFlowData.header && (

                                <Col sm="12" lg="12" className="text-center d-flex align-items-center justify-content-between p-4">
                                  <div />
                                  <div>
                                    <h4 className="font-weight-bold">{(cashFlowData.header.society_business_name).charAt(0).toUpperCase() + (cashFlowData.header.society_business_name).slice(1)}</h4>

                                    <h6 className="font-weight-bold">Estado de flujo de efectivos</h6>

                                    <p className="m-0" style={{ fontSize: "12px" }}>Cifras esperadas en moneda: {cashFlowData.header.money_symbol}</p>

                                    <p className="m-0" style={{ fontSize: "12px" }}>{cashFlowData.header.year}</p>
                                  </div>

                                  <DropdownButton id="dropdown-basic-button" variant="secondary" title="Descargar">
                                    <Dropdown.Item onClick={handlePDF}>Descargar PDF</Dropdown.Item>
                                    <Dropdown.Item onClick={handleExcel} style={{ display: 'none' }}>Descargar Excel</Dropdown.Item>
                                  </DropdownButton>
                              
                                </Col>
                              )
                            }


                            <Card.Body>

                              {
                                // Body
                                cashFlowData.flow && (
                                  <div className="table-wrapper">
                                  <div className='table-wrapper2'>
                                    <div className='table-wrapper3'>
                                      <Table id='tabla' responsive borderless striped className="mb-0 small">

                                        <thead id='header-table'>
                                          <tr>
                                            <th colSpan={2} className="p-0" >
                                              <div className="p-2" id='style_th'>
                                                Estado de flujo de efectivos
                                              </div>
                                              <hr className="m-0" />
                                            </th>
                                            <th colSpan={2} className="p-0">
                                              <div className="p-2" id='style_th'>
                                                Nombre
                                              </div>
                                              <hr className="m-0" />
                                            </th>
                                            {
                                              cashFlowData.flow[0][1] && (
                                                <th className="p-0">
                                                  <div className="p-2">
                                                    Enero
                                                  </div>
                                                  <hr className="m-0" />
                                                </th>
                                              )
                                            }
                                            {
                                              cashFlowData.flow[0][2] && (
                                                <th className="p-0">
                                                  <div className="p-2">
                                                    Febrero
                                                  </div>
                                                  <hr className="m-0" />
                                                </th>
                                              )
                                            }
                                            {
                                              cashFlowData.flow[0][3] && (
                                                <th className="p-0">
                                                  <div className="p-2">
                                                    Marzo
                                                  </div>
                                                  <hr className="m-0" />
                                                </th>
                                              )
                                            }
                                            {
                                              cashFlowData.flow[0][4] && (
                                                <th className="p-0">
                                                  <div className="p-2">
                                                    Abril
                                                  </div>
                                                  <hr className="m-0" />
                                                </th>
                                              )
                                            }
                                            {
                                              cashFlowData.flow[0][5] && (
                                                <th className="p-0">
                                                  <div className="p-2">
                                                    Mayo
                                                  </div>
                                                  <hr className="m-0" />
                                                </th>
                                              )
                                            }
                                            {
                                              cashFlowData.flow[0][6] && (
                                                <th className="p-0">
                                                  <div className="p-2">
                                                    Junio
                                                  </div>
                                                  <hr className="m-0" />
                                                </th>
                                              )
                                            }
                                            {
                                              cashFlowData.flow[0][7] && (
                                                <th className="p-0">
                                                  <div className="p-2">
                                                    Julio
                                                  </div>
                                                  <hr className="m-0" />
                                                </th>
                                              )
                                            }
                                            {
                                              cashFlowData.flow[0][8] && (
                                                <th className="p-0">
                                                  <div className="p-2">
                                                    Agosto
                                                  </div>
                                                  <hr className="m-0" />
                                                </th>
                                              )
                                            }
                                            {
                                              cashFlowData.flow[0][9] && (
                                                <th className="p-0">
                                                  <div className="p-2">
                                                    Septiembre
                                                  </div>
                                                  <hr className="m-0" />
                                                </th>
                                              )
                                            }
                                            {
                                              cashFlowData.flow[0][10] && (
                                                <th className="p-0">
                                                  <div className="p-2">
                                                    Octubre
                                                  </div>
                                                  <hr className="m-0" />
                                                </th>
                                              )
                                            }
                                            {
                                              cashFlowData.flow[0][11] && (
                                                <th className="p-0">
                                                  <div className="p-2">
                                                    Noviembre
                                                  </div>
                                                  <hr className="m-0" />
                                                </th>
                                              )
                                            }
                                            {
                                              cashFlowData.flow[0][11] && (
                                                <th className="p-0">
                                                  <div className="p-2">
                                                    Diciembre
                                                  </div>
                                                  <hr className="m-0" />
                                                </th>
                                              )
                                            }
                                            <th className="p-0">
                                              <div className="p-2">
                                                Total
                                              </div>
                                              <hr className="m-0" />
                                            </th>
                                          </tr>

                                        </thead>

                                        <tbody>
                                          <tr>
                                            <td colSpan={20} />
                                          </tr>
                                          {
                                            cashFlowData.flow.map((flow, index) => {
                                              // Amarzena el indice 0 = titulo
                                              const currentTitle = flow.index[0];
                                              // Amarzena la comparación que index sea igual a 0
                                              const isFirstRow = index === 0;
                                              // Amarzena que el index 0 sea diferente a titulo amarzenado o al index 0
                                              const shouldPrintTitle = currentTitle !== previousTitle || isFirstRow;
                                              // Variable vacia recibe el ultimo titulo
                                              previousTitle = currentTitle;

                                              return (
                                                <>
                                                  {
                                                    // Verifica si el flow.index no es un array, si se cumple le asigna una clase para pintar de otro
                                                    <tr key={index} className={!Array.isArray(flow.index) ? 'custom-filter2' : ''}>

                                                      {flow.index !== 0 ? (
                                                        <>
                                                          <td className="p-2 custom-filter">
                                                            {shouldPrintTitle ? (
                                                              Array.isArray(flow.index) ? (
                                                                <span>{flow.index[0]}</span>
                                                              ) : (
                                                                <span>{flow.index}</span>
                                                              )
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </td>

                                                          <td className="p-2 custom-filter"></td>
                                                          <td className="p-2 custom-filter"></td>
                                                          <td className="p-2">{flow.index[1] === '.' ? '' : flow.index[1]}</td>
                                                          {flow[1] && <td className="p-2">{flow[1]}</td>}
                                                          {flow[2] && <td className="p-2">{flow[2]}</td>}
                                                          {flow[3] && <td className="p-2">{flow[3]}</td>}
                                                          {flow[4] && <td className="p-2">{flow[4]}</td>}
                                                          {flow[5] && <td className="p-2">{flow[5]}</td>}
                                                          {flow[6] && <td className="p-2">{flow[6]}</td>}
                                                          {flow[7] && <td className="p-2">{flow[7]}</td>}
                                                          {flow[8] && <td className="p-2">{flow[8]}</td>}
                                                          {flow[9] && <td className="p-2 ">{flow[9]}</td>}
                                                          {flow[10] && <td className="p-2">{flow[10]}</td>}
                                                          {flow[11] && <td className="p-2">{flow[11]}</td>}
                                                          {flow[12] && <td className="p-2">{flow[12]}</td>}
                                                          {flow.total && <td className="p-2">{flow.total}</td>}
                                                        </>


                                                      ) : (
                                                        <></>
                                                      )}
                                                    </tr>
                                                  }


                                                  {
                                                    flow.index === 0 && (
                                                      <tr>
                                                        <>
                                                          <td className="p-2 custom-filter">Gran total</td>
                                                        </>
                                                        <td className="p-2 custom-filter" ></td>
                                                        <td className="p-2 custom-filter"></td>
                                                        <td className="p-2 custom-filter"></td>
                                                        {flow[1] && (<td className="p-2  custom-filter" >{flow[1]}</td>)}
                                                        {flow[2] && (<td className="p-2 custom-filter">{flow[2]}</td>)}
                                                        {flow[3] && (<td className="p-2 custom-filter">{flow[3]}</td>)}
                                                        {flow[4] && (<td className="p-2 custom-filter">{flow[4]}</td>)}
                                                        {flow[5] && (<td className="p-2 custom-filter">{flow[5]}</td>)}
                                                        {flow[6] && (<td className="p-2 custom-filter">{flow[6]}</td>)}
                                                        {flow[7] && (<td className="p-2 custom-filter">{flow[7]}</td>)}
                                                        {flow[8] && (<td className="p-2 custom-filter">{flow[8]}</td>)}
                                                        {flow[9] && (<td className="p-2 custom-filter">{flow[9]}</td>)}
                                                        {flow[10] && (<td className="p-2 custom-filter">{flow[10]}</td>)}
                                                        {flow[11] && (<td className="p-2 custom-filter">{flow[11]}</td>)}
                                                        {flow[12] && (<td className="p-2 custom-filter">{flow[12]}</td>)}
                                                        {flow.total && (<td className="p-2 custom-filter">{flow.total}</td>)}
                                                      </tr>
                                                    )
                                                  }

                                                </>
                                              );



                                            })

                                          }



                                        </tbody>

                                        {
                                          cashFlowData.footer && (
                                            <tr className="custom-filter-dark">
                                              <td colSpan={4} className="font-weight-bold p-2 ">Total</td>
                                              {cashFlowData.flow[0][1] && (<td />)}
                                              {cashFlowData.flow[0][2] && (<td />)}
                                              {cashFlowData.flow[0][3] && (<td />)}
                                              {cashFlowData.flow[0][4] && (<td />)}
                                              {cashFlowData.flow[0][5] && (<td />)}
                                              {cashFlowData.flow[0][6] && (<td />)}
                                              {cashFlowData.flow[0][7] && (<td />)}
                                              {cashFlowData.flow[0][8] && (<td />)}
                                              {cashFlowData.flow[0][9] && (<td />)}
                                              {cashFlowData.flow[0][10] && (<td />)}
                                              {cashFlowData.flow[0][11] && (<td />)}
                                              {cashFlowData.flow[0][12] && (<td />)}
                                              <td colSpan={2} className="font-weight-bold p-2">{cashFlowData.footer.total}</td>
                                            </tr>
                                          )
                                        }
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                                    
                              
                                )
                              }
                            </Card.Body>
                          </>
                        )
                      }
                    </>
                  )
                }
              </>
            )
          }
        </Card>
      </>
    </ConfigProvider>
  )

}





AccountingUser.propTypes = {
  showBalance8Columns: PropTypes.func.isRequired,
  balance8ColumnsData: PropTypes.object,
  showSocietyForUser: PropTypes.func.isRequired,
  societyUserList: PropTypes.array,
  showBalance8ColumnsDates: PropTypes.func.isRequired,
  balance8ColumnsDates: PropTypes.array,
  isLoadedAccounting: PropTypes.bool,
  showAccountingPDF: PropTypes.func.isRequired,
  showCashFlowDates: PropTypes.func.isRequired,
  cashFlowDates: PropTypes.array,
  showCashFlow: PropTypes.func.isRequired,
  cashFlowData: PropTypes.object,
  financialSituationDates: PropTypes.array,
  showFinancialSituation: PropTypes.func.isRequired,
  financialSituationData: PropTypes.object,
  statusResultsDates: PropTypes.array,
  showStatusResult: PropTypes.func.isRequired,
  statusResultsData: PropTypes.object
};

const stateToProps = state => ({
  balance8ColumnsData: state.accounting.balance8ColumnsData,
  societyUserList: state.societies.societyUserList,
  balance8ColumnsDates: state.accounting.balance8ColumnsDates,
  isLoadedAccounting: state.accounting.isLoadedAccounting,
  cashFlowDates: state.accounting.cashFlowDates,
  cashFlowData: state.accounting.cashFlowData,
  financialSituationDates: state.accounting.financialSituationDates,
  financialSituationData: state.accounting.financialSituationData,
  statusResultsDates: state.accounting.statusResultsDates,
  statusResultsData: state.accounting.statusResultsData,
  dataExist: state.accounting.dataExist,
  userProfile: state.profile.userProfile
});

const actionsToProps = dispatch => ({
  showBalance8Columns: payload => dispatch({ type: FETCH_BALANCE_8_COLUMNS, payload }),
  showSocietyForUser: payload => dispatch({ type: FETCH_SOCIETY_USER_LIST, payload }),
  showBalance8ColumnsDates: payload => dispatch({ type: FETCH_DATES_BALANCE_8_COLUMNS, payload }),
  showAccountingPDF: payload => dispatch({ type: FETCH_PDF_BALANCE_8_COLUMNS, payload }),
  showCashFlowDates: payload => dispatch({ type: FETCH_DATES_CASH_FLOW, payload }),
  showCashFlow: payload => dispatch({ type: FETCH_CASH_FLOW, payload }),
  showFinancialSituationDates: payload => dispatch({ type: FETCH_DATES_FINANCIAL_SITUATION, payload }),
  showFinancialSituation: payload => dispatch({ type: FETCH_FINANCIAL_SITUATION, payload }),
  showStatusResultDates: payload => dispatch({ type: FETCH_DATES_STATUS_RESULTS, payload }),
  showStatusResult: payload => dispatch({ type: FETCH_STATUS_RESULTS, payload }),
  showAccountingExcel: payload => dispatch({ type: FETCH_EXCEL, payload })
});

export default connect(stateToProps, actionsToProps)(AccountingUser);