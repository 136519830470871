import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Collapse, Form, Row, Table } from 'react-bootstrap';
//import { ConfigProvider } from 'react-avatar';
import UsersItem from '../users_item';
import SearchBar from '../search_bar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PaginationBar from '../pagination/paginationBar';
import { Link, useHistory } from 'react-router-dom';
import { ChevronDown, UploadCloud, Plus } from 'react-feather';
import InvitationItem from '../invitation_item';

import { FETCH_LIST_USERS, PATCH_USER } from '../../store/user/types';
import { FETCH_GROUP, FETCH_LIST_GROUPS } from '../../store/group/types';
import { FETCH_LIST_PROJECTS, FETCH_PROJECT } from '../../store/project/types';




const UsersAdmin = (
  {
    showUsers,
    patchUser,
    usersList,
    indexPagination,
    showGroup,
    groupData,
    indexPaginationInvitations,
    invitationsList,
    showProjects,
    projectsList,
    showProject,
    projectData,
    userProfile,
    groupsList,
    showGroups
  }) => {
  const [flagAction, setFlagAction] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const [paginationPosition, setPaginationPosition] = useState(window.location.hash.substring(1, window.location.hash.indexOf("%")));
  const [queryValue, setQueryValue] = useState(window.location.hash.substring(window.location.hash.indexOf("?")));
  const [filterValue, setFilterValue] = useState({
    project: "",
    group: "",
    society: "",
    ordering: "",
    user_role: ""
  });
  const history = useHistory();
  const [paginationPositionInvitations, setPaginationPaginationPositionInvitations] = useState(window.location.hash.substring(window.location.hash.indexOf("%") + 1, window.location.hash.indexOf("?")));
  const [usersInScreen, setUsersInScreen] = useState(5);
  const [open, setOpen] = useState(false);




  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'PROJECT_ADMIN') {
        showGroups({ valueSearch: { project: localStorage.project } });
      }
    }// eslint-disable-next-line
  }, [userProfile]);

  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'SUPER_ADMIN') {
        showProjects({ valueSearch: "", paginationPosition: null });
      }
    }
  }, [userProfile, showProjects]);

  useEffect(() => {
    if (filterValue.project) {
      showProject(filterValue.project);
    }
  }, [filterValue.project, showProject]);

  useEffect(() => {
    if (filterValue.group) {
      showGroup(filterValue.group);
    }
  }, [filterValue.group, showGroup]);

  useEffect(() => {
    showUsers({ valueSearch, queryValue, paginationPosition, filterValue, paginationPositionInvitations, usersInScreen });
  }, [history, showUsers, flagAction, valueSearch, paginationPosition, filterValue, paginationPositionInvitations, usersInScreen, queryValue]);

  useEffect(() => {
    if (!window.location.hash) {
      setPaginationPosition('1');
      setPaginationPaginationPositionInvitations('1');
      setQueryValue("?search=&project=&group=&society=&ordering=&user_role=");
    } else {
      setValueSearch(window.location.hash.substring(window.location.hash.indexOf("h=") + 2, window.location.hash.indexOf("&")));
      setFilterValue({
        project: window.location.hash.substring(window.location.hash.indexOf("t=") + 2, window.location.hash.indexOf("&gro", 2)),
        group: window.location.hash.substring(window.location.hash.indexOf("p=") + 2, window.location.hash.indexOf("&soc", 2)),
        society: window.location.hash.substring(window.location.hash.indexOf("y=") + 2, window.location.hash.indexOf("&ord", 2)),
        ordering: window.location.hash.substring(window.location.hash.indexOf("g=") + 2, window.location.hash.indexOf("&use", 2)),
        user_role: window.location.hash.substring(window.location.hash.indexOf("e=") + 2)
      })
    }
  }, [setPaginationPosition]);

  useEffect(() => {
    history.replace(`#${paginationPosition}%${paginationPositionInvitations}?search=${valueSearch}&project=${filterValue.project}&group=${filterValue.group}&society=${filterValue.society}&ordering=${filterValue.ordering}&user_role=${filterValue.user_role}`);
  }, [paginationPosition, paginationPositionInvitations, valueSearch, filterValue, history]);

  const handleSearch = ({ target }) => {
    setPaginationPosition(1);
    setPaginationPaginationPositionInvitations(1);
    setQueryValue(`?search=${target.value}&project=${filterValue.project}&group=${filterValue.group}&society=${filterValue.society}&ordering=${filterValue.ordering}&user_role=${filterValue.user_role}`);
    setValueSearch(target.value);
  }


  const handleSelectFilter = ({ target }) => {
    let formData = { ...filterValue, [target.name]: target.value };
    if (target.name === 'project') {
      formData.group = "";
      formData.society = "";
    }
    if (target.name === 'group')
      formData.society = "";
    setPaginationPosition(1);
    setPaginationPaginationPositionInvitations(1);
    setQueryValue(`?search=${valueSearch}&project=${formData.project}&group=${formData.group}&society=${formData.society}&ordering=${formData.ordering}&user_role=${formData.user_role}`);
    setFilterValue(formData);
  };

  return (

    <>



      <Row className='d-flex align-items-center justify-content-beetwen' id={ open ? 'headOpen' : 'headNormaly' }>


        <Col xs="12" sm="3" lg="4" xl="5">
          <Breadcrumb className="small">
            <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
            <Breadcrumb.Item active>Administración Usuarios</Breadcrumb.Item>
          </Breadcrumb>
          <h4 className="font-weight-bold  mb-4 mb-sm-0 ml-3">Usuarios</h4>
        </Col>


        <Col xs="12" sm="9" lg="8" xl="7" className='mt-3'>
          <Row>
            <Col sm="12" lg="12" className='d-flex justify-content-beetwen align-items-center' id='wrap-filter'>

              <Button
                onClick={() => setOpen(!open)}
                variant="outline-primary"
                size="md"
                aria-controls="example-collapse-text"
                aria-expanded={open}
                className="d-flex justify-content-between mr-2"
                id='btnFilter'
                block
              >
                Filtros
                <ChevronDown />
              </Button>
              <SearchBar handleSearch={handleSearch} defaultValue={decodeURI(valueSearch).length === 1 ? (`${decodeURI(valueSearch)} `) : (decodeURI(valueSearch))}/>
              <Button as={Link} to="/admin/users/import" className='d-flex justify-content-between mr-2 btn btn-outline-success btn-block btn-md'  variant="outline-success" size="md" block><UploadCloud size={18} className='mr-2'></UploadCloud>Importar</Button>
              <Button as={Link} to="/admin/users/invite" className='mr-2 my-3 my-lg-0' variant="primary" size="md" block><Plus size={18} className='mr-2'></Plus>Invitar</Button>
            </Col>
          </Row>

          <Collapse in={open}>
            <div id="example-collapse-text">
              <Row className="my-3">
                {
                  userProfile.profile && (
                    userProfile.profile.user_role === 'SUPER_ADMIN' && (
                      <>
                        <Col xs="12" md="4" lg="3" className="mb-2">
                          <Form.Group controlId="project" className="m-0">
                            <Form.Control
                              className="text-muted"
                              size="md"
                              as="select"
                              name="project"
                              type="project"
                              onChange={handleSelectFilter}
                            >
                              <option value="" className="secondary-text-color" selected> Todos los proyectos </option>
                              {
                                projectsList.map((project) => {
                                  return (
                                    filterValue.project === project.uuid ? (
                                      <option value={project.uuid} key={project.uuid} selected>{project.project_name}</option>
                                    ) : (
                                      <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                                    )
                                  );
                                })
                              }
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs="12" md="4" lg="2" className="mb-2">
                          {
                            filterValue.project && projectData ? (
                              <Form.Group controlId="group" className="m-0">
                                <Form.Control
                                  className="text-muted"
                                  size="md"
                                  as="select"
                                  name="group"
                                  type="group"
                                  onChange={handleSelectFilter}
                                >
                                  <option value="" className="secondary-text-color" selected> Todos los grupos </option>
                                  {
                                    projectData.groups.map((group) => {
                                      return (
                                        filterValue.group === group.uuid ? (
                                          <option value={group.uuid} key={group.uuid} selected>{group.group_name}</option>
                                        ) : (
                                          <option value={group.uuid} key={group.uuid}>{group.group_name}</option>
                                        )
                                      );
                                    })
                                  }
                                </Form.Control>
                              </Form.Group>
                            ) : (
                              <Form.Group controlId="group" className="m-0">
                                <Form.Control
                                  className="text-muted"
                                  size="md"
                                  as="select"
                                  name="group"
                                  type="group"
                                  disabled
                                >
                                  <option value="" className="secondary-text-color" selected> Todos los grupos </option>
                                </Form.Control>
                              </Form.Group>
                            )
                          }
                        </Col>
                        <Col xs="12" md="4" lg="2" className="mb-2">
                          {
                            filterValue.group && groupData ? (
                              <Form.Group controlId="society" className="m-0">
                                <Form.Control
                                  className="text-muted"
                                  size="md"
                                  as="select"
                                  placeholder="Ingresa un correo"
                                  name="society"
                                  type="society"
                                  onChange={handleSelectFilter}
                                >
                                  <option value="" className="secondary-text-color" selected> Todas las sociedades </option>
                                  {
                                    groupData.societies.map((society) => {
                                      return (
                                        filterValue.society === society.uuid ? (
                                          <option value={society.uuid} key={society.uuid} selected>{society.society_business_name}</option>
                                        ) : (
                                          <option value={society.uuid} key={society.uuid}>{society.society_business_name}</option>
                                        )
                                      );
                                    })
                                  }
                                </Form.Control>
                              </Form.Group>
                            ) : (
                              <Form.Group controlId="society" className="m-0">
                                <Form.Control
                                  className="text-muted"
                                  size="md"
                                  as="select"
                                  name="society"
                                  type="society"
                                  disabled
                                >
                                  <option value="" className="secondary-text-color" selected> Todas las sociedades </option>
                                </Form.Control>
                              </Form.Group>
                            )
                          }
                        </Col>
                      </>
                    )
                  )
                }
                {
                  userProfile.profile && (
                    userProfile.profile.user_role === 'PROJECT_ADMIN' && (
                      <>
                        <Col xs="12" md="4" lg="2" className="mb-2">
                          {
                            groupsList !== 0 && (
                              <Form.Group controlId="group" className="m-0">
                                <Form.Control
                                  className="text-muted"
                                  size="md"
                                  as="select"
                                  name="group"
                                  type="group"
                                  onChange={handleSelectFilter}
                                >
                                  <option value="" className="secondary-text-color" selected> Todos los grupos </option>
                                  {
                                    groupsList.map((group) => {
                                      return (
                                        filterValue.group === group.uuid ? (
                                          <option value={group.uuid} key={group.uuid} selected>{group.group_name}</option>
                                        ) : (
                                          <option value={group.uuid} key={group.uuid}>{group.group_name}</option>
                                        )
                                      );
                                    })
                                  }
                                </Form.Control>
                              </Form.Group>
                            )
                          }
                        </Col>
                        <Col xs="12" md="4" lg="2" className="mb-2">
                          {
                            filterValue.group && groupData ? (
                              <Form.Group controlId="society" className="m-0">
                                <Form.Control
                                  className="text-muted"
                                  size="md"
                                  as="select"
                                  placeholder="Ingresa un correo"
                                  name="society"
                                  type="society"
                                  onChange={handleSelectFilter}
                                >
                                  <option value="" className="secondary-text-color" selected> Todas las sociedades </option>
                                  {
                                    groupData.societies.map((society) => {
                                      return (
                                        filterValue.society === society.uuid ? (
                                          <option value={society.uuid} key={society.uuid} selected>{society.society_business_name}</option>
                                        ) : (
                                          <option value={society.uuid} key={society.uuid}>{society.society_business_name}</option>
                                        )
                                      );
                                    })
                                  }
                                </Form.Control>
                              </Form.Group>
                            ) : (
                              <Form.Group controlId="society" className="m-0">
                                <Form.Control
                                  className="text-muted"
                                  size="md"
                                  as="select"
                                  name="society"
                                  type="society"
                                  disabled
                                >
                                  <option value="" className="secondary-text-color" selected> Todas las sociedades </option>
                                </Form.Control>
                              </Form.Group>
                            )
                          }
                        </Col>
                      </>
                    )
                  )
                }
                <Col xs="6" md="6" lg="3" className="mb-2">
                  <Form.Group controlId="user_role" className="m-0">
                    <Form.Control
                      className="text-muted"
                      size="md"
                      as="select"
                      placeholder="Ingresa un correo"
                      name="user_role"
                      type="user_role"
                      onChange={handleSelectFilter}
                    >
                      {
                        filterValue.user_role === "" ? (
                          <option value="" selected> Todos los usuarios </option>
                        ) : (
                          <option value=""> Todos los usuarios </option>
                        )
                      }
                      {
                        filterValue.user_role === "SUPER_ADMIN" ? (
                          <option value="SUPER_ADMIN" selected>Superadmin</option>
                        ) : (
                          <option value="SUPER_ADMIN">Superadmin</option>
                        )
                      }
                      {
                        filterValue.user_role === "PROJECT_ADMIN" ? (
                          <option value="PROJECT_ADMIN" selected>Admin de proyecto</option>
                        ) : (
                          <option value="PROJECT_ADMIN">Admin de proyecto</option>
                        )
                      }
                      {
                        filterValue.user_role === "GROUP_ADMIN" ? (
                          <option value="GROUP_ADMIN" selected>Admin de grupo</option>
                        ) : (
                          <option value="GROUP_ADMIN">Admin de grupo</option>
                        )
                      }
                      {
                        filterValue.user_role === "USER_GROUP" ? (
                          <option value="USER_GROUP" selected>Usuario grupo</option>
                        ) : (
                          <option value="USER_GROUP">Usuario grupo</option>
                        )
                      }
                      {
                        filterValue.user_role === "USER_SOCIETY" ? (
                          <option value="USER_SOCIETY" selected>Usuario sociedad</option>
                        ) : (
                          <option value="USER_SOCIETY">Usuario sociedad</option>
                        )
                      }
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs="6" lg="2">
                  <Form.Group controlId="ordering">
                    <Form.Control
                      className="text-muted"
                      size="md"
                      as="select"
                      name="ordering"
                      type="ordering"
                      onChange={handleSelectFilter}
                    >
                      <option className="secondary-text-color" disabled> Ordenar por: </option>

                      {
                        filterValue.ordering === "" ? (
                          <option value="" selected>Ultima edicion</option>
                        ) : (
                          <option value="">Ultima edicion</option>
                        )
                      }
                      {
                        filterValue.ordering === "first_name" ? (
                          <option value="first_name" selected>Nombre</option>
                        ) : (
                          <option value="first_name">Nombre</option>
                        )
                      }
                      {
                        filterValue.ordering === "last_name" ? (
                          <option value="last_name" selected>Apellido</option>
                        ) : (
                          <option value="last_name">Apellido</option>
                        )
                      }
                      {
                        filterValue.ordering === "email" ? (
                          <option value="email" selected>Email</option>
                        ) : (
                          <option value="email">Email</option>
                        )
                      }
                      {
                        filterValue.ordering === "is_active" ? (
                          <option value="is_active" selected>Estado</option>
                        ) : (
                          <option value="is_active">Estado</option>
                        )
                      }
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Collapse>


        </Col>

      </Row>






      <Table className='mt-4 mb-0'>

        <thead>
          <Row className='sub_header'>
            <Col xs="12" sm="12" lg="3" xl="2"><span>Grupos:</span></Col>
            <Col xs="12" sm="12" lg="3" xl="2"  ><span>Rol:</span></Col>
            <Col xs="12" sm="12" lg="3" xl="2"><span>Status:</span></Col>
            <Col xs="12" sm="12" lg="3" xl="2" ><span>Acciones:</span></Col>
          </Row>
        </thead>
        <tbody>
          {
            usersList.map((user) => {
              return (
                user.user.email ? (
                  <UsersItem
                    enable={user.user.enable}
                    key={user.user.uuid} uuid={user.user.uuid}
                    name={`${user.user.first_name} ${user.user.last_name}`}
                    email={user.user.email}
                    setFlagAction={setFlagAction}
                    role={user.profile.user_role}
                    patchUser={patchUser}
                    variant="warning" />

                ) : (
                  <UsersItem
                    enable={user.user.enable}
                    key={user.user.uuid} uuid={user.user.uuid}
                    name={`${user.user.first_name} ${user.user.last_name}`}
                    email="-"
                    setFlagAction={setFlagAction}
                    role={user.profile.user_role}
                    patchUser={patchUser}
                    fvariant="warning" />
                )
              );
            })
          }
        </tbody>
      </Table>

      <PaginationBar total={indexPagination} position={paginationPosition} setPosition={setPaginationPosition} />

      {
        invitationsList.length !== 0 && (
          <div className="mb-5">
            <Col sm="4" lg="5" className="d-flex align-items-center mt-5 p-0">
              <h4 className="font-weight-bold mb-1 mt-5">Invitaciones pendientes</h4>
            </Col>
            <Table>
              <thead>
                <Row className='sub_header'>
                  <Col xs="12" sm="12" lg="3" xl="4"><span>Invitado:</span></Col>
                  <Col xs="12" sm="12" lg="3" xl="2"  ><span>Rol:</span></Col>
                  <Col xs="12" sm="12" lg="3" xl="2"  ><span>Enviado:</span></Col>
                  <Col xs="12" sm="12" lg="3" xl="2" ><span>Dias:</span></Col>
                  <Col xs="12" sm="12" lg="3" xl="2" ><span>Acciones:</span></Col>
                </Row>
              </thead>
              <tbody>
                {
                  invitationsList.map((invitation) => {
                    return (
                      <InvitationItem enable={invitation.status} key={invitation.uuid} uuid={invitation.uuid} name={invitation.email} setFlagAction={setFlagAction} role={invitation.user_role} date={invitation.created} />
                    );
                  })
                }
              </tbody>
            </Table>

            <PaginationBar total={indexPaginationInvitations} position={paginationPositionInvitations} setPosition={setPaginationPaginationPositionInvitations} />
          </div>
        )
      }
      {
        paginationPosition === 1 && paginationPositionInvitations === 1 && (usersList.length >= 5 || invitationsList.length >= 5) && (
          <div className="small d-flex justify-content-start align-content-center">
            <span className="mt-1">Mostrar:</span>
            <Col xs={3} lg={1} className="d-flex">
              <Form.Group controlId="project" className="m-0">
                <Form.Control
                  className="text-muted"
                  as="select"
                  name="project"
                  type="project"
                  size="sm"
                  defaultValue={usersInScreen}
                  onChange={({ target }) => {
                    setUsersInScreen(target.value);
                  }}
                >
                  <option value={5} className="secondary-text-color" selected>5</option>
                  <option value={15} className="secondary-text-color">15</option>
                  <option value={30} className="secondary-text-color">30</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </div>
        )
      }
    </>

  )
}

UsersAdmin.propTypes = {
  patchUser: PropTypes.func.isRequired,
  showUsers: PropTypes.func.isRequired,
  usersList: PropTypes.array,
  invitationsList: PropTypes.array,
  paginationPosition: PropTypes.number,
  showGroup: PropTypes.func.isRequired,
  groupData: PropTypes.array,
  showProjects: PropTypes.func.isRequired,
  projectsList: PropTypes.array,
  showProject: PropTypes.func.isRequired,
  projectData: PropTypes.array,
  indexPaginationInvitations: PropTypes.number
};

const stateToProps = state => ({
  usersList: state.users.usersList,
  invitationsList: state.users.invitationsList,
  isLoaded: state.users.isLoaded,
  indexPagination: state.users.indexPagination,
  groupData: state.groups.groupData,
  indexPaginationInvitations: state.users.indexPaginationInvitations,
  projectsList: state.projects.projectsList,
  projectData: state.projects.projectData,
  userProfile: state.profile.userProfile,
  groupsList: state.groups.groupsList
});

const actionsToProps = dispatch => ({
  patchUser: payload => dispatch({ type: PATCH_USER, payload }),
  showUsers: payload => dispatch({ type: FETCH_LIST_USERS, payload }),
  showProjects: payload => dispatch({ type: FETCH_LIST_PROJECTS, payload }),
  showProject: payload => dispatch({ type: FETCH_PROJECT, payload }),
  showGroup: payload => dispatch({ type: FETCH_GROUP, payload }),
  showGroups: payload => dispatch({ type: FETCH_LIST_GROUPS, payload })
});

export default connect(stateToProps, actionsToProps)(UsersAdmin);
