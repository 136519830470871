import React, { useEffect, useState } from 'react';
import { Row, Breadcrumb, Col, Button, Form, FormControl, Spinner, Accordion, Card, FormGroup } from 'react-bootstrap';
import { Link, Prompt } from 'react-router-dom';
import { CheckCircle } from 'react-feather'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_GROUP_ADMINS, FETCH_LIST_GROUPS } from '../../store/group/types';
import { FETCH_LIST_PROJECTS } from '../../store/project/types';
import validator from '../../utils/validator';
import { registerSchema } from '../../utils/validator/schemas';
import { POST_INVITATION } from '../../store/user/types';
import { FETCH_LIST_SOCIETIES } from '../../store/society/types';

const CreateGroup =
  ({
    userProfile,
    postInvitation,
    loadingInvitation,
    groupsList,
    showProjects,
    showGroups,
    invitationErrors,
    showSocieties,
    societiesList,
    projectsList
  }) => {
  const [step, setStep] = React.useState(0);
  const [triggerForm, setTriggerForm] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [promptAux, setPromptAux] = useState(true);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [invitationForm, setInvitationForm] = useState({
    user_role: '',
    email: '',
    project: [],
    group: [],
    society: []
  });
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [auxProjectSelected, setAuxProjectSelected] = useState([]);
  const [auxGroupSelected, setAuxGroupSelected] = useState([]);
  const [auxSocietySelected, setAuxSocietySelected] = useState([]);
  const [projectFilters, setProjectFilters] = useState({
    projectSearch: "",
  });
  const [groupFilters, setGroupFilters] = useState({
    groupSearch: "",
    project: ""
  });
  const [societyFilters, setSocietyFilters] = useState({
    societySearch: "",
    project: "",
    group: ""
  });

  useEffect(() => {
    if(userProfile.profile){
      if(userProfile.profile.user_role === 'SUPER_ADMIN'){
        showProjects({ ...projectFilters });
      }
    }
  }, [showProjects, userProfile, projectFilters]);

    useEffect(() => {
      if(userProfile.profile){
        if(userProfile.profile.user_role === 'SUPER_ADMIN'){
          showGroups({ ...groupFilters });
        }
        if(userProfile.profile.user_role === 'PROJECT_ADMIN'){
          showGroups({ ...groupFilters });
        }
      }
    }, [showGroups, userProfile, groupFilters]);

    useEffect(() => {
      if(userProfile.profile){
        if(userProfile.profile.user_role === 'SUPER_ADMIN'){
          showSocieties({ ...societyFilters });
        }
        if(userProfile.profile.user_role === 'PROJECT_ADMIN'){
          showSocieties({ ...societyFilters });
        }
        if(userProfile.profile.user_role === 'GROUP_ADMIN'){
          showSocieties({ ...societyFilters });
        }
      }
    }, [showSocieties, userProfile, societyFilters]);

  useEffect(() => {
    window.onbeforeunload = (event) => {
      if(triggerForm === true){
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
      }
    };
  },);

  const handleInputChange = ({ target }) => {
    if(!triggerForm){
      setTriggerForm(true)
    }
    if(target.id === "projectsCheckbox" || target.id === "groupsCheckbox" || target.id === "societiesCheckbox"){
      if(target.id === "projectsCheckbox"){
        const formData = {...invitationForm}
        if(formData.project.includes(target.value)){
          const pos = formData.project.indexOf(target.value);
          formData.project.project(pos,1);
          const auxForm = auxProjectSelected;
          auxForm.splice(pos,1);
          setInvitationForm(formData);
          setAuxProjectSelected(auxForm);
        }else{
          formData.project.push(target.value);
          const aux = {name:target.name, value:target.value}
          setInvitationForm(formData);
          auxProjectSelected.push(aux);
        }
      }
      if(target.id === "groupsCheckbox"){
        const formData = {...invitationForm}
        if(formData.group.includes(target.value)){
          const pos = formData.group.indexOf(target.value);
          formData.group.splice(pos,1);
          const auxForm = auxGroupSelected;
          auxForm.splice(pos,1);
          setInvitationForm(formData);
          setAuxGroupSelected(auxForm);
        }else{
          formData.group.push(target.value);
          const aux = {name:target.name, value:target.value}
          setInvitationForm(formData);
          auxGroupSelected.push(aux);
        }
      }
      if(target.id === "societiesCheckbox"){
        const formData = {...invitationForm}
        if(formData.society.includes(target.value)){
          const pos = formData.society.indexOf(target.value);
          formData.society.splice(pos,1);
          const auxForm = auxSocietySelected;
          auxForm.splice(pos,1);
          setInvitationForm(formData);
          setAuxSocietySelected(auxForm);
        }else{
          formData.society.push(target.value);
          const aux = {name:target.name, value:target.value}
          setInvitationForm(formData);
          auxSocietySelected.push(aux);
        }
      }
    } else{
      const formData = { ...invitationForm, [target.name]: target.value };
      setInvitationForm(formData);
      delete validationError[target.name];
      setValidationError(validationError);
      if(target.name === "email"){
        const errors = validator({ [target.name]: registerSchema[target.name] }, formData);
        if (errors.length > 0) {
          const messages = errors
            .map(e => ({ [e.field]: e.message }))
            .reduce((prev, current) => ({ ...prev, ...current }), {});
          setValidationError({ ...validationError, ...messages });
          return false;
        }
      }
      if(target.name === 'user_role'){
        if(target.value === 'SUPER_ADMIN'){
          delete validationError[invitationForm.project];
          delete validationError[invitationForm.group];
          setInvitationForm({
            user_role: target.value,
            email:'',
            project: [],
            group: [],
            society: []
          });
          setProjectFilters({
            projectSearch: "",
          });
          setGroupFilters({
            groupSearch: "",
            project: ""
          });
          setSocietyFilters({
            societySearch: "",
            project: "",
            group: ""
          });
        }
        if(target.value === 'PROJECT_ADMIN'){
          delete validationError[invitationForm.group];
          setInvitationForm({
            user_role: target.value,
            email:'' ,
            project: [],
            group: [],
            society: []
          });
          setProjectFilters({
            projectSearch: "",
          });
          setGroupFilters({
            groupSearch: "",
            project: ""
          });
          setSocietyFilters({
            societySearch: "",
            project: "",
            group: ""
          });
        }
        if(target.value === 'GROUP_ADMIN'){
          delete validationError[invitationForm.project];
          setInvitationForm({
            user_role: target.value,
            email:'',
            project: [],
            group: [],
            society: []
          });
          setProjectFilters({
            projectSearch: "",
          });
          setGroupFilters({
            groupSearch: "",
            project: ""
          });
          setSocietyFilters({
            societySearch: "",
            project: "",
            group: ""
          });
        }
        if(target.value === 'USER_GROUP'){
          setInvitationForm({
            user_role: target.value,
            email:'',
            project: [],
            group: [],
            society: []
          });
          setProjectFilters({
            projectSearch: "",
          });
          setGroupFilters({
            groupSearch: "",
            project: ""
          });
          setSocietyFilters({
            societySearch: "",
            project: "",
            group: ""
          });
        }
        if(target.value === 'USER_SOCIETY'){
          setInvitationForm({
            user_role: target.value,
            email:'',
            project: [],
            group: [],
            society: []
          });
          setProjectFilters({
            projectSearch: "",
          });
          setGroupFilters({
            groupSearch: "",
            project: ""
          });
          setSocietyFilters({
            societySearch: "",
            project: "",
            group: ""
          });
        }
      }
    }
  };

  useEffect(() => {
    if(flag){
      if (!loadingInvitation && invitationErrors !== null) {
        setLoading(false);
      }
      if (loadingInvitation === false && invitationErrors === null) {
        setStep(step + 1);
        setFlag(false);
      }
    }
  }, [loadingInvitation, invitationErrors, flag, step]);


  const sendData = () => {
    const formData = invitationForm;
    if(formData.project.length === 0)
      delete formData.project;
    if(formData.group.length === 0)
      delete formData.group;
    if(formData.society.length === 0)
      delete formData.society;
    postInvitation(formData);
    setLoading(true);
    setPromptAux(false);
    setTriggerForm(false)
    setFlag(true);
  }

  useEffect(() => {
    if (
      Object.keys(invitationForm).some(key => invitationForm[key] === '')
    ) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [invitationForm]);

  const alert = () => {
    if (invitationErrors) {
      return (
        invitationErrors.map((error) => {
          return (<div key={error}>
            {error !== undefined ? (
              `-${error}`
            ) : (
              ''
            )}
          </div>);
        })
      );
    }
  };

  return (
    <Form>
      <Row className="mb-4">
        <Prompt
          when={triggerForm && promptAux}
          message={`¿Estás seguro de desear abandonar este formulario? Los datos ingresados no serán guardados.`}
        />
        <Col>
          <Breadcrumb className="small">
            <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
            <Breadcrumb.Item
              href={
                userProfile.profile && (
                  userProfile.profile.user_role === "SUPER_ADMIN" ? (
                    `/admin/users`
                  ):(
                    userProfile.profile.user_role === "PROJECT_ADMIN" ?(
                      `/admin/users#1%1?search=&project=${localStorage.project}&group=&society=&ordering=&user_role=`
                    ):(
                      `/admin/users#1%1?search=&project=${localStorage.group}&group=&society=&ordering=&user_role=`                    )
                  )
                )
              }
            >
              Administración de usuarios
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Invitar usuario</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {step === 0 && (
        <Row className="mb-4">
          <Col xl="5" lg="6" className="mx-auto">
            <h4 className="font-weight-bold mb-4">Datos principales</h4>
            <Form.Group controlId="user_role" onChange={handleInputChange}>
              <Form.Label className="font-weight-bold small">Tipo de usuario</Form.Label>
              {
                userProfile.profile && (
                  userProfile.profile.user_role === 'SUPER_ADMIN' ?(
                    <>
                      <Form.Check
                        type="radio"
                        label="Superadmin"
                        value="SUPER_ADMIN"
                        id="superAdmin"
                        name="user_role"
                      />
                      <Form.Check
                        type="radio"
                        label="Administrador de proyecto"
                        value="PROJECT_ADMIN"
                        id="projectAdmin"
                        name="user_role"
                      />
                    </>
                  ):(
                    ''
                  )
                )
              }
              {
                userProfile.profile && (
                  userProfile.profile.user_role === 'SUPER_ADMIN' || userProfile.profile.user_role === 'PROJECT_ADMIN' ?(
                    <Form.Check
                      type="radio"
                      label="Administrador de grupo"
                      value="GROUP_ADMIN"
                      id="groupAdmin"
                      name="user_role"
                    />
                  ):(
                    ''
                  )
                )
              }
                <Form.Check
                  type="radio"
                  label="Usuario grupo"
                  value="USER_GROUP"
                  id="groupUser"
                  name="user_role"
                />
                <Form.Check
                  type="radio"
                  label="Usuario sociedad"
                  value="USER_SOCIETY"
                  id="societyUser"
                  name="user_role"
                />
            </Form.Group>
            {

              userProfile.profile && (
                userProfile.profile.user_role === 'SUPER_ADMIN' ?(
                  invitationForm.user_role === 'SUPER_ADMIN' && (
                    <Form.Group controlId="username">
                      <Form.Label className="font-weight-bold small">Correo electrónico</Form.Label>
                      <FormControl
                        name="email"
                        type="email"
                        placeholder="Ingresa tu correo electrónico"
                        autoComplete="email"
                        onChange={handleInputChange}
                        isInvalid={validationError.email}
                        size="lg"
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )
                ):(
                  ''
                )
              )
            }
            {
              invitationForm.user_role === 'PROJECT_ADMIN' && (
                <div>
                  <Form.Group controlId="username">
                    <Form.Label className="font-weight-bold small">Correo electrónico</Form.Label>
                    <FormControl
                      name="email"
                      type="email"
                      placeholder="Ingresa tu correo electrónico"
                      autoComplete="email"
                      onChange={handleInputChange}
                      isInvalid={validationError.email}
                      size="lg"
                    />
                    <Form.Control.Feedback type="invalid">
                      {validationError.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label className="font-weight-bold small">
                    Asignar proyecto
                  </Form.Label>
                  <Accordion defaultActiveKey="1" className="mb-4">
                    <Card>
                      <Card.Header className="bg-transparent text-muted">
                        Seleccione
                      </Card.Header>
                      <Card.Body eventKey="1">
                        <Card.Body className="py-0">
                          <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                            <FormControl
                              className='mb-2'
                              size='md'
                              placeholder='Buscar...'
                              onChange={({target})=>setProjectFilters({ ...projectFilters, projectSearch: target.value })}
                            />
                          </FormGroup>
                          <Form.Group controlId="projectsCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                            {
                              projectsList.map( (project) =>{
                                return (
                                  <Form.Check type="checkbox" key={project.uuid} label={project.project_name} name={project.project_name} value={project.uuid} defaultChecked={invitationForm.project.includes(project.uuid)}/>
                                );
                              })
                            }
                          </Form.Group>
                        </Card.Body>
                      </Card.Body>
                    </Card>
                  </Accordion>
                </div>
              )
            }
            {
              invitationForm.user_role === 'GROUP_ADMIN' && (
                <div>
                  <Form.Group controlId="username">
                    <Form.Label className="font-weight-bold small">Correo electrónico</Form.Label>
                    <FormControl
                      name="email"
                      type="email"
                      placeholder="Ingresa tu correo electrónico"
                      autoComplete="email"
                      onChange={handleInputChange}
                      isInvalid={validationError.email}
                      size="lg"
                    />
                    <Form.Control.Feedback type="invalid">
                      {validationError.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label className="font-weight-bold small">
                    Asignar grupo
                  </Form.Label>
                  <Accordion defaultActiveKey="1" className="mb-4">
                    <Card>
                      <Card.Header className="bg-transparent text-muted">
                        Seleccione
                      </Card.Header>
                      <Card.Header className="bg-transparent">
                        <Form.Group controlId="project" className="m-0">
                          <Form.Control
                            size="md"
                            as="select"
                            placeholder="Ingresa un correo"
                            name="project"
                            type="project"
                            onChange={({target})=>setGroupFilters({ ...groupFilters, project: target.value })}
                          >
                            <option value="" className="secondary-text-color" selected> Todos los proyectos </option>
                            {
                              projectsList.map( (project) =>{
                                return (
                                  <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                                );
                              })
                            }
                          </Form.Control>
                        </Form.Group>
                      </Card.Header>
                      <Card.Body eventKey="1">
                        <Card.Body className="py-0">
                          <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                            <FormControl
                              className='mb-2'
                              size='md'
                              placeholder='Buscar...'
                              onChange={({target})=>setGroupFilters({ ...groupFilters, groupSearch: target.value })}
                            />
                          </FormGroup>
                          <Form.Group controlId="groupsCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                            {
                              groupsList.map( (group) =>{
                                return (
                                  <Form.Check type="checkbox" key={group.uuid} label={group.group_name} name={group.group_name} value={group.uuid} defaultChecked={invitationForm.group.includes(group.uuid)}/>
                                );
                              })
                            }
                          </Form.Group>
                        </Card.Body>
                      </Card.Body>
                    </Card>
                  </Accordion>
                </div>
              )
            }
            {
              invitationForm.user_role === 'USER_GROUP' && (
                <div>
                  <Form.Group controlId="username">
                    <Form.Label className="font-weight-bold small">Correo electrónico</Form.Label>
                    <FormControl
                      name="email"
                      type="email"
                      placeholder="Ingresa tu correo electrónico"
                      autoComplete="email"
                      onChange={handleInputChange}
                      isInvalid={validationError.email}
                      size="lg"
                    />
                    <Form.Control.Feedback type="invalid">
                      {validationError.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label className="font-weight-bold small">
                    Asignar grupo
                  </Form.Label>
                  <Accordion defaultActiveKey="1" className="mb-4">
                    <Card>
                      <Card.Header className="bg-transparent text-muted">
                        Seleccione
                      </Card.Header>
                      {
                        userProfile && (
                          userProfile.profile.user_role !== "GROUP_ADMIN" && (
                            <Card.Header className="bg-transparent">
                              <Form.Group controlId="project" className="m-0">
                                <Form.Control
                                  size="md"
                                  as="select"
                                  placeholder="Ingresa un correo"
                                  name="project"
                                  type="project"
                                  onChange={({target})=>setGroupFilters({ ...groupFilters, project: target.value })}
                                >
                                  <option value="" className="secondary-text-color" selected> Todos los proyectos </option>
                                  {
                                    projectsList.map( (project) =>{
                                      return (
                                        <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                                      );
                                    })
                                  }
                                </Form.Control>
                              </Form.Group>
                            </Card.Header>
                          )
                        )
                      }
                      <Card.Body eventKey="1">
                        <Card.Body className="py-0">
                          {
                            userProfile && (
                              userProfile.profile.user_role !== "GROUP_ADMIN" && (
                                <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                                  <FormControl
                                    className='mb-2'
                                    size='md'
                                    placeholder='Buscar...'
                                    onChange={({target})=>setGroupFilters({ ...groupFilters, groupSearch: target.value })}
                                  />
                                </FormGroup>
                              )
                            )
                          }
                          <Form.Group controlId="groupsCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                            {
                              groupsList.map( (group) =>{
                                return (
                                  <Form.Check type="checkbox" key={group.uuid} label={group.group_name} name={group.group_name} value={group.uuid} defaultChecked={invitationForm.group.includes(group.uuid)}/>
                                );
                              })
                            }
                          </Form.Group>
                        </Card.Body>
                      </Card.Body>
                    </Card>
                  </Accordion>
                </div>
              )
            }
            {
              invitationForm.user_role === 'USER_SOCIETY' && (
                <div>
                  <Form.Group controlId="username">
                    <Form.Label className="font-weight-bold small">Correo electrónico</Form.Label>
                    <FormControl
                      name="email"
                      type="email"
                      placeholder="Ingresa tu correo electrónico"
                      autoComplete="email"
                      onChange={handleInputChange}
                      isInvalid={validationError.email}
                      size="lg"
                    />
                    <Form.Control.Feedback type="invalid">
                      {validationError.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label className="font-weight-bold small">
                    Asignar grupo
                  </Form.Label>
                  <Accordion defaultActiveKey="1" className="mb-4">
                    <Card>
                      <Card.Header className="bg-transparent text-muted">
                        Seleccione
                      </Card.Header>
                      <Card.Header className="bg-transparent">
                        {
                          userProfile && (
                            userProfile.profile.user_role !== "GROUP_ADMIN" && (
                              <Form.Group controlId="project">
                                <Form.Control
                                  size="md"
                                  as="select"
                                  placeholder="Ingresa un correo"
                                  name="project"
                                  type="project"
                                  onChange={({target})=> {
                                    showGroups({groupSearch: "" ,project: target.value});
                                    setSocietyFilters({ ...societyFilters, project: target.value }
                                    )
                                    if(target.value === "")
                                      setSocietyFilters({...societyFilters, project: "", group: ""})
                                  }}
                                >
                                  <option value="" className="secondary-text-color" selected> Todos los proyectos </option>
                                  {
                                    projectsList.map( (project) =>{
                                      return (
                                        <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                                      );
                                    })
                                  }
                                </Form.Control>
                              </Form.Group>
                            )
                          )
                        }
                        <Form.Group controlId="group" className="m-0">
                          <Form.Control
                            size="md"
                            as="select"
                            placeholder="Ingresa un correo"
                            name="group"
                            type="group"
                            disabled={(societyFilters.project === "" && userProfile.profile.user_role !== "GROUP_ADMIN")}
                            onChange={({target})=>setSocietyFilters({ ...societyFilters, group: target.value })}
                          >
                            <option value="" className="secondary-text-color" selected> Todos los grupos </option>
                            {
                              groupsList.map( (group) =>{
                                return (
                                  <option value={group.uuid} key={group.uuid}>{group.group_name}</option>
                                );
                              })
                            }
                          </Form.Control>
                        </Form.Group>
                      </Card.Header>
                      <Card.Body eventKey="1">
                        <Card.Body className="py-0">
                          <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                            <FormControl
                              className='mb-2'
                              size='md'
                              placeholder='Buscar...'
                              onChange={({target})=>setSocietyFilters({ ...societyFilters, societySearch: target.value })}
                            />
                          </FormGroup>
                          <Form.Group controlId="societiesCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                            {
                              societiesList.map( (society) =>{
                                return (
                                  <Form.Check type="checkbox" key={society.uuid} label={society.society_business_name} name={society.society_business_name} value={society.uuid} defaultChecked={invitationForm.society.includes(society.uuid)}/>
                                );
                              })
                            }
                          </Form.Group>
                        </Card.Body>
                      </Card.Body>
                    </Card>
                  </Accordion>
                </div>
              )
            }
            {
              invitationErrors ? (
                <div className="alert alert-danger my-3 mx-auto" role="alert">
                  {alert()}
                </div>
              ) : null
            }
            <Button
              onClick={sendData}
              disabled={disableSubmit || loading || loadingInvitation}
              size="lg"
              block
            >
              {
                loading || loadingInvitation ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  ''
                )
              }{' '}
              Invitar usuario
            </Button>
          </Col>
        </Row>
      )}
      {step === 1 && (
        <Row>
          <Col xs="12" className="text-center">
            <CheckCircle size="48" className="text-success mb-4"/>
            <h5 className="font-weight-bold mb-4">Usuario invitado con éxito</h5>
            <Button
              className="mx-2"
              as={Link}
              to={
                userProfile && (
                  userProfile.profile.user_role === "SUPER_ADMIN" ? (
                    `/admin/users`
                  ):(
                    userProfile.profile.user_role === "PROJECT_ADMIN" ?(
                      `/admin/users#1%1?search=&project=${localStorage.project}&group=&society=&ordering=&user_role=`
                    ):(
                      `/admin/users#1%1?search=&project=${localStorage.group}&group=&society=&ordering=&user_role=`                    )
                  )
                )
              }
            >
              Ir a usuarios
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  )
}

CreateGroup.propTypes = {
  showGroupAdmins: PropTypes.func.isRequired,
  groupsList: PropTypes.array,
  groupAdminList: PropTypes.array,
  showProjects: PropTypes.func.isRequired,
  postInvitation: PropTypes.func.isRequired,
  loadingInvitation: PropTypes.bool,
  showSocieties: PropTypes.func.isRequired,
  societiesList: PropTypes.array
};

const stateToProps = state => ({
  groupAdminList: state.groups.groupAdminList,
  errors: state.groups.errors,
  userProfile: state.profile.userProfile,
  groupsList: state.groups.groupsList,
  loadingInvitation: state.users.loadingInvitation,
  invitationErrors: state.users.invitationErrors,
  societiesList: state.societies.societiesList,
  projectsList: state.projects.projectsList
});

const actionsToProps = dispatch => ({
  showGroupAdmins: payload => dispatch({ type: FETCH_GROUP_ADMINS, payload }),
  showProjects: payload => dispatch({ type: FETCH_LIST_PROJECTS, payload }),
  showGroups: payload => dispatch({ type: FETCH_LIST_GROUPS, payload }),
  postInvitation: payload => dispatch({ type: POST_INVITATION, payload }),
  showSocieties: payload => dispatch({ type: FETCH_LIST_SOCIETIES, payload })
});

export default connect(stateToProps, actionsToProps)(CreateGroup);
