import React from 'react';
import { SuccessRegister } from '../../components';

const SuccessRegisterView= () => {
  return (
    <div className="bg-light vh-100">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-lg-6 mx-auto">
            <SuccessRegister/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessRegisterView;
