import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TOKEN_CONFIRMATION } from '../../store/document/types';
import { Button, Card } from 'react-bootstrap';

const SharedFile = ({ getToken, stateToken, expiredToken }) => {
  const { token } = useParams();
  const history = useHistory();

  useEffect(() => {
    getToken(token);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(stateToken)
      window.location.href = `${process.env.REACT_APP_SERVICE_URL}api/v1/documents/share/download/?token=${token}&type=attachment`;
    }, [stateToken, token]);

  return (
    <>
      {
        expiredToken && (
          <Card className="border-0">
            <Card.Body className="text-center p-5">
              <img  alt="" className="mb-4" />
              <h3 className="py-2 font-weight-bold title-color text-center">
                Link expirado
              </h3>
              <p className="text-center">
                Este link se encuentra expirado, solicite uno nuevo.
              </p>
              <Button
                className="btn-rounded mt-4"
                variant="primary"
                onClick={() => history.push('/')}
              >
                Ir a inicio de sesión
              </Button>
            </Card.Body>
          </Card>
        )
      }
    </>
  );
};

SharedFile.propTypes = {
  getToken: PropTypes.func.isRequired,
  stateToken: PropTypes.bool,
  expiredToken: PropTypes.bool
};

const stateToProps = state => ({
  stateToken: state.documents.stateToken,
  expiredToken: state.documents.expiredToken
});

const actionsToProps = dispatch => ({
  getToken: payload => dispatch({ type: TOKEN_CONFIRMATION, payload })
})

export default connect(stateToProps, actionsToProps) (SharedFile);
