import { takeLatest, put, call } from 'redux-saga/effects';
import {
  deleteReport,
  getReportData,
  postReport,
  updateReport,
  getUserReports,
  getUserReportData,
  getModulesList,
  searchReports,
  getReportsRef
} from '../../network';
import {
  SET_DATA_LIST_REPORTS,
  IS_LOADED,
  INDEX_PAGINATION_REPORTS,
  FETCH_LIST_REPORTS,
  DELETE_REPORT,
  IS_LOADED_REPORT,
  SET_DATA_REPORT,
  FETCH_REPORT,
  LOADING_REPORT_FORM,
  SET_REPORT_ERRORS,
  POST_REPORTS,
  PATCH_REPORT,
  FETCH_USER_REPORTS,
  FETCH_USER_REPORT,
  SET_USER_DATA_LIST_REPORTS,
  SET_LIST_MODULES,
  FETCH_MODULES, 
  SET_DATA_LIST_REPORTS_DISABLED, 
  INDEX_PAGINATION_REPORTS_DISABLED,
  GET_REPORTS_REF,
  GET_REPORTS_REF_REDUCER
  
} from './types';
 import formsErrors from '../../utils/errors/formsErrors';
 import messageValidationFailed from '../../utils/helpers/messageValidationFailed';
import registerErrors from '../../utils/errors/formsErrors';

function* showReports({ payload }) {
  try {
    let response = "";
    let responseDisabled = "";
    const { queryValue, paginationPosition, paginationPositionDisabled, reportsInScreen } = payload;
    response = yield call(searchReports, `${queryValue}&enable=true&limit=${reportsInScreen}&offset=${(paginationPosition - 1) * reportsInScreen}`);
    responseDisabled = yield call(searchReports,`${queryValue}&enable=false&limit=${reportsInScreen}&offset=${(paginationPositionDisabled-1)*reportsInScreen}`);
    yield put({ type: IS_LOADED, payload: false })
    yield put({ type: SET_DATA_LIST_REPORTS, payload: response.data.results });
    yield put({ type: SET_DATA_LIST_REPORTS_DISABLED, payload: responseDisabled.data.results });
    yield put({ type: IS_LOADED, payload: true });
    yield put({ type: INDEX_PAGINATION_REPORTS, payload: Math.ceil(response.data.count/reportsInScreen) });
    yield put({ type: INDEX_PAGINATION_REPORTS_DISABLED, payload: Math.ceil(responseDisabled.data.count/reportsInScreen) });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* deleteReportData({ payload }) {
  try {
    yield call(deleteReport, payload);
    window.location.reload();
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showReport({ payload }) {
  try {
    const response = yield call(getReportData, payload);
    yield put({ type: IS_LOADED_REPORT, payload: false })
    yield put({ type: SET_DATA_REPORT, payload: response.data });
    yield put({ type: IS_LOADED_REPORT, payload: true })
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* postReports({ payload }) {
  let errors = [];
  try {
    yield put({ type: LOADING_REPORT_FORM, payload: true });
    yield call(postReport, payload);
    console.log(postReport, payload)
    yield put({ type: LOADING_REPORT_FORM, payload: false });
    yield put({ type: SET_REPORT_ERRORS, payload: null });
  } catch (e) {
    yield put({ type: LOADING_REPORT_FORM, payload: true });
    const { response } = e;
    if (response.status === 400) {
     
      if (response.data.report_name) {
        errors = [...errors, messageValidationFailed(response.data.report_name, 'nombre de reporte.')];
      }
      if (response.data.menu_name) {
        errors = [...errors, messageValidationFailed(response.data.menu_name, 'nombre en menu de reporte.')];
      }
      if (response.data.description) {
        errors = [...errors, messageValidationFailed(response.data.description, 'descripción de reporte.')];
      }
      if (response.data.url) {
        errors = [...errors, messageValidationFailed(response.data.url, 'URL de reporte.')];
      }
      if (response.data.aws_id_dashboard) {
        errors = [...errors, messageValidationFailed(response.data.aws_id_dashboard, 'AWS dashboard ID.')];
      }
      if (response.data.aws_username) {
        errors = [...errors, messageValidationFailed(response.data.aws_username, 'nombre de usuario de AWS.')];
      }
      if (response.data.image) {
        errors = [...errors, messageValidationFailed(response.data.image, 'imagen.')];
      }
      if (response.data.url) {
        errors = [...errors,registerErrors.URL_EXISTS];
      }


      if (response.data.aws_id_dashboard) {
        console.log('Entra en el error');
        //errors = [...errors, ...response.data.aws_id_dashboard, 'El ID del dashboard al parecer ya existe'].slice(0,1);
        errors = [...errors, 'El ID del dashboard al parecer ya existe'];
      }
      if (response.data.tool_type) {
        errors = [...errors,registerErrors.DATA_CONFLICT];
      }
      if (response.data.parameter) {
        errors = [...errors,registerErrors.DATA_CONFLICT_PARAMS];
      }
      yield put({ type: SET_REPORT_ERRORS, payload: errors });
      yield put({ type: LOADING_REPORT_FORM, payload: false });

    }





    if (response && response.status === 500) {
      yield put({ type: SET_REPORT_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: LOADING_REPORT_FORM, payload: false });
    }
    
    // TODO: handle error
    console.log(e);
  }
}

function* patchReports({ payload }) {
  let errors = [];
  try {
    yield put({ type: LOADING_REPORT_FORM, payload: true });
    yield call(updateReport, payload);
    yield put({ type: LOADING_REPORT_FORM, payload: false });
    yield put({ type: SET_REPORT_ERRORS, payload: null });
  } catch (e) {
    const { response } = e;
    if (response.status === 400) {
      if (response.data.report_name) {
        errors = [...errors, messageValidationFailed(response.data.report_name, 'nombre de reporte.')];
      }
      if (response.data.menu_name) {
        errors = [...errors, messageValidationFailed(response.data.menu_name, 'nombre en menu de reporte.')];
      }
      if (response.data.description) {
        errors = [...errors, messageValidationFailed(response.data.description, 'descripción de reporte.')];
      }
      if (response.data.url) {
        errors = [...errors, messageValidationFailed(response.data.url, 'URL de reporte.')];
      }
      if (response.data.aws_id_dashboard) {
        errors = [...errors, messageValidationFailed(response.data.aws_id_dashboard, 'AWS dashboard ID.')];
      }
      if (response.data.aws_username) {
        errors = [...errors, messageValidationFailed(response.data.aws_username, 'nombre de usuario de AWS.')];
      }
      if (response.data.url) {
        errors = [...errors,registerErrors.messageValidationFailed];
      }
      if (response.data.image) {
        errors = [...errors, messageValidationFailed(response.data.image, 'imagen.')];
      }
      yield put({ type: SET_REPORT_ERRORS, payload: errors });
      yield put({ type: LOADING_REPORT_FORM, payload: false });
    }
    if (response.status === 404) {
      if (response.data.aws_id_dashboard) {
        errors = [...errors,registerErrors.NOT_FOUNT];
      }
      if (response.data.tool_type) {
        errors = [...errors,registerErrors.DATA_CONFLICT];
      }
      if (response.data.parameter) {
        errors = [...errors,registerErrors.DATA_CONFLICT_PARAMS];
      }
      yield put({ type: SET_REPORT_ERRORS, payload: errors });
      yield put({ type: LOADING_REPORT_FORM, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_REPORT_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: LOADING_REPORT_FORM, payload: false });
    }
    // TODO: handle error
    console.log(e);
  }
}

function* showUserReports({ payload }) {
  try {
    const {project, group, society} = payload;
    let response;
    if(project)
      response = yield call(getUserReports, `project=${project}`);
    if(group)
      response = yield call(getUserReports, `group=${group}`);
    if(society)
      response = yield call(getUserReports, `society=${society}`);
    yield put({ type: IS_LOADED_REPORT, payload: false })
    yield put({ type: SET_USER_DATA_LIST_REPORTS, payload: response.data.results });
    yield put({ type: IS_LOADED_REPORT, payload: true })
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showUserReportsRef({ payload }) {
  try {
    
    const response = yield call(getReportsRef);
    yield put({ type: GET_REPORTS_REF_REDUCER, payload: response.data });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showUserReport({ payload }) {
  try {
    const response = yield call(getUserReportData, payload);
    yield put({ type: IS_LOADED_REPORT, payload: false })
    yield put({ type: SET_DATA_REPORT, payload: response.data });
    yield put({ type: IS_LOADED_REPORT, payload: true })
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showModulesList({payload}) {
  try {
    const response = yield call(getModulesList, payload);
    yield put({ type: SET_LIST_MODULES, payload: response.data.results });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* watchShowReports() {
  yield takeLatest(FETCH_LIST_REPORTS, showReports);
}

function* watchDeleteReportData() {
  yield takeLatest(DELETE_REPORT, deleteReportData);
}

function* watchShowReport() {
  yield takeLatest(FETCH_REPORT, showReport);
}

function* watchPostReports() {
  yield takeLatest(POST_REPORTS, postReports);
}

function* watchReportsGroup() {
  yield takeLatest(PATCH_REPORT, patchReports);
}

function* watchShowUserReports() {
  yield takeLatest(FETCH_USER_REPORTS, showUserReports);
}

function* watchShowUserReport() {
  yield takeLatest(FETCH_USER_REPORT, showUserReport);
}

function* watchShowModulesList() {
  yield takeLatest(FETCH_MODULES, showModulesList);
}

function* watchShowUserReportsRef() {
  yield takeLatest(GET_REPORTS_REF, showUserReportsRef);
}

const saga = [
  watchShowReports(),
  watchDeleteReportData(),
  watchShowReport(),
  watchPostReports(),
  watchReportsGroup(),
  watchShowUserReports(),
  watchShowUserReport(),
  watchShowModulesList(),
  watchShowUserReportsRef()
];

export default saga;
