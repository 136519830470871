import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import IndcatorCarrousel from '../components/indicadores/IndcatorCarrousel';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_USER_REPORTS } from '../store/report/types';
import { FETCH_USER_ACCOUNTING_LIST } from '../store/accounting/types';
import { FETCH_USER_PROJECTS } from '../store/project/types';
import { FETCH_USERS_GROUP } from '../store/group/types';
import { FETCH_USER_SOCIETY } from '../store/society/types';

// Images
import cuadro1 from '../images/cuadro1.png';
import cuadro2 from '../images/cuadro2.png';
import cuadro3 from '../images/cuadro3.png';
import cuadro4 from '../images/cuadro4.png';
import cuadro5 from '../images/cuadro5.png';
import loadingHome from '../images/loadingHomeView.png'



const HomeView = ({
  userProfile,
  showUserReports,
  reportsUserList,
  showUserAccountingList,
  accountingUserList,
  showUserProject,
  projectsList,
  showUserGroup,
  groupsList,
  showUserSociety,
  societiesList,
  userLoaded
}) => {

  const [userName, setName] = useState('')

  // Animación de texto
  const [text, setText] = useState('')
  const [opacity, setOpacity] = useState(1)
  const texts = [
    'Servicios integrales: RSM ofrece una amplia gama de servicios, incluyendo auditoría, consultoría fiscal y cumplimiento, y asesoramiento en transacciones.',
    'Presencia global: Con oficinas en todo el mundo, RSM brinda una presencia global que le permite a sus clientes obtener soluciones y servicios de alta calidad donde quiera que estén.',
    'Experiencia sectorial: RSM cuenta con un conocimiento y experiencia extensos en varios sectores, lo que le permite proporcionar soluciones especializadas y servicios a sus clientes.',
    'Cultura colaborativa: RSM tiene una cultura colaborativa que valora el trabajo en equipo y fomenta la comunicación abierta, lo que ayuda a garantizar el éxito de sus clientes.',
    'En resumen, RSM es una empresa de servicios profesionales de tamaño medio que se destaca por su compromiso con la excelencia, su capacidad para proporcionar soluciones personalizadas, y su presencia global.'
  ]
  let counter = 0


  useEffect(() => {
    const intervalId = setInterval(() => {
      setOpacity(0)
      setTimeout(() => {
        setText(texts[counter])
        setOpacity(counter % 1 === 0 ? 1 : 0)
        counter = (counter + 1) % texts.length
      }, 1500)
    }, 7000)
    return () => clearInterval(intervalId)
  }, [])

  setTimeout(() => {
    try {
      // Setea el nombre del usuario logado en el estado y muestra el primer index en mayuscula
      setName((userProfile.user.first_name).charAt(0).toLocaleUpperCase() + (userProfile.user.first_name).slice(1))
    } catch (error) {

    }
  }, 4500)

  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'PROJECT_ADMIN') {
        showUserProject();
      }
      if (userProfile.profile.user_role === 'GROUP_ADMIN' || userProfile.profile.user_role === 'USER_GROUP') {
        showUserGroup();
      }
      if (userProfile.profile.user_role === 'USER_SOCIETY') {
        showUserSociety();
      }
    }
  }, [showUserProject, showUserGroup, showUserSociety, userProfile]);

  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'PROJECT_ADMIN') {
        if (!localStorage.project || localStorage.project === "") {
          if (projectsList.length !== 0)
            localStorage.setItem('project', projectsList[0].uuid);
          else
            localStorage.setItem('project', "");
        }
      }
      if (userProfile.profile.user_role === 'GROUP_ADMIN' || userProfile.profile.user_role === 'USER_GROUP') {
        if (!localStorage.group || localStorage.group === "") {
          if (groupsList.length !== 0)
            localStorage.setItem('group', groupsList[0].uuid);
          else
            localStorage.setItem('group', "");
        }
      }
      if (userProfile.profile.user_role === 'USER_SOCIETY') {
        if (!localStorage.society || localStorage.society === "") {
          if (societiesList.length !== 0)
            localStorage.setItem('society', societiesList[0].uuid);
          else
            localStorage.setItem('society', "");
        }
      }
    }
  }, [userProfile, projectsList, groupsList, societiesList]);

  useEffect(() => {
    if (userLoaded) {
      if (userProfile.profile) {
        if (userProfile.profile.user_role === 'PROJECT_ADMIN') {
          if (localStorage.project) {
            showUserReports({ project: localStorage.project });
            showUserAccountingList({ project: localStorage.project });
          } else {
            if (projectsList.length !== 0) {
              showUserReports({ project: projectsList[0].uuid });
              showUserAccountingList({ project: projectsList[0].uuid });
            }
          }
        }
        if (userProfile.profile.user_role === 'GROUP_ADMIN' || userProfile.profile.user_role === 'USER_GROUP') {
          if (localStorage.group) {
            showUserReports({ group: localStorage.group });
            showUserAccountingList({ group: localStorage.group });
          } else {
            if (groupsList.length !== 0) {
              showUserReports({ project: groupsList[0].uuid });
              showUserAccountingList({ project: groupsList[0].uuid });
            }
          }
        }
        if (userProfile.profile.user_role === 'USER_SOCIETY') {
          if (localStorage.society) {
            showUserReports({ society: localStorage.society });
            showUserAccountingList({ society: localStorage.society });
          } else {
            if (societiesList.length !== 0) {
              showUserReports({ project: societiesList[0].uuid });
              showUserAccountingList({ project: societiesList[0].uuid });
            }
          }
        }
      }
    }
  }, [showUserReports, showUserAccountingList, userProfile, userLoaded, groupsList, projectsList, societiesList]);

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {

      setLoading(false)
    }, 4500)

  }, [])


  /*return (
    <h1>Teste</h1>
  )*/





  if (loading) {
    return (

      <div className="contenedor-principal-loading-two">

        <img src={loadingHome} alt='rsm loading' width={250} />

      </div>

    );

  } else {

    return (

      < div id="container-primary-home" >
      <div id="container-teste">
        <div className="container-admin bg-light" id='viewHome'>



      <div className="containerFlex">
            <Container className="py-5" fluid>
              <div className="row mt-5 d-flex">
                <div id="flexMsg">
                  <div className="col-6" id='titleHome'>
                    <h1 id='titleHomeView' style={{ color: 'white' }}>¡Hola {!userName ? 'Usuario' : userName}! <br></br><span style={{ fontSize: '28px', color: 'white' }}>Bienvenido a nuestro portal</span></h1>
                    <p id='text' style={{ opacity: opacity, color: 'white' }}>{text}</p>
                  </div>
                  <div className="col-6"></div>
                </div>
              </div>
            </Container>
          </div>

        </div>

        <div id='viewHome2'>
          <div className="iconsHome">
            <div className="col-2" id='iconsImg'>

              <img src={cuadro1} alt='...' />
              <img src={cuadro2}  alt='...' />
              <img src={cuadro3}  alt='...' />
              <img src={cuadro4}  alt='...' />
              <img src={cuadro5}  alt='...' />

            </div>
            <div className="d-flex flex-wrap justify-contente-center" id='contenedorPrincipalIndicador'>
              <IndcatorCarrousel />
            </div>
          </div>
      </div>
         
        </div>

      </div >



    )

    /*
    
      return (
        
      
    
    
          /**
           *   {
           *  <div className="contenedor-principal">
    
          <div className="container-admin bg-light" id='viewHome'>
    
            <div className="containerFlex">
              <Container className="py-5" fluid>
                <div className="row mt-5 d-flex">
                  <div id="flexMsg">
                  <div className="col-6" id='titleHome'>
                    <h1 id='titleHomeView' style={{color: 'white'}}>¡Hola {!userName ? 'Usuario' : userName }! <br></br><span style={{fontSize: '28px', color: 'white'}}>Bienvenido a nuestro portal</span></h1>
                    <p id='text' style={{ opacity: opacity , color: 'white'}}>{text}</p>
                  </div>
                  <div className="col-6"></div> 
                  </div>
                </div>
              </Container>
            </div>
    
          </div>
          
          <div id='viewHome2'>
            <div className="iconsHome">
              <div className="col-2" id='iconsImg'>
                
                <img src={cuadro1} width={215} alt='...'/>
                <img src={cuadro2} width={215} alt='...'/>
                <img src={cuadro3} width={215} alt='...'/>
                <img src={cuadro4} width={215} alt='...'/>
                <img src={cuadro5} width={215} alt='...'/>
                
              </div>
              <div className="d-flex flex-wrap justify-contente-center" id='contenedorPrincipalIndicador'>
              <IndcatorCarrousel />
            </div>
          </div>
          </div>
            
        </div>
          }
       
           
      
    
      );
    */

  }





}

HomeView.propTypes = {
  showUserReports: PropTypes.func.isRequired,
  reportsUserList: PropTypes.array,
  accountingUserList: PropTypes.array,
  projectsList: PropTypes.array,
  showUserGroup: PropTypes.func.isRequired,
  showUserSociety: PropTypes.func.isRequired,
  societiesList: PropTypes.array,
};

const actionsToProps = dispatch => ({
  showUserReports: payload => dispatch({ type: FETCH_USER_REPORTS, payload }),
  showUserAccountingList: payload => dispatch({ type: FETCH_USER_ACCOUNTING_LIST, payload }),
  showUserProject: () => dispatch({ type: FETCH_USER_PROJECTS }),
  showUserGroup: () => dispatch({ type: FETCH_USERS_GROUP }),
  showUserSociety: payload => dispatch({ type: FETCH_USER_SOCIETY, payload })
});

const stateToProps = state => ({
  userProfile: state.profile.userProfile,
  reportsUserList: state.reports.reportsUserList,
  accountingUserList: state.accounting.accountingUserList,
  projectsList: state.projects.projectsList,
  userLoaded: state.profile.userLoaded,
  groupsList: state.groups.groupsList,
  societiesList: state.societies.societiesList
});

export default connect(stateToProps, actionsToProps)(HomeView);

