import axios from 'axios';
import cookieHandler from '../../utils/helpers/cookie.handler';
import configServices from '../../config';
import eventBus from '../../utils/helpers/eventBus';

const authorization = http => ({
  authRequest: request => {
    const accessToken = cookieHandler().getAccessToken();
    if (accessToken) {
      request.headers = { Authorization: `Bearer ${accessToken}` };
    }
    return request;
  },

  authResponse: async error => {
    const { config, response } = error;
    const { loginURI } = configServices().services;
    
    if (response && response.status === 401 && config.url !== loginURI) {
      if (!window.refreshing) {
        const { getRefreshToken, setTokens, removeToken } = cookieHandler();
        
        window.refreshing = true;

        try {
          const refresh = await http.post(loginURI, {
            grant_type: 'refresh_token',
            refresh: getRefreshToken()
          });

          if (refresh && refresh.status === 200) {
            setTokens(refresh.data);
            axios.defaults.headers.common['Authorization'] = `Bearer ${refresh.data.access_token}`;
            window.refreshing = false;
            window.logout = false;
            eventBus.dispatch('refreshed');
            return http(config);
          }
          removeToken();
          throw new Error('unauthorized');
        } catch (e) {
          removeToken();
          window.refreshing = false;
          window.logout = true;
          return Promise.reject(error);
        }
      }

      return new Promise(resolve => {
        eventBus.addEventListener('refreshed', () => {
          eventBus.remove('refreshed');
          if (window.logout) {
            return Promise.reject(error);
          }
          resolve(http(config));
        });
      });
    }
    return Promise.reject(error);
  }
});

export default authorization;
