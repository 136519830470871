import { all } from 'redux-saga/effects';
import auth from './auth/saga';
import project from './project/saga';
import group from './group/saga';
import society from './society/saga';
import profile from './profile/saga';
import user from './user/saga';
import report from './report/saga';
import document from './document/saga';
import accounting from './accounting/saga';
import logs from './logs/saga';

export default function* rootSaga() {
  yield all([
    ...auth,
    ...project,
    ...group,
    ...society,
    ...profile,
    ...user,
    ...report,
    ...document,
    ...accounting,
    ...logs
  ]);
}
