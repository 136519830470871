import React, { useState } from 'react';
import { Button, Card, Form, FormControl, FormGroup, Modal, Spinner } from 'react-bootstrap';

const CreateFolder = ({ handleCreateFolder, handleInputChange, serviceError, newFolderLoaded }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="primary"
        size="lg"
        title="Subir archivo"
        block
        onClick={handleShow}
      >
        Crear carpeta
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton/>
        <Modal.Body>
          <Card.Body >
            <h3 className="font-weight-bold title-color text-left">
              Nueva carpeta
            </h3>
            <FormGroup className="position-relative" controlId="folder_name">
              <Form.Label className="font-weight-bold small">
                Nombre
              </Form.Label>
              <FormControl
                size="lg"
                name="folder_name"
                type="folder_name"
                placeholder="Ingrese nombre de carpeta"
                onChange={handleInputChange}
              />
            </FormGroup>
            {
              !newFolderLoaded && serviceError.length !== 0 && (
                serviceError.map(
                  (error) => {
                    return(<p key={error} className='text-danger'>{error}</p>)
                  }
                )
              )
            }
            <div className="justify-content-center d-flex">
              <Button
                className="btn-rounded col-12 base-btn-color"
                variant="primary"
                onClick={handleCreateFolder}
              >
                {
                  newFolderLoaded ?(
                    <Spinner animation="border" className="spinner-custom" />
                  ):(
                    'Crear'
                  )
                }
              </Button>
            </div>
          </Card.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateFolder;
