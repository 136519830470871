import React, { useEffect } from 'react';
import { Breadcrumb, Col, Row, Button, Table } from 'react-bootstrap'
import { ConfigProvider } from 'react-avatar';
import UsersItem from '../users_item';
import GroupItem from '../group_item';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_SOCIETY, FETCH_PDF } from '../../store/society/types';
import { useParams } from 'react-router-dom';
import { Copy } from 'react-feather';

const SocietyDetail = ({ showSociety, societyData, showSocietyPDF, userProfile }) => {
  const { uuid } = useParams();

  useEffect(() => {
    showSociety(uuid);
    console.log(societyData)
  }, [showSociety, uuid, societyData]);

  const handlePDF = () => {
    showSocietyPDF(uuid)
  }

  return (
    <>
      {
        societyData !== null ? (
            <>

              <Row id='projectsAdmin'>
                <Col className='p-0'>
                  <Breadcrumb className="small mt-3">
                    <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
                    <Breadcrumb.Item
                      href={
                        userProfile && (
                          userProfile.profile.user_role === "SUPER_ADMIN" ? (
                            `/admin/society`
                          ):(
                            userProfile.profile.user_role === "PROJECT_ADMIN" ?(
                              `/admin/society#1?search=&project=${localStorage.project}&group=`
                            ):(
                              `/admin/society#1?search=&project=&group=${localStorage.group}`
                            )
                          )
                        )
                      }
                    >
                      Administración sociedad
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Detalle sociedad</Breadcrumb.Item>
                    
                  </Breadcrumb>
                  <h4 className="font-weight-bold mb-1 ml-3">{societyData.society_business_name}</h4>
                </Col>

              <Col>
              {
                societyData && (
                  <Row>
                    <Col>
                      <Row className="float-sm-right ml-1 mt-3">
                        <div className="mt-3 mr-3">
                          <span className="font-weight-bold">ID:</span>
                          <span className="">{societyData.uuid.substring(0, 10)}...</span>
                        </div>
                        <Button
                          className="m-0 mt-2 mr-3"
                          variant="outline-primary"
                          onClick={() => {navigator.clipboard.writeText(societyData.uuid)}}
                        >
                          <Copy size={20}/>
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                )

              }
              </Col>

             
              </Row>
         
              <hr />
              
              <Row className="mb-2" id='projects_item'>
                <Col md="6" lg="4">
                  <h6 className="font-weight-bold">Giro de sociedad</h6>
                  <p className="small">{societyData.business_line}</p>
                </Col>

                <Col md="6" lg="4">
                  <h6 className="font-weight-bold">RUT</h6>
                  <p className="small">{societyData.society_rut ?(societyData.society_rut):("-")}</p>
                </Col>

                <Col md="6" lg="4">
                  <h6 className="font-weight-bold">Código de sociedad</h6>
                  <p className="small">{societyData.company_code}</p>
                </Col>

                {
                  societyData.society_address && (
                      <Col md="6" lg="4">
                        <h6 className="font-weight-bold">Dirección de sociedad</h6>
                        <p className="small">{societyData.society_address}</p>
                      </Col>
                  )
                }

                <Col md="6" lg="4">
                  <h6 className="font-weight-bold">Número de teléfono</h6>
                  <p className="small">{societyData.society_phone_number}</p>
                </Col>

                {
                  societyData.society_contact_email && (
                      <Col md="6" lg="4">
                        <h6 className="font-weight-bold">Email de contacto</h6>
                        <p className="small">{societyData.society_contact_email}</p>
                      </Col>
                  )
                }

                <Col md="6" lg="4">
                  <h6 className="font-weight-bold">Correo electrónico</h6>
                  <p className="small">{societyData.society_email}</p>
                </Col>

                <Col md="6" lg="4">
                  <h6 className="font-weight-bold">Moneda</h6>
                  <p className="small">{societyData.currency}</p>
                </Col>
              </Row>

              <Row id='projects_item'>
                <Col lg="12" id='backgroundHeader' className='mt-3' >
                  <h6 className="font-weight-bold ">Grupo Sociedad</h6>
                </Col>

                <Col xs="12">
                  <Table striped hover size="md">
                    <tbody>
                      <GroupItem name={societyData.group.group_name} uuid={societyData.group.uuid} readOnly="true"/>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              {
                societyData.user_society && (
                  societyData.user_society.length !== 0  && (
                    <>
                      <Row>
                        <Col lg="12" id='backgroundHeader' className='mt-3'>
                          <h6 className="font-weight-bold">Usuarios sociedad</h6>
                        </Col>
                      </Row>
                      {
                        societyData.user_society.map( (user) => {
                          return (
                            <Table striped hover size="md">
                              <tbody>
                                <tr>
                                  <UsersItem key={user.uuid} uuid={user.uuid} name={`${user.first_name} ${user.last_name}`} enable={user.is_active} readOnly="true"/>
                                </tr>
                              </tbody>
                            </Table>
                          );
                        })
                      }
                    </>
                  )
                )
              }
              <Row>
                <Col className="d-flex justify-content-end">
                  <Button variant="primary" onClick={handlePDF} size="lg">Imprimir info</Button>
                </Col>
              </Row>
            </>
        ):(
          ""
        )
      }
    </>
  )
}

SocietyDetail.propTypes = {
  showSociety: PropTypes.func.isRequired,
  societyData: PropTypes.object,
  isLoaded: PropTypes.bool.isRequired,
  showSocietyPDF: PropTypes.func.isRequired,
};

const stateToProps = state => ({
  societyData: state.societies.societyData,
  isLoaded: state.societies.isLoaded,
  userProfile: state.profile.userProfile
});

const actionsToProps = dispatch => ({
  showSociety: payload => dispatch({ type: FETCH_SOCIETY, payload }),
  showSocietyPDF: payload => dispatch({ type: FETCH_PDF, payload })
});

export default connect(stateToProps, actionsToProps)(SocietyDetail);
