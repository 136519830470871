import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Collapse, Form, Row, Table } from 'react-bootstrap';
import { ConfigProvider } from 'react-avatar';
import SocietyItem from '../society_item';
import SearchBar from '../search_bar';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_LIST_SOCIETIES } from '../../store/society/types';
import PaginationBar from '../pagination/paginationBar';
import { FETCH_LIST_PROJECTS, FETCH_PROJECT } from '../../store/project/types';
import { ChevronDown, Plus } from 'react-feather';
import { FETCH_LIST_GROUPS } from '../../store/group/types';

const SocietyAdmin = (
  {
    showSocieties,
    societiesList,
    indexPagination,
    projectData,
    showProject,
    projectsList,
    showProjects,
    userProfile,
    showGroups,
    groupsList
  }) => {
  const [flagAction, setFlagAction] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const [paginationPosition, setPaginationPosition] = useState(window.location.hash.substring(1, window.location.hash.indexOf("?")));
  const [queryValue, setQueryValue] = useState(window.location.hash.substring(window.location.hash.indexOf("?")));
  const [filterValue, setFilterValue] = useState({
    project: "",
    group: ""
  });
  const [societiesInScreen, setSocietiesInScreen] = useState(5);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'PROJECT_ADMIN') {
        showGroups({ valueSearch: { project: localStorage.project } });
      }
      if (userProfile.profile.user_role === 'GROUP_ADMIN') {
        showGroups({ valueSearch: { group: localStorage.group } });
      }
    }
  }, [showGroups, userProfile]);


  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'SUPER_ADMIN') {
        showProjects({ valueSearch: "", paginationPosition: null });
      }
    }
  }, [userProfile, showProjects]);

  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'PROJECT_ADMIN') {
        setFilterValue({ ...filterValue, project: localStorage.project })
      }
      if (userProfile.profile.user_role === 'GROUP_ADMIN') {
        setFilterValue({ ...filterValue, group: localStorage.group })
      }
    }// eslint-disable-next-line
  }, [userProfile]);

  useEffect(() => {
    showSocieties({ valueSearch, paginationPosition, filterValue, queryValue, societiesInScreen });
  }, [showSocieties, flagAction, valueSearch, paginationPosition, filterValue, societiesInScreen, queryValue]);

  const handleSearch = ({ target }) => {
    setPaginationPosition(1);
    setQueryValue(`?search=${target.value}&project=${filterValue.project}&group=${filterValue.group}`);
    setValueSearch(target.value);
  }

  useEffect(() => {
    if (!window.location.hash) {
      setPaginationPosition('1');
      setQueryValue("?search=&project=&group=");
    } else {
      setValueSearch(window.location.hash.substring(window.location.hash.indexOf("h=") + 2, window.location.hash.indexOf("&")));
      setFilterValue({
        project: window.location.hash.substring(window.location.hash.indexOf("t=") + 2, window.location.hash.indexOf("&gro", 2)),
        group: window.location.hash.substring(window.location.hash.indexOf("p=") + 2)
      })
    }
  }, [setPaginationPosition]);

  useEffect(() => {
    history.replace(`#${paginationPosition}?search=${valueSearch}&project=${filterValue.project}&group=${filterValue.group}`);
  }, [paginationPosition, valueSearch, filterValue, history]);

  useEffect(() => {
    if (filterValue.project) {
      showProject(filterValue.project);
    }
  }, [filterValue.project, showProject]);

  const handleSelectFilter = ({ target }) => {
    let formData = { ...filterValue, [target.name]: target.value };
    if (target.name === 'project')
      formData.group = "";
    setPaginationPosition(1);
    setFilterValue(formData);
    setQueryValue(`?search=${valueSearch}&project=${formData.project}&group=${formData.group}`);
  };

  return (

    <>

      <Row className='d-flex align-items-start justify-content-start' id='headerSociety'>

        <Col xs="10" sm="10" lg="3" xl="3" >
          <Breadcrumb className="small">
            <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
            <Breadcrumb.Item active>Administración Sociedad</Breadcrumb.Item>
          </Breadcrumb>
          <h4 className="font-weight-bold mb-4 mb-lg-0 ml-3 mt-4">Sociedad</h4>
        </Col>


        <Col xs="12" sm="12" lg="9" xl="9">

          <Row>
            <Col sm="12" className='d-block d-md-flex justify-content-end align-items-center mt-4'>


              {
                userProfile.profile && (
                  userProfile.profile.user_role === 'SUPER_ADMIN' && (
                    <>

                     
                        <Form.Group controlId="project" >
                          <Form.Control
                             className="text-muted mb-4 mt-3 mb-sm-0"
                            size="md"
                            as="select"
                            name="project"
                            type="project"
                            onChange={handleSelectFilter}
                            style={{ width: '13rem', marginRight: '-8px' }}
                          >
                            <option value="" className="secondary-text-color"> Todos los proyectos </option>
                            {
                              projectsList.map((project) => {
                                return (
                                  filterValue.project === project.uuid ? (
                                    <option value={project.uuid} key={project.uuid} selected>{project.project_name}</option>
                                  ) : (
                                    <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                                  )
                                );
                              })
                            }
                          </Form.Control>
                        </Form.Group>
                


                      <Col className='mt-3' sm="6" lg="4" xl="3" id='btnSociety'>
                        {
                          filterValue.project && projectData ? (
                            <Form.Group controlId="group">
                              <Form.Control
                                className="text-muted"
                                size="md"
                                as="select"
                                placeholder="Ingresa un correo"
                                name="group"
                                type="group"
                                onChange={handleSelectFilter}
                              
                              >
                                <option value="" className="secondary-text-color"> Todos los grupos </option>
                                {
                                  projectData.groups.map((group) => {
                                    return (
                                      filterValue.group === group.uuid ? (
                                        <option value={group.uuid} key={group.uuid} selected>{group.group_name}</option>
                                      ) : (
                                        <option value={group.uuid} key={group.uuid}>{group.group_name}</option>
                                      )
                                    );
                                  })
                                }
                              </Form.Control>
                            </Form.Group>
                          ) : (
                            <Form.Group controlId="group">
                              <Form.Control
                                className="text-muted "
                                size="md"
                                as="select"
                                name="group"
                                type="group"
                                disabled
                               
                              >
                                <option value="" className="secondary-text-color" selected> Todos los grupos </option>
                              </Form.Control>
                            </Form.Group>
                          )
                        }
                      </Col>


                      
                    </>
                  )
                )
              }

              {
                userProfile.profile && (
                  userProfile.profile.user_role === 'PROJECT_ADMIN' && (
                    <Col sm="6" lg="4" xl="3" className='mt-3'>
                      {
                        groupsList.length !== 0 && (
                          <Form.Group controlId="group">
                            <Form.Control
                              className="text-muted"
                              size="md"
                              as="select"
                              placeholder="Ingresa un correo"
                              name="group"
                              type="group"
                              onChange={handleSelectFilter}
                              style={{ width: '13rem'}}
                            >
                              <option value="" className="secondary-text-color"> Todos los grupos </option>
                              {
                                groupsList.map((group) => {
                                  return (
                                    filterValue.group === group.uuid ? (
                                      <option value={group.uuid} key={group.uuid} selected>{group.group_name}</option>
                                    ) : (
                                      <option value={group.uuid} key={group.uuid}>{group.group_name}</option>
                                    )
                                  );
                                })
                              }
                            </Form.Control>
                          </Form.Group>
                        )
                      }
                    </Col>
                  )
                )
              }


              <SearchBar handleSearch={handleSearch} defaultValue={decodeURI(valueSearch).length === 1 ? (`${decodeURI(valueSearch)} `) : (decodeURI(valueSearch))} />
              <Button as={Link} to="/admin/society/create"  variant="primary" size="md" className='mt-3 mt-md-0'><Plus size={18} ></Plus>Crear</Button>


            </Col>
          </Row>

        </Col>


      </Row>







      <Table size="md" className='mt-4 mb-5'>
      <thead>   
      <Row className='sub_header'>
        <Col xs="12" sm="12" lg="3" xl="2"><span>Grupos:</span></Col>
        <Col xs="12" sm="12" lg="3" xl="2"  ><span>Rut:</span></Col>
        <Col xs="12" sm="12" lg="3" xl="2"><span>Proyecto:</span></Col>
        <Col xs="12" sm="12" lg="3" xl="2" ><span>Acciones:</span></Col>
      </Row>
      </thead>
        <tbody>
          {
            societiesList.map((society) => {
              return (
                <SocietyItem
                  setFlagDelete={setFlagAction}
                  key={society.uuid}
                  name={society.society_business_name}
                  rut={society.society_rut}
                  project={society.project}
                  variant={society.primary_color}
                  group={society.group}
                  uuid={society.uuid}
                  enabled={society.enable}
                />

              )
            })
          }
        </tbody>
      </Table>

      <PaginationBar total={indexPagination} position={paginationPosition} setPosition={setPaginationPosition} />
      {
        paginationPosition === 1 && societiesList.length >= 5 && (
          <div className="small d-flex justify-content-center align-content-center">
            <span className="mt-1">Mostrar:</span>
            <Col className="mb-2 d-flex">
              <Form.Group controlId="project" className="m-0">
                <Form.Control
                  className="text-muted"
                  as="select"
                  name="project"
                  type="project"
                  size="sm"
                  onChange={({ target }) => {
                    setSocietiesInScreen(target.value);
                  }}
                >
                  <option value={5} className="secondary-text-color" selected>5</option>
                  <option value={15} className="secondary-text-color">15</option>
                  <option value={30} className="secondary-text-color">30</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </div>
        )
      }
    </>

  )
}

SocietyAdmin.propTypes = {
  showProject: PropTypes.func.isRequired,
  projectData: PropTypes.array,
  showSocieties: PropTypes.func.isRequired,
  societiesList: PropTypes.array,
  isLoaded: PropTypes.bool.isRequired,
  indexPagination: PropTypes.number,
  showProjects: PropTypes.func.isRequired,
  projectsList: PropTypes.array,
  showGroups: PropTypes.func.isRequired
};

const stateToProps = state => ({
  projectData: state.projects.projectData,
  societiesList: state.societies.societiesList,
  isLoaded: state.societies.isLoaded,
  indexPagination: state.societies.indexPagination,
  projectsList: state.projects.projectsList,
  userProfile: state.profile.userProfile,
  groupsList: state.groups.groupsList,
});

const actionsToProps = dispatch => ({
  showSocieties: payload => dispatch({ type: FETCH_LIST_SOCIETIES, payload }),
  showProject: payload => dispatch({ type: FETCH_PROJECT, payload }),
  showProjects: payload => dispatch({ type: FETCH_LIST_PROJECTS, payload }),
  showGroups: payload => dispatch({ type: FETCH_LIST_GROUPS, payload })
});

export default connect(stateToProps, actionsToProps)(SocietyAdmin);
