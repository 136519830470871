import { combineReducers } from 'redux';
import auth from './auth/reducer';
import projects from './project/reducer';
import groups from './group/reducer';
import societies from './society/reducer';
import profile from './profile/reducer';
import users from './user/reducer';
import reports from './report/reducer';
import documents from './document/reducer';
import accounting from './accounting/reducer';
import logs from './logs/reducer';

export default combineReducers({
  auth,
  projects,
  groups,
  societies,
  profile,
  users,
  reports,
  documents,
  accounting,
  logs
});
