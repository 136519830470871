import React, { useEffect, useState } from 'react';
import {
  Row,
  Breadcrumb,
  Col,
  Button,
  Form,
  FormGroup,
  Dropdown,
  Spinner,
  Accordion,
  Card,
  FormControl
} from 'react-bootstrap';
import { CheckCircle, ChevronRight } from 'react-feather';
import { HexColorPicker } from "react-colorful";
import { Link, Prompt } from 'react-router-dom';
import ImageUpload from '../image_upload';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_PROJECT_ADMINS, POST_PROJECTS } from '../../store/project/types';
import validator from '../../utils/validator';
import { projectSchema } from '../../utils/validator/schemas';

const CreateProject = ({postProjects, showProjectAdmins, projectAdminList, loadingForm, errors}) => {
  const [step, setStep] = React.useState(0);
  const [color, setColor] = React.useState("#aabbcc");
  const [colorSecondary, setColorSecondary] = React.useState("#aabbcc");
  const [logo, setLogo] = useState([]);
  const [image, setImage] = useState([]);
  const [icon, setIcon] = useState([]);
  const [auxImage, setAuxImage] = useState(false);
  const [auxLogo, setAuxLogo] = useState(false);
  const [auxIcon, setAuxIcon] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [triggerForm, setTriggerForm] = useState(false);
  const [promptAux, setPromptAux] = useState(true);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [serviceError, setServiceError] = useState(null);
  const [projectFormOne, setProjectFormOne] = useState({
    project_name: '',
    client_name: '',
    associated_service_description: '',
    service_description: '',
    admin: []
  });
  const [projectFormTwo, setProjectFormTwo] = useState({
    // Si quiero hacerl obligatorio nuevamento dejo estado vacio
    meta_title: 'default',
    meta_content: 'default'
  });
  const [auxProjectSelected, setAuxProjectSelected] = useState([]);
  const [valueSearch, setValueSearch] = useState("");

  useEffect(() => {
    window.onbeforeunload = (event) => {
      if(triggerForm === true){
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
      }
    };
  },);

  useEffect(() => {
    showProjectAdmins(valueSearch);
  }, [showProjectAdmins, valueSearch]);

  useEffect(() => {
    if(step === 0){
      if (
        Object.keys(projectFormOne).some(key => projectFormOne[key] === '')
      ) {
        setDisableSubmit(true);
      } else {
        setDisableSubmit(false);
      }
    }
    if(step === 1){
      /*if (
        Object.keys(validationError).length ||
        Object.keys(projectFormTwo).some(key => projectFormTwo[key] === '')
      ) {
        setDisableSubmit(true);
      } else {
       
      }*/
      setDisableSubmit(false);
    }
  }, [validationError, projectFormOne, projectFormTwo, step]);

  const handleInputChange = ({ target }) => {
    if(target.id === "adminsCheckbox"){
      const formData = {...projectFormOne}
      if(formData.admin.includes(target.value)){
        const pos = formData.admin.indexOf(target.value);
        formData.admin.splice(pos,1);
        const auxForm = auxProjectSelected;
        auxForm.splice(pos,1);
        setProjectFormOne(formData);
        setAuxProjectSelected(auxForm);
      }else{
        formData.admin.push(target.value);
        const aux = {name:target.name, value:target.value}
        setProjectFormOne(formData);
        auxProjectSelected.push(aux);
      }
    }
    if(target.name === "primary_color")
      setColor(target.value);
    if(target.name === "secondary_color")
      setColorSecondary(target.value);
    if(!triggerForm){
      setTriggerForm(true);
    }
    if(step === 0){
      let formData = { ...projectFormOne, [target.name]: target.value };
      setProjectFormOne(formData);
    }
    if(step === 1){
      let formData = { ...projectFormTwo, [target.name]: target.value };
      setProjectFormTwo(formData);
      if(target.name === "url_own_site" ||
        target.name === "custom_portal_url" ||
        target.name === "contact_email" ||
        target.name === "contact_phone"){
        setValidationError({ ...validationError, [target.name]: '' });
        const errors = validator({ [target.name]: projectSchema[target.name] }, formData);
        if (errors.length > 0) {
          const messages = errors
            .map(e => ({...validationError, [e.field]: e.message }))
            .reduce((prev, current) => ({ ...prev, ...current }), {});
          setValidationError(messages);
          return false;
        }
        delete validationError[target.name];
        setValidationError(validationError);
      }
    }
  };

  const onChange = nextStep => {
    setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
  };
  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);

  const sendData = () => {
    setPromptAux(false);
    setTriggerForm(false);
    setServiceError(null);
    setLoading(true);
    const projectForm = ({ ...projectFormOne, ...projectFormTwo });
    const fd = new FormData();
    const arrKeys = Object.keys(projectForm);
    const arrValues = Object.values(projectForm);
    let i = 0;

    arrKeys.forEach( o => {
      fd.append(o, arrValues[i] );
      i++;
    })
    if(auxImage){
      fd.append('image', image );
    }
    if(auxLogo){
      fd.append('icon', icon );
    }
    if(auxIcon){
      fd.append('logo', logo );
    }
    fd.append('primary_color', color );
    fd.append('secondary_color', colorSecondary );

    postProjects(fd);
    setServiceError(errors);
    setFlag(true);
  }

  useEffect(() => {
    setServiceError(null);
  }, [errors]);

  useEffect(() => {
    if(flag){
      if (!loadingForm && errors !== null) {
        setServiceError(errors);
        setLoading(false);
      }
      if (loadingForm === false && errors === null) {
        setStep(step + 1);
        setFlag(false);
      }
    }
  }, [loadingForm, errors, flag, step]);

  const alert = () => {
    if (serviceError) {
      return (
        serviceError.map((error) => {
          return (<div key={error}>
            {error !== undefined ? (
              `-${error}`
            ) : (
              ''
            )}
          </div>);
        })
      );
    }
  };

  const handleSearch = ({ target }) => {
    setValueSearch(target.value);
  }

  return (
    <Form>
      <Row className="mb-4">
          <Prompt
            when={triggerForm && promptAux}
            message={`¿Estás seguro de desear abandonar este formulario? Los datos ingresados no serán guardados.`}
          />
        <Col>
          <Breadcrumb className="small">
            <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin/projects">
              Administración proyectos
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Crear proyecto</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md="8" lg="6" className="mx-auto">
          <div className="steps d-flex justify-content-between align-items-center">
            <div className={`step-item ${step === 0 ? 'active' : ''}`}>
              <span className="step-item-indicator mr-3 small">1</span>
              <h6 className="step-item-title mb-0 small">Datos principales</h6>
            </div>
            <ChevronRight size="12" />
            <div className={`step-item ${step === 1 ? 'active' : ''}`}>
              <span className="step-item-indicator mr-3 small">2</span>
              <h6 className="step-item-title mb-0 small">Login</h6>
            </div>
            <ChevronRight size="12" />
            <div className={`step-item ${step === 2 ? 'active' : ''}`}>
              <span className="step-item-indicator mr-3 small">3</span>
              <h6 className="step-item-title mb-0 small">Diseño</h6>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md="8" lg="6" className="mx-auto">
          {step === 0 && (
            <>
              <h5 className="font-weight-bold mb-4">Datos principales</h5>
              <FormGroup controlId="project_name">
                <Form.Label className="font-weight-bold small">Nombre del proyecto
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa un nombre"
                  name="project_name"
                  type="project_name"
                  onChange={handleInputChange}
                  defaultValue={projectFormOne.project_name}
                />
              </FormGroup>
              <FormGroup controlId="client_name">
                <Form.Label className="font-weight-bold small">Nombre de cliente
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa un nombre"
                  name="client_name"
                  type="client_name"
                  onChange={handleInputChange}
                  defaultValue={projectFormOne.client_name}
                />
              </FormGroup>
              <FormGroup controlId="associated_service_description">
                <Form.Label className="font-weight-bold small">Descripción del servicio asociado
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa una descripción"
                  name="associated_service_description"
                  type="associated_service_description"
                  onChange={handleInputChange}
                  defaultValue={projectFormOne.associated_service_description}
                />
              </FormGroup>
              <FormGroup controlId="service_description">
                <Form.Label className="font-weight-bold small">Descripción del servicio
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  size="lg"
                  placeholder="Ingresa una descripción"
                  name="service_description"
                  type="service_description"
                  onChange={handleInputChange}
                  defaultValue={projectFormOne.service_description}
                />
              </FormGroup>
              {
                (projectAdminList.length !== 0 || valueSearch !== "") && (
                  <>
                    <Form.Label className="font-weight-bold small">
                      Designar Administrador
                    </Form.Label>
                    <Accordion defaultActiveKey="1" >
                      <Card>
                        <Card.Header className="bg-transparent text-muted">
                          Seleccione
                        </Card.Header>
                        <Card.Body eventKey="1">
                          <Card.Body className="py-0">
                            <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                              <FormControl
                                className='mb-2'
                                size='md'
                                placeholder='Buscar...'
                                onChange={handleSearch}
                              />
                            </FormGroup>
                            <Form.Group controlId="adminsCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                              {
                                projectAdminList.map( (admin) =>{
                                  return (
                                    <Form.Check type="checkbox" key={admin.uuid} label={`${admin.first_name} ${admin.last_name}`} name={`${admin.first_name} ${admin.last_name}`} value={admin.uuid} defaultChecked={projectFormOne.admin.includes(admin.uuid)}/>
                                  );
                                })
                              }
                            </Form.Group>
                          </Card.Body>
                        </Card.Body>
                      </Card>
                    </Accordion>
                  </>
                )
              }
              <span className="text-danger">
                (*)
                <span className="text-black-50">
                  Campos obligatorios
                </span>
              </span>
            </>
          )}
          {step === 1 && (
            <>
              <h5 className="font-weight-bold mb-4">Login</h5>
              <FormGroup controlId="meta_title">
                <Form.Label className="font-weight-bold small">Meta tittle
                {
                  /**
                   * <span className="ml-1 text-danger small">
                    (*)
                  </span>
                   */
                }
                  
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa un título"
                  name="meta_title"
                  type="meta_title"
                  onChange={handleInputChange}
                  defaultValue={projectFormTwo.meta_title}
                  
                />
              </FormGroup>
              <FormGroup controlId="meta_content">
                <Form.Label className="font-weight-bold small">Meta content
                {
                  /**
                   * <span className="ml-1 text-danger small">
                    (*)
                  </span>
                   */
                }
                  
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa un contenido"
                  name="meta_content"
                  type="meta_content"
                  onChange={handleInputChange}
                  defaultValue={projectFormTwo.meta_content}
                 
                />
              </FormGroup>
              <FormGroup controlId="url_own_site">
                <Form.Label className="font-weight-bold small">Url sitio proyecto</Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa una url. Ej:https://..."
                  name="url_own_site"
                  type="url_own_site"
                  onChange={handleInputChange}
                  isInvalid={validationError.url_own_site}
                  defaultValue={projectFormTwo.url_own_site}
                />
                <Form.Control.Feedback type="invalid">
                  {validationError.url_own_site}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup controlId="custom_portal_url">
                <Form.Label className="font-weight-bold small">Url sitio custom portal</Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa una url. Ej:https://..."
                  name="custom_portal_url"
                  type="custom_portal_url"
                  onChange={handleInputChange}
                  isInvalid={validationError.custom_portal_url}
                  defaultValue={projectFormTwo.custom_portal_url}
                />
                <Form.Control.Feedback type="invalid">
                  {validationError.custom_portal_url}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup controlId="header_title">
                <Form.Label className="font-weight-bold small">Título header</Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa un título"
                  name="header_title"
                  type="header_title"
                  onChange={handleInputChange}
                  defaultValue={projectFormTwo.header_title}
                />
              </FormGroup>
              <FormGroup controlId="footer_disclaimer">
                <Form.Label className="font-weight-bold small">Derecho de autor</Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa derecho de autor"
                  name="footer_disclaimer"
                  type="footer_disclaimer"
                  onChange={handleInputChange}
                  defaultValue={projectFormTwo.footer_disclaimer}
                />
              </FormGroup>
              <FormGroup controlId="contact_email">
                <Form.Label className="font-weight-bold small">Mail contacto</Form.Label>
                <Form.Control
                  type="contact_email"
                  size="lg"
                  placeholder="Ej: correo@email.com"
                  name="contact_email"
                  onChange={handleInputChange}
                  isInvalid={validationError.contact_email}
                  defaultValue={projectFormTwo.contact_email}
                />
                <Form.Control.Feedback type="invalid">
                  {validationError.contact_email}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup controlId="contact_phone">
                <Form.Label className="font-weight-bold small">Número contacto</Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="EJ: +56988888888"
                  name="contact_phone"
                  type="contact_phone"
                  onChange={handleInputChange}
                  isInvalid={validationError.contact_phone}
                  defaultValue={projectFormTwo.contact_phone}
                />
                <Form.Control.Feedback type="invalid">
                  {validationError.contact_phone}
                </Form.Control.Feedback>
              </FormGroup>
              <Form.Group controlId="image">
                <Form.Label className="font-weight-bold small">Imagen</Form.Label>
                <ImageUpload
                  setLogo={setImage}
                  logo={image}
                  imgName={'image'}
                  auxImage={auxImage}
                  setAuxImage={setAuxImage}
                />
              </Form.Group>
              <span className="text-danger">
                (*)
                <span className="text-black-50">
                  Campos obligatorios
                </span>
              </span>
            </>
          )}
          {step === 2 && (
            <>
              <h5 className="font-weight-bold mb-4">Diseño</h5>
              <FormGroup controlId="logo">
                <Form.Label className="font-weight-bold small">Logo</Form.Label>
                <ImageUpload
                  setLogo={setLogo}
                  logo={logo}
                  imgName={'logo'}
                  auxLogo={auxLogo}
                  setAuxLogo={setAuxLogo}
                />
              </FormGroup>
              <FormGroup controlId="icon">
                <Form.Label className="font-weight-bold small">Icono</Form.Label>
                <ImageUpload
                  setLogo={setIcon}
                  logo={icon}
                  imgName={'icon'}
                  auxIcon={auxIcon}
                  setAuxIcon={setAuxIcon}
                />
              </FormGroup>
              <Row className="mb-3">
                <Col lg="12">
                  <Form.Label className="font-weight-bold small">Código de color primario</Form.Label>
                </Col>
                <Col xs="9">
                  <FormGroup className="mb-0 position-relative" controlId="primary_color">
                    <Form.Control
                      size="lg"
                      placeholder="Selecciona un color"
                      value={color}
                      name="primary_color"
                      type="primary_color"
                      onChange={handleInputChange}
                      defaultValue={color}
                    />
                    <div className="color-box-result" style={{ backgroundColor: color }}/>
                  </FormGroup>
                </Col>
                <Col xs="3">
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic" size="lg" block>
                      <i className="far fa-eye-dropper"/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="border-0 p-0 my-1">
                      <HexColorPicker color={color} onChange={setColor} />
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <Form.Label className="font-weight-bold small">Código de color secundario</Form.Label>
                </Col>
                <Col xs="9">
                  <FormGroup className="mb-0 position-relative" controlId="secondary_color">
                    <Form.Control
                      size="lg"
                      placeholder="Selecciona un color"
                      value={colorSecondary}
                      name="secondary_color"
                      type="secondary_color"
                      defaultValue={colorSecondary}
                      onChange={handleInputChange}
                    />
                    <div className="color-box-result" style={{ backgroundColor: colorSecondary }}/>
                  </FormGroup>
                </Col>
                <Col xs="3">
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic" size="lg" block>
                      <i className="far fa-eye-dropper"/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="border-0 p-0 my-1">
                      <HexColorPicker color={colorSecondary} onChange={setColorSecondary}  />
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </>
          )}
          {step === 3 && (
            <Row>
              <Col xs="12" className="text-center">
                <CheckCircle size="48" className="text-success mb-4"/>
                <h5 className="font-weight-bold">Proyecto creado con éxito</h5>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      {
        serviceError ? (
          <div className="alert alert-danger my-3 col-lg-6 col-md-8 mx-auto" role="alert">
            {alert()}
          </div>
        ) : null
      }
      <Row className="d-flex justify-content-center mb-5">
        {step > 0 && step < 3 && (
          <Button variant="light" className="mx-2" onClick={onPrevious}>Atrás</Button>
        )}
        {step === 3 ?(
          <Button className="mx-2" as={Link} to="/admin/projects">Ir a proyectos</Button>
        ) :(
          step === 2 ?
            (
              <Button
                className="mx-2"
                onClick={sendData}
                disabled={ loading || loadingForm}
              >
                {
                  loading || loadingForm ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  ) : (
                    ''
                  )
                }{' '}
                Crear proyecto
              </Button>
            ):(
              <Button className="mx-2" onClick={onNext} disabled={disableSubmit}>Siguiente</Button>
            )
        )}
      </Row>
    </Form>
  )
}

CreateProject.propTypes = {
  postProjects: PropTypes.func.isRequired,
  showProjectAdmins: PropTypes.func.isRequired,
  projectAdminList: PropTypes.array,
  loadingForm: PropTypes.bool,
  errors: PropTypes.array
};

const stateToProps = state => ({
  projectAdminList: state.projects.projectAdminList,
  loadingForm: state.projects.loadingForm,
  errors: state.projects.errors
});

const actionsToProps = dispatch => ({
  postProjects: payload => dispatch({ type: POST_PROJECTS, payload }),
  showProjectAdmins: payload => dispatch({ type: FETCH_PROJECT_ADMINS, payload })
});

export default connect(stateToProps, actionsToProps)(CreateProject);
