import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { Trash } from 'react-feather';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DELETE_SOCIETY } from '../../store/society/types';
import { DELETE_LOG } from '../../store/logs/types';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';

const SocietyDelete = ({ setFlagDelete, uuid, deleteSocietyData, logDelete }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    logDelete(false);
    setShow(false);
  };
  const handleShow = () => {
    logDelete(true);
    setShow(true);
  };

  const handleDelete = () => {
    handleClose();
    setFlagDelete(true);
    deleteSocietyData(uuid);
  }

  return (
    <>
      <DropdownItem
        data-toggle="tooltip"
        data-placement="top"
        title="Eliminar sociedad"
        variant="link"
        size="sm"
        className="text-danger font-weight-bold"
        onClick={handleShow}
      >
        Eliminar
      </DropdownItem>


      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton/>
        <Modal.Body>
          <Card.Body className=" text-center">
            <Trash className='text-danger' size="26"></Trash>
            <h3 className="py-2 font-weight-bold title-color text-center">
              Eliminar Sociedad
            </h3>
            <p className="text-center mb-0">
              ¿Estás seguro que deseas eliminar esta Sociedad?</p>

            <Button
              className="btn-rounded base-btn-color mt-4"
              variant="primary"
              size="md"
              onClick={handleDelete}
            >
              Eliminar
            </Button>
          </Card.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

SocietyDelete.propTypes = {
  deleteSocietyData: PropTypes.func.isRequired,
  logDelete: PropTypes.func.isRequired
};

const actionsToProps = dispatch => ({
  deleteSocietyData: payload => dispatch({ type: DELETE_SOCIETY, payload }),
  logDelete: payload => dispatch({ type: DELETE_LOG, payload })
});

export default connect(null, actionsToProps)(SocietyDelete);
