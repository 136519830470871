import config from '../config';

const { groupURI, groupAdminURI, groupsPdfURI, groupSearchURI, groupUsersList, userGroupsURI } = config().services;

const network = client => ({
  getGroups: payload => client.get(`${groupURI}${payload}`),
  postGroup: payload => client.post(groupURI, { ...payload }),
  updateGroup: ({ formData, uuid }) => client.patch(`${groupURI}${uuid}/`, formData),
  getGroupData: payload => client.get(`${groupURI}${payload}/`),
  getGroupAdmins: payload => client.get(`${groupAdminURI}?limit=100&search=${payload}`),
  getGroupUsers: payload => client.get(`${groupUsersList}?limit=100&search=${payload}`),
  deleteGroup: payload => client.delete(`${groupURI}${payload}/`),
  getGroupPDF: payload => client.get(`${groupsPdfURI}${payload}`),
  searchGroup: (payload) => client.get(`${groupSearchURI}${payload}`),
  getUserGroups: () => client.get(`${userGroupsURI}?limit=100000`)
});

export default network;
