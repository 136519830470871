import React, { useEffect, useState } from 'react';
import { Button, Card, Form, FormControl, FormGroup, Modal, Spinner } from 'react-bootstrap';
import { Plus } from 'react-feather';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { POST_INDUSTRY } from '../../store/society/types';

const CreateIndustry = ({ postIndustryObject, errors, industryLoading }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [flag, setFlag] = useState(false);
  const [ industryForm, setIndustryForm ] = useState({})

  useEffect(() => {
    if(!industryLoading && errors.length === 0 && flag) {
      handleClose();
      setFlag(false);
    }
  }, [industryLoading, errors, flag]);

  const handleInputChange = ({ target }) => {
    const formData = { ...industryForm, [target.name]: target.value };
    setIndustryForm(formData);
  };

  return (
    <>
      <Button
        variant="outline-secondary"
        size="xs"
        title="Crear industria"
        data-toggle="tooltip"
        data-placement="top"
        onClick={handleShow}
      >
        <Plus/>
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton/>
        <Modal.Body>
          <Card.Body >
            <h3 className="font-weight-bold title-color text-left">
              Crear industria
            </h3>
            <FormGroup className="position-relative" controlId="industry">
              <Form.Label className="font-weight-bold small">
                Nombre de industria
              </Form.Label>
              <FormControl
                size="lg"
                name="industry"
                type="industry"
                placeholder="Ingrese nombre de industria"
                onChange={handleInputChange}
              />
            </FormGroup>
            {
              !industryLoading && errors.length !== 0 && (
                errors.map(
                  (error) => {
                    return(<p key={error} className='text-danger'>{error}</p>)
                  }
                )
              )
            }
            <div className="justify-content-center d-flex">
              <Button
                className="btn-rounded col-12 base-btn-color"
                variant="primary"
                onClick={ () => {
                  postIndustryObject(industryForm);
                  setFlag(true);
                }}
              >
                {
                  industryLoading ?(
                    <Spinner animation="border" className="spinner-custom" />
                  ):(
                    'Guardar'
                  )
                }
              </Button>
            </div>
          </Card.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

CreateIndustry.propTypes = {
  patchFolder: PropTypes.func.isRequired,
};

const stateToProps = state => ({
  errors: state.societies.industryErrors,
  industryLoading: state.societies.industryLoading
});

const actionsToProps = dispatch => ({
  postIndustryObject: payload => dispatch({ type: POST_INDUSTRY, payload })
});

export default connect(stateToProps, actionsToProps)(CreateIndustry);
