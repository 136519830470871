import config from '../config';

const { usersURI, userSearchURI, invitationURI, importCsvURI, invitationsURI, invitationsSearchURI } = config().services;

const network = client => ({
  getUserList: payload => client.get(`${usersURI}${payload}`),
  searchUser: (payload) => client.get(`${userSearchURI}${payload}`),
  sendInvitation: payload => client.post(invitationURI, { ...payload }),
  registerCsv: payload => client.post(importCsvURI, payload ),
  getUserData: payload => client.get(`${usersURI}${payload}/`),
  deleteUser: payload => client.delete(`${usersURI}${payload}/`),
  //updateUser: ({ userForm, uuid }) => client.patch(`${usersURI}${uuid}/`, userForm),
  updateUser: ({ userForm, uuid }) => {
    client.patch(`${usersURI}${uuid}/`, userForm);
  },
  getInvitationsList: payload => client.get(`${invitationsURI}${payload}`),
  deleteInvitation: payload => client.delete(`${invitationsURI}${payload}/`),
  resendInvitation: payload => client.put(`${invitationsURI}${payload}/`),
  getInvitationsSearchList: payload => client.get(`${invitationsSearchURI}${payload}`),
});

export default network;
