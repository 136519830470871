import React, { useEffect, useState } from 'react';
import Logo from '../../images/logo-color.svg';
import PropTypes from 'prop-types';
import { Navbar, Nav, Dropdown, Image, Button } from 'react-bootstrap';
import { ConfigProvider } from 'react-avatar';
import NotificationItem from '../notification_item';
import { Bell, ChevronLeft, HelpCircle, Phone } from 'react-feather';
import { REMOVE_LAST_BREADCRUMP } from '../../store/document/types';
import { connect } from 'react-redux';
import { POST_LOGOUT } from '../../store/auth/types';
import logo from '../../images/avatar-icon.svg';
import ZohoModal from './zohoModal';
import { useHistory } from 'react-router-dom';
import LoadingBtn from '../loading/LoadingBtn';
import { Link } from 'react-router-dom';
import AlfredRedirectButton from './alfredRedirectButton';

const NavbarWeb = ({breaDcrumbFucntion, userProfile, logout, notifications, currentPath }) => {
  const [loading, setLoading] = useState(false)
  const [scroll, setScroll] = useState(false);
  const history = useHistory();

  
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 30);
    });
  }, []);

  window.addEventListener('DOMContentLoaded', event => {
    // Se mostra el contenido despues de 6 segundos mientras se carga, muestra un loading
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 4500)
  })

  function handleBack(){
    breaDcrumbFucntion()
  }

  if (loading) {
    return (
      <ConfigProvider colors={['red', 'green', 'blue']}>
      <Navbar expand="lg" fixed="top" className={`bg-light ${scroll ? 'shadow-sm' : ''}`}>
        <div className="container-fluid px-0 container-admin">
          <div className="d-flex">
  
            <Button variant="link" id="sidebarToggle" style={{display: 'none'}}>
              <i className="far fa-bars fa-lg"/>
            </Button>
            <LoadingBtn />
            
            <Image src={Logo} alt="logo" fluid className="p-0 pl-3" width="50%" onClick={()=>{window.location.href="/home"}} style={{cursor:"pointer"}}/>
          </div>
          <div className="d-flex align-items-center">
            {
              currentPath !== "/home" && (
                <Button onClick={()=>{
                  history.goBack();
                  handleBack()
                }} variant="light" className="mr-1 d-flex">
                  <ChevronLeft className="mr-3"/>
                  <h6 className="mb-0 mt-1">Volver</h6>
                </Button>
              )
            }
          </div>
          <Nav className="ml-auto d-flex align-items-center">
            <>
              <Dropdown>
                <Dropdown.Toggle className="btn-notification" variant="default" id="dropdown-basic">
                  <p className='small mb-0 d-inline'>Necesito Ayuda</p>
                  <HelpCircle size={20}/>
                </Dropdown.Toggle>
  
                <Dropdown.Menu align="end" className="border-0 shadow">
                  <Dropdown.Item className='d-flex align-items-center' target="_blank" href={'https://wa.me/message/KBUU3CI3PK7GB1'}>
                    <Phone className='mr-2' size="20"></Phone>
                    <p className='mb-0 small'>Enviar Whatsapp</p>
                  </Dropdown.Item>
  
                  <Dropdown.Divider/>
  
                  <ZohoModal type={1}/>
  
                </Dropdown.Menu>
              </Dropdown>
  
              <Dropdown>
                <Dropdown.Toggle className="btn-notification" variant="default" id="dropdown-basic">
                  <Bell size={20}/>
                </Dropdown.Toggle>
  
                <Dropdown.Menu align="end" className="menu-notifications border-0 shadow">
                  {
                    userProfile && (
                      notifications.length !== 0 ? (
                        notifications.map((notification, n) =>{
                          return(
                            <div key={notification.document_name}>
                              <NotificationItem name={notification.document_name} folder={notification.folder.uuid} user_role={userProfile.profile.user_role}/>
                              {
                                n !== notifications.length-1 && (
                                  <Dropdown.Divider/>
                                )
                              }
                            </div>
                          )
                        })
                      ):(
                        <div className="ml-2">No hay notificaciones</div>
                      )
                    )
                  }
                </Dropdown.Menu>
              </Dropdown>
  
              <Dropdown>
                <Dropdown.Toggle className="btn-avatar" variant="link" id="dropdown-basic">
                  {
                    userProfile.profile ? (
                      userProfile.profile.image ? (
                        <Image src={`${process.env.REACT_APP_SERVICE_URL}${userProfile.profile.image}`} roundedCircle className="mr-2" style={{width:"35px",height:"35px"}}/>
                      ):(
                        <Image src={logo} className="mr-2"/>
                      )
                    ):(
                      <Image src={logo} className="mr-2"/>
                    )
                  }
                  {
                    userProfile.user &&
                    <span className="d-none d-sm-inline-block text-capitalize">{`${userProfile.user.first_name} ${userProfile.user.last_name}`}</span>
                  }
  
                </Dropdown.Toggle>
  
                <Dropdown.Menu alignRight className="border-0 shadow">
                  {
                    //<Dropdown.Item href="/userinfo">Perfil</Dropdown.Item>
                  }
  
                  <Dropdown.Item href="/changePassword">Cambiar contraseña</Dropdown.Item>
                  <Dropdown.Item
                    onClick={logout} className="text-danger font-weight-bold">Cerrar sesión</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          </Nav>
        </div>
      </Navbar>
    </ConfigProvider>
    )
  }else{
    return (
      <ConfigProvider colors={['red', 'green', 'blue']}>
      <Navbar expand="lg" fixed="top" className={`bg-light ${scroll ? 'shadow-sm' : ''}`}>
        <div className="container-fluid px-0 container-admin">
          <div className="d-flex">
  
            <Button variant="link" id="sidebarToggle">
              <i className="far fa-bars fa-lg"/>
            </Button>
            
            <Image src={Logo} alt="logo" fluid className="p-0 pl-3" width="50%" onClick={()=>{window.location.href="/home"}} style={{cursor:"pointer"}}/>
          </div>
          <div className="d-flex align-items-center">
            {
              currentPath !== "/home" && (
                <Button onClick={()=>{
                  history.goBack();
                  handleBack()
                }} variant="light" className="mr-1 d-flex">
                  <ChevronLeft className="mr-3"/>
                  <h6 className="mb-0 mt-1">Volver</h6>
                </Button>
              )
            }
          </div>
          <Nav className="ml-auto d-flex align-items-center">
            <>

            {/*
              window.innerWidth < 1024 && (
                <a href='/chatbot'><Button className='btnAssistentVirtual'>Asistente virtual</Button></a>
              )
            */}
   
              <AlfredRedirectButton />

              <Dropdown>
                <Dropdown.Toggle className="btn-notification" variant="default" id="dropdown-basic">
                  <p className='small mb-0 d-inline'>Necesito Ayuda </p>
                  <HelpCircle size={20}/>
                </Dropdown.Toggle>
  
                <Dropdown.Menu align="end" className="border-0 shadow">
                  <Dropdown.Item className='d-flex align-items-center' target="_blank" href={'https://wa.me/message/KBUU3CI3PK7GB1'}>
                    <Phone className='mr-2' size="20"></Phone>
                    <p className='mb-0 small'>Enviar Whatsapp</p>
                  </Dropdown.Item>
  
                  <Dropdown.Divider/>
  
                  <ZohoModal type={1}/>
  
                </Dropdown.Menu>
              </Dropdown>
  
              <Dropdown>
                <Dropdown.Toggle className="btn-notification" variant="default" id="dropdown-basic">
                  <Bell size={20}/>
                </Dropdown.Toggle>
  
                <Dropdown.Menu align="end" className="menu-notifications border-0 shadow">
                  {
                    userProfile && (
                      notifications.length !== 0 ? (
                        notifications.map((notification, n) =>{
                          return(
                            <div key={notification.document_name}>
                              <NotificationItem key={notification.document_name} name={notification.document_name} folder={notification.folder.uuid} user_role={userProfile.profile.user_role}/>
                              {
                                n !== notifications.length-1 && (
                                  <Dropdown.Divider/>
                                )
                              }
                            </div>
                          )
                        })
                      ):(
                        <div className="ml-2">No hay notificaciones</div>
                      )
                    )
                  }
                </Dropdown.Menu>
              </Dropdown>
  
              <Dropdown>
                <Dropdown.Toggle className="btn-avatar" variant="link" id="dropdown-basic">
                  {
                    userProfile.profile ? (
                      userProfile.profile.image ? (
                        <Image src={`${process.env.REACT_APP_SERVICE_URL}${userProfile.profile.image}`} roundedCircle className="mr-2" style={{width:"35px",height:"35px"}}/>
                      ):(
                        <Image src={logo} className="mr-2"/>
                      )
                    ):(
                      <Image src={logo} className="mr-2"/>
                    )
                  }
                  {
                    userProfile.user &&
                    <span className="d-none d-sm-inline-block text-capitalize">{`${userProfile.user.first_name} ${userProfile.user.last_name}`}</span>
                  }
  
                </Dropdown.Toggle>
  
                <Dropdown.Menu alignRight className="border-0 shadow">
                  {
                    //<Dropdown.Item href="/userinfo">Perfil</Dropdown.Item>
                  }
  
                  <Dropdown.Item href="/changePassword">Cambiar contraseña</Dropdown.Item>
                  <Dropdown.Item
                    onClick={logout} className="text-danger font-weight-bold">Cerrar sesión</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          </Nav>
        </div>
      </Navbar>
    </ConfigProvider>
    )
  }

  
    

};

NavbarWeb.propTypes = {
  isLoggedIn: PropTypes.bool,
  profile: PropTypes.object,
  logout: PropTypes.func,
  notifications: PropTypes.array
};

const stateToProps = state => ({
  userProfile: state.profile.userProfile,
  userLoaded: state.profile.userLoaded,
  notifications: state.profile.notifications,
  bradCrumpList: state.documents.breadCrump
});

const actionsToProps = dispatch => ({
  logout: payload => dispatch({ type: POST_LOGOUT, payload }),
  breaDcrumbFucntion: payload => dispatch({ type: REMOVE_LAST_BREADCRUMP, payload})
});

export default connect(stateToProps, actionsToProps)(NavbarWeb);
