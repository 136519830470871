import React from 'react'
import {Button} from 'react-bootstrap';
import { File } from 'react-feather';
import { Link } from 'react-router-dom';

const NotificationItem = props => {
  return (
    <Button
      className="media text-left w-100"
      variant="default"
      as={Link}
      to={props.user_role === "SUPER_ADMIN" ? (
        `/admin/documents/${props.folder}`
      ):(
        `/documents_user/${props.folder}`
      )}
    >
      <div className="p-2 bg-light rounded-8 mr-1">
        <File size={20}/>
      </div>

      <div className="media-body">
        <div className='d-flex align-items-center justify-content-between'>
          <div className=''>
            <h6 className="mb-0">Archivo añadido</h6>
            <p className='small text-muted mb-0'>{props.name}</p>
          </div>
          {/*<p className='small text-muted mb-0'><Clock size='14' /> Hace 15 min</p>*/}
        </div>
      </div>
    </Button>
  )
}

export default NotificationItem;
