import {
  SET_DATA_LIST_SOCIETIES,
  IS_LOADED,
  SET_DATA_SOCIETY,
  IS_LOADED_SOCIETY,
  SET_LIST_SOCIETY_ADMINS,
  INDEX_PAGINATION_SOCIETIES,
  SET_SOCIETY_ERRORS,
  LOADING_SOCIETY_FORM,
  SOCIETY_USER_LIST,
  INDUSTRIES_LIST, INDUSTRY_DATA, INDUSTRY_LOADED, INDUSTRY_ERRORS, INDUSTRY_LOADING, SET_LIST_SOCIETY_USERS
} from './types';
const initialState = {
  societiesList: [],
  societyData:null,
  isLoaded:false,
  isLoadedSociety: false,
  societyAdminList: [],
  indexPagination:0,
  errors: null,
  loadingForm: false,
  societyUserList: [],
  industryList: [],
  industryData: {},
  industryLoaded: false,
  industryErrors: [],
  industryLoading: false,
  societyUsers: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case SET_DATA_LIST_SOCIETIES:
    return { ...state, societiesList: payload };
  case SET_DATA_SOCIETY:
    return { ...state, societyData: payload };
  case SET_LIST_SOCIETY_ADMINS:
    return { ...state, societyAdminList: payload }
  case IS_LOADED:
    return { ...state, isLoaded: payload };
  case LOADING_SOCIETY_FORM:
    return { ...state, loadingForm: payload };
  case SET_SOCIETY_ERRORS:
    return { ...state, errors: payload };
  case IS_LOADED_SOCIETY:
    return { ...state, isLoadedSociety: payload };
  case SOCIETY_USER_LIST:
    return { ...state, societyUserList: payload };
  case INDEX_PAGINATION_SOCIETIES:
    return { ...state, indexPagination: payload };
  case INDUSTRIES_LIST:
    return { ...state, industryList: payload };
  case INDUSTRY_DATA:
    return { ...state, industryData: payload };
  case INDUSTRY_LOADED:
    return { ...state, industryLoaded: payload };
  case INDUSTRY_ERRORS:
    return { ...state, industryErrors: payload };
  case INDUSTRY_LOADING:
    return { ...state, industryLoading: payload };
  case SET_LIST_SOCIETY_USERS:
    return { ...state, societyUsers: payload };
  default:
    return state;
  }
};

export default reducer;
