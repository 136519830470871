import React, { useEffect, useState } from 'react';
import { Row, Breadcrumb, Col, Button, Form, Spinner, FormControl, Accordion, Card, FormGroup } from 'react-bootstrap';
import { Prompt, useParams } from 'react-router-dom';
import { CheckCircle } from 'react-feather';
import PropTypes from 'prop-types';
import {
  FETCH_LIST_GROUPS, FETCH_LIST_PROJECTS, FETCH_LIST_SOCIETIES,
  FETCH_USER,
  PATCH_GROUP, PATCH_USER
} from '../../store/types';
import { connect } from 'react-redux';
import validator from '../../utils/validator';
import { loginSchema, registerSchema } from '../../utils/validator/schemas';

const EditGroup = (
  {
    errors,
    loadingForm,
    showUser,
    userData,
    isLoadedUser,
    patchUser,
    groupsList,
    showGroups,
    showSocieties,
    societiesList,
    userProfile,
    showProjects,
    projectsList
  }) => {
  const [step, setStep] = React.useState(0);
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);

  // Aqui setea el rol
  const [userForm, setUserForm] = useState({
    group: [],
    society: []
  });

  const [disableSubmit, setDisableSubmit] = useState(false);
  const [promptAux, setPromptAux] = useState(true);
  const [triggerForm, setTriggerForm] = useState(false);
  const [flag, setFlag] = useState(false);
  const [serviceError, setServiceError] = useState(null);
  const [validationError, setValidationError] = useState({});
  const [groupFilters, setGroupFilters] = useState({
    groupSearch: "",
    project: ""
  });
  const [societyFilters, setSocietyFilters] = useState({
    societySearch: "",
    project: "",
    group: ""
  });
  const [auxGroupSelected, setAuxGroupSelected] = useState([]);
  const [auxSocietySelected, setAuxSocietySelected] = useState([]);


  useEffect(() => {
    showUser(uuid);
  }, [showUser, uuid]);

  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'SUPER_ADMIN') {
        showGroups({ ...groupFilters });
      }
      if (userProfile.profile.user_role === 'PROJECT_ADMIN') {
        showGroups({ ...groupFilters });
      }
    }
  }, [showGroups, userProfile, groupFilters]);

  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'SUPER_ADMIN') {
        showSocieties({ ...societyFilters });
      }
      if (userProfile.profile.user_role === 'PROJECT_ADMIN') {
        showSocieties({ ...societyFilters });
      }
      if (userProfile.profile.user_role === 'GROUP_ADMIN') {
        showSocieties({ ...societyFilters });
      }
    }
  }, [showSocieties, userProfile, societyFilters]);

  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'SUPER_ADMIN') {
        showProjects({ projectSearch: "" });
      }
    }
  }, [showProjects, userProfile]);

  useEffect(() => {
    if (userData) {
      delete userForm.user_role;
      if (userData.profile.user_role === "USER_GROUP") {
        setUserForm({ ...userForm, user_role: 'USER_GROUP' });
        for (let i = 0; i < userData.group.length; i++) {
          auxGroupSelected.push(userData.group[i].uuid);
        }
        setAuxSocietySelected([]);
      }
      if (userData.profile.user_role === "USER_SOCIETY") {
        setUserForm({ ...userForm, user_role: 'USER_SOCIETY' });
        for (let i = 0; i < userData.society.length; i++) {
          auxSocietySelected.push(userData.society[i].uuid);
        }
        setAuxGroupSelected([]);
      }
    }// eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    if (
      Object.keys(userForm).some(key => userForm[key] === '' && key !== 'user_magnus')
    ) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [userForm]);

  useEffect(() => {
    window.onbeforeunload = (event) => {
      if (triggerForm === true) {
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
      }
    };
  },);

  const alert = () => {
    if (serviceError) {
      return (
        serviceError.map((error) => {
          return (<div key={error}>
            {error !== undefined ? (
              `-${error}`
            ) : (
              ''
            )}
          </div>);
        })
      );
    }
  };

  const handleInputChange = ({ target }) => {
    if (!triggerForm) {
      setTriggerForm(true)
    }
    if (target.id === "groupsCheckbox" || target.id === "societiesCheckbox") {
      if (target.id === "groupsCheckbox") {
        const formData = { ...userForm }
        if (formData.group.includes(target.value)) {
          const pos = formData.group.indexOf(target.value);
          formData.group.splice(pos, 1);
          const auxForm = auxGroupSelected;
          auxForm.splice(pos, 1);
          setUserForm(formData);
          setAuxGroupSelected(auxForm);
        } else {
          formData.group.push(target.value);
          setUserForm(formData);
        }
      }

      if (target.id === "societiesCheckbox") {
        const formData = { ...userForm }
        if (formData.society.includes(target.value)) {
          const pos = formData.society.indexOf(target.value);
          formData.society.splice(pos, 1);
          const auxForm = auxSocietySelected;
          auxForm.splice(pos, 1);
          setUserForm(formData);
          setAuxSocietySelected(auxForm);
        } else {
          formData.society.push(target.value);
          setUserForm(formData);
        }
      }
    } else {
      let formData = { ...userForm, [target.name]: target.value };
      if (target.name === "rut_user" && target.value === "") {
        delete formData.rut_user;
      }
      setUserForm(formData);
      if (target.name === "user_role") {
        formData.group = [];
        formData.society = [];
      }
      if (target.name === "first_name" ||
        target.name === "last_name" ||
        target.name === "rut_user" ||
        target.name === "phone_number" ||
        target.name === "email"
      ) {
        delete validationError[target.name];
        setValidationError(validationError);
        if (target.name === "email") {
          const errors = validator({ [target.name]: loginSchema[target.name] }, formData);
          if (errors.length > 0) {
            const messages = errors
              .map(e => ({ [e.field]: e.message }))
              .reduce((prev, current) => ({ ...prev, ...current }), {});
            setValidationError({ ...validationError, ...messages });
            return false;
          }
        } else {
          const errors = validator({ [target.name]: registerSchema[target.name] }, formData);
          if (errors.length > 0) {
            const messages = errors
              .map(e => ({ [e.field]: e.message }))
              .reduce((prev, current) => ({ ...prev, ...current }), {});
            setValidationError({ ...validationError, ...messages });
            return false;
          }
        }
      }
    }
  };

  const sendData = () => {
    let formData;
    if (!userForm.rut_user && userData.profile.rut_user)
      formData = { ...userForm, rut_user: "" };
    else
      formData = { ...userForm };
    if (userData.profile.first_name === userForm.first_name)
      delete formData.first_name;
    if (userData.profile.last_name === userForm.last_name)
      delete formData.last_name;
    if (userData.profile.rut_user === userForm.rut_user)
      delete formData.rut_user;
    if (userData.profile.email === userForm.email)
      delete formData.email;
    if (userData.profile.phone_number === userForm.phone_number)
      delete formData.phone_number;
    if (userData.profile.user_role === userForm.user_role)
      delete formData.user_role;
    if (userForm.group.length === 0)
      delete formData.group;
    if (userForm.society.length === 0)
      delete formData.society;
    setPromptAux(false);
    setTriggerForm(false);
    setServiceError(null);
    setLoading(true);
    patchUser({ formData, uuid });
    setServiceError(errors);
    setFlag(true);
    
  }

  useEffect(() => {
    setServiceError(null);
  }, [errors]);

  useEffect(() => {
    if (flag) {
      if (!loadingForm && errors !== null) {
        setServiceError(errors);
        setLoading(false);
      }
      if (loadingForm === false && errors === null) {
        setStep(step + 1);
        setFlag(false);
      }
    }
  }, [loadingForm, errors, flag, step]);




  return (
    isLoadedUser === true ? (
      <Form>
        <Row className="mb-4">
          <Prompt
            when={triggerForm && promptAux}
            message={`¿Estás seguro de desear abandonar este formulario? Los datos ingresados no serán guardados.`}
          />
          <Col>
            <Breadcrumb className="small">
              <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
              <Breadcrumb.Item
                href={
                  userProfile && (
                    userProfile.profile.user_role === "SUPER_ADMIN" ? (
                      `/admin/users`
                    ) : (
                      userProfile.profile.user_role === "PROJECT_ADMIN" ? (
                        `/admin/users#1%1?search=&project=${localStorage.project}&group=&society=&ordering=&user_role=`
                      ) : (
                        `/admin/users#1%1?search=&project=${localStorage.group}&group=&society=&ordering=&user_role=`)
                    )
                  )
                }
              >
                Administración grupo sociedad
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Editar grupo</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {step === 0 && (
          <Row className="mb-4">
            <Col xl="5" lg="6" className="mx-auto">
              <h4 className="font-weight-bold mb-4">Datos principales</h4>
              <Form.Group controlId="first_name">
                <Form.Label className="font-weight-bold small">
                  Nombre
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa nombre"
                  name="first_name"
                  type="first_name"
                  defaultValue={userData.user.first_name}
                  isInvalid={validationError.first_name}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  {validationError.first_name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="last_name">
                <Form.Label className="font-weight-bold small">
                  Apellido
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa apellido"
                  name="last_name"
                  type="last_name"
                  isInvalid={validationError.last_name}
                  defaultValue={userData.user.last_name}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  {validationError.last_name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="rut_user">
                <Form.Label className="font-weight-bold small">
                  RUT
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa rut"
                  name="rut_user"
                  type="rut_user"
                  isInvalid={validationError.rut_user}
                  defaultValue={userData.profile.rut_user}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  {validationError.rut_user}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="phone_number">
                <Form.Label className="font-weight-bold small">Teléfono</Form.Label>
                <FormControl
                  name="phone_number"
                  type="phone"
                  placeholder="EJ: +56988888888"
                  autoComplete="lastName"
                  onChange={handleInputChange}
                  defaultValue={userData.profile.phone_number}
                  isInvalid={validationError.phone_number}
                  size="lg"
                />
                <Form.Control.Feedback type="invalid">
                  {validationError.phone_number}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label className="font-weight-bold small">
                  Correo electrónico
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa correo electrónico"
                  name="email"
                  type="email"
                  isInvalid={validationError.email}
                  defaultValue={userData.user.email}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  {validationError.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="user_documentation">
                <Form.Label className="font-weight-bold small">
                  Ver modulo de documentos
                </Form.Label>
                <Form.Control
                  size="lg"
                  as="select"
                  type="user_documentation"
                  name="user_documentation"
                  onChange={handleInputChange}
                  defaultValue={userData.profile.user_documentation}
                >
                  <option value={true}>Habilitado</option>
                  <option value={false}>Deshabilitado</option>
                </Form.Control>
              </Form.Group>
              
              <Form.Group controlId="enable">
                <Form.Label className="font-weight-bold small">
                  Rol
                </Form.Label>
                <Form.Control
                  size="lg"
                  as="select"
                  type="enable"
                  name="enable"
                  onChange={handleInputChange}
                  defaultValue={userData.user.enable}
                >
                  <option value={true}>Habilitado</option>
                  <option value={false}>Deshabilitado</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="user_magnus">
                <Form.Label className="font-weight-bold small">
                  Usuario Magnus
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa usuario magnus"
                  name="user_magnus"
                  type="user_magnus"
                  defaultValue={userData.profile.user_magnus}
                  onChange={handleInputChange}
                />
              </Form.Group>
              {
                userProfile && (
                  userProfile.profile.user_role === "SUPER_ADMIN" && (
                    <Form.Group controlId="user_role">
                      <Form.Label className="font-weight-bold small">
                        Rol de usuario
                        <span className="ml-1 text-danger small">
                          (*)
                        </span>
                      </Form.Label>
                      <Form.Control
                        size="lg"
                        as="select"
                        type="user_role"
                        name="user_role"
                        onChange={handleInputChange}
                        defaultValue={userData.profile.user_role}
                      >
                        <option value="SUPER_ADMIN">Super admin</option>
                        <option value="PROJECT_ADMIN">Admin de Proyectos</option>
                        <option value="GROUP_ADMIN">Admin de grupos</option>
                        <option value="USER_GROUP">Usuario grupo</option>
                        <option value="USER_SOCIETY">Usuario sociedad</option>
                      </Form.Control>
                    </Form.Group>
                  )
                )
              }
              <Form.Group controlId="user_description">
                <Form.Label className="font-weight-bold small">
                  Descripción de usuario
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Escribe aquí..."
                  name="user_description"
                  type="user_description"
                  defaultValue={userData.profile.user_description}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="position">
                <Form.Label className="font-weight-bold small">
                  Cargo
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa cargo"
                  name="position"
                  type="position"
                  defaultValue={userData.profile.position}
                  onChange={handleInputChange}
                />
              </Form.Group>
              {
                (userForm.user_role === "USER_GROUP") && (
                  <div>
                    <Form.Label className="font-weight-bold small">
                      Asignar grupo
                    </Form.Label>
                    <Accordion defaultActiveKey="1" className="mb-4">
                      <Card>
                        <Card.Header className="bg-transparent text-muted">
                          Seleccione
                        </Card.Header>
                        {
                          userProfile && (
                            userProfile.profile.user_role !== "GROUP_ADMIN" && (
                              <Card.Header className="bg-transparent">
                                <Form.Group controlId="project" className="m-0">
                                  <Form.Control
                                    size="md"
                                    as="select"
                                    placeholder="Ingresa un correo"
                                    name="project"
                                    type="project"
                                    onChange={({ target }) => setGroupFilters({ ...groupFilters, project: target.value })}
                                  >
                                    <option value="" className="secondary-text-color" selected> Todos los proyectos </option>
                                    {
                                      projectsList.map((project) => {
                                        return (
                                          <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                                        );
                                      })
                                    }
                                  </Form.Control>
                                </Form.Group>
                              </Card.Header>
                            )
                          )
                        }
                        <Card.Body eventKey="1">
                          <Card.Body className="py-0">
                            {
                              userProfile && (
                                userProfile.profile.user_role !== "GROUP_ADMIN" && (
                                  <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                                    <FormControl
                                      className='mb-2'
                                      size='md'
                                      placeholder='Buscar...'
                                      onChange={({ target }) => setGroupFilters({ ...groupFilters, groupSearch: target.value })}
                                    />
                                  </FormGroup>
                                )
                              )
                            }
                            <Form.Group controlId="groupsCheckbox" className="overflow-auto" style={{ height: "150px" }} onChange={handleInputChange}>
                              {
                                groupsList.map((group) => {
                                  return (
                                    <Form.Check type="checkbox" key={group.uuid} label={group.group_name} name={group.group_name} value={group.uuid} defaultChecked={auxGroupSelected.includes(group.uuid)} />
                                  );
                                })
                              }
                            </Form.Group>
                          </Card.Body>
                        </Card.Body>
                      </Card>
                    </Accordion>
                  </div>
                )
              }
              {
                (userForm.user_role === "USER_SOCIETY") && (
                  <div>
                    <Form.Label className="font-weight-bold small">
                      Asignar grupo
                    </Form.Label>
                    <Accordion defaultActiveKey="1" className="mb-4">
                      <Card>
                        <Card.Header className="bg-transparent text-muted">
                          Seleccione
                        </Card.Header>
                        <Card.Header className="bg-transparent">
                          {
                            userProfile && (
                              userProfile.profile.user_role !== "GROUP_ADMIN" && (
                                <Form.Group controlId="project">
                                  <Form.Control
                                    size="md"
                                    as="select"
                                    placeholder="Ingresa un correo"
                                    name="project"
                                    type="project"
                                    onChange={({ target }) => {
                                      showGroups({ groupSearch: "", project: target.value });
                                      setSocietyFilters({ ...societyFilters, project: target.value }
                                      )
                                      if (target.value === "")
                                        setSocietyFilters({ ...societyFilters, project: "", group: "" })
                                    }}
                                  >
                                    <option value="" className="secondary-text-color" selected> Todos los proyectos </option>
                                    {
                                      projectsList.map((project) => {
                                        return (
                                          <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                                        );
                                      })
                                    }
                                  </Form.Control>
                                </Form.Group>
                              )
                            )
                          }
                          <Form.Group controlId="group" className="m-0">
                            <Form.Control
                              size="md"
                              as="select"
                              placeholder="Ingresa un correo"
                              name="group"
                              type="group"
                              disabled={(societyFilters.project === "" && userProfile.profile.user_role !== "GROUP_ADMIN")}
                              onChange={({ target }) => setSocietyFilters({ ...societyFilters, group: target.value })}
                            >
                              <option value="" className="secondary-text-color" selected> Todos los grupos </option>
                              {
                                groupsList.map((group) => {
                                  return (
                                    <option value={group.uuid} key={group.uuid}>{group.group_name}</option>
                                  );
                                })
                              }
                            </Form.Control>
                          </Form.Group>
                        </Card.Header>
                        <Card.Body eventKey="1">
                          <Card.Body className="py-0">
                            <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                              <FormControl
                                className='mb-2'
                                size='md'
                                placeholder='Buscar...'
                                onChange={({ target }) => setSocietyFilters({ ...societyFilters, societySearch: target.value })}
                              />
                            </FormGroup>
                            <Form.Group controlId="societiesCheckbox" className="overflow-auto" style={{ height: "150px" }} onChange={handleInputChange}>
                              {
                                societiesList.map((society) => {
                                  return (
                                    <Form.Check type="checkbox" key={society.uuid} label={society.society_business_name} name={society.society_business_name} value={society.uuid} defaultChecked={auxSocietySelected.includes(society.uuid)} />
                                  );
                                })
                              }
                            </Form.Group>
                          </Card.Body>
                        </Card.Body>
                      </Card>
                    </Accordion>
                  </div>
                )
              }
              <span className="text-danger">
                (*)
                <span className="text-black-50">
                  Campos obligatorios
                </span>
              </span>
              {
                serviceError ? (
                  <div className="alert alert-danger my-3" role="alert">
                    {alert()}
                  </div>
                ) : null
              }
              <Button
                className="mt-2"
                onClick={sendData}
                disabled={disableSubmit || loading || loadingForm}
                size="lg"
                block
              >
                {
                  loading || loadingForm ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  ) : (
                    ''
                  )
                }{' '}
                Guardar usuario
              </Button>
            </Col>
          </Row>
        )}
        {step === 1 && (
          <Row>
            <Col xs="12" className="text-center">
              <CheckCircle size="48" className="text-success mb-4" />
              <h5 className="font-weight-bold mb-4">Usuario guardado con éxito</h5>
              <Button
                className="mx-2"
                href={
                  userProfile.profile && (
                    userProfile.profile.user_role === "SUPER_ADMIN" ? (
                      `/admin/users`
                    ) : (
                      userProfile.profile.user_role === "PROJECT_ADMIN" ? (
                        `/admin/users#1%1?search=&project=${localStorage.project}&group=&society=&ordering=&user_role=`
                      ) : (
                        `/admin/users#1%1?search=&project=${localStorage.group}&group=&society=&ordering=&user_role=`)
                    )
                  )
                }
              >
                Ir a usuarios
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    ) : ('')
  )
}

EditGroup.propTypes = {
  showUser: PropTypes.func.isRequired,
  userData: PropTypes.object,
  isLoadedUser: PropTypes.bool.isRequired,
  showGroups: PropTypes.func.isRequired,
  groupsList: PropTypes.array,
  errors: PropTypes.array,
  showSocieties: PropTypes.func.isRequired,
  societiesList: PropTypes.array,
};

const stateToProps = state => ({
  userData: state.users.userData,
  isLoadedUser: state.users.isLoadedUser,
  groupsList: state.groups.groupsList,
  societiesList: state.societies.societiesList,
  errors: state.users.errors,
  userProfile: state.profile.userProfile,
  loadingForm: state.users.loadingUserForm,
  patchUser: PropTypes.func.isRequired,
  projectsList: state.projects.projectsList
});

const actionsToProps = dispatch => ({
  showUser: payload => dispatch({ type: FETCH_USER, payload }),
  patchGroup: payload => dispatch({ type: PATCH_GROUP, payload }),
  showGroups: payload => dispatch({ type: FETCH_LIST_GROUPS, payload }),
  showProjects: payload => dispatch({ type: FETCH_LIST_PROJECTS, payload }),
  patchUser: payload => {
    const { formData, ...rest } = payload;
    dispatch({ type: PATCH_USER, payload: { userForm: formData, ...rest } });
  },
  showSocieties: payload => dispatch({ type: FETCH_LIST_SOCIETIES, payload }),
});

export default connect(stateToProps, actionsToProps)(EditGroup);
