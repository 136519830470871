const validador = (rut , option) => {
  let verifierCalculation = 0;
  let j = 0;
  const multiplicators = [2, 3 ,4 ,5 ,6 ,7]
  const rutArr = rut.substring(0,rut.indexOf('-')).split('');
  let verificator = rut.substring(rut.indexOf('-')+1,11);
  for(let i=rutArr.length-1;i>=0;i--){
    verifierCalculation = verifierCalculation + (parseInt(rutArr[i])*multiplicators[j]);
    j++;
    if(j === multiplicators.length)
      j=0;
  }
  verifierCalculation = 11-(verifierCalculation-(parseInt(verifierCalculation/11)*11))
  if(verifierCalculation === 10)
    verifierCalculation = 'k';
  if(verifierCalculation === 11)
    verifierCalculation = 0;
  if(verificator !== 'k')
    verificator = parseInt(rut.substring(rut.indexOf('-')+1,11));
  if(verificator === verifierCalculation){
     return true
  }else{
    if(option === 1){
      return [{ actual: verificator, expected: verifierCalculation,field: 'society_rut', type:'verificator', message: 'El RUT no es valido' }]
    }
    if(option === 0){
      return [{ actual: verificator, expected: verifierCalculation,field: 'rut_user', type:'verificator', message: 'El RUT no es valido' }]
    }
  }
}

export default validador;
