import React, { useState } from 'react';
import { Button, Card, Dropdown, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DELETE_USER } from '../../store/user/types';
import { DELETE_LOG } from '../../store/logs/types';

const UserDelete = ({ setFlagDelete, uuid, deleteUserData, deleteErrors, logDelete }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    logDelete(false);
    setShow(false);
  };
  const handleShow = () => {
    logDelete(true);
    setShow(true);
  };
  const [flag, setFlag] = useState(false)

  const handleDelete = () => {
    setFlagDelete(true);
    deleteUserData(uuid);
  }

  return (
    <>
      <Dropdown.Item
        data-toggle="tooltip"
        data-placement="top"
        title="Eliminar usuario"
        className="text-danger"
        onClick={handleShow}
      >
        Eliminar
      </Dropdown.Item>

      <Modal show={show} onHide={ () => {
        setFlag(false);
        handleClose();
      }}>
        <Modal.Header closeButton/>
        <Modal.Body>
          <Card.Body className="py-5 text-center">
            <i className="far fa-trash-alt text-danger text-center fa-3x mb-4"/>
            <h3 className="py-2 font-weight-bold title-color text-center">
              Eliminar usuario
            </h3>
            <p className="text-center">
              ¿Estás seguro que deseas eliminar este usuario?
            </p>
            {
              deleteErrors && flag &&(
                <p className="text-danger"> Este usuario no se puede eliminar ya que es administrador de algun proyecto,grupo o sociedad. </p>
              )
            }
            <div className="justify-content-center d-flex">
              <Button
                className="btn-rounded col-6 base-btn-color mt-4 py-2"
                variant="primary"
                onClick={() => {
                  setFlag(true);
                  handleDelete();
                }}
              >
                Seguro
              </Button>
            </div>
          </Card.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

UserDelete.propTypes = {
  deleteUserData: PropTypes.func.isRequired,
  logDelete: PropTypes.func.isRequired
};

const stateToProps = state => ({
  deleteErrors: state.users.deleteErrors
});

const actionsToProps = dispatch => ({
  deleteUserData: payload => dispatch({ type: DELETE_USER, payload }),
  logDelete: payload => dispatch({ type: DELETE_LOG, payload })
});

export default connect(stateToProps, actionsToProps)(UserDelete);
