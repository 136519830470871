import config from '../config';

const {
  accountingURI,
  accountingSearchURI,
  accountingUserListURI,
  balance8columnsURI,
  balance8columnsDetailURI,
  balance8columnsDatesURI,
  cashFlowDatesURI,
  cashFlowURI,
  financialSituationDataURI,
  financialSituationDatesURI,
  financialSituationDetail,
  accountingDetailURI,
  statusResultsURI,
  statusResultsDatesURI,
  statusResultsDetailURI,
  accountStatusDetailURI
} = config().services;
const network = client => ({
  getAccountingList: payload => client.get(`${accountingURI}${payload}`),
  deleteAccounting: payload => client.delete(`${accountingURI}${payload}/`),
  getAccountingData: payload => client.get(`${accountingURI}${payload}/`),
  postAccounting: (payload) => client.post(accountingURI, payload ),
  updateAccounting: ({ fd, uuid }) => client.patch(`${accountingURI}${uuid}/`, fd),
  getUserAccountingList: (filter) => client.get(`${accountingUserListURI}?${filter}`),
  searchAccounting: payload => client.get(`${accountingSearchURI}${payload}`),
  getBalance8Columns: payload => client.get(`${balance8columnsURI}${payload}`),
  getBalance8ColumnsDetail: payload => client.get(`${balance8columnsDetailURI}${payload}`),
  balance8columnsDates: payload => client.get(`${balance8columnsDatesURI}${payload}`),
  getCashFlow: payload => client.get(`${cashFlowURI}${payload}`),
  cashFlowDates: payload => client.get(`${cashFlowDatesURI}${payload}`),
  getFinancialSituation: payload => client.get(`${financialSituationDataURI}${payload}`),
  financialSituationDates: payload => client.get(`${financialSituationDatesURI}${payload}`),
  getFinancialSituationDetail: payload => client.get(`${financialSituationDetail}${payload}`),
  getAccountDetail: payload => client.get(`${accountingDetailURI}${payload}`),
  getStatusResults: payload => client.get(`${statusResultsURI}${payload}`),
  statusResultsDates: payload => client.get(`${statusResultsDatesURI}${payload}`),
  getStatusResultsDetail: payload => client.get(`${statusResultsDetailURI}${payload}`),
  getStatusAccountDetail: payload => client.get(`${accountStatusDetailURI}${payload}`),
});

export default network;
