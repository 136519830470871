import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { UploadCloud, XCircle } from 'react-feather';

const ImageUpload = ({setLogo, logo, imgName, setAuxImage, setAuxLogo, setAuxIcon}) => {

  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);

  useEffect(() => {
    return () => {
      setLogo(picture);
    };
  }, [logo, picture, setLogo]);

  const onChangePicture = e => {
    if(imgName === 'image'){
      setAuxImage(true);
    }
    if(imgName === 'logo'){
      setAuxLogo(true);
    }
    if(imgName === 'icon'){
      setAuxIcon(true);
    }
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const deleteImg = () => {
    setImgData(null)
  }

  return (
    <>
      <label className="upload-file-custom position-relative">
        <input type="file" id="file" aria-label="File browser example" onChange={onChangePicture} />
        <div className="upload-file-custom-content text-center mb-4">
          { !imgData &&
            <>
              <UploadCloud color="#999999"/>
              <span className="file-custom text-center font-weight-bold d-block">Subir imagen</span>
            </>
          }
          <img alt="" className="upload-file-custom-img img-fluid" src={imgData} />
          { imgData &&
            <Button variant="light" className="btn-delete" onClick={deleteImg}>
              <XCircle/>
            </Button>
          }
        </div>
      </label>
    </>
  )
}

export default ImageUpload;
