import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import validator from '../utils/validator';
import { ticketSchema } from '../utils/validator/schemas';
import { POST_CONTACT_EMAIL } from '../store/auth/types';

const ContactModal = (
  {
    postContactEmail,
    contactErrors,
    loadingContact
  }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [ticketForm, setTicketForm] = useState({ email:'', subject:'', description:'' });
  const [validationError, setValidationError] = useState({ description:'' });
  const [flag, setFlag] = useState(false);

  const handleTicket = () => {
    postContactEmail(ticketForm);
    setFlag(true);
  }

  useEffect(() => {
    if(!loadingContact && contactErrors.length === 0 && flag) {
      handleClose();
      setFlag(false);
    }
  }, [loadingContact, contactErrors, flag]);

  useEffect(() => {
    if (
      Object.keys(ticketForm).some(key => ticketForm[key] === '')
    ) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [ticketForm]);

  const handleInputChange = ({ target }) => {
    const formData = { ...ticketForm, [target.name]: target.value };
    setTicketForm(formData);

    if(target.name === 'description'){
      setValidationError({ ...validationError, [target.name]: '' });
      const errors = validator({ [target.name]: ticketSchema[target.name] }, formData);
      if (errors.length > 0) {
        const messages = errors
          .map(e => ({...validationError, [e.field]: e.message }))
          .reduce((prev, current) => ({ ...prev, ...current }), {});
        setValidationError(messages);
        return false;
      }
    }
  };

  return (
    <>
      <Button variant="light" size="lg" className='mt-4 mt-md-0' onClick={handleShow}>
        Contáctanos
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton/>
        <Modal.Body className='p-5 border-0' >
          <h3 className='font-weight-bold mb-4'>Envíanos un mensaje</h3>

          <Form.Group controlId="email">
            <Form.Label className="small">
              Email
            </Form.Label>
            <Form.Control
              size="lg"
              placeholder="Ingresa email"
              name="email"
              type="email"
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="subject">
            <Form.Label className="small">
              Asunto
            </Form.Label>
            <Form.Control
              size="lg"
              placeholder="Ingresa asunto"
              name="subject"
              type="subject"
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="description">
            <Form.Label className="small">
              Mensaje
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Ingresa mensaje"
              name="description"
              type="description"
              onChange={handleInputChange}
              isInvalid={validationError.description}
            />
            <Form.Text id="passwordHelpBlock" muted>
              Máximo 1024 carácteres
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {validationError.description}
            </Form.Control.Feedback>
          </Form.Group>

          {
            !loadingContact && contactErrors.length !== 0 && (
              contactErrors.map(
                (error) => {
                  return(<p key={error} className='text-danger'>{error}</p>)
                }
              )
            )
          }

          <Button disabled={disableSubmit || validationError.description !== ''} variant="primary" className='float-right' onClick={handleTicket} size="md">
            {
              loadingContact ?(
                <Spinner animation="border" className="spinner-custom" />
              ):(
                'Enviar mensaje'
              )
            }
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

ContactModal.propTypes = {
  postContactEmail: PropTypes.func.isRequired,
  contactErrors: PropTypes.array,
  loadingContact: PropTypes.bool,
};

const stateToProps = state => ({
  contactErrors: state.auth.contactErrors,
  loadingContact: state.auth.loadingContact
});

const actionsToProps = dispatch => ({
  postContactEmail: payload => dispatch({ type: POST_CONTACT_EMAIL, payload })
});

export default connect(stateToProps, actionsToProps)(ContactModal);
