import React from 'react';
import { Container } from 'react-bootstrap';
import Home from '../components/home';
// import { ChevronLeft, ChevronRight } from 'react-feather';

const Support = () => {
  return (
    <div className="container-admin bg-light vh-100">
      <Container className="py-5 d-flex justify-content-center vh-100" fluid>
        <div className="row mt-5 col-10 container_soporte d-flex justify-content-center">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <Container>
              <Home/>
            </Container>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Support;
