import React from 'react'
import { FormControl, FormGroup } from 'react-bootstrap'
import { Search } from 'react-feather'

const SearchBar = ({ handleSearch, defaultValue }) => {
  return (
    <FormGroup className="mb-3 mb-md-0 mr-0 mr-md-2 search-bar position-relative pr-0" >
      <FormControl
        size="md"
        placeholder="Buscar..."
        onChange={handleSearch}
        defaultValue={defaultValue}
        style={{width:'13rem'}}
        id='btnSearch'
      />
      <Search className="icon"/>
    </FormGroup>
  )
}

export default SearchBar
