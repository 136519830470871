import React from 'react';
import { Card, Container } from 'react-bootstrap';
import UserProfile from '../components/userProfile';

const NoAvailableView = () => {
  return (
    <div className="container-admin bg-light">
      <Container className="py-5 container_profile_primary" id='container_profile_id' style={{ paddingLeft: '4rem' }} fluid>
        <div className="row">
          <div className="col-12">
            <Card className="border-0 shadow-sm">
              <Card.Body>
                <UserProfile/>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NoAvailableView;
