import React, { useEffect, useState } from 'react';
import { Row, Col, Badge, Dropdown, Spinner } from 'react-bootstrap';
import Avatar from 'react-avatar'
import { MoreHorizontal } from 'react-feather';
import PropTypes from 'prop-types';
import { RESEND_INVITATION } from '../../store/user/types';
import { connect } from 'react-redux';
import InvitationDelete from './userDelete';
import moment from 'moment';

const InvitationItem = props => {
  const [variant, setVariant] = useState('');
  const [nameUser, setNameUser] = useState('');
  const [trigger, setTrigger] = useState(false);
  const [sent, setSent] = useState(false);
  const { readOnly } = props;
  const uuid = props.uuid;
  const date = moment(`${props.date.date} ${props.date.hour}`).toDate();
  const oneDay = 24 * 60 * 60 * 1000;
  const totalDays = Math.round(Math.abs((moment().toDate() - date) / oneDay));

  useEffect(() => {
    if (props.loadingResend === false && props.invitationSent && trigger)
      setSent(true);
  }, [props.invitationSent, props.loadingResend, trigger]);

  const handleResend = () => {
    if (trigger)
      setSent(false);
    props.resendInvitation(uuid);
    setTrigger(true);
  }

  useEffect(() => {
    const variants = [
      'warning',
      'info',
      'danger',
      'success',
      'dark'
    ]
    switch (props.role) {
      case 'USER_GROUP':
        setNameUser('Usuario de grupo');
        setVariant(variants[0]);
        break;
      case 'PROJECT_ADMIN':
        setNameUser('Admin de proyecto');
        setVariant(variants[1]);
        break;
      case 'GROUP_ADMIN':
        setNameUser('Admin de grupo');
        setVariant(variants[2]);
        break;
      case 'SUPER_ADMIN':
        setNameUser('Superadmin');
        setVariant(variants[3]);
        break;
      case 'USER_SOCIETY':
        setNameUser('Usuario de sociedad');
        setVariant(variants[4]);
        break;
      default:
        break;
    }
  }, [props.role]);

  return (
    <>
      <Row className='p-3 project-item' id='projects_item'>

        <Col xs="12" sm="12" lg="3" xl="4">
          <div className="d-flex">
            {/*<Avatar maxInitials="2" round="8px" fgColor="black" size="48" name={props.name} className="mr-3"/>*/}
            <div className="media-body">
              <h6 className="font-weight-bold mb-0 mr-2 mt-2">{props.name}</h6>
            </div>
          </div>
        </Col>

        {!readOnly &&
          <>
            <Col xs="12" sm="12" lg="3" xl="2">
              <span>{nameUser}</span>
            </Col>

            <Col xs="12" sm="12" lg="3" xl="2">
              {
                ((props.loadingResend === false && props.invitationSent && trigger) || sent) && (
                  <p className="text-danger">Enviado</p>
                )
              }
              {
                props.loadingResend && trigger && !sent && (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                )
              }
            </Col>

            <Col xs="12" sm="12" lg="3" xl="2">
              <span className="mr-2 text-dark">Invitado hace {totalDays} días</span>
            </Col>

            <Col xs="12" sm="12" lg="3" xl="2">
              <div className="d-flex align-items-center">

                <Dropdown>
                  <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" className="mx-1" size="sm">
                    <MoreHorizontal size="12" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="shadow-sm border-0 p-2">
                    <Dropdown.Item
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Reenviar invitación"
                      onClick={handleResend}
                    >
                      Reenviar
                    </Dropdown.Item>
                    <InvitationDelete uuid={props.uuid} setFlagDelete={props.setFlagAction} />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </>
        }

      </Row>
    </>
  )
}


InvitationItem.propTypes = {
  resendInvitation: PropTypes.func.isRequired
};

const stateToProps = state => ({
  loadingResend: state.users.loadingResend,
  invitationSent: state.users.invitationSent
});

const actionsToProps = dispatch => ({
  resendInvitation: payload => dispatch({ type: RESEND_INVITATION, payload })
});

export default connect(stateToProps, actionsToProps)(InvitationItem);
