import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Card, Container, Row, Col, Form, FormControl, Spinner } from 'react-bootstrap';
import validator from '../../utils/validator';
import { loginSchema } from '../../utils/validator/schemas';
import { POST_EMAIL_RESET_PASSWORD } from '../../store/types';

const SendEmail = ({ sendEmailReset, isSending, errors }) => {
  const history = useHistory();
  const [resetForm, setResetForm] = useState({ email: '' });
  const [validationError, setValidationError] = useState({ email: '' });

  const handleSubmit = event => {
    event.preventDefault();
    sendEmailReset({ resetForm, history });
  };

  const handleInputChange = ({ target }) => {
    const formData = { ...resetForm, [target.name]: target.value };
    setResetForm(formData);
    setValidationError({ ...validationError, [target.name]: '' });

    const errors = validator({ [target.name]: loginSchema[target.name] }, formData);
    if (errors.length > 0) {
      const messages = errors
        .map(e => ({ [e.field]: e.message }))
        .reduce((prev, current) => ({ ...prev, ...current }), {});
      setValidationError(messages);
      return false;
    }
  };

  return (
    <Container>
      <Row className="d-flex justify-content-center">
        <Col md="8" lg="6">
          <Card className="border-0 p-4">
            <Card.Body>
              <h2 className="font-weight-bold text-center">
                Recuperar contraseña
              </h2>
              <p className="text-center mt-3 small w-75 mx-auto">
                Ingresa tu correo electrónico y te enviaremos un enlace para que recuperes el acceso a tu cuenta.
             </p>
              <Form noValidate onSubmit={handleSubmit} className="pr-lg-4">
                <Form.Group controlId="loginEmail">
                  <Form.Label className="logs-font font-weight-bold small mt-4">Correo electrónico</Form.Label>
                  <FormControl
                    className="secondary-text-color"
                    name="email"
                    size='lg'
                    type="email"
                    placeholder="Ingresa tu correo electrónico"
                    autoComplete="email"
                    onChange={handleInputChange}
                    isInvalid={validationError.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationError.email}
                  </Form.Control.Feedback>
                </Form.Group>
                {errors ? (
                  <div className="alert alert-danger" role="alert">
                    {errors}
                  </div>
                ) : null
                }
                <div className='text-center'>
                  <Button
                    className="btn-rounded"
                    variant="primary"
                    type="submit"
                    disabled={isSending}
                  >
                    {isSending ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  ) : (
                    ''
                  )}{' '}
                  Enviar Correo
                </Button>
                </div>

              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

SendEmail.propTypes = {
  sendEmailReset: PropTypes.func.isRequired,
  isSending: PropTypes.bool,
  errors: PropTypes.string
};

const stateToProps = state => ({
  isSending: state.auth.isSending,
  errors: state.auth.errors
});


const actionsToProps = dispatch => ({
  sendEmailReset: payload => dispatch({ type: POST_EMAIL_RESET_PASSWORD, payload })
});

export default connect(stateToProps, actionsToProps)(SendEmail);
