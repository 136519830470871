const schema = {
  url_own_site: {
    type: 'string',
    optional: true,
    empty: true,
    pattern:  '^http(s)?:\\/\\/?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$',
    messages: {
      stringPattern: 'Debes ingresar una URL válida'
    }
  },
  custom_portal_url: {
    type: 'string',
    optional: true,
    empty: true,
    pattern: '^http(s)?:\\/\\/?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$',
    messages: {
      stringPattern: 'Debes ingresar una URL válida'
    }
  },
  contact_email: {
    type: 'email',
    max: 100,
    optional: true,
    empty: true,
    messages: {
      email: 'El email debe tener el siguiente formato ejemplo@correo.com',
      string: 'Debes ingresar un email',
      stringMax: 'El email es muy largo (maximo 100 caracteres).'
    }
  },
  contact_phone: {
    type: 'string',
    min: 0,
    max: 15,
    optional: true,
    empty: true,
    pattern: '^(\\+?)?[0-9]*$',
    messages: {
      required: 'Debes ingresar tu número de telefono.',
      number: 'Debes ingresar un número válido',
      stringMin: 'El número es muy corto.',
      stringMax: 'Tu número es muy largo (máximo 15 caracteres).',
      stringPattern: 'Debes ingresar un número válido'
    }
  }
};

export default schema;
