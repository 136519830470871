import React from 'react';

function AlfredRedirectButton() {
    const handleSecureRedirect = () => {
        const newWindow = window.open(`${process.env.REACT_APP_PLAYGROUND}?loading=1`, '_blank');
    };

    return (
        <button
            className='btn btn-primary'
            style={{ color: 'white', fontSize: '13px' }}
            onClick={handleSecureRedirect}
        >
            Alfred Friendly Tax Advisor
        </button>
    );
}

export default AlfredRedirectButton;