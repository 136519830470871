export const SET_DATA_LIST_USERS = 'user/SET_DATA_USERS';
export const FETCH_LIST_USERS = 'user/FETCH_USERS';
export const IS_LOADED = 'user/IS_LOADED';
export const INDEX_PAGINATION_USERS = 'user/INDEX_PAGINATION_USERS';
export const POST_INVITATION = 'user/POST_INVITATION';
export const POST_CSV = 'user/POST_CSV';
export const LOADING_CSV = 'user/LOADING_CSV';
export const SET_IMPORT_ERRORS = 'user/SET_IMPORT_ERRORS';
export const IS_LOADED_USER = 'user/IS_LOADED_USER';
export const SET_DATA_USER = 'user/SET_DATA_USER';
export const DELETE_USER = 'user/DELETE_USER';
export const FETCH_USER = 'user/FETCH_USER';
export const SET_USER_ERRORS = 'user/SET_USER_ERRORS';
export const LOADING_USER_FORM = 'user/LOADING_USER_FORM';
export const PATCH_USER = 'user/PATCH_USER';
export const DELETE_USER_ERRORS = 'user/DELETE_USER_ERRORS';
export const SET_DATA_LIST_INVITATIONS = 'user/SET_DATA_LIST_INVITATIONS';
export const INDEX_PAGINATION_INVITATIONS = 'user/INDEX_PAGINATION_INVITATIONS';
export const RESEND_INVITATION = 'user/RESEND_INVITATION';
export const DELETE_INVITATION = 'user/DELETE_INVITATION';
export const INVITATION_LOADING = 'user/INVITATION_LOADING';
export const INVITATION_ERRORS = 'user/INVITATION_ERRORS';
export const RESEND_LOADING = 'user/RESEND_LOADING';
export const INVITATION_SENT = 'user/INVITATIONS_SUCCESS';
