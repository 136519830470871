import React from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { Download, File, Folder, Maximize2, MoreHorizontal } from 'react-feather';
import { useHistory } from 'react-router-dom';
import cookieHandler from '../../utils/helpers/cookie.handler';
import DocumentShare from './documentShare';
import EditFolder from './editFolderName';
import EditDocument from './editDocumentName';
import DocumentDelete from './documentDelete';
import FolderDelete from './folderDelete';
import FolderIcon from '../../images/folder.png';
import DocumentFile from '../../images/reports.png'
import PanelTax from '../../images/reportesAll.png'

const DocumentItem = props => {
  const history = useHistory();
  const accessToken = cookieHandler().getAccessToken();
  return (
    <>
      {
        props.type === "folder" && (
          <tr>
            <th
              scope="row"
              className='border-top-0 align-middle'
                              onClick={() => {
                  history.push({
                    pathname: `/admin/documents/${props.uuid}`
                  })
                  props.handleFolder(props.uuid, props.type, props.name);
                }}
                
              style={{ cursor: "pointer" }}
            >
              <div className='d-flex align-items-center '>
                <img src={FolderIcon} width={35} className='mb-0 mr-2' alt='...' />
                <h6 className="text-uppercase font-weight-bold mb-0" style={{ fontSize: '12px' }}>{props.name}</h6>
              </div>
            </th>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small text-center'>{props.date}</p>
            </td>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small'>-</p>
            </td>
            <td className='border-top-0 text-right align-middle'>
              {
                props.step === 1 && (
                  <Button
                    variant="outline-dark"
                    size="sm"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Editar carpetas"
                    onClick={
                      () => history.push({
                        pathname: `/admin/documents/root/${props.project_uuid}`
                      })}
                  >
                    <File size="12" />
                  </Button>
                )
              }
            </td>
          </tr>
        )
      }

      {
        props.type === "taxAdmin" && (
          <tr>
            <th
              scope="row"
              className='border-top-0 align-middle'
              onClick={() => {
                history.push({ pathname: `/admin/documents/${props.uuid}` });
                props.handleFolder(props.uuid, props.type, props.name);
              }}
              style={{ cursor: "pointer" }}
            >
              <div className='d-flex align-items-center '>
                <img src={PanelTax} className='mb-0 mr-2' width={25} alt='...' />
                <h6 className="text-uppercase font-weight-bold mb-0">{props.name}</h6>
              </div>
            </th>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small text-center'>{props.date}</p>
            </td>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small'>-</p>
            </td>
            <td className='border-top-0 text-right align-middle' />
          </tr>
        )
      }
      {
        props.type === "taxFormAdmin" && (
          <tr>
            <th
              scope="row"
              className='border-top-0 align-middle'
              onClick={() => {
                history.push({ pathname: `/admin/documents/${props.uuid}` });
                props.handleFolder(props.uuid, props.type, props.name);
              }}
              style={{ cursor: "pointer" }}
            >
              <div className='d-flex align-items-center '>
                <img src={PanelTax} className='mb-0 mr-2' width={25} alt='...' />
                <h6 className="text-uppercase font-weight-bold mb-0">{props.name}</h6>
              </div>
            </th>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small text-center'>{props.date}</p>
            </td>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small'>-</p>
            </td>
            <td className='border-top-0 text-right align-middle' />
          </tr>
        )
      }
      {
        props.type === "taxFormYearAdmin" && (
          <tr>
            <th
              scope="row"
              className='border-top-0 align-middle'  
              onClick={() => {
                history.push({ pathname: `/admin/documents/${props.uuid}` });
                props.handleFolder(props.uuid, props.type, props.name);
              }}
              style={{ cursor: "pointer" }}
            >
              <div className='d-flex align-items-center '>
                <img src={PanelTax} className='mb-0 mr-2' width={25} alt='...' />
                <h6 className="text-uppercase font-weight-bold mb-0">{props.name}</h6>
              </div>
            </th>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small text-center'>{props.date}</p>
            </td>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small'>-</p>
            </td>
            <td className='border-top-0 text-right align-middle' />
          </tr>
        )
      }
       {
        props.type === "taxFormYearAdminDocument" && (
          <tr>
            <th
              scope="row"
              className='border-top-0 align-middle'
              onClick={() => {
                window.open(props.Document, '_blank');
              }}
              style={{ cursor: "pointer" }}
            >
              <div className='d-flex align-items-center '>
                <img src={DocumentFile} className='mb-0 mr-2' width={25} alt='...' />
                <h6 className="text-uppercase font-weight-bold mb-0">{props.file_name}</h6>
              </div>
            </th>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small text-center'></p>
            </td>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small'>-</p>
            </td>
            <td className='border-top-0 text-right align-middle' />
          </tr>
        )
      }
         {
        props.type === "folderListAdmin" && (
          <tr>
            <th
              scope="row"
              className='border-top-0 align-middle'
              onClick={() => {
                history.push({ pathname: `/admin/documents/${props.uuid}` });
                props.handleFolder(props.uuid, props.type, props.name);
              }}
              style={{ cursor: "pointer" }}
            >
              <div className='d-flex align-items-center '>
                <img src={FolderIcon} className='mb-0 mr-2' width={25} alt='...' />
                <h6 className="text-uppercase font-weight-bold mb-0">{props.name}</h6>
              </div>
            </th>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small text-center'>{props.date}</p>
            </td>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small'>-</p>
            </td>
            <td className='border-top-0 text-right align-middle' />
          </tr>
        )
      }



      {/**Folders for user */}
      {
        props.type === "userFolder" && (
          <tr>
            <th
              scope="row"
              className='border-top-0 align-middle'
              onClick={() => {
                history.push({ pathname: `/documents_user/${props.uuid}` });
                props.handleFolder(props.uuid, props.type, props.name);
              }}

              style={{ cursor: "pointer" }}
            >
              <div className='d-flex align-items-center '>
                <img src={FolderIcon} className='mb-0 mr-2' width={25} alt='...' />
                <h6 className="text-uppercase font-weight-bold mb-0">{props.name}</h6>
              </div>
            </th>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small text-center'>{props.date}</p>
            </td>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small'>-</p>
            </td>
            <td className='border-top-0 text-right align-middle' />
          </tr>
        )
      }
      {
        props.type === "folderList" && (
          <tr>
            <th
              scope="row"
              className='border-top-0 align-middle'
              onClick={() => {
                history.push({ pathname: `/documents_user/${props.uuid}` });
                props.handleFolder(props.uuid, props.type, props.name);
              }}
              style={{ cursor: "pointer" }}
            >
              <div className='d-flex align-items-center '>
                <img src={FolderIcon} className='mb-0 mr-2' width={25} alt='...' />
                <h6 className="text-uppercase font-weight-bold mb-0">{props.name}</h6>
              </div>
            </th>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small text-center'>{props.date}</p>
            </td>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small'>-</p>
            </td>
            <td className='border-top-0 text-right align-middle' />
          </tr>
        )
      }
      {
        props.type === "tax" && (

          <tr>
            <th
              scope="row"
              className='border-top-0 align-middle'
              onClick={() => {
                history.push({ pathname: `/documents_user/${props.uuid}` });
                props.handleFolder(props.uuid, props.type, props.name);
              }}
              style={{ cursor: "pointer" }}
            >
              <div className='d-flex align-items-center '>
                <img src={PanelTax} className='mb-0 mr-2' width={25} alt='...' />
                <h6 className="text-uppercase font-weight-bold mb-0">{props.name}</h6>
              </div>
            </th>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small text-center'>{props.date}</p>
            </td>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small'>-</p>
            </td>
            <td className='border-top-0 text-right align-middle' />
          </tr>
        )
      }
      {
        props.type === "taxForm" && (
          <tr>
            <th
              scope="row"
              className='border-top-0 align-middle'
              onClick={() => {
                history.push({ pathname: `/documents_user/${props.uuid}` });
                props.handleFolder(props.uuid, props.type, props.name);
              }}
              style={{ cursor: "pointer" }}
            >
              <div className='d-flex align-items-center '>
                <img src={PanelTax} className='mb-0 mr-2' width={25} alt='...' />
                <h6 className="text-uppercase font-weight-bold mb-0">{props.name}</h6>
              </div>
            </th>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small text-center'>{props.date}</p>
            </td>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small'>-</p>
            </td>
            <td className='border-top-0 text-right align-middle' />
          </tr>
        )
      }
      {
        props.type === "taxFormYear" && (
          <tr>
            <th
              scope="row"
              className='border-top-0 align-middle'
              onClick={() => {
                history.push({ pathname: `/documents_user/${props.uuid}` });
                props.handleFolder(props.uuid, props.type, props.name, props.year);
              }}
              style={{ cursor: "pointer" }}
            >
              <div className='d-flex align-items-center '>
                <img src={PanelTax} className='mb-0 mr-2' width={25} alt='...' />
                <h6 className="text-uppercase font-weight-bold mb-0">{props.name}</h6>
              </div>
            </th>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small text-center'>{props.date}</p>
            </td>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small'>-</p>
            </td>
            <td className='border-top-0 text-right align-middle' />
          </tr>
        )
      }
      {
        props.type === "taxFormYearDocument" && (
          <tr>
            <th
              scope="row"
              className='border-top-0 align-middle'
              onClick={() => {
                window.open(props.Document, '_blank');
              }}
              style={{ cursor: "pointer" }}
            >
              <div className='d-flex align-items-center '>
                <img src={DocumentFile} className='mb-0 mr-2' width={25} alt='...' />
                <h6 className="text-uppercase font-weight-bold mb-0">{props.file_name}</h6>
              </div>
            </th>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small text-center'></p>
            </td>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small'>-</p>
            </td>
            <td className='border-top-0 text-right align-middle' />
          </tr>
        )
      }
      {/**Folders for user end */}


      {
        props.type === "folder_edition" && (
          <tr>
            <th
              scope="row"
              className='border-top-0 align-middle'
              onClick={
                () => history.push({
                  pathname: `/admin/documents/${props.uuid}/${props.project_uuid}`
                })}
              style={{ cursor: "pointer" }}
            >
              <div className='d-flex align-items-center '>
                <Folder className='mb-0 mr-2'></Folder>
                <h6 className="text-uppercase font-weight-bold mb-0">{props.name}</h6>
              </div>
            </th>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small text-center'>{props.date}</p>
            </td>
            <td className='border-top-0 text-right align-middle'>
              <Dropdown>
                <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" className="mx-1" size="sm">
                  <MoreHorizontal size="12" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow-sm border-0 p-2">
                  <EditFolder name={props.name} uuid={props.uuid} project={props.project_uuid} />
                  <FolderDelete deleteFolder={props.deleteFolderTemplate} uuid={props.uuid} />
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        )
      }
      {
        props.type === "file" && (
          <tr>
            <th
              scope="row"
              className='border-top-0 align-middle'
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(`${process.env.REACT_APP_SERVICE_URL}api/v1/documents/download/${props.uuid}/?token=${accessToken}&type=inline`, 'document').focus();
              }}
            >
              <div className='d-flex align-items-center'>
                <File className="mr-2" size={25} />
                <h6 className="text-uppercase font-weight-bold mb-0">{props.name}</h6>
              </div>
            </th>
            <td className='border-top-0 align-middle text-center'>
              <p className='mb-0 small text-center'>{props.date}</p>
            </td>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small'>{props.size}</p>
            </td>
            <td className='border-top-0 text-right align-middle'>
              <Dropdown>
                <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" className="mx-1" size="sm">
                  <MoreHorizontal size="12" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow-sm border-0 p-2">
                  <Dropdown.Item
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Abrir archivo"
                    className="text-mute"
                    onClick={() => {
                      window.open(`${process.env.REACT_APP_SERVICE_URL}api/v1/documents/download/${props.uuid}/?token=${accessToken}&type=inline`, 'document').focus();
                    }}
                  >
                    <Maximize2 size="15" className="mr-1" />
                    Abrir
                  </Dropdown.Item>
                  <DocumentShare uuid={props.uuid} />
                  <EditDocument name={props.name} uuid={props.uuid} />
                  <Dropdown.Item
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Descargar carpeta"
                    className="text-mute"
                    href={`${process.env.REACT_APP_SERVICE_URL}api/v1/documents/download/${props.uuid}/?token=${accessToken}&type=attachment`}
                  >
                    <Download size="15" className="mr-1" />
                    Descargar
                  </Dropdown.Item>
                  <DocumentDelete deleteDocument={props.deleteDocumentFile} uuid={props.uuid} />
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        )
      }
      {
        props.type === "file_user" && (
          <tr>
            <th
              scope="row"
              className='border-top-0 align-middle'
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(`${process.env.REACT_APP_SERVICE_URL}api/v1/documents/download/${props.uuid}/?token=${accessToken}&type=inline`, 'document').focus();
              }}
            >
              <div className='d-flex align-items-center'>
                <File className="mr-2" size={25} />
                <h6 className="text-uppercase font-weight-bold mb-0">{props.name}</h6>
              </div>
            </th>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small text-center'>{props.date}</p>
            </td>
            <td className='border-top-0 align-middle'>
              <p className='mb-0 small'>{props.size}</p>
            </td>
            <td className='border-top-0 text-right align-middle'>
              <Dropdown>
                <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" className="mx-1" size="sm">
                  <MoreHorizontal size="12" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow-sm border-0 p-2">
                  <Dropdown.Item
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Abrir archivo"
                    className="text-mute"
                    onClick={() => {
                      window.open(`${process.env.REACT_APP_SERVICE_URL}api/v1/documents/download/${props.uuid}/?token=${accessToken}&type=inline`, 'document').focus();
                    }}
                  >
                    <Maximize2 size="15" className="mr-1" />
                    Abrir
                  </Dropdown.Item>
                  <DocumentShare uuid={props.uuid} />
                  <Dropdown.Item
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Descargar carpeta"
                    className="text-mute"
                    href={`${process.env.REACT_APP_SERVICE_URL}api/v1/documents/download/${props.uuid}/?token=${accessToken}&type=attachment`}
                  >
                    <Download size="15" className="mr-1" />
                    Descargar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        )
      }
    </>
  )
}

export default DocumentItem;
