import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FETCH_USER_REPORT } from '../../store/report/types';
import { connect } from 'react-redux';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Button } from 'react-bootstrap';
import { Maximize2 } from 'react-feather';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

const UserReport = ({showUserReport, reportData, isLoadedReport, userProfile}) => {
  const { uuid } = useParams();
  const handle = useFullScreenHandle();
  const [url, setUrl] = useState();
  const quicksightRef = React.createRef();
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if(reportData){
      if(reportData.tool_type === 'QUICKSIGHT') {
        if(flag)
          window.location.reload();
        else
          embedQuicksight();
        setFlag(true);
      }
    }// eslint-disable-next-line
  },[reportData]);

  useEffect(() => {
    showUserReport(uuid);
  }, [showUserReport, uuid]);

  useEffect(() => {
    if(reportData){
      let auxUrl = reportData.url;
      let flagFilter = true
      setUrl(auxUrl);
      if(reportData.tool_type === 'POWER_BI'){
        if(reportData.project_parameter){
          if(flagFilter){
            auxUrl = `${auxUrl}&filter=`;
            flagFilter = false;
          }else{
            auxUrl = `${auxUrl} and `;
          }
          auxUrl = `${auxUrl}${reportData.project_parameter.parameter_name} in ('${reportData.project_parameter.parameter_content}')`;
          setUrl(auxUrl);
        }
        if(reportData.group_parameter){
          if(flagFilter){
            auxUrl = `${auxUrl}&filter=`;
            flagFilter = false;
          }else{
            auxUrl = `${auxUrl} and `;
          }
          let arrayParameters = '';
          for(let i = 0; i<reportData.group_parameter.length; i++){
            if(i === 0)
              arrayParameters = `'${reportData.group_parameter[i].parameter_content}'`;
            else
              arrayParameters = `${arrayParameters}, '${reportData.group_parameter[i].parameter_content}'`;
          }
          auxUrl = `${auxUrl}${reportData.group_parameter[0].parameter_name} in (${arrayParameters})`;
          setUrl(auxUrl);
        }
        if(reportData.user_role_parameter){
          if(flagFilter){
            auxUrl = `${auxUrl}&filter=`;
            flagFilter = false;
          }else{
            auxUrl = `${auxUrl} and `;
          }
          auxUrl = `${auxUrl}${reportData.user_role_parameter.parameter_name} in ('${reportData.user_role_parameter.parameter_content}')`;
          setUrl(auxUrl);
        }
        if(reportData.user_parameter){
          if(flagFilter){
            auxUrl = `${auxUrl}&filter=`;
            flagFilter = false;
          }else{
            auxUrl = `${auxUrl} and `;
          }
          auxUrl = `${auxUrl}${reportData.user_parameter.parameter_name} in ('${reportData.user_parameter.parameter_content}')`;
          setUrl(auxUrl);
        }
        if(reportData.parameter_list){
          for(let i = 0; i<reportData.parameter_list.length; i++){
            if(flagFilter){
              auxUrl = `${auxUrl}&filter=`;
              flagFilter = false;
            }else{
              auxUrl = `${auxUrl} and `;
            }
            auxUrl = `${auxUrl}${reportData.parameter_list[i].parameter_name} in ('${reportData.parameter_list[i].parameter_content}')`;
            setUrl(auxUrl);
          }
        }
      }
    }
  }, [reportData, url]);

  const embedQuicksight = () => {
    if(reportData){
      if(reportData.tool_type === 'QUICKSIGHT'){
        let options = {
          url: reportData.url,
          container: quicksightRef.current,
          printEnabled: true,
          parameters:{},
          width: "1000px",
          height: "1000px",
          className:'embed-bi mx-auto',
          sheetTabsDisabled: false,
        }
        if(reportData.project_parameter){
          options.parameters = {
            ...options.parameters,
            [reportData.project_parameter.parameter_name]:reportData.project_parameter.parameter_content
          }
        }
        if(reportData.group_parameter){
          const array = [];
          for(let i=0; i<reportData.group_parameter.length; i++){
            array.push(reportData.group_parameter[i].parameter_content);
          }
          options.parameters = {
            ...options.parameters,
            [reportData.group_parameter[0].parameter_name]:array
          }
        }
        if(reportData.user_role_parameter){
          options.parameters = {
            ...options.parameters,
            [reportData.user_role_parameter.parameter_name]:reportData.user_role_parameter.parameter_content
          }
        }
        if(reportData.user_parameter){
          options.parameters = {
            ...options.parameters,
            [reportData.user_parameter.parameter_name]:reportData.user_parameter.parameter_content
          }
        }
        if(reportData.parameter_list){
          for(let i=0; i<reportData.parameter_list.length; i++){
            options.parameters = {
              ...options.parameters,
              [reportData.parameter_list[i].parameter_name]:reportData.parameter_list[i].parameter_content
            }
          }
        }
        embedDashboard(options);
      }
    }
  }

  return(
  <div className="mx-auto mb-5">
    {
      reportData && (
        reportData.tool_type === 'QUICKSIGHT' && (
          <FullScreen handle={handle}>
            <div ref={quicksightRef}/>
            <Button
              variant="outline-dark"
              size="md"
              className="float-right"
              data-toggle="tooltip"
              data-placement="top"
              title="Fullscreen"
              onClick={handle.enter}
            >
              <Maximize2 size="15"/>
            </Button>
          </FullScreen>
        )
      )
    }
    {
      reportData && isLoadedReport && (
        reportData.tool_type === 'POWER_BI' && (
          <>
            <iframe
              title="power_bi"
              className="embed-bi"
              src={`${url}`}
              frameBorder="0"
              allowFullScreen="true"
            />
          </>
        )
      )
    }
  </div>
  );
};

UserReport.propTypes = {
  showUserReport: PropTypes.func.isRequired,
  reportData: PropTypes.object,
  isLoadedReport: PropTypes.bool.isRequired,
  userProfile:PropTypes.object
};

const stateToProps = state => ({
  reportData: state.reports.reportData,
  isLoadedReport: state.reports.isLoadedReport,
  userProfile: state.profile.userProfile
});

const actionsToProps = dispatch => ({
  showUserReport: payload => dispatch({ type: FETCH_USER_REPORT, payload })
});

export default connect(stateToProps, actionsToProps)(UserReport);
