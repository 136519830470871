import { Container, Col, Row, Button, Navbar, Nav, Image, Table, Badge } from 'react-bootstrap';
import logomagnus from '../../images/logo-magnus.svg';
import footer from '../../images/logo-footer.svg';
import line from '../../images/line.png';
import solution from '../../images/img-diagram-magnus.png';
import data from '../../images/img-data.png';
import magnus from '../../images/img-magnus.png';
import React, { useEffect, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { PopupButton } from 'react-calendly';
import { Link } from 'react-router-dom';
import ContactModal from '../contactModal';

const Landing = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <>
    {
      // Borrado className='landing'
    }
      <div>
        <Navbar collapseOnSelect expand="lg" bg={scroll ? "white" : "transparent"} variant="light" fixed="top">
          <Container fluid="md">
            <Navbar.Brand href="#home">
              <Image src={logomagnus} fluid alt="logo" width={100}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto mr-0 mr-lg-5 p-3">
                <AnchorLink offset={80} className="nav-link" href="#home">Inicio</AnchorLink>
                <AnchorLink offset={80} className="nav-link" href="#solution">Solución Cloud</AnchorLink>
                <AnchorLink offset={80} className="nav-link" href="#character">Características</AnchorLink>
                <AnchorLink offset={80} className="nav-link" href="#contact">Contacto</AnchorLink>
                <Button variant="dark" size="sm" id='btnIngresar'  href="/login">
                Ingresar
              </Button>
              </Nav>

             
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className='header'>
          <Container id="home">
            <Row className='d-flex align-items-center text-white'>
              <Col sm={12} md={6} className="bg-md-info bg-lg-red mt-md-5 mt-3">
                <p className='text-white-50'>Control de gestión de su empresa</p>
                <h1 className='mb-3'>Plataforma de control de gestión que se adapta a las necesidades de su negocio</h1>

                <h4 className='mb-5 text-white-50'>
                  20 años de experiencia potenciando la gestión corporativa de las empresas del middle market
                </h4>
                <PopupButton
                  url="https://calendly.com/magnuserp/demo"
                  className="btn btn-primary p-3"
                  rootElement={document.getElementById("root")}
                  text="Agendar una demo"
                />

              </Col>
            </Row>
          </Container>
        </div>

    
        <Container id="solution" className='py-5 my-5'>
          <Row className='my-5 my-md-5 my-lg-0 d-flex align-items-center'>
            <Col sm={12} md={6}>
              <p className='text-muted mb-0'>Solución Cloud</p>
              <div className='h1 mb-3'>Gestiona tu empresa de forma rápida y cloud</div>

              <div className='p mt-5'>
                Magnus es un software de Gestión Empresarial que incorpora un potente ERP, avanzadas integraciones para la ingesta y procesamiento de datos y un portal que mediante analítica avanzada consolida la información con visión estratégica.
              </div>
              <div className='p'>
                Magnus está diseñado para empresas medianas y grandes, cuyo objetivo es entregar una solución integral para resolver las necesidades del negocio del cliente, alineada con las normativas, procedimientos y prácticas tanto internas como externas.
              </div>
            </Col>
            <Col sm={12} md={{ span: 5, offset: 1 }} className="mt-5 mt-md-0">
              <Image src={solution} fluid alt="Portal de Servicios" className=''/>
            </Col>
          </Row>
        </Container>

        <div className='bg-light'>
          <Container className='py-5'>
            <Row className='pt-5'>
              <Col sm={12} md={10}>
                <div className='h4 text-info mb-4'>
                  Tener datos oportunos y precisos, sin la necesidad de un procesamiento manual complejo o iteraciones de hojas de cálculo, puede convertir el análisis de datos en un servicio de alto valor agregado para su empresa y sus clientes
                </div>
              </Col>
            </Row>
            <Row className='pb-5 pt-4 d-flex align-items-center'>
              <Col sm={12} md={5}>
                <div className='py-4'>
                  <div className='h5 font-weight-bold'>Los datos en el centro de las decisiones</div>
                  <div className="p mb-5">Con la contabilidad en segundo plano, tener datos en tiempo real permite la resolución preventiva de problemas..</div>

                  <div className='h5 font-weight-bold'>Gestión en tiempo real</div>
                  <div className="p mb-5">Las herramientas de informes pueden proporcionar impresiones en vivo sobre los temas más urgentes / relevantes y alimentar directamente la toma de decisiones de gestión.</div>

                  <div className='h5 font-weight-bold'>Planificación fiscal</div>
                  <div className="p mb-5">Inicie conversaciones en torno a la planificación fiscal con datos fiables, estructurados y oportunos.</div>

                  <div className='h5 font-weight-bold'>Flexibilidad</div>
                  <div className="p">Es una solución flexible, que se adapta a diversas industrias desde la agroindustrial, manufactura, farmacéutica y financiera entre otras</div>
                </div>
              </Col>
              <Col sm={12} md={7}>
                <Image src={data} fluid alt="Portal de Servicios" className=''/>
              </Col>
            </Row>
          </Container>
        </div>


        <Container className='p-0' fluid>
          <Image src={line} fluid={true} className="col-12 p-0"/>
        </Container>

        <Container id="character">
          <Row className='my-5 d-flex align-items-center'>
            <Col sm={12} md={6} className="mb-5 mb-md-0">
              <Image src={magnus} fluid alt="Caracteristicas Magnus"/>
            </Col>
            <Col sm={12} md={6}>
              <Badge pill style={{backgroundColor:"#e5e5e5"}}>
                Características de Magnus
              </Badge>

              <div className='h1 mb-5 mt-2 font-bold' style={{color:"#46a818"}}>Una plataforma pensada para tu negocio </div>

              <div className="p-4" style={{backgroundColor:"#e5e5e5"}}>
                <div className='p mb-4'>
                  • Imputación automática de costos y gastos, vía reglas particulares y avanzados algoritmos de inteligencia artificial, ajustados a cada empresa.
                </div>
                <div className='p mb-4'>
                  • Visualización y Consolidación de indicadores (ingresos, costos, resultados) por unidades de negocios, áreas geográficas, países, proyectos u otros.
                </div>
                <div className='p mb-4'>
                  • Solución modular, con la capacidad de incorporación vía suscripción o desarrollo de nuevos módulos e integraciones.
                </div>
                <div className='p mb-4'>
                  • Cuenta con certificación SOC 1 TIPO II
                </div>
                <div className='p mb-4'>
                  • Incorporamos soporte técnico-contable con SLA.
                </div>
                <div className='p mb-4'>
                  • SaaS, montado en Microsoft Azure Cloud
                </div>
                <div className='p'>
                  • Cumplimiento de las regulaciones de seguridad y privacidad de datos con los mas altos estándares.
                </div>
              </div>
              <Table className="mt-3 custom-line" borderless>
                <tbody className="custom-line">
                <td className="col-1 custom-line" style={{backgroundColor:"#cbcbcb"}}/>
                <td className=" p-0"/>
                <td className="col-4" style={{backgroundColor:"#46a818"}}/>
                <td className=" p-0"/>
                <td className="bg-secondary col-6"/>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>

        <div className="bg-separator">
          <Container>
            <Row>
              <Col sm={12} className="p-0">
                <div className='text-light my-4 d-block d-md-flex align-items-center justify-content-between'>
                  <div className='h1 mb-4 p-0'>Impulsa tu gestión empresarial<br></br> en tiempo real ahora.</div>
                  <PopupButton
                    url="https://calendly.com/magnuserp/demo"
                    className="btn btn-light mb-4 btn-lg"
                    rootElement={document.getElementById("root")}
                    text="Agendar una demo"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className='bg-contact mt-3'>
          <Container>
            <Row id="contact">
              <Col sm={12} className="px-auto px-md-0">
                <div className='text-light my-4 d-block d-md-flex align-items-center justify-content-between'>
                  <div>
                    <p className='text-white-50 mb-0'>Contacto</p>
                    <div className='h1 mb-4'>¿Te interesaria saber más?</div>
                    <div className='p'>Estaremos encantados de responder a cualquier pregunta.</div>
                  </div>
                  <div>
                    <ContactModal/>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="bg-separator mt-3">
          <Container>
            <Row>
              <Col sm={12} className="p-0">
                <div className='text-light my-4 d-block d-md-flex align-items-center justify-content-between'>
                  <div>
                    <div className='h1 mb-4'>Visita nuestra página web</div>
                    <div className='p'>Informate más del servicio integral que ofrecemos.</div>
                  </div>
                  <div>
                  <Button variant="light" size="lg" className='btn btn-light mb-4 btn-lg' target="_blank" href={'https://www.erpmagnus.cl/'}>
                    Visítanos
                  </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className='bg-light py-4 mt-5'>
          <Container>
            <Row>
              <Col xs={4} md={4}>
                <Image src={footer} fluid alt="Magnus Logo"/>
              </Col>
              <Col xs={4} md={4} className="text-right">
                <PopupButton
                  url="https://calendly.com/magnuserp/demo"
                  className="btn btn-link mt-2 p-0"
                  rootElement={document.getElementById("root")}
                  text="Agendar una demo"
                />
              </Col>
              <Col xs={4} md={4} className="text-right">
                <Button variant="link" size="md" className='p-0 mt-2' as={Link} to={"/login"}>
                  Portal clientes
                </Button>
              </Col>
            </Row>

            <Row>
              <Col xs={4} md={4}>
                <div className='p mt-3 small text-muted'>Todos los derechos reservados 2022</div>
                <div className='p small text-muted'>Cruz del Sur 133, piso 4 . Las Condes Santiago, Chile</div>
              </Col>

              <Col xs={4} md={4} className="text-right">
                <a href="callto:+56232539050" className='p align-text-bottom small text-muted text-lg-right'>Telefono:<br/> +56 2 3253 9050</a>
              </Col>
              <Col xs={4} md={4} className="text-right">
                <a href="mailto:soporte@erpmagnus.cl" className='p small text-muted pull-right text-right'>Email:<br/> soporte@erpmagnus.cl</a>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

    </>
  );
};

export default Landing;
