import {
  FLAG_DELETE_LOG
} from './types';

const initialState = {
  deleteLogFlag: false
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case FLAG_DELETE_LOG:
    return { ...state, deleteLogFlag: payload };
  default:
    return state;
  }
};

export default reducer;
