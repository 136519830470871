import config from '../config';

const { userProfileURI, userProfileDataURI, updatePasswordURI, zohoURI, notificationURI } = config().services;

const network = client => ({
  getProfile: () => client.get(userProfileURI),
  updatePassword: ({ password }) => client.patch(updatePasswordURI, { password }),
  sendTicket: payload => client.post(zohoURI, payload ),
  getNotifications: () => client.get(notificationURI),
  patchProfile: (payload) => client.patch(userProfileDataURI, payload),
});

export default network;
