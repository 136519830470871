import { Col, Row } from 'react-bootstrap';
import React from 'react';
import ZohoModal from './navbar/zohoModal';
import { Smartphone, Headphones } from 'react-feather'
// import zohoModal from './navbar/zohoModal';
import { ChevronLeft, ChevronRight } from 'react-feather';


import { useState, useEffect } from 'react';
import banner1 from '../images/banner_soporte1.png';
import banner2 from '../images/banner_soporte2.png';


const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [banner1, banner2];

  const phrases = [
    {
      title: 'Aqui podemos ayudarte',
      text1: '¿Estas utilizando el portal y tienes dudas?',
      text2: 'Puede contactarnos por los seguiente medios de comuncación para resolver tus dudas o consultas.',
      button: <ZohoModal type={3} style={{ color: '#FFF' }} />,
      button2: <a  href="https://wa.me/message/KBUU3CI3PK7GB1" target="_blank"><button className='btn_contact'><Smartphone className='icon_smartphone' />WhatsApp</button></a>,
    },
    {
      title: '¿Sabias que contamos con un chatbot?',
      text1: '¡Pruébenlo y experimenten la facilidad y rapidez que ofrece nuestro chat bot!',
      text2: 'Este chat bot esta basado en una inteligencia artificial que puede ayudarlos a responder preguntas relacionadas con el contexto de TAX y asuntos contables. ',
      /*button: <a  href="/chatbot"><button className='btn_contactTwo'><Headphones className='icon_smartphone' />Asistente Virtual</button></a>,
      button1: <ZohoModal type={3} style={{ color: '#FFF' }} />,*/
  
    }
  ];


/*
  useEffect(() => {
    const intervalId = setInterval(() => {

      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setTimeout(() => {
        const img = document.querySelector('.fade_in')
        if (img) {
          img.classList.remove('show')
        }
      }, 9500)

    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const img = document.querySelector('.fade_in')
      if (img) {
        img.classList.add('show')
      }
    }, 1000)
  }, [currentIndex])
*/



const handleLeftClick = () => {
  const img = document.querySelector('.fade_in');
  img.classList.add('fade-out');
  setTimeout(() => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  }, 500);
};

const handleRightClick = () => {
  const img = document.querySelector('.fade_in');
  img.classList.add('fade-out');
  setTimeout(() => {
    setCurrentIndex((currentIndex + 1) % images.length);
  }, 500);
};

useEffect(() => {
  const img = document.querySelector('.fade_in');
  if (img) {
    const timerId = setTimeout(() => {
      img.classList.remove('fade-out');
    }, 1000);
    return () => clearTimeout(timerId);
  }
}, [currentIndex]);
  

  return (
    <div className="container_fadein">
      <ChevronLeft className='chevronLeft' size={50} onClick={handleLeftClick} />
      <div className="fade_in">
        <Row className='d-flex justify-content-center text-center' id='container_fade'>
          {
            window.innerWidth < 1024 ? (
              <Col lg={6} sm={12} className='container_texts_soporte'>
              <h1 className='title_soporte'>{phrases[currentIndex].title}</h1>
              <div className='phrases-container'>
                <p className='p_one_soporte'>{phrases[currentIndex].text1}</p>
                <p className='p_two_soporte'>{phrases[currentIndex].text2}</p>
                {phrases[currentIndex].button}
                {phrases[currentIndex].button3}
              </div>
            </Col>
            ):(
              <Col lg={6} sm={12} className='container_texts_soporte'>
              <h1 className='title_soporte'>{phrases[currentIndex].title}</h1>
              <div className='phrases-container'>
                <p className='p_one_soporte'>{phrases[currentIndex].text1}</p>
                <p className='p_two_soporte'>{phrases[currentIndex].text2}</p>
                {phrases[currentIndex].button1}
                {phrases[currentIndex].button2}
                {phrases[currentIndex].button}
              </div>
            </Col>
            )
          }
      
          <Col lg={6} sm={12}>
            <div className="container_img">
              <img src={images[currentIndex]} alt='banner_soporte' className='banner_soporte' />
            </div>
          </Col>
        </Row>
      </div>
      <ChevronRight className='chevronRight' size={50} onClick={handleRightClick} />
    </div>
  );
};

export default Home;
