import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const ExpiredToken = () => {
  const history = useHistory();

  return (
    <Card className="border-0">
      <Card.Body className="text-center p-5">
        <img  alt="" className="mb-4" />
        <h3 className="py-2 font-weight-bold title-color text-center">
          Link expirado
        </h3>
        <p className="text-center">
          Este link se encuentra expirado, solicite uno nuevo.
        </p>
        <Button
          className="btn-rounded mt-4"
          variant="primary"
          onClick={() => history.push('/')}
        >
          Ir a inicio de sesión
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ExpiredToken;
