import React, { useEffect, useState } from 'react';
import { Row, Breadcrumb, Col, Button, Form, Spinner, Accordion, Card, FormGroup, FormControl } from 'react-bootstrap';
import { Prompt, useParams } from 'react-router-dom';
import { CheckCircle } from 'react-feather';
import PropTypes from 'prop-types';
import {
  FETCH_GROUP,
  FETCH_GROUP_ADMINS,
  FETCH_GROUP_USERS,
  FETCH_LIST_PROJECTS,
  PATCH_GROUP
} from '../../store/types';
import { connect } from 'react-redux';

const EditGroup = (
  {
    showGroup,
    groupData,
    isLoadedGroup,
    patchGroup,
    showGroupAdmins,
    groupAdminList,
    showProjects,
    errors,
    loadingForm,
    showGroupUsers,
    groupUserList,
    userProfile
  }) => {
  const [step, setStep] = React.useState(0);
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [groupForm, setGroupForm] = useState({
    admin:[],
    user:[]
  });
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [promptAux, setPromptAux] = useState(true);
  const [triggerForm, setTriggerForm] = useState(false);
  const [flag, setFlag] = useState(false);
  const [serviceError, setServiceError] = useState(null);
  const [auxAdminProject, setAuxAdminProject] = useState(null);
  const [valueSearch, setValueSearch] = useState("");
  const [valueSearchUser, setValueSearchUser] = useState("");
  const [auxAdminSelected, setAuxAdminSelected] = useState([]);
  const [auxUserSelected, setAuxUserSelected] = useState([]);

  useEffect(() => {
    showProjects({ valueSearch: '' });
  }, [showProjects]);

  useEffect(() => {
    showGroupAdmins(valueSearch);
  }, [showGroupAdmins, valueSearch]);

  useEffect(() => {
    showGroup(uuid);
  }, [showGroup, uuid]);

  useEffect(() => {
    showGroupUsers(valueSearchUser);
  }, [showGroupUsers, valueSearchUser]);

  useEffect(() => {
    if (
      Object.keys(groupForm).some(key => groupForm[key] === ''
      )
    ) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [groupForm]);

  useEffect(() => {
    window.onbeforeunload = (event) => {
      if(triggerForm === true){
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
      }
    };
  },);

  const alert = () => {
    if (serviceError) {
      return (
        serviceError.map((error) => {
          return (<div key={error}>
            {error !== undefined ? (
              `-${error}`
            ) : (
              ''
            )}
          </div>);
        })
      );
    }
  };

  const handleSearch = ({ target }) => {
    setValueSearch(target.value);
  }

  useEffect(() => {
    if(groupData){
      const arrayAdmin = [];
      const arrayUsers = [];
      if(groupData.admin.length !== 0) {
        for(let i=0; i<groupData.admin.length; i++){
          arrayAdmin.push(groupData.admin[i].uuid);
        }
        setAuxAdminSelected(arrayAdmin);
      }
      if(groupData.user_group.length !== 0) {
        for(let i=0; i<groupData.user_group.length; i++){
          arrayUsers.push(groupData.user_group[i].uuid);
        }
        setAuxUserSelected(arrayUsers);
      }
    }
  }, [groupData, setAuxAdminSelected, setAuxUserSelected]);

  const handleInputChange = ({ target }) => {
    if(target.id === "adminsCheckbox" || target.id === "usersCheckbox"){
      if(target.id === "adminsCheckbox"){
        const formData = {...groupForm}
        if(formData.admin.includes(target.value)){
          const pos = formData.admin.indexOf(target.value);
          formData.admin.splice(pos,1);
          const auxForm = auxAdminSelected;
          auxForm.splice(pos,1);
          setGroupForm(formData);
          setAuxAdminSelected(auxForm);
        }else{
          formData.admin.push(target.value);
          const aux = {name:target.name, value:target.value}
          setGroupForm(formData);
          auxAdminSelected.push(aux);
        }
      }
      if(target.id === "usersCheckbox"){
        const formData = {...groupForm}
        if(formData.user.includes(target.value)){
          const pos = formData.user.indexOf(target.value);
          formData.user.splice(pos,1);
          const auxForm = auxUserSelected;
          auxForm.splice(pos,1);
          setGroupForm(formData);
          setAuxUserSelected(auxForm);
        }else{
          formData.user.push(target.value);
          const aux = {name:target.name, value:target.value}
          setGroupForm(formData);
          auxUserSelected.push(aux);
        }
      }
    } else{
      if(!triggerForm){
        setTriggerForm(true)
      }
      const formData = { ...groupForm, [target.name]: target.value };
      setGroupForm(formData);
    }
  };

  const sendData = () => {
    const formData = groupForm;
    if(formData.admin.length === 0)
      delete formData.admin;
    if(formData.user.length === 0)
      delete formData.user;
    setPromptAux(false);
    setTriggerForm(false);
    setServiceError(null);
    setLoading(true);
    patchGroup({ formData, uuid });
    setServiceError(errors);
    setFlag(true);
  }

  useEffect(() => {
    setServiceError(null);
  }, [errors]);

  useEffect(() => {
    if(flag){
      if (!loadingForm && errors !== null) {
        setServiceError(errors);
        setLoading(false);
      }
      if (loadingForm === false && errors === null) {
        setStep(step + 1);
        setFlag(false);
      }
    }
  }, [loadingForm, errors, flag, step]);

  useEffect(() => {
    if(groupAdminList){
      for(let i = 0; i < groupAdminList.length; i++){
        if(groupAdminList[i].uuid === groupForm.admin){
          setAuxAdminProject(groupAdminList[i]);
        }
      }
    }
  }, [groupForm.admin, groupAdminList]);

  useEffect(() => {
    if(auxAdminProject){
      if(auxAdminProject.project)
        setGroupForm({ ...groupForm, project: auxAdminProject.project.uuid });
    }// eslint-disable-next-line
  }, [auxAdminProject]);

  return (
    isLoadedGroup === true ? (
      <Form>
        <Row className="mb-4">
          <Prompt
            when={triggerForm && promptAux}
            message={`¿Estás seguro de desear abandonar este formulario? Los datos ingresados no serán guardados.`}
          />
          <Col>
            <Breadcrumb className="small">
              <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
              <Breadcrumb.Item
                href={
                  userProfile && (
                    userProfile.profile.user_role === "SUPER_ADMIN" ? (
                      `/admin/group`
                    ):(
                      `/admin/group#1?search=&project=${localStorage.project}&ordering=`
                    )
                  )
                }
              >
                Administración grupo sociedad
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Editar grupo</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {step === 0 && (
          <Row className="mb-4">
            <Col xl="5" lg="6" className="mx-auto">
              <h4 className="font-weight-bold mb-4">Datos principales</h4>
              <Form.Group controlId="group_name">
                <Form.Label className="font-weight-bold small">
                  Nombre de grupo
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa un nombre"
                  name="group_name"
                  type="group_name"
                  defaultValue={groupData.group_name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="group_description">
                <Form.Label className="font-weight-bold small">
                  Descripción
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Escribe aquí..."
                  name="group_description"
                  type="group_description"
                  defaultValue={groupData.group_description}
                  onChange={handleInputChange}
                />
              </Form.Group>
              {
                groupAdminList && (
                  (groupAdminList.length !== 0 || valueSearch !== "") && (
                    <>
                      <Form.Label className="font-weight-bold small">
                        Designar Administrador/es de grupo
                      </Form.Label>
                      <Accordion defaultActiveKey="1" className="mb-4">
                        <Card>
                          <Card.Header className="bg-transparent text-muted">
                            Seleccione
                          </Card.Header>
                          <Card.Body eventKey="1">
                            <Card.Body className="py-0">
                              <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                                <FormControl
                                  className='mb-2'
                                  size='md'
                                  placeholder='Buscar...'
                                  onChange={handleSearch}
                                />
                              </FormGroup>
                              <Form.Group controlId="adminsCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                                {
                                  groupAdminList.map( (admin) =>{
                                    return (
                                      <Form.Check type="checkbox" key={admin.uuid} label={`${admin.first_name} ${admin.last_name}`} name={`${admin.first_name} ${admin.last_name}`} value={admin.uuid} defaultChecked={auxAdminSelected.includes(admin.uuid)}/>
                                    );
                                  })
                                }
                              </Form.Group>
                            </Card.Body>
                          </Card.Body>
                        </Card>
                      </Accordion>
                    </>
                  )
                )
              }
              {
                groupUserList && (
                  (groupUserList.length !== 0 || valueSearchUser !== "") && (
                    <>
                      <Form.Label className="font-weight-bold small">
                        Asignar usuarios al grupo
                      </Form.Label>
                      <Accordion defaultActiveKey="1" className="mb-4">
                        <Card>
                          <Card.Header className="bg-transparent text-muted">
                            Seleccione
                          </Card.Header>
                          <Card.Body eventKey="1">
                            <Card.Body className="py-0">
                              <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                                <FormControl
                                  className='mb-2'
                                  size='md'
                                  placeholder='Buscar...'
                                  onChange={({target})=>setValueSearchUser(target.value)}
                                />
                              </FormGroup>
                              <Form.Group controlId="usersCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                                {
                                  groupUserList && (
                                    groupUserList.map( (user) =>{
                                      return (
                                        <Form.Check type="checkbox" key={user.uuid} label={`${user.first_name} ${user.last_name}`} name={`${user.first_name} ${user.last_name}`} value={user.uuid} defaultChecked={auxUserSelected.includes(user.uuid)}/>
                                      );
                                    })
                                  )
                                }
                              </Form.Group>
                            </Card.Body>
                          </Card.Body>
                        </Card>
                      </Accordion>
                    </>
                  )
                )
              }
              <span className="text-danger">
                (*)
                <span className="text-black-50">
                  Campos obligatorios
                </span>
              </span>
              {
                serviceError ? (
                  <div className="alert alert-danger my-3" role="alert">
                    {alert()}
                  </div>
                ) : null
              }
              <Button
                className="mt-2"
                onClick={sendData}
                disabled={disableSubmit || loading || loadingForm}
                size="lg"
                block
              >
                {
                  loading || loadingForm ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  ) : (
                    ''
                  )
                }{' '}
                Guardar grupo sociedad
              </Button>
            </Col>
          </Row>
        )}
        {step === 1 && (
          <Row>
            <Col xs="12" className="text-center">
              <CheckCircle size="48" className="text-success mb-4"/>
              <h5 className="font-weight-bold mb-4">Grupo guardado con éxito</h5>
              <Button
                className="mx-2"
                href={
                  userProfile && (
                    userProfile.profile.user_role === "SUPER_ADMIN" ? (
                      `/admin/group`
                    ):(
                      `/admin/group#1?search=&project=${localStorage.project}&ordering=`
                    )
                  )
                }
              >Ir a grupos</Button>
            </Col>
          </Row>
        )}
      </Form>
    ):('')
  )
}

EditGroup.propTypes = {
  patchGroup: PropTypes.func.isRequired,
  showGroup: PropTypes.func.isRequired,
  groupData: PropTypes.object,
  isLoadedGroup: PropTypes.bool.isRequired,
  showGroupAdmins: PropTypes.func.isRequired,
  groupAdminList: PropTypes.array,
  showProjects: PropTypes.func.isRequired,
  projectsList: PropTypes.array,
  errors: PropTypes.array
};

const stateToProps = state => ({
  groupData: state.groups.groupData,
  groupUserList: state.groups.groupUserList,
  isLoadedGroup: state.groups.isLoadedGroup,
  errors: state.groups.errors,
  groupAdminList: state.groups.groupAdminList,
  projectsList: state.projects.projectsList,
  userProfile: state.profile.userProfile,
  loadingForm: state.groups.loadingForm
});

const actionsToProps = dispatch => ({
  patchGroup: payload => dispatch({ type: PATCH_GROUP, payload }),
  showGroup: payload => dispatch({ type: FETCH_GROUP, payload }),
  showGroupAdmins: payload => dispatch({ type: FETCH_GROUP_ADMINS, payload }),
  showGroupUsers: payload => dispatch({ type: FETCH_GROUP_USERS, payload }),
  showProjects: payload => dispatch({ type: FETCH_LIST_PROJECTS, payload })
});

export default connect(stateToProps, actionsToProps)(EditGroup);
