import React from 'react';
import { LoginForm } from '../../components';

const LoginView = () => {
  return (
    <div className="bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-lg-6 mx-auto">
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginView;
