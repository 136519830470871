import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Form, FormControl, Spinner } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import validator from '../../utils/validator';
import { registerSchema } from '../../utils/validator/schemas';
import { DISPATCH_CONFIRM_ACCOUNT, PATCH_PASSWORD, POST_DATA_RESET } from '../../store/types';
import SuccessPass from '../../views/changePassword/successPassView';

const PasswordForm = ({ updatePassword, resetPassword, errors, getConfirmToken, tokenConfirmed, errorsUpdate, passChanged }) => {
  const history = useHistory();
  const { token } = useParams();
  const [passwordForm, setPasswordForm] = useState({ password: '', password_confirmation: '' });
  const [validationError, setValidationError] = useState({ password: '', password_confirmation: '' });
  const [disableSubmit, setDisableSubmit] = useState(true);

  useEffect(() => {
    if(token)
      getConfirmToken({ token, history });
  }, [token, getConfirmToken, history]);

  useEffect(() => {
    if (
      Object.keys(validationError).length ||
      Object.keys(passwordForm).some(key => passwordForm[key] === '')
    ) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [validationError, passwordForm]);

  const handleSubmit = event => {
    event.preventDefault();
    if (token) {
      resetPassword({ token, ...passwordForm, history });
     } else {
       updatePassword({ ...passwordForm, history });
     }
  };

  const handleInputChange = ({ target }) => {
    const formData = { ...passwordForm, [target.name]: target.value };
    setPasswordForm(formData);
    delete validationError[target.name];
    setValidationError(validationError);
    const errors = validator({ [target.name]: registerSchema[target.name] }, formData);
    if (errors.length > 0) {
      const messages = errors
        .map(e => ({ [e.field]: e.message }))
        .reduce((prev, current) => ({ ...prev, ...current }), {});
      setValidationError({ ...validationError, ...messages });
      return false;
    }
  };

  return (
    <>
      {
        token ?(
          tokenConfirmed ? (
            <>
              <Card className="bg-transparent border-0">
                <div className="card-body">
                  <h2 className="py-3 text-center font-weight-bold title-color mb-5">
                    Cambio de contraseña
                  </h2>
                  <Form noValidate onSubmit={handleSubmit} className="pr-lg-4">
                    <Form.Group controlId="password" className="my-5">
                      <Form.Label className="logs-font font-weight-bold">Ingresa nueva contraseña</Form.Label>
                      <FormControl
                        className="secondary-text-color"
                        name="password"
                        size='lg'
                        type={'password'}
                        placeholder="Ingresa tu contraseña"
                        autoComplete="password"
                        onChange={handleInputChange}
                        isInvalid={validationError.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError.password}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="password_confirmation" className="my-5">
                      <Form.Label className="logs-font font-weight-bold">Repite nueva contraseña</Form.Label>
                      <FormControl
                        className="secondary-text-color"
                        name="password_confirmation"
                        type={'password'}
                        size='lg'
                        placeholder="Repite tu contraseña"
                        autoComplete="password_confirmation"
                        onChange={handleInputChange}
                        isInvalid={validationError.password_confirmation}
                      />
                      <Form.Control.Feedback type="invalid">
                        {validationError.password_confirmation}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {errors ? (
                      <div className="alert alert-danger my-1 p-2 mb-3" role="alert">
                        {errors}
                      </div>
                    ) : null
                    }
                    <Button
                      className="btn-rounded col-12 base-btn-color "
                      size='lg'
                      variant="primary"
                      type="submit"
                      disabled={disableSubmit}>
                      {'Cambiar Contraseña'}
                    </Button>
                  </Form>
                </div>
              </Card>
            </>
          ):(
            <div className="spinner-container text-center py-5">
              <Spinner animation="border" className="spinner-custom" />
            </div>
          )
        ):(
          !passChanged ? (
            <>
              <h2 className="py-3 text-center font-weight-bold title-color mb-5">
                Cambio de contraseña
              </h2>
              <Form noValidate onSubmit={handleSubmit} className="pr-lg-4">
                <Form.Group controlId="password" className="my-5">
                  <Form.Label className="logs-font font-weight-bold">Ingresa nueva contraseña</Form.Label>
                  <FormControl
                    className="secondary-text-color"
                    name="password"
                    size='lg'
                    type={'password'}
                    placeholder="Ingresa tu contraseña"
                    autoComplete="password"
                    onChange={handleInputChange}
                    isInvalid={validationError.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationError.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="password_confirmation" className="my-5">
                  <Form.Label className="logs-font font-weight-bold">Repite nueva contraseña</Form.Label>
                  <FormControl
                    className="secondary-text-color"
                    name="password_confirmation"
                    type={'password'}
                    size='lg'
                    placeholder="Repite tu contraseña"
                    autoComplete="password_confirmation"
                    onChange={handleInputChange}
                    isInvalid={validationError.password_confirmation}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationError.password_confirmation}
                  </Form.Control.Feedback>
                </Form.Group>
                {errorsUpdate ? (
                  <div className="alert alert-danger my-1 p-2 mb-3" role="alert">
                    {errorsUpdate}
                  </div>
                ) : null
                }
                <Button
                  className="btn-rounded col-12 base-btn-color "
                  size='lg'
                  variant="primary"
                  type="submit"
                  disabled={disableSubmit}>
                  {'Cambiar Contraseña'}
                </Button>
              </Form>
            </>
          ):(
            <SuccessPass/>
          )
        )
      }

    </>
  );
};

PasswordForm.propTypes = {
  updatePassword: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  errors: PropTypes.string
};

const stateToProps = state => ({
  errors: state.auth.errors,
  errorsUpdate: state.profile.errors,
  passChanged: state.profile.passChanged,
  tokenConfirmed: state.auth.tokenConfirmed
});

const actionsToProps = dispatch => ({
  updatePassword: payload => dispatch({ type: PATCH_PASSWORD, payload }),
  resetPassword: payload => dispatch({ type: POST_DATA_RESET, payload }),
  getConfirmToken: payload => dispatch({ type: DISPATCH_CONFIRM_ACCOUNT, payload })
});

export default connect(stateToProps, actionsToProps)(PasswordForm);
