import React, { useEffect, useState } from 'react';
import { Row, Breadcrumb, Col, Button, Form, Spinner, Accordion, Card, FormGroup, FormControl } from 'react-bootstrap';
import { Link, Prompt } from 'react-router-dom';
import { CheckCircle } from 'react-feather'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_GROUP_ADMINS, FETCH_GROUP_USERS, POST_GROUPS } from '../../store/group/types';
import { FETCH_LIST_PROJECTS } from '../../store/project/types';

const CreateGroup = ({showGroupUsers, groupUserList, postGroups, showGroupAdmins, groupAdminList, projectsList, showProjects, errors, loadingForm, userProfile}) => {
  const [step, setStep] = React.useState(0);
  const [triggerForm, setTriggerForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promptAux, setPromptAux] = useState(true);
  const [flag, setFlag] = useState(false);
  const [serviceError, setServiceError] = useState(null);
  const [auxAdminProject, setAuxAdminProject] = useState(null);
  const [groupForm, setGroupForm] = useState({
    group_name: '',
    group_description: '',
    admin: [],
    user: [],
    project: ''
  });
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [valueSearch, setValueSearch] = useState("");
  const [valueSearchUser, setValueSearchUser] = useState("");
  const [auxAdminSelected, setAuxAdminSelected] = useState([]);
  const [auxUserSelected, setAuxUserSelected] = useState([]);

  useEffect(() => {
    window.onbeforeunload = (event) => {
      if(triggerForm === true){
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
      }
    };
  },);

  const handleInputChange = ({ target }) => {
    if(!triggerForm){
      setTriggerForm(true)
    }
    if(target.id === "adminsCheckbox" || target.id === "usersCheckbox"){
      if(target.id === "adminsCheckbox"){
        const formData = {...groupForm}
        if(formData.admin.includes(target.value)){
          const pos = formData.admin.indexOf(target.value);
          formData.admin.splice(pos,1);
          const auxForm = auxAdminSelected;
          auxForm.splice(pos,1);
          setGroupForm(formData);
          setAuxAdminSelected(auxForm);
        }else{
          formData.admin.push(target.value);
          const aux = {name:target.name, value:target.value}
          setGroupForm(formData);
          auxAdminSelected.push(aux);
        }
      }
      if(target.id === "usersCheckbox"){
        const formData = {...groupForm}
        if(formData.user.includes(target.value)){
          const pos = formData.user.indexOf(target.value);
          formData.user.splice(pos,1);
          const auxForm = auxUserSelected;
          auxForm.splice(pos,1);
          setGroupForm(formData);
          setAuxUserSelected(auxForm);
        }else{
          formData.user.push(target.value);
          const aux = {name:target.name, value:target.value}
          setGroupForm(formData);
          auxUserSelected.push(aux);
        }
      }
    } else{

      const formData = { ...groupForm, [target.name]: target.value };
      setGroupForm(formData);
    }
  };

  const alert = () => {
    if (serviceError) {
      return (
        serviceError.map((error) => {
          return (<div key={error}>
            {error !== undefined ? (
              `-${error}`
            ) : (
              ''
            )}
          </div>);
        })
      );
    }
  };

  const sendData = () => {
    const formData = groupForm;
    if(formData.admin.length === 0)
      delete formData.admin;
    if(formData.user.length === 0)
      delete formData.user;
    setPromptAux(false);
    setTriggerForm(false);
    setServiceError(null);
    setLoading(true);
    postGroups(formData);
    setServiceError(errors);
    setFlag(true);
  }

  useEffect(() => {
    if(flag){
      if (!loadingForm && errors !== null) {
        setServiceError(errors);
        setLoading(false);
      }
      if (loadingForm === false && errors === null) {
        setStep(1);
        setFlag(false);
      }
    }
  }, [loadingForm, errors, flag]);

  useEffect(() => {
    if (
      Object.keys(groupForm).some(key => groupForm[key] === '')
    ) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [groupForm]);

  useEffect(() => {
    showProjects({ valueSearch: '' });
  }, [showProjects]);

  useEffect(() => {
    setServiceError(null);
  }, [errors]);

  useEffect(() => {
    showGroupAdmins(valueSearch);
  }, [showGroupAdmins, valueSearch]);

  useEffect(() => {
    showGroupUsers(valueSearchUser);
  }, [showGroupUsers, valueSearchUser]);

  //useEffect(() => {
  //  if(userProfile.profile){
  //    if(userProfile.profile.user_role === 'SUPER_ADMIN'){
  //      showProjects({ valueSearch: '' });
  //      setGroupForm({...groupForm, project: ''});
  //    }
  //  }// eslint-disable-next-line
  //}, [showGroupAdmins, showProjects, userProfile.profile]);

  useEffect(() => {
    if(groupAdminList){
      for(let i = 0; i < groupAdminList.length; i++){
        if(groupAdminList[i].uuid === groupForm.admin){
          setAuxAdminProject(groupAdminList[i]);
        }
      }
    }
  }, [groupForm.admin, groupAdminList]);

  useEffect(() => {
    if(auxAdminProject){
      if(auxAdminProject.project)
        setGroupForm({ ...groupForm, project: auxAdminProject.project.uuid });
    }// eslint-disable-next-line
  }, [auxAdminProject]);

  const handleSearch = ({ target }) => {
    setValueSearch(target.value);
  }

  return (
    <Form>
      <Row className="mb-4">
        <Prompt
          when={triggerForm && promptAux}
          message={`¿Estás seguro de desear abandonar este formulario? Los datos ingresados no serán guardados.`}
        />
        <Col>
          <Breadcrumb className="small">
            <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
            <Breadcrumb.Item
              href={
                userProfile.profile && (
                  userProfile.profile.user_role === "SUPER_ADMIN" ? (
                    `/admin/group`
                  ):(
                    `/admin/group#1?search=&project=${localStorage.project}&ordering=`
                  )
                )
              }
            >
              Administración grupo sociedad
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Crear grupo</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {step === 0 && (
        <Row className="mb-4">
          <Col xl="5" lg="6" className="mx-auto">
            <h4 className="font-weight-bold mb-4">Datos principales</h4>
            <Form.Group controlId="group_name">
              <Form.Label className="font-weight-bold small">
                Nombre de grupo
                <span className="ml-1 text-danger small">
                  (*)
                </span>
              </Form.Label>
              <Form.Control
                size="lg"
                placeholder="Ingresa un nombre"
                name="group_name"
                type="group_name"
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="group_description">
              <Form.Label className="font-weight-bold small">
                Descripción
                <span className="ml-1 text-danger small">
                  (*)
                </span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Escribe aquí..."
                name="group_description"
                type="group_description"
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="project">
              <Form.Label className="font-weight-bold small">
                Asignar proyecto al grupo
                <span className="ml-1 text-danger small">
                  (*)
                </span>
              </Form.Label>
              <Form.Control
                as="select"
                custom placeholder="Selecciona"
                name="project"
                type="project"
                onChange={handleInputChange}
              >
                <option value="" className="secondary-text-color" disabled selected> - </option>
                {
                  projectsList.map( (project) =>{
                    return (
                      <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                    );
                  })
                }
              </Form.Control>
            </Form.Group>
            {
              groupAdminList && (
                (groupAdminList.length !== 0 || valueSearch !== "") && (
                  <>
                    <Form.Label className="font-weight-bold small">
                      Designar Administrador/es de grupo
                    </Form.Label>
                    <Accordion defaultActiveKey="1" className="mb-4">
                      <Card>
                        <Card.Header className="bg-transparent text-muted">
                          Seleccione
                        </Card.Header>
                        <Card.Body eventKey="1">
                          <Card.Body className="py-0">
                            <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                              <FormControl
                                className='mb-2'
                                size='md'
                                placeholder='Buscar...'
                                onChange={handleSearch}
                              />
                            </FormGroup>
                            <Form.Group controlId="adminsCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                              {
                                groupAdminList.map( (admin) =>{
                                  return (
                                    <Form.Check type="checkbox" key={admin.uuid} label={`${admin.first_name} ${admin.last_name}`} name={`${admin.first_name} ${admin.last_name}`} value={admin.uuid}/>
                                  );
                                })
                              }
                            </Form.Group>
                          </Card.Body>
                        </Card.Body>
                      </Card>
                    </Accordion>
                  </>
                )
              )
            }
            {
              groupUserList && (
                (groupUserList.length !== 0 || valueSearchUser !== "") && (
                  <>
                    <Form.Label className="font-weight-bold small">
                      Asignar usuarios al grupo
                    </Form.Label>
                    <Accordion defaultActiveKey="1" className="mb-4">
                      <Card>
                        <Card.Header className="bg-transparent text-muted">
                          Seleccione
                        </Card.Header>
                        <Card.Body eventKey="1">
                          <Card.Body className="py-0">
                            <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                              <FormControl
                                className='mb-2'
                                size='md'
                                placeholder='Buscar...'
                                onChange={({target})=>setValueSearchUser(target.value)}
                              />
                            </FormGroup>
                            <Form.Group controlId="usersCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                              {
                                groupUserList.map( (user) =>{
                                  return (
                                    <Form.Check type="checkbox" key={user.uuid} label={`${user.first_name} ${user.last_name}`} name={`${user.first_name} ${user.last_name}`} value={user.uuid}/>
                                  );
                                })
                              }
                            </Form.Group>
                          </Card.Body>
                        </Card.Body>
                      </Card>
                    </Accordion>
                  </>
                )
              )
            }
            <span className="text-danger">
              (*)
              <span className="text-black-50">
                Campos obligatorios
              </span>
            </span>
            {
              serviceError ? (
                <div className="alert alert-danger my-3" role="alert">
                  {alert()}
                </div>
              ) : null
            }
            <Button
              className="mt-2"
              onClick={sendData}
              disabled={disableSubmit || loading || loadingForm }
              size="lg"
              block
            >
              {
                loading || loadingForm ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  ''
                )
              }{' '}
              Crear grupo sociedad
            </Button>
          </Col>
        </Row>
      )}
      {step === 1 && (
        <Row>
          <Col xs="12" className="text-center">
            <CheckCircle size="48" className="text-success mb-4"/>
            <h5 className="font-weight-bold mb-4">Grupo creado con éxito</h5>
            <Button
              className="mx-2"
              as={Link}
              to={
                userProfile && (
                  userProfile.profile.user_role === "SUPER_ADMIN" ? (
                    `/admin/group`
                  ):(
                    `/admin/group#1?search=&project=${localStorage.project}&ordering=`
                  )
                )
              }
            >
              Ir a grupos
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  )
}

CreateGroup.propTypes = {
  postGroups: PropTypes.func.isRequired,
  showGroupAdmins: PropTypes.func.isRequired,
  groupAdminList: PropTypes.array,
  showProjects: PropTypes.func.isRequired,
  projectsList: PropTypes.array
};

const stateToProps = state => ({
  groupAdminList: state.groups.groupAdminList,
  groupUserList: state.groups.groupUserList,
  errors: state.groups.errors,
  projectsList: state.projects.projectsList,
  userProfile: state.profile.userProfile,
  loadingForm: state.groups.loadingForm
});

const actionsToProps = dispatch => ({
  postGroups: payload => dispatch({ type: POST_GROUPS, payload }),
  showGroupAdmins: payload => dispatch({ type: FETCH_GROUP_ADMINS, payload }),
  showGroupUsers: payload => dispatch({ type: FETCH_GROUP_USERS, payload }),
  showProjects: payload => dispatch({ type: FETCH_LIST_PROJECTS, payload })
});

export default connect(stateToProps, actionsToProps)(CreateGroup);
