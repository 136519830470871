import React, { /*Children*/ useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Navbar, Sidebar } from './components';
import cookieHandler from './utils/helpers/cookie.handler';
import { FETCH_NOTIFICATIONS, FETCH_USER_PROFILE } from './store/profile/types';
import { POST_LOGOUT } from './store/auth/types';
import moment from 'moment';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { POST_LOG_OUT, POST_LOGS } from './store/logs/types';


const Layout = (

  {
    Component,
    path,
    exact = false,
    showNav = true,
    guestRoute = false,
    projectAdminRoute = false,
    groupAdminRoute = false,
    adminRoute = false,
    showSide = true,
    isLoggedIn,
    fetchProfile,
    userLoaded,
    userProfile,
    logout,
    isLoaded,
    deleteLogFlag,
    postLog,
    postLogout,
    fetchNotifications,
    notificationLoaded
  }) => {






  const { getAccessToken, removeToken, getPrimaryColor } = cookieHandler();

  const token = getAccessToken();

  const currentPath = window.location.pathname;

  const [expires, setExpires] = useState(moment().add(15, 'm').toDate());

  /*const [icon, setIcon] = useState(true)*/



  let root = document.documentElement;


  const [color, setColor] = useState('#005A9F');

  const siteUrl = window.location.href;

  root.style.setProperty('--color', color);
  root.style.setProperty('--degradedColor', color + "7d");

  useEffect(() => {
    let logData = { browsing_url: siteUrl };
    switch (true) {
      case siteUrl.includes('/projects'):
        logData = { ...logData, browsing_module: 'PROJECT' };
        break;
      case siteUrl.includes('/group'):
        logData = { ...logData, browsing_module: 'GROUP' };
        break;
      case siteUrl.includes('/society'):
        logData = { ...logData, browsing_module: 'SOCIETY' };
        break;
      case siteUrl.includes('/users'):
        logData = { ...logData, browsing_module: 'USER' };
        break;
      case siteUrl.includes('/reports'):
        logData = { ...logData, browsing_module: 'REPORT' };
        break;
      case siteUrl.includes('/documents') || siteUrl.includes('/documents_user'):
        logData = { ...logData, browsing_module: 'DOCUMENT' };
        break;
      case siteUrl.includes('/accounting'):
        logData = { ...logData, browsing_module: 'ACCOUNTING' };
        break;
      default:
        break;
    }
    switch (true) {
      case siteUrl.includes('/detail'):
        logData = { ...logData, browsing_view: 'GET' };
        break;
      case siteUrl.includes('/edit'):
        logData = { ...logData, browsing_view: 'UPDATE' };
        break;
      case siteUrl.includes('/create'):
        logData = { ...logData, browsing_view: 'CREATE' };
        break;
      default:
        logData = { ...logData, browsing_view: 'GET' };
    }
    if (deleteLogFlag)
      logData.browsing_view = 'DELETE'
    postLog(logData);
  }, [siteUrl, deleteLogFlag, postLog]);


  useEffect(() => {
    if (isLoaded && currentPath === "/") {
      if (getPrimaryColor())
        setColor(`#${getPrimaryColor()}`);
      else
        setColor('#005A9F');
    } else {
      if (getPrimaryColor())
        setColor(`#${getPrimaryColor()}`);
      else
        setColor('#005A9F');
    }
  }, [getPrimaryColor, isLoaded, currentPath]);


  useEffect(() => {
    const protectedRoutes = [
      '/admin/projects/create',
      '/admin/group/create',
      '/admin/society/create',
      '/admin/projects/edit',
      '/admin/group/edit',
      '/admin/society/edit'
    ];
    window.onbeforeunload = (event) => {
      if (!protectedRoutes.includes(window.location.pathname)) {
        window.onbeforeunload = null;
      }
    };

  },);

  useEffect(() => {
    if (token || isLoggedIn) {
      fetchProfile();
    }
    if (token === undefined && isLoggedIn && userLoaded) {
      postLogout()
      //logout();
    }
  }, [token, fetchProfile, isLoggedIn, logout, userLoaded]);

  useEffect(() => {
    if (isLoggedIn && !notificationLoaded)
      fetchNotifications();
  }, [isLoggedIn, notificationLoaded, fetchNotifications]);

  useEffect(() => {
    if (isLoggedIn) {
      setExpires(moment().add(15, 'm').toDate());
    }
  }, [currentPath, isLoggedIn]);

  useEffect(() => {
    let interval;
    if (isLoggedIn) {
      interval = setInterval(function () {
        if (String(expires) === String(moment().toDate())) {
          postLogout()
          //logout()
          //removeToken();
          alert('Sesión terminada por inactividad')
        }
      }, 300000);
    }
    return () => clearInterval(interval);
  }, [currentPath, expires, isLoggedIn, postLogout]);

  useEffect(() => {
    // Comprueba si el usuario está conectado
    if (isLoggedIn) {
      // Elimina el valor intervalSet de localStorage
      localStorage.removeItem('intervalSet');
      // Comprueba si el intervalo ya ha sido establecido
      if (!localStorage.getItem('intervalSet')) {
        let interval;
        interval = setInterval(function () {
          postLogout();
          // Limpia el valor en localStorage cuando se cierra la sesión
          localStorage.removeItem('intervalSet');
          alert('Por seguridad estamos cerrando sesión.')
        }, 3300000);
        // Almacena un valor en localStorage para indicar que el intervalo ha sido establecido
        localStorage.setItem('intervalSet', 'true');
        return () => clearInterval(interval);
      }
    }
  }, [isLoggedIn]);
  const showNavbar = () => (showNav ? <Navbar currentPath={currentPath} /> : null);

  const showSidebar = () => (showSide ? <Sidebar /> : null);

  const renderView = componentProps => {
    if (userLoaded) {
      if (userProfile.profile) {

        if (userProfile.profile.user_role === 'GROUP_ADMIN' && !groupAdminRoute) {
          return <Redirect to={{ pathname: '/home' }} />;
        }
        if (userProfile.profile.user_role === 'PROJECT_ADMIN' && !projectAdminRoute) {
          return <Redirect to={{ pathname: '/home' }} />;
        }
        if (guestRoute) {
          return <Redirect to={{ pathname: '/home' }} />;
        }
      }
    }
    if (!userLoaded && adminRoute && !token) {
      return <Redirect to={{ pathname: '/' }} />;
    }
    return <Component {...componentProps} />
  };

  return (
    <>
      {
        localStorage.header_title ? (
          <HelmetProvider>
            <Helmet>
              <title>{localStorage.header_title}</title>
              {
                localStorage.icon && (
                  <link rel='icon' href={`${process.env.REACT_APP_SERVICE_URL}${localStorage.icon}`} />
                )
              }
            </Helmet>
          </HelmetProvider>
        ) : (
          <HelmetProvider>
            <Helmet>
              <title>Portal RSM</title>
              <link rel="canonical" href="http://localhost:3000/" />
            </Helmet>
          </HelmetProvider>
        )
      }

      {showNavbar()}

      <div className='d-flex justify-content-between' id='wrapper'>
        {
          showSide && (
            <div id='sidebar-wrapper'>
              {showSidebar()}
            </div>
          )
        }

        <div id='page-content-wrapper'>
          <Route exact={exact} path={path} render={renderView} />
        </div>
      </div>
    </>
  );
};



<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>


window.addEventListener('DOMContentLoaded', event => {

  // Comprobar a que ruta estamos
  const currentPath = window.location.pathname;
  console.log(currentPath)
 
 /* if (currentPath === '/home') {
    if (document.querySelector('#page-content-wrapper')) {
      const pagecontentwrapper = document.querySelector('#page-content-wrapper')
      //pagecontentwrapper.style.minWidth = '100vw'
    }
  }*/

  // Llamada al botón toggle
  const sidebarToggle = document.body.querySelector('#sidebarToggle');

  // Si botón toggle es presionado executa el código abajo
  if (sidebarToggle) {
    // Dispara el evento
    sidebarToggle.addEventListener('click', event => {


      // Lógica iconos menus animación Para Desktop
      let b = window.matchMedia("(min-width: 768px)")
      if (b.matches) {

        /*if (currentPath === '/home' && document.querySelector('#viewHome2')) {
          const container = document.querySelector('#viewHome2')
          container.classList.toggle('container-primary-js')
          if((container.classList == 'container-primary-js')){
            container.style.paddingRight = '0%'
          }else {
           container.style.paddingRight = '15em'
          }
        }*/


        if (document.querySelector('#pryecto9')) {

          const pryecto9 = document.querySelector('#pryecto9')

          pryecto9.classList.toggle('fixedPosition')

          if (pryecto9.classList.contains('fixedPosition')) {

            pryecto9.style.position = 'fixed'
            pryecto9.style.left = '10px'
            pryecto9.style.zIndex = '10000'
            pryecto9.style.transition = 'all 2s'

          } else {

            pryecto9.style.position = 'unset'
            pryecto9.style.left = '0'
            pryecto9.style.zIndex = '0'
          }
        }
        if (document.querySelector('#pryecto12')) {

          const pryecto12 = document.querySelector('#pryecto12')

          pryecto12.classList.toggle('fixedPosition')

          if (pryecto12.classList.contains('fixedPosition')) {

            pryecto12.style.position = 'fixed'
            pryecto12.style.left = '10px'
            pryecto12.style.zIndex = '10000'
            pryecto12.style.transition = 'all 2s'

          } else {

            pryecto12.style.position = 'unset'
            pryecto12.style.left = '0'
            pryecto12.style.zIndex = '0'
          }
        }
        if (document.querySelector('#pryecto10')) {

          const pryecto10 = document.querySelector('#pryecto10')

          pryecto10.classList.toggle('fixedPosition')

          if (pryecto10.classList.contains('fixedPosition')) {

            pryecto10.style.position = 'fixed'
            pryecto10.style.left = '10px'
            pryecto10.style.zIndex = '10000'
            pryecto10.style.transition = 'all 2s'

          } else {

            pryecto10.style.position = 'unset'
            pryecto10.style.left = '0'
            pryecto10.style.zIndex = '0'
          }
        }
        if (document.querySelector('#pryecto11')) {

          const pryecto11 = document.querySelector('#pryecto11')

          pryecto11.classList.toggle('fixedPosition')

          if (pryecto11.classList.contains('fixedPosition')) {

            pryecto11.style.position = 'fixed'
            pryecto11.style.left = '10px'
            pryecto11.style.zIndex = '10000'
            pryecto11.style.transition = 'all 2s'

          } else {

            pryecto11.style.position = 'unset'
            pryecto11.style.left = '0'
            pryecto11.style.zIndex = '0'
          }
        }
        if (document.querySelector('#pryectos')) {

          const pryectos = document.querySelector('#pryectos')

          pryectos.classList.toggle('fixedPosition')

          if (pryectos.classList.contains('fixedPosition')) {

            pryectos.style.position = 'fixed'
            pryectos.style.left = '10px'
            pryectos.style.zIndex = '10000'
            pryectos.style.transition = 'all 2s'

          } else {

            pryectos.style.position = 'unset'
            pryectos.style.left = '0'
            pryectos.style.zIndex = '0'
          }
        }
        if (document.querySelector('#pryectos2')) {

          const pryectos2 = document.querySelector('#pryectos2')

          pryectos2.classList.toggle('fixedPosition')

          if (pryectos2.classList.contains('fixedPosition')) {

            pryectos2.style.position = 'fixed'
            pryectos2.style.left = '10px'
            pryectos2.style.zIndex = '10000'
            pryectos2.style.transition = 'all 2s'

          } else {

            pryectos2.style.position = 'unset'
            pryectos2.style.left = '0'
            pryectos2.style.zIndex = '0'
          }
        }
        if (document.querySelector('#pryectos3')) {

          const pryectos3 = document.querySelector('#pryectos3')

          pryectos3.classList.toggle('fixedPosition')

          if (pryectos3.classList.contains('fixedPosition')) {

            pryectos3.style.position = 'fixed'
            pryectos3.style.left = '10px'
            pryectos3.style.zIndex = '10000'
            pryectos3.style.transition = 'all 2s'

          } else {

            pryectos3.style.position = 'unset'
            pryectos3.style.left = '0'
            pryectos3.style.zIndex = '0'
          }
        }
        if (document.querySelector('#pryectos4')) {

          const pryectos4 = document.querySelector('#pryectos4')

          pryectos4.classList.toggle('fixedPosition')

          if (pryectos4.classList.contains('fixedPosition')) {

            pryectos4.style.position = 'fixed'
            pryectos4.style.left = '10px'
            pryectos4.style.zIndex = '10000'
            pryectos4.style.transition = 'all 2s'

          } else {

            pryectos4.style.position = 'unset'
            pryectos4.style.left = '0'
            pryectos4.style.zIndex = '0'
          }
        }
        if (document.querySelector('#pryectos5')) {

          const pryectos5 = document.querySelector('#pryectos5')

          pryectos5.classList.toggle('fixedPosition')

          if (pryectos5.classList.contains('fixedPosition')) {

            pryectos5.style.position = 'fixed'
            pryectos5.style.left = '10px'
            pryectos5.style.zIndex = '10000'
            pryectos5.style.transition = 'all 2s'

          } else {

            pryectos5.style.position = 'unset'
            pryectos5.style.left = '0'
            pryectos5.style.zIndex = '0'
          }
        }
        if (document.querySelector('#pryectos8')) {

          const pryectos8 = document.querySelector('#pryectos8')

          pryectos8.classList.toggle('fixedPosition')

          if (pryectos8.classList.contains('fixedPosition')) {

            pryectos8.style.position = 'fixed'
            pryectos8.style.left = '10px'
            pryectos8.style.zIndex = '10000'
            pryectos8.style.transition = 'all 2s'

          } else {

            pryectos8.style.position = 'unset'
            pryectos8.style.left = '0'
            pryectos8.style.zIndex = '0'
          }
        }
        if (document.querySelector('#pryectos6')) {

          const pryectos6 = document.querySelector('#pryectos6')

          pryectos6.classList.toggle('fixedPosition')

          if (pryectos6.classList.contains('fixedPosition')) {

            pryectos6.style.position = 'fixed'
            pryectos6.style.left = '48px'
            pryectos6.style.top = '446px'
            pryectos6.style.zIndex = '10000'
            pryectos6.style.transition = 'all 2s'

          } else {

            pryectos6.style.position = 'unset'
            pryectos6.style.left = '0'
            pryectos6.style.zIndex = '0'
          }
        }
        if (document.querySelector('#pryectos7')) {

          const pryectos7 = document.querySelector('#pryectos7')

          pryectos7.classList.toggle('fixedPosition')

          if (pryectos7.classList.contains('fixedPosition')) {

            pryectos7.style.position = 'fixed'
            pryectos7.style.left = '48px'
            pryectos7.style.top = '476px'
            pryectos7.style.zIndex = '10000'
            pryectos7.style.transition = 'all 2s'

          } else {

            pryectos7.style.position = 'unset'
            pryectos7.style.left = '0'
            pryectos7.style.zIndex = '0'
          }
        }
        if (document.querySelector('#misReportes')) {

          const misReportes = document.querySelector('#misReportes')

          misReportes.classList.toggle('fixedPosition')

          if (misReportes.classList.contains('fixedPosition')) {

            misReportes.style.position = 'fixed'
            misReportes.style.left = '10px'
            misReportes.style.top = '195px'
            misReportes.style.zIndex = '9000'
            misReportes.style.transition = 'all 2s'

          } else {

            misReportes.style.position = 'unset'
            misReportes.style.left = '0'
            misReportes.style.zIndex = '0'
          }
        }
        if (document.querySelector('#miContabilidad')) {

          const miContabilidad = document.querySelector('#miContabilidad')

          miContabilidad.classList.toggle('fixedPosition')

          if (miContabilidad.classList.contains('fixedPosition')) {

            miContabilidad.style.position = 'fixed'
            miContabilidad.style.left = '10px'
            miContabilidad.style.top = '195px'
            miContabilidad.style.zIndex = '9000'
            miContabilidad.style.transition = 'all 2s'

          } else {

            miContabilidad.style.position = 'unset'
            miContabilidad.style.left = '0'
            miContabilidad.style.zIndex = '0'
          }
        }



      }

      // Lógica iconos menus animación Para Mobile
      let a = window.matchMedia("(max-width: 767px)")
      if (a.matches) {

       /*if (currentPath === '/home' && document.querySelector('#viewHome2')) {
          const container = document.querySelector('#viewHome2')
          container.classList.toggle('container-primary-js')
          if((container.classList == 'container-primary-js')){
            container.style.paddingRight = '0%'
          }else {
           container.style.paddingRight = '0em'
          }
        }*/


        if (document.querySelector('#pryecto9')) {

          const pryecto9 = document.querySelector('#pryecto9')

          pryecto9.classList.toggle('fixedPosition')

          if ((pryecto9.classList == 'fixedPosition')) {
            pryecto9.style.position = 'unset'
            pryecto9.style.left = '0'
            pryecto9.style.zIndex = '0'
          } else {

            pryecto9.style.position = 'fixed'
            pryecto9.style.left = '7px'
            pryecto9.style.zIndex = '10000'
            pryecto9.style.transition = 'all 2s'

          }
        }
        if (document.querySelector('#pryecto12')) {

          const pryecto12 = document.querySelector('#pryecto12')

          pryecto12.classList.toggle('fixedPosition')

          if ((pryecto12.classList == 'fixedPosition')) {


            pryecto12.style.position = 'unset'
            pryecto12.style.left = '0'
            pryecto12.style.zIndex = '0'

          } else {
            pryecto12.style.position = 'fixed'
            pryecto12.style.left = '7px'
            pryecto12.style.zIndex = '10000'
            pryecto12.style.transition = 'all 2s'

          }
        }
        if (document.querySelector('#pryecto10')) {

          const pryecto10 = document.querySelector('#pryecto10')

          pryecto10.classList.toggle('fixedPosition')

          if ((pryecto10.classList == 'fixedPosition')) {

            pryecto10.style.position = 'unset'
            pryecto10.style.left = '0'
            pryecto10.style.zIndex = '0'

          } else {

            pryecto10.style.position = 'fixed'
            pryecto10.style.left = '7px'
            pryecto10.style.zIndex = '10000'
            pryecto10.style.transition = 'all 2s'

          }
        }
        if (document.querySelector('#pryecto11')) {

          const pryecto11 = document.querySelector('#pryecto11')

          pryecto11.classList.toggle('fixedPosition')

          if ((pryecto11.classList == 'fixedPosition')) {
            pryecto11.style.position = 'unset'
            pryecto11.style.left = '0'
            pryecto11.style.zIndex = '0'


          } else {
            pryecto11.style.position = 'fixed'
            pryecto11.style.left = '7px'
            pryecto11.style.zIndex = '10000'
            pryecto11.style.transition = 'all 2s'

          }
        }

        if (document.querySelector('#pryectos')) {

          const pryectos = document.querySelector('#pryectos')

          pryectos.classList.toggle('fixedPosition')

          if ((pryectos.classList == 'fixedPosition')) {


            pryectos.style.position = 'unset'
            pryectos.style.left = '0'
            pryectos.style.zIndex = '0'

          } else {
            pryectos.style.position = 'fixed'
            pryectos.style.left = '7px'
            pryectos.style.zIndex = '10000'
            pryectos.style.transition = 'all 2s'

          }
        }
        if (document.querySelector('#pryectos2')) {

          const pryectos2 = document.querySelector('#pryectos2')

          pryectos2.classList.toggle('fixedPosition')

          if ((pryectos2.classList == 'fixedPosition')) {

            pryectos2.style.transition = 'all 2s'
            pryectos2.style.position = 'unset'
            pryectos2.style.left = '0'
            pryectos2.style.zIndex = '0'

          } else {


            pryectos2.style.position = 'fixed'
            pryectos2.style.left = '7px'
            pryectos2.style.zIndex = '10000'

          }
        }
        if (document.querySelector('#pryectos3')) {

          const pryectos3 = document.querySelector('#pryectos3')

          pryectos3.classList.toggle('fixedPosition')

          if ((pryectos3.classList == 'fixedPosition')) {

            pryectos3.style.position = 'unset'
            pryectos3.style.left = '0'
            pryectos3.style.zIndex = '0'


          } else {

            pryectos3.style.position = 'fixed'
            pryectos3.style.left = '7px'
            pryectos3.style.zIndex = '10000'
            pryectos3.style.transition = 'all 2s'

          }
        }
        if (document.querySelector('#pryectos4')) {

          const pryectos4 = document.querySelector('#pryectos4')

          pryectos4.classList.toggle('fixedPosition')

          if ((pryectos4.classList == 'fixedPosition')) {

            pryectos4.style.position = 'unset'
            pryectos4.style.left = '0'
            pryectos4.style.zIndex = '0'


          } else {

            pryectos4.style.position = 'fixed'
            pryectos4.style.left = '7px'
            pryectos4.style.zIndex = '10000'
            pryectos4.style.transition = 'all 2s'

          }
        }
        if (document.querySelector('#pryectos5')) {

          const pryectos5 = document.querySelector('#pryectos5')

          pryectos5.classList.toggle('fixedPosition')

          if ((pryectos5.classList == 'fixedPosition')) {

            pryectos5.style.position = 'unset'
            pryectos5.style.left = '0'
            pryectos5.style.zIndex = '0'

          } else {


            pryectos5.style.position = 'fixed'
            pryectos5.style.left = '7px'
            pryectos5.style.zIndex = '10000'
            pryectos5.style.transition = 'all 2s'

          }
        }
        if (document.querySelector('#pryectos8')) {

          const pryectos8 = document.querySelector('#pryectos8')

          pryectos8.classList.toggle('fixedPosition')

          if ((pryectos8.classList == 'fixedPosition')) {


            pryectos8.style.position = 'unset'
            pryectos8.style.left = '0'
            pryectos8.style.zIndex = '0'

          } else {

            pryectos8.style.position = 'fixed'
            pryectos8.style.left = '7px'
            pryectos8.style.zIndex = '10000'
            pryectos8.style.transition = 'all 2s'

          }
        }

        if (document.querySelector('#pryectos6')) {

          const pryectos6 = document.querySelector('#pryectos6')

          pryectos6.classList.toggle('fixedPosition')

          if ((pryectos6.classList == 'fixedPosition')) {


            pryectos6.style.position = 'unset'
            pryectos6.style.left = '0'
            pryectos6.style.zIndex = '0'

          } else {

            pryectos6.style.position = 'fixed'
            pryectos6.style.left = '27px'
            pryectos6.style.top = '415'
            pryectos6.style.zIndex = '10000'
            pryectos6.style.transition = 'all 2s'

          }
        }
        if (document.querySelector('#pryectos7')) {

          const pryectos7 = document.querySelector('#pryectos7')

          pryectos7.classList.toggle('fixedPosition')

          if ((pryectos7.classList == 'fixedPosition')) {


            pryectos7.style.position = 'unset'
            pryectos7.style.left = '0'
            pryectos7.style.zIndex = '0'

          } else {
            pryectos7.style.position = 'fixed'
            pryectos7.style.left = '27px'
            pryectos7.style.top = '458'
            pryectos7.style.zIndex = '10000'
            pryectos7.style.transition = 'all 2s'
          }
        }

        if (document.querySelector('#misReportes')) {

          const misReportes = document.querySelector('#misReportes')

          misReportes.classList.toggle('fixedPosition')

          if ((misReportes.classList == 'fixedPosition')) {

            misReportes.style.position = 'unset'
            misReportes.style.left = '0'
            misReportes.style.zIndex = '0'


          } else {
            misReportes.style.position = 'fixed'
            misReportes.style.left = '-7px'
            misReportes.style.top = '164px'
            misReportes.style.zIndex = '9000'
            misReportes.style.transition = 'all 2s'

          }
        }
        if (document.querySelector('#miContabilidad')) {

          const miContabilidad = document.querySelector('#miContabilidad')

          miContabilidad.classList.toggle('fixedPosition')

          if ((miContabilidad.classList == 'fixedPosition')) {

            miContabilidad.style.position = 'unset'
            miContabilidad.style.left = '0'
            miContabilidad.style.zIndex = '0'

          } else {

            miContabilidad.style.position = 'fixed'
            miContabilidad.style.left = '-7px'
            miContabilidad.style.top = '164px'
            miContabilidad.style.zIndex = '9000'
            miContabilidad.style.transition = 'all 2s'

          }
        }
      }


      /*
            if (b.matches) {

              if (currentPath === '/home' && document.querySelector('#iconsImg') && document.querySelector('#contenedorPrincipalIndicador')) {
                const iconsImg = document.querySelector('#iconsImg')
                const contenedorPrincipalIndicador = document.querySelector('#contenedorPrincipalIndicador')
                iconsImg.classList.toggle('iconsImg2')
                contenedorPrincipalIndicador.classList.toggle('iconsImg2')
                if ((iconsImg.classList == 'col-2 iconsImg2')) {
      
                  iconsImg.style.marginLeft = '0%'
                  contenedorPrincipalIndicador.style.marginLeft = '0%'
                } else {
                  iconsImg.style.marginLeft = '-0%'
                  contenedorPrincipalIndicador.style.marginLeft = '-0%'
                }
              }
            }
            if (a.matches) {
      
              if (currentPath === '/home' && document.querySelector('#iconsImg') && document.querySelector('#contenedorPrincipalIndicador')) {
      
                const iconsImg = document.querySelector('#iconsImg')
                const contenedorPrincipalIndicador = document.querySelector('#contenedorPrincipalIndicador')
      
                iconsImg.classList.toggle('iconsImg2')
                contenedorPrincipalIndicador.classList.toggle('iconsImg2')
      
      
                if ((iconsImg.classList == 'col-2 iconsImg2')) {
      
                  iconsImg.style.marginLeft = '0%'
                  contenedorPrincipalIndicador.style.marginLeft = '0%'
      
      
      
                } else {
                  iconsImg.style.marginLeft = '-29%'
                  contenedorPrincipalIndicador.style.marginLeft = '-29%'
                }
      
      
      
              }
            }
      
      */






      event.preventDefault();
      //Sidebar principal
      document.body.classList.toggle('sb-sidenav-toggled');
      localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
    });




  }




});






Layout.propTypes = {
  Component: PropTypes.func.isRequired,
  fetchProfile: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  showNav: PropTypes.bool,
  showSide: PropTypes.bool,
  guestRoute: PropTypes.bool,
  privateRoute: PropTypes.bool,
  isLoggedIn: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  postLog: PropTypes.func.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
  notifications: PropTypes.array,
  notificationLoaded: PropTypes.bool
};

const stateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
  userProfile: state.profile.userProfile,
  userLoaded: state.profile.userLoaded,
  isLoaded: state.auth.isLoaded,
  deleteLogFlag: state.logs.deleteLogFlag,
  notifications: state.profile.notifications,
  notificationLoaded: state.profile.notificationLoaded,
});

const actionToProps = dispatch => ({
  fetchProfile: () => dispatch({ type: FETCH_USER_PROFILE }),
  logout: payload => dispatch({ type: POST_LOGOUT, payload }),
  postLog: payload => dispatch({ type: POST_LOGS, payload }),
  postLogout: payload => dispatch({ type: POST_LOG_OUT, payload }),
  fetchNotifications: payload => dispatch({ type: FETCH_NOTIFICATIONS, payload })
});

export default connect(stateToProps, actionToProps)(Layout);
