import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Router from './router';
import store from './store';
import Ask from './components/ask/Ask';
import './scss/app.scss';

const App = () => (
  <Provider store={store}>
    <Ask />
    <Router></Router>
  </Provider>
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
