import {
  SET_IS_LOGGED_IN,
  SET_AUTH_CHECKING,
  SET_AUTH_ERRORS,
  SEND_EMAIL,
  CONFIRM_TOKEN,
  SITE_DATA,
  LOADING_DATA, DATA_LOADED, SET_EMAIL_ERRORS, LOADING_EMAIL_FORM
} from './types';

const initialState = {
  isLoggedIn: false,
  loadingForm: false,
  errors: null,
  tokenConfirmed: false,
  isSending: false,
  siteData: null,
  loadingData: false,
  isLoaded: false,
  loadingContact: false,
  contactErrors: []
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case SET_IS_LOGGED_IN:
    return { ...state, isLoggedIn: payload };
  case SET_AUTH_CHECKING:
    return { ...state, loadingForm: payload };
  case CONFIRM_TOKEN:
    return { ...state, tokenConfirmed: payload };
  case SET_AUTH_ERRORS:
    return { ...state, errors: payload };
  case SEND_EMAIL:
    return { ...state, isSending: payload };
  case SITE_DATA:
    return { ...state, siteData: payload };
  case LOADING_DATA:
    return { ...state, loadingData: payload };
  case DATA_LOADED:
    return { ...state, isLoaded: payload };
  case LOADING_EMAIL_FORM:
    return { ...state, loadingContact: payload };
  case SET_EMAIL_ERRORS:
    return { ...state, contactErrors: payload };
  default:
    return state;
  }
};

export default reducer;
