import React, { useEffect } from 'react';
import { Badge, Breadcrumb, Col, Row, Table } from 'react-bootstrap';
import { ConfigProvider } from 'react-avatar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProjectItem from '../project_item';
import GroupItem from '../group_item';
import SocietyItem from '../society_item';
import { FETCH_ACCOUNTING } from '../../store/accounting/types';
import { useParams } from 'react-router-dom';

const AccountingDetail = ({showAccounting, accountingData}) => {
  const { uuid } = useParams();

  useEffect(() => {
    showAccounting(uuid);
  }, [showAccounting, uuid]);


  useEffect(() => {
    console.log(accountingData);
  }, [accountingData]);


  return (
    <>
      {
        accountingData && (
          <ConfigProvider colors={['#F2BBAD', '#E3F2FD']}>
            <>
              <Row>
                <Col className='p-0'>
                  <Breadcrumb className="small">
                    <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
                    <Breadcrumb.Item>Modulos</Breadcrumb.Item>
                    <Breadcrumb.Item >Contabilidad</Breadcrumb.Item>
                    <Breadcrumb.Item active>Detalle Asignación</Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>

              <Row>
                <Col sm="4" lg="5" className="d-flex align-items-center">
                  <h4 className="font-weight-bold mb-1">{accountingData.menu_name}</h4>
                </Col>
                <Col>
                  <h6 className="float-md-right m-0 mt-2">Fecha de creación: {accountingData.created.date}</h6>
                </Col>
              </Row>
              <hr />
              <Row className="mb-3">
                <Col lg="6">
                  <h6 className="font-weight-bold">Descripción del reporte</h6>
                  <p className="small">{accountingData.description}</p>
                </Col>
              </Row>
              <Row className="mb-5 mt-3">
                <Col>
                  <h6 className="font-weight-bold">Tipos de usuarios</h6>
                </Col>

                <Col xs="12">
                  {
                    accountingData.enable_project_admin === true && (
                      <Badge variant="info" className="mr-2" pill>Admin de proyecto</Badge>
                    )
                  }
                  {
                    accountingData.enable_group_admin === true && (
                      <Badge variant="danger" className="mr-2" pill>Admin de grupo</Badge>
                    )
                  }
                  {
                    accountingData.enable_user_group === true && (
                      <Badge variant="warning" className="mr-2" pill>Usuario de grupo</Badge>
                    )
                  }
                  {
                    accountingData.enable_user_society === true && (
                      <Badge variant="dark" className="mr-2" pill>Usuario de sociedad</Badge>
                    )
                  }
                </Col>
              </Row>
              {
                accountingData.project_list && (
                  <>
                    <Row>
                      <Col>
                        <h6 className="font-weight-bold">Proyectos</h6>
                      </Col>
                    </Row>
                    <Table>
                      <tbody striped hoover>
                        {
                          accountingData.project_list.map( (project) => {
                            return (
                              <ProjectItem key={project.uuid} uuid={project.uuid} name={project.project_name} readOnly="true"/>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </>
                )
              }
              {
                accountingData.group_list && (
                  <>
                    <Row>
                      <Col>
                        <h6 className="font-weight-bold">Grupos</h6>
                      </Col>
                    </Row>
                    <Table>
                      <tbody striped hover>
                        {
                          accountingData.group_list.map( (group) => {
                            return (
                              <GroupItem key={group.uuid} uuid={group.uuid} name={group.group_name} readOnly="true"/>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </>
                )
              }
              {
                accountingData.society_list && (
                  <>
                    <Row>
                      <Col>
                        <h6 className="font-weight-bold">Sociedades</h6>
                      </Col>
                    </Row>
                    <Table striped hovere>
                      <tbody>
                        {
                          accountingData.society_list.map( (society) => {
                            return (
                              <SocietyItem key={society.uuid} uuid={society.uuid} name={society.society_business_name} readOnly="true"/>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </>
                )
              }
            </>
          </ConfigProvider>
        )
      }
    </>
  )
}

AccountingDetail.propTypes = {
  showAccounting: PropTypes.func.isRequired,
  accountingData: PropTypes.object,
  isLoaded: PropTypes.bool.isRequired
};

const stateToProps = state => ({
  accountingData: state.accounting.accountingData,
  isLoaded: state.accounting.isLoaded
});

const actionsToProps = dispatch => ({
  showAccounting: payload => dispatch({ type: FETCH_ACCOUNTING, payload })
});

export default connect(stateToProps, actionsToProps)(AccountingDetail);
