import { takeLatest, put, call } from 'redux-saga/effects';
import {
  deleteInvitation,
  deleteUser,
  getInvitationsSearchList,
  getUserData,
  resendInvitation,
  searchUser,
  sendInvitation,
  updateUser
} from '../../network';
import {
  SET_DATA_LIST_USERS,
  FETCH_LIST_USERS,
  IS_LOADED,
  INDEX_PAGINATION_USERS,
  POST_INVITATION,
  POST_CSV,
  LOADING_CSV,
  DELETE_USER,
  FETCH_USER,
  IS_LOADED_USER,
  SET_DATA_USER,
  SET_IMPORT_ERRORS,
  LOADING_USER_FORM,
  SET_USER_ERRORS,
  PATCH_USER,
  DELETE_USER_ERRORS,
  SET_DATA_LIST_INVITATIONS,
  INDEX_PAGINATION_INVITATIONS,
  RESEND_INVITATION,
  DELETE_INVITATION, INVITATION_LOADING, INVITATION_ERRORS, RESEND_LOADING, INVITATION_SENT
} from './types';
import messageValidationFailed from '../../utils/helpers/messageValidationFailed';
import formsErrors from '../../utils/errors/formsErrors';
import registerErrors from '../../utils/errors/formsErrors';
import cookieHandler from '../../utils/helpers/cookie.handler';
import axios from 'axios';

const axiosPost = ( payload ) => {
  const { getAccessToken } = cookieHandler();
  const token = getAccessToken();
  const headers = {
    'Authorization': `Bearer ${token}`,
  }
  return axios.post(`${process.env.REACT_APP_SERVICE_URL}/api/v1/register/load-register/csv/`, payload, {headers})
}

function* showUsers({ payload }) {
  try {
    let response = "";
    let responseInvitations = "";
    const { queryValue, paginationPosition, paginationPositionInvitations, usersInScreen } = payload;
    response = yield call(searchUser, `${queryValue}&limit=${usersInScreen}&offset=${(paginationPosition - 1) * usersInScreen}`);
    responseInvitations = yield call(getInvitationsSearchList, `${queryValue}&limit=${usersInScreen}&offset=${(paginationPositionInvitations - 1) * usersInScreen}`);
    yield put({ type: IS_LOADED, payload: false });
    yield put({ type: SET_DATA_LIST_USERS, payload: response.data.results });
    yield put({ type: SET_DATA_LIST_INVITATIONS, payload: responseInvitations.data.results });
    yield put({ type: IS_LOADED, payload: true });
    yield put({ type: INDEX_PAGINATION_USERS, payload: Math.ceil(response.data.count/usersInScreen) });
    yield put({ type: INDEX_PAGINATION_INVITATIONS, payload: Math.ceil(responseInvitations.data.count/usersInScreen) });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* postInvitation({ payload }) {
  try {
    yield put({ type: INVITATION_LOADING, payload: true });
    yield call(sendInvitation, payload);
    yield put({ type: INVITATION_ERRORS, payload: null });
    yield put({ type: INVITATION_LOADING, payload: false });
  } catch (e) {
    const { response } = e;
    if (response && response.status === 400) {
      yield put({ type: INVITATION_ERRORS, payload: ["Usuario ya invitado y/o registrado"] });
    }
    yield put({ type: INVITATION_LOADING, payload: false });
    // TODO: handle error
    console.log(e);
  }
}

function* postCsvRegister({ payload }) {
  let errors = [];
  try {
    yield put({ type: SET_IMPORT_ERRORS, payload: null });
    yield put({ type: LOADING_CSV, payload: true });
    const response = yield call( axiosPost, payload );
    if(response){
      yield put({ type: SET_USER_ERRORS, payload: null });
      yield put({ type: LOADING_CSV, payload: false });
    }
  } catch (e) {
    yield put({ type: LOADING_CSV, payload: true });
    const { response } = e;
    if (response.status === 400) {
      if (response.data.file_csv) {
        errors = [...errors, messageValidationFailed(response.data.file_csv, 'archivo csv.')];
      }
      yield put({ type: SET_USER_ERRORS, payload: errors });
      yield put({ type: LOADING_CSV, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_USER_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: LOADING_CSV, payload: false });
    }
    // TODO: handle error
    console.log(e);
  }
}

function* showUser({ payload }) {
  try {
    const response = yield call(getUserData, payload);
    yield put({ type: IS_LOADED_USER, payload: false })
    yield put({ type: SET_DATA_USER, payload: response.data });
    yield put({ type: IS_LOADED_USER, payload: true })
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* deleteUserData({ payload }) {
  try {
    yield call(deleteUser, payload);
    window.location.reload();
  } catch (e) {
    const { response } = e;
    if (response.status === 409) {
      yield put({ type: DELETE_USER_ERRORS, payload: true });
    }
    // TODO: handle error
    console.log(e);
  }
}

function* patchUser({ payload }) {
  let errors = [];
  try {

    
   
    yield put({ type: LOADING_USER_FORM, payload: true });

   

    yield call(updateUser, payload);

    

    yield put({ type: LOADING_USER_FORM, payload: false });

    yield put({ type: SET_USER_ERRORS, payload: null });


  } catch (e) {


    const { response } = e;
    if (response.status === 400) {
      if (response.data.first_name) {
        errors = [...errors, messageValidationFailed(response.data.first_name, 'nombre.')];
      }
      if (response.data.last_name) {
        errors = [...errors, messageValidationFailed(response.data.last_name, 'apellidos.')];
      }
      if (response.data.email) {
        errors = [...errors, messageValidationFailed(response.data.email, 'email.')];
      } 
      if (response.data.user_role) {
        errors = [...errors,registerErrors.DATA_CONFLICT];
      }
      if (response.data.rut_user) {
        errors = [...errors,registerErrors.RUT_TAKEN];
      }
      yield put({ type: SET_USER_ERRORS, payload: errors });
      yield put({ type: LOADING_USER_FORM, payload: false });
    }
    if (response && response.status === 403) {
      errors = [...errors,registerErrors.ACTION_NOT_ALLOWED];
      yield put({ type: SET_USER_ERRORS, payload: errors });
      yield put({ type: LOADING_USER_FORM, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_USER_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: LOADING_USER_FORM, payload: false });
    }
    // TODO: handle error
    console.log(e);
  }
}

function* resendUserInvitation({ payload }) {
  try {
    yield put({ type: RESEND_LOADING, payload: true });
    yield call(resendInvitation, payload);
    yield put({ type: INVITATION_SENT, payload: true });
    yield put({ type: RESEND_LOADING, payload: false });
  } catch (e) {
    yield put({ type: RESEND_LOADING, payload: false });
    // TODO: handle error
    console.log(e);
  }
}

function* deleteUserInvitation({ payload }) {
  try {
    yield call(deleteInvitation, payload);
    window.location.href = '/admin/users';
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* watchShowUsers() {
  yield takeLatest(FETCH_LIST_USERS, showUsers);
}

function* watchPostInvitation() {
  yield takeLatest(POST_INVITATION, postInvitation);
}

function* watchPostCsvRegister() {
  yield takeLatest(POST_CSV, postCsvRegister);
}

function* watchDeleteUserData() {
  yield takeLatest(DELETE_USER, deleteUserData);
}

function* watchShowUser() {
  yield takeLatest(FETCH_USER, showUser);
}

function* watchPatchUser() {
  yield takeLatest(PATCH_USER, patchUser);
}

function* watchResendUserInvitation() {
  yield takeLatest(RESEND_INVITATION, resendUserInvitation);
}

function* watchDeleteUserInvitation() {
  yield takeLatest(DELETE_INVITATION, deleteUserInvitation);
}

const saga = [
  watchShowUsers(),
  watchPostInvitation(),
  watchPostCsvRegister(),
  watchDeleteUserData(),
  watchShowUser(),
  watchPatchUser(),
  watchResendUserInvitation(),
  watchDeleteUserInvitation()
];

export default saga;
