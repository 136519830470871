export const SET_DATA_SOCIETY = 'society/SET_DATA_SOCIETY';
export const FETCH_SOCIETY = 'society/FETCH_SOCIETY';
export const SET_DATA_LIST_SOCIETIES = 'society/SET_DATA_LIST_SOCIETIES';
export const FETCH_LIST_SOCIETIES = 'society/FETCH_LIST_SOCIETIES';
export const IS_LOADED = 'society/IS_LOADED';
export const IS_LOADED_SOCIETY = 'society/IS_LOADED_SOCIETY';
export const POST_SOCIETIES = 'society/POST_SOCIETIES';
export const PATCH_SOCIETY = 'society/PATCH_SOCIETY';
export const FETCH_SOCIETY_ADMINS = 'society/FETCH_SOCIETY_ADMINS';
export const SET_LIST_SOCIETY_ADMINS = 'society/SET_LIST_SOCIETY_ADMINS';
export const DELETE_SOCIETY = 'society/DELETE_SOCIETY';
export const FETCH_PDF = 'society/FETCH_PDF';
export const INDEX_PAGINATION_SOCIETIES = 'society/INDEX_PAGINATION_SOCIETIES';
export const SET_SOCIETY_ERRORS = 'society/SET_SOCIETY_ERRORS';
export const LOADING_SOCIETY_FORM = 'society/LOADING_SOCIETY_FORM';
export const FETCH_SOCIETY_USER_LIST = 'society/FETCH_SOCIETY_USER_LIST';
export const SOCIETY_USER_LIST = 'society/SOCIETY_USER_LIST';
export const INDUSTRIES_LIST = 'society/INDUSTRIES_LIST';
export const FETCH_INDUSTRIES = 'society/FETCH_INDUSTRIES';
export const POST_INDUSTRY = 'society/POST_INDUSTRY';
export const PATCH_INDUSTRY = 'society/PATCH_INDUSTRY';
export const DELETE_INDUSTRY = 'society/DELETE_INDUSTRY';
export const INDUSTRY_DATA = 'society/INDUSTRY_DATA';
export const FETCH_INDUSTRY_DATA = 'society/FETCH_INDUSTRY_DATA';
export const INDUSTRY_LOADED = 'society/INDUSTRY_LOADED';
export const INDUSTRY_ERRORS = 'society/INDUSTRY_ERRORS';
export const INDUSTRY_LOADING = 'society/INDUSTRY_LOADING';
export const SET_LIST_SOCIETY_USERS = 'society/SET_LIST_SOCIETY_USERS';
export const FETCH_SOCIETY_USERS = 'society/FETCH_SOCIETY_USERS';
export const FETCH_USER_SOCIETY = 'society/FETCH_USER_SOCIETY';
