import React, { useState } from 'react';
import { Button, Card, Dropdown, Form, FormControl, FormGroup, Modal, Spinner } from 'react-bootstrap';
import { Edit3 } from 'react-feather';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PATCH_DOCUMENT } from '../../store/document/types';

const EditDocument = ({ patchDocument, errors, name, uuid, folderLoaded }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ documentForm, setDocumentForm ] = useState({
    document_name: name.substring(0,name.lastIndexOf(".")),
  })

  const handleInputChange = ({ target }) => {
    const formData = { ...documentForm, [target.name]: target.value };
    setDocumentForm(formData);
  };

  return (
    <>
      <Dropdown.Item
        data-toggle="tooltip"
        data-placement="top"
        title="Cambiar nombre de documento"
        className="text-mute"
        onClick={handleShow}
      >
        <Edit3 size="15" className="mr-1"/>
        Cambiar nombre
      </Dropdown.Item>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton/>
        <Modal.Body>
          <Card.Body >
            <h3 className="font-weight-bold title-color text-left">
              Cambiar nombre
            </h3>
            <FormGroup className="position-relative" controlId="document_name">
              <Form.Label className="font-weight-bold small">
                Nombre
              </Form.Label>
              <FormControl
                size="lg"
                name="document_name"
                type="document_name"
                placeholder="Ingrese nombre de documento"
                defaultValue={documentForm.document_name}
                onChange={handleInputChange}
              />
            </FormGroup>
            {
              !folderLoaded && errors.length !== 0 && (
                errors.map(
                  (error) => {
                    return(<p key={error} className='text-danger'>{error}</p>)
                  }
                )
              )
            }
            <div className="justify-content-center d-flex">
              <Button
                className="btn-rounded col-12 base-btn-color"
                variant="primary"
                onClick={ () => {
                  patchDocument({ documentForm, uuid })
                }}
              >
                {
                  folderLoaded ?(
                    <Spinner animation="border" className="spinner-custom" />
                  ):(
                    'Guardar'
                  )
                }
              </Button>
            </div>
          </Card.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

EditDocument.propTypes = {
  patchDocument: PropTypes.func.isRequired,
};

const stateToProps = state => ({
  errors: state.documents.errors,
  folderLoaded: state.documents.newFolderLoaded
});

const actionsToProps = dispatch => ({
  patchDocument: payload => dispatch({ type: PATCH_DOCUMENT, payload })
});

export default connect(stateToProps, actionsToProps)(EditDocument);
