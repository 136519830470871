const schema = {
  email: {
    type: 'email',
    max: 100,
    optional: false,
    messages: {
      emailEmpty: 'El email es obligatorio.',
      email: 'El email debe tener el siguiente formato ejemplo@correo.com',
      string: 'Debes ingresar un email',
      stringMax: 'El email es muy largo (maximo 100 caracteres).'
    }
  },
  password: {
    type: 'string',
    min: 8,
    max: 100,
    optional: false,
    pattern: '^(?=.*\\d)(?=.*[\u0021-\u002f\u003a-\u0040\u005B-\u0060\u007B-\u007E])(?=.*[A-Z])(?=.*[a-z])',
    messages: {
      required: 'La contraseña es obligatoria.',
      string: 'Por favor ingresar contraseña valida.',
      stringMin: 'Su contraseña es muy corta (minimo 8 caracteres).',
      stringMax: 'Su contraseña es muy larga (maximo 100 caracteres).',
      stringPattern: 'Su contraseña debe tener como mínimo una mayúscula, una minúscula, un número y un símbolo (@$!%*?&).'
    }
  }
};

export default schema;
