import React, { useEffect, useState } from 'react';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import { ConfigProvider } from 'react-avatar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_USER } from '../../store/user/types';
import ProfilePicture from './profilePicture';
import Picture from './Picture';
// import { ChevronDown } from 'react-feather';
import { Lock } from 'react-feather';
import { FETCH_LIST_PROJECTS } from '../../store/project/types';
import { FETCH_LIST_GROUPS } from '../../store/group/types';
import { FETCH_LIST_SOCIETIES } from '../../store/society/types';
import { PATCH_IMAGE_PROFILE } from '../../store/profile/types';
import { useHistory } from 'react-router-dom';
//import { compose } from 'redux';

const UserProfile = (
  {
    errors,
    projectsList,
    groupsList,
    societiesList,
    userProfile,
    showProjects,
    showGroups,
    showSocieties,
    patchUpdateImage
  }) => {

  const [image, setImage] = useState([]);
  const [auxImage, setAuxImage] = useState(false);
  const [serviceError, setServiceError] = useState([]);
  const history = useHistory();





  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'SUPER_ADMIN') {
        showProjects({ projectSearch: "" });
      }
    }
  }, [showProjects, userProfile]);

  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'SUPER_ADMIN') {
        showGroups({ groupSearch: "", project: "" });
      }
      if (userProfile.profile.user_role === 'PROJECT_ADMIN') {
        showGroups({ groupSearch: "", project: "" });
      }
    }
  }, [showGroups, userProfile]);

  useEffect(() => {
    if (userProfile.profile) {
      if (userProfile.profile.user_role === 'SUPER_ADMIN') {
        showSocieties({ societySearch: "", project: "", group: "" });
      }

      if (userProfile.profile.user_role === 'PROJECT_ADMIN') {
        showSocieties({ societySearch: "", project: "", group: "" });
      }

      if (userProfile.profile.user_role === 'GROUP_ADMIN') {
        showSocieties({ societySearch: "", project: "", group: "" });
      }
    }
  }, [showSocieties, userProfile]);

  useEffect(() => {
    setServiceError(errors);
  }, [setServiceError, errors]);



  const uploadImage = (picture) => {
    const formData = new FormData();
    formData.append('image', picture);
    patchUpdateImage(formData);
  }

  const alert = () => {
    if (serviceError) {
      return (
        serviceError.map((documentErrors) => {
          return (<div key={documentErrors}>
            {documentErrors !== undefined ? (
              `-${documentErrors}`
            ) : (
              ''
            )}
          </div>);
        })
      );
    }
  };

  return (


    <ConfigProvider colors={['#F2BBAD', '#E3F2FD']}>
      {
        userProfile && (
          <>
            <header className='header_user_profile'>

              <Row>
                <Col>
                  <Breadcrumb className="small">
                    <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
                    <Breadcrumb.Item
                      href={
                        "#"
                      }
                    >
                      Administración usuarios
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Detalle usuario</Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>
              <div className="avatar_user">
                {
                  userProfile.profile && (
                    <Picture
                      profileImage={userProfile.profile.image ? (userProfile.profile.image) : null}
                      setLogo={setImage}
                      logo={image}
                      imgName={'image'}
                      auxImage={auxImage}
                      setAuxImage={setAuxImage}
                      uploadImage={uploadImage}
                      setServiceError={setServiceError}
                    />
                  )
                }
              </div>


            </header>

            <Col lg={12} className="mx-auto mt-4 d-flex justify-content-center" id='container_profile_columna'>
              <Row className="px-3" style={{ display: 'flex', width: '80%', justifyContent: 'space-between' }}>

                {
                  <Col lg={4} className='info_user col-sm-12' style={{ marginTop: '5%' }}>

                    {
                      userProfile.user && (
                        <>
                          <span className="font-bold">Nombre:</span>
                          <div className="info_extern">{userProfile.user.first_name} {userProfile.user.last_name}</div>

                          <span className="font-bold">Telefono:</span>
                          <div className="info_extern">
                            {
                              userProfile.profile.phone_number ? (
                                <span className="ml-1">{userProfile.profile.phone_number}</span>
                              ) : (
                                <span className="ml-1">-</span>
                              )
                            }
                          </div>
                          <span className="font-bold">Correo:</span>
                          <div className="info_extern">
                            {userProfile.user.email}
                          </div>
                          <span className="font-bold">Rol de usuario:</span>
                          <div className="info_extern">
                            {userProfile.profile.user_role === "SUPER_ADMIN" && (<span className="ml-1">Super administrador</span>)}
                            {userProfile.profile.user_role === "PROJECT_ADMIN" && (<span className="ml-1">Administrador de proyectos</span>)}
                            {userProfile.profile.user_role === "USER_ADMIN" && (<span className="ml-1">Administrador de grupos</span>)}
                            {userProfile.profile.user_role === "USER_GROUP" && (<span className="ml-1">Usuario de grupos</span>)}
                            {userProfile.profile.user_role === "USER_SOCIETY" && (<span className="ml-1">Usuario de sociedad</span>)}
                          </div>
                          <span className="font-bold">Cargo:</span>
                          <div className="info_extern">
                            {
                              userProfile.profile.position ? (
                                <span className="ml-1">{userProfile.profile.position}</span>
                              ) : (
                                <span className="ml-1">-</span>
                              )
                            }
                          </div>

                          {
                            userProfile.profile && (
                              <ProfilePicture
                                profileImage={userProfile.profile.image ? (userProfile.profile.image) : null}
                                setLogo={setImage}
                                logo={image}
                                imgName={'image'}
                                auxImage={auxImage}
                                setAuxImage={setAuxImage}
                                uploadImage={uploadImage}
                                setServiceError={setServiceError}
                              />
                            )
                          }

                          <Col className="d-flex  justify-content-center">
                            <label className=" mt-2 upload-profile-image position-relative bg-primary" id='cambiar_contraseña'>
                              <div className="upload-file-custom-content text-center" id='cambiar_contraseña'>
                                <span className="file-custom text-center font-weight-bold d-block text-white" onClick={() => history.push({ pathname: `/changePassword` })}  ><Lock className="mr-2" />Cambiar contraseña</span>
                              </div>
                            </label>
                          </Col>

                        </>
                      )
                    }



                    {
                      /*
                    <div className="mx-auto">
                      <Col className="d-flex justify-content-center">
                        <Form.Group controlId="image" className="mt-1 mb-5" style={{ width: "200px" }}>
                          {
                            userProfile.profile && (
                              <ProfilePicture
                                profileImage={userProfile.profile.image ? (userProfile.profile.image) : null}
                                setLogo={setImage}
                                logo={image}
                                imgName={'image'}
                                auxImage={auxImage}
                                setAuxImage={setAuxImage}
                                uploadImage={uploadImage}
                                setServiceError={setServiceError}
                              />
                            )
                          }
                        </Form.Group>


                        {
                          errors.length !== 0 ? (
                            <div className="alert alert-danger my-3 mx-auto" role="alert">
                              {alert()}
                            </div>
                          ) : null
                        }


                      </Col>
                    </div>
                    */
                    }



                  </Col>
                }

                {

                  <Col lg={7} className='info_proyects' style={{ marginTop: '5%' }}>

                    <div className="container_p">
                      <div className="fixed_header">
                        <span id='proyectos_profile'>Proyectos</span>
                      </div>
                      {

                        projectsList.map((project) => {

                          return (
                            <div className="description_pgs">
                              <span>{project.project_name}</span>
                            </div>

                          );
                        })
                      }
                    </div>

                    <div className="container_p mt-5">
                      <div className="fixed_header">
                        <span id='proyectos_profile'>Grupos</span>
                      </div>
                      {
                        groupsList.map((group) => {
                          return (
                            <div className="description_pgs">
                              <span>{group.group_name}</span>
                            </div>

                          );
                        })
                      }
                    </div>

                    <div className="container_p mt-5">
                      <div className="fixed_header">
                        <span id='proyectos_profile'>Sociedad</span>
                      </div>
                      {
                        societiesList.map((society) => {
                          return (
                            <div className="description_pgs">
                              <span>{society.society_business_name}</span>
                            </div>

                          );
                        })
                      }
                    </div>

                  </Col>
                }



                {

                  /*
                  userProfile.user && (
                    <Col className="mt-3 ml-3">
                      <Row>
                        <Col lg="12" className="d-flex justify-content-end">
                          <Button onClick={() => history.push({ pathname: `/changePassword` })}>Cambiar contraseña</Button>
                        </Col>
                        <Col lg="12" className="mt-2">
                          <p>
                            <span className="font-bold">Nombre:</span>
                            <span className="ml-1">{userProfile.user.first_name}</span>
                          </p>
                        </Col>
                        <Col lg="12">
                          <p>
                            <span className="font-bold">Apellido:</span>
                            <span className="ml-1">{userProfile.user.last_name}</span>
                          </p>
                        </Col>
                        <Col lg="12">
                          <p>
                            <span className="font-bold">Teléfono:</span>
                            {
                              userProfile.profile.phone_number ? (
                                <span className="ml-1">{userProfile.profile.phone_number}</span>
                              ) : (
                                <span className="ml-1">-</span>
                              )
                            }
                          </p>
                        </Col>
                        <Col lg="12">
                          <p>
                            <span className="font-bold">Correo electrónico:</span>
                            <span className="ml-1">{userProfile.user.email}</span>
                          </p>
                        </Col>
                        <Col lg="12">
                          <p>
                            <span className="font-bold">Rol de usuario:</span>
                            {userProfile.profile.user_role === "SUPER_ADMIN" && (<span className="ml-1">Super administrador</span>)}
                            {userProfile.profile.user_role === "PROJECT_ADMIN" && (<span className="ml-1">Administrador de proyectos</span>)}
                            {userProfile.profile.user_role === "USER_ADMIN" && (<span className="ml-1">Administrador de grupos</span>)}
                            {userProfile.profile.user_role === "USER_GROUP" && (<span className="ml-1">Usuario de grupos</span>)}
                            {userProfile.profile.user_role === "USER_SOCIETY" && (<span className="ml-1">Usuario de sociedad</span>)}
                          </p>
                        </Col>
                        <Col lg="12">
                          <p>
                            <span className="font-bold">Cargo:</span>
                            {
                              userProfile.profile.position ? (
                                <span className="ml-1">{userProfile.profile.position}</span>
                              ) : (
                                <span className="ml-1">-</span>
                              )
                            }
                          </p>
                        </Col>
    
                        <Col lg={12}>
                          <Accordion defaultActiveKey="-1">
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="0">
                                Proyectos
                                <ChevronDown className="float-right" />
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  {
                                    projectsList.map((project) => {
                                      return (
                                        <ul>
                                          <li>
                                            {project.project_name}
                                          </li>
                                        </ul>
                                      );
                                    })
                                  }
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="1">
                                Grupos
                                <ChevronDown className="float-right" />
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                  {
                                    groupsList.map((group) => {
                                      return (
                                        <ul>
                                          <li>
                                           
                                          </li>
                                        </ul>
                                      );
                                    })
                                  }
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="2">
                                Sociedades
                                <ChevronDown className="float-right" />
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                  {
                                    societiesList.map((society) => {
                                      return (
                                        <ul>
                                          <li>
                                            {society.society_business_name}
                                          </li>
                                        </ul>
                                      );
                                    })
                                  }
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                        </Col>
                        
                      </Row>
                    </Col>
                  )
                                  */
                }
              </Row>
            </Col>
          </>
        )
      }
    </ConfigProvider >
  )
}

UserProfile.propTypes = {
  showUser: PropTypes.func.isRequired,
  userData: PropTypes.object,
  isLoadedUser: PropTypes.bool.isRequired

};

const stateToProps = state => ({
  userData: state.users.userData,
  isLoadedUser: state.users.isLoadedUser,
  userProfile: state.profile.userProfile,
  societiesList: state.societies.societiesList,
  projectsList: state.projects.projectsList,
  groupsList: state.groups.groupsList,
  errors: state.profile.errors,
});

const actionsToProps = dispatch => ({
  showUser: payload => dispatch({ type: FETCH_USER, payload }),
  showProjects: payload => dispatch({ type: FETCH_LIST_PROJECTS, payload }),
  showGroups: payload => dispatch({ type: FETCH_LIST_GROUPS, payload }),
  showSocieties: payload => dispatch({ type: FETCH_LIST_SOCIETIES, payload }),
  patchUpdateImage: payload => dispatch({ type: PATCH_IMAGE_PROFILE, payload })
});

export default connect(stateToProps, actionsToProps)(UserProfile);
