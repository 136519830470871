import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Collapse, Form, Row, Table } from 'react-bootstrap';
import { ConfigProvider } from 'react-avatar';
import SearchBar from '../search_bar';
import ReportItem from '../report_item';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FETCH_LIST_REPORTS } from '../../store/report/types';
import { connect } from 'react-redux';
import PaginationBar from '../pagination/paginationBar';
import { FETCH_GROUP } from '../../store/group/types';
import { FETCH_LIST_PROJECTS, FETCH_PROJECT } from '../../store/project/types';
import { ChevronDown, Plus } from 'react-feather';

const ReportAdmin = (
  { showReports,
    reportsList,
    indexPagination,
    disabledReportsList,
    projectsList,
    showProjects,
    indexPaginationDisabled,
    showProject,
    showGroup,
    projectData,
    groupData,
  }) => {
  const [paginationPosition, setPaginationPosition] = useState(window.location.hash.substring(1, window.location.hash.indexOf("%")));
  const [paginationPositionDisabled, setPaginationPaginationPositionDisabled] = useState(window.location.hash.substring(window.location.hash.indexOf("%") + 1, window.location.hash.indexOf("?")));
  const [queryValue, setQueryValue] = useState(window.location.hash.substring(window.location.hash.indexOf("?")));
  const [valueSearch, setValueSearch] = useState("");
  const [flagAction, setFlagAction] = useState(false);
  const [filterValue, setFilterValue] = useState({
    project: "",
    group: "",
    society: ""
  });
  const [reportsInScreen, setReportsInScreens] = useState(5);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (filterValue.project) {
      showProject(filterValue.project);
    }
  }, [filterValue.project, showProject]);

  useEffect(() => {
    if (filterValue.group) {
      showGroup(filterValue.group);
    }
  }, [filterValue.group, showGroup]);

  useEffect(() => {
    showProjects({ valueSearch: "" });
  }, [showProjects, flagAction]);

  useEffect(() => {
    showReports({ valueSearch, queryValue, filterValue, paginationPosition, paginationPositionDisabled, reportsInScreen })
  }, [showReports, filterValue, paginationPosition, valueSearch, paginationPositionDisabled, setValueSearch, reportsInScreen, queryValue]);

  useEffect(() => {
    if (!window.location.hash) {
      setPaginationPosition('1');
      setPaginationPaginationPositionDisabled('1');
      setQueryValue("?search=&project=&group=&society=");
    } else {
      setValueSearch(window.location.hash.substring(window.location.hash.indexOf("h=") + 2, window.location.hash.indexOf("&")));
      setFilterValue({
        project: window.location.hash.substring(window.location.hash.indexOf("t=") + 2, window.location.hash.indexOf("&gro", 2)),
        group: window.location.hash.substring(window.location.hash.indexOf("p=") + 2, window.location.hash.indexOf("&soc", 2)),
        society: window.location.hash.substring(window.location.hash.indexOf("y=") + 2)
      })
    }
  }, [setPaginationPosition, setPaginationPaginationPositionDisabled]);

  useEffect(() => {
    history.replace(`#${paginationPosition}%${paginationPositionDisabled}?search=${valueSearch}&project=${filterValue.project}&group=${filterValue.group}&society=${filterValue.society}`);
  }, [paginationPosition, paginationPositionDisabled, valueSearch, filterValue, history]);


  const handleSearch = ({ target }) => {
    setPaginationPosition(1);
    setPaginationPaginationPositionDisabled(1);
    setQueryValue(`?search=${target.value}&project=${filterValue.project}&group=${filterValue.group}&society=${filterValue.society}`);
    setValueSearch(target.value);
  }

  const handleSelectFilter = ({ target }) => {

    console.log(target)

    let formData = { ...filterValue, [target.name]: target.value };

    if (target.name === 'project') {
      formData.group = "";
      formData.society = "";
    }

    if (target.name === 'group')
      formData.society = "";

    setPaginationPosition(1);
    setPaginationPaginationPositionDisabled(1);
    setQueryValue(`?search=${valueSearch}&project=${formData.project}&group=${formData.group}&society=${formData.society}`);

    setFilterValue(formData);
    console.log(formData)
  };

  return (

    <>
      <Row className='d-flex align-items-center justify-content-beetwen' id={open ? 'headOpen' : 'headNormaly'}>
        <Col xs="10" sm="10" lg="3" xl="3">
          <Breadcrumb className="small">
            <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
            <Breadcrumb.Item active>Administración Reportes</Breadcrumb.Item>
          </Breadcrumb>
          <h4 className="font-weight-bold mb-4 mb-lg-0 mt-3 ml-3">Reportes</h4>
        </Col>

        <Col xs="12" sm="12" lg="9" xl="9">
          <Row>
            <Col sm="12" className='d-block d-md-flex justify-content-end align-items-center mt-4'>


              <Button
                onClick={() => setOpen(!open)}
                variant="outline-primary"
                size="md"
                aria-controls="example-collapse-text"
                aria-expanded={open}
                className="d-flex justify-content-between mr-0 mr-md-2 mb-3 mb-md-0"

              >
                Filtros
                <ChevronDown style={{ marginLeft: "26px" }} />
              </Button>
              <SearchBar handleSearch={handleSearch} defaultValue={decodeURI(valueSearch).length === 1 ? (`${decodeURI(valueSearch)} `) : (decodeURI(valueSearch))} />
              <Button as={Link} to="/admin/reports/create" variant="primary" size="md" className='mt-3 mt-md-0' ><Plus size={18} className='mr-2'></Plus>Crear</Button>
            </Col>
          </Row>


          <Collapse in={open}>
            <div id="example-collapse-text">
              <Row className="mt-3 d-flex align-items-center justify-content-end">
                <Col xs="12" md="4" lg="3" className="pr-md-0">
                  <Form.Group controlId="project" className="m-0">
                    <Form.Control
                      className="text-muted"
                      size="md"
                      as="select"
                      name="project"
                      type="project"
                      onChange={handleSelectFilter}
                    >
                      <option value="" className="secondary-text-color" selected> Todos los proyectos </option>
                      {
                        projectsList.map((project) => {
                          return (
                            filterValue.project === project.uuid ? (
                              <option value={project.uuid} key={project.uuid} selected>{project.project_name}</option>
                            ) : (
                              <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                            )
                          );
                        })
                      }
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs="12" md="4" lg="3" className="my-3 my-md-0">
                  {
                    filterValue.project && projectData ? (
                      <Form.Group controlId="group" className="m-0">
                        <Form.Control
                          className="text-muted"
                          size="md"
                          as="select"
                          name="group"
                          type="group"
                          onChange={handleSelectFilter}
                        >
                          <option value="" className="secondary-text-color" selected> Todos los grupos </option>
                          {
                            projectData.groups.map((group) => {
                              return (
                                filterValue.group === group.uuid ? (
                                  <option value={group.uuid} key={group.uuid} selected>{group.group_name}</option>
                                ) : (
                                  <option value={group.uuid} key={group.uuid}>{group.group_name}</option>
                                )
                              );
                            })
                          }
                        </Form.Control>
                      </Form.Group>
                    ) : (
                      <Form.Group controlId="group" className="m-0">
                        <Form.Control
                          className="text-muted"
                          size="md"
                          as="select"
                          name="group"
                          type="group"
                          disabled
                        >
                          <option value="" className="secondary-text-color" selected> Todos los grupos </option>
                        </Form.Control>
                      </Form.Group>
                    )
                  }
                </Col>
                <Col xs="12" md="4" lg="3" className="pl-md-0">
                  {
                    filterValue.group && groupData ? (
                      <Form.Group controlId="society" className="m-0">
                        <Form.Control
                          className="text-muted"
                          size="md"
                          as="select"
                          placeholder="Ingresa un correo"
                          name="society"
                          type="society"
                          onChange={handleSelectFilter}
                        >
                          <option value="" className="secondary-text-color" selected> Todas las sociedades </option>
                          {
                            groupData.societies.map((society) => {
                              return (
                                filterValue.society === society.uuid ? (
                                  <option value={society.uuid} key={society.uuid} selected>{society.society_business_name}</option>
                                ) : (
                                  <option value={society.uuid} key={society.uuid}>{society.society_business_name}</option>
                                )
                              );
                            })
                          }
                        </Form.Control>
                      </Form.Group>
                    ) : (
                      <Form.Group controlId="society" className="m-0">
                        <Form.Control
                          className="text-muted"
                          size="md"
                          as="select"
                          name="society"
                          type="society"
                          disabled
                        >
                          <option value="" className="secondary-text-color" selected> Todas las sociedades </option>
                        </Form.Control>
                      </Form.Group>
                    )
                  }
                </Col>
              </Row>
            </div>
          </Collapse>

        </Col>



      </Row>



      <Table size="md" className='mt-4 mb-0'>
        <thead>
          <Row className='sub_header'>
            <Col xs="12" sm="12" lg="4" xl="2"><span>Reporte:</span></Col>
            <Col xs="12" sm="12" lg="4" xl="2"><span>Rol:</span></Col>
            <Col xs="12" sm="12" lg="4" xl="2"><span>Tipo de herramienta:</span></Col>
            <Col xs="12" sm="12" lg="4" xl="2"><span>Acciones:</span></Col>
          </Row>
        </thead>
        <tbody>
          {
            reportsList.map((report) => {
              return (
                <ReportItem
                  key={report.uuid}
                  uuid={report.uuid}
                  setFlagAction={setFlagAction}
                  name={report.report_name}
                  enabled={report.enable}
                  project_admin={report.enable_project_admin}
                  group_admin={report.enable_group_admin}
                  user_group={report.enable_user_group}
                  user_society={report.enable_user_society}
                  tool_type={report.tool_type}
                />
              );
            })
          }
        </tbody>
      </Table>

      <PaginationBar total={indexPagination} position={paginationPosition} setPosition={setPaginationPosition} />
      {
        disabledReportsList.length !== 0 && (
          <div className="mb-5">
            <Col sm="4" lg="5" className="d-flex align-items-center mt-5">
              <h4 className="font-weight-bold mb-1 mt-5">Reportes Inactivos</h4>
            </Col>
            <Table size="md" className='mt-4 mb-0'>
              <thead>
                <Row className='sub_header'>
                  <Col xs="12" sm="12" lg="4" xl="2"><span>Reporte:</span></Col>
                  <Col xs="12" sm="12" lg="4" xl="2"  ><span>Rol:</span></Col>
                  <Col xs="12" sm="12" lg="4" xl="2"  ><span>Tipo de herramienta:</span></Col>
                  <Col xs="12" sm="12" lg="4" xl="2"><span>Acciones:</span></Col>
                </Row>
              </thead>

              <tbody>
                {
                  disabledReportsList.map((report) => {
                    return (
                      <ReportItem
                        key={report.uuid}
                        uuid={report.uuid}
                        setFlagAction={setFlagAction}
                        name={report.report_name}
                        enabled={report.enable}
                        project_admin={report.enable_project_admin}
                        group_admin={report.enable_group_admin}
                        user_group={report.enable_user_group}
                        user_society={report.enable_user_society}
                        tool_type={report.tool_type}
                      />
                    );
                  })
                }
              </tbody>
            </Table>
            <PaginationBar total={indexPaginationDisabled} position={paginationPositionDisabled} setPosition={setPaginationPaginationPositionDisabled} />
          </div>
        )
      }
      {
        paginationPosition === 1 && paginationPositionDisabled === 1 && (reportsList.length >= 5 || disabledReportsList.length >= 5) && (
          <div className="d-flex justify-content-start align-content-center">
            <span className="mt-2">Mostrar:</span>
            <Col xs={3} lg={1} className="mb-2 d-flex">
              <Form.Group controlId="project" className="m-0">
                <Form.Control
                  className="text-muted"
                  as="select"
                  name="project"
                  type="project"
                  defaultValue={reportsInScreen}
                  onChange={({ target }) => {
                    setReportsInScreens(target.value);
                  }}
                >
                  <option value={5} className="secondary-text-color" selected>5</option>
                  <option value={15} className="secondary-text-color">15</option>
                  <option value={30} className="secondary-text-color">30</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </div>
        )
      }
    </>

  )
}

ReportAdmin.propTypes = {
  showReports: PropTypes.func.isRequired,
  reportsList: PropTypes.array,
  reportsListDisabled: PropTypes.array,
  isLoaded: PropTypes.bool.isRequired,
  indexPagination: PropTypes.number,
  indexPaginationDisabled: PropTypes.number,
  showProjects: PropTypes.func.isRequired,
  projectsList: PropTypes.array,
};

const stateToProps = state => ({
  reportsList: state.reports.reportsList,
  disabledReportsList: state.reports.disabledReportsList,
  isLoaded: state.reports.isLoaded,
  indexPagination: state.reports.indexPagination,
  indexPaginationDisabled: state.reports.indexPaginationDisabled,
  projectsList: state.projects.projectsList,
  projectData: state.projects.projectData,
  groupData: state.groups.groupData,
});

const actionsToProps = dispatch => ({
  showProjects: payload => dispatch({ type: FETCH_LIST_PROJECTS, payload }),
  showReports: payload => dispatch({ type: FETCH_LIST_REPORTS, payload }),
  showProject: payload => dispatch({ type: FETCH_PROJECT, payload }),
  showGroup: payload => dispatch({ type: FETCH_GROUP, payload }),
});

export default connect(stateToProps, actionsToProps)(ReportAdmin);
