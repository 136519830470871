import React, { useEffect, useState } from 'react';
import { Row, Breadcrumb, Col, Button, Form, FormGroup, Spinner, Accordion, Card, FormControl } from 'react-bootstrap';
import { ChevronRight, CheckCircle, Edit3, Trash } from 'react-feather';
import { Link, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_LIST_GROUPS } from '../../store/group/types';
import { FETCH_INDUSTRIES, FETCH_INDUSTRY_DATA, FETCH_SOCIETY_USERS, POST_SOCIETIES } from '../../store/society/types';
import validator from '../../utils/validator';
import { societySchema } from '../../utils/validator/schemas';
import EditIndustry from './editIndustry';
import CreateIndustry from './createIndustry';
import IndustryDelete from './industryDelete';
import { FETCH_LIST_PROJECTS } from '../../store/project/types';

const CreateSociety = (
  {
    showGroups,
    groupsList,
    postSocieties,
    userProfile,
    loadingForm,
    errors,
    showIndustriesList,
    industryList,
    showIndustryData,
    industryData,
    industryLoaded,
    showSocietyUsers,
    societyUsers,
    showProjects,
    projectsList
  }) => {
  const [step, setStep] = React.useState(0);
  const [societyFormOne, setSocietyFormOne] = useState({
    group: "",
    user: [],
    society_business_name: '',
    business_line: '',
    company_code: '',
    society_email: '',
    currency:"CLP",
    industry_type: ''
  });
  const [societyFormTwo, setSocietyFormTwo] = useState({
    society_contact_name: '',
    society_phone_number: ''
  });
  const [validationError, setValidationError] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [triggerForm, setTriggerForm] = useState(false);
  const [promptAux, setPromptAux] = useState(true);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [serviceError, setServiceError] = useState(null);
  const [auxIndustry, setAuxIndustry] = useState();
  const [valueSearchUser, setValueSearchUser] = useState("");
  const [groupFilter, setGroupFilter] = useState({
    groupSearch: "",
    project: ""
  });
  const [auxAdminSelected, setAuxAdminSelected] = useState([]);
  const [auxUserSelected, setAuxUserSelected] = useState([]);

  const onChange = nextStep => {
    setStep(nextStep < 0 ? 0 : nextStep > 2 ? 2 : nextStep);
  };
  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);

  useEffect(() => {
    if(step === 0){
      if (
        Object.keys(validationError).length ||
        Object.keys(societyFormOne).some(key => societyFormOne[key] === '')
      ) {
        setDisableSubmit(true);
      } else {
        setDisableSubmit(false);
      }
    }
    if(step === 1){
      if (
        Object.keys(validationError).length ||
        Object.keys(societyFormTwo).some(key => societyFormTwo[key] === '')
      ) {
        setDisableSubmit(true);
      } else {
        setDisableSubmit(false);
      }
    }
  }, [validationError, societyFormOne, societyFormTwo, step]);

  useEffect(() => {
    showIndustriesList();
    // eslint-disable-next-line
  }, [showGroups, userProfile.profile, showIndustriesList]);

  useEffect(() => {
    if(industryLoaded)
      showIndustriesList();
  }, [showIndustriesList, industryLoaded]);

  useEffect(() => {
    showIndustryData(auxIndustry);
  }, [showIndustryData, auxIndustry]);

  useEffect(() => {
    showGroups(groupFilter);
  }, [showGroups, groupFilter]);

  useEffect(() => {
    showSocietyUsers(valueSearchUser);
  }, [showSocietyUsers, valueSearchUser]);

  useEffect(() => {
    showProjects({ valueSearch: '' });
  }, [showProjects]);

  useEffect(() => {
    window.onbeforeunload = (event) => {
      if(triggerForm === true){
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
      }
    };
  },);

  const handleInputChange = ({ target }) => {
    if(!triggerForm){
      setTriggerForm(true)
    }
    if(step === 0){
      if(target.id === "groupsCheckbox" || target.id === "usersCheckbox"){
        if(target.id === "groupsCheckbox"){
          const formData = {...societyFormOne}
          if(formData.group.includes(target.value)){
            const pos = formData.group.indexOf(target.value);
            formData.group.splice(pos,1);
            const auxForm = auxAdminSelected;
            auxForm.splice(pos,1);
            setSocietyFormOne(formData);
            setAuxAdminSelected(auxForm);
          }else{
            formData.group.push(target.value);
            const aux = {name:target.name, value:target.value}
            setSocietyFormOne(formData);
            auxAdminSelected.push(aux);
          }
        }
        if(target.id === "usersCheckbox"){
          const formData = {...societyFormOne}
          if(formData.user.includes(target.value)){
            const pos = formData.user.indexOf(target.value);
            formData.user.splice(pos,1);
            const auxForm = auxUserSelected;
            auxForm.splice(pos,1);
            setSocietyFormOne(formData);
            setAuxUserSelected(auxForm);
          }else{
            formData.user.push(target.value);
            const aux = {name:target.name, value:target.value}
            setSocietyFormOne(formData);
            auxUserSelected.push(aux);
          }
        }
      }else{
        let formData = { ...societyFormOne, [target.name]: target.value };
        if(target.name === "society_rut" && target.value === "") {
          delete formData.society_rut;
        }
        setSocietyFormOne(formData);
        if(target.name === "industry_type") {
          setAuxIndustry(target.value);
        }
        if(target.name === "society_rut" ||
          target.name === "society_email" ) {
          setValidationError({ ...validationError, [target.name]: '' });
          const errors = validator({ [target.name]: societySchema[target.name] }, formData);
          if (errors.length > 0) {
            const messages = errors
              .map(e => ({ [e.field]: e.message }))
              .reduce((prev, current) => ({ ...prev, ...current }), {});
            setValidationError({ ...messages });
            return false;
          }
          delete validationError[target.name];
          setValidationError(validationError);
        }
      }
    }
    if(step === 1){
      let formData = { ...societyFormTwo, [target.name]: target.value };
      setSocietyFormTwo(formData);
      console.log(target.value)
      if(target.name === "society_contact_email" ||
        target.name === "society_phone_number"){
        setValidationError({ ...validationError, [target.name]: '' });
        const errors = validator({ [target.name]: societySchema[target.name] }, formData);
        if (errors.length > 0) {
          const messages = errors
            .map(e => ({...validationError, [e.field]: e.message }))
            .reduce((prev, current) => ({ ...prev, ...current }), {});
          setValidationError(messages);
          return false;
        }
        delete validationError[target.name];
        setValidationError(validationError);
      }
    }
  };

  const sendData = () => {
    setPromptAux(false);
    setTriggerForm(false);
    setServiceError(null);
    setLoading(true);
    const societyForm = {...societyFormOne, ...societyFormTwo};
    if(societyForm.group === "")
      delete societyForm.group;
    if(societyForm.user.length === 0)
      delete societyForm.user;
    postSocieties(societyForm);
    setServiceError(errors);
    setFlag(true);
  }

  useEffect(() => {
    setServiceError(null);
  }, [errors]);

  useEffect(() => {
    if(flag){
      if (!loadingForm && errors !== null) {
        setServiceError(errors);
        setLoading(false);
      }
      if (loadingForm === false && errors === null) {
        setStep(step + 1);
        setFlag(false);
      }
    }
  }, [loadingForm, errors, flag, step]);

  const alert = () => {
    if (serviceError) {
      return (
        serviceError.map((error) => {
          return (<div key={error}>
            {error !== undefined ? (
              `-${error}`
            ) : (
              ''
            )}
          </div>);
        })
      );
    }
  };

  return (
    <Form>
      <Row className="mb-4">
        <Prompt
          when={triggerForm && promptAux}
          message={`¿Estás seguro de desear abandonar este formulario? Los datos ingresados no serán guardados.`}
        />
        <Col>
          <Breadcrumb className="small">
            <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
            <Breadcrumb.Item
              href={
                userProfile.profile && (
                  userProfile.profile.user_role === "SUPER_ADMIN" ? (
                    `/admin/society`
                  ):(
                    userProfile.profile.user_role === "PROJECT_ADMIN" ?(
                      `/admin/society#1?search=&project=${localStorage.project}&group=`
                    ):(
                      `/admin/society#1?search=&project=&group=${localStorage.group}`
                    )
                  )
                )
              }
            >
              Administración sociedad
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Crear sociedad</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs="10" sm="8" md="6" lg="5" xl="3" className="mx-auto">
          <div className="steps d-flex justify-content-between align-items-center">
            <div className={`step-item ${step === 0 ? 'active' : ''}`}>
              <span className="step-item-indicator mr-3 small">1</span>
              <h6 className="step-item-title mb-0 small">Datos principales</h6>
            </div>
            <ChevronRight size="12" />
            <div className={`step-item ${step === 1 ? 'active' : ''}`}>
              <span className="step-item-indicator mr-3 small">2</span>
              <h6 className="step-item-title mb-0 small">Contacto</h6>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md="8" lg="6" className="mx-auto">
          {step === 0 && (
            <>
              <h5 className="font-weight-bold mb-4">Datos principales</h5>
              <FormGroup controlId="society_business_name">
                <Form.Label className="font-weight-bold small">
                  Razón social
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa una razón"
                  name="society_business_name"
                  type="society_business_name"
                  onChange={handleInputChange}
                  defaultValue={societyFormOne.society_business_name}
                />
              </FormGroup>
              <FormGroup controlId="society_rut">
                <Form.Label className="font-weight-bold small">
                  Rut de la sociedad
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa un rut"
                  name="society_rut"
                  type="society_rut"
                  onChange={handleInputChange}
                  isInvalid={validationError.society_rut}
                  defaultValue={societyFormOne.society_rut}
                />
                <Form.Control.Feedback type="invalid">
                  {validationError.society_rut}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup controlId="business_line">
                <Form.Label className="font-weight-bold small">
                  Giro de la empresa
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa un giro"
                  name="business_line"
                  type="business_line"
                  onChange={handleInputChange}
                  defaultValue={societyFormOne.business_line}
                />
              </FormGroup>
              <FormGroup controlId="company_code">
                <Form.Label className="font-weight-bold small">
                  Código de la empresa
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa un código"
                  name="company_code"
                  type="company_code"
                  onChange={handleInputChange}
                  defaultValue={societyFormOne.company_code}
                />
              </FormGroup>
              <Form.Label className="font-weight-bold small">
                Industria
                <span className="ml-1 text-danger small">
                  (*)
                </span>
              </Form.Label>
              <Row>
                <Col xs={12} className="d-flex justify-content-start">
                  <Col xl={8} className="pl-0">
                    <Form.Group controlId="industry_type">
                      <Form.Control
                        size="lg"
                        as="select"
                        placeholder="Ingresa un correo"
                        type="industry_type"
                        name="industry_type"
                        onChange={handleInputChange}
                        defaultValue={societyFormOne.industry_type}
                      >
                        <option selected disabled>Seleccione</option>
                        {
                          industryList.map((industry)=>{
                            return(
                              <option value={industry.uuid}>{industry.industry}</option>
                            );
                          })
                        }
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="p-1">
                    {
                      auxIndustry && auxIndustry !== industryList[0].uuid ?(
                        <EditIndustry uuid={auxIndustry} name={industryData.industry}/>
                      ):(
                        <Button
                          variant="outline-secondary"
                          size="xs"
                          title="Cambiar nombre industria"
                          data-toggle="tooltip"
                          data-placement="top"
                          disabled
                        >
                          <Edit3/>
                        </Button>
                      )
                    }
                  </Col>
                  <Col className="p-1">
                    {
                      auxIndustry && auxIndustry !== industryList[0].uuid ?(
                        <IndustryDelete setAuxIndustry={setAuxIndustry} industryList={industryList} uuid={auxIndustry}/>
                      ):(
                        <Button
                          variant="outline-secondary"
                          size="xs"
                          title="Eliminar industria"
                          data-toggle="tooltip"
                          data-placement="top"
                          disabled
                        >
                          <Trash/>
                        </Button>
                      )
                    }
                  </Col>
                  <Col className="p-1">
                    <CreateIndustry setAuxIndustry={setAuxIndustry} industryList={industryList} uuid={auxIndustry}/>
                  </Col>
                </Col>
              </Row>
              <FormGroup controlId="society_email">
                <Form.Label className="font-weight-bold small">
                  Correo
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  type="society_email"
                  size="lg"
                  placeholder="Ingresa un correo"
                  name="society_email"
                  onChange={handleInputChange}
                  isInvalid={validationError.society_email}
                  defaultValue={societyFormOne.society_email}
                />
                <Form.Control.Feedback type="invalid">
                  {validationError.society_email}
                </Form.Control.Feedback>
              </FormGroup>
              <Form.Group controlId="currency">
                <Form.Label className="font-weight-bold small">
                  Moneda
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  as="select"
                  placeholder="Ingresa un correo"
                  type="currency"
                  name="currency"
                  onChange={handleInputChange}
                  defaultValue={societyFormOne.currency}
                >
                  <option>CLP</option>
                  <option>USD</option>
                  <option>EUR</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="project">
                <Form.Label className="font-weight-bold small">
                  Seleccione proyecto
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  as="select"
                  placeholder="Ingresa un correo"
                  name="project"
                  type="project"
                  defaultValue={groupFilter.project}
                  onChange={({target})=>{
                    setGroupFilter({ ...groupFilter ,project: target.value });
                    setSocietyFormOne({...societyFormOne ,group: "" });
                  }}
                >
                  <option value="" className="secondary-text-color" disabled selected> - </option>
                  {
                    projectsList.map( (project) =>{
                      return (
                        <option value={project.uuid} key={project.uuid}>{project.project_name}</option>
                      );
                    })
                  }
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="group">
                <Form.Label className="font-weight-bold small">
                  Asociar a grupo de sociedad
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  as="select"
                  placeholder="Ingresa un correo"
                  name="group"
                  type="group"
                  onChange={handleInputChange}
                  defaultValue={societyFormOne.group}
                  disabled={groupFilter.project === ""}
                >
                  <option value="" className="secondary-text-color" disabled selected={societyFormOne.group === ""}> - </option>
                  {
                    groupsList.map( (group) =>{
                      return (
                        <option value={group.uuid} key={group.uuid}>{group.group_name}</option>
                      );
                    })
                  }
                </Form.Control>
              </Form.Group>

              {
                societyUsers && (
                  (societyUsers.length !== 0 || valueSearchUser !== "") && (
                    <>
                      <Form.Label className="font-weight-bold small mt-3">
                        Asignar usuarios a sociedad
                      </Form.Label>
                      <Accordion defaultActiveKey="1" className="mb-4">
                        <Card>
                          <Card.Header className="bg-transparent text-muted">
                            Seleccione
                          </Card.Header>
                          <Card.Body eventKey="1">
                            <Card.Body className="py-0">
                              <FormGroup className='mb-3 mb-md-0 mr-0 mr-md-2 position-relative pr-0 w-100'>
                                <FormControl
                                  className='mb-2'
                                  size='md'
                                  placeholder='Buscar...'
                                  onChange={({target})=>setValueSearchUser(target.value)}
                                />
                              </FormGroup>
                              <Form.Group controlId="usersCheckbox" className="overflow-auto" style={{height:"150px"}} onChange={handleInputChange}>
                                {
                                  societyUsers.map( (user) =>{
                                    return (
                                      <Form.Check type="checkbox" key={user.uuid} label={`${user.first_name} ${user.last_name}`} name={`${user.first_name} ${user.last_name}`} value={user.uuid} defaultChecked={societyFormOne.user.includes(user.uuid)}/>
                                    );
                                  })
                                }
                              </Form.Group>
                            </Card.Body>
                          </Card.Body>
                        </Card>
                      </Accordion>
                    </>
                  )
                )
              }
              <span className="text-danger">
                (*)
                <span className="text-black-50">
                  Campos obligatorios
                </span>
              </span>
            </>
          )}
          {step === 1 && (
            <>
              <h5 className="font-weight-bold mb-4">
                Contacto
              </h5>
              <FormGroup controlId="society_contact_name">
                <Form.Label className="font-weight-bold small">
                  Nombre
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa un nombre"
                  name="society_contact_name"
                  type="society_contact_name"
                  onChange={handleInputChange}
                  defaultValue={societyFormTwo.society_contact_name}
                />
              </FormGroup>

              <Form.Group controlId="industry_type">
              <Form.Label className="font-weight-bold small">
                  Indique si tendrá tax panel
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  as="select"
                  type="tax_module"
                  name="tax_module"
                  onChange={handleInputChange}
                >
                  <option selected disabled>Seleccione:</option>
                  <option value='True'>Si</option>
                  <option value='False'>No</option>
                </Form.Control>
              </Form.Group>

              <FormGroup controlId="society_phone_number">
                <Form.Label className="font-weight-bold small">
                  Teléfono
                  <span className="ml-1 text-danger small">
                    (*)
                  </span>
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ej: +56988888888"
                  name="society_phone_number"
                  type="society_phone_number"
                  onChange={handleInputChange}
                  isInvalid={validationError.society_phone_number}
                  defaultValue={societyFormTwo.society_phone_number}
                />
                <Form.Control.Feedback type="invalid">
                  {validationError.society_phone_number}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup controlId="society_address">
                <Form.Label className="font-weight-bold small">Dirección</Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa una dirección"
                  name="society_address"
                  type="society_address"
                  onChange={handleInputChange}
                  defaultValue={societyFormTwo.society_address}
                />
              </FormGroup>
              <FormGroup controlId="society_contact_email">
                <Form.Label className="font-weight-bold small">Email</Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Ingresa un email"
                  name="society_contact_email"
                  type="society_contact_email"
                  onChange={handleInputChange}
                  isInvalid={validationError.society_contact_email}
                  defaultValue={societyFormTwo.society_contact_email}
                />
                <Form.Control.Feedback type="invalid">
                  {validationError.society_contact_email}
                </Form.Control.Feedback>
              </FormGroup>
              <span className="text-danger">
                (*)
                <span className="text-black-50">
                  Campos obligatorios
                </span>
              </span>
            </>
          )}
          {step === 2 && (
            <Row>
              <Col xs="12" className="text-center">
                <CheckCircle size="48" className="text-success mb-4"/>
                <h5 className="font-weight-bold">Sociedad creada con éxito</h5>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      {
        serviceError ? (
          <div className="alert alert-danger my-3 col-lg-6 col-md-8 mx-auto" role="alert">
            {alert()}
          </div>
        ) : null
      }
      <Row className="d-flex justify-content-center mb-5">
        {step > 0 && step < 2 && (
          <Button variant="light" className="mx-2" onClick={onPrevious}>Atrás</Button>
        )}
        {
          step === 2 ? (
            <Button
              as={Link}
              to={
                userProfile && (
                  userProfile.profile.user_role === "SUPER_ADMIN" ? (
                    `/admin/society`
                  ):(
                    userProfile.profile.user_role === "PROJECT_ADMIN" ?(
                      `/admin/society#1?search=&project=${localStorage.project}&group=`
                    ):(
                      `/admin/society#1?search=&project=&group=${localStorage.group}`
                    )
                  )
                )
              }
              className="mx-2"
              onClick={onNext}
            >
              Ir a sociedad
            </Button>
            ):(
              step === 1 ?(
                <Button
                  className="mx-2"
                  onClick={sendData}
                  disabled={disableSubmit || loading || loadingForm}
                >
                  {
                    loading || loadingForm ? (
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    ) : (
                      ''
                    )
                  }{' '}
                  Crear sociedad</Button>
                ):(
                <Button className="mx-2" onClick={onNext} disabled={disableSubmit}>Siguiente</Button>
              )
            )
        }
      </Row>
    </Form>
  )
}
CreateSociety.propTypes = {
  showGroups: PropTypes.func.isRequired,
  postSocieties: PropTypes.func.isRequired,
  groupsList: PropTypes.array,
  loadingForm: PropTypes.bool,
  errors: PropTypes.array,
  industryList: PropTypes.array,
  showIndustriesList: PropTypes.func.isRequired,
  showIndustryData: PropTypes.func.isRequired,
  showSocietyUsers: PropTypes.func.isRequired,
  societyUsers: PropTypes.array,
  showProjects: PropTypes.func.isRequired,
  projectsList: PropTypes.array
};

const stateToProps = state => ({
  groupsList: state.groups.groupsList,
  userProfile: state.profile.userProfile,
  loadingForm: state.societies.loadingForm,
  errors: state.societies.errors,
  industryList: state.societies.industryList,
  industryData: state.societies.industryData,
  industryLoaded: state.societies.industryLoaded,
  societyUsers: state.societies.societyUsers,
  projectsList: state.projects.projectsList
});

const actionsToProps = dispatch => ({
  postSocieties: payload => dispatch({ type: POST_SOCIETIES, payload }),
  showGroups: payload => dispatch({ type: FETCH_LIST_GROUPS, payload }),
  showIndustriesList: payload => dispatch({ type: FETCH_INDUSTRIES, payload }),
  showIndustryData: payload => dispatch({ type: FETCH_INDUSTRY_DATA, payload }),
  showSocietyUsers: payload => dispatch({ type: FETCH_SOCIETY_USERS, payload }),
  showProjects: payload => dispatch({ type: FETCH_LIST_PROJECTS, payload })
});

export default connect(stateToProps, actionsToProps)(CreateSociety);
