import { takeLatest, put, call } from 'redux-saga/effects';
import { getProfile, getNotifications, sendTicket, updatePassword, patchProfile } from '../../network';
import {
  FETCH_NOTIFICATIONS,
  FETCH_USER_PROFILE,
  FLAG_PASSWORD_CHANGED, LOADING_TICKET_FORM, NOTIFICATIONS_DATA, NOTIFICATIONS_LOADED, PATCH_IMAGE_PROFILE,
  PATCH_PASSWORD, POST_TICKET,
  SET_PROFILE_ERRORS, SET_TICKET_ERRORS,
  SET_USER_PROFILE,
  USER_LOADED
} from './types';
import { SET_IS_LOGGED_IN } from '../types';
import registerErrors from '../../utils/errors/formsErrors';
import messageValidationFailed from '../../utils/helpers/messageValidationFailed';
import formsErrors from '../../utils/errors/formsErrors';

function* fetchUserProfile() {
  try {
    const response = yield call(getProfile);
    yield put({ type: SET_USER_PROFILE, payload: response.data });
    yield put({ type: SET_IS_LOGGED_IN, payload: true });
    yield put({ type: USER_LOADED, payload: true })
  } catch (e) {
    yield put({ type: USER_LOADED, payload: false })
    // TODO: handle error
    console.log(e);
  }
}

function* patchUpdatePassword({ payload }) {
  try {
    yield call(updatePassword, payload);
    yield put({ type: FLAG_PASSWORD_CHANGED, payload: true });
  } catch (e) {
    const { response } = e;
    if (response.status === 400) {
      if (response.data.password && response.data.password.password === 'PASSWORD_EXISTS') {
        yield put({ type: SET_PROFILE_ERRORS, payload: registerErrors.PASSWORD_EXISTS });
      }
    }
    // TODO: handle error
    console.log(e);
  }
}

function* patchUpdateImage({ payload }) {
  let errors = [];
  try {
    yield put({ type: USER_LOADED, payload: true });
    yield call(patchProfile, payload);
    yield put({ type: USER_LOADED, payload: false });
    yield put({ type: SET_PROFILE_ERRORS, payload: errors });
    window.location.reload();
  } catch (e) {
    const { response } = e;
    if (response.status === 400) {
      if (response.data.image) {
        errors = [...errors, messageValidationFailed(response.data.image, 'imagen.')];
      }
      yield put({ type: SET_PROFILE_ERRORS, payload: errors });
      yield put({ type: USER_LOADED, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_PROFILE_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: USER_LOADED, payload: false });
    }
    // TODO: handle error
    console.log(e);
  }
}

function* postZohoTicket({ payload }) {
  let errors = [];
  try {
    yield put({ type: LOADING_TICKET_FORM, payload: true });
    yield call(sendTicket, payload);
    yield put({ type: LOADING_TICKET_FORM, payload: false });
    yield put({ type: SET_TICKET_ERRORS, payload: [] });
  } catch (e) {
    yield put({ type: LOADING_TICKET_FORM, payload: true });
    const { response } = e;
    if (response.status === 400) {
      if (response.data.subject) {
        errors = [...errors, messageValidationFailed(response.data.subject, 'asunto.')];
      }
      if (response.data.description) {
        errors = [...errors, messageValidationFailed(response.data.description, 'descripción.')];
      }
      yield put({ type: SET_TICKET_ERRORS, payload: errors });
    }
    else {
      errors = [...errors, formsErrors.TRY_AGAIN];
      yield put({ type: SET_TICKET_ERRORS, payload: errors });
    }
    yield put({ type: LOADING_TICKET_FORM, payload: false });
    // TODO: handle error
    console.log(e);
  }
}

function* fetchNotifications() {
  try {
    const response = yield call(getNotifications);
    yield put({ type: NOTIFICATIONS_DATA, payload: response.data });
    yield put({ type: NOTIFICATIONS_LOADED, payload: true })
  } catch (e) {
    yield put({ type: NOTIFICATIONS_LOADED, payload: true })
    // TODO: handle error
    console.log(e);
  }
}

function* watchFetchUserProfile() {
  yield takeLatest(FETCH_USER_PROFILE, fetchUserProfile);
}

function* watchUpdatePassword() {
  yield takeLatest(PATCH_PASSWORD, patchUpdatePassword);
}

function* watchPostZohoTicket() {
  yield takeLatest(POST_TICKET, postZohoTicket);
}

function* watchFetchNotifications() {
  yield takeLatest(FETCH_NOTIFICATIONS, fetchNotifications);
}

function* watchUpdateImage() {
  yield takeLatest(PATCH_IMAGE_PROFILE, patchUpdateImage);
}

const saga = [
  watchFetchUserProfile(),
  watchUpdatePassword(),
  watchPostZohoTicket(),
  watchFetchNotifications(),
  watchUpdateImage()
];

export default saga;
