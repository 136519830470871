import React from 'react'
import { Container, Card } from 'react-bootstrap'
import { CreateReport } from '../../components';

const CreateReportView = () => {
  return (
    <div className="container-admin bg-light">
      <Container className="pt-5" id='layout-reports4' style={{ paddingLeft: '4rem' }} fluid>
        <div className="row">
          <div className="col-12">
            <Card className="border-0 shadow-sm">
              <Card.Body>
                <CreateReport/>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default CreateReportView;
