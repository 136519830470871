import { takeLatest, put, call } from 'redux-saga/effects';
import {
  getProject,
  postProject,
  getProjectData,
  updateProject,
  getProjectAdmins,
  deleteProject,
  getProjectPDF,
  searchProject, getUserProjects
} from '../../network';
import {
  FETCH_LIST_PROJECTS,
  SET_DATA_LIST_PROJECTS,
  IS_LOADED,
  POST_PROJECTS,
  SET_DATA_PROJECT,
  FETCH_PROJECT,
  PATCH_PROJECT,
  IS_LOADED_PROJECT,
  SET_LIST_PROJECT_ADMINS,
  FETCH_PROJECT_ADMINS,
  DELETE_PROJECT,
  FETCH_PDF,
  INDEX_PAGINATION_PROJECTS,
  LOADING_PROJECT_FORM,
  SET_PROJECT_ERRORS,
  FETCH_USER_PROJECTS
} from './types';
import messageValidationFailed from '../../utils/helpers/messageValidationFailed';
import registerErrors from '../../utils/errors/formsErrors';
import formsErrors from '../../utils/errors/formsErrors';
import cookieHandler from '../../utils/helpers/cookie.handler';

function* showProjects({ payload }) {
  try {
    let response = "";
    const { queryValue, paginationPosition, projectsInScreen, projectSearch } = payload;
    if(!paginationPosition) {
      if (projectSearch)
        response = yield call(searchProject, `?limit=10000&offset=${(paginationPosition - 1) * projectsInScreen}&search=${projectSearch}`);
      else
        response = yield call(getProject, `?limit=10000&offset=${(paginationPosition - 1) * projectsInScreen}`);
    }else{
      response = yield call(searchProject, `${queryValue}&limit=${projectsInScreen}&offset=${(paginationPosition - 1) * projectsInScreen}`);
    }
     yield put({ type: IS_LOADED, payload: false })
    yield put({ type: SET_DATA_LIST_PROJECTS, payload: response.data.results });
    yield put({ type: IS_LOADED, payload: true });
    yield put({ type: INDEX_PAGINATION_PROJECTS, payload: Math.ceil(response.data.count/projectsInScreen) });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showProject({ payload }) {
  try {
    const response = yield call(getProjectData, payload);
    yield put({ type: IS_LOADED_PROJECT, payload: false });
    yield put({ type: SET_DATA_PROJECT, payload: response.data });
    yield put({ type: IS_LOADED_PROJECT, payload: true });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* deleteProjectData({ payload }) {
  try {
    yield call(deleteProject, payload);
    window.location.reload();
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* postProjects({ payload }) {
  let errors = [];
  try {
    yield put({ type: LOADING_PROJECT_FORM, payload: true });
    yield call(postProject, payload);
    yield put({ type: LOADING_PROJECT_FORM, payload: false });
    yield put({ type: SET_PROJECT_ERRORS, payload: null });
  } catch (e) {
    yield put({ type: LOADING_PROJECT_FORM, payload: true });
    const { response } = e;
    if (response.status === 400) {
      if (response.data.project_name) {
        if(response.data.project_name.includes('PROJECT_TAKEN') || response.data.project_name.includes('PROJECT_NAME_TAKEN')){
          errors = [...errors,registerErrors.PROJECT_NAME_TAKEN];
        }else{
          errors = [...errors, messageValidationFailed(response.data.project_name, 'nombre de proyecto.')];
        }
      }
      if (response.data.client_name) {
        errors = [...errors, messageValidationFailed(response.data.client_name, 'nombre de cliente.')];
      }
      if (response.data.associated_service_description) {
        errors = [...errors, messageValidationFailed(response.data.associated_service_description, 'descripción del servicio asociado.')];
      }
      if (response.data.meta_title) {
        errors = [...errors, messageValidationFailed(response.data.meta_title, 'meta tittle.')];
      }
      if (response.data.meta_content) {
        errors = [...errors, messageValidationFailed(response.data.meta_content, 'meta content.')];
      }
      if (response.data.service_description) {
        errors = [...errors, messageValidationFailed(response.data.service_description, 'descripción del servicio.')];
      }
      if (response.data.url_own_site) {
        errors = [...errors, messageValidationFailed(response.data.url_own_site, 'url sitio proyecto.')];
      }
      if (response.data.custom_portal_url) {
        errors = [...errors, messageValidationFailed(response.data.custom_portal_url, 'url sitio custom portal.')];
      }
      if (response.data.header_title === "URL_EXISTS") {
        errors = [...errors, messageValidationFailed(response.data.header_title, 'titulo header.')];
      }
      if (response.data.footer_disclaimer) {
        errors = [...errors, messageValidationFailed(response.data.footer_disclaimer, 'derechos de autor.')];
      }
      if (response.data.contact_email) {
        errors = [...errors, messageValidationFailed(response.data.contact_email, 'mail contacto.')];
      }
      if (response.data.contact_phone) {
        errors = [...errors, messageValidationFailed(response.data.contact_phone, 'numero de contacto.')];
      }
      if (response.data.icon) {
        errors = [...errors, messageValidationFailed(response.data.icon, 'icono.')];
      }
      if (response.data.image) {
        errors = [...errors, messageValidationFailed(response.data.image, 'imagen.')];
      }
      if (response.data.logo) {
        errors = [...errors, messageValidationFailed(response.data.logo, 'logo.')];
      }
      yield put({ type: SET_PROJECT_ERRORS, payload: errors });
      yield put({ type: LOADING_PROJECT_FORM, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_PROJECT_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: LOADING_PROJECT_FORM, payload: false });
    }
    // TODO: handle error
    console.log(e);
  }
}

function* patchProject({ payload }) {
  let errors = [];
  try {
    yield put({ type: LOADING_PROJECT_FORM, payload: true });
    yield call(updateProject, payload);
    yield put({ type: LOADING_PROJECT_FORM, payload: false });
    yield put({ type: SET_PROJECT_ERRORS, payload: null });
  } catch (e) {
    yield put({ type: LOADING_PROJECT_FORM, payload: true });
    const { response } = e;
    if (response.status === 400) {
      if (response.data.project_name) {
        if(response.data.project_name.includes('PROJECT_TAKEN') || response.data.project_name.includes('PROJECT_NAME_TAKEN')){
          errors = [...errors,registerErrors.PROJECT_NAME_TAKEN];
        }else{
          errors = [...errors, messageValidationFailed(response.data.project_name, 'nombre de proyecto.')];
        }
      }
      if (response.data.client_name) {
        errors = [...errors, messageValidationFailed(response.data.client_name, 'nombre de cliente.')];
      }
      if (response.data.associated_service_description) {
        errors = [...errors, messageValidationFailed(response.data.associated_service_description, 'descripción del servicio asociado.')];
      }
      if (response.data.meta_title) {
        errors = [...errors, messageValidationFailed(response.data.meta_title, 'meta tittle.')];
      }
      if (response.data.meta_content) {
        errors = [...errors, messageValidationFailed(response.data.meta_content, 'meta content.')];
      }
      if (response.data.service_description) {
        errors = [...errors, messageValidationFailed(response.data.service_description, 'descripción del servicio.')];
      }
      if (response.data.url_own_site) {
        errors = [...errors, messageValidationFailed(response.data.url_own_site, 'url sitio proyecto.')];
      }
      if (response.data.custom_portal_url) {
        errors = [...errors, messageValidationFailed(response.data.custom_portal_url, 'url sitio custom portal.')];
      }
      if (response.data.header_title) {
        errors = [...errors, messageValidationFailed(response.data.header_title, 'titulo header.')];
      }
      if (response.data.footer_disclaimer) {
        errors = [...errors, messageValidationFailed(response.data.footer_disclaimer, 'derechos de autor.')];
      }
      if (response.data.contact_email) {
        errors = [...errors, messageValidationFailed(response.data.contact_email, 'mail contacto.')];
      }
      if (response.data.contact_phone) {
        errors = [...errors, messageValidationFailed(response.data.contact_phone, 'numero de contacto.')];
      }
      if (response.data.icon) {
        errors = [...errors, messageValidationFailed(response.data.icon, 'icono.')];
      }
      if (response.data.image) {
        errors = [...errors, messageValidationFailed(response.data.image, 'imagen.')];
      }
      if (response.data.logo) {
        errors = [...errors, messageValidationFailed(response.data.logo, 'logo.')];
      }
      yield put({ type: SET_PROJECT_ERRORS, payload: errors });
      yield put({ type: LOADING_PROJECT_FORM, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_PROJECT_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: LOADING_PROJECT_FORM, payload: false });
    }
    // TODO: handle error
    console.log(e);
  }
}

function* showProjectAdmins({payload}) {
  try {
    const response = yield call(getProjectAdmins, payload);
    yield put({ type: SET_LIST_PROJECT_ADMINS, payload: response.data.results });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showUserProject() {
  try {
    const response = yield call(getUserProjects);
    yield put({ type: IS_LOADED, payload: false })
    yield put({ type: SET_DATA_LIST_PROJECTS, payload: response.data.results });
    yield put({ type: IS_LOADED, payload: true });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showProjectPDF({ payload }) {
  try {
    const accessToken = cookieHandler().getAccessToken();
    yield call(getProjectPDF, `${payload}/?token=${accessToken}`);
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_SERVICE_URL}api/v1/projects/detail/pdf/${payload}/?token=${accessToken}`;
    link.setAttribute('download', 'report.pdf');
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* watchShowProjects() {
  yield takeLatest(FETCH_LIST_PROJECTS, showProjects);
}

function* watchShowProject() {
  yield takeLatest(FETCH_PROJECT, showProject);
}

function* watchDeleteProjectData() {
  yield takeLatest(DELETE_PROJECT, deleteProjectData);
}

function* watchPostProjects() {
  yield takeLatest(POST_PROJECTS, postProjects);
}

function* watchPatchProject() {
  yield takeLatest(PATCH_PROJECT, patchProject);
}

function* watchShowProjectAdmins() {
  yield takeLatest(FETCH_PROJECT_ADMINS, showProjectAdmins);
}

function* watchShowUserProject() {
  yield takeLatest(FETCH_USER_PROJECTS, showUserProject);
}

function* watchShowProjectPDF() {
  yield takeLatest(FETCH_PDF, showProjectPDF);
}

const saga = [
  watchShowProjects(),
  watchShowProject(),
  watchPostProjects(),
  watchPatchProject(),
  watchShowProjectAdmins(),
  watchDeleteProjectData(),
  watchShowProjectPDF(),
  watchShowUserProject()
];

export default saga;
