import React from 'react'
import Spinner from 'react-bootstrap/Spinner';

function Loading() {
  return (
    <div className='loadingPrincipal' id='loadingPrincipalId'>
     <Spinner animation="border" style={{color: '#009cde'}} />
     <div>
     <span>Cargando información...</span>
     </div>
    </div>
  )
}

export default Loading