import config from '../config';

const { reportsURI, reportUserURI, modulesAdminURI, reportListUserURI, reportSearchURI, reportRef } = config().services;

const network = client => ({
  getReports: payload => client.get(`${reportsURI}${payload}`),
  deleteReport: payload => client.delete(`${reportsURI}${payload}/`),
  getReportData: payload => client.get(`${reportsURI}${payload}/`),
  postReport: (payload) => client.post(reportsURI, payload ),
  updateReport: ({ fd, uuid }) => client.patch(`${reportsURI}${uuid}/`, fd),
  getUserReports: payload => client.get(`${reportListUserURI}?limit=100000&${payload}`),
  getUserReportData: payload => client.get(`${reportUserURI}${payload}/`),
  getModulesList: payload => client.get(`${modulesAdminURI}${payload}`),
  searchReports: payload => client.get(`${reportSearchURI}${payload}`),
  getReportsRef: payload => client.get(`${reportRef}`)
});

export default network;
