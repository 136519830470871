import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Dropdown, Badge } from 'react-bootstrap'
import { Edit, List } from 'react-feather'
import { useHistory } from 'react-router-dom';
import ProjectDelete from './projectDelete';


const ProjectItem = (props) => {
  const history = useHistory();
  const uuid = props.uuid;
  const formData = { enable: !props.enabled };
  const { readOnly } = props;

  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    const windowLocation = window.location.pathname
    const rutaInicial = windowLocation.split('/').slice(0, 4).join('/')
    setCurrentPath(rutaInicial)
  }, [])



  const handleDisable = () => {
    props.patchProject({ formData, uuid });
    setTimeout(() => {
      window.location.reload();
    }, 3000)
    
  }

  return (
    <>

      <Row className='project-item p-3 mb-3' id={ currentPath === '/admin/group/detail' ? 'projects_item' : 'projects_item'  }>

        <Col xs="12" sm="12" lg="3" xl="2">
          <div className="media">
            <div className="media-body">
              <div className='d-flex align-items-center justify-content-between justify-content-sm-start'>
                <h6 className="text-uppercase mb-0">{props.name}</h6>
                {/*
                  !props.enabled && !readOnly && (
                    <Badge variant="warning">Desabilitado</Badge>
                  )*/
                }
              </div>

            </div>
          </div>
        </Col>

        {
          currentPath === '/admin/group/detail' ? (
            <Col xs="12" sm="12" lg="3" xl="2" style={{display:'none'}}>   {
              !readOnly && (
                <h6 className="text-muted small mb-0">{props.admin} Administradores </h6>
              )
            }</Col>
          ):(
            <Col xs="12" sm="12" lg="3" xl="2">   {
              !readOnly && (
                <h6 className="text-muted small mb-0">{props.admin} Administradores </h6>
              )
            }</Col>
          )
        }      


          {
          currentPath === '/admin/group/detail' ? (
            <Col xs="12" sm="12" lg="3" xl="2" style={{display:'none'}}><span>{props.fecha}</span></Col>
          ):(
            <Col xs="12" sm="12" lg="3" xl="2">   {
              !readOnly && (
                <h6 className="text-muted small mb-0">{props.admin} Administradores </h6>
              )
            }</Col>
          )
        }    




        {
            currentPath === '/admin/group/detail' ? (

              <Col xs="12" sm="12" lg="3" xl="2" className='d-flex justify-content-center'>

              {
               <h6><span className={props.enabled ? 'habilitadoStatus' : 'desabilitadoStatus'}></span>{props.enabled ? 'Habilitado' : 'Deshabilitado'}</h6>
              }
    
            </Col>
    

            ):(
              <Col xs="12" sm="12" lg="3" xl="2">

              {
                !props.enabled && !readOnly ? (
                  <h6><span className='desabilitadoStatus'></span>Deshabilitado</h6>
                ):(
                  <h6><span className='habilitadoStatus'></span>Habilitado</h6>
                )
              }
    
            </Col>
    
            )
          }




        
        {
          currentPath === '/admin/group/detail' ? (
            <Col xs="12" sm="12" lg="3" xl="2"> 
            <div style={{display:'flex', justifyContent:'end'}}>
  
              <Dropdown>
                {
                  !readOnly && (
                    <Dropdown.Toggle variant="outline-dark" className='mr-2' size="sm">
                      <Edit size="12" />
                    </Dropdown.Toggle>
                  )
                }
  
                <Dropdown.Menu className="shadow-sm border-0 p-2">
                  {
                    !readOnly && (
                      props.enabled ? (
                        <Dropdown.Item onClick={handleDisable}>Deshabilitar</Dropdown.Item>
                      ) : (
                        <Dropdown.Item onClick={handleDisable}>Habilitar</Dropdown.Item>
                      )
                    )
                  }
                  <Dropdown.Item onClick={() => history.push({ pathname: `/admin/projects/edit/${props.uuid}` })}>Editar</Dropdown.Item>
                  <ProjectDelete setFlagDelete={props.setFlagAction} uuid={props.uuid} valueSearch={props.valueSearch} paginationPosition={props.paginationPosition} projectsInScreen={props.projectsInScreen} />
                </Dropdown.Menu>
                <Button variant="outline-dark" size="sm" className='btnShowDetails' onClick={() => history.push({ pathname: `/admin/projects/detail/${props.uuid}` })}>
                  Ver Detalle
                  <List size="12" className='ml-2 mb-0' />
                </Button>
              </Dropdown>
  
            </div>
          </Col>
          ):(
            <Col xs="12" sm="12" lg="3" xl="2" className="" id='btnDetails'> 
            <div className="d-flex align-items-center">
  
              <Dropdown>
                {
                  !readOnly && (
                    <Dropdown.Toggle variant="outline-dark" className='mr-2' size="sm">
                      <Edit size="12" />
                    </Dropdown.Toggle>
                  )
                }
  
                <Dropdown.Menu className="shadow-sm border-0 p-2">
                  {
                    !readOnly && (
                      props.enabled ? (
                        <Dropdown.Item onClick={handleDisable}>Deshabilitar</Dropdown.Item>
                      ) : (
                        <Dropdown.Item onClick={handleDisable}>Habilitar</Dropdown.Item>
                      )
                    )
                  }
                  <Dropdown.Item onClick={() => history.push({ pathname: `/admin/projects/edit/${props.uuid}` })}>Editar</Dropdown.Item>
                  <ProjectDelete setFlagDelete={props.setFlagAction} uuid={props.uuid} valueSearch={props.valueSearch} paginationPosition={props.paginationPosition} projectsInScreen={props.projectsInScreen} />
                </Dropdown.Menu>
                <Button variant="outline-dark" size="sm" className='btnShowDetails' onClick={() => history.push({ pathname: `/admin/projects/detail/${props.uuid}` })}>
                  Ver Detalle
                  <List size="12" className='ml-2 mb-0' />
                </Button>
              </Dropdown>
  
            </div>
          </Col>
          )

        }
      
      
      </Row>
    </>
  )
}


export default ProjectItem;
