import React, { useEffect } from 'react';
import { Badge, Breadcrumb, Col, Row, Table } from 'react-bootstrap';
import { ConfigProvider } from 'react-avatar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_REPORT } from '../../store/report/types';
import { useParams } from 'react-router-dom';
import ProjectItem from '../project_item';
import GroupItem from '../group_item';
import SocietyItem from '../society_item';

const ReportDetail = ({ showReport, reportData }) => {
  const { uuid } = useParams();

  useEffect(() => {
    showReport(uuid);
  }, [showReport, uuid]);

  return (
    <>
      {
        reportData !== null ? (
          
            <>
              <Row className='d-flex align-items-center justify-content-beetwen' id='projectsAdmin'>

                <Col className='p-0'>
                  <Breadcrumb className="small">
                    <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
                    <Breadcrumb.Item >Administración Reportes</Breadcrumb.Item>
                    <Breadcrumb.Item active>Detalle Reporte</Breadcrumb.Item>
                  </Breadcrumb>
                  <h4 className="font-weight-bold mb-1 ml-3">{reportData.report_name}</h4>
                </Col>              
                <Col>
                  <h6 className="float-md-right m-0 mt-2">Fecha de creación: {reportData.created.date}</h6>
                </Col>
              </Row>

 
        
   

              <hr />
              <Row className="mb-2 'd-flex align-items-center justify-content-beetwen" >
                <Col sm="12" lg="4"  className="mb-2 'd-flex align-items-center justify-content-center">
                  <h6 className="font-weight-bold">Descripción del reporte</h6>
                  <p className="small">{reportData.description}</p>
                </Col>
                <Col sm="12" lg="4" className="mb-2 'd-flex align-items-center justify-content-center text-center">
                  <h6 className="font-weight-bold">Tipo de herrramienta</h6>
                  <p className="small">{reportData.tool_type}</p>
                </Col>
                
                <Col lg="4" className="mb-2 'd-flex align-items-center justify-content-center text-center">
                  <h6 className="font-weight-bold">Tipos de usuarios</h6>
                  {
                    reportData.enable_project_admin === true && (
                      <Badge variant="info" className="mr-1" pill>Admin de proyecto</Badge>
                    )
                  }
                  {
                    reportData.enable_group_admin === true && (
                      <Badge variant="danger" className="mr-1" pill>Admin de grupo</Badge>
                    )
                  }
                  {
                    reportData.enable_user_group === true && (
                      <Badge variant="warning" className="mr-1" pill>Usuario de grupo</Badge>
                    )
                  }
                  {
                    reportData.enable_user_society === true && (
                      <Badge variant="dark" className="mr-1" pill>Usuario de sociedad</Badge>
                    )
                  }
                </Col>
              </Row>

         
    

              <Row>
                <Col>
                  <img alt="" width={50} className="upload-file-custom-img img-fluid mt-0" src={process.env.REACT_APP_SERVICE_URL+reportData.image} />
                </Col>
              </Row>
              {
                reportData.project_list && (
                  <>
                    <Row>
                      <Col className='mt-5'>
                        <h6 className="font-weight-bold">Proyectos</h6>
                      </Col>
                    </Row>
                    <Table striped hover>
                      <tbody>
                          {
                            reportData.project_list.map( (project) => {
                              return (
                                <ProjectItem key={project.uuid} uuid={project.uuid} name={project.project_name} readOnly="true"/>
                              )
                            })
                          }
                      </tbody>
                    </Table>
                  </>
                )
              }
              {
                reportData.group_list && (
                  <>
                    <Row>
                      <Col lg="6" className="mt-5">
                        <h6 className="font-weight-bold">Grupos</h6>
                      </Col>
                    </Row>
                    <Table striped hover>
                      <tbody>
                        {
                          reportData.group_list.map( (group) => {
                            return (
                              <GroupItem key={group.uuid} uuid={group.uuid} name={group.group_name} readOnly="true"/>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </>
                )
              }
              {
                reportData.society_list && (
                  <>
                    <Row>
                      <Col lg="6" className="mt-5">
                        <h6 className="font-weight-bold">Sociedades</h6>
                      </Col>
                    </Row>
                    <Table>
                      <tbody>
                        {
                          reportData.society_list.map( (society) => {
                            return (
                              <SocietyItem key={society.uuid} uuid={society.uuid} name={society.society_business_name} readOnly="true"/>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </>
                )
              }
            </>
 
        ):(
          ''
        )
      }
    </>
  )
}

ReportDetail.propTypes = {
  showReport: PropTypes.func.isRequired,
  reportData: PropTypes.object,
  isLoaded: PropTypes.bool.isRequired
};

const stateToProps = state => ({
  reportData: state.reports.reportData,
  isLoaded: state.reports.isLoaded
});

const actionsToProps = dispatch => ({
  showReport: payload => dispatch({ type: FETCH_REPORT, payload })
});

export default connect(stateToProps, actionsToProps)(ReportDetail);
