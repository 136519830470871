import registerErrors from '../errors/formsErrors';

const ValidationFailed = (message, name) =>{
  if(message.includes('REQUIRED')){
    return (`${registerErrors.REQUIRED} ${name}`);
  }
  if(message.includes('FORMAT_ERROR')){
    return (`${registerErrors.FORMAT_ERROR} ${name}`);
  }
  if(message.includes('ERROR_LENGTH')){
    return (`${registerErrors.ERROR_LENGTH} ${name}`);
  }
  if(message.includes('FIELD_BLANK')){
    return (`${registerErrors.FIELD_BLANK} ${name}`);
  }
  if(message.includes('FORMAT_NOT_ALLOWED')){
    return (`${registerErrors.FORMAT_NOT_ALLOWED} ${name}`);
  }
  if(message.includes('FORMAT_ERROR')){
    return (`${registerErrors.FORMAT_ERROR} ${name}`);
  }
  if(message.includes('SAME_DATA')){
    return (`${registerErrors.SAME_DATA} ${name}`);
  }
  if(message.includes('URL_EXISTS')){
    return (`${registerErrors.URL_EXISTS} ${name}`);
  }
}

export default ValidationFailed;
