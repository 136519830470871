import React from 'react'
import Spinner from 'react-bootstrap/Spinner';
function LoadingBtn() {
  return (
    <div className='loadingBtn'>
    <Spinner animation="border" style={{color: '#009cde'}} />
    <div>
    </div>
   </div>
  )
}

export default LoadingBtn