import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NavbarWeb from './navbar';
import { POST_LOGOUT } from '../../store/types';

const Navbar = ({ isLoggedIn, logout, name, currentPath }) => {
  return <NavbarWeb isLoggedIn={isLoggedIn} logout={logout} name={name} currentPath={currentPath}/>;
};

Navbar.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired
};

const stateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
});

const actionToProps = dispatch => ({
  logout: () => dispatch({ type: POST_LOGOUT })
});

export default connect(stateToProps, actionToProps)(Navbar);
