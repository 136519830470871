import React, { useEffect } from 'react';
import { Breadcrumb, Col, Row, Button, Table } from 'react-bootstrap';
import { ConfigProvider } from 'react-avatar';
import GroupItem from '../group_item';
import PropTypes from 'prop-types';
import { FETCH_PDF, FETCH_PROJECT } from '../../store/project/types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Copy } from 'react-feather';
import UsersItem from '../users_item';

const ProjectDetail = ({ showProject, projectData, showProjectPDF }) => {

  const { uuid } = useParams();

  console.log('Console del detail', showProject)

  useEffect(() => {
    showProject(uuid);
  }, [uuid, showProject]);

  const handlePDF = () => {
    showProjectPDF(uuid);
  }

  return (

    <>


      <Row className='d-flex align-items-center justify-content-beetwen' id='projectsAdmin'>

        
        <Col className='p-0'>
          <Breadcrumb className="small">
            <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin/projects">Administración Proyectos</Breadcrumb.Item>
            <Breadcrumb.Item active>Detalle proyecto</Breadcrumb.Item>
          </Breadcrumb>
          {
            projectData && (
              <h4 className="font-weight-bold mb-1 ml-3">{projectData.project_name}</h4>
            )
          }
        </Col>

        <Col>
          {
            projectData && (
              <Row>

                <Col>
                  <Row className="float-sm-right ml-1">
                    <div className="mt-3 mr-3">
                      <span className="font-weight-bold">ID:</span>
                      <span className="">{projectData.uuid.substring(0, 10)}...</span>
                    </div>
                    <Button
                      className="m-0 mt-2 mr-3"
                      variant="outline-primary"
                      onClick={() => { navigator.clipboard.writeText(projectData.uuid) }}
                    >
                      <Copy size={20} />
                    </Button>
                  </Row>
                </Col>
              </Row>
            )
          }
        </Col>
      </Row>


      
      <div style={{}}  id='projects_item' className='mt-5 mb-5 p-2'>
        <div>
        <h6 className="font-weight-bold">Descripción del proyecto</h6>
          {
            projectData ? (
              <p className="small">{projectData.service_description}</p>
            ) : (
              <p className="small">-</p>
            )
          }
        </div>
        <div>
        <h6 className="font-weight-bold">Fecha de creación</h6>
          {
            projectData ? (
              <p className="small">{projectData.created.date}</p>
            ) : (
              <p className="small">-</p>
            )
          }
        </div>
        <div>
        <h6 className="font-weight-bold">Status</h6>
          {
            projectData && (
              projectData.enable ? (
                <h6><span className='habilitadoStatus'></span>Habilitado</h6>
              ) : (
                <h6><span className='desabilitadoStatus'></span>Deshabilitado</h6>
              )
            )
          }
        </div>
      </div>
      <Row>
        <Col>
          {
            projectData && (
              projectData.groups.length !== 0 && (
                <Row>
                  <Col lg="6">
                    <h6 className="font-weight-bold">Grupo Sociedad</h6>
                  </Col>
                </Row>
              )
            )
          }
          {
            projectData && projectData.groups.map((group) => {
              return (
                <div key={group.uuid}>
                  {
                    !group.admin_quantity === 0 ? (
                      <GroupItem
                        readOnly="true"
                        name={group.group_name} project={group.project}
                        variant="warning" uuid={group.uuid}
                        user={group.user_quantity}
                        society={group.society_quantity}
                      />
                    ) : (
                      <GroupItem
                        readOnly="true"
                        name={group.group_name}
                        project={group.project}
                        variant="warning"
                        admin={`${group.admin_quantity.first_name} ${group.admin_quantity.last_name}`}
                        uuid={group.uuid}
                        user={group.user_quantity}
                        society={group.society_quantity}
                      />
                    )
                  }
                </div>
              );
            })
          } {
            projectData && (
              projectData.admin.length !== 0 && (
                <>
                  <Row>
                    <Col lg="12"  >
                      <h6 className="font-weight-bold mt-3">Admin</h6>
                    </Col>
                  </Row>
                  {
                    projectData.admin.map((admin) => {
                      return (
                        <Table striped hover size="md">
                          <tbody>
                            <UsersItem key={admin.uuid} uuid={admin.uuid} name={`${admin.first_name} ${admin.last_name}`} readOnly="true" />
                          </tbody>
                        </Table>
                      );
                    })
                  }
                </>
              )
            )
          }
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-end">
          <Button variant="outline-primary" size="md" onClick={handlePDF}>Imprimir info</Button>
        </Col>
      </Row>
    </>

  )
}

ProjectDetail.propTypes = {
  showProject: PropTypes.func.isRequired,
  projectData: PropTypes.object,
  isLoaded: PropTypes.bool.isRequired,
  showProjectPDF: PropTypes.func.isRequired
};

const stateToProps = state => ({
  projectData: state.projects.projectData,
  isLoaded: state.projects.isLoaded
});

const actionsToProps = dispatch => ({
  showProject: payload => dispatch({ type: FETCH_PROJECT, payload }),
  showProjectPDF: payload => dispatch({ type: FETCH_PDF, payload })
});

export default connect(stateToProps, actionsToProps)(ProjectDetail);
