import React, { useEffect } from 'react';
import { Row, Col, Button, Dropdown, Badge } from 'react-bootstrap'
import Avatar from 'react-avatar'
import { Eye, MoreVertical, ArrowRight } from 'react-feather';
import { useHistory } from 'react-router-dom';
import AccountingDelete from './accountingDelete';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PATCH_ACCOUNTING } from '../../store/accounting/types';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';

const ReportItem = props => {
  const fd = { enable: !props.enabled };
  const uuid = props.uuid;

  const handleDisable = () => {
    props.patchAccounting({ fd, uuid });
    window.location.reload();
  }

  const { readOnly, userView } = props;
  const history = useHistory();

  return (
    <>
      <tr>
        <Row className='p-3' id='projects_item'>
          <Col xs="12" sm="12" lg="3" xl="2">
            <div className="media">
              {
                /**
                 *<Avatar maxInitials="2" round="12px" fgColor="black" size="45" name={props.name} className="mr-3"/>
                 */
              }
              <div className="media-body mt-1">
                <h6 className="text-uppercase font-weight-bold mb-0 mr-2">{props.name}</h6>
                {
                  props.accounting_type === 'BALANCE_8_COLUMNS' && (
                    <p className="small mb-0 text-muted">Balance de 8 columnas</p>
                  )
                }
                {
                  props.accounting_type === 'FINANCIAL_SITUATION' && (
                    <p className="small mb-0 text-muted">Estado de situación financiera</p>
                  )
                }
                {
                  props.accounting_type === 'STATUS_RESULTS' && (
                    <p className="small mb-0 text-muted">Estado de resultado</p>
                  )
                }
                {
                  props.accounting_type === 'CASH_FLOWS' && (
                    <p className="small mb-0 text-muted">Estado de flujos efectivos</p>
                  )
                }
              </div>
            </div>
          </Col>

     

          <Col xs="12" sm="12" lg="3" xl="2">
            <div className="media" id='lista'>

              {
                props.project_list ? (
                  props.project_list.map((project) => (
                    <ul id='ul_project_list'>
                      <li>{project.project_name}</li>
                    </ul>
                  ))) : (
                    <ul>
                      <li>-</li>
                    </ul>
                )
              }
            </div>
          </Col>




          {!readOnly &&
            <>
              <Col xs="12" sm="12" lg="3" xl="2" className='d-flex' id='proyectosAso'>
                {
                  props.project_admin === true && (
                    <Badge className='text-wrap mr-2' pill>Proyecto</Badge>
                  )
                }
                {
                  props.group_admin === true && (
                    <Badge className='text-wrap mr-2' pill>Grupo sociedad</Badge>
                  )
                }
                {
                  props.user_group === true && (
                    <Badge className='text-wrap mr-2' pill>Usuario de grupo</Badge>
                  )
                }
                {
                  props.user_society === true && (
                    <Badge className='text-wrap mr-2' pill>Sociedad</Badge>
                  )
                }
              </Col>

              <Col xs="12" sm="12" lg="3" xl="2">
                <div className="d-flex align-items-center">

                  <Dropdown className='project-item'>
                    <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" className="mx-1" size="sm">
                      <MoreVertical size="12" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="shadow-sm border-0 p-2">
                      {
                        props.enabled ? (
                          <Dropdown.Item
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Deshabilitar reporte de contabilidad"
                            onClick={handleDisable}
                          >
                            Deshabilitar
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Habilitar reporte de contabilidad"
                            onClick={handleDisable}
                          >
                            Habilitar
                          </Dropdown.Item>
                        )
                      }
                      <DropdownItem onClick={() => history.push({
                        pathname: `/admin/accounting/edit/${props.uuid}`
                      })}>Editar</DropdownItem>
                      <AccountingDelete uuid={props.uuid} setFlagDelete={props.setFlagAction} />
                    </Dropdown.Menu>
                  </Dropdown>

                  <Button variant="outline-dark" size="sm" onClick={() => history.push({
                    pathname: `/admin/accounting/detail/${props.uuid}`
                  })}>
                    Ver Detalle
                    <ArrowRight size="12" className='ml-2 mb-0' />
                  </Button>
                </div>
              </Col>
            </>
          }
          {
            userView && (
              <>
                <Col xs="12" sm="12" lg="3" xl="2">
                  <div className="d-flex align-items-center">
                    <Button
                      variant="outline-dark"
                      size="sm"
                      className="mx-1"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Ver reporte"
                      onClick={() => history.push({
                        pathname: '/reportView',
                        state:
                        {
                          uuid: props.uuid
                        }
                      })}
                    >
                      <Eye size="12" />
                    </Button>
                  </div>
                </Col>
              </>
            )
          }
        </Row>
      </tr>

    </>
  )
}

ReportItem.propTypes = {
  patchAccounting: PropTypes.func.isRequired
};

const actionsToProps = dispatch => ({
  patchAccounting: payload => dispatch({ type: PATCH_ACCOUNTING, payload })
});

export default connect(null, actionsToProps)(ReportItem);
