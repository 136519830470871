import config from '../config';

const { folderListURI, folderTemplateListURI, sharedTokenRouteURI, folderTemplateURI, documentURI, userFolderListURI, shareTokenURI, documentTaxPanelForms, documentTaxPanelFormsAdmin } = config().services;

const network = client => ({
  getFolders: payload => client.get(`${folderListURI}${payload}`),
  getTemplateFolders: payload => client.get(`${folderTemplateListURI}${payload}`),
  postFolder: payload => client.post(folderTemplateURI, { ...payload }),
  postDocument: payload => client.post(documentURI, payload ),
  getUserFolders: payload => client.get(`${userFolderListURI}${payload}`),
  getDocumentToken: payload => client.get(`${shareTokenURI}${payload}/`),
  deleteFolder: payload => client.delete(`${folderTemplateURI}${payload}/`),
  deleteDocument: payload => client.delete(`${documentURI}${payload}/`),
  updateFolder: ({ folderForm, uuid }) => client.patch(`${folderTemplateURI}${uuid}/`, folderForm),
  updateDocument: ({ documentForm, uuid }) => client.patch(`${documentURI}${uuid}/`, documentForm),
  getToken: payload => client.get(`${sharedTokenRouteURI}${payload}`),
  getDocumentPanelTaxForms: payload => client.get(`${documentTaxPanelForms}${payload}`),
  documentTaxPanelFormsAdmins: async payload => {
    console.log(payload)
    const response = await client.get(`${documentTaxPanelFormsAdmin}${payload}`);
    console.log(`${documentTaxPanelFormsAdmin}${payload}`);
    console.log(response);
    return response;
  }
});

export default network;
