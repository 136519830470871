import config from '../config';

const { societiesURI, societiesAdminURI, societiesPdfURI, societySearchURI, userSocietiesURI, industryURI, societyUsersList, userSocietyURI } = config().services;

const network = client => ({
  getSociety: payload => client.get(`${societiesURI}${payload}`),
  postSociety: payload => client.post(societiesURI, { ...payload }),
  updateSociety: ({ societyForm, uuid, tax_module }) => client.patch(`${societiesURI}${uuid}/?tax_module=${tax_module}`, societyForm),
  getSocietyData: payload => client.get(`${societiesURI}${payload}/`),
  getSocietyAdmins: () => client.get(`${societiesAdminURI}?limit=100000`),
  deleteSociety: payload => client.delete(`${societiesURI}${payload}/`),
  getSocietyPDF: payload => client.get(`${societiesPdfURI}${payload}`),
  searchSociety: (payload) => client.get(`${societySearchURI}${payload}`),
  getSocietiesUser: (payload) => client.get(`${userSocietiesURI}?limit=100000&${payload}`),
  getIndustries: payload => client.get(`${industryURI}${payload}`),
  postIndustry: payload => client.post(industryURI, { ...payload }),
  updateIndustry: ({ industryForm, uuid }) => client.patch(`${industryURI}${uuid}/`, industryForm),
  deleteIndustry: payload => client.delete(`${industryURI}${payload}/`),
  getIndustryData: payload => client.get(`${industryURI}${payload}/`),
  getSocietyUsers: payload => client.get(`${societyUsersList}?limit=100&search=${payload}`),
  getUserSocieties: () => client.get(`${userSocietyURI}?limit=100000`)
});

export default network;
