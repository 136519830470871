import Validator from 'fastest-validator';
import rutValidator from '../helpers/rutValidator';

const validator = (schema, params) => {
  const validator = new Validator();
  const check = validator.compile(schema);
  let validationResult = check(params);
  const { society_rut, rut_user, password_confirmation, password } = params;

  if(password_confirmation && password && validationResult === true){
    if(password === password_confirmation)
      validationResult = true;
    else
      validationResult = [{...validationResult,
        actual: password_confirmation,
        expected: password,
        field: 'password_confirmation',
        message: 'Las contraseñas deben ser iguales'}];
  }

  if(society_rut && validationResult === true){
    validationResult = rutValidator(society_rut, 1);
  }

  if(rut_user && validationResult === true){
    validationResult = rutValidator(rut_user, 0);
  }

  if (validationResult !== true) {
    return validationResult;
  }

  return [];
};

export default validator;
