import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Row, Table } from 'react-bootstrap';
import ProjectItem from '../project_item';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_LIST_PROJECTS, PATCH_PROJECT } from '../../store/project/types';
import PaginationBar from '../pagination/paginationBar';
import SearchBar from '../search_bar';


const ProjectsAdmin = ({patchProject, showProjects, projectsList, indexPagination }) => {

  const [flagAction, setFlagAction] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const [paginationPosition, setPaginationPosition] = useState(window.location.hash.substring(1, window.location.hash.indexOf("?")));
  const [projectsInScreen, setProjectsInScreen] = useState(5);
  const [queryValue, setQueryValue] = useState(window.location.hash.substring(window.location.hash.indexOf("?")));
  const history = useHistory();


  useEffect(() => {

    showProjects({ valueSearch, queryValue, paginationPosition, projectsInScreen })

  }, [showProjects, flagAction, valueSearch, paginationPosition, projectsInScreen, queryValue]);
  const handleSearch = ({ target }) => {
    setPaginationPosition(1);
    setQueryValue(`?search=${target.value}`);
    setValueSearch(target.value);
  }


  useEffect(() => {
    if (!window.location.hash) {
      setPaginationPosition('1');
      setQueryValue("?search=");
    } else {
      setValueSearch(window.location.hash.substring(window.location.hash.indexOf("h=") + 2));
    }
  }, [setPaginationPosition]);

  useEffect(() => {
    history.replace(`#${paginationPosition}?search=${valueSearch}`);
  }, [paginationPosition, valueSearch, history]);

  return (
    <>

      <Row className='d-flex align-items-center justify-content-beetwen' id='projectsAdmin'>

        <Col xs="12" sm="12" lg="3" xl="6">
          <Breadcrumb className="small">
            <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
            <Breadcrumb.Item active>Administración Proyectos</Breadcrumb.Item>
          </Breadcrumb>
          <h4 className="font-weight-bold ml-3">Proyectos</h4>
        </Col>

        <Col xs="12" sm="12" lg="9" xl="6" className='mt-4'>
          <Row>
            <Col sm="12" className='d-block d-md-flex justify-content-end align-items-center'>
              <SearchBar handleSearch={handleSearch} defaultValue={decodeURI(valueSearch).length === 1 ? (`${decodeURI(valueSearch)} `) : (decodeURI(valueSearch))} />
              <Button as={Link} to="/admin/projects/create" variant="primary"  >+ Crear</Button>
            </Col>
          </Row>
        </Col>

      </Row>

    

      <Table striped hover size="md" className='mt-4 mb-5'>



      <thead>
      <Row className='sub_header'>
        <Col xs="12" sm="12" lg="3" xl="2" ><span>Nombre del proyecto:</span></Col>
        <Col xs="12" sm="12" lg="3" xl="2"  ><span>Administradores:</span></Col>
        <Col xs="12" sm="12" lg="3" xl="2"  ><span>Fecha de creación:</span></Col>
        <Col xs="12" sm="12" lg="3" xl="2"  ><span>Status:</span></Col>
        <Col xs="12" sm="12" lg="3" xl="2" ><span>Acciones:</span></Col>
      </Row>
      </thead>

        
            <tbody>
              {projectsList.map((project) => {
                const fecha = new Date(project.created).toLocaleDateString('es-ES');
                return (
                  <tr key={project.uuid}>
                    <ProjectItem
                      setFlagAction={setFlagAction}
                      name={project.project_name}
                      admin={project.admin_quantity}
                      fecha={fecha}
                      uuid={project.uuid}
                      enabled={project.enable}
                      patchProject={patchProject}
                    />
                  </tr>
                );
              })}
            </tbody>
       

          






      </Table>


      <PaginationBar total={indexPagination} position={paginationPosition} setPosition={setPaginationPosition} />
      {
        paginationPosition === 1 && projectsList.length >= 5 && (
          <div className="d-flex justify-content-start align-content-center">
            <span className="mt-2">Mostrar:</span>
            <Col xs={12} lg={1} className="mb-2 d-flex">
              <Form.Group controlId="project" className="m-0">
                <Form.Control
                  className="text-muted"
                  as="select"
                  name="project"
                  type="project"
                  defaultValue={projectsInScreen}
                  onChange={({ target }) => {
                    setProjectsInScreen(target.value);
                  }}
                >
                  <option value={5} className="secondary-text-color" selected>5</option>
                  <option value={15} className="secondary-text-color">15</option>
                  <option value={30} className="secondary-text-color">30</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </div>
        )
      }
    </>
  )
}

ProjectsAdmin.propTypes = {
  patchProject: PropTypes.func.isRequired,
  showProjects: PropTypes.func.isRequired,
  projectsList: PropTypes.array,
  isLoaded: PropTypes.bool.isRequired,
  indexPagination: PropTypes.number
};

const stateToProps = state => ({
  projectsList: state.projects.projectsList,
  isLoaded: state.projects.isLoaded,
  indexPagination: state.projects.indexPagination
});

const actionsToProps = dispatch => ({
  patchProject: payload => dispatch({ type: PATCH_PROJECT, payload }),
  showProjects: payload => dispatch({ type: FETCH_LIST_PROJECTS, payload })
});

export default connect(stateToProps, actionsToProps)(ProjectsAdmin);
