import React, { useState } from 'react';
import { Button, Card, Dropdown, Modal } from 'react-bootstrap';
import { Trash2 } from 'react-feather';
import { connect } from 'react-redux';

const FolderDelete = ({ uuid, deleteFolder, errors, folderLoaded }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleDelete = () => {
    deleteFolder(uuid);
  }

  return (
    <>
      <Dropdown.Item
        data-toggle="tooltip"
        data-placement="top"
        title="Eliminar carpeta"
        className="text-danger"
        onClick={handleShow}
      >
        <Trash2 size="15" className="mr-1"/>
        Eliminar
      </Dropdown.Item>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton/>
        <Modal.Body>
          <Card.Body className="py-5 text-center">
            <i className="far fa-trash-alt text-danger text-center fa-3x mb-4"/>
            <h3 className="py-2 font-weight-bold title-color text-center">
              Eliminar carpeta
            </h3>
            <p className="text-center">
              ¿Estás seguro que deseas eliminar esta carpeta?
            </p>
            {
              !folderLoaded && errors.length !== 0 && (
                errors.map(
                  (error) => {
                    return(<p key={error} className='text-danger'>{error}</p>)
                  }
                )
              )
            }
            <div className="justify-content-center d-flex">
              <Button
                className="btn-rounded col-6 base-btn-color mt-4 py-2"
                variant="primary"
                onClick={handleDelete}
              >
                Seguro
              </Button>
            </div>
          </Card.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

const stateToProps = state => ({
  errors: state.documents.folderErrors,
  folderLoaded: state.documents.newFolderLoaded
});

export default connect(stateToProps, null)(FolderDelete);
