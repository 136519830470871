import React, { useState } from 'react';
import {  Col, Image } from 'react-bootstrap';
// import { Check, UploadCloud, XCircle } from 'react-feather';
import avatar from '../../images/avatar-icon.png';

const Picture = ({ /*setLogo*/ logo, imgName, setAuxImage, profileImage, uploadImage, setServiceError}) => {

  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);

  

  const onChangePicture = e => {
    if(imgName === 'image'){
      setAuxImage(true);
    }
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const deleteImg = () => {
    setImgData(null)
  }

  return (
    <Col lg={12}>
      <div style={{alignItems: "center", justifyContent: "center" }} >
        {
          imgData ? (
            <Image alt="" src={imgData} roundedCircle className="my-5 image-user-upload"/>
          ):(
            profileImage ? (
              <Image alt="" src={`${process.env.REACT_APP_SERVICE_URL}${profileImage}`}  style={{ objectFit: "contain", objectPosition: "center" }} roundedCircle className="my-5 image-user-upload"/>
            ):(
              <Image src={avatar} className="my-5 image-user-upload" roundedCircle/>
            )
          )
        }
      </div>


    </Col>

    
  )
}

export default Picture;
