import React, { useEffect, useState } from 'react';
import { Breadcrumb, Col, Image, Row, Table } from 'react-bootstrap';
import { ConfigProvider } from 'react-avatar';
import DocumentItem from '../document_item';
import { connect } from 'react-redux';
import { DELETE_FOLDER, FETCH_LIST_TEMPLATE, POST_FOLDER } from '../../store/document/types';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import CreateFolder from '../documents_admin/createFolder';
import searchFile from '../../images/file-search-regular.svg';

const DocumentsEdit = (
  {
    showTemplateFolders,
    folderList,
    root_directory,
    loadingList,
    createFolder,
    errors,
    newFolderLoaded,
    deleteFolderTemplate
  }) => {
  const { uuid, project } = useParams();
  const [serviceError, setServiceError] = useState([]);
  const [flag, setFlag] = useState(false);
  const history = useHistory();
  const [folderForm, setFolderForm] = useState({
    project: project
  });

  useEffect(() => {
    if( uuid ==='root' )
      showTemplateFolders({ uuid: '', project: project });
    else
      showTemplateFolders({ uuid: uuid, project: project });
  }, [uuid, project, showTemplateFolders]);

  useEffect(() => {
    if(errors.length !==0 && flag )
      setServiceError(errors);
    else
      setServiceError([]);
  }, [errors, flag]);


  useEffect(() => {
    if(uuid !== "root"){
      const formData = { ...folderForm, main_folder: uuid };
      setFolderForm(formData);
    }// eslint-disable-next-line
  }, [uuid]);

  const handleFolder = (uuid) => {
    showTemplateFolders(`/${uuid}`);
  }

  const handleInputChange = ({ target }) => {
    const formData = { ...folderForm, [target.name]: target.value };
    setFlag(false);
    setFolderForm(formData);
  };

  const handleCreateFolder = () => {
    createFolder(folderForm);
    setFlag(true);
  }

  return (
    !loadingList && (
      <ConfigProvider colors={['#F2BBAD', '#E3F2FD', '#2FB099', '#FEF4D5']}>
        <>
          <Row>
            <Col>
              <Breadcrumb className="small">
                <Breadcrumb.Item onClick={()=>history.push({pathname: `/`})}>Inicio</Breadcrumb.Item>
                <Breadcrumb.Item onClick={()=>history.push({pathname: `/admin/documents/root`})}>Administración de documentos</Breadcrumb.Item>
                {
                  root_directory.length === 0 ? (
                    <Breadcrumb.Item active>Carpetas plantilla</Breadcrumb.Item>
                  ):(
                    <Breadcrumb.Item onClick={()=>history.push({pathname: `/admin/documents/root/${project}`})}>Carpetas plantilla</Breadcrumb.Item>
                  )
                }
                {
                  root_directory && (
                    root_directory.map((folder, n) => {
                      return (
                        <>
                          {
                            n === root_directory.length-1 ? (
                              <Breadcrumb.Item active>{folder.folder_name}</Breadcrumb.Item>
                            ):(
                              <Breadcrumb.Item onClick={()=>history.push({pathname: `/admin/documents/${folder.uuid}`})}>{folder.folder_name}</Breadcrumb.Item>
                            )
                          }
                        </>
                      );
                    })
                  )
                }
              </Breadcrumb>
            </Col>
          </Row>
          <Row>
            {
              uuid === "root" && (
                <Col sm="12" md="8" lg="10" className="d-flex align-items-center">
                  <h4 className="font-weight-bold mb-1">Carpetas plantilla</h4>
                </Col>
              )
            }
            {
              root_directory && (
                uuid !== "root" && root_directory.length !==0 && (
                  <Col sm="12" md="8" lg="10" className="d-flex align-items-center">
                    <h4 className="font-weight-bold mb-1">
                      {root_directory[root_directory.length-1].folder_name}
                    </h4>
                  </Col>
                )
              )
            }
            <Col sm="12" md="4" lg="2" className="d-flex align-items-center justify-content-end">
              <CreateFolder
                handleCreateFolder={handleCreateFolder}
                handleInputChange={handleInputChange}
                serviceError={serviceError}
                newFolderLoaded={newFolderLoaded}
              />
            </Col>
          </Row>
          {
            folderList.length === 0 ? (
              <div className="text-center my-5">
                <Image className="my-3" src={searchFile}/>
                <h5 style={{color:"gray"}}>Esta carpeta no posee subcarpetas</h5>
              </div>
            ):(
              <>


                <Table striped hover size="md" className='mt-4 mb-0'>
                  <thead>
                    <tr className='small'>
                      <th scope="col" className='border-top-0 border-bottom-0'>Carpetas</th>
                      <th scope="col" className='border-top-0 border-bottom-0 text-center'>Últ. modificación</th>
                    </tr>
                  </thead>

                  <tbody>
                  <>
                    {
                      folderList && folderList.length !== 0 && !loadingList && (
                        folderList.map( (folder) => {
                          return (
                            <DocumentItem
                              key={folder.uuid}
                              name={folder.folder_name}
                              date={folder.modified.date}
                              size="42 MB"
                              step={root_directory.length+1}
                              type="folder_edition"
                              handleFolder={handleFolder}
                              uuid={folder.uuid}
                              project_uuid={project}
                              deleteFolderTemplate={deleteFolderTemplate}
                            />
                          )
                        })
                      )
                    }
                  </>
                  </tbody>
                </Table>

              </>
            )
          }

        </>
      </ConfigProvider>
    )
  )
}

DocumentsEdit.propTypes = {
  showTemplateFolders: PropTypes.func.isRequired,
  folderList: PropTypes.array,
  root_directory: PropTypes.array,
  document_list: PropTypes.array,
  loadingList: PropTypes.bool,
  createFolder: PropTypes.func.isRequired,
  errors: PropTypes.array,
  deleteFolderTemplate: PropTypes.func.isRequired,
};

const stateToProps = state => ({
  folderList: state.documents.folderList,
  root_directory: state.documents.root_directory,
  document_list: state.documents.document_list,
  loadingList: state.documents.loadingList,
  errors: state.documents.errors,
  newFolderLoaded: state.documents.newFolderLoaded
});

const actionsToProps = dispatch => ({
  showTemplateFolders: payload => dispatch({ type: FETCH_LIST_TEMPLATE, payload }),
  createFolder: payload => dispatch({ type: POST_FOLDER, payload }),
  deleteFolderTemplate: payload => dispatch({ type: DELETE_FOLDER, payload }),
});

export default connect(stateToProps, actionsToProps)(DocumentsEdit);
