import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Spinner, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FETCH_DETAIL_BALANCE_8_COLUMNS } from '../../store/accounting/types';


const AccountingModalOne = (
  {
    cod,
    uuid,
    society,
    money,
    showBalance8ColumnsDetail,
    balance8ColumnsDataDetail,
    isLoadedAccountingDetail,
    rule,
    date
  }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [title, setTitle] = useState('');


  useEffect(() => {
    if (show) {
      showBalance8ColumnsDetail(`${uuid}/?society=${society}${date}&rule=${rule}&cod_cta=${cod}&money_type=${money}`);
    }
  }, [show, showBalance8ColumnsDetail, uuid, society, cod, date, money, rule]);


  useEffect(() => {

    if (!balance8ColumnsDataDetail || !balance8ColumnsDataDetail.table || !balance8ColumnsDataDetail.table[0] || !balance8ColumnsDataDetail.table[0].nom_cta) {
      console.error('No se encontró la información de la cuenta');
      return;
    }
    const titleFirstLetter = balance8ColumnsDataDetail.table[0].nom_cta[0].toUpperCase()
    const restoftheword = balance8ColumnsDataDetail.table[0].nom_cta.slice(2).toLowerCase()
    setTitle(titleFirstLetter + restoftheword)
  }, [showBalance8ColumnsDetail, show, handleShow])










  return (
    <>
      <div
        className="text-primary"
        style={{ cursor: "pointer" }}
        onClick={handleShow}
      >
        {cod}
      </div>


      <Modal show={show} size='lg' onHide={handleClose}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Card.Body>
            {
              isLoadedAccountingDetail ? (
                <div className="d-flex align-content-center justify-content-center">
                  <Spinner as="span" animation="border" size="xl" role="status" aria-hidden="true" />
                </div>
              ) : (
                ''
              )
            }



            {
              balance8ColumnsDataDetail && !isLoadedAccountingDetail && balance8ColumnsDataDetail.table && (

                <>

                  {
                    // Imprime antes del hacer el recorrido para mostrar apenas una vez
                  }
                  <h4 className="font-weight-bold"> { title ? title : 'No fue posible mostrar datos' }</h4>

                  <Table responsive borderless>
                    <thead>
                      <tr>
                        <th className="p-0">
                          <div className="p-2">
                            Cuenta
                          </div>
                          <hr className="m-0" />
                        </th>
                        <th className="p-0">
                          <div className="p-2">
                            Fecha
                          </div>
                          <hr className="m-0" />
                        </th>
                        <th className="p-0">
                          <div className="p-2">
                            Descripción
                          </div>
                          <hr className="m-0" />
                        </th>
                        <th className="p-0">
                          <div className="p-2">
                            Monto
                          </div>
                          <hr className="m-0" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={3} />
                      </tr>
                      {
                        balance8ColumnsDataDetail.table.map((detail) => {
                          return (
                            <tr key={detail.nro_trans}>
                              <td className="p-2">{detail.nro_trans}</td>
                              <td className="p-2">{detail.fecha_reporte}</td>
                              <td className="p-2">{detail.com_mov}</td>
                              <td className="p-2">{detail.imp_mov_mn_sg}</td>
                            </tr>
                          );
                        })
                      }

                      <tr>
                        <td colSpan={2} className="font-weight-bold p-2">{ title ? 'Total' + title : 'No fue posible mostrar datos'  }</td>
                        <td className="font-weight-bold p-2">{balance8ColumnsDataDetail.footer.total}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Button
                    size='lg'
                    className="btn-rounded col-12 base-btn-color "
                    variant="primary"
                    onClick={handleClose}
                  >
                    Cerrar
                  </Button>
                </>
              )
            }
          </Card.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

AccountingModalOne.propTypes = {
  showBalance8ColumnsDetail: PropTypes.func.isRequired,
  balance8ColumnsDataDetail: PropTypes.array,
  isLoadedAccountingDetail: PropTypes.bool,
};

const stateToProps = state => ({
  balance8ColumnsDataDetail: state.accounting.balance8ColumnsDataDetail,
  isLoadedAccountingDetail: state.accounting.isLoadedAccountingDetail
});

const actionsToProps = dispatch => ({
  showBalance8ColumnsDetail: payload => dispatch({ type: FETCH_DETAIL_BALANCE_8_COLUMNS, payload })
});

export default connect(stateToProps, actionsToProps)(AccountingModalOne);
