import servicesURL from './services';

const config = () => ({
  appEnv: process.env.REACT_APP_ENV || 'local',
  serviceUrl: process.env.REACT_APP_SERVICE_URL,
  timeout: 300000,
  services: servicesURL()
});

export default config;
