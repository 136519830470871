import config from '../config';

const { projectsURI, projectAdminsListURI, projectsPdfURI, projectSearchURI, userProjectsURI } = config().services;

const network = client => ({
  getProject: payload => client.get(`${projectsURI}${payload}`),
  postProject: payload => client.post(projectsURI, payload ),
  updateProject: ({ formData, uuid }) => client.patch(`${projectsURI}${uuid}/`, formData),
  getProjectData: payload => client.get(`${projectsURI}${payload}/`),
  getProjectAdmins: payload => client.get(`${projectAdminsListURI}?limit=100000&search=${payload}`),
  deleteProject: payload => client.delete(`${projectsURI}${payload}/`),
  getProjectPDF: payload => client.get(`${projectsPdfURI}${payload}`),
  searchProject: (payload) => client.get(`${projectSearchURI}${payload}`),
  getUserProjects: () => client.get(`${userProjectsURI}?limit=100000`)
});

export default network;
