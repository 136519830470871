import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Spinner, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FETCH_FINANCIAL_SITUATION_DETAIL } from '../../store/accounting/types';
import { connect } from 'react-redux';
import AccountItem from './accountItem';

const AccountingModalTwo = (
  {
    name,
    uuid,
    value,
    parent1,
    money,
    date,
    showFinancialSituationDetail,
    financialSituationDetail,
    isLoadedFinancialDetail,
    society,
    rule
  }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [collapseOpen, setCollapseOpen] = useState(1);

  useEffect(() => {
    if(show){
      showFinancialSituationDetail(`${uuid}/?society=${society}${date}&rule=${rule}&parent1=${parent1}&money_type=${money}`);
    }
  }, [show, date, showFinancialSituationDetail, rule, uuid, society, parent1, money]);

  return (
    <>
      <div
        className="text-primary"
        style={{cursor:"pointer"}}
        onClick={handleShow}
      >
        {value}
      </div>

      <Modal show={show} size='lg' onHide={handleClose}>
        <Modal.Header closeButton/>
        <Modal.Body>
          <Card.Body>
            {
              isLoadedFinancialDetail ? (
                <div className="d-flex align-content-center justify-content-center my-5">
                  <Spinner as="span" animation="border" size="xl" role="status" aria-hidden="true" />
                </div>
              ) : (
                <>
                  <h4 className="font-weight-bold">{name}</h4>
                  <Table responsive borderless>
                    <thead>
                    <tr>
                      <th className="p-0">
                        <div className="p-2">
                          Codigo
                        </div>
                        <hr className="m-0"/>
                      </th>
                      <th className="p-0">
                        <div className="p-2">
                          Cuenta
                        </div>
                        <hr className="m-0"/>
                      </th>
                      <th className="p-0">
                        <div className="p-2">
                          Monto
                        </div>
                        <hr className="m-0"/>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td colSpan={3}/>
                    </tr>
                    {
                      financialSituationDetail.table && (
                        financialSituationDetail.table.length !== 0 ? (
                          financialSituationDetail.table.map((detail, n)=>{
                            return(
                              <AccountItem key={n} n={n} society={society} rule={rule} uuid={uuid} date={date} detail={detail} money={money} collapseOpen={collapseOpen} setCollapseOpen={setCollapseOpen}/>
                            );
                          })
                        ):(
                          <td className="text-center font-bold" colSpan={3}>
                            No hay datos en detalle
                          </td>
                        )
                      )
                    }
                    {
                      financialSituationDetail.footer && (
                        <tr>
                          <td colSpan={2} className="font-weight-bold p-2">Total de {name}</td>
                          <td className="font-weight-bold p-2">{financialSituationDetail.footer.total}</td>
                        </tr>
                      )
                    }
                    </tbody>
                  </Table>
                </>
              )
            }

            <Button
              size='lg'
              className="btn-rounded col-12 base-btn-color "
              variant="primary"
              onClick={handleClose}
            >
              Cerrar
            </Button>
          </Card.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

AccountingModalTwo.propTypes = {
  showFinancialSituationDetail: PropTypes.func.isRequired,
  financialSituationDetail: PropTypes.object,
  isLoadedFinancialDetail: PropTypes.bool,
};

const stateToProps = state => ({
  financialSituationDetail: state.accounting.financialSituationDetail,
  isLoadedFinancialDetail: state.accounting.isLoadedFinancialDetail
});

const actionsToProps = dispatch => ({
  showFinancialSituationDetail: payload => dispatch({ type: FETCH_FINANCIAL_SITUATION_DETAIL, payload })
});

export default connect(stateToProps, actionsToProps)(AccountingModalTwo);
