import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import axios from 'axios';
import { connect } from 'react-redux';
import Alfred from '../../images/AlfredHeader.png'
import { CSSTransition } from 'react-transition-group';
import { Send } from 'react-feather';
import { Minimize2 } from 'react-feather';
import Whatsapp from '../../images/WhatsApp_icon.png'

const Ask = ({
    userProfile,
}) => {

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const currentPath = window.location.pathname;

    useEffect(() => {
        if (userProfile && userProfile.user && userProfile.user.first_name) {
            const firstName = userProfile.user.first_name;
            setName(firstName.charAt(0).toLocaleUpperCase() + firstName.slice(1));
            setEmail(userProfile.user.email)

        }
    }, [userProfile, name]);
/*
   
    const messagesRef = useRef(null)
    const askRef = useRef(null)

    // Esconder chat en las pantallas de inicio y login
    const [showComponent, setShowComponent] = useState(true)

    // Estado de la animación del dialogo de Alfred
    const [showDialog, setShowDialog] = useState(false);

    const [showChat, setShowChat] = useState(false);

    const [showContainer, setShowContainer] = useState(true);

    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState(false);

    // Respuesta de la api
    const [responseApi, setResponse] = useState('');

    // Inicializar el índice del último mensaje a -1
    const [load, setLoad] = useState(true)

    const [askList, setAskList] = useState([]);

    const [name, setName] = useState('')

    // Respuesta
    const [responseAsk, setResponseAsk] = useState([])

    // Pregunta
    const [ask, setAsk] = useState('Hola, soy Alfred tu asistente virtual de impuestos. Quiero recordarte que aunque tengo muchos conocimiento de la legislación vigente, te puedo dar una guía pero no reemplaza la asesoría de un experto en el área. Todos los días estoy incorporando nuevos conocimientos.');

    const [id, setId] = useState('')


    useEffect(() => {
        setTimeout(() => {
            if (messagesRef.current) {
                messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
            
                if (askList.length > responseAsk.length) {
                  // Ajustar la altura al 50% si se envió una pregunta
                  messagesRef.current.style.height = '90%';
                } else {
                  // Ajustar la altura al 100% si se recibió una respuesta
                  messagesRef.current.style.height = '100%';
                }
              }
        }, 0)
      }, [askList, responseAsk]);

    // Captando nombre del usuario
    useEffect(() => {
        if (userProfile && userProfile.user && userProfile.user.first_name) {
            const firstName = userProfile.user.first_name;
            setName(firstName.charAt(0).toLocaleUpperCase() + firstName.slice(1));
            setEmail(userProfile.user.email)

        }
    }, [userProfile, setName]);

   
    // Accediendo al endpoint de la api
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_ALFRED_URL}`, {
                    withCredentials: true,
                    // other custom settings for this specific request
                });
                setResponse(response.data.answer);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [setResponse, axios]);

    // Capta el id
    useEffect(() => {
        if (responseAsk.length > 0) {
            setId(responseAsk[0].session_id);
        }
    }, [responseAsk]);

    useEffect(() => {
        // Enviar la pregunta automáticamente al cargar la página
        handleSubmit({ preventDefault: () => { } });
    }, []); // Dependencia vacía para que se ejecute solo una vez al cargar la página

    // Metodo post
    const handleSubmit = event => {
        if (ask.length === 0) {
            event.preventDefault();
            alert('El campo necesita contener una pregunta!')
        } else {
            event.preventDefault();
            setAskList(prevList => [...prevList, ask]);

            const options = {
                headers: {
                    'Authorization': 'Basic ' + btoa('EduardoPortalMagnus:jKuc53-xekACH-zJyeud-ap1JLs-HEeCKy')
                },
                timeout: 120000 // Adding a timeout of 2 minutes
            };

            const fetchData2 = async () => {
                try {
                    const requestData = {
                        withCredentials: true,
                        question: ask
                    };

                    if (id) {
                        requestData.session_id = id;
                    }

                    const Askresponse = await axios.post(`${process.env.REACT_APP_ALFRED_URL}/alfred/ask`, requestData, options);
                    setResponseAsk(prevList => [...prevList, Askresponse.data]);
                    setLoad(false);
                   
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            setAsk('')
            fetchData2();
            setLoad(true)
        }

    };

    // Send the input value to the question state
    const handleAsk = (event) => {
        event.preventDefault();
        const askValue = event.target.value;
        setAsk(askValue);
    };

    // Zoom effect on loading Alfred icon
    const props = useSpring({ to: { transform: 'scale(1)' }, from: { transform: 'scale(0)' }, delay: 2000 });

    const one_style = useSpring({
        to: {
            position: 'fixed',
            bottom: '15px',
            right: '25px',
            zIndex: '100002',
            backgroundColor: '#e2e2e2',
            borderRadius: '20px',
            height: '30px',
            display: 'flex',
            padding: '15px',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'rgb(58 58 58)',
            cursor: 'pointer',
            transition: 'all 0.5s',
            fontSize: '15px',
            fontWeight: 'bolder',
            fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
            marginTop: '3px',
            padding: '5px 25px'
        }
    });

    const two_style = useSpring({
        to: {
            position: 'fixed',
            bottom: '35px',
            right: '25px',
            zIndex: '100002',
            backgroundColor: '#e2e2e2',
            borderRadius: '50%',
            height: '20px',
            width: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#000',
            cursor: 'pointer',
            transition: 'all 0.5s'
        }
    });

    const currentPath = window.location.pathname;
  
    // Función para cambiar el estado después de cierto tiempo
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowDialog(true);
        }, 2000); // Aparecer después de 2 segundos
        return () => clearTimeout(timer);
    }, []);

    const handleContainerToggle = () => {
        setShowContainer(!showContainer);
    };

    const handleHiddenChatToggle = (event) => {
        event.preventDefault();
        setShowChat(!showChat);
    }
*/
    return (
           email === 'john.droguett@rsmchile.cl' || email === 'g.argomedo@rsmchile.cl' || email === 'superadmin@mail.com' ||
            email === 'claudia.sossa@rsmchile.cl' || email === 'constanza.rodriguez@rsmchile.cl' || email === 'david.sandoval@rsmchile.cl' ||
            email === 'felipe.yanez@rsmchile.cl' || email === 'ingrid.navarrete@rsmchile.cl' || email === 'jose.delafuente@rsmchile.cl' ||
            email === 'natalia.ramirezca@rsmchile.cl' || email === 'roberto.melendez@rsmchile.cl' || email === 'sergio.ayala@rsmchile.cl' ||
            email === 'sergio.bernales@rsmchile.cl' || email === 'yulexis.almeida@rsmchile.cl' || email === 'victor.padilla@erpmagnus.com' || 
            email === 'eileen.Turkot@rsm.global' || email === 'alfonso.elias@rsmmx.mx' || email === 'guillermo.erhard@rsmmx.mx' || 
            email === 'xavier.mena@rsmmx.mx' || email === 'alberto.figueroa@rsmmx.mx' || email === 'luis.orellana@rsm.com.gt' || 
            email === 'ekeller@rsmelsalvador.com' || email === 'eaguirre@rsm.hn' || email === 'freddy.solis@rsm.ni' || 
            email === 'arbatista@rsm.com.pa' || email === 'talvarado@rsm.cr' || email === 'dbarroso@rsm.pr' || email === 'jgomez@rsm.pr' || 
            email === 'milton.bello@rsm.do' || email === 'oscar.bobadilla@rsmco.co' || email === 'victor.gomez@rsmco.co' ||
            email === 'marcelo.conti@rsmbr.com' || email === 'gerhard.carlos@rsmbrasil.com.br' || email === 'jorge.gutfraind@rsm.uy' || 
            email === 'jorge.perez@rsmargentina.com.ar' || email === 'daniel.razzetto@rsmargentina.com.ar' || email === 'marisa.panez@rsm.pe' || 
            email === 'alberto.barboza@rsm.co.ve' || email === 'gustavo.chacin@rsm.co.ve' || email === 'gustavo.leon@rsm.co.ve' || 
            email === 'eduardo.aguirre@rsm.hn' || email === 'jcruz@rsm.com.pa' || email === 'laercio.soto@rsmbr.com' || email === 'alex.gutierrez@rsm.global' || email === 'proyectadmin@mail.com' ? (
            <>
                {currentPath === '/' || currentPath === '/login' || window.innerWidth < 1024 ? (
                    <></>
                ) : (
                    <>
                        <a href='https://wa.me/18563176444' target='_blank'><img id='ask_alfred' src={Whatsapp} width={60} height={60} alt='Whatsapp' /></a>
                    </>
                )}
            </>
        ) : (
            <></>
        )
        
    )



}

const stateToProps = state => ({
    userProfile: state.profile.userProfile,
});


export default connect(stateToProps)(Ask);
