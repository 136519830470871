const schema = {
  description: {
    type: 'string',
    min:4,
    max: 1024,
    optional: false,
    messages: {
      string: 'Debe ingresar descripción',
      stringMin: 'texto demasiado corto.',
      stringMax: 'Se ha excedido el largo maximo (1024 carácteres).'
    }
  }
};

export default schema;
