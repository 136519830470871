export const SET_DATA_REPORT = 'report/SET_DATA_REPORT';
export const FETCH_REPORT = 'report/FETCH_REPORT';
export const SET_DATA_LIST_REPORTS = 'report/SET_DATA_LIST_REPORTS';
export const SET_DATA_LIST_REPORTS_DISABLED = 'report/SET_DATA_LIST_REPORTS_DISABLED';
export const SET_USER_DATA_LIST_REPORTS = 'report/SET_USER_DATA_LIST_REPORTS';
export const FETCH_LIST_REPORTS = 'report/FETCH_LIST_REPORTS';
export const IS_LOADED = 'report/IS_LOADED';
export const IS_LOADED_REPORT = 'report/IS_LOADED_REPORT';
export const POST_REPORTS = 'report/POST_REPORTS';
export const PATCH_REPORT = 'report/PATCH_REPORT';
export const DELETE_REPORT = 'report/DELETE_REPORT';
export const INDEX_PAGINATION_REPORTS = 'report/INDEX_PAGINATION_REPORT';
export const INDEX_PAGINATION_REPORTS_DISABLED = 'report/INDEX_PAGINATION_REPORTS_DISABLED';
export const LOADING_REPORT_FORM = 'report/LOADING_REPORT_FORM';
export const SET_REPORT_ERRORS = 'report/SET_REPORT_ERRORS';
export const FETCH_USER_REPORTS = 'report/FETCH_USER_REPORTS';
export const FETCH_USER_REPORT = 'report/FETCH_USER_REPORT';
export const FETCH_MODULES = 'report/FETCH_MODULES';
export const SET_LIST_MODULES = 'report/LIST_MODULES';
export const GET_REPORTS_REF = 'report/GET_REPORTS_REF';
export const GET_REPORTS_REF_REDUCER = 'report/GET_REPORTS_REF_REDUCER';
