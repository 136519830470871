import React, {useEffect, useState} from 'react'
import { DolarData } from './FunctionDolar'




function Dolar() {

    const [dolar, setDolar] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        DolarData(setDolar);
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 4500)

    }, [])

 
 if(loading){
    return (
        <>
            <div id="dolarIndicator2"></div>
        </>
      )
 }else {
    return (
        <>
            {dolar != null ?
            [dolar].map((item) => {
                return(
                
                    <React.Fragment key={item.data.dolar.codigo}>
                         <div id='containerDolar'>
                         <div id='inLinea'></div>
                        <span id='titleDolar'>USD:</span><br></br>
                        <strong id='dolarvalue'>{item.data.dolar.valor}</strong>                     
                        </div>  

                        <div id='containerDolar'>
                        <div id='inLinea'></div>
                        <span id='titleDolar'>UF:</span><br></br>
                        <strong id='dolarvalue'>{item.data.uf.valor}</strong>                     
                        </div>  

                        <div id='containerDolar'>
                        <div id='inLinea'></div>
                        <span id='titleDolar'>EUR:</span><br></br>
                        <strong id='dolarvalue'>{item.data.euro.valor}</strong>                      
                        </div>

                        <div id='containerDolar'>
                        <div id='inLinea'></div>
                        <span id='titleDolar'>UTM:</span><br></br>
                        <strong id='dolarvalue'>{item.data.utm.valor}</strong>                     
                        </div>

                        <div id='containerDolar'>
                        
                        <div id='inLinea'></div>
                        <span id='titleDolar'>IPC:</span><br></br>
                        <strong id='dolarvalue'>{item.data.ipc.valor}</strong>                       
                        </div>

                    </React.Fragment>
                       
                        
                )
            })
            : 'No hay valores disponibles'}
        </>
      )
 }
     
 
  }

export default Dolar