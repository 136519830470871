import { takeLatest, put, call } from 'redux-saga/effects';
import {
  getGroups,
  postGroup,
  getGroupData,
  updateGroup,
  getGroupAdmins,
  deleteGroup,
  getGroupPDF,
  searchGroup,
  getGroupUsers,
  getUserGroups
} from '../../network';
import {
  FETCH_LIST_GROUPS,
  SET_DATA_LIST_GROUPS,
  IS_LOADED,
  POST_GROUPS,
  SET_DATA_GROUP,
  FETCH_GROUP,
  PATCH_GROUP,
  IS_LOADED_GROUP,
  FETCH_GROUP_ADMINS,
  SET_LIST_GROUP_ADMINS,
  DELETE_GROUP,
  FETCH_PDF,
  INDEX_PAGINATION_GROUPS,
  LOADING_GROUP_FORM, SET_GROUP_ERRORS, SET_LIST_USERS, FETCH_GROUP_USERS, FETCH_USERS_GROUP, FILTERED_GROUPS
} from './types';
import formsErrors from '../../utils/errors/formsErrors';
import messageValidationFailed from '../../utils/helpers/messageValidationFailed';
import cookieHandler from '../../utils/helpers/cookie.handler';
import registerErrors from '../../utils/errors/formsErrors';

function* showGroups({ payload }) {
  try {
    let response = "";
    const { queryValue, paginationPosition, groupsInScreen, groupSearch, project, filtered } = payload;
    if(filtered) {
      const response2 = yield call(searchGroup, `?limit=10000&offset=${(paginationPosition - 1) * groupsInScreen}&project=${project}`);
      yield put({ type: FILTERED_GROUPS, payload: response2.data.results });
    }else{
      if(!paginationPosition){
        if((groupSearch || project) && !filtered) {
          response = yield call(searchGroup, `?limit=10000&offset=${(paginationPosition - 1) * groupsInScreen}&search=${groupSearch}&project=${project}`);
        }else
          response = yield call(getGroups,`?limit=10000&offset=${(paginationPosition-1)*groupsInScreen}`);
      } else{
        response = yield call(searchGroup, `${queryValue}&limit=${groupsInScreen}&offset=${(paginationPosition - 1) * groupsInScreen}`);
      }
    }
    yield put({ type: IS_LOADED, payload: false })
    yield put({ type: SET_DATA_LIST_GROUPS, payload: response.data.results });
    yield put({ type: IS_LOADED, payload: true });
    yield put({ type: INDEX_PAGINATION_GROUPS, payload: Math.ceil(response.data.count/groupsInScreen) });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showGroup({ payload }) {
  try {
    const response = yield call(getGroupData, payload);
    yield put({ type: IS_LOADED_GROUP, payload: false })
    yield put({ type: SET_DATA_GROUP, payload: response.data });
    yield put({ type: IS_LOADED_GROUP, payload: true })
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* deleteGroupData({ payload }) {
  try {
    yield call(deleteGroup, payload);
    window.location.reload();
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* postGroups({ payload }) {
  let errors = [];
  try {
    yield put({ type: LOADING_GROUP_FORM, payload: true });
    yield call(postGroup, payload);
    yield put({ type: LOADING_GROUP_FORM, payload: false });
    yield put({ type: SET_GROUP_ERRORS, payload: null });
  } catch (e) {
    yield put({ type: LOADING_GROUP_FORM, payload: true });
    const { response } = e;
    if (response.status === 400) {
      if (response.data.group_name) {
        errors = [...errors, messageValidationFailed(response.data.group_name, 'nombre de grupo.')];
      }
      if (response.data.group_description) {
        errors = [...errors, messageValidationFailed(response.data.group_description, 'descripción de grupo.')];
      }
      if(response.data.admin.includes('ADMIN_ERROR')){
        errors = [...errors,registerErrors.ADMIN_ERROR];
      }
      yield put({ type: SET_GROUP_ERRORS, payload: errors });
      yield put({ type: LOADING_GROUP_FORM, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_GROUP_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: LOADING_GROUP_FORM, payload: false });
    }
    // TODO: handle error
    console.log(e);
  }
}

function* patchGroup({ payload }) {
  let errors = [];
  try {
    yield put({ type: LOADING_GROUP_FORM, payload: true });
    yield call(updateGroup, payload);
    yield put({ type: LOADING_GROUP_FORM, payload: false });
    yield put({ type: SET_GROUP_ERRORS, payload: null });
  } catch (e) {
    const { response } = e;
    if (response.status === 400) {
      if (response.data.group_name) {
        errors = [...errors, messageValidationFailed(response.data.group_name, 'nombre de grupo.')];
      }
      if (response.data.group_description) {
        errors = [...errors, messageValidationFailed(response.data.group_description, 'descripción de grupo.')];
      }
      if(response.data.admin.includes('ADMIN_ERROR')){
        errors = [...errors,registerErrors.ADMIN_ERROR];
      }
      yield put({ type: SET_GROUP_ERRORS, payload: errors });
      yield put({ type: LOADING_GROUP_FORM, payload: false });
    }
    if (response && response.status === 500) {
      yield put({ type: SET_GROUP_ERRORS, payload: formsErrors.TRY_AGAIN });
      yield put({ type: LOADING_GROUP_FORM, payload: false });
    }
    // TODO: handle error
    console.log(e);
  }
}

function* showGroupAdmins({ payload }) {
  try {
    const response = yield call(getGroupAdmins, payload);
    yield put({ type: SET_LIST_GROUP_ADMINS, payload: response.data.results });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showGroupUsers({ payload }) {
  try {
    const response = yield call(getGroupUsers, payload);
    yield put({ type: SET_LIST_USERS, payload: response.data.results });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showGroupPDF({ payload }) {
  try {
    const accessToken = cookieHandler().getAccessToken();
    yield call(getGroupPDF, `${payload}/?token=${accessToken}`);
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_SERVICE_URL}api/v1/groups/detail/pdf/${payload}/?token=${accessToken}`;
    link.setAttribute('download', 'report.pdf');
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* showUserGroup() {
  try {
    const response = yield call(getUserGroups);
    yield put({ type: IS_LOADED, payload: false })
    yield put({ type: SET_DATA_LIST_GROUPS, payload: response.data.results });
    yield put({ type: IS_LOADED, payload: true });
  } catch (e) {
    // TODO: handle error
    console.log(e);
  }
}

function* watchShowGroups() {
  yield takeLatest(FETCH_LIST_GROUPS, showGroups);
}

function* watchDeleteGroupData() {
  yield takeLatest(DELETE_GROUP, deleteGroupData);
}

function* watchShowGroup() {
  yield takeLatest(FETCH_GROUP, showGroup);
}

function* watchPostGroups() {
  yield takeLatest(POST_GROUPS, postGroups);
}

function* watchPatchGroup() {
  yield takeLatest(PATCH_GROUP, patchGroup);
}

function* watchShowProjectAdmins() {
  yield takeLatest(FETCH_GROUP_ADMINS, showGroupAdmins);
}

function* watchShowGroupUsers() {
  yield takeLatest(FETCH_GROUP_USERS, showGroupUsers);
}

function* watchShowGroupPDF() {
  yield takeLatest(FETCH_PDF, showGroupPDF);
}

function* watchShowUserGroup() {
  yield takeLatest(FETCH_USERS_GROUP, showUserGroup);
}

const saga = [
  watchShowGroups(),
  watchShowGroup(),
  watchPostGroups(),
  watchPatchGroup(),
  watchShowProjectAdmins(),
  watchDeleteGroupData(),
  watchShowGroupPDF(),
  watchShowGroupUsers(),
  watchShowUserGroup()
];

export default saga;
